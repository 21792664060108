define("zipbooks/mixins/transaction-sheet/auto-suggest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    defaultInputClasses: '',
    enterKeyPressed: function enterKeyPressed(_event) {
      this._super.apply(this, arguments);

      return true;
    },
    escapeKeyPressed: function escapeKeyPressed() {
      this.set('show', false);
      this.set('selection', null);
      this.set('fresh', true);

      if (this.savedInitialValue) {
        var searchText = this.labelForResult(this.savedInitialValue);
        this.set('searchText', searchText || '');
      }

      return true;
    }
  });

  _exports.default = _default;
});