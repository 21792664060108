define("zipbooks/components/mega-menu/nav-link", ["exports", "zipbooks/components/nav-side-bar/nav-link"], function (_exports, _navLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _navLink.default.extend({});

  _exports.default = _default;
});