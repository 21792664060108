define("zipbooks/controllers/main/invoices", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller"], function (_exports, _controllerPagination, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    queryParams: ['status', 'contact_id'],
    status: 'all',
    contact_id: null,
    sort: 'date',
    direction: 'desc',
    archived: false,
    collection: Ember.computed.alias('model.invoices'),
    actions: {
      filterByContact: function filterByContact(contact) {
        this.transitionToRoute({
          queryParams: {
            contact_id: contact && contact.get('id'),
            page: 1
          }
        });
      },
      duplicate: function duplicate(invoice) {
        var _this = this;

        this.client.send('POST', 'invoices', invoice.get('id') + '/duplicate').then(function (resp) {
          _this.store.pushPayload(resp);

          var duplicated = _this.store.peekRecord('invoice', resp.data.id);

          _this.transitionToRoute('main.invoice', duplicated, {
            queryParams: {
              editing: true
            }
          });

          _this.overlay.showBanner('success', 'Your invoice has been duplicated.');
        }, function ()
        /*jqXHR, textStatus,errorThrown */
        {
          _this.overlay.showBanner('error', 'Failed to duplicate invoice.');
        });
      }
    }
  });

  _exports.default = _default;
});