define("zipbooks/templates/components/dashboard/total-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0igUk0kA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"box-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row align-items-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto font-size-16px\"],[8],[0,\"\\n      Total licenses\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col text-right font-size-12px gray font-weight-normal\"],[8],[0,\"\\n      \"],[4,\"drawer-link\",[\"add-client\"],[[\"params\"],[[28,\"hash\",null,[[\"withLicense\"],[true]]]]],{\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-add btn-add-tiny btn-depressable\"],[10,\"data-test-id\",\"add-license\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"box-info-content px-5 py-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row mb-2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"font-size-48px lh-36px font-weight-bold blue\"],[10,\"data-test-id\",\"license-count\"],[8],[1,[28,\"if\",[[24,[\"data\",\"total\"]],[24,[\"data\",\"total\"]],\"0\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"canvas\",true],[10,\"class\",\"p-3\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/dashboard/total-licenses.hbs"
    }
  });

  _exports.default = _default;
});