define("zipbooks/components/drawer-link", ["exports", "zipbooks/utils/drawer-link-for"], function (_exports, _drawerLinkFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DrawerLinkComponent = Ember.Component.extend({
    tagName: '',
    drawerParams: Ember.computed('model.id', function () {
      return (0, _drawerLinkFor.default)([this.model, this.parent, this.params]);
    })
  });
  DrawerLinkComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = DrawerLinkComponent;
  _exports.default = _default;
});