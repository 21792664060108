define("zipbooks/components/overlays/dialogs/growth/associate-locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    step: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('reference', {
        locationAssociator: null
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().closest('.modal-dialog').addClass('modal-dialog-wider');
      var integration = this.get('model.integration');

      if (integration.oauthProvider === 'square') {
        if (integration.externalLocations.any(function (l) {
          return l.get('location.id');
        })) {
          this.set('step', 1);
        }
      }
    },
    actions: {
      giveUp: function giveUp() {
        var _this = this;

        this.client.DELETE("integrations/".concat(this.model.integration.oauthProvider.dasherize(), "/").concat(this.model.integration.id)).then(function () {
          _this.close();
        });
      },
      confirm: function confirm() {
        var _this2 = this;

        this.set('buttonState', 'saving');
        this.reference.locationAssociator.save().then(function () {
          _this2.set('buttonState', 'loaded');

          if (_this2.get('model.integration.oauthProvider') === 'square') {
            _this2.store.query('location', {
              filter: {
                'billable-for': 'square'
              }
            }).then(function (locations) {
              var integration = _this2.get('model.integration');

              if (integration && locations.length > 0) {
                var params;

                if (_this2.get('model.squareSkipPreferences')) {
                  params = {
                    enableSync: true,
                    location: locations.firstObject,
                    syncedTo: moment().subtract(30, 'days').format('YYYY-MM-DD')
                  };
                } else {
                  params = {
                    location: locations.firstObject
                  };
                }

                integration.setProperties(params);
                integration.save().then(function () {
                  _this2.nextStep();
                });
              } else {
                _this2.nextStep();
              }
            }, function () {
              _this2.set('buttonState', 'invalid');
            });
          } else {
            _this2.finish();
          }
        }, function () {
          _this2.set('buttonState', 'invalid');
        });
      },
      saveIntegration: function saveIntegration() {
        var _this3 = this;

        this.get('model.integration').save().then(function () {
          _this3.finish();
        }, function () {
          _this3.set('buttonState', 'invalid');
        });
      }
    },
    nextStep: function nextStep() {
      if (!this.get('model.squareSkipPreferences')) {
        this.set('step', 1);
      } else {
        this.finish();
      }
    },
    finish: function finish() {
      if (this.callbacks && this.callbacks.complete) {
        this.callbacks.complete();
      }

      this.close();
    }
  });

  _exports.default = _default;
});