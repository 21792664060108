define("zipbooks/templates/components/time/from-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OHkpPnib",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"timeEntry\",\"startTime\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[[22,\"class\"],\" white-space-nowrap\"]]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"gray-800\"],[8],[1,[28,\"format-date\",[[24,[\"timeEntry\",\"startTime\"]],\"h:mm\"],null],false],[9],[7,\"span\",true],[10,\"class\",\"gray-700\"],[8],[1,[28,\"format-date\",[[24,[\"timeEntry\",\"startTime\"]],\"a\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"timeEntry\",\"endTime\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"gray-500\"],[8],[0,\" - \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"gray-800\"],[8],[1,[28,\"format-date\",[[24,[\"timeEntry\",\"endTime\"]],\"h:mm\"],null],false],[9],[7,\"span\",true],[10,\"class\",\"gray-700\"],[8],[1,[28,\"format-date\",[[24,[\"timeEntry\",\"endTime\"]],\"a\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/time/from-to.hbs"
    }
  });

  _exports.default = _default;
});