define("zipbooks/components/dashboard/monthly-revenue", ["exports", "zipbooks/utils/graph-colors"], function (_exports, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    periods: Ember.computed(function () {
      var data = this.data; // collect ids

      var ids = data.map(function (d) {
        return d.chart_account_id;
      }).compact().uniq(); // map ids to colors

      var colorIds = ids.reduce(function (acc, id, idx) {
        acc[id] = _graphColors.default.cycle[idx];
        return acc;
      }, {}); // assign colors

      data.forEach(function (d) {
        return Ember.set(d, 'color', colorIds[d.chart_account_id]);
      });
      return [0, 1, 3, 12].map(function (ago) {
        // this month
        var period = moment().subtract(ago, 'month');
        var month = period.format('YYYY-MM');
        var monthAmounts = data.filter(function (d) {
          return d.month == month;
        });
        var monthTotal = monthAmounts.reduce(function (acc, m) {
          return acc + m.total * 1;
        }, 0); // previous month

        var previousMonth = moment().subtract(ago + 1, 'month').format('YYYY-MM');
        var previousMonthAmounts = data.filter(function (d) {
          return d.month == previousMonth;
        });
        var previousMonthTotal = previousMonthAmounts.reduce(function (acc, m) {
          return acc + m.total * 1;
        }, 0);
        var projectedRevenue;
        var percentIncrease;

        if (ago === 0) {
          // Projected revenue = This month revenue * (Total # of days in this month / day of the month)
          // Percent change = (Projected revenue / last month revenue) - 1
          var daysInMonth = moment().daysInMonth();
          var dayOfMonth = moment().date();
          projectedRevenue = monthTotal * (daysInMonth / dayOfMonth);
          percentIncrease = Math.round((projectedRevenue / previousMonthTotal - 1) * 100);
        } else {
          var increase = monthTotal - previousMonthTotal;
          percentIncrease = Math.round(increase / previousMonthTotal * 100);
        }

        var periodTitle;

        if (ago === 0) {
          periodTitle = 'This month';
        } else if (ago === 1) {
          periodTitle = 'Last month';
        } else {
          periodTitle = ago + ' months ago';
        } //end_date=2018-01-31&start_date=2017-01-01


        return {
          projectedRevenue: projectedRevenue,
          title: periodTitle,
          monthTotal: monthTotal,
          start_date: period.startOf('month').format('YYYY-MM-DD'),
          end_date: period.endOf('month').format('YYYY-MM-DD'),
          percentIncrease: isNaN(percentIncrease) ? 0 : percentIncrease,
          salesAccounts: monthAmounts.filter(function (a) {
            return a.chart_account_is_sales;
          })
        };
      });
    })
  });

  _exports.default = _default;
});