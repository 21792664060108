define("zipbooks/components/transaction-sheet/suggest/simple-select", ["exports", "zipbooks/components/suggest/simple-select", "zipbooks/mixins/transaction-sheet/auto-suggest"], function (_exports, _simpleSelect, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simpleSelect.default.extend(_autoSuggest.default, {});

  _exports.default = _default;
});