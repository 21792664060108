define("zipbooks/overrides/store", ["exports", "@ember-data/store", "zipbooks/utils/classification-helpers", "zipbooks/utils/classification-tags"], function (_exports, _store, _classificationHelpers, _classificationTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _store.default.reopen({
    urlCache: Ember.inject.service(),
    adapter: 'default',
    cachedQuery: function cachedQuery(type) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var expires = arguments.length > 2 ? arguments[2] : undefined;
      // query changes the params
      var $params = $.extend(true, {}, params); // per adapter

      $params.adapter = this.adapter;
      var cached = this.urlCache.read(type, $params);

      if (cached) {
        return cached;
      }

      var query = this.query(type, params);
      this.urlCache.write(type, $params, query, expires);
      return query;
    },
    unloadUnsaved: function unloadUnsaved(type) {
      this.peekAll(type).forEach(function (record) {
        if (record.get('isNew')) {
          record.deleteRecord();
          record.unloadRecord();
        }
      });
    },
    chartAccounts: function chartAccounts() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        filter: []
      };
      var results = this.peekAll('chartAccount').filter(function (ca) {
        return !ca.archivedAt;
      }); // handle filters

      if (opts.filter.includes('created-only')) {
        results = results.filter(function (chartAccount) {
          return !!chartAccount.viewable;
        });
      }

      if (opts.filter.includes('uncreated-only')) {
        results = results.filter(function (chartAccount) {
          return !chartAccount.viewable;
        });
      }

      if (opts.filter.includes('can-have-children')) {
        results = results.filter(function (chartAccount) {
          return chartAccount.classificationInfo.children && !chartAccount.isBankAccount;
        });
      }

      if (opts.filter.includes('non-bank-accounts')) {
        results = results.filter(function (chartAccount) {
          return !chartAccount.isBankAccount;
        });
      }

      if (opts.filter.includes('cash')) {
        results = results.filter(function (chartAccount) {
          return chartAccount.classification === 'Cash';
        });
      }

      if (opts.filter.includes('sales')) {
        results = results.filter(function (chartAccount) {
          return ['Sales', 'Other revenue'].includes(chartAccount.classification);
        });
      }

      if (opts.filter.includes('expenses')) {
        results = results.filter(function (chartAccount) {
          return ['Operating expenses', 'Other expenses', 'Cost of goods sold'].includes(chartAccount.classification);
        });
      }

      if (opts.filter.includes('accounts-receivable')) {
        results = results.filter(function (chartAccount) {
          return chartAccount.classification === 'Accounts receivable';
        });
      }

      if (opts.filter.includes('paid-from')) {
        results = results.filter(function (chartAccount) {
          return chartAccount.isCritical || _classificationHelpers.default.withTag(_classificationTags.default['expense-drawer-account-filter']).mapBy('name').includes(chartAccount.classification);
        });
      }

      if (opts.filter.includes('paid-to')) {
        results = results.filter(function (chartAccount) {
          return chartAccount.isCritical || _classificationHelpers.default.withTag(_classificationTags.default['deposit-drawer-account-filter']).mapBy('name').includes(chartAccount.classification);
        });
      }

      if (opts.filter.includes('transfer')) {
        results = results.filter(function (chartAccount) {
          return chartAccount.isCritical || _classificationHelpers.default.withTag(_classificationTags.default['owners-equity-account-filter']).mapBy('name').includes(chartAccount.classification);
        });
      }

      if (opts.filter.includes('bill-line-item')) {
        results = results.filter(function (chartAccount) {
          return _classificationHelpers.default.withTag(_classificationTags.default['bill-item-account-filter']).mapBy('name').includes(chartAccount.classification);
        });
      }

      return results;
    }
  });

  _exports.default = _default;
});