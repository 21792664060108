define("zipbooks/templates/components/overlays/dialogs/recurring-profile-send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yALauvhH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n  Since your next scheduled date for this recurring profile is today, we can send this invoice now. Are you sure you want to do that?\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-full btn-green\"],[12,\"title\",\"Send\"],[12,\"data-test-id\",\"send\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"send\"]],[8],[0,\"Yes, Save & Send\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-full btn-white\"],[12,\"title\",\"Cancel\"],[12,\"data-test-id\",\"cancel\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"No, cancel\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/overlays/dialogs/recurring-profile-send.hbs"
    }
  });

  _exports.default = _default;
});