define("zipbooks/utils/action-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    sent: {
      color: 'blue'
    },
    viewed: {
      color: 'blue'
    },
    accepted: {
      color: 'green'
    },
    invoice_paid: {
      color: 'green'
    },
    invoice_refunded: {
      color: 'red'
    },
    estimate_converted: {
      color: 'green'
    },
    bill_created: {
      color: 'red'
    },
    bill_paid: {
      color: 'red'
    },
    payment_failed: {
      color: 'red'
    },
    invoice_reminder_sent: {
      color: 'blue'
    },
    bank_connected: {
      color: 'blue'
    },
    stripe_amount_deposited: {
      color: 'green'
    },
    transaction_confirmed: {
      color: 'blue'
    },
    transaction_imported_from_bank: {
      color: 'blue'
    },
    transaction_auto_categorized: {
      color: 'purple'
    },
    time_entry_created: {
      color: 'blue'
    },
    password_reset: {
      color: 'purple'
    },
    recurring_expires: {
      color: 'red'
    },
    recurring_paused: {
      color: 'gray'
    },
    recurring_unpaused: {
      color: 'green'
    },
    recurring_created: {
      color: 'green'
    },
    recurring_profile_payment_failed: {
      color: 'red'
    },
    team_member_added: {
      color: 'purple'
    },
    document_added: {
      color: 'purple'
    },
    updated: {
      color: 'green'
    },
    user_activity: {
      color: 'purple'
    }
  };
  _exports.default = _default;
});