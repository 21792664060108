define("zipbooks/controllers/main/accountant/clients/defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function save() {
        return this.model.save();
      }
    }
  });

  _exports.default = _default;
});