define("zipbooks/controllers/main/reports/expense-summary", ["exports", "zipbooks/mixins/tag-filterable-controller", "zipbooks/mixins/reports/date-error"], function (_exports, _tagFilterableController, _dateError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tagFilterableController.default, _dateError.default, {
    queryParams: ['start_date', 'end_date', 'group_by', 'include'],
    group_by: 'category',
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    expenses: Ember.computed('model.expense_summary.@each', function () {
      var _this = this;

      return this.model.expense_summary.map(function (c) {
        c.lines = c.lines.map(function (l) {
          if (_this.get('group_by') === 'customer') {
            l.item_name = l.chart_account_name;
          } else {
            l.item_name = l.customer_name;
          }

          return l;
        });
        return c;
      });
    }),
    grandTotal: Ember.computed('model.grand_total', function () {
      return this.model.grand_total;
    }),
    opposingItemName: Ember.computed('group_by', function () {
      return this.get('group_by') === 'customer' ? 'Category' : 'Customer';
    })
  });

  _exports.default = _default;
});