define("zipbooks/mixins/embedded-jsonapi-records", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_rest.EmbeddedRecordsMixin, {
    serializeBelongsTo: function serializeBelongsTo(_snapshot, json, relationship) {
      this._super.apply(this, arguments);

      this._adjustEmbedLocation(json, relationship, function (item) {
        return item.data;
      });
    },
    serializeHasMany: function serializeHasMany(_snapshot, json, relationship) {
      this._super.apply(this, arguments);

      this._adjustEmbedLocation(json, relationship, function (items) {
        return items.map(function (item) {
          return item.data;
        });
      });
    },
    _adjustEmbedLocation: function _adjustEmbedLocation(json, relationship, transform) {
      var key = relationship.key.dasherize();

      if (json.hasOwnProperty(key)) {
        var result = json[key] && transform(json[key]);
        delete json[key];

        if (!json.relationships) {
          json.relationships = {};
        }

        json.relationships[key] = {
          data: result
        };
      }
    }
  });

  _exports.default = _default;
});