define("zipbooks/routes/main/accountant/dashboard", ["exports", "zipbooks/mixins/refreshable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _refreshableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('session.subscription.plan.kind') !== 'cpa') {
        this.transitionTo('main.dashboard');
      }

      this._super.apply(this, arguments);
    },
    model: function model() {
      return this.client.cached('GET', 'dashboard', 'client');
    }
  });

  _exports.default = _default;
});