define("zipbooks/components/overlays/dialogs/destroy-confirm", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/string"], function (_exports, _testable, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    testId: 'destroy-confirm',
    warning: Ember.computed('model.type', 'model.count', function () {
      return (0, _string.default)(this.get('model.type'), 'destroy', 'warning', {
        count: this.get('model.count'),
        pluralize: this.get('model.type'),
        humanize: true
      });
    }),
    prompt: Ember.computed('model.type', 'model.count', function () {
      return (0, _string.default)(this.get('model.type'), 'destroy', 'body', {
        count: this.get('model.count'),
        pluralize: this.get('model.type'),
        humanize: true
      });
    }),
    actions: {
      destroy: function destroy() {
        var _this = this;

        if (this.get('callbacks.destroy')) {
          var promise = this.get('callbacks.destroy')();

          if (promise && promise.then) {
            this.set('buttonState', 'saving');
            promise.then(function () {
              _this.set('buttonState', 'loaded');

              _this.close();
            }, function () {
              _this.set('buttonState', 'invalid');
            });
          } else {
            this.close();
          }
        } else {
          this.close();
        }
      },
      cancel: function cancel() {
        if (this.get('callbacks.cancel')) {
          this.get('callbacks.cancel')();
        }

        this.close();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('[type="submit"]').focus();
    }
  });

  _exports.default = _default;
});