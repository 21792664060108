define("zipbooks/components/quick-stats/last-three-estimates", ["exports", "zipbooks/mixins/testable", "ember-data"], function (_exports, _testable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    store: Ember.inject.service(),
    estimates: Ember.computed('contact.id', function () {
      if (this.get('contact.id')) {
        return _emberData.default.PromiseArray.create({
          promise: this.store.cachedQuery('estimate', {
            page: {
              'page-size': 3
            },
            filter: {
              contact_id: this.get('contact.id')
            },
            sort: '-date'
          })
        });
      }
    })
  });

  _exports.default = _default;
});