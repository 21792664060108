define("zipbooks/components/drawers/transaction/deposit-mode", ["exports", "zipbooks/mixins/transaction-mode", "zipbooks/utils/line-sorter"], function (_exports, _transactionMode, _lineSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transactionMode.default, {
    sortedLines: Ember.computed('entry.journalEntryLines.@each', function () {
      var lines = this.get('entry.journalEntryLines').toArray();
      return (0, _lineSorter.default)(lines, 'debit', 'deposit-drawer-account-filter');
    }),
    getPrimaryLine: function getPrimaryLine() {
      var primaryLine = this._super.apply(this, arguments);

      if (primaryLine) {
        primaryLine.set('previousKind', 'debit');
        primaryLine.set('kind', 'debit');
      }

      return primaryLine;
    },
    savableEntry: function savableEntry() {
      var primaryLine = this.primaryLine; // set the amount of the primary line to the sum of the bottom lines

      primaryLine.set('amount', this.bottomSum);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});