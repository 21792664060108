define("zipbooks/helpers/param-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paramToggle = paramToggle;
  _exports.default = void 0;

  function paramToggle(params, _hash) {
    var value = params[1];
    var current = params[0];

    if (current) {
      var array = current.split(',');

      if (array.includes(value)) {
        return array.filter(function (v) {
          return v !== value;
        }).join(',');
      } else {
        array.pushObject(value);
        return array.join(',');
      }
    } else {
      return value;
    }
  }

  var _default = Ember.Helper.helper(paramToggle);

  _exports.default = _default;
});