define("zipbooks/components/tag-input", ["exports", "zipbooks/utils/navigate-to", "zipbooks/components/base-tag-input"], function (_exports, _navigateTo, _baseTagInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTagInput.default.extend({
    overlay: Ember.inject.service(),
    savedTags: Ember.computed.filterBy('tags', 'isNew', false),
    visibleTags: Ember.computed('tags.@each.createdAt', 'savedTags.@each.createdAt', 'showUnsaved', function () {
      var tags;

      if (this.showUnsaved) {
        tags = this.tags;
      } else {
        tags = this.savedTags;
      }

      return tags.toArray().sort(function (a, b) {
        if (!a.get('createdAt')) {
          return 1;
        }

        if (!b.get('createdAt')) {
          return -1;
        }

        return a.get('createdAt') - b.get('createdAt');
      });
    }),
    hasNoSelectedKinds: Ember.computed('visibleTags.@each', function () {
      return !this.visibleTags.any(function (t) {
        return t.kind === 'kind';
      });
    }),
    noneOptionSelected: Ember.computed('visibleTags.@each', function () {
      return this.visibleTags.any(function (t) {
        return t.id === 'none';
      });
    }),
    actions: {
      view: function view(tag, e) {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target.classList.contains('tag-remove')) {
          var router = Ember.getOwner(this).lookup('router:main');
          var kind = tag.get('taggableType').dasherize();
          (0, _navigateTo.default)(router, kind, tag.get('taggable.id') || tag.get('id'), tag, this.overlay);
        }
      }
    },
    click: function click(event) {
      // if they click in the tag input but not on a tag or the input, put focus in the input
      if (event.target == this.$()[0]) {
        event.preventDefault();
        event.stopPropagation();
        this.$('input').focus();
      }
    }
  });

  _exports.default = _default;
});