define("zipbooks/utils/transaction-sheet/row", ["exports", "zipbooks/mixins/add-journal-entry-line-tags", "zipbooks/utils/parse-date"], function (_exports, _addJournalEntryLineTags, _parseDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_addJournalEntryLineTags.default, {
    dateTransform: Ember.computed('transaction.journalEntry.journalEntryLines.@each.date', {
      get: function get(_key) {
        var date;

        if (this.transaction.splitInfo.isSplit && !this.transaction.splitInfo.mergedColumns.includes('date')) {
          date = this.categoryLine.date;
        } else {
          date = this.transaction.journalEntry.journalEntryLines.mapBy('date').firstObject;
        }

        if (date) {
          return moment(date).format('MM/DD/YY');
        }
      },
      set: function set(_key, value) {
        var parsed = (0, _parseDate.default)(value);

        if (parsed) {
          var date = parsed.format('YYYY-MM-DD');

          if (this.transaction.splitInfo.isSplit && !this.transaction.splitInfo.mergedColumns.includes('date')) {
            this.categoryLine.set('date', date);
            this.set('date', date);
          } else {
            this.transaction.journalEntry.journalEntryLines.forEach(function (l) {
              return l.set('date', date);
            });
          }

          return value;
        } else {
          return value; // return moment(this.transaction.journalEntry.date).format('MM/DD/YY')
        }
      }
    }),
    tags: Ember.computed('tagLines.@each.tagCount', {
      get: function get(_key) {
        return this.tagLines.reduce(function (acc, line) {
          return acc.concat(line.tags.toArray());
        }, []).uniqBy('name');
      },
      set: function set(_key, value) {
        if (!value) {
          this.tagLines.forEach(function (line) {
            line.tags.forEach(function (t) {
              return t.destroyRecord();
            });
            line.set('tags', []);
          });
          return [];
        }

        return value;
      }
    }),

    /*************************************************
     * AddJournalEntryLineTagsMixin Protocol
     **************************************************/
    linesToBeTagged: function linesToBeTagged() {
      return this.tagLines;
    },
    tagWasAdded: function tagWasAdded(tag) {
      var _this = this;

      var tagNames = this.tags.mapBy('name');

      if (!tagNames.includes(tag.name)) {
        this.tags.pushObject(tag);
      }

      tag.save().catch(function (error) {
        return _this.handleError(error, tag);
      });
    },
    tagWasRemoved: function tagWasRemoved(tag) {
      this.tags.removeObject(tag);
      tag.destroyRecord();
    },
    handleError: function handleError(error, tag) {
      this.linesToBeTagged().forEach(function (l) {
        l.get('tags').removeObject(tag);
      });
      var message = Ember.get(error, 'errors.firstObject.detail');

      if (message) {
        this.overlay.showBanner('error', message);
      }
    }
  });

  _exports.default = _default;
});