define("zipbooks/routes/main/reports/balance-sheet", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      include: {
        refreshModel: true
      },
      basis: {
        refreshModel: true
      },
      group_by: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.tags = this.paramTags(params);

      if (moment(params.start_date).isAfter(moment(params.end_date))) {
        params.start_date = params.end_date;
      }

      this.set('params', params);
      return Ember.RSVP.hash({
        statement: Ember.RSVP.resolve(this.client.send('GET', 'reports', "balance-sheet?".concat($.param(params))).then(function (resp) {
          return resp;
        }))
      });
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var queryParams = {
          basis: params.basis,
          include: params.include,
          group_by: params.group_by,
          start_date: params.start_date,
          end_date: params.end_date
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.location = '/v2/reports/balance-sheet/export?' + $.param(queryParams);
      },
      pdf: function pdf() {
        var params = this.params;
        var queryParams = {
          basis: params.basis,
          include: params.include,
          group_by: params.group_by,
          start_date: params.start_date,
          end_date: params.end_date,
          tmz_offset: moment().utcOffset()
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.open('/v2/reports/balance-sheet/pdf?' + $.param(queryParams), '_blank');
      }
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      this._super.apply(this, arguments);

      if (!controller.get('include')) {
        controller.set('include', this.get('session.account.settings.reportsDefaultShowUnconfirmed') ? 'all' : 'confirmed');
      }

      if (!controller.get('basis')) {
        controller.set('basis', this.get('session.account.settings.defaultBasis'));
      }

      controller.setup();
    }
  });

  _exports.default = _default;
});