define("zipbooks/components/drawers/journal-entry-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/utils/line-analyzer", "zipbooks/utils/array"], function (_exports, _modelDrawer, _lineAnalyzer, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    classNames: ['drawer-x-large'],
    session: Ember.inject.service(),
    advancedView: null,
    rightHeaderPartial: 'main/partials/transaction-view',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('shared', {});
    },
    afterModel: function afterModel(_model) {
      this._super.apply(this, arguments);

      this.set('shared.formLines', null);
    },

    /* Mode
     * Can be:
     * - custom
     * - advanced
     * - transfer
     * - deposit
     * - expense
     * - split-transfer
     */
    mode: Ember.computed('model.id', 'advancedView', 'rest', function () {
      var lines = this.get('shared.formLines') || this.get('model.journalEntryLines');
      var inferredMode = _lineAnalyzer.default.type(lines) || 'custom'; // if toggled advanced view, it overrides everything else

      if (this.advancedView) {
        return this.whichAdvancedView(inferredMode, lines);
      } // if editing and they haven't toggled the advanced view, but they want to default to it, then we show that as default
      else if (this.advancedView === null && this.session.get('currentUser.identity.isAdvanced')) {
          this.set('advancedView', true);
          return this.whichAdvancedView(inferredMode, lines);
        } // if opening a new transaction


      if (this.get('rest.mode')) {
        return this.get('rest.mode');
      }

      return inferredMode;
    }),
    whichAdvancedView: function whichAdvancedView(inferredMode, lines) {
      var dates = (0, _array.groupBy)(lines, function (l) {
        return l.date;
      });

      if (inferredMode === 'transfer' && Object.keys(dates).length > 1) {
        return 'split-transfer';
      } else {
        return 'advanced';
      }
    },
    title: Ember.computed('model.id', 'mode', function () {
      var verb = 'Edit';

      if (this.get('model.isNew')) {
        verb = 'Add';
      }

      var mode = 'Transaction';

      switch (this.mode) {
        case 'transfer':
          mode = 'Transfer';
          break;

        case 'deposit':
          mode = 'Deposit';
          break;

        case 'expense':
          mode = 'Expense';
          break;
      }

      return "".concat(verb, " ").concat(mode);
    }),
    showAdvancedView: Ember.computed('mode', function () {
      return this.mode !== 'split-transfer';
    }),
    modeComponent: Ember.computed('mode', function () {
      return "drawers/transaction/".concat(this.mode, "-mode");
    }),
    defaultAccount: Ember.computed('rest.default_account_id', function () {
      return this.store.peekRecord('chartAccount', this.get('rest.default_account_id') || 0);
    }),
    actions: {
      toggleView: function toggleView() {
        this.toggleProperty('advancedView');
      }
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.store.peekAll('journalEntry').compact().forEach(function (record) {
        if (record.get('isNew')) {
          record.deleteRecord();
          record.unloadRecord();
        }
      });
      this.store.peekAll('journalEntryLine').compact().forEach(function (record) {
        if (record.get('isNew')) {
          record.deleteRecord();
          record.unloadRecord();
        }
      });
      this.store.peekAll('tag').compact().forEach(function (record) {
        if (record.get('isNew')) {
          record.deleteRecord();
          record.unloadRecord();
        }
      });
    },
    handleError: function handleError(error) {
      // handle specific errors that aren't handled nicely by individual models
      if (error.errors.find(function (e) {
        return e.source && e.title === 'Need to balance.';
      })) {
        this.overlay.showBanner('error', 'The amounts of bottom lines need to add up to the top amount.');
      } else {
        var firstError = error.errors.find(function (e) {
          return e.title;
        });

        if (firstError) {
          this.overlay.showBanner('error', firstError.title);
        }
      }
    }
  });

  _exports.default = _default;
});