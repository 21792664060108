define("zipbooks/components/drawers/import-invoices-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    large: true,
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    title: 'Import Invoices',
    actions: {
      upload: function upload() {
        var field = this.$('input[type="file"]')[0];
        var file = field.files.length > 0 ? field.files[0] : (field.testingfiles || [])[0];

        if (file) {
          if ((file.name || '').toLowerCase().split('.').pop() === 'csv') {
            var self = this;
            this.set('buttonState', 'saving');
            this.client.upload('invoices', 'import', file, {
              success: function success()
              /*result*/
              {
                self.set('buttonState', 'loaded');
                self.get('overlay').showBanner('success', 'Your invoices are now importing and will display soon.');
                self.get('close')(715);
              },
              failed: function failed(status, json) {
                self.set('buttonState', null);

                if (status === 422 && json.errors) {
                  var lineErrors = json.errors.map(function (error) {
                    return error.title;
                  });
                  var errorHtml = Ember.String.htmlSafe(lineErrors.map(function (s) {
                    return s.stripHTML();
                  }).join('<br>'));
                  self.overlay.showBanner('error', errorHtml, {
                    title: 'Whoops!'
                  });
                } else {
                  self.overlay.showBanner('error', 'Something went wrong. Contact support.', {
                    title: 'Whoops!'
                  });
                }
              },
              error: function error(_error) {
                self.set('buttonState', null);
                self.overlay.showBanner('error', _error, {
                  title: 'Whoops!'
                });
              }
            });
          } else {
            this.overlay.showBanner('error', 'File must be a CSV.', {
              title: 'Whoops!'
            });
          }
        } else {
          this.overlay.showBanner('error', 'You must select a file.', {
            title: 'Oops!'
          });
        }
      }
    }
  });

  _exports.default = _default;
});