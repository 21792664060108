define("zipbooks/utils/poll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = poll;

  function poll(check) {
    var _this = this;

    var interval = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3000;

    if (!check.count) {
      check.count = 0;
    }

    var pollFunc = function pollFunc() {
      check.count += 1;
      check(waitAndCheckAgain, check.count);
    };

    var waitAndCheckAgain = function waitAndCheckAgain() {
      Ember.run.later(_this, function () {
        pollFunc();
      }, interval);
    };

    pollFunc();
  }
});