define("zipbooks/mixins/sessions", ["exports", "zipbooks/utils/plans", "zipbooks/utils/need-details"], function (_exports, _plans, _needDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // chart accounts are fetch eagerly and asynchronously out of normal flow,
    // so its impossible to obsever when they are loaded, so we store a reference
    // to them on session so we can observe their loading
    chartAccounts: [],
    chartAccountsPromise: null,
    account: Ember.computed.alias('currentUser.account'),
    preloadChartAccounts: function preloadChartAccounts() {
      // preload chart accounts
      this.set('chartAccountsPromise', Ember.RSVP.hash({
        uncreated: this.store.query('chartAccount', {
          filter: {
            uncreated: true
          }
        }),
        created: this.store.query('chartAccount', {})
      }));
    },

    /**************
     * Subscription
     **************/
    subscription: Ember.computed('account.subscriptions.@each.id', 'account.subscriptions.@each.{canceledAt,nextBillingDate}', function () {
      return this.getWithDefault('account.subscriptions', []).find(function (s) {
        return ['accounting', 'cpa'].includes(s.get('plan.kind')) && !s.canceledAt;
      });
    }),
    planInfo: Ember.computed('subscription.plan.id', function () {
      var _this = this;

      return _plans.default.find(function (p) {
        return p.id === _this.get('subscription.plan.id');
      });
    }),
    subscriptionFlags: Ember.computed('account.subscriptions.@each.plan', function () {
      return this.getWithDefault('account.subscriptions', []).toArray().concat(this.getWithDefault('account.inheritedSubscriptions', []).toArray()).filter(function (s) {
        return !s.canceledAt;
      }).reduce(function (acc, sub) {
        return acc.concat(sub.flags).concat(sub.get('plan.flags'));
      }, []).uniq();
    }),

    /**************
     * Priviledges
     **************/
    privileges: Ember.computed('account.subscriptions.@each.plan', 'account.subscriptions.@each.isActive', function () {
      return this.getWithDefault('account.subscriptions', []).toArray().concat(this.getWithDefault('account.inheritedSubscriptions', []).toArray()).filter(function (s) {
        return s.get('isActive');
      }).reduce(function (acc, sub) {
        return acc.concat(sub.get('plan.privileges'));
      }, []).uniq();
    }),
    hasPrivilege: function hasPrivilege(privilege) {
      var _this2 = this;

      // this converts a string like `projects && time` into `true && false`
      var evalString = privilege.replace(/([a-z-]+)/gi, function (_match, p) {
        return _this2.privileges.includes(p);
      }); // this evaulates the string (like true && false) as javascript

      return eval(evalString);
    },
    // if they've ever had this plan in the past, they can't trial it
    canHaveTrial: function canHaveTrial(privilege) {
      var details = _needDetails.default[privilege];

      if (details && details.required_plan) {
        return this.getWithDefault('account.subscriptions', []).every(function (s) {
          return s.get('plan.name').toLowerCase() !== details.required_plan;
        });
      }

      return false;
    }
  });

  _exports.default = _default;
});