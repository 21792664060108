define("zipbooks/serializers/recurring-journal-entry", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/embedded-jsonapi-records", "zipbooks/mixins/patch-serializer"], function (_exports, _application, _embeddedJsonapiRecords, _patchSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_embeddedJsonapiRecords.default, _patchSerializer.default, {
    attrs: {
      journalEntryLines: {
        serialize: 'records'
      }
    }
  });

  _exports.default = _default;
});