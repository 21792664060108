define("zipbooks/routes/main/settings", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'main.settings.index') {
        this.transitionTo('main.location', this.get('session.account.primaryLocation.id'));
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'main.settings.index') {
          this.transitionTo('main.location', this.get('session.account.primaryLocation.id'));
        }
      }
    }
  });

  _exports.default = _default;
});