define("zipbooks/components/auto-resize-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.adjust();
    },
    actions: {
      adjust: function adjust(field) {
        this.adjust();
        this.set('value', $(field).val());
        return true;
      }
    },
    adjust: function adjust() {
      var field = this.$('textarea')[0];
      $(field).height($(field)[0].scrollHeight);
    }
  });

  _exports.default = _default;
});