define("zipbooks/templates/components/yodlee-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bbgTffpb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-backdrop\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"yodlee-loading\"],[8],[15,\"partials/loading\",[]],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",false],[12,\"class\",\"modal\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-dialog modal-dialog-centered\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"yodlee-container\"],[10,\"class\",\"m-0 p-0\"],[8],[0,\"\\n      \"],[7,\"form\",true],[10,\"action\",\"https://usyichannelnode.yodleeinteractive.com/authenticate/zipbooksinc/?channelAppName=usyichnnel\"],[10,\"method\",\"post\"],[10,\"id\",\"rsessionPost\"],[10,\"target\",\"yodlee-widget\"],[10,\"style\",\"display: none;\"],[8],[0,\"\\n        \"],[7,\"input\",true],[10,\"name\",\"rsession\"],[10,\"value\",\"\"],[10,\"id\",\"rsession\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n        \"],[7,\"input\",true],[10,\"name\",\"app\"],[10,\"value\",\"10003600\"],[10,\"id\",\"finappId\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n        \"],[7,\"input\",true],[10,\"name\",\"redirectReq\"],[10,\"value\",\"true\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n        \"],[7,\"input\",true],[10,\"name\",\"token\"],[10,\"value\",\"\"],[10,\"id\",\"token\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"externalId\"]]],null,{\"statements\":[[0,\"          \"],[7,\"input\",true],[10,\"name\",\"extraParams\"],[11,\"value\",[29,[\"providerAccountId=\",[24,[\"model\",\"externalId\"]],\"&flow=refresh&callback=https://app.zipbooks.com/yodlee-confirmation\"]]],[10,\"id\",\"extramParams\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"iframe\",true],[10,\"id\",\"yodlee-widget\"],[10,\"name\",\"yodlee-widget\"],[10,\"class\",\"border-0 modal-dialog m-0 p-0\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "zipbooks/templates/components/yodlee-widget.hbs"
    }
  });

  _exports.default = _default;
});