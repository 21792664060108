define("zipbooks/components/dashboard/hours-by-team-member", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/chart-custom-tooltip", "zipbooks/utils/graph-colors"], function (_exports, _testable, _chartCustomTooltip, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _chartCustomTooltip.default, {
    client: Ember.inject.service(),
    teamMembers: Ember.computed('data.@each', function () {
      var colorIndex = 0;
      return this.data.members.map(function (customer) {
        if (customer.customer_name === 'Unassigned') {
          Ember.set(customer, 'backgroundColor', '#BEC5CB');
          Ember.set(customer, 'hoverBackgroundColor', '#BEC5CB');
        } else {
          Ember.set(customer, 'backgroundColor', _graphColors.default.cycle[colorIndex % _graphColors.default.cycle.length]);
          Ember.set(customer, 'hoverBackgroundColor', _graphColors.default.cycle[colorIndex % _graphColors.default.cycle.length]);
          colorIndex += 1;
        }

        return customer;
      });
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var ctx = this.$('canvas');

      if (!ctx) {
        return;
      }

      var labels = [3, 2, 1, 0].map(function (offset) {
        var start = moment().subtract(offset, 'weeks').startOf('week');
        var end = moment().subtract(offset, 'weeks').endOf('week');
        var endFormat = start.format('MMM') === end.format('MMM') ? 'D' : 'MMM D';
        return start.format('MMM D') + ' - ' + end.format(endFormat);
      });
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: this.teamMembers
        },
        options: {
          legend: {
            display: false
          },
          hover: {
            onHover: function onHover(e) {
              var point = this.getElementAtEvent(e);
              if (point.length) e.target.style.cursor = 'pointer';else e.target.style.cursor = 'default';
            }
          },
          tooltips: $.extend(true, {
            callbacks: {
              label: function label(tooltipItem, _data) {
                if (_this.teamMembers.length <= 10) {
                  return [{
                    members: _this.teamMembers.filter(function (t) {
                      return t.data[tooltipItem.index];
                    }),
                    index: tooltipItem.index,
                    datasetIndex: tooltipItem.datasetIndex
                  }];
                } else {
                  return [{
                    members: [_this.teamMembers[tooltipItem.datasetIndex]],
                    index: tooltipItem.index,
                    datasetIndex: tooltipItem.datasetIndex
                  }];
                }
              }
            }
          }, this.customTooltipOpts(this)),
          scales: {
            xAxes: [{
              ticks: {
                fontFamily: 'Karla, sans-serif',
                beginAtZero: true
              },
              gridLines: {
                display: false
              },
              stacked: true
            }],
            yAxes: [{
              ticks: {
                fontFamily: 'Karla, sans-serif',
                beginAtZero: true,
                maxTicksLimit: 6
              },
              stacked: true
            }]
          }
        }
      });
    },
    chartWasClicked: function chartWasClicked(router, context) {
      router.transitionTo('main.time-entries', {
        queryParams: {
          user_id: this.teamMembers[context.datasetIndex].user_id,
          contact_id: null
        }
      });
    }
  });

  _exports.default = _default;
});