define("zipbooks/components/suggest/currency-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/currencies", "zipbooks/utils/decode-entities"], function (_exports, _autoSuggest, _currencies, _decodeEntities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Currency',
    layoutName: 'components/auto-suggest',
    autoSelectFirst: false,
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      return (this.currencies || Object.keys(_currencies.default)).filter(function (currency) {
        if (!search) {
          return true;
        }

        var symbol = (0, _decodeEntities.default)(_currencies.default[currency]);
        var isUSD = currency === 'USD' && search.indexOf(symbol) === 0;
        var matchesSymbol = symbol !== '$' && search.indexOf(symbol) === 0;
        return currency.indexOf(search.toUpperCase()) === 0 || isUSD || matchesSymbol;
      });
    },
    sort: function sort(a, b) {
      return a < b ? -1 : a > b ? 1 : 0;
    },
    labelForResult: function labelForResult(result) {
      return result;
    }
  });

  _exports.default = _default;
});