define("zipbooks/templates/components/quick-stats/most-valuable-projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ho3vYKJq",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"box-header\"],[8],[0,\"Most valuable projects\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"projects\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      \"],[1,[28,\"small-loader\",null,[[\"class\"],[\"my-6\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"projects\",\"isRejected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      Failed to load counts.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"projects\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"d-block box-body border-bottom-1\",\"main.project\",[23,1,[\"project_id\"]]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"font-size-14px font-weight-bold\"],[8],[0,\"\\n          \"],[1,[23,1,[\"project_name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray\"],[8],[0,\"\\n          \"],[1,[23,1,[\"customer_name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"font-weight-bold font-size-16px blue\"],[8],[1,[28,\"format-money\",[[23,1,[\"billed_total\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n        No projects yet.\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/quick-stats/most-valuable-projects.hbs"
    }
  });

  _exports.default = _default;
});