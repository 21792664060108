define("zipbooks/components/reports/balance-sheet-line-value", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/paid-modal-handler", "zipbooks/utils/tag-serialization", "zipbooks/utils/to-decimal"], function (_exports, _testable, _paidModalHandler, _tagSerialization, _toDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, _testable.default, {
    quickLinkExclude: ['Liabilities and Equity total'],
    hideBalance: Ember.computed('balance', 'accountInfo.children.length', function () {
      var zero = new Decimal(0);
      var balance = (0, _toDecimal.default)(this.balance);
      var count = this.get('accountInfo.children.length');
      return balance.comparedTo(zero) === 0 && count > 0;
    }),
    balance: Ember.computed('accountInfo.balances', 'group', function () {
      var _this = this;

      return this.get('accountInfo.balances').find(function (balance) {
        return balance.group === _this.group;
      }).balance;
    }),
    total: Ember.computed('accountInfo.balances', 'group', function () {
      var _this2 = this;

      return this.get('accountInfo.balances').find(function (balance) {
        return balance.group === _this2.group;
      }).total;
    }),
    actions: {
      viewTransactions: function viewTransactions(chartAccountId) {
        var startDate;
        var month;
        var year;
        var group = this.group;
        var endDate = moment(this.endDate);
        var maxEndDate = moment(this.endDate);

        switch (this.groupBy) {
          case 'month':
            startDate = moment(group).startOf('month').format('YYYY-MM-DD');
            endDate = moment(group).endOf('month');
            break;

          case 'quarter':
            month = group.split(' ')[0].charAt(1) * 3 - 2;
            year = group.split(' ')[1];
            group = year + '-' + month;
            startDate = moment(group, 'YYYY-M').startOf('quarter').format('YYYY-MM-DD');
            endDate = moment(group, 'YYYY-M').endOf('quarter');
            break;

          case 'year':
            startDate = moment(group, 'YYYY').startOf('year').format('YYYY-MM-DD');
            endDate = moment(group, 'YYYY').endOf('year');
            break;

          default:
            startDate = null;
        }

        endDate = endDate.isAfter(this.endDate) ? maxEndDate : endDate;

        if (this.permissions.can('accounting.read')) {
          this.router.transitionTo('main.transactions', {
            queryParams: {
              chart_account_id: chartAccountId,
              end_date: endDate.format('YYYY-MM-DD'),
              start_date: startDate,
              tags: _tagSerialization.default.serialize(this.tags),
              confirmed: this.include === 'all' ? '' : 'confirmed'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});