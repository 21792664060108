define("zipbooks/controllers/main/contact", ["exports", "zipbooks/mixins/archive-actionable", "zipbooks/utils/countries", "zipbooks/config/environment"], function (_exports, _archiveActionable, _countries, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_archiveActionable.default, {
    overlay: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    stripeBaseUrl: Ember.computed(function () {
      return _environment.default.isProduction ? 'https://dashboard.stripe.com/customers/' : 'https://dashboard.stripe.com/test/customers/';
    }),
    actions: {
      updatePaymentMethod: function updatePaymentMethod(bank) {
        var _this = this;

        this.client.send('PATCH', 'contacts', "".concat(this.get('model.id'), "/update-payment-method"), {
          data: {
            type: 'contact',
            attributes: {
              'bank-account-id': bank.id
            }
          }
        }).then(function () {
          var customersCalled = _this.get('session.account.settings.customersCalled');

          _this.overlay.showBanner('success', "This ACH bank account ".concat(bank.bankName, " will now be used to charge all auto-billing recurring profiles for this ").concat(customersCalled, "."));
        }, function (resp) {
          _this.overlay.showBanner('error', resp.responseJSON.errors.mapBy('detail').join('<br>'));
        });
      },
      destroy: function destroy() {
        var _this2 = this;

        this.overlay.showDialog('overlays/dialogs/destroy-confirm', 'Are you sure?', {
          type: 'contact'
        }, {
          destroy: function destroy() {
            _this2.urlCache.clear();

            _this2.model.destroyRecord().then(function ()
            /*resp*/
            {
              _this2.overlay.showBanner('success', 'Contact deleted');

              _this2.transitionToRoute('main.contacts');
            });
          }
        });
      },
      destroyPerson: function destroyPerson(person) {
        var _this3 = this;

        this.overlay.showDialog('overlays/dialogs/destroy-confirm', 'Are you sure?', {
          type: 'person'
        }, {
          destroy: function destroy() {
            _this3.urlCache.clear();

            person.destroyRecord().then(function ()
            /*resp*/
            {
              _this3.overlay.showBanner('success', 'Person deleted');
            });
          }
        });
      }
    },
    countryName: Ember.computed('model.country', function () {
      return (0, _countries.getCountryName)(this.get('model.country'));
    })
  });

  _exports.default = _default;
});