define("zipbooks/mixins/paid-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    tagName: '',
    privileged: Ember.computed('needs', 'session.privileges', function () {
      var needs = this.needs;
      return this.session.hasPrivilege(needs);
    }),
    shouldHide: Ember.computed('otherwise', 'privileged', function () {
      return !this.privileged && this.otherwise === 'hide' || this.privileged && this.otherwise === 'show';
    })
  });

  _exports.default = _default;
});