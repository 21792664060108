define("zipbooks/components/overlays/dialogs/square-promotion", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/promotions", "zipbooks/utils/intercom"], function (_exports, _testable, _promotions, _intercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().closest('.modal-dialog').addClass('modal-dialog-square-promotion');
    },
    actions: {
      accept: function accept() {
        var _this = this;

        this.model.settings.set('acceptedSquarePromotion', true);
        this.model.settings.save().then(function () {
          _this.close();

          _intercom.default.update({
            'accepted-square-promotion': true
          });

          _this.router.transitionTo('main.integrations');
        });
        return true;
      },
      decline: function decline() {
        var _this2 = this;

        this.model.settings.set('acceptedSquarePromotion', false);
        this.model.settings.save().then(function () {
          _this2.close();
        });
      }
    },
    acceptLink: Ember.computed(function () {
      var country = this.model.location.country;

      if ((0, _promotions.isInUSA)(country)) {
        return 'https://squareup.com/t/f_partnerships/d_partnerships/p_zipbooks/c_general/o_free_processing/u_email/l_us?route=signup%3Fsignup_token%3D445AD48933';
      } else if ((0, _promotions.isInCanada)(country)) {
        return 'https://squareup.com/t/f_partnerships/d_partnerships/p_zipbooks/c_general/o_free_processing/u_email/l_ca?route=signup%3Fsignup_token%3D0872795AE4';
      }
    })
  });

  _exports.default = _default;
});