define("zipbooks/routes/main/dashboard", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      return this.client.GET('dashboard', 'summary');
    }
  });

  _exports.default = _default;
});