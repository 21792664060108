define("zipbooks/router", ["exports", "zipbooks/config/environment", "zipbooks/mixins/router"], function (_exports, _environment, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Leave formatting alone in this file for easier diffing when upgrading Ember
  var Router = Ember.Router.extend(_router.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('register');
    this.route('signup');
    this.route('verify');
    this.route('yodlee-confirmation');
    this.route('main', {
      path: '/'
    }, function () {
      this.route('dashboard', {
        path: '/'
      });
      this.route('accountant', function () {
        this.route('dashboard');
        this.route('clients', function () {
          this.route('category-templates');
          this.route('category-template', {
            path: 'category-templates/:id'
          });
          this.route('defaults');
        });
      }); // transactions

      this.route('transactions');
      this.route('recurring-transactions');
      this.route('transaction-sheet');
      this.route('bank-accounts');
      this.route('reconciliations', {
        path: 'reconciliation'
      });
      this.route('reconciliation', {
        path: 'reconciliation/:id'
      });
      this.route('chart-accounts', {
        path: 'categories'
      }); // reports

      this.route('reports', function () {
        this.route('accounts-receivable');
        this.route('balance-sheet');
        this.route('billable-expenses');
        this.route('cash-flow-statement');
        this.route('client-report');
        this.route('contractor-summary', {
          path: '1099-summary'
        });
        this.route('credit-card-processing');
        this.route('expense-summary');
        this.route('general-journal');
        this.route('general-ledger');
        this.route('income-statement-percent-of-sales');
        this.route('income-statement');
        this.route('invoice-summary');
        this.route('sales-summary');
        this.route('taxes-invoiced');
        this.route('time-tracking');
        this.route('transaction-tracking');
        this.route('trial-balance');
        this.route('user-summary');
      });
      this.route('contacts');
      this.route('contact', {
        path: 'contacts/:id'
      }); // invoices

      this.route('invoices');
      this.route('invoice', {
        path: 'invoices/:id'
      });
      this.route('estimates');
      this.route('estimate', {
        path: 'estimates/:id'
      });
      this.route('recurring-profiles');
      this.route('recurring-profile', {
        path: 'recurring-profiles/:id'
      });
      this.route('reminders'); // billing

      this.route('bills');
      this.route('bill', {
        path: 'bills/:id'
      });
      this.route('payroll'); // tracking

      this.route('projects');
      this.route('project', {
        path: 'projects/:id'
      });
      this.route('time-entries');
      this.route('time-and-pay');
      this.route('items');
      this.route('labels', {
        path: 'tags'
      }); // account

      this.route('settings', function () {
        this.route('company');
        this.route('delete');
        this.route('payments');
        this.route('preferences');
        this.route('subscription');
        this.route('team');
        this.route('admin');
      });
      this.route('documents');
      this.route('locations');
      this.route('location', {
        path: 'locations/:id'
      }); // misc

      this.route('integrations', function () {
        this.route('google-drive', {
          path: 'drive/invoice'
        });
      });
      this.route('messaging.show', {
        path: '/m/:token'
      });
      this.route('accept-payments');
    });
    this.route('forgot_password');
    this.route('reset_password', {
      path: '/reset_password/:token'
    });
    this.route('time-tracker');
    this.route('not-found', {
      path: '/*path'
    });
    this.route('maintenance');
  });
  var _default = Router;
  _exports.default = _default;
});