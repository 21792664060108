define("zipbooks/controllers/main/reports/taxes-invoiced", ["exports", "zipbooks/mixins/reports/date-error"], function (_exports, _dateError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateError.default, {
    queryParams: ['start_date', 'end_date'],
    start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD')
  });

  _exports.default = _default;
});