define("zipbooks/utils/tag-serialization", ["exports", "zipbooks/utils/base-64"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    serialize: function serialize(tags) {
      return tags.map(function (tagObject) {
        var properties = [Ember.get(tagObject, 'id'), (0, _base.encode)(Ember.get(tagObject, 'name')), Ember.get(tagObject, 'taggableType'), Ember.get(tagObject, 'kind'), moment(Ember.get(tagObject, 'createdAt')).format('YYYY-MM-DD')];
        return properties.join('.');
      }).join('~');
    },
    deserialize: function deserialize(string, func) {
      if (Ember.typeOf(string) === 'string') {
        return string.split('~').map(function (serialized) {
          var parts = serialized.split('.');
          var name;

          try {
            name = (0, _base.decode)(parts[1]);
          } catch (_err) {
            name = parts[1];
          }

          if (parts.length === 5) {
            var obj = {
              id: parts[0],
              name: name,
              taggableType: parts[2],
              kind: parts[3],
              createdAt: Date.parse(parts[4])
            };

            if (func) {
              obj = func(obj);
            }

            return obj;
          }
        }).compact();
      }

      return [];
    },
    serializeModels: function serializeModels(models) {
      var names = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return this.serialize(models.map(function (model, idx) {
        return Ember.Object.create({
          id: model.get('id'),
          name: model.get(names[idx] || 'name'),
          createdAt: moment().add(idx, 'minutes'),
          taggableType: model.get('modelType').capitalize()
        });
      }));
    }
  };
  _exports.default = _default;
});