define("zipbooks/components/content-nav-fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultPosition: 94,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      $(window).on('resize.content-nav-fixed', function () {
        _this.adjustFixed();
      });
      $(window).on('scroll.content-nav-fixed', function () {
        _this.adjustFixed();
      });
      this.notificationCenter.addObserver(this, 'tags-input-changed', function () {
        _this.adjustFixed();
      });
      this.adjustFixed();
    },
    notificationCenter: Ember.inject.service(),
    classNames: ['content-nav-container'],
    adjustFixed: function adjustFixed() {
      var eTop = this.$().offset().top; //get the offset top of the element

      var position = eTop - $(window).scrollTop(); //position of the ele w.r.t window

      if (position <= this.defaultPosition && this.$('.content-nav-fixed').hasClass('content-nav-fixed-unfix')) {
        this.$('.content-nav-fixed').removeClass('content-nav-fixed-unfix');
      } else if (position > this.defaultPosition && !this.$('.content-nav-fixed').hasClass('content-nav-fixed-unfix')) {
        this.$('.content-nav-fixed').addClass('content-nav-fixed-unfix');
      } // If we're doing special fixed stuff (which we do at lg breakpoint and above), then adjust size of the fixed containers.  If not, leave it alone


      if ($(window).width() >= 768) {
        this.$().height($('.content-nav-fixed').height());
        $('.content-nav-fixed').width($('main').width() + 20);
      } else {
        this.$().css('height', 'auto');
        $('.content-nav-fixed').css('width', 'auto');
      }
    },
    didRefreshingChanged: Ember.observer('isRefreshing', function () {
      this.adjustFixed();
    }),
    willDestroyElement: function willDestroyElement() {
      $(window).off('.content-nav-fixed');
      this.notificationCenter.removeObserver(this, 'tags-input-changed');

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});