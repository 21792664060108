define("zipbooks/components/mega-menu", ["exports", "zipbooks/utils/redirect"], function (_exports, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['megamenu'],
    session: Ember.inject.service(),
    actions: {
      logout: function logout() {
        // we do this to make sure there is a clean slate
        (0, _redirect.default)('/login');
      }
    }
  });

  _exports.default = _default;
});