define("zipbooks/components/gusto/choose-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    companyOptions: Ember.computed('companies.@each', function () {
      return this.companies.map(function (company) {
        return {
          value: Ember.Object.create({
            company: company
          })
        };
      });
    })
  });

  _exports.default = _default;
});