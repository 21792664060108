define("zipbooks/templates/components/pros/gallery-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XbQXZYWo",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"custom-select\",null,[[\"value\",\"title\",\"placeholder\",\"class\",\"options\"],[[24,[\"category\"]],\"Category\",\"All\",\"mb-2\",[28,\"array\",[[28,\"hash\",null,[[\"value\",\"title\"],[null,\"All\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"LOGO\",\"Logo\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"EXTERIOR\",\"Exterior\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"INTERIOR\",\"Interior\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"PRODUCT\",\"Product\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"AT_WORK\",\"At Work\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"FOOD_AND_DRINK\",\"Food And Drink\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"MENU\",\"Menu\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"COMMON_AREA\",\"Common Area\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"ROOMS\",\"Rooms\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"TEAMS\",\"Teams\"]]],[28,\"hash\",null,[[\"value\",\"title\"],[\"ADDITIONAL\",\"Additional\"]]]],null]]]],false],[0,\"\\n\"],[1,[28,\"multi-file-upload\",null,[[\"title\",\"cloudFiles\",\"class\",\"testId\",\"category\",\"limit\",\"didUpload\",\"instructions\",\"maxSize\"],[\"Gallery Photos\",[24,[\"cloudFilesInCategory\"]],\"mb-5\",\"image-gallery\",[24,[\"category\"]],[28,\"mapping\",[[24,[\"category\"]]],[[\"LOGO\",\"COVER\",\"default\"],[1,1,100]]],[28,\"action\",[[23,0,[]],\"didUpload\"],null],\"Drag images of your business here.\",5242880]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/pros/gallery-images.hbs"
    }
  });

  _exports.default = _default;
});