define("zipbooks/routes/main/location", ["exports", "zipbooks/mixins/integrations-route", "zipbooks/mixins/highlightable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _integrationsRoute, _highlightableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_integrationsRoute.default, _highlightableRoute.default, _permissionRedirect.default, {
    queryParams: {
      action: {}
    },
    highlightClass: 'onboard-highlighted',
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        account: Ember.RSVP.resolve(this.session.get('account')),
        location: this.store.findRecord('location', params.id, {
          reload: true
        }),
        integrations: this.integrationsPromise()
      });
    },
    resetController: function resetController(controller, isExiting, _transition) {
      if (isExiting) {
        controller.get('model.location').rollbackAttributes();
        controller.get('model.account').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});