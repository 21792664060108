define("zipbooks/routes/main/integrations/google-drive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    model: function model(params) {
      return this.client.GET('integrations/drive/invoice?state=' + params.state).then(function (result) {
        if (result.id) {
          return result.id;
        } else {
          return Ember.RSVP.reject();
        }
      });
    },
    actions: {
      error: function error() {
        // most likely error is the invoice wasn't found because the user is logged into the wrong account
        // either way they were looking for an invoice so let's give them the invoices
        this.transitionTo('main.invoices');
        this.overlay.showBanner('error', "Sorry, we couldn't find your invoice. Make sure you're logged in to the correct account.", {
          delay: 4500
        });
      }
    },
    afterModel: function afterModel(invoiceId) {
      this.transitionTo('main.invoice', invoiceId);
    }
  });

  _exports.default = _default;
});