define("zipbooks/components/list-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['no-select', 'form-check-label'],
    attributeBindings: ['unselectable'],
    unselectable: 'on',
    testId: 'list-check',
    actions: {
      clicked: function clicked(model, event) {
        this.clicked(model, event);
      }
    }
  });

  _exports.default = _default;
});