define("zipbooks/routes/main", ["exports", "zipbooks/utils/intercom", "zipbooks/utils/storage", "zipbooks/utils/error-reporter"], function (_exports, _intercom, _storage, _errorReporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    client: Ember.inject.service(),
    notificationStore: Ember.inject.service(),
    actions: {
      didTransition: function didTransition() {
        this.set('controller.friendlierError', null); // if their accounting plan is `overdue`, redirect them
        // to subscription settings page

        if (this.get('session.subscription.isOverdue')) {
          this.transitionTo('main.settings.subscription');
        }
      },
      error: function error(transition
      /*, originRoute*/
      ) {
        if (!transition) {
          return true;
        } // handle an error response


        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = (transition.errors || [])[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var error = _step.value;

            if (error.status === '500' || error.status === '422') {
              this.set('controller.friendlierError', "Oops! Our server returned a bad response. We've been notified and will get it fixed!");
              return false;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return true;
      }
    },
    beforeModel: function beforeModel(transition) {
      if (transition.to.queryParams && transition.to.queryParams.error) {
        this.overlay.showBanner('error', transition.to.queryParams.error, {
          delay: 5000
        });
      } // they need to be main to access anything under this route


      if (!this.get('session.token')) {
        this.session.rememberLoginRedirectParams();
        this.transitionTo('login', {
          queryParams: transition.to.queryParams
        });
      }
    },
    model: function model() {
      return this.session.fetchLoggedInUser();
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      _intercom.default.setup(model);

      _errorReporter.default.setup(model, this.session); // if the user has a token we will set the cookie so exports work


      var token = _storage.default.get('token');

      if (token) _storage.default.set('token', token, !_storage.default.get('remember')); // preload account logo

      var logoUrl = model.get('account.logoCloudFile.downloadUrl');

      if (logoUrl) {
        var logo = new Image();
        logo.src = logoUrl;
      }

      if (this.permissions.any(['accounting.read', 'reports.read'])) {
        this.session.preloadChartAccounts();
      }

      if (this.permissions.any(['account.read'])) {
        this.client.GET('credit-cards', 'default').then(function (cc) {
          if (cc && cc.data.id) {
            _this.session.account.set('hasCreditCard', true);

            _this.session.account.set('stripeCardId', cc.data.id);
          } else {
            _this.session.account.set('hasCreditCard', false);
          }
        });
      }

      $('body').on('keydown', function (e) {
        if ((e.ctrlKey || e.metaKey) && e.which === 74) {
          $('#search-all input').focus();
          return false;
        }
      }); // bill.com making users accept tos
      // the business logic here translates to the following code: bill.com(divvy(zipbooks()))

      if (!model.get('identity.lastAcceptedTos')) {
        this.overlay.showDialog('overlays/dialogs/accept-tos', null, {
          customHeader: true,
          identity: model.get('identity')
        });
      } // make them choose a twilio number


      if (this.session.getWithDefault('account.subscriptions', []).any(function (s) {
        return s.get('plan.id') === '303' && !s.canceledAt;
      }) && !this.session.get('account.settings.messagingPhone') && !this.overlay.isShowing('overlays/dialogs/choose-number-modal')) {
        this.overlay.showDialog('overlays/dialogs/choose-number-modal', '', {
          customHeader: true
        });
      } // if (this.session.hasPrivilege('accountant') && transition.targetName === 'main.dashboard') {


      if (this.get('session.subscription.plan.kind') === 'cpa' && transition.targetName === 'main.dashboard') {
        this.transitionTo('main.accountant.dashboard');
      }
    },
    setupController: function setupController(controller, _model, _transition) {
      this._super.apply(this, arguments); // load notifications


      this.notificationStore.query('notification', {}).then(function (results) {
        controller.set('notifications', results.toArray());
        controller.set('unReadNotificationsCount', results.get('meta.unread'));
      });

      if (this.session.hasPrivilege('messaging')) {
        controller.setupMessengerWebsocket();
      }
    }
  });

  _exports.default = _default;
});