define("zipbooks/utils/initials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initials;

  function initials(string) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var chars = (string || '').replace(/[^a-z0-9 ]/gi, '').match(/(^| )./g);

    if (chars) {
      chars = chars.map(function (m) {
        return m.trim();
      });

      if (opts.max) {
        chars = chars.slice(0, opts.max);
      }

      if (chars.length > 1) {
        chars = [chars[0], chars[chars.length - 1]];
      }

      return chars.map(function (i) {
        return i.toUpperCase();
      }).join('');
    } else {
      return (opts.fallback || '').charAt(0).toUpperCase();
    }
  }
});