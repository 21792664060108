define("zipbooks/utils/parse-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseDuration;
  var normalized = {
    hours: 'hours',
    hrs: 'hours',
    hr: 'hours',
    h: 'hours',
    minutes: 'minutes',
    mins: 'minutes',
    min: 'minutes',
    m: 'minutes',
    seconds: 'seconds',
    secs: 'seconds',
    sec: 'seconds',
    s: 'seconds' // returns a moment.duration

  };

  function parseDuration(string) {
    // if its just a number, the its assumed to be hours
    if (string.match(/^[\d.]+$/)) {
      return moment.duration(string * 1, 'hours');
    }

    var duration; // matches 3h2m, 3h 2m, 300h 266m 6s

    string.replace(/(([\d.]{1,})( |)(hours|minutes|seconds|hrs|hr|mins|min|secs|sec|h|m|s]))/g, function (_a, _b, value, _space, unit) {
      unit = normalized[unit];
      duration = duration || moment.duration();
      duration.add(value * 1, unit);
    }); // if they used colons, we assume HH:mm:ss format and let moment parse it

    if (!duration) {
      // clean the string so it only has characters we accept
      string = string.replace(/[^0-9.:hms]/g, '');
      duration = moment.duration(string);
    }

    return duration;
  }
});