define("zipbooks/transforms/integer", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return parseInt(serialized || 0);
    },
    serialize: function serialize(deserialized) {
      return parseInt(deserialized || 0);
    }
  });

  _exports.default = _default;
});