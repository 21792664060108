define("zipbooks/utils/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Object.extend({
    session: Ember.inject.service(),
    scopes: ['account', 'users', 'accounting', 'contacts', 'invoices', 'estimates', 'projects', 'time_tracking', 'reports', 'payroll', 'banks'],
    hasReadOnly: ['accounting'],
    can: function can(permission) {
      var _permission$split = permission.split('.'),
          _permission$split2 = _slicedToArray(_permission$split, 2),
          scope = _permission$split2[0],
          action = _permission$split2[1];

      var level = this.getWithDefault("session.currentUser.permissionsMap.".concat(scope), 0);

      if (action === 'all') {
        return level == 9;
      } else if (action === 'read') {
        return level >= 1;
      } else {
        return false;
      }
    },
    any: function any(permissions) {
      var _this = this;

      return permissions.any(function (p) {
        return _this.can(p);
      });
    },
    all: function all(permissions) {
      var _this2 = this;

      return permissions.every(function (p) {
        return _this2.can(p);
      });
    },
    first: function first(permissions) {
      var _this3 = this;

      return permissions.find(function (p) {
        return _this3.can(p);
      });
    }
  });

  _exports.default = _default;
});