define("zipbooks/components/drawers/recurring-journal-entry-form", ["exports", "zipbooks/components/drawers/journal-entry-form"], function (_exports, _journalEntryForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _journalEntryForm.default.extend({
    title: 'Recurring Transaction',
    showAdvancedView: false,
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.store.peekAll('recurringJournalEntry').compact().forEach(function (record) {
        if (record.get('isNew')) {
          record.deleteRecord();
          record.unloadRecord();
        }
      });
      this.store.peekAll('recurringJournalEntryLine').compact().forEach(function (record) {
        if (record.get('isNew')) {
          record.deleteRecord();
          record.unloadRecord();
        }
      });
      this.store.peekAll('tag').compact().forEach(function (record) {
        if (record.get('isNew')) {
          record.deleteRecord();
          record.unloadRecord();
        }
      });
    }
  });

  _exports.default = _default;
});