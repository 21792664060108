define("zipbooks/components/import/column-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // if any input headers match output headers, we set the mapping automatically for them


      this.inputHeaders.forEach(function (input) {
        if (_this.outputHeaders.mapBy('value').includes(input)) {
          var map = _this.mapping.map;
          map[input] = input;

          _this.mapping.set('map', map);

          _this.mapping.notifyPropertyChange('map');
        }
      });
    }
  });

  _exports.default = _default;
});