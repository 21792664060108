define("zipbooks/components/overlays/popovers/line-form", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/add-journal-entry-line-tags"], function (_exports, _testable, _addJournalEntryLineTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _addJournalEntryLineTags.default, {
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    actions: {
      setChartAccount: function setChartAccount(chartAccount) {
        this.set('model.line.chartAccount', chartAccount);
      },
      setBillToContact: function setBillToContact(model) {
        this.set('model.line.billToContact', model);
      },
      done: function done() {
        this.close();
      }
    },
    linesToBeTagged: function linesToBeTagged() {
      return [this.get('model.line')];
    },
    tagWasAdded: function tagWasAdded()
    /*tag*/
    {},
    tagWasRemoved: function tagWasRemoved()
    /*tag*/
    {}
  });

  _exports.default = _default;
});