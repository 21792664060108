define("zipbooks/models/journal-entry", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/mixins/cloud-fileable", "zipbooks/mixins/notificationable", "zipbooks/mixins/journal-entry/sorted-lines", "zipbooks/utils/overridable-computed"], function (_exports, _model, _model2, _cloudFileable, _notificationable, _sortedLines, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_cloudFileable.default, _notificationable.default, _sortedLines.default, {
    session: Ember.inject.service(),
    currencyCode: (0, _model.attr)(),
    isConfirmed: (0, _model.attr)('boolean'),
    isReconciled: (0, _model.attr)(),
    isRollup: (0, _model.attr)(),
    isSystem: (0, _model.attr)(),
    name: (0, _model.attr)(),
    note: (0, _model.attr)(),
    kind: (0, _model.attr)(),
    autoConfirm: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    journalEntryLines: (0, _model.hasMany)('journalEntryLine'),
    integrationObject: (0, _model.belongsTo)('integrationObject'),
    comment: (0, _model.belongsTo)('journalEntryComment'),
    recurringJournalEntry: (0, _model.belongsTo)('recurringJournalEntry'),
    kindSortedLines: Ember.computed('journalEntryLines.@each', function () {
      return this.journalEntryLines.sortBy('kind').reverse();
    }),
    expandedNote: Ember.computed('note', 'journalEntryLines.@each', function () {
      var notes = [this.note];
      return notes.concat(this.journalEntryLines.map(function (line) {
        return line.get('importedTransaction.memo');
      })).filter(function (n) {
        return !!n;
      }).join(' | ');
    }),
    primaryLine: Ember.computed('sortedLines.@each.chartAccount', function () {
      return this.get('sortedLines.firstObject');
    }),
    lineTags: Ember.computed('journalEntryLines.@each.computedTags', function () {
      return this.journalEntryLines.reduce(function (a, v) {
        return a.concat(v.get('computedTags').toArray());
      }, []).uniqBy('name');
    }),
    date: (0, _overridableComputed.default)('date', 'journalEntryLines.@each.date', function () {
      return this.journalEntryLines.map(function (line) {
        return line.get('date');
      }).sort()[0];
    }),
    locked: Ember.computed('date', 'session.account.settings.lockDate', function () {
      return this.date <= this.session.get('account.settings.lockDate');
    }),
    breakingLines: function breakingLines() {
      var _this = this;

      return this.journalEntryLines // we only care about lines that had interesting changes (amount, date, chart_account_id)
      .filter(function (line) {
        if (line.changedAttributes().amount) {
          return true;
        } else if (line.changedAttributes().date) {
          return true;
        } else if (line.changedAttributes().chartAccount) {
          return true; // if isConfirmed changed, all the lines are at risk of breaking
        } else if (_this.changedAttributes().isConfirmed) {
          return true;
        }

        return false;
      }) // we only care about lines with reconcilable chart accounts
      .filter(function (line) {
        return line.get('chartAccount.isReconcilable');
      });
    },
    reconciliationBreakingDate: function reconciliationBreakingDate() {
      return this.breakingLines().map(function (line) {
        if (line.changedAttributes().date) {
          return line.changedAttributes().date.sort()[0];
        } else {
          return line.get('date');
        }
      }).sort()[0];
    },
    hasChangesThatBreakReconciliation: function hasChangesThatBreakReconciliation() {
      return this.breakingLines() // for those lines, if the date (old or new) is before its bank accounts reconciledTo, then it'll
      // break and we need to warn them they are 'bout to create themselves a headache. They should use
      // the POST /headache endpoint for such nonsense.
      .any(function (line) {
        var reconciledTo = line.get('chartAccount.reconciledTo');
        var dateChange = line.changedAttributes().date;

        if (dateChange && dateChange[0] <= reconciledTo) {
          return true;
        } else if (dateChange && dateChange[1] <= reconciledTo) {
          return true;
        } else if (line.get('date') && line.get('date') <= reconciledTo) {
          return true;
        }

        return false;
      });
    }
  });

  _exports.default = _default;
});