define("zipbooks/components/growth/external-location-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // set this when we start so we know if when it was opened there were already locations associated
      // if there were, we do not want to allow them to change already associated locations


      this.set('alreadyAssociated', this.get('model.operation.alreadyAssociated'));
    },
    operationOptions: Ember.computed('model.locations.all.@each', 'model.externalLocations.@each', 'excludedLocationIds.@each', function () {
      var _this = this;

      return this.locations.all.filter(function (l) {
        return l.id === _this.get('model.operation.location.id') || !_this.excludedLocationIds.includes(l.id);
      }).map(function (location) {
        return {
          value: Ember.Object.create({
            operationKey: 'associate',
            location: location
          })
        };
      }).concat([{
        value: Ember.Object.create({
          operationKey: 'dont-manage'
        })
      }, {
        value: Ember.Object.create({
          operationKey: 'add-location'
        })
      }]);
    })
  });

  _exports.default = _default;
});