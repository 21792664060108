define("zipbooks/components/drawers/add-client-form", ["exports", "zipbooks/components/drawers/add-account-form", "zipbooks/mixins/accountants/manage-team", "zipbooks/helpers/oauth-host", "zipbooks/helpers/current-url"], function (_exports, _addAccountForm, _manageTeam, _oauthHost, _currentUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _addAccountForm.default.extend(_manageTeam.default, {
    session: Ember.inject.service(),
    errors: {},
    title: Ember.computed('rest.linkedContactId', function () {
      return this.rest.linkedContactId ? 'Add license' : 'Add client';
    }),
    // this requires the mybookkeepingreports.com domain to be active and working. It appears to have broken
    // when we migrated from gcloud to AWS under Divvy. We got one complaint in 1.5 years, so I don't think this is
    // used actively. If we ever wanted to resurrect and prioritize this, we would porbably need to use a new domain and
    // a new zippooks developer account
    // rightHeaderPartial: 'main/partials/add-from-quickbooks',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.rest.withLicense) {
        this.set('withLicense', this.rest.withLicense);
      }

      if (this.rest.linkedContactId) {
        this.store.findRecord('contact', this.rest.linkedContactId).then(function (contact) {
          return _this.setContact(contact);
        });
      } else {
        this.setContact(this.store.createRecord('contact'));
      }

      this.store.findRecord('account-template', this.session.get('account.id')).then(function (template) {
        _this.set('template', template);

        _this.set('templateGroup', template.get('templateGroup'));
      });
    },
    setContact: function setContact(contact) {
      // if they have an email, we want to hide the email box. If they don't, we don't want to hide the email
      // box as soon as they start typing in it
      this.set('contactEmail', contact.email);
      this.set('contact', contact);
    },
    actions: {
      save: function save() {
        var _this2 = this;

        if (this.withLicense) {
          this.createLicense();
        } else {
          this.contact.save().then(function () {
            _this2.send('close');

            _this2.notificationCenter.notify('client-edited');
          });
        }
      },
      selectCountry: function selectCountry(country) {
        this.set('contact.country', country && country.id);
      },
      addFromQuickbooks: function addFromQuickbooks() {
        window.location = "".concat((0, _oauthHost.oauthHost)({}, {
          host: 'mybookkeepingreports'
        }), "/v2/auth-link/intuit_mbr?action=add-client&callback-url=").concat((0, _currentUrl.currentUrl)({}, {
          encode: true
        }), "&token=").concat(this.session.token);
      },
      chooseGroup: function chooseGroup(group) {
        this.set('templateGroup', group);
      }
    },
    createLicense: function createLicense() {
      var _this3 = this;

      if (Ember.isBlank(this.contact.name)) {
        this.set('contact.errors.name', [{
          message: "Name can't be blank"
        }]);
      } else {
        this.set('contact.errors.name', []);
      }

      this.set('buttonState', 'saving');
      this.contact.save().then(function (contact) {
        var body = {
          data: {
            type: 'account',
            attributes: {
              email: contact.email,
              name: contact.name || contact.displayName
            },
            relationships: {
              subscriptions: {
                data: [{
                  type: 'subscription',
                  relationships: {
                    plan: {
                      data: {
                        type: 'plan',
                        id: '301'
                      }
                    }
                  }
                }]
              },
              'linked-contact': {
                data: {
                  id: contact.id,
                  type: 'contact'
                }
              }
            }
          }
        };

        if (_this3.templateGroup) {
          body.data.relationships['chart-account-template-group'] = {
            data: {
              id: _this3.templateGroup.get('id'),
              type: 'chart-account-template-group'
            }
          };
        }

        _this3.client.POST('team-accounts', null, body).then(function (resp) {
          _this3.savePreferences(resp.meta.token, resp.data.relationships.settings.data.id);

          _this3.saveUsers(_this3.selectedUsers, resp.data.id).then(function () {
            return _this3.finishSave(resp);
          });
        }, function (jqXHR) {
          _this3.set('buttonState', 'invalid');

          _this3.overlay.showBanner('error', jqXHR.responseJSON.errors.mapBy('detail').join(', '));
        });
      }, function (_jqXHR) {
        _this3.set('buttonState', 'invalid');
      });
    },
    savePreferences: function savePreferences(token, settingsId) {
      var showChartCode = this.template.get('showChartCode');

      if (this.templateGroup) {
        showChartCode = this.templateGroup.get('showChartCode');
      }

      var body = {
        data: {
          type: 'account-settings',
          attributes: {
            'auto-confirm': this.template.get('autoConfirm'),
            'default-basis': this.template.get('defaultBasis'),
            'show-chart-code': showChartCode,
            'show-chart-class': this.template.get('showChartClass'),
            'reports-default-show-unconfirmed': this.template.get('reportsDefaultShowUnconfirmed')
          }
        }
      };
      this.client.PATCH('account-settings', settingsId, body, null, {
        token: token
      });
    },
    markUsersAsSelected: function markUsersAsSelected(users) {
      var _this4 = this;

      users.forEach(function (user) {
        return _this4.selectedUsers.addObject(user);
      });
    }
  });

  _exports.default = _default;
});