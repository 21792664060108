define("zipbooks/components/index-row-content/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _mapsLibLoaded: Ember.observer('mapsLib', 'location.{city,state,postalCode}', function () {
      this.tryLoadingMaps();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('mapsLib.Geocoder')) {
        this.tryLoadingMaps();
      }
    },
    hasAddress: Ember.computed('location.{city,state,postalCode}', function () {
      var _this = this;

      return ['city', 'state', 'postalCode'].every(function (k) {
        return !Ember.isBlank(_this.get("location.".concat(k)));
      });
    }),
    canDelete: Ember.computed('location.{isPrimary,externalLocation.integrationObject.id}', function () {
      return this.permissions.can('accounting.all') && !location.isPrimary && !(location.externalLocation && location.externalLocation.integrationObject.id);
    }),
    actions: {
      didSelect: function didSelect() {
        this.didSelect.apply(this, arguments);
      },
      destroy: function destroy() {
        this.destroy.apply(this, arguments);
      }
    },
    connectedIntegrations: Ember.computed('integrations.@each', function () {
      var _this2 = this;

      return this.integrations.map(function (i) {
        return {
          integration: i,
          externalLocations: i.get('externalLocations.length') > 0 ? i.externalLocations.filter(function (loc) {
            return loc.get('location.id') === _this2.location.id;
          }) : []
        };
      }).filter(function (i) {
        return i.externalLocations.length > 0;
      });
    }),
    tryLoadingMaps: function tryLoadingMaps() {
      var _this3 = this;

      var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var address = ['address_1', 'city', 'state', 'postalCode'].map(function (k) {
        return _this3.get("location.".concat(k));
      }).compact().join(',');
      var geocoder = new this.mapsLib.Geocoder();
      geocoder.geocode({
        address: address
      }, function (results, status) {
        if (status === 'OVER_QUERY_LIMIT' && count < 10) {
          Ember.run.later(_this3, function () {
            return _this3.tryLoadingMaps(count + 1);
          }, 1000);
        } else if (status == _this3.mapsLib.GeocoderStatus.OK && status != _this3.mapsLib.GeocoderStatus.ZERO_RESULTS && _this3.$('.js-map')) {
          var coords = results[0].geometry.location;
          var map = new _this3.mapsLib.Map(_this3.$('.js-map')[0], {
            center: coords,
            zoom: 15,
            fullscreenControl: false,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false
          });
          new _this3.mapsLib.Marker({
            position: coords,
            map: map,
            title: 'Hello World!'
          });
        }
      });
    }
  });

  _exports.default = _default;
});