define("zipbooks/components/index-row-content/integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    visible: true,
    classNameBindings: ['visible::hidden', 'large:col-sm-12:col-sm-6'],
    hasCustomTemplate: Ember.computed('id', function () {
      return Ember.getOwner(this).hasRegistration("component:integration-box/".concat(this.id));
    })
  });

  _exports.default = _default;
});