define("zipbooks/models/action", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    action: (0, _model.attr)(),
    isRead: (0, _model.attr)('boolean'),
    groupedIds: (0, _model.attr)(),
    extra: (0, _model.attr)(),
    actionable: (0, _model.belongsTo)('actionable', {
      polymorphic: true
    }),
    actorable: (0, _model.belongsTo)('actorable', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});