define("zipbooks/templates/components/drawers/-manage-team-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jtYwy0fa",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row mb-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto font-weight-bold\"],[8],[0,\"\\n      Assigned team members\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col text-right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"allAreSelected\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"blue\"],[3,\"action\",[[23,0,[]],\"deselectAll\"]],[8],[0,\"Deselect all\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"blue\"],[3,\"action\",[[23,0,[]],\"selectAll\"]],[8],[0,\"Select all\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"users\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"manage-user\",null,[[\"user\",\"selectedUsers\",\"disabled\"],[[23,1,[]],[24,[\"selectedUsers\"]],[23,1,[\"owner\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"action\",\"state\",\"title\",\"class\",\"testId\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[24,[\"buttonState\"]],\"Save\",\"btn btn-full btn-depressable btn-purple\",\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-manage-team-form.hbs"
    }
  });

  _exports.default = _default;
});