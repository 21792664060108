define("zipbooks/components/gusto/associate-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchEmployeesAndUsers();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('reference.userAssociator', this);
    },
    fetchEmployeesAndUsers: function fetchEmployeesAndUsers() {
      var _this = this;

      this.set('loading', true);
      this.store.cachedQuery('user', {
        filter: {
          archived: false
        },
        sort: 'display_name',
        include: 'identity.avatar_cloud_file,integration_object'
      }).then(function (users) {
        var operationOptions = [{
          value: Ember.Object.create({
            operationKey: 'add-user'
          })
        }, {
          value: Ember.Object.create({
            operationKey: 'dont-manage'
          })
        }].concat(users.filter(function (u) {
          return !u.connectedWithGusto;
        }).map(function (user) {
          return {
            value: Ember.Object.create({
              operationKey: 'associate',
              user: user
            })
          };
        }));

        _this.set('operationOptions', operationOptions);

        _this.client.GET('integrations/gusto/employees').then(function (resp) {
          var employees = resp.map(function (employee) {
            var hasMatch = users.find(function (u) {
              return u.id === "".concat(employee.recommended_user_id) && !u.connectedWithGusto;
            });

            if (employee.recommended_user_id && hasMatch) {
              return {
                operation: Ember.Object.create({
                  operationKey: 'associate',
                  user: _this.store.peekRecord('user', employee.recommended_user_id)
                }),
                employee: employee
              };
            } else {
              return {
                operation: Ember.Object.create({}),
                employee: employee
              };
            }
          });

          _this.set('employees', employees);

          _this.set('loading', false);
        });
      });
    },
    _operations: Ember.observer('employees.@each.operation', function () {
      this.set('selectedOperations', this.employees.mapBy('operation').filter(function (o) {
        return o.operationKey === 'associate';
      }));
    }),
    selectedUserIds: Ember.computed('selectedOperations.@each.{user,operationKey}', function () {
      return this.selectedOperations.map(function (o) {
        return o.user.id;
      });
    }),
    save: function save() {
      var _this2 = this;

      var readyEmployees = this.employees.map(function (employee) {
        var operation = employee.operation && employee.operation.operationKey;

        if (operation === 'associate' || operation === 'add-user' || operation === 'dont-manage') {
          return true;
        } else {
          employee.operation.set('errors', [{
            message: 'You must select an option.'
          }]);
          return false;
        }
      });

      if (readyEmployees.every(function (e) {
        return e;
      })) {
        var promises = this.employees.filter(function (employee) {
          var operation = employee.operation && employee.operation.operationKey;
          return operation === 'associate' || operation == 'add-user';
        }).map(function (employee) {
          var operation = employee.operation && employee.operation.operationKey;

          var object = _this2.store.createRecord('integration-object', {
            externalIdAlt: employee.employee.uuid,
            externalSource: 'Gusto',
            enableSync: false
          });

          if (operation === 'associate') {
            var user = employee.operation.user;
            if (!user.title) user.set('title', employee.employee.title);
            if (!user.hourlyCost) user.set('hourlyCost', employee.employee.hourly_cost);
            user.set('integrationObject', object);
            return user.save();
          } else if (operation === 'add-user') {
            var newIdentity = _this2.store.createRecord('identity', {
              email: employee.employee.email,
              firstName: employee.employee.first_name,
              lastName: employee.employee.last_name,
              sendInvite: false,
              requireEmail: !Ember.isBlank(employee.employee.email)
            });

            var newUser = _this2.store.createRecord('user', {
              title: employee.employee.title,
              hourlyCost: employee.employee.hourly_cost,
              permissions: null,
              identity: newIdentity,
              integrationObject: object
            });

            return newUser.save();
          }
        });
        return Ember.RSVP.all(promises);
      } else {
        return Ember.RSVP.reject('You must choose an option for each employee.');
      }
    }
  });

  _exports.default = _default;
});