define("zipbooks/routes/main/reports/taxes-invoiced", ["exports", "zipbooks/mixins/partial-page-refresh", "zipbooks/mixins/permission-redirect"], function (_exports, _partialPageRefresh, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_partialPageRefresh.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    queryParams: {
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (moment(params.start_date).isAfter(moment(params.end_date))) {
        params.start_date = params.end_date;
      }

      return this.client.cached('GET', 'reports', "taxes-invoiced?".concat($.param(params)));
    }
  });

  _exports.default = _default;
});