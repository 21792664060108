define("zipbooks/controllers/main/recurring-profile", ["exports", "zipbooks/mixins/line-itemable-controller"], function (_exports, _lineItemableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_lineItemableController.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.set('allowSingleItemEditing', true);
    },
    actions: {
      selectFrequency: function selectFrequency(frequency) {
        this.set('model.frequency', frequency);
      },
      includeUnbilledChanged: function includeUnbilledChanged() {
        if (this.get('model.includeUnbilled')) {
          this.send('updateBillingPreferences');
        } else {
          this.send('autoSaveModel');
        }
      },
      toggleAcceptAch: function toggleAcceptAch() {
        if (this.model.acceptAch) {
          this.set('model.bankAccount', this.get('model.contact.paymentBankAccounts.firstObject'));
        }

        this.set('model.autobill', this.model.acceptAch);
        this.send('autoSaveModel');
      },
      updateBillingPreferences: function updateBillingPreferences() {
        var _this = this;

        this.overlay.showDrawer('drawers/recurring-profile-form', this.model, {
          updated: function updated() {
            _this.send('autoSaveModel');
          }
        });
      },
      checkSave: function checkSave() {
        var _this2 = this;

        if (this.model.sendInvoice) {
          this.overlay.showDialog('overlays/dialogs/recurring-profile-send', 'Send invoice now?', null, {
            send: function send() {
              _this2.set('model.sendOnSave', true);

              _this2.send('save');

              _this2.set('model.sendOnSave', false);
            }
          });
        } else {
          this.set('model.sendOnSave', true);
          this.send('save');
          this.set('model.sendOnSave', false);
        }
      }
    },
    achBankAccounts: Ember.computed('model.contact.id', 'model.contact.paymentBankAccounts.@each.id', function () {
      if (this.get('model.contact.id')) {
        return this.get('model.contact.paymentBankAccounts').filter(function (ba) {
          return ba.status === 'verified';
        }).map(function (ba) {
          return {
            value: ba,
            title: "".concat(ba.bankName, " ").concat(ba.last4)
          };
        });
      } else {
        return [];
      }
    }),
    verifyAchAccount: Ember.computed('model.contact.id', 'model.contact.paymentBankAccounts.@each.id', function () {
      if (this.get('model.contact.id')) {
        return this.get('model.contact.paymentBankAccounts').filter(function (ba) {
          return ['new', 'validated'].includes(ba.status);
        }).get('firstObject');
      }
    }),
    showSaveAndSend: Ember.computed('model.nextScheduledDate', function () {
      return this.get('model.nextScheduledDate') === moment().format('YYYY-MM-DD');
    }),
    sendButtonTitle: Ember.computed('model.sendInvoice', function () {
      return this.model.sendInvoice ? 'Send Invoice' : 'Create Invoice';
    }),
    _needsSaving: Ember.observer('model.acceptCreditCards', 'model.autobill', function (_controller, key) {
      if (this.model) {
        var changedKeys = Object.keys(this.model.changedAttributes());
        var hasKeyChanged = changedKeys.some(function (attribute) {
          return key === 'model.' + attribute;
        });

        if (hasKeyChanged) {
          this.send('autoSaveModel');
        }
      }
    }),
    // overrides save on line-itemable-controller
    save: function save() {
      var _this3 = this;

      var closeMiniForms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (closeMiniForms) {
        this.set('allowSingleItemEditing', false);
      }

      return this._super.apply(this, arguments).then(function () {
        _this3.set('buttonState', 'loaded');

        if (closeMiniForms) {
          _this3.set('allowSingleItemEditing', true);
        }
      }, function () {
        _this3.set('buttonState', 'invalid');

        if (closeMiniForms) {
          _this3.set('allowSingleItemEditing', true);
        }
      });
    }
  });

  _exports.default = _default;
});