define("zipbooks/utils/form-line", ["exports", "zipbooks/utils/parse-number"], function (_exports, _parseNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    errors: Ember.Object.create(),
    inferredLineKind: function inferredLineKind() {
      if (!this._hasPreviousKind()) {
        return this._computedKind();
      } // if it already has a kind, we flip it if its negative
      else {
          var kind = this.previousKind || this._computedKind();

          if (this._isNegative()) {
            return this.oppositeKind(kind);
          }

          return kind;
        }
    },
    computedTags: Ember.computed('tags.@each.name', function () {
      return this.getWithDefault('tags', []);
    }),
    empty: function empty() {
      return !this.kind || !this.getWithDefault('amount', 0) && (0, _parseNumber.default)(this.getWithDefault('amount', 0)) !== 0;
    },
    oppositeKind: function oppositeKind() {
      var kind = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return (kind || this.inferredLineKind()) === 'debit' ? 'credit' : 'debit';
    },
    inferKindFromPrimary: function inferKindFromPrimary(pl) {
      if (this._signage() === pl._signage()) {
        return pl.oppositeKind();
      } else {
        return pl.inferredLineKind();
      }
    },
    isRed: function isRed() {
      var pl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var creditRed = this._creditRed();

      var kind;

      if (pl && this !== pl) {
        kind = this.inferKindFromPrimary(pl);
      } else {
        kind = this.inferredLineKind();
      }

      return creditRed && kind === 'credit' || !creditRed && kind !== 'credit';
    },
    _hasPreviousKind: function _hasPreviousKind() {
      return Ember.isPresent(this.previousKind);
    },
    _computedKind: function _computedKind() {
      // if it already has a kind, don't change it
      if (this._hasPreviousKind()) {
        return;
      }

      var creditNegative = this._creditNegative();

      var positive = this.amount > -1 ? true : false;
      var kind;

      if (creditNegative && positive || !creditNegative && !positive) {
        kind = 'debit';
      } else {
        kind = 'credit';
      }

      return kind;
    },
    _creditNegative: function _creditNegative() {
      return (this.get('chartAccount.classificationInfo') || {}).creditNegative;
    },
    _creditRed: function _creditRed() {
      return (this.get('chartAccount.classificationInfo') || {}).creditRed;
    },
    _isNegative: function _isNegative() {
      return this.getWithDefault('amount', 0) < 0;
    },
    _signage: function _signage() {
      return this._isNegative() ? -1 : +1;
    }
  });

  _exports.default = _default;
});