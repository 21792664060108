define("zipbooks/components/suggest/country-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/countries", "zipbooks/utils/search-in"], function (_exports, _autoSuggest, _countries, _searchIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Country',
    layoutName: 'components/auto-suggest',
    displayArbitrary: true,
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      return (0, _searchIn.default)(this.countriesArray, 'name', search).map(function (item
      /*, index, enumerable*/
      ) {
        return {
          id: item.abbr,
          name: item.name
        };
      });
    },
    labelForResult: function labelForResult(result) {
      return result.name;
    },
    initial: Ember.computed('country', function () {
      var country = this.country;
      var value = (0, _countries.getCountryValue)(country);

      if (!value) {
        return {
          id: country,
          name: country
        };
      } else {
        return {
          id: value.abbr,
          name: value.name
        };
      }
    }),
    countriesArray: Ember.computed('countries', function () {
      return this.countries || (0, _countries.default)();
    }),
    sort: function sort(a, b) {
      if (a.id === 'US') {
        return -1;
      }

      if (b.id === 'US') {
        return 1;
      }

      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    }
  });

  _exports.default = _default;
});