define("zipbooks/routes/main/settings/preferences", ["exports", "zipbooks/mixins/settings-route", "zipbooks/mixins/highlightable-route"], function (_exports, _settingsRoute, _highlightableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_settingsRoute.default, _highlightableRoute.default, {});

  _exports.default = _default;
});