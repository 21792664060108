define("zipbooks/controllers/main/accountant/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notificationCenter: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'account-added', function () {
        return _this.send('refreshRoute');
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'account-added');
    },
    showAccountsReceivable: Ember.computed('model.accounts_receivable', function () {
      return this.get('model.accounts_receivable').any(function (r) {
        return r.total * 1 > 0;
      }) && this.get('model.accounts_receivable').any(function (c) {
        return c.customer_name !== 'Unassigned';
      });
    }),
    showSalesByCustomer: Ember.computed(function () {
      return this.permissions.can('accounting.read');
    }),
    showActiveMrr: Ember.computed(function () {
      return this.permissions.can('invoices.read');
    }),
    showTotalLicenses: Ember.computed(function () {
      return this.permissions.can('account.read');
    }),
    showHoursByClient: Ember.computed(function () {
      return this.permissions.can('time_tracking.read');
    }),
    showHoursByTeamMeber: Ember.computed(function () {
      return this.permissions.can('time_tracking.read') && this.permissions.can('users.read');
    })
  });

  _exports.default = _default;
});