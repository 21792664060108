define("zipbooks/components/mini-tracker/time-entry-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    editing: false,
    actions: {
      toggleEditor: function toggleEditor() {
        return; // coming back to editing later
        //this.toggleEditor(this.editing ? null : this.time)
      }
    }
  });

  _exports.default = _default;
});