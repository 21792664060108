define("zipbooks/components/suggest/tag-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/search-in", "zipbooks/utils/keycodes"], function (_exports, _autoSuggest, _searchIn, _keycodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Tags',
    classNames: 'tag-select',
    layoutName: 'components/auto-suggest',
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var _this = this;

      var noneOption = {
        id: 'none',
        type: '',
        name: 'No tags',
        kind: ''
      };
      return this.taggables().then(function (taggables) {
        if (_this.includeKinds) {
          return _this.kinds().then(function (kinds) {
            if (_this.includeNoneOption) {
              return _this.filter(kinds.concat(taggables).concat([noneOption]), search);
            } else {
              return _this.filter(kinds.concat(taggables), search);
            }
          });
        } else if (_this.includeNoneOption) {
          return _this.filter(taggables.concat([noneOption]), search);
        } else {
          return _this.filter(taggables, search);
        }
      });
    },
    filter: function filter(results, search) {
      var _this2 = this;

      var excludeLabels = this.getWithDefault('exclude', []).mapBy('name');
      results = results.filter(function (t) {
        return !excludeLabels.includes(t.name);
      }); // exclude anything but invoices/contacts unless they have a paid
      // plan that supports other stuff

      if (!this.session.hasPrivilege('tags-expanded')) {
        results = results.filter(function (t) {
          return ['invoice', 'contact', 'bill'].includes(t.type);
        });
      }

      if (this.filterType) {
        results = results.filter(function (tag) {
          return tag.type === _this2.filterType;
        });
      }

      return (0, _searchIn.default)(results, 'name', search).slice(0, 20);
    },
    taggables: function taggables() {
      return this.client.cached('GET', 'taggables').then(function (results) {
        return results.data.map(function (r) {
          if (r.type === 'label') {
            return {
              id: r.id,
              type: r.type,
              name: r.attributes.name,
              kind: r.attributes.kind
            };
          }

          return {
            id: r.id,
            type: Ember.String.dasherize(r.type),
            name: r.attributes.name
          };
        });
      });
    },
    kinds: function kinds() {
      if (this.session.hasPrivilege('labels')) {
        return this.client.cached('GET', 'label-kinds').then(function (results) {
          return results.data.concat(['Contact', 'Location', 'Project', 'Customer', 'Vendor', 'Item']).map(function (kind) {
            return {
              id: kind,
              type: 'kind',
              name: kind,
              kind: 'kind'
            };
          });
        });
      } else {
        return Ember.RSVP.resolve([]);
      }
    },
    labelForResult: function labelForResult(result) {
      return result.name;
    },
    actions: {
      select: function select(result) {
        this._super(result);

        if (this.clearAfterSelect) {
          this.set('searchText', null);
        }

        return false;
      },
      add: function add(label) {
        this.handleAddNew(label);
        Ember.tryInvoke(this, 'willAdd', [label]);
      }
    },
    keyDown: function keyDown(event) {
      this._super.apply(this, arguments);

      if (event.which === _keycodes.default.BACKSPACE || event.which === _keycodes.default.DELETE) {
        if (this.searchText === '') {
          Ember.tryInvoke(this, 'deleteLastTag', [event]);
        }
      }
    },
    focusIn: function focusIn() {
      this._super.apply(this, arguments);

      this.set('show', false);
    },
    excludeChanged: Ember.observer('exclude.@each', function () {
      this.updateResultsMatching();
    }),
    handleAddNew: function handleAddNew(name) {
      var _this3 = this;

      var label = this.store.createRecord('label', {
        name: name
      });
      this.overlay.showDrawer('drawers/label-form', label, {
        saved: function saved(model) {
          var tag = {
            id: model.get('id'),
            type: model.get('modelType').capitalize(),
            name: model.get('name'),
            kind: model.get('kind')
          };

          _this3.trySelect(tag);
        }
      });
    }
  });

  _exports.default = _default;
});