define("zipbooks/components/overlays/dialogs/accept-tos", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/intercom"], function (_exports, _testable, _intercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('accepted', false);
      this.$().closest('.modal-dialog').addClass('modal-dialog-square-promotion');
    },
    actions: {
      close: function close() {
        this.close();
      },
      accept: function accept() {
        var _this = this;

        this.model.identity.set('lastAcceptedTos', moment());
        this.get('model.identity').then(function (identity) {
          identity.save().then(function () {
            _this.close();

            _intercom.default.update({
              'accepted-tos': true
            });
          });
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});