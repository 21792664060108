define("zipbooks/serializers/recurring-journal-entry-line", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/embedded-jsonapi-records", "zipbooks/mixins/timestamp-serializer"], function (_exports, _application, _embeddedJsonapiRecords, _timestampSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_embeddedJsonapiRecords.default, _timestampSerializer.default, {
    attrs: {
      tags: {
        serialize: 'records'
      },
      journalEntry: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});