define("zipbooks/initializers/sentry", ["exports", "zipbooks/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(_application) {
    // makes a mess of js stack traces in dev
    if (!_environment.default.isProduction) {
      return;
    }

    Sentry.init({
      dsn: _environment.default.sentryDSN,
      debug: true,
      release: _environment.default.COMMIT,
      environment: _environment.default.environment,
      integrations: [new Sentry.Integrations.Ember()],
      ignoreErrors: ['The adapter rejected the commit because it was invalid', 'TransitionAborted', 'a glimmer transaction was begun', 'returned a 404', 'UnrecognizedURLError'],
      whitelistUrls: [/.*zipbooks\.com/],
      // we dont care about mixpanel breadcrumbs
      beforeBreadcrumb: function beforeBreadcrumb(crumb) {
        if (crumb.category === 'xhr' && crumb.data.url.match('mixpanel')) {
          return null;
        }

        return crumb;
      }
    });
  }

  var _default = {
    name: 'sentry',
    initialize: initialize
  };
  _exports.default = _default;
});