define("zipbooks/templates/components/drawers/-document-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "igH4RePB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[7,\"form\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row inline-form\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col inline-form-col\"],[8],[0,\"\\n          \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Title\"],[9],[0,\"\\n          \"],[1,[28,\"form-input\",null,[[\"value\",\"errors\",\"testId\"],[[24,[\"model\",\"title\"]],[24,[\"model\",\"errors\",\"title\"]],\"title\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"action\",\"model\",\"title\",\"class\",\"testId\"],[[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null],[24,[\"model\"]],\"Save\",\"btn btn-full btn-blue btn-depressable\",\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-document-form.hbs"
    }
  });

  _exports.default = _default;
});