define("zipbooks/templates/components/import/column-matcher-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mGt2Buep",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row d-flex align-items-center mb-2\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-4\"],[8],[0,\"\\n    \"],[1,[22,\"inputHeader\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-1 text-center\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"icon-arrow-right-long blue\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n    \"],[1,[28,\"custom-select\",null,[[\"value\",\"small\",\"placeholder\",\"testId\",\"options\"],[[24,[\"value\"]],true,\"Select\",\"zipbooks-field-select\",[24,[\"availableOutputHeaders\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/import/column-matcher-row.hbs"
    }
  });

  _exports.default = _default;
});