define("zipbooks/components/drawers/manage-team-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/mixins/accountants/manage-team"], function (_exports, _modelDrawer, _manageTeam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend(_manageTeam.default, {
    classNames: ['overlay'],
    store: Ember.inject.service(),
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    title: 'Manage team',
    actions: {
      save: function save() {
        var _this = this;

        this.set('buttonState', 'saving');
        this.saveUsers(this.selectedUsers, this.model.get('id')).then(function () {
          _this.notificationCenter.notify('client-edited');

          _this.afterSave();
        });
      }
    },
    partialName: Ember.computed('modelName', function () {
      return "components/drawers/manage-team-form";
    }),
    markUsersAsSelected: function markUsersAsSelected(users) {
      var _this2 = this;

      users.forEach(function (user) {
        if (_this2.model.get('users').find(function (modelUser) {
          return modelUser.get('identity.id') === user.get('identity.id');
        })) {
          _this2.selectedUsers.addObject(user);
        }
      });
    }
  });

  _exports.default = _default;
});