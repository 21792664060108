define("zipbooks/templates/components/failable-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TiQHxav6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"loadImage\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[true,[28,\"action\",[[23,0,[]],\"badUrl\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1,[false,[28,\"action\",[[23,0,[]],\"badUrl\"],null]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/failable-image.hbs"
    }
  });

  _exports.default = _default;
});