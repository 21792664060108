define("zipbooks/templates/components/time/duration-formatter-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uppLpo3Q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"format\"]],\"clock\"],null]],null,{\"statements\":[[0,\"  \"],[1,[22,\"clock\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"format\"]],\"decimal\"],null]],null,{\"statements\":[[0,\"  \"],[1,[22,\"decimal\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"time/duration-formatter\",null,[[\"duration\"],[[24,[\"duration\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/time/duration-formatter-report.hbs"
    }
  });

  _exports.default = _default;
});