define("zipbooks/components/drawers/transaction/split-transfer-mode", ["exports", "zipbooks/components/drawers/transaction/advanced-mode", "zipbooks/utils/array", "zipbooks/utils/in-transit-line"], function (_exports, _advancedMode, _array, _inTransitLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _advancedMode.default.extend({
    savableEntry: function savableEntry() {
      var formLines = this.formLines;
      formLines = this.withEmptyLinesRemoved(formLines);
      var lines = this.convertToLines(formLines);
      var entry = this.entry;
      entry.set('journalEntryLines', lines);
      return entry;
    },
    showInTransitLines: false,
    actions: {
      toggleShowInTransitLines: function toggleShowInTransitLines() {
        this.toggleProperty('showInTransitLines');
      }
    },
    inTransitLines: Ember.computed('showInTransitLines', 'formLines.@each', function () {
      if (!this.showInTransitLines) {
        return [];
      } else {
        var byDate = (0, _array.groupBy)(this.formLines, function (v) {
          return v.date;
        });
        var inTransitChartAccount = this.store.peekAll('chartAccount').find(function (ca) {
          return ca.systemCode === '9000';
        });
        return Object.keys(byDate).map(function (date) {
          return _inTransitLine.default.create({
            sourceLines: byDate[date],
            isInTransit: true,
            chartAccount: inTransitChartAccount
          });
        });
      }
    }),
    allLines: Ember.computed('inTransitLines.@each', 'formLines.@each', function () {
      return this.formLines.concat(this.inTransitLines).sort(function (a, b) {
        if (a.date === b.date) {
          if (a.isInTransit) {
            return 1;
          }

          if (b.isInTransit) {
            return -1;
          }
        }

        if (!a.date) {
          return 1;
        }

        if (!b.date) {
          return -1;
        }

        return moment(a.date, 'YYYY-MM-DD').unix() - moment(b.date, 'YYYY-MM-DD').unix();
      });
    })
  });

  _exports.default = _default;
});