define("zipbooks/components/drawers/add-account-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    title: 'Add Account',
    classNames: ['drawer-large'],
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    actions: {
      selectCountry: function selectCountry(country) {
        this.set('country', country && country.id);
      },
      save: function save() {
        var _this = this;

        this.set('buttonState', 'saving');
        var body = {
          data: {
            type: 'account',
            attributes: {
              email: this.get('email'),
              name: this.get('name'),
              link: true
            }
          }
        };
        this.client.POST('accounts', '', body).then(function (resp) {
          return _this.finishSave(resp);
        }, function (jqXHR) {
          _this.set('buttonState', 'invalid');

          _this.overlay.showBanner('error', jqXHR.responseJSON.errors.mapBy('detail').join(', '));
        });
      }
    },
    finishSave: function finishSave(resp) {
      var _this2 = this;

      var location = resp.included.find(function (i) {
        return i.type === 'location';
      });
      this.saveLocation(resp.meta.token, location.id);

      if (resp.data.relationships['linked-contact'].data) {
        this.updateContact(resp.data.relationships['linked-contact'].data.id);
      }

      this.session.fetchLoggedInUser().then(function () {
        _this2.set('buttonState', 'loaded');

        _this2.overlay.showBanner('success', 'Account has been created!');

        _this2.notificationCenter.notify('account-added');

        _this2.send('close');
      });
    },
    saveLocation: function saveLocation(token, locationId) {
      var body = {
        data: {
          type: 'location',
          attributes: {
            'address-1': this.address_1,
            'address-2': this.address_2,
            city: this.city,
            state: this.state,
            country: this.country,
            'postal-code': this.postal_code
          }
        }
      };
      this.client.PATCH('locations', locationId, body, null, {
        token: token
      });
    },
    updateContact: function updateContact(contactId) {
      var body = {
        data: {
          type: 'contact',
          attributes: {
            'first-name': this.firstName,
            'last-name': this.lastName,
            name: this.name,
            'address-1': this.address_1,
            'address-2': this.address_2,
            city: this.city,
            state: this.state,
            country: this.country,
            'postal-code': this.postal_code
          }
        }
      };
      this.client.PATCH('contacts', contactId, body, null);
    }
  });

  _exports.default = _default;
});