define("zipbooks/controllers/main/estimate", ["exports", "zipbooks/mixins/line-itemable-controller"], function (_exports, _lineItemableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_lineItemableController.default, {
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    downloadUrl: Ember.computed('model', function () {
      return "".concat(window.location.origin, "/v2/public/estimates/").concat(this.get('model.externalId'), "/pdf");
    }),
    previewUrl: Ember.computed('model.externalId', function () {
      return "".concat(window.location.origin, "/estimate?id=").concat(this.get('model.externalId'), "&preview=1");
    }),
    accepted: Ember.computed('model.status', function () {
      return this.get('model.status') === 'Accepted';
    }),
    actions: {
      convertToInvoice: function convertToInvoice() {
        var _this = this;

        this.set('convertButtonState', 'saving');
        this.client.send('POST', 'estimates', this.get('model.id') + '/convert').then(function (resp) {
          _this.store.findRecord('invoice', resp.data.id).then(function (converted) {
            _this.set('convertButtonState', 'loaded');

            _this.overlay.showBanner('success', 'Converted to invoice!');

            _this.transitionToRoute('main.invoice', converted, {
              queryParams: {
                editing: false
              }
            });
          }, function () {
            _this.set('convertButtonState', 'invalid');
          });
        }, function ()
        /*jqXHR, textStatus,errorThrown */
        {
          _this.set('convertButtonState', 'invalid');

          _this.overlay.showBanner('error', 'Failed to convert to invoice.');
        });
      },
      toggleAccept: function toggleAccept() {
        if (this.get('model.status') === 'Accepted') {
          return this.unaccept();
        } else {
          return this.accept();
        }
      }
    },
    accept: function accept() {
      var _this2 = this;

      this.set('acceptButtonState', 'saving');
      this.client.send('PATCH', 'estimates', this.get('model.id') + '/accept').then(function ()
      /*resp*/
      {
        _this2.set('acceptButtonState', 'loaded');

        _this2.set('model.status', 'Accepted');
      }, function ()
      /*jqXHR, textStatus,errorThrown */
      {
        _this2.overlay.showBanner('error', 'Failed to accept estimate.');

        _this2.set('acceptButtonState', 'invalid');
      });
    },
    unaccept: function unaccept() {
      var _this3 = this;

      this.set('acceptButtonState', 'saving');
      this.client.send('PATCH', 'estimates', this.get('model.id') + '/unaccept').then(function (resp) {
        _this3.set('acceptButtonState', 'loaded');

        _this3.set('model.status', resp.data.attributes.status);
      }, function ()
      /*jqXHR, textStatus,errorThrown */
      {
        _this3.overlay.showBanner('error', 'Failed to mark estimate unaccepted.');

        _this3.set('acceptButtonState', 'invalid');
      });
    }
  });

  _exports.default = _default;
});