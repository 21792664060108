define("zipbooks/components/time/duration-formatter", ["exports", "zipbooks/utils/format-seconds-as-hours"], function (_exports, _formatSecondsAsHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'time-entry-duration-formatter',
    colorClass: Ember.computed(function () {
      return this.get('color') || 'blue';
    }),
    noColorClass: Ember.computed(function () {
      return this.get('color') || 'gray-500';
    }),
    opacityClass: Ember.computed(function () {
      if (this.get('color')) {
        return 'opacity-80';
      } else {
        // default
        return 'opacity-50';
      }
    }),
    hours: Ember.computed('duration', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 'h');
    }),
    minutes: Ember.computed('duration', 'showSeconds', function () {
      if (this.get('showSeconds')) {
        return 0;
      } else {
        return (0, _formatSecondsAsHours.default)(this.duration || 0, 'm');
      }
    }),
    seconds: Ember.computed('duration', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 's');
    }),
    hoursClass: Ember.computed('hours', function () {
      if (this.get('hours') == 0) {
        return this.get('noColorClass');
      } else {
        return this.get('colorClass');
      }
    }),
    minutesClass: Ember.computed('hours', 'showSeconds', function () {
      if (this.get('hours') == 0 && this.get('minutes') == 0) {
        return this.get('noColorClass');
      } else {
        return this.get('colorClass');
      }
    }),
    secondsClass: Ember.computed('duration', function () {
      if (this.get('duration') == 0 || this.get('duration') == null) {
        return this.get('noColorClass') + ' ' + this.get('opacityClass');
      } else {
        return this.get('colorClass') + ' ' + this.get('opacityClass');
      }
    }),
    showHours: Ember.computed('duration', function () {
      if (this.get('duration') >= 60) {
        return true;
      }
    }),
    showSeconds: Ember.computed('duration', function () {
      if (this.get('duration') < 60) {
        return true;
      }
    }),
    decimal: Ember.computed('duration', function () {
      var value = (0, _formatSecondsAsHours.default)(this.duration || 0, 'd').toString();
      var decimals = value.split('.')[1];

      if (!decimals || decimals.length > 2) {
        value = (+value).toFixed(2);
      }

      return value;
    })
  });

  _exports.default = _default;
});