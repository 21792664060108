define("zipbooks/components/dashboard/hours-by-client", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/chart-custom-tooltip", "ember-data", "zipbooks/utils/graph-colors"], function (_exports, _testable, _chartCustomTooltip, _emberData, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _chartCustomTooltip.default, {
    client: Ember.inject.service(),
    hoursByClient: Ember.computed('month', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.client.cached('GET', "dashboard/hours-by-client?month=".concat(this.month))
      });
    }),
    data: Ember.computed('hoursByClient.hours', 'hoursByClient.isPending', function () {
      if (this.hoursByClient.isPending) {
        return [];
      } else {
        this.set('minDate', this.get('hoursByClient.min_date'));
        return this.get('hoursByClient.hours');
      }
    }),
    month: moment().format('YYYY-MM'),
    months: Ember.computed('minDate', function () {
      if (this.minDate) {
        var periods = [];
        var date = moment(this.minDate).startOf('month');

        while (date <= moment()) {
          periods.push({
            value: moment(date).format('YYYY-MM'),
            title: moment(date).format('MMM YYYY')
          });
          date = date.add(1, 'month');
        }

        return periods.reverse();
      } else {
        return [{
          value: moment().format('YYYY-MM'),
          title: moment().format('MMM YYYY')
        }];
      }
    }),
    total: Ember.computed('data.@each.total', function () {
      return this.data.reduce(function (acc, c) {
        var amount = new Decimal(c.total);
        return amount.plus(acc);
      }, new Decimal(0));
    }),
    sortedAccounts: Ember.computed('data.@each', function () {
      var accounts = this.data;
      var total = accounts.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
      var colorIndex = 0;
      return accounts.sort(function (a, b) {
        return b.total - a.total;
      }).map(function (customer) {
        if (customer.customer_name === 'Unassigned') {
          Ember.set(customer, 'backgroundColor', '#BEC5CB');
        } else {
          Ember.set(customer, 'backgroundColor', _graphColors.default.cycle[colorIndex % _graphColors.default.cycle.length]);
          colorIndex += 1;
        }

        Ember.set(customer, 'percent', customer.total * 1 / total * 100);
        return customer;
      });
    }),
    topAccounts: Ember.computed('sortedAccounts.@each', function () {
      if (this.get('sortedAccounts.length') > 6) {
        var total = this.sortedAccounts.slice(6).reduce(function (acc, c) {
          var amount = new Decimal(c.total);
          return amount.plus(acc);
        }, new Decimal(0));
        var billedTotal = this.sortedAccounts.slice(6).reduce(function (acc, c) {
          var amount = new Decimal(c.billed_total);
          return amount.plus(acc);
        }, new Decimal(0));
        return this.sortedAccounts.slice(0, 5).concat([{
          customer_name: 'Other',
          total: total,
          billed_total: billedTotal,
          backgroundColor: '#E8EAEE'
        }]);
      } else {
        return this.sortedAccounts;
      }
    }),
    _dataChanged: Ember.observer('hoursByClient.isPending', 'month', 'total', function () {
      var _this = this;

      if (this.total > 0) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this.renderChart();
        });
      }
    }),
    renderChart: function renderChart() {
      var data = this.sortedAccounts.filter(function (c) {
        return c.total * 1 > 0;
      }).slice(0, 50);
      var ctx = this.$('canvas') && this.$('canvas')[0];

      if (!ctx) {
        return;
      }

      new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: data.map(function (a) {
              return a.total;
            }),
            backgroundColor: data.map(function (a) {
              return a.backgroundColor;
            }),
            hoverBackgroundColor: data.map(function (a) {
              return a.backgroundColor;
            }),
            borderWidth: 0,
            pointRadius: 0
          }],
          labels: data.map(function (a) {
            return a.customer_name;
          })
        },
        options: {
          aspectRatio: 1,
          cutoutPercentage: 56,
          legend: {
            display: false
          }
        }
      });
      var ctx2 = this.$('canvas')[1];

      if (!ctx2) {
        return;
      }

      new Chart(ctx2, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: data.map(function (a) {
              return a.total;
            }),
            backgroundColor: data.map(function (a) {
              return a.backgroundColor;
            }),
            hoverBackgroundColor: data.map(function (a) {
              return Chart.helpers.color(a.backgroundColor).saturate(0.1).darken(0.05).rgbString();
            }),
            borderWidth: 0,
            pointRadius: 0
          }],
          labels: data.map(function (a) {
            return a.contact_id;
          })
        },
        options: {
          aspectRatio: 1,
          cutoutPercentage: 67,
          legend: {
            display: false
          },
          tooltips: $.extend(true, {
            callbacks: {
              label: function label(tooltipItem, _data) {
                return data[tooltipItem.index];
              }
            }
          }, this.customTooltipOpts(this)),
          hover: {
            onHover: function onHover(e) {
              var point = this.getElementAtEvent(e);
              if (point.length) e.target.style.cursor = 'pointer';else e.target.style.cursor = 'default';
            }
          }
        }
      });
    },
    chartWasClicked: function chartWasClicked(router, context) {
      router.transitionTo('main.time-entries', {
        queryParams: {
          contact_id: context.contact_id,
          user_id: null
        }
      });
    }
  });

  _exports.default = _default;
});