define("zipbooks/components/index-row-content/transaction", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/line-analyzer", "zipbooks/utils/transaction-stamper", "zipbooks/utils/later", "zipbooks/helpers/redness", "zipbooks/utils/base-64", "zipbooks/mixins/transaction-list-row", "zipbooks/mixins/add-journal-entry-line-tags"], function (_exports, _testable, _lineAnalyzer, _transactionStamper, _later, _redness, _base, _transactionListRow, _addJournalEntryLineTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _transactionListRow.default, _addJournalEntryLineTags.default, {
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'li',
    classNames: ['transaction-line'],
    classNameBindings: ['isExpanded:pullForward', 'isExpanded:box-transaction-input:cursor-pointer'],
    journalEntry: Ember.computed.alias('primaryLine.journalEntry'),
    actions: {
      setAccount: function setAccount(chartAccount) {
        var _this = this;

        var line = this.accountLine;
        line.set('chartAccount', chartAccount);
        line.get('journalEntry').thenIfExists(function (journalEntry) {
          journalEntry.save().then(function () {
            if (_this.didSave) {
              _this.didSave(journalEntry, _this);
            }

            Ember.tryInvoke(_this, 'close', []);
          });
        });
      },
      setCategory: function setCategory(chartAccount) {
        var _this2 = this;

        var line = this.categoryLine;
        line.set('chartAccount', chartAccount);
        line.get('journalEntry').thenIfExists(function (journalEntry) {
          journalEntry.save().then(function () {
            if (_this2.didSave) {
              _this2.didSave(journalEntry, _this2);
            }

            Ember.tryInvoke(_this2, 'close', []);
          });
        });
      },
      selectBillToContact: function selectBillToContact(contact) {
        var _this3 = this;

        this.get('primaryLine.otherLines').forEach(function (line) {
          return line.set('billToContact', contact);
        });
        this.get('primaryLine.journalEntry').thenIfExists(function (journalEntry) {
          if (_this3.didSave) {
            _this3.didSave(journalEntry, _this3);
          }

          journalEntry.save();
        });
      },
      toggleConfirm: function toggleConfirm(event) {
        event.stopPropagation();
        this.confirm(this.primaryLine, this);
        return false;
      },
      autoSave: function autoSave(model) {
        var _this4 = this;

        model.thenIfExists(function (journalEntry) {
          if (journalEntry.get('hasDirtyAttributes')) {
            journalEntry.save().then(function () {
              if (_this4.didSave) {
                _this4.didSave(journalEntry, _this4);
              }
            });
          }
        });
      },
      save: function save(model) {
        var _this5 = this;

        model.thenIfExists(function (journalEntry) {
          journalEntry.save().then(function () {
            _this5.notificationCenter.notify('journal-entry.save');

            if (_this5.didSave) {
              _this5.didSave(journalEntry, _this5);
            }

            (0, _later.default)(300, function () {
              Ember.tryInvoke(_this5, 'close', []);
            });
          });
        });
      },
      selected: function selected() {
        this.selected.apply(this, arguments);
      }
    },
    click: function click(event) {
      var _this6 = this;

      // if they clicked on name or chart account, we give focus to it when it expands
      if (!this.isExpanded) {
        var target = $(event.target);
        var shortcut = target.data('click-shortcut');

        if (shortcut) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            var input = _this6.$("input[title=\"".concat(shortcut, "\"]"));

            input.focus();

            if (shortcut === 'Name' && input.length > 0) {
              input[0].setSelectionRange(0, input[0].value.length);
            }
          });
        }
      }

      var isConfirmationClick = $(event.target).hasClass('confirm-check');
      var isControlElement = ['form-check-input', 'form-checkbox', 'form-control', 'btn'].any(function (c) {
        return event.target.classList.contains(c);
      });

      if (!isConfirmationClick && !isControlElement) {
        var line = this.primaryLine;

        if (this.isExpanded) {
          // if (event.target.classList.contains('row')) {
          Ember.tryInvoke(this, 'close', [line]); // }
        } else {
          Ember.tryInvoke(this, 'open', [line]);
        }
      }
    },
    color: Ember.computed('amount', 'hasMultipleCriticalLines', 'isTransfer', 'chartAccountId', 'primaryLine', 'equityException', function () {
      if (this.hasMultipleCriticalLines && !this.isTransfer) {
        return this.amount >= 0 ? 'green' : 'red';
      } else {
        return (0, _redness.redness)([this.primaryLine], {
          exception: this.equityException,
          isTransfer: this.isTransfer,
          isFiltered: this.chartAccountId
        });
      }
    }),
    // if the transaction is not a CREDIT to a critical account, hide pass-through and extend notes all the way to the right
    showBillToContact: Ember.computed('primaryLine.journalEntry.journalEntryLines.@each.chartAccount', function () {
      var lines = this.get('primaryLine.journalEntry.journalEntryLines') || []; // if categorized, show only if expense

      if (lines.every(function (l) {
        return l.get('chartAccount.id');
      })) {
        return _lineAnalyzer.default.isExpense(lines);
      } // otherwise, show if there is one credit a critical account


      return _lineAnalyzer.default.hasOneCriticalCredit(lines);
    }),
    arrowDirectionClass: Ember.computed('isTransfer', 'isFlipped', function () {
      if (!this.isFlipped && this.get('primaryLine.kind') === 'debit') {
        return 'left-arrow';
      }

      return '';
    }),
    stamper: Ember.computed('primaryLine.journalEntry.lineTags.@each', 'primaryLine.journalEntry.journalEntryLines.@each.chartAccount', function () {
      return new _transactionStamper.default(this.get('primaryLine.journalEntry.journalEntryLines'));
    }),
    specialTypeStamp: Ember.computed('stamper', 'primaryLine.journalEntry.integrationObject.id', function () {
      if (this.stamper.hasInvoiceStamp()) {
        return 'invoice-stamp';
      } else if (this.stamper.hasInvoicePaymentStamp()) {
        return 'invoice-payment-stamp';
      } else if (this.stamper.hasBillPaymentStamp()) {
        return 'bill-payment-stamp';
      } else if (this.stamper.hasBillStamp()) {
        return 'bill-stamp';
      } else if (this.get('primaryLine.journalEntry.integrationObject.externalSource') === 'Square') {
        return 'integration-stamp';
      }
    }),
    vendorStampName: Ember.computed('stamper', function () {
      var name = this.stamper.vendorStampName();
      return name;
    }),
    vendorIsCompetitor: Ember.computed('vendorStampName', function () {
      var vendorStampName = this.vendorStampName.toLowerCase();

      if (vendorStampName == 'freshbooks' || vendorStampName == 'intuit' || vendorStampName == 'wave' || vendorStampName == 'xero') {
        return true;
      }
    }),
    vendorIsZipBooks: Ember.computed('vendorStampName', function () {
      if (this.vendorStampName == 'ZipBooks') {
        return true;
      }
    }),
    has1099Stamp: Ember.computed('stamper', function () {
      return this.stamper.has1099Stamp();
    }),
    billToStampName: Ember.computed('stamper', function () {
      var name = this.stamper.billToStampName();
      return name;
    }),

    /*************************************************
     * Observers
     **************************************************/
    _didConfirm: Ember.observer('primaryLine.journalEntry.isConfirmed', 'primaryLine.clickedToConfirm', function () {
      this.set('showMatched', this.get('primaryLine.journalEntry.isConfirmed') && !this.get('primaryLine.clickedToConfirm') && this.get('primaryLine.otherLines').any(function (line) {
        return line.get('clickedToConfirm');
      }));
    }),

    /*************************************************
     * AddJournalEntryLineTagsMixin Protocol
     **************************************************/
    linesToBeTagged: function linesToBeTagged() {
      return this.get('primaryLine.journalEntry.journalEntryLines').toArray();
    },
    tagWasAdded: function tagWasAdded(tag) {
      var _this7 = this;

      tag.save().catch(function (error) {
        return _this7.handleError(error, tag);
      });
    },
    tagWasRemoved: function tagWasRemoved(tag) {
      this.urlCache.clear();
      tag.destroyRecord();
    },
    handleError: function handleError(error, tag) {
      this.linesToBeTagged().forEach(function (l) {
        l.get('tags').removeObject(tag);
      });
      var message = Ember.get(error, 'errors.firstObject.detail');

      if (message) {
        this.overlay.showBanner('error', message);
      }
    },
    systemObject: Ember.computed('primaryLine.tags.@each', function () {
      return this.get('primaryLine.tags').filter(function (tag) {
        return ['invoice', 'bill'].indexOf(tag.get('taggableType')) !== -1;
      }).get('firstObject');
    }),

    /*************************************************
     * Rollup
     **************************************************/
    rollupQueryParam: Ember.computed('primaryLine.journalEntry.id', function () {
      var properties = this.get('primaryLine.journalEntry').getProperties(['id', 'name']);
      return (0, _base.encode)(JSON.stringify(properties));
    })
  });

  _exports.default = _default;
});