define("zipbooks/serializers/location", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/patch-serializer", "zipbooks/mixins/embedded-jsonapi-records"], function (_exports, _application, _patchSerializer, _embeddedJsonapiRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_patchSerializer.default, _embeddedJsonapiRecords.default, {
    alwaysSend: ['values']
  });

  _exports.default = _default;
});