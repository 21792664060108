define("zipbooks/utils/compare-arrays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = compareArrays;

  function compareArrays(a, b) {
    var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (opts.strict) {
      return a.length == b.length && a.every(function (e, i) {
        return b.indexOf(e) === i;
      });
    } else {
      return a.length == b.length && a.every(function (e) {
        return b.indexOf(e) > -1;
      });
    }
  }
});