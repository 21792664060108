define("zipbooks/utils/insert-at-cursor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = insertAtCursor;

  function insertAtCursor(field, value) {
    // IE support
    if (document.selection) {
      field.focus();
      var sel = document.selection.createRange();
      sel.text = value;
    } // MOZILLA and others
    else if (field.selectionStart || field.selectionStart == '0') {
        var startPos = field.selectionStart;
        var endPos = field.selectionEnd;
        field.value = field.value.substring(0, startPos) + value + field.value.substring(endPos, field.value.length);
        field.selectionStart = startPos + value.length;
        field.selectionEnd = startPos + value.length;
      } else {
        field.value += value;
      }
  }
});