define("zipbooks/components/suggest/taxes-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/search-in"], function (_exports, _autoSuggest, _searchIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Tax',
    layoutName: 'components/auto-suggest',
    store: Ember.inject.service(),
    throttle: 500,
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      return this.store.findAll('tax').then(function (results) {
        results = results.map(function (tax) {
          return {
            id: tax.get('id'),
            name: tax.get('name'),
            number: tax.get('number'),
            rate: tax.get('rate')
          };
        });
        return (0, _searchIn.default)(results, 'name', search);
      });
    },
    labelForResult: function labelForResult(result) {
      return result.name;
    },
    handleAddNew: function handleAddNew(label) {
      this.addNew(label, this.lineItem, this.position);
    }
  });

  _exports.default = _default;
});