define("zipbooks/templates/components/suggest/choices/contact-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PZmha3pa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row x-small-gutters align-items-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-auto\"],[8],[0,\"\\n    \"],[1,[28,\"avatar-image\",null,[[\"url\",\"initials\",\"size\",\"css\"],[[24,[\"choice\",\"publicProfile\",\"avatar\"]],[28,\"truncate\",[[24,[\"choice\",\"formattedName\"]],1],null],\"tiny\",\"mr-2\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"choice\",\"firstName\"]],[24,[\"choice\",\"lastName\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[1,[28,\"search-highlight\",[[24,[\"choice\",\"firstName\"]],[24,[\"searchText\"]]],null],false],[0,\" \"],[1,[28,\"search-highlight\",[[24,[\"choice\",\"lastName\"]],[24,[\"searchText\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-size-12px gray\"],[8],[0,\"\\n          \"],[1,[28,\"search-highlight\",[[24,[\"choice\",\"name\"]],[24,[\"searchText\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"choice\",\"name\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"search-highlight\",[[24,[\"choice\",\"name\"]],[24,[\"searchText\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"search-highlight\",[[24,[\"choice\",\"email\"]],[24,[\"searchText\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/suggest/choices/contact-select.hbs"
    }
  });

  _exports.default = _default;
});