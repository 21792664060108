define("zipbooks/components/drawers/finalize-sending-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/utils/format-money", "ember-inflector"], function (_exports, _modelDrawer, _formatMoney, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    details: null,
    title: 'Finalize Sending',
    modelName: Ember.computed('rest', function () {
      var params = this.rest;
      return Object.keys(params)[0];
    }),
    modelId: Ember.computed('rest', function () {
      var params = this.rest;
      var modelName = Object.keys(params)[0];
      return params[modelName];
    }),
    afterModel: function afterModel(model) {
      var _this = this;

      if (model) {
        var type = model.get('modelType');
        var account = this.get('session.account');
        var recipients = model.get('contact.email');
        var pdf = account.get('settings.defaultAttachPdf');
        var subject = type === 'invoice' ? '[::company_name::] Invoice #::invoice_number::' : '[::company_name::] Estimate #::estimate_number::';
        var message = type === 'invoice' ? "You've got a new invoice from ::company_name:: for ::amount_due::.  View your invoice here:\r\n::invoice_box::\r\n\r\nThanks, ::company_name::" : "You've got a new estimate from ::company_name:: for ::amount_due::.  View your estimate here:\r\n::link::\r\n\r\nThanks, ::company_name::";

        if (this.session.hasPrivilege('account-defaults')) {
          subject = type === 'invoice' ? account.get('settings.defaultEmailSubject') : account.get('settings.estimateDefaultEmailSubject') || '';
          message = type === 'invoice' ? account.get('settings.defaultEmailBody') : account.get('settings.estimateDefaultEmailBody') || '';
        }

        this.set('details', Ember.Object.create({
          send_to: recipients,
          subject: this.replaceTokens(subject, model),
          message: this.replaceTokens(message, model),
          bcc: true,
          pdf: pdf
        }));
        var self = this;
        Ember.run.next(function () {
          _this.$('.tag-input').tagsInput({
            delimiter: ',',
            // others hard coded into line 295 of jquery.tagsinput.js
            defaultText: '',
            height: 'auto',
            onChange: function onChange() {
              self.set('details.send_to', this.value || this[0] && this[0].value);
            }
          });
        });
      } else {
        this.overlay.showBanner('error', 'Something went wrong getting the invoice ready to send.');
        this.close();
      }
    },
    actions: {
      sendIt: function sendIt() {
        var _this2 = this;

        this.set('sendButton', 'saving');
        this.sendIt().then(function (result) {
          if (result) {
            _this2.set('sendButton', 'loaded');

            _this2.overlay.showBanner('success', _this2.get('model.modelType').capitalize() + ' was sent successfully!');

            _this2.close();
          } else {
            _this2.set('sendButton', 'invalid');
          }
        }, function (_e) {
          _this2.set('sendButton', 'invalid');
        });
      },
      openIt: function openIt() {
        var _this3 = this;

        this.openIt().then(function (resp) {
          if (resp) {
            _this3.overlay.showBanner('success', _this3.get('model.modelType').capitalize() + ' was finalized successfully!');

            _this3.close();
          }
        });
      }
    },
    partialName: Ember.computed('modelName', function () {
      return "components/drawers/finalize-sending-form";
    }),
    sendButton: 'loaded',
    instantPaymentButton: 'loaded',
    sendIt: function sendIt() {
      var _this4 = this;

      var model = this.model;
      var modelType = model.get('modelType').underscore();
      var payload = this.details.getProperties('send_to', 'subject', 'message', 'bcc', 'pdf');
      return this.client.send('POST', (0, _emberInflector.pluralize)(modelType), model.get('id') + '/send', payload).then(function (resp) {
        _this4.store.pushPayload(resp);

        return _this4.store.peekRecord(modelType, resp.data.id);
      }, function (jqXHR) {
        var errors = jqXHR.responseJSON.errors.reduce(function (acc, e) {
          acc[e.source.pointer] = [{
            message: e.detail
          }];
          return acc;
        }, {});

        _this4.set('errors', errors);

        return false;
      });
    },
    openIt: function openIt() {
      var _this5 = this;

      var model = this.model;
      var modelType = model.get('modelType').underscore();
      return this.client.send('POST', (0, _emberInflector.pluralize)(modelType), model.get('id') + '/open').then(function (resp) {
        _this5.store.pushPayload(resp);

        return _this5.store.peekRecord(modelType, resp.data.id);
      }, function (jqXHR) {
        _this5.overlay.showBanner('error', jqXHR.responseJSON.errors.mapBy('detail').join('<br>'));

        return false;
      });
    },
    showInstantPaymentButton: Ember.computed('model.instantPaymentOfferAmount', function () {
      return this.get('model.instantPaymentOfferAmount') > 0;
    }),
    replaceTokens: function replaceTokens(string, model) {
      var map = {
        company_name: this.get('session.account.name'),
        invoice_number: model.get('number'),
        estimate_number: model.get('number'),
        amount_due: (0, _formatMoney.default)(model.get('computedAmountDue'), {
          currency: model.get('currencyCode'),
          escaped: false
        })
      };
      return string.replace(/::(.*?)::/g, function (match, key) {
        return map[key] || match;
      });
    }
  });

  _exports.default = _default;
});