define("zipbooks/utils/seconds-to-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = secondsToRead;

  function secondsToRead(string) {
    if (!string || string.length === 0) {
      return 1;
    }

    var wordCount = string.split(' ').length; // the average adult can read 275 words per minute. Seems like the average
    // brain needs .3 seconds just to register and start reading.
    // 2 seconds added just for a bit longer to read

    var seconds = (wordCount / 275 * 60 + 2.3).round(2);
    return seconds > 60 ? 0 : seconds;
  }
});