define("zipbooks/templates/components/each-saved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E130sOwA",
    "block": "{\"symbols\":[\"record\",\"&inverse\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"savedRecords\"]]],null,{\"statements\":[[0,\"  \"],[14,3,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/each-saved.hbs"
    }
  });

  _exports.default = _default;
});