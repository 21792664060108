define("zipbooks/templates/components/notifications-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQezt75m",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"unReadNotificationsCount\"]],4],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"notifications-menu-mark-all\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"markAllAsRead\"],null]],[8],[0,\"\\n    Mark all \"],[1,[28,\"if\",[[28,\"gt\",[[24,[\"unReadNotificationsCount\"]],99],null],\"99+\",[24,[\"unReadNotificationsCount\"]]],null],false],[0,\" as read\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"notifications-menu-mark-all-spacer\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"js-notifications-scroll\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"notifications\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"notification-row\",null,[[\"notification\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row px-3 py-3 font-size-13px\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n        \"],[7,\"b\",true],[8],[0,\"You don't have any notifications yet!\"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"As things happen within the app, notifications will appear here.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoadingMore\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"px-3 py-3 font-size-13px border-bottom-1 \",[22,\"leftBorder\"]]]],[8],[0,\"\\n      \"],[1,[22,\"small-loader\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/notifications-menu.hbs"
    }
  });

  _exports.default = _default;
});