define("zipbooks/mixins/google-maps-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController: function setupController(controller, _model) {
      this._super.apply(this, arguments);

      if (!(window.google && window.google.maps)) {
        $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyA18UGybR-mQgOlb_TfZdomK_ht-W66Wrg', function () {
          controller.set('mapsLib', google.maps);
        });
      } else {
        controller.set('mapsLib', google.maps);
      }
    }
  });

  _exports.default = _default;
});