define("zipbooks/models/chart-account-template", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/utils/classifications"], function (_exports, _model, _model2, _classifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    classification: (0, _model.attr)(),
    classCode: (0, _model.attr)(),
    code: (0, _model.attr)(),
    deletable: (0, _model.attr)('boolean'),
    name: (0, _model.attr)(),
    nameLocked: (0, _model.attr)('boolean'),
    subCode: (0, _model.attr)(),
    systemCode: (0, _model.attr)(),
    children: (0, _model.hasMany)('chart-account-template', {
      inverse: 'parent'
    }),
    parent: (0, _model.belongsTo)('chart-account-template', {
      inverse: 'children'
    }),
    templateGroup: (0, _model.belongsTo)('chart-account-template-group'),
    classificationInfo: Ember.computed('classification', function () {
      var classification = this.classification;
      return (0, _classifications.default)()[classification] || {};
    })
  });

  _exports.default = _default;
});