define("zipbooks/routes/main/reminders", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect"], function (_exports, _routePagination, _autoUpdatingRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    model: function model(params) {
      return this.store.cachedQuery('reminder', {
        page: {
          page: params.page || 1
        }
      });
    }
  });

  _exports.default = _default;
});