define("zipbooks/templates/components/permissions/permissions-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MKpVF2Ls",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-4\"],[8],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"account\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"users\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"contacts\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"invoices\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"estimates\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"payroll\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-8\"],[8],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"accounting\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\",\"readOnly\"],[[24,[\"map\"]],\"accounting\",true]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"projects\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"time_tracking\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"reports\"]]],false],[0,\"\\n      \"],[1,[28,\"permissions/permission-checkbox\",null,[[\"map\",\"scope\"],[[24,[\"map\"]],\"banks\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/permissions/permissions-list.hbs"
    }
  });

  _exports.default = _default;
});