define("zipbooks/helpers/format-frequency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatFrequency = formatFrequency;
  _exports.default = void 0;

  function formatFrequency(params
  /*, hash*/
  ) {
    switch (params[0]) {
      case 'weekly':
        return 'Weekly';

      case 'monthly':
        return 'Monthly';

      case 'yearly':
        return 'Yearly';

      case 'two_weeks':
        return 'Every 2 weeks';

      case 'four_weeks':
        return 'Every 4 weeks';

      case 'two_months':
        return 'Every 2 months';

      case 'three_months':
        return 'Every 3 months';

      case 'six_months':
        return 'Every 6 months';

      case 'two_years':
        return 'Every 2 years';

      case 'three_years':
        return 'Every 3 years';
    }
  }

  var _default = Ember.Helper.helper(formatFrequency);

  _exports.default = _default;
});