define("zipbooks/templates/components/gusto/associate-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mFtA1H6y",
    "block": "{\"symbols\":[\"employee\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row small-gutters gray font-size-13px border-bottom-1 pb-2\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-5\"],[8],[0,\"\\n    Gusto employee\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-2\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-5\"],[8],[0,\"\\n    How to handle in ZipBooks\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"loader-small\",null,[[\"class\"],[\"mt-5\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"employees\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"gusto/user-matcher\",null,[[\"model\",\"operationOptions\",\"selectedUserIds\"],[[23,1,[]],[24,[\"operationOptions\"]],[24,[\"selectedUserIds\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/gusto/associate-users.hbs"
    }
  });

  _exports.default = _default;
});