define("zipbooks/mixins/transaction-mode", ["exports", "zipbooks/mixins/add-journal-entry-line-tags", "zipbooks/mixins/form-line", "zipbooks/utils/form-line", "zipbooks/utils/if-promise"], function (_exports, _addJournalEntryLineTags, _formLine, _formLine2, _ifPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_addJournalEntryLineTags.default, _formLine.default, {
    tagName: '',
    store: Ember.inject.service(),
    classNames: ['scrollable d-block'],
    actions: {
      selectChartAccount: function selectChartAccount(line, chartAccount) {
        line.set('chartAccount', chartAccount);
      },
      editLine: function editLine(line, e) {
        e.preventDefault();
        e.stopPropagation();
        this.overlay.showPopover('overlays/popovers/line-form', e.target, null, {
          direction: 'right',
          line: line,
          journalEntry: this.entry
        });
        return false;
      },
      addPart: function addPart() {
        var _this = this;

        // if there is only one line, we update it with the current amount
        if (this.get('otherLines.length') === 1 && !!this.get('primaryLine.amount')) {
          this.otherLines.forEach(function (l) {
            return l.set('amount', _this.get('primaryLine.amount'));
          });
        }

        var newPart = _formLine2.default.create({
          name: null,
          amount: this.difference || 0,
          kind: null,
          date: null,
          tags: []
        });

        this.formLines.pushObject(newPart);
        this.otherLines.pushObject(newPart);
      },
      removePart: function removePart(part) {
        this.formLines.removeObject(part);
        this.otherLines.removeObject(part);
      },
      filesDidUpload: function filesDidUpload(cloudFiles) {
        (0, _ifPromise.default)(this.entry, function (entry) {
          cloudFiles.forEach(function (file) {
            return file.set('cloudFileable', entry);
          }); // auto save if this is not a new transaction

          if (!entry.get('isNew')) {
            entry.save();
          }
        });
      },
      save: function save() {
        var _this2 = this;

        (0, _ifPromise.default)(this.savableEntry(), function (entry) {
          if (!_this2.recurring && entry.hasChangesThatBreakReconciliation()) {
            _this2.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
              title: Ember.String.htmlSafe('If you save these changes, all reconciliations that include or are after ' + moment(entry.reconciliationBreakingDate()).format('M/D/YY') + ' will be unreconciled.'),
              confirmButton: {
                title: 'Confirm',
                class: 'btn-red text-small-bd'
              },
              cancelButton: {
                title: 'Cancel',
                class: 'btn-white text-small-bd'
              }
            }, {
              confirm: function confirm() {
                Ember.tryInvoke(_this2, 'save', [entry]);
              },
              cancel: function cancel() {}
            });
          } else {
            Ember.tryInvoke(_this2, 'save', [entry]);
          }
        });
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var sortedLines = this.sortedLines;
      var formLines = this.get('shared.formLines');

      if (!formLines) {
        formLines = this.getFormLines(sortedLines);
        this.set('shared.formLines', formLines);
      }

      this.set('formLines', formLines);
      var primaryLine = this.getPrimaryLine(formLines);
      var otherLines = this.getOtherLines(formLines, primaryLine);

      if (!this.get('shared.date')) {
        if (this.get('entry.isNew') && this.defaultDate) {
          this.set('shared.date', this.defaultDate);
        } else {
          this.set('shared.date', formLines.get('firstObject.date') || new Date());
        }
      } // if there is a default account passed as a param, we'll set it on the primary line


      if (this.get('entry.isNew') && this.get('defaultAccount.id')) {
        primaryLine.set('chartAccount', this.defaultAccount);
      }

      this.set('primaryLine', primaryLine);
      this.set('otherLines', otherLines);
    },
    bottomSum: Ember.computed('otherLines.@each.amount', function () {
      return this.otherLines.reduce(function (acc, line) {
        return acc + line.getWithDefault('amount', 0);
      }, 0);
    }),
    withEmptyLinesRemoved: function withEmptyLinesRemoved(otherLines) {
      return otherLines.filter(function (l) {
        return !l.empty();
      });
    },

    /*************************************************
     * TransactionModeMixin Protocol
     **************************************************/
    // can override
    sortedLines: Ember.computed.alias('entry.sortedLines'),
    // can override
    getFormLines: function getFormLines(sortedLines) {
      var primaryLine = this.getPrimaryLine(sortedLines);
      return this.convertFromLines(sortedLines, primaryLine);
    },
    // can override
    getPrimaryLine: function getPrimaryLine(formLines) {
      return formLines.get('firstObject');
    },
    // can override
    getOtherLines: function getOtherLines(formLines, primaryLine) {
      return formLines.filter(function (line) {
        return line !== primaryLine;
      });
    },
    debitSum: Ember.computed('formLines.@each.{kind,amount}', function () {
      return this.formLines.filter(function (l) {
        return l.kind === 'debit';
      }).reduce(function (acc, line) {
        return acc + Math.abs(line.getWithDefault('amount', 0));
      }, 0);
    }),
    creditSum: Ember.computed('formLines.@each.{kind,amount}', function () {
      return this.formLines.filter(function (l) {
        return l.kind === 'credit';
      }).reduce(function (acc, line) {
        return acc + Math.abs(line.getWithDefault('amount', 0));
      }, 0);
    }),
    difference: Ember.computed('debitSum', 'creditSum', function () {
      return (this.debitSum - this.creditSum).round(2) * 1;
    }),
    // we basically try to infer "kind" live so that the debitSum/crediSum/difference calculations work
    _observeCategorization: Ember.observer('formLines.@each.chartAccount', function () {
      var _this3 = this;

      if (this.primaryLine) {
        this.primaryLine.set('kind', this.primaryLine.inferredLineKind()); // form-line.js

        this.otherLines.forEach(function (l) {
          return l.set('kind', l.inferKindFromPrimary(_this3.primaryLine));
        });
      }
    }),
    // translate form lines back to valid journal entry lines
    savableEntry: function savableEntry() {
      var _this4 = this;

      var primaryLine = this.primaryLine;
      var otherLines = this.otherLines; // 1. we start with the primary line since we deduce the kinds of other lines from it. It's the pivot line

      primaryLine.set('kind', primaryLine.inferredLineKind()); // form-line.js
      // 2. infer the other line's kind from the primary line

      otherLines.forEach(function (l) {
        return l.set('kind', l.inferKindFromPrimary(primaryLine));
      }); // 3. remove empty lines

      otherLines = this.withEmptyLinesRemoved(otherLines); // 4. collect all the lines together

      var formLines = [primaryLine].concat(otherLines); // set date on all lines

      formLines.forEach(function (l) {
        return l.set('date', _this4.get('shared.date'));
      }); // 5. we map our form lines back to journal entry line models

      var entry = this.entry;
      var lines = this.convertToLines(formLines);
      entry.set('journalEntryLines', lines);
      return entry;
    },

    /******************
     * TAGS
     ******************/
    allTags: Ember.computed('formLines.@each.computedTags', function () {
      return this.linesToBeTagged().reduce(function (a, v) {
        return a.concat(v.get('computedTags').toArray());
      }, []).uniqBy('name');
    }),

    /*************************************************
     * Implement AddJournalEntryLineTagsMixin
     **************************************************/
    linesToBeTagged: function linesToBeTagged() {
      return this.formLines;
    },
    tagWasAdded: function tagWasAdded()
    /*tag*/
    {},
    tagWasRemoved: function tagWasRemoved()
    /*tag*/
    {}
  });

  _exports.default = _default;
});