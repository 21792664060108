define("zipbooks/components/overlays/dialogs/bank-credit-card-modal", ["exports", "zipbooks/mixins/stripe-payment-form"], function (_exports, _stripePaymentForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stripePaymentForm.default, {
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('card', this.store.createRecord('credit-card')); // make modal narrower

      this.$().closest('.modal-dialog').addClass('modal-dialog-narrow');
      this.setupStripe();
    },
    actions: {
      confirm: function confirm(card) {
        var _this = this;

        var errors = 0;
        this.set('error', null);

        card.get('errors')._clear();

        if (Ember.isBlank(card.get('addressLine1'))) {
          this.set('card.errors.addressLine1', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(card.get('addressCity'))) {
          this.set('card.errors.addressCity', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(card.get('addressState'))) {
          this.set('card.errors.addressState', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(card.get('addressZip'))) {
          this.set('card.errors.addressZip', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(card.get('name'))) {
          this.set('card.errors.name', [{
            message: ''
          }]);
          errors++;
        }

        if (errors > 0) {
          return;
        }

        this.set('buttonState', 'saving');
        var additionalData = {
          address_line1: card.get('addressLine1'),
          address_line2: card.get('addressLine2'),
          address_city: card.get('addressCity'),
          address_state: card.get('addressState'),
          address_zip: card.get('addressZip'),
          name: card.get('name')
        };
        this.stripe.createToken(this.elements[0], additionalData).then(function (result) {
          card.set('token', result.token.id);
          card.save().then(function () {
            _this.afterUpgrade();
          }, function (response) {
            _this.set('buttonStatus', 'invalid');

            _this.overlay.showBanner('error', response.errors[0].detail || 'There was an error saving your card.');
          });
        }).catch(function (response) {
          _this.set('buttonState', 'invalid');

          if (response.error && response.error.type === 'card_error') {
            card.get('errors')._add(response.error.param.camelize(), response.error.message);
          } else if (response.error.type === 'invalid_request_error') {
            _this.set('error', 'Oops! Make sure you fill out all the fields!');
          } else {
            _this.overlay.showBanner('error', response.error.message);
          }
        });
      },
      cancel: function cancel() {
        Ember.tryInvoke(this.callbacks, 'onCancel', []);
        this.close();
      }
    },
    afterUpgrade: function afterUpgrade() {
      this.session.account.set('hasCreditCard', true);
      this.set('buttonState', 'loaded');
      this.close();

      if (this.get('callbacks.upgraded')) {
        this.get('callbacks.upgraded')();
      }
    },
    onFreePlan: Ember.computed('sessions.subscription.plan.id', function () {
      return !this.get('session.subscription.plan.id');
    })
  });

  _exports.default = _default;
});