define("zipbooks/components/quick-stats/accounts-receivable", ["exports", "zipbooks/mixins/testable", "ember-data", "zipbooks/utils/graph-colors"], function (_exports, _testable, _emberData, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    client: Ember.inject.service(),
    accountsReceivable: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.client.cached('GET', "quick-stats/accounts-receivable")
      });
    }),
    total: Ember.computed('accountsReceivable.details.@each', function () {
      return this.get('accountsReceivable.details').reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
    }),
    sortedAccounts: Ember.computed('accountsReceivable.details.@each', function () {
      var accounts = this.get('accountsReceivable.details');
      var total = accounts.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
      var colorIndex = 0;
      return accounts.sort(function (a, b) {
        return b.total - a.total;
      }).map(function (customer) {
        if (customer.customer_name === 'Unassigned') {
          Ember.set(customer, 'backgroundColor', '#BEC5CB');
        } else {
          Ember.set(customer, 'backgroundColor', _graphColors.default.cycle[colorIndex % _graphColors.default.cycle.length]);
          colorIndex += 1;
        }

        Ember.set(customer, 'percent', customer.total * 1 / total * 100);
        return customer;
      });
    }),
    topAccounts: Ember.computed('sortedAccounts.@each', function () {
      if (this.get('sortedAccounts.length') > 4) {
        var other = this.sortedAccounts.slice(4).reduce(function (acc, c) {
          return acc + c.total * 1;
        }, 0);
        return this.sortedAccounts.slice(0, 3).concat([{
          customer_name: 'Other',
          total: other,
          backgroundColor: '#E8EAEE'
        }]);
      } else {
        return this.sortedAccounts;
      }
    })
  });

  _exports.default = _default;
});