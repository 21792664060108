define("zipbooks/components/dashboard/expense-accounts", ["exports", "zipbooks/mixins/chart-custom-tooltip", "zipbooks/utils/graph-colors"], function (_exports, _chartCustomTooltip, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartCustomTooltip.default, {
    session: Ember.inject.service(),
    trialText: Ember.computed(function () {
      return this.session.canHaveTrial('intelligence-basic') ? 'Try Smarter free' : 'Upgrade';
    }),
    total: Ember.computed('data.@each', function () {
      return this.data.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
    }),
    sortedAccounts: Ember.computed('data.@each', function () {
      var accounts = this.data;
      var total = accounts.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
      var colorIndex = 0;
      return accounts.sort(function (a, b) {
        return b.total - a.total;
      }).map(function (customer) {
        if (customer.customer_name === 'Unassigned') {
          Ember.set(customer, 'color', '#D8D8D8');
          Ember.set(customer, 'accent', '#F1F1F1');
        } else {
          Ember.set(customer, 'color', _graphColors.default.cycle[colorIndex % _graphColors.default.cycle.length]);
          colorIndex += 1;
        }

        Ember.set(customer, 'percent', customer.total * 1 / total * 100);
        return customer;
      });
    }),
    topAccounts: Ember.computed('sortedAccounts.@each', function () {
      if (this.get('sortedAccounts.length') > 7) {
        var other = this.sortedAccounts.slice(7).reduce(function (acc, c) {
          return acc + c.total * 1;
        }, 0);
        var total = this.sortedAccounts.reduce(function (acc, c) {
          return acc + c.total * 1;
        }, 0);
        var otherPercent = other / total * 100;
        return this.sortedAccounts.slice(0, 6).concat([{
          color: '#D8D8D8',
          accent: '#F1F1F1',
          chart_account_name: 'Other',
          total: other,
          percent: otherPercent
        }]);
      } else {
        return this.sortedAccounts;
      }
    }),
    didInsertElement: function didInsertElement() {
      this.renderChart();
    },
    _privilegesChanged: Ember.observer('session.privileges.@each', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.renderChart();
      });
    }),
    renderChart: function renderChart() {
      var ctx = this.$('canvas') && this.$('canvas')[0];

      if (!ctx) {
        return;
      }

      var data = this.sortedAccounts.filter(function (c) {
        return c.total * 1 > 0;
      }).slice(0, 100);
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: data.map(function (a) {
              return a.total;
            }),
            backgroundColor: data.map(function (a) {
              return a.color;
            }),
            hoverBackgroundColor: data.map(function (a) {
              return Chart.helpers.color(a.color).saturate(0.1).darken(0.05).rgbString();
            }),
            borderWidth: 0,
            pointRadius: 0
          }],
          labels: data.map(function (a) {
            return a.chart_account_name;
          })
        },
        options: {
          aspectRatio: 1,
          cutoutPercentage: 56,
          legend: {
            display: false
          }
        }
      });
      var ctx2 = this.$('canvas')[1];

      if (!ctx2) {
        return;
      }

      new Chart(ctx2, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: data.map(function (a) {
              return a.total;
            }),
            backgroundColor: data.map(function (a) {
              return a.color;
            }),
            hoverBackgroundColor: data.map(function (a) {
              return Chart.helpers.color(a.color).saturate(0.1).darken(0.05).rgbString();
            }),
            borderWidth: 0,
            // this should match # of sections
            pointRadius: 0
          }],
          labels: data.map(function (a) {
            return a.chart_account_name;
          })
        },
        options: {
          aspectRatio: 1,
          cutoutPercentage: 67,
          legend: {
            display: false
          },
          tooltips: $.extend(true, {
            callbacks: {
              label: function label(tooltipItem, _data) {
                return data[tooltipItem.index];
              }
            }
          }, this.customTooltipOpts(this)),
          hover: {
            onHover: function onHover(e) {
              var point = this.getElementAtEvent(e);
              if (point.length) e.target.style.cursor = 'pointer';else e.target.style.cursor = 'default';
            }
          }
        }
      });
    },
    chartWasClicked: function chartWasClicked(router, context) {
      router.transitionTo('main.transactions', {
        queryParams: {
          chart_account_id: context.chart_account_id,
          tags: null,
          start_date: null,
          end_date: null
        }
      });
    }
  });

  _exports.default = _default;
});