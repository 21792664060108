define("zipbooks/models/project", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/utils/parse-number", "zipbooks/mixins/taggable", "zipbooks/utils/format-seconds-as-hours"], function (_exports, _model, _model2, _parseNumber, _taggable, _formatSecondsAsHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_taggable.default, {
    allowMultipleContacts: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    billable: (0, _model.attr)('boolean'),
    billedDuration: (0, _model.attr)(),
    billingMethod: (0, _model.attr)('string', {
      example: 'project_rate'
    }),
    budget: (0, _model.attr)(),
    description: (0, _model.attr)(),
    duration: (0, _model.attr)(),
    flatAmount: (0, _model.attr)('string'),
    hourlyRate: (0, _model.attr)('string', {
      example: 20
    }),
    name: (0, _model.attr)(),
    timeLastLogged: (0, _model.attr)(),
    contact: (0, _model.belongsTo)('contact'),
    mainParent: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    parent: (0, _model.belongsTo)('project', {
      inverse: 'projects'
    }),
    projects: (0, _model.hasMany)('project', {
      inverse: 'parent'
    }),
    timeEntries: (0, _model.hasMany)('timeEntries'),
    users: (0, _model.hasMany)('users'),
    inheritedHourlyRate: Ember.computed('mainParent.id', 'mainParent.hourlyRate', 'hourlyRate', function () {
      return this.hourlyRate ? this.hourlyRate : this.get('mainParent.hourlyRate');
    }),
    inheritedFlatAmount: Ember.computed('mainParent.id', 'mainParent.flatAmount', 'flatAmount', function () {
      return this.flatAmount ? this.flatAmount : this.get('mainParent.flatAmount');
    }),
    inheritedBillingMethod: Ember.computed('mainParent.id', 'mainParent.billingMethod', 'billingMethod', function () {
      return this.billingMethod ? this.billingMethod : this.get('mainParent.billingMethod');
    }),
    hours: Ember.computed('duration', {
      get: function get() {
        return (0, _formatSecondsAsHours.default)(this.duration || 0, 'h');
      },
      set: function set(_key, value) {
        this.set('duration', (0, _parseNumber.default)(value) * 3600);
        return value;
      }
    }),
    minutes: Ember.computed('duration', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 'm');
    }),
    billedHours: Ember.computed('billedDuration', function () {
      return (0, _formatSecondsAsHours.default)(this.billedDuration, 'h');
    }),
    billedMinutes: Ember.computed('billedDuration', function () {
      return (0, _formatSecondsAsHours.default)(this.billedDuration, 'm');
    }),
    lastTimeEntryDate: Ember.computed('timeEntries', function () {
      var lastEntry = this.get('timeEntries.lastObject');
      return lastEntry ? lastEntry.get('date') : null;
    })
  });

  _exports.default = _default;
});