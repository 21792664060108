define("zipbooks/components/yodlee-widget", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    inClass: 'fadeInFast',
    inSelector: '.fade-selector',
    outClass: 'fadeOutFast',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.openWidget();
    },
    actions: {
      close: function close() {
        this.client.send('POST', 'bank-members', 'check-for-new-members');
        this.callbacks.refresh();
        this.overlay.close(this.ref);
      }
    },
    openWidget: function openWidget() {
      var _this = this;

      this.client.send('GET', 'bank-accounts/widget?provider=yodlee').then(function (details) {
        (0, _jquery.default)('#rsession').val(details.rsession);
        (0, _jquery.default)('#token').val(details.value);
        document.getElementById('rsessionPost').submit();
      }, function (_jqXHR
      /*, textStatus,errorThrown */
      ) {
        _this.overlay.close(_this.ref);
      });
    }
  });

  _exports.default = _default;
});