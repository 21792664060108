define("zipbooks/helpers/pluralize", ["exports", "zipbooks/utils/pluralize"], function (_exports, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pluralize = pluralize;
  _exports.default = void 0;

  function pluralize(params
  /*, hash*/
  ) {
    return (0, _pluralize.default)(params[0], params[1]);
  }

  var _default = Ember.Helper.helper(pluralize);

  _exports.default = _default;
});