define("zipbooks/serializers/cloud-file", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/timestamp-serializer"], function (_exports, _application, _timestampSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_timestampSerializer.default, {
    attrs: {
      cloudFileable: {
        serialize: false
      },
      downloadUrl: {
        serialize: false
      },
      isUploaded: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});