define("zipbooks/serializers/identity", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/embedded-jsonapi-records"], function (_exports, _application, _embeddedJsonapiRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_embeddedJsonapiRecords.default, {});

  _exports.default = _default;
});