define("zipbooks/models/account-settings", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    activePaymentProcessor: (0, _model.attr)(),
    allowOverpayment: (0, _model.attr)('boolean'),
    autoConfirm: (0, _model.attr)('boolean'),
    bankAccountProvider: (0, _model.attr)(),
    bankLimitPerDay: (0, _model.attr)(),
    cobranding: (0, _model.attr)('boolean'),
    customersCalled: (0, _model.attr)(),
    defaultAccentHex: (0, _model.attr)('color'),
    defaultAcceptCreditCards: (0, _model.attr)('boolean'),
    defaultAcceptPaypal: (0, _model.attr)('boolean'),
    defaultAttachPdf: (0, _model.attr)('boolean'),
    defaultBasis: (0, _model.attr)(),
    defaultEmailBody: (0, _model.attr)(),
    defaultEmailSubject: (0, _model.attr)(),
    defaultNotes: (0, _model.attr)(),
    defaultReplyTo: (0, _model.attr)(),
    defaultTerms: (0, _model.attr)(),
    estimateDefaultEmailBody: (0, _model.attr)(),
    estimateDefaultEmailSubject: (0, _model.attr)(),
    estimateDefaultNotes: (0, _model.attr)(),
    estimateDefaultTerms: (0, _model.attr)(),
    estimatesCalled: (0, _model.attr)(),
    lockDate: (0, _model.attr)('date'),
    logoHeight: (0, _model.attr)(),
    messagingDefaultBody: (0, _model.attr)(),
    messagingPhone: (0, _model.attr)(),
    numberBy: (0, _model.attr)(),
    paymentRetrySchedule: (0, _model.attr)(),
    receiptDefaultEmailBody: (0, _model.attr)(),
    reportNotes: (0, _model.attr)(),
    reportsDefaultShowUnconfirmed: (0, _model.attr)(),
    retryPayments: (0, _model.attr)('boolean'),
    showChartClass: (0, _model.attr)('boolean'),
    showChartCode: (0, _model.attr)(),
    trackingRequireContact: (0, _model.attr)('boolean'),
    trackingRequireProject: (0, _model.attr)('boolean'),
    trackTaxes: (0, _model.attr)('boolean'),
    vendorsCalled: (0, _model.attr)(),
    account: (0, _model.belongsTo)('account'),
    defaultInvoiceSalesChartAccount: (0, _model.belongsTo)('chart-account'),
    minDate: Ember.computed('lockDate', function () {
      if (this.lockDate) {
        return moment(this.lockDate).add(1, 'days');
      }
    }),
    paymentScheduleFirst: Ember.computed('paymentRetrySchedule.@each', {
      get: function get() {
        if (!this.paymentRetrySchedule) this.set('paymentRetrySchedule', []);
        return this.paymentRetrySchedule[0];
      },
      set: function set(_key, value) {
        if (value === 'none') {
          var schedule = this.paymentRetrySchedule;
          schedule.splice(0, 1);
          this.set('paymentRetrySchedule', schedule);
        } else {
          this.set('paymentRetrySchedule.0', value);
          return value;
        }
      }
    }),
    paymentScheduleSecond: Ember.computed('paymentRetrySchedule.@each', {
      get: function get() {
        if (!this.paymentRetrySchedule) this.set('paymentRetrySchedule', []);
        return this.paymentRetrySchedule[1];
      },
      set: function set(_key, value) {
        if (value === 'none') {
          var schedule = this.paymentRetrySchedule;
          schedule.splice(1, 1);
          this.set('paymentRetrySchedule', schedule);
        } else {
          this.set('paymentRetrySchedule.1', value);
          return value;
        }
      }
    }),
    paymentScheduleThird: Ember.computed('paymentRetrySchedule.@each', {
      get: function get() {
        if (!this.paymentRetrySchedule) this.set('paymentRetrySchedule', []);
        return this.paymentRetrySchedule[2];
      },
      set: function set(_key, value) {
        if (value === 'none') {
          var schedule = this.paymentRetrySchedule;
          schedule.splice(2, 1);
          this.set('paymentRetrySchedule', schedule);
        } else {
          this.set('paymentRetrySchedule.2', value);
          return value;
        }
      }
    })
  });

  _exports.default = _default;
});