define("zipbooks/components/forms/phone-input", ["exports", "zipbooks/components/form-input", "zipbooks/helpers/format-phone"], function (_exports, _formInput, _formatPhone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formInput.default.extend({
    layoutName: 'components/form-input',
    keyUp: function keyUp(_event) {
      var current = this.value;
      var newValue = (0, _formatPhone.formatPhone)([current], {
        partial: true
      });
      this.$('input').val(newValue);
    },
    valueTransform: Ember.computed('value', 'money', {
      get: function get(_key) {
        return (0, _formatPhone.formatPhone)([this.value], {});
      },
      set: function set(_key, value) {
        this.set('value', value.replace(/[^0-9]/g, ''));
        return value;
      }
    })
  });

  _exports.default = _default;
});