define("zipbooks/templates/components/overlays/pop-over", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2h8pA/m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"popover-overlay\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"closePopover\"],null]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"popover\"],[8],[0,\"\\n  \"],[1,[28,\"component\",[[24,[\"model\",\"component\"]]],[[\"model\",\"callbacks\",\"close\"],[[24,[\"model\",\"model\"]],[24,[\"callbacks\"]],[28,\"action\",[[23,0,[]],\"closePopover\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/overlays/pop-over.hbs"
    }
  });

  _exports.default = _default;
});