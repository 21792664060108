define("zipbooks/components/chart-account-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    syncStateClass: 'loaded',
    linkChartAccountId: Ember.computed('filteredChartAccountId', 'chartAccount.id', function () {
      return this.filteredChartAccountId === this.get('chartAccount.id') ? null : this.get('chartAccount.id');
    }),
    linkClass: Ember.computed('filteredChartAccountId', 'chartAccount.id', function () {
      return this.filteredChartAccountId === this.get('chartAccount.id') ? 'active' : null;
    }),
    actions: {
      updateAccount: function updateAccount(member) {
        var _this = this;

        this.set('syncStateClass', 'loading');
        this.client.send('POST', 'bank-members/' + this.get('chartAccount.bankAccount.member.id') + '/aggregate').then(function () {
          _this.overlay.showDrawer('drawers/bank-aggregate-form', member);

          Ember.run.later(_this, function () {
            _this.set('syncStateClass', 'loaded');
          }, 1000);
        }).catch(function () {
          _this.overlay.showBanner('error', 'It appears there was an error, go to Manage Bank Accounts to verify there are no connection issues.');
        });
      }
    }
  });

  _exports.default = _default;
});