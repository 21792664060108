define("zipbooks/components/documents-upload", ["exports", "zipbooks/components/file-upload"], function (_exports, _fileUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileUpload.default.extend({
    isPrivate: true,
    maxSize: Math.pow(1024, 2) * 10,
    _filesWereDropped: Ember.on('init', Ember.observer('fileEvent', function () {
      var _this = this;

      var event = this.fileEvent;

      if (event) {
        var files = event.testingFiles || (event.dataTransfer ? event.dataTransfer.files : event.target.files);

        if (files.length > 0) {
          this.handleFiles(files);
        }

        Ember.run.next(function () {
          _this.set('fileEvent', null);
        });
      }
    })),
    // overridden
    setUploaders: function setUploaders(uploaders) {
      this.set('uploaders', uploaders);
    },
    didFinishUploading: function didFinishUploading(cloudFiles) {
      var _this2 = this;

      this._super.apply(this, arguments);

      var promises = cloudFiles.map(function (cloudFile) {
        var doc = _this2.store.createRecord('document', {
          contentType: cloudFile.get('contentType'),
          filename: cloudFile.get('filename'),
          title: cloudFile.get('filename'),
          cloudFile: cloudFile
        });

        return doc.save({
          adapterOptions: {
            token: _this2.sessionToken
          }
        });
      });
      Ember.RSVP.all(promises).then(function (results) {
        _this2.set('uploaders', []);

        Ember.tryInvoke(_this2, 'documentsDidUpload', results);
      });
    }
  });

  _exports.default = _default;
});