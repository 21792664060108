define("zipbooks/utils/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.write = write;

  function write(text) {
    var textarea = document.createElement('textarea');
    textarea.style.hidden = true;
    textarea.style.alpha = 0;
    textarea.style.height = 0;
    textarea.style.width = 0;
    document.body.appendChild(textarea);
    textarea.value = text;
    textarea.select();
    document.execCommand('copy');
    textarea.remove();
  }
});