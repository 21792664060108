define("zipbooks/helpers/has-subscription-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    onUpdate: Ember.observer('session.subscriptionFlags.@each', function () {
      this.recompute();
    }),
    compute: function compute(params) {
      return this.session.subscriptionFlags.includes(params[0]);
    }
  });

  _exports.default = _default;
});