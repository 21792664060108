define("zipbooks/components/drawers/identity-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/utils/redirect"], function (_exports, _modelDrawer, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    classNames: ['overlay'],
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    title: 'Edit Your Profile',
    modelId: Ember.computed('session.currentUser.identity.id', function () {
      return this.get('session.currentUser.identity.id');
    }),
    verifyCode: '',
    actions: {
      linkFacebook: function linkFacebook() {
        var path = '/?drawer=identity.me&token=' + this.get('session.token');
        (0, _redirect.default)(window.location.origin + '/v2/auth-link/facebook?callback-url=' + window.location.origin + path);
      },
      linkGoogle: function linkGoogle() {
        var path = '/?drawer=identity.me&token=' + this.get('session.token');
        (0, _redirect.default)(window.location.origin + '/v2/auth-link/google?callback-url=' + window.location.origin + path);
      },
      linkLinkedin: function linkLinkedin() {
        var path = '/?drawer=identity.me&token=' + this.get('session.token');
        (0, _redirect.default)(window.location.origin + '/v2/auth-link/linkedin?callback-url=' + window.location.origin + path);
      },
      sendVerificationEmail: function sendVerificationEmail() {
        var _this = this;

        this.client.GET('auth', 'request-verification-email').then(function () {
          _this.overlay.showBanner('success', 'Verification email sent.');
        }, function (jqXHR
        /*, textStatus,errorThrown */
        ) {
          if (jqXHR.responseJSON && jqXHR.responseJSON.errors[0].detail && jqXHR.status === 422) {
            _this.overlay.showBanner('error', jqXHR.responseJSON.errors[0].detail, {
              title: 'Oops!'
            });
          } else {
            _this.overlay.showBanner('error', 'There was some problem on our end. Sorry!', {
              title: 'Oops!'
            });
          }
        });
      },
      sendPasswordReset: function sendPasswordReset() {
        var _this2 = this;

        this.client.POST('auth', 'request-password-reset', {
          email: this.get('session.currentUser.identity.email')
        }).then(function ()
        /*resp*/
        {
          _this2.overlay.showBanner('success', "We've sent a reset link to your email address.", {
            title: 'Ok!'
          });
        });
      },
      disableTFA: function disableTFA() {
        var _this3 = this;

        this.client.POST('auth', 'disable-tfa', {
          code: this.verifyCode
        }).then(function () {
          _this3.set('buttonState', 'loaded');

          _this3.overlay.showBanner('success', 'Two-Factor-Authentication Disabled!');
        }, function (jqXHR
        /*, textStatus,errorThrown */
        ) {
          _this3.set('buttonState', 'invalid');

          if (jqXHR.responseJSON && jqXHR.status === 422) {
            _this3.set('errors', {
              verifyCode: [{
                message: 'The verification code is incorrect.'
              }]
            });
          } else {
            _this3.overlay.showBanner('error', 'There was some problem on our end. Sorry!', {
              title: 'Oops!'
            });
          }
        });
      }
    },
    afterSave: function afterSave(model) {
      var _this4 = this;

      var token = model.get('userTokens').find(function (obj) {
        return obj.user_id == _this4.get('session.currentUser.id');
      }).token;
      this.set('session.token', token);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});