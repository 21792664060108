define("zipbooks/controllers/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    view: 'reset',
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', null);

        if (this.get('model.password') !== this.get('model.passwordConfirmation')) {
          this.set('errors', {
            passwordConfirmation: [{
              message: 'Passwords must match.'
            }]
          });
        } else if (this.get('model.password.length') < 8) {
          this.set('errors', {
            password: [{
              message: 'Password must be at least 8 characters.'
            }]
          });
        } else {
          var attrs = {
            token: this.get('model.token'),
            password: this.get('model.password')
          };
          this.client.send('PATCH', 'auth', 'reset-password', attrs).then(function (resp) {
            _this.store.pushPayload(resp);

            var identity = _this.store.peekRecord('identity', resp.data.id);

            _this.set('identity', identity);

            _this.set('buttonState', 'loaded');

            if (_this.get('identity.userTokens.length') === 1) {
              _this.session.login(identity.userTokens[0].token, false, false);

              _this.transitionToRoute('main');
            } else {
              _this.set('view', 'chooseAccount');
            }
          }, function ()
          /*jqXHR, textStatus,errorThrown */
          {
            _this.overlay.showBanner('error', 'Reset failed.');
          });
        }
      }
    }
  });

  _exports.default = _default;
});