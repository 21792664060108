define("zipbooks/helpers/chart-account-icon", ["exports", "zipbooks/utils/classifications"], function (_exports, _classifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chartAccountIcon = chartAccountIcon;
  _exports.default = void 0;

  function chartAccountIcon(params
  /*, hash*/
  ) {
    return ((0, _classifications.default)()[params[0]] || 'Assets').icon;
  }

  var _default = Ember.Helper.helper(chartAccountIcon);

  _exports.default = _default;
});