define("zipbooks/components/drawers/gusto-integration-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    store: Ember.inject.service(),
    title: 'Gusto Preferences',
    model: Ember.computed(function () {
      return this.store.queryRecord('gusto-integration', {});
    })
  });

  _exports.default = _default;
});