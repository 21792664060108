define("zipbooks/routes/main/project", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    model: function model(params) {
      return this.store.findRecord('project', params.id, {
        reload: true
      });
    }
  });

  _exports.default = _default;
});