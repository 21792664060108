define("zipbooks/components/drawers/square-integration-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    title: 'Square Preferences',
    model: Ember.computed('session.account.id', function () {
      return this.store.queryRecord('square-integration', {});
    }),
    afterModel: function afterModel(model) {
      this.set('integration', model);
    },
    actions: {
      disconnect: function disconnect() {
        var _this = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: 'Are you sure you want to disconnect Square. Your data will no longer sync.',
          confirmButton: {
            title: 'Disconnect',
            class: 'btn-red text-small-bd'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white text-small-bd'
          }
        }, {
          confirm: function confirm() {
            _this.client.POST('integrations/square/revoke').then(function () {
              return _this.session.fetchLoggedInUser();
            });

            _this.handleClose(0);
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      }
    }
  });

  _exports.default = _default;
});