define("zipbooks/mixins/integrations-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    client: Ember.inject.service(),
    integrationsPromise: function integrationsPromise() {
      var _this = this;

      return this.client.GET('integrations').then(function (payload) {
        _this.store.pushPayload(payload);

        return payload.data.map(function (p) {
          return p.type;
        }).map(function (t) {
          return _this.store.peekAll(t).firstObject;
        });
      });
    }
  });

  _exports.default = _default;
});