define("zipbooks/components/gusto/assign-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchUsers();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('reference.userAssociator', this);
    },
    fetchUsers: function fetchUsers() {
      var _this = this;

      this.set('loading', true);
      Ember.RSVP.all([this.store.cachedQuery('user', {
        filter: {
          archived: false
        },
        sort: 'display_name',
        include: 'identity.avatar_cloud_file'
      }).then(function (users) {
        _this.set('session.currentUser.permissionsMap.payroll', 9);

        _this.set('users', users);
      }), this.store.findAll('teamAccount').then(function (teams) {
        return _this.set('teams', teams);
      })]).then(function () {
        _this.set('loading', false);
      });
    },
    actions: {
      toggleManagePayroll: function toggleManagePayroll(user) {
        if (!user.owner && user.id !== this.get('session.currentUser.id')) {
          if (user.get('permissionsMap.payroll') === 9) {
            user.set('permissionsMap.payroll', 0);
          } else {
            user.set('permissionsMap.payroll', 9);
          }
        }
      }
    },
    save: function save() {
      // We don't include this in all because it might fail if a team user is setting up Gusto as they can't
      // change their own permissions
      this.teams.map(function (team) {
        return team.save();
      });
      return Ember.RSVP.all(this.users.map(function (user) {
        return user.save();
      }));
    }
  });

  _exports.default = _default;
});