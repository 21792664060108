define("zipbooks/components/drawers/transaction/custom-mode", ["exports", "zipbooks/mixins/transaction-mode"], function (_exports, _transactionMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transactionMode.default, {
    savableEntry: function savableEntry() {
      var primaryLine = this.primaryLine;
      var otherLines = this.otherLines; // we don't show the amount when there is only one bottom line, so we set it here to balance

      if (otherLines.get('length') === 1) {
        otherLines.forEach(function (l) {
          return l.set('amount', primaryLine.getWithDefault('amount', 0));
        });
      }

      return this._super.apply(this, arguments);
    },
    hideAmount: Ember.computed('formLines.@each', function () {
      return this.get('formLines.length') < 3;
    })
  });

  _exports.default = _default;
});