define("zipbooks/components/suggest/plan-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/plans"], function (_exports, _autoSuggest, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    layoutName: 'components/auto-suggest',
    resultsMatchingSearchString: function resultsMatchingSearchString(_search) {
      return this.options;
    },
    labelForResult: function labelForResult(result) {
      var id = Ember.get(result, 'id');

      var plan = _plans.default.find(function (p) {
        return p.id == id;
      });

      if (plan) {
        return "".concat(plan.name, " ($").concat(plan.price, "/mo)");
      } else {
        return Ember.get(result, 'name');
      }
    }
  });

  _exports.default = _default;
});