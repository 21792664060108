define("zipbooks/utils/observe-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = observeChange;

  function observeChange() {
    if (arguments.length < 2) {
      (false && !(false) && Ember.assert('must have at least one key, one function and optional options'));
    }

    var keys = [];
    var func;
    var options = {};

    for (var i in arguments) {
      var a = arguments[i];

      if (Ember.typeOf(a) === 'string') {
        keys.push(a);
      } else if (Ember.typeOf(a) === 'function') {
        func = a;
      } else if (Ember.typeOf(a) === 'object') {
        options = a;
      }
    }

    var obs = Ember.observer.apply(void 0, keys.concat([function () {
      var _this = this;

      var changes = {};
      keys.forEach(function (key) {
        var oldKey = "".concat(key, "-old");

        var old = _this.get(oldKey);

        var current = _this.get(key);

        changes[key] = {
          old: old,
          new: current
        };

        _this.set(oldKey, current);
      });
      var anyChanges = Object.keys(changes).any(function (k) {
        return changes[k].old !== changes[k].new;
      });

      if (anyChanges) {
        func.call(this, changes);
      }
    }]));
    return options.init ? Ember.on('init', obs) : obs;
  }
});