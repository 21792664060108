define("zipbooks/components/transaction-sheet/tag-input", ["exports", "zipbooks/components/tag-input"], function (_exports, _tagInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tagInput.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('classNames', ['tag-input position-relative']);
    }
  });

  _exports.default = _default;
});