define("zipbooks/components/suggest/user-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/search-in"], function (_exports, _autoSuggest, _searchIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'User',
    layoutName: 'components/auto-suggest',
    store: Ember.inject.service(),
    throttle: 500,
    filter: [],
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      return this.store.cachedQuery('user', {
        filter: {
          archived: false
        },
        sort: 'display_name',
        include: 'identity.avatar_cloud_file'
      }).then(function (results) {
        return (0, _searchIn.default)(results, 'name', search);
      });
    },
    labelForResult: function labelForResult(result) {
      if (result.get('identity.name')) {
        return result.get('identity.name');
      } else {
        return result.get('identity.email');
      }
    }
  });

  _exports.default = _default;
});