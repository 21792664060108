define("zipbooks/routes/application", ["exports", "zipbooks/utils/redirect", "zipbooks/utils/intercom", "zipbooks/utils/google-analytics", "zipbooks/utils/pluck-query-param"], function (_exports, _redirect, _intercom, _googleAnalytics, _pluckQueryParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    actions: {
      error: function error(transition
      /*, originRoute*/
      ) {
        if (!transition) {
          return true;
        }

        if (transition.responseJSON) {
          if (transition.responseJSON.error === 'user_not_found') {
            this.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared

            (0, _redirect.default)('/login');
            return false;
          }
        } // handle an error response


        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = (transition.errors || [])[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var error = _step.value;

            if (error.status === '401') {
              this.session.rememberLoginRedirectParams(); // we do this full browser transition to make sure all current user data is cleared

              (0, _redirect.default)('/login');
              return false;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return true;
      },
      willTransition: function willTransition(transition) {
        transition.promise.then(function () {
          _intercom.default.update();

          _googleAnalytics.default.update();
        });
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments); // very first thing, make sure that we aren't running against production in test mode, just in case


      if (Ember.testing && window.location.origin.indexOf('zipbooks.com') >= 0) {
        alert('You are running tests while pointed at production!');
      } // people are getting errors that their cookie headers are too big. I'm pretty sure its because of auto-suggest
      // storing too many results in there, so lets get rid of all those


      Object.keys(Cookies.get()).forEach(function (key) {
        if (key.includes('autosuggest-count')) {
          Cookies.remove(key);
        }
      });
      var error = (0, _pluckQueryParam.default)('error');

      if (error) {
        this.overlay.showBanner('error', decodeURI(error)); // discard any success if theres an also an error, error takes precedence

        (0, _pluckQueryParam.default)('success');
      }

      var success = (0, _pluckQueryParam.default)('success');

      if (success) {
        this.overlay.showBanner('success', decodeURI(success));
      }
    }
  });

  _exports.default = _default;
});