define("zipbooks/models/public-profile", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    identifier: (0, _model.attr)(),
    fullName: (0, _model.attr)(),
    givenName: (0, _model.attr)(),
    familyName: (0, _model.attr)(),
    gender: (0, _model.attr)(),
    location: (0, _model.attr)(),
    timeZone: (0, _model.attr)(),
    utcOffset: (0, _model.attr)(),
    geoCity: (0, _model.attr)(),
    geoState: (0, _model.attr)(),
    geoStateCode: (0, _model.attr)(),
    geoCountry: (0, _model.attr)(),
    geoCountryCode: (0, _model.attr)(),
    geoLat: (0, _model.attr)(),
    geoLng: (0, _model.attr)(),
    bio: (0, _model.attr)(),
    site: (0, _model.attr)(),
    avatar: (0, _model.attr)(),
    employmentDomain: (0, _model.attr)(),
    employmentName: (0, _model.attr)(),
    employmentTitle: (0, _model.attr)(),
    employmentRole: (0, _model.attr)(),
    employmentSeniority: (0, _model.attr)(),
    facebookHandle: (0, _model.attr)(),
    githubHandle: (0, _model.attr)(),
    githubAvatar: (0, _model.attr)(),
    githubCompany: (0, _model.attr)(),
    githubBlog: (0, _model.attr)(),
    githubFollowers: (0, _model.attr)(),
    githubFollowing: (0, _model.attr)(),
    twitterHandle: (0, _model.attr)(),
    twitterBio: (0, _model.attr)(),
    twitterFollowers: (0, _model.attr)(),
    twitterFollowing: (0, _model.attr)(),
    twitterLocation: (0, _model.attr)(),
    twitterSite: (0, _model.attr)(),
    twitterAvatar: (0, _model.attr)(),
    linkedinHandle: (0, _model.attr)(),
    googleplusHandle: (0, _model.attr)(),
    aboutmeHandle: (0, _model.attr)(),
    aboutmeBio: (0, _model.attr)(),
    aboutmeAvatar: (0, _model.attr)(),
    gravatarHandle: (0, _model.attr)(),
    gravatarAvatar: (0, _model.attr)()
  });

  _exports.default = _default;
});