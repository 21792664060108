define("zipbooks/components/drawers/transaction/transfer-mode", ["exports", "zipbooks/mixins/transaction-mode"], function (_exports, _transactionMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transactionMode.default, {
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var entry = this.entry;
      var lines = entry.get('journalEntryLines');
      var fromLine = this.getFromLine(lines);
      var toLine = this.getToLine(lines);
      fromLine = this.convertFromLine(fromLine);
      toLine = this.convertFromLine(toLine); // if there is a default account passed as a param, we'll set it on the from line

      if (this.get('entry.isNew') && this.get('defaultAccount.id')) {
        fromLine.set('chartAccount', this.defaultAccount);
      }

      this.set('fromLine', fromLine);
      this.set('toLine', toLine);
      var formLines = [fromLine, toLine];
      this.set('formLines', formLines);
      this.set('shared.formLines', formLines);
    },
    getFromLine: function getFromLine() {
      var lines = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (lines.get('length') > 0) {
        return lines.filter(function (l) {
          return l.get('kind') === 'credit' && l.get('chartAccount.classification') !== 'In transit';
        }).get('firstObject');
      } else {
        return this.store.createRecord('journalEntryLine', {
          kind: 'credit',
          date: new Date(),
          chartAccount: null,
          billToContact: null,
          tags: []
        });
      }
    },
    getToLine: function getToLine() {
      var lines = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (lines.get('length') > 0) {
        return lines.filter(function (l) {
          return l.get('kind') === 'debit' && l.get('chartAccount.classification') !== 'In transit';
        }).get('firstObject');
      } else {
        return this.store.createRecord('journalEntryLine', {
          kind: 'debit',
          date: new Date(),
          chartAccount: null,
          billToContact: null,
          tags: []
        });
      }
    },
    savableEntry: function savableEntry() {
      var fromLine = this.fromLine;
      var toLine = this.toLine;
      fromLine.set('kind', 'credit');
      toLine.set('kind', 'debit');
      toLine.set('amount', fromLine.getWithDefault('amount', 0));
      var formLines = [fromLine].concat(toLine);
      var entry = this.entry;
      var lines = this.convertToLines(formLines); // now, if there were any lines we didn't show the user, we need to add them back here.
      // This happens when someone does a match on two jes that have different dates. The transfer
      // drawer doesn't handle more than 2 lines, so we just show the 2 lines and then add back
      // the lines we didn't show

      if (entry.get('journalEntryLines.length') > 2) {
        var ids = formLines.mapBy('id');
        var hiddenLines = entry.get('journalEntryLines').filter(function (line) {
          return !ids.includes(line.id);
        });
        lines = lines.concat(hiddenLines);
      }

      entry.set('journalEntryLines', lines);
      return entry;
    }
  });

  _exports.default = _default;
});