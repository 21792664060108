define("zipbooks/mixins/infinite-scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    infiniteScrollEnabled: true,
    registerInfiniteScroll: function registerInfiniteScroll(selector) {
      var _this = this;

      this.set('allLoaded-top', false);
      this.set('allLoaded-bottom', false);
      this.scrollable = this.$(selector);
      this.scrollable.on('scroll.' + this.elementId, function () {
        _this.checkScroll();
      });
      $(window).on('resize.' + this.elementId, function () {
        _this.checkScroll();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.scrollable) {
        this.scrollable.off('.' + this.elementId);
      }

      $(window).off('.' + this.elementId);
    },
    checkScroll: function checkScroll() {
      if (!this.infiniteScrollEnabled) {
        return;
      }

      if (this.isAtBottom()) {
        Ember.run.throttle(this, 'checkAfterDelay', 200);
      } else if (this.isAtTop()) {
        Ember.run.throttle(this, 'checkAfterDelay', 200);
      }
    },
    checkAfterDelay: function checkAfterDelay() {
      // if still visible after 2 seconds of not scrolling, mark as seen
      if (this.isAtBottom()) {
        this._loadMore('down');
      } else if (this.isAtTop()) {
        this._loadMore('up');
      }
    },
    _loadMore: function _loadMore(direction) {
      var _this2 = this;

      if (this.get("allLoaded-".concat(direction))) {
        return;
      }

      this.set('infiniteScrollEnabled', false);
      var currentHeight = this.scrollable[0].scrollHeight;
      var currentScrollY = this.scrollable.scrollTop();
      this.loadMore(direction, function (handled, allLoaded) {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }

        _this2.set('infiniteScrollEnabled', true);

        if (handled) {
          if (allLoaded) {
            _this2.set("allLoaded-".concat(direction), true);
          }

          if (direction === 'up') {
            Ember.run.scheduleOnce('afterRender', _this2, function () {
              var diff = _this2.scrollable[0].scrollHeight - currentHeight;

              _this2.scrollable.scrollTop(currentScrollY + diff);
            });
          }
        }
      });
    },
    loadMore: function loadMore() {
      (false && Ember.warn('You must implement this', {
        id: 'loadMore'
      }));
    },
    isAtBottom: function isAtBottom() {
      return this.distanceFromBottom() < 50;
    },
    isAtTop: function isAtTop() {
      var $scrollable = this.scrollable;
      return $scrollable.scrollTop() < 50;
    },
    distanceFromBottom: function distanceFromBottom() {
      var $scrollable = this.scrollable;
      var scrollableT = $scrollable.scrollTop();
      var scrollableH = $scrollable[0].scrollHeight;
      var viewportR = $scrollable[0].getBoundingClientRect();
      var viewportH = viewportR.height;
      return scrollableH - viewportH - scrollableT;
    }
  });

  _exports.default = _default;
});