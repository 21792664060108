define("zipbooks/templates/main/partials/integration-box/-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WKeQB/rm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"class\",[29,[[28,\"first\",[[24,[\"logoClass\"]],\"w-10rem\"],null]]]],[11,\"src\",[22,\"logo\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"gray d-flex align-items-center\"],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"font-size-18px mr-1 \",[28,\"if\",[[24,[\"connected\"]],\" icon-check-circle purple \",\"icon-check-lines gray-500\"],null]]]],[8],[9],[0,\"\\n  \"],[1,[28,\"if\",[[24,[\"connected\"]],\"Connected\",\"Not Connected\"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"privilege\"]]],null,{\"statements\":[[4,\"needs-privilege\",[[24,[\"privilege\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"connected\"]]],null,{\"statements\":[[0,\"        but unavailable for use on Starter plan\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/main/partials/integration-box/-logo.hbs"
    }
  });

  _exports.default = _default;
});