define("zipbooks/utils/navigate-to", ["exports", "zipbooks/utils/drawer-link-for", "zipbooks/utils/tag-serialization"], function (_exports, _drawerLinkFor, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = navigateTo;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function navigateTo(router, type, id, tag, overlayService) {
    // if it has a page of its own
    if (['project', 'invoice', 'estimate', 'recurring-profile', 'contact', 'bill', 'reconciliation'].indexOf(type) > -1) {
      router.transitionTo("main.".concat(type), id);
    } else if (type === 'label' && tag && tag.kind === 'Message') {
      var tags = [Ember.Object.create({
        id: id,
        name: tag.name,
        createdAt: tag.createdAt,
        kind: 'Message',
        taggableType: tag.taggableType
      })];
      router.transitionTo('main.transactions', {
        queryParams: {
          tags: _tagSerialization.default.serialize(tags),
          messages_label_id: id
        }
      });
      overlayService.showPopout('messaging/accountant-messenger', {
        anchorLabelId: id
      });
    } // otherwise, we'll assume it shows up in a drawer
    else {
        if (type === 'transaction') {
          type = 'journal-entry';
        }

        var params = (0, _drawerLinkFor.default)([_defineProperty({}, type, id)]);
        router.transitionTo({
          queryParams: {
            drawer: params
          }
        });
      }
  }
});