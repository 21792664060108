define("zipbooks/components/pros/gallery-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    category: null,
    cloudFilesInCategory: Ember.computed('page.cloudFiles.@each', 'category', function () {
      var _this = this;

      if (this.category) {
        return this.get('page.cloudFiles').filter(function (cf) {
          return cf.category === _this.category;
        });
      } else {
        return this.get('page.cloudFiles');
      }
    }),
    actions: {
      didUpload: function didUpload() {
        this.didUpload.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});