define("zipbooks/helpers/format-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDuration = formatDuration;
  _exports.default = void 0;

  function formatDuration(params
  /*, hash*/
  ) {
    return moment.duration(params[0], 'seconds').locale('en').humanize();
  }

  var _default = Ember.Helper.helper(formatDuration);

  _exports.default = _default;
});