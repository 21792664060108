define("zipbooks/app", ["exports", "zipbooks/resolver", "ember-load-initializers", "zipbooks/config/environment", "zipbooks/overrides/model", "zipbooks/overrides/component", "zipbooks/overrides/number", "zipbooks/overrides/promise-object", "zipbooks/overrides/store", "zipbooks/overrides/text-area", "zipbooks/overrides/text-field", "zipbooks/overrides/array", "zipbooks/overrides/string", "zipbooks/overrides/custom-inflector-rules"], function (_exports, _resolver, _emberLoadInitializers, _environment, _model, _component, _number, _promiseObject, _store, _textArea, _textField, _array, _string, _customInflectorRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Leave formatting alone in this file for easier diffing when upgrading Ember
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    customEvents: {
      // add support for the paste event
      paste: 'paste'
    },
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});