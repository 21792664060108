define("zipbooks/templates/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bsrXZayH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"tooltipText\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"tooltip-text\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"font-weight-bold\"],[8],[1,[22,\"tooltipText\"],false],[9],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[\"tooltip-content \",[22,\"tooltipContentClass\"]]]],[8],[14,1],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"icon\"]],[24,[\"icon\"]],\"icon-tooltip\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"for\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"icon-tooltip-for-hidden\"],[8],[0,\"|\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"path1\"],[8],[9],[7,\"span\",true],[10,\"class\",\"path2\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"tooltip-content \",[22,\"tooltipContentClass\"]]]],[8],[14,1],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/tool-tip.hbs"
    }
  });

  _exports.default = _default;
});