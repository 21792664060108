define("zipbooks/components/drawers/bank-aggregate-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/utils/log", "zipbooks/mixins/add-bank"], function (_exports, _modelDrawer, _log, _addBank) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend(_addBank.default, {
    title: 'Connect Bank Account',
    // STARTS HERE
    afterModel: function afterModel(model) {
      this.aggregate(model);
    },

    /************************************************************************
     * AddBankMixin
     ************************************************************************/
    aggregate: function aggregate(member) {
      var _this = this;

      (0, _log.default)('info', 'aggregating existing bank account');
      this.showWidget({
        guid: member.externalId
      }).then(function () {
        (0, _log.default)('info', 'calling aggregate and being done fixing');

        _this.set('state', 'default');

        _this.set('message', 'This bank connection is established and working properly.');

        _this.client.send('POST', 'bank-members', "".concat(member.id, "/sync")).then(function () {});
      });
    },

    /************************************************************************
     * Helpers
     ************************************************************************/
    handleClose: function handleClose() {
      var _this2 = this;

      this.client.send('POST', 'bank-members', 'check-for-new-members').then(function () {
        _this2.store.peekAll('bank-account').forEach(function (bankAccount) {
          bankAccount.reload();
        });
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});