define("zipbooks/controllers/main/contacts", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller", "zipbooks/utils/format-called"], function (_exports, _controllerPagination, _indexController, _formatCalled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    queryParams: ['archived', 'kind'],
    archived: false,
    kind: 'all',
    sort: 'name',
    session: Ember.inject.service(),
    kindDisplay: Ember.computed('kind', function () {
      switch (this.kind) {
        case 'customers':
          return this.get('session.account.settings.customersCalled');

        case 'vendors':
          return this.get('session.account.settings.vendorsCalled');

        case 'recognized-vendors':
          return 'Recongized ' + this.get('session.account.settings.vendorsCalled');

        default:
          return this.kind;
      }
    }),
    actions: {
      makeVisisble: function makeVisisble(contact) {
        var _this = this;

        contact.set('isVisible', true);
        contact.save().then(function () {
          return _this.send('refreshRoute');
        });
      }
    },
    typeFilterTitle: Ember.computed('kind', function () {
      if (this.kind === 'customers') {
        return (0, _formatCalled.default)(this.get('session.account.settings.customersCalled'), ['pluralize']);
      } else if (this.kind === 'vendors') {
        return (0, _formatCalled.default)(this.get('session.account.settings.vendorsCalled'), ['pluralize']);
      }

      return this.kind;
    })
  });

  _exports.default = _default;
});