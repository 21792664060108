define("zipbooks/components/mini-tracker/accountant-tracker", ["exports", "zipbooks/mixins/time-tracking", "zipbooks/mixins/clicked-outside"], function (_exports, _timeTracking, _clickedOutside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_timeTracking.default, _clickedOutside.default, {
    miniTrackerTicker: Ember.inject.service(),
    accountantStore: Ember.inject.service(),
    ticker: Ember.computed.alias('miniTrackerTicker'),
    store: Ember.computed.alias('accountantStore'),
    user: Ember.computed.alias('session.accountantUser'),
    classNames: 'mini-tracker',
    classNameBindings: ['contactsMatch::mini-tracker-red', 'loaded::hidden'],
    loaded: false,
    showEditorForTimeEntryId: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var token = this.get('session.currentUser.identity.accountantToken');
      var params = {
        filter: {
          linked_account_id: this.get('session.currentUser.account.id')
        }
      };
      return this.client.GET('contacts', "?".concat($.param(params)), null, null, {
        token: token.token
      }).then(function (resp) {
        if (resp.data.length > 0) {
          _this.accountantStore.pushPayload(resp);

          _this.set('linkedContact', _this.accountantStore.peekRecord('contact', resp.data.firstObject.id));
        }

        _this.checkForUnfinished({
          token: token.token
        }).then(function () {
          if (!_this.ticker.isRunning) {
            _this.set('contact', _this.linkedContact);
          }

          _this.set('loaded', true);

          _this.getTodaysTime();

          _this.setupWebSocket(token.token);
        });
      });
    },
    actions: {
      swap: function swap() {
        var _this2 = this;

        this.$('.mini-tracker-detail').slideDown(200);
        this.set('startingNew', true);
        this.submit().then(function () {
          _this2.set('contact', _this2.linkedContact);

          if (_this2.project && !_this2.project.allowMultipleContacts && _this2.get('project.contact.id') !== _this2.contact.id) {
            _this2.set('project', null);
          }

          _this2.start();
        }).then(function () {
          return _this2.set('startingNew', false);
        });
      }
    },
    afterStart: function afterStart() {
      this.open();
    },
    stop: function stop() {
      this._super.apply(this, arguments);

      this.getTodaysTime();
      this.open();
    },
    contactsMatch: Ember.computed('linkedContact.id', 'contact.id', function () {
      if (this.linkedContact && this.contact) {
        return this.get('linkedContact.id') === this.get('contact.id');
      } else if (this.linkedContact) {
        return false;
      } else {
        return true;
      }
    }),

    /*
     * ClickedOutsideMixin
     */
    outsideClickTriggerElement: function outsideClickTriggerElement() {
      return this.$('.mini-tracker-ticker-time, .mini-tracker-summary-spacer');
    },
    didClick: function didClick() {
      this.$('.mini-tracker-detail').slideToggle(200);
    },
    didClickOutside: function didClickOutside() {
      this.$('.mini-tracker-detail').slideUp(200);
    },
    open: function open() {
      this.setupOutsideClickListener();
      this.$('.mini-tracker-detail').slideDown(200);
    }
  });

  _exports.default = _default;
});