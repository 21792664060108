define("zipbooks/mixins/labelable", ["exports", "zipbooks/utils/later"], function (_exports, _later) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    animateLabel: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // give the component time to settle before we start allowing animation


      (0, _later.default)(1000, function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('animateLabel', true);
        }
      });
    },
    showLabel: Ember.computed('labelAttribute', function () {
      return Ember.isPresent(this.label) && Ember.isPresent(this.labelAttribute);
    }),
    labelString: Ember.computed('placeholder', 'label', function () {
      if (this.label === true) {
        return this.placeholder;
      } else {
        return this.label;
      }
    }),
    fadeClass: Ember.computed('showLabel', function () {
      return this.animateLabel ? this.showLabel ? 'fadeInFast' : 'fadeOutFast' : this.showLabel ? 'fadeShow' : 'fadeHide';
    }),
    positionClass: Ember.computed('showLabel', function () {
      return this.animateLabel ? this.showLabel ? 'moveDown' : 'moveUp' : this.showLabel ? 'down' : 'up';
    })
  });

  _exports.default = _default;
});