define("zipbooks/components/suggest/location-select", ["exports", "zipbooks/components/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Location',
    layoutName: 'components/auto-suggest',
    store: Ember.inject.service(),
    throttle: 500,
    assignedTo: null,
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var params = {
        filter: {
          archived: false,
          q: search
        }
      };

      if (this.billableFor) {
        return this.store.query('location', {
          filter: {
            'billable-for': this.billableFor
          }
        });
      } else {
        return this.store.cachedQuery('location', params);
      }
    },
    labelForResult: function labelForResult(result) {
      return result.get('name');
    },
    handleAddNew: function handleAddNew(label) {
      var _this = this;

      var location = this.store.createRecord('location', {
        name: label
      });
      this.overlay.showDrawer('drawers/location-form', location, {
        saved: function saved(model) {
          _this.trySelect(model);
        }
      });
    }
  });

  _exports.default = _default;
});