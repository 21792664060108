define("zipbooks/controllers/main/bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    overlay: Ember.inject.service(),
    queryParams: ['editing'],
    editing: false,
    save: function save() {
      var _this = this;

      this.removeEmptyLines();
      return this.model.save().then(function () {
        _this.urlCache.clear();

        _this.store.unloadUnsaved('billItem');

        _this.transitionToRoute({
          queryParams: {
            editing: false
          }
        });

        return true;
      }).catch(function (resp) {
        var errors = Ember.String.htmlSafe(_this.parseErrors(resp.errors).map(function (s) {
          return s.stripHTML();
        }).join('<br>'));

        _this.overlay.showBanner('error', errors || 'Failed to save bill.');

        return false;
      });
    },
    parseErrors: function parseErrors(errors) {
      return errors.map(function (error) {
        switch (error.detail) {
          case "chart-account-id can't be blank.":
            return 'Category is required for all items.';

          case "number can't be blank.":
            return 'Bill or Reference Number is required.';

          case "customer-id can't be blank.":
            return 'Vendor is required.';

          default:
            return error.detail;
        }
      }).filter(function (v, i, a) {
        return a.indexOf(v) === i;
      });
    },
    removeEmptyLines: function removeEmptyLines() {
      var emptyLines = this.get('model.items').filter(function (item) {
        return !(item.get('name') || item.get('notes') || item.get('chartAccount.id') || item.get('total') > 0);
      });
      this.get('model.items').removeObjects(emptyLines);
    },
    canLogPayment: Ember.computed('model.computedTotal', function () {
      return this.get('model.computedTotal') > 0;
    }),
    isDrawerOpen: false,
    actions: {
      edit: function edit() {
        this.transitionToRoute({
          queryParams: {
            editing: true
          }
        });
      },
      save: function save() {
        if (this.get('model.computedTotal') >= 0) {
          this.save();
          return;
        }

        this.overlay.showBanner('error', "Can't save bill with a negative amount.");
      },
      logPayment: function logPayment() {
        var _this2 = this;

        if (!this.canLogPayment && !this.isDrawerOpen) {
          return;
        }

        var openDrawer = function openDrawer() {
          _this2.set('isDrawerOpen', true);

          _this2.overlay.showDrawer('drawers/bill-payment-form', _this2.model, {
            logPayment: function logPayment(paymentForm) {
              var paymentPromise = _this2.model.addPayment(paymentForm);

              paymentPromise.then(function () {
                _this2.model.reload();
              });
              return paymentPromise;
            },
            onClose: function onClose() {
              _this2.set('isDrawerOpen', false);
            }
          });
        };

        if (this.editing) {
          this.save().then(function (resp) {
            if (resp) {
              openDrawer();
            }
          });
        } else {
          openDrawer();
        }
      },
      selectContact: function selectContact(contact) {
        this.set('model.contact', contact);
      },
      selectCurrency: function selectCurrency(currency) {
        this.set('model.currencyCode', currency);
      },
      swapLines: function swapLines(dropped, on) {
        var items = this.get('model.items');
        var droppedIndex = items.indexOf(dropped);
        var onIndex = items.indexOf(on);
        items.replace(onIndex, 1, [dropped]);
        items.replace(droppedIndex, 0, [on]);
      },
      destroyItem: function destroyItem(item) {
        this.get('model.items').removeObject(item);
      },
      addItem: function addItem() {
        this.get('model.items').pushObject(this.store.createRecord('billItem', {}));
      },
      filesDidUpload: function filesDidUpload(cloudFiles) {
        var model = this.model;
        model.get('cloudFiles').pushObjects(cloudFiles);

        if (!model.get('isNew')) {
          model.save();
        }
      }
    },
    _observeEditing: Ember.observer('editing', 'model', function () {
      if (this.editing === true && this.get('model.items.length') === 0) {
        this.send('addItem');
      }
    })
  });

  _exports.default = _default;
});