define("zipbooks/mixins/cloud-fileable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cloudFiles: (0, _model.hasMany)('cloudFile')
  });

  _exports.default = _default;
});