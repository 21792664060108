define("zipbooks/components/index-row-content/chart-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    indent: 0,
    actions: {
      destroy: function destroy() {
        this.destroy.apply(this, arguments);
      },
      archive: function archive() {
        this.archive.apply(this, arguments);
      },
      select: function select() {
        this.onSelect.apply(this, arguments);
      }
    },
    nextIndent: Ember.computed('indent', function () {
      return this.indent + 1;
    }),
    showRow: Ember.computed('hasParent', 'isChildRow', 'searchText', 'archivedView', function () {
      return !this.hasParent || this.isChildRow || this.searchText || this.archivedView;
    }),
    hasParent: Ember.computed('chartAccount.parent.id', function () {
      return !!this.get('chartAccount.parent.id');
    })
  });

  _exports.default = _default;
});