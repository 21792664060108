define("zipbooks/components/drawers/bill-payment-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    title: 'Log payment',
    form: Ember.computed('model.id', 'model.contact', 'defaultDebitLines', 'chartAccounts', function () {
      return Ember.Object.create({
        chartAccount: this.chartAccounts.find(function (ca) {
          return ca.get('systemCode') == '1200';
        }),
        // cash
        date: null,
        debitLines: this.defaultDebitLines,
        note: null,
        tags: [Ember.Object.create({
          id: this.get('model.id'),
          name: this.get('model.number'),
          taggableType: 'Bill',
          kind: null,
          isSystem: true,
          createdAt: new Date()
        }), Ember.Object.create({
          id: this.get('model.contact.id'),
          name: this.get('model.contact.formattedName'),
          taggableType: 'Contact',
          kind: null,
          isSystem: true,
          createdAt: new Date()
        })]
      });
    }),
    total: Ember.computed('proportionDue', 'form.debitLines.@each.amount', {
      get: function get() {
        return this.get('form.debitLines').reduce(function (acc, line) {
          return line.get('amount') + acc;
        }, 0);
      },
      set: function set(_property, amount) {
        if (isNaN(amount)) {
          amount = 0;
        }

        var total = 0;
        this.get('form.debitLines').forEach(function (line) {
          var newAmount = (amount * line.get('proportion')).round(2);
          line.set('amount', newAmount);
          total += newAmount;
        });
        var diff = (amount - total).round(2);

        if (diff != 0) {
          this.incrementProperty('form.debitLines.firstObject.amount', diff);
        }

        return amount;
      }
    }),
    remaining: Ember.computed('total', 'model.due', function () {
      return (this.get('model.due') - this.total).round(2);
    }),
    defaultDebitLines: Ember.computed('model.items', 'model.due', 'model.total', 'chartAccounts', function () {
      var categoryTotals = {};
      var debitLineCategories = {};
      var chartAccounts = this.chartAccounts;
      var defaultChart = chartAccounts.find(function (ca) {
        return ca.get('systemCode') == '2300';
      }); // accounts payable

      this.get('model.items').forEach(function (item) {
        var chartAccount = defaultChart;
        var apName = item.get('chartAccount.name') + ' Payable';
        var relatedAp = chartAccounts.find(function (ca) {
          return ca.get('name') == apName;
        });

        if (relatedAp) {
          chartAccount = relatedAp;
        }

        var chartAccountId = chartAccount.get('id');
        debitLineCategories[chartAccountId] = chartAccount;
        categoryTotals[chartAccountId] = (categoryTotals[chartAccountId] || 0) + item.get('total');
      });
      var debitLines = [];
      var total = 0;

      for (var id in debitLineCategories) {
        var category = debitLineCategories[id];
        var proportion = categoryTotals[category.id] / this.get('model.total');
        var amount = (proportion * this.get('model.due')).round(2);
        debitLines.push(Ember.Object.create({
          chartAccount: category,
          proportion: proportion,
          amount: amount
        }));
        total += amount;
      }

      var diff = (this.get('model.due') - total).round(2);

      if (diff != 0) {
        debitLines[0].set('amount', (debitLines[0].get('amount') + diff).round(2));
      }

      return debitLines;
    }),
    chartAccounts: Ember.computed(function () {
      return this.store.chartAccounts();
    }),
    errors: {},
    actions: {
      selectChartAccount: function selectChartAccount(chartAccount) {
        this.set('form.chartAccount', chartAccount);
      },
      addTag: function addTag(tag) {
        var tagObject = Ember.Object.create({
          id: tag.id,
          name: tag.name,
          taggableType: tag.type,
          kind: tag.kind,
          createdAt: new Date()
        });
        this.get('form.tags').pushObject(tagObject);
      },
      removeTag: function removeTag(tag) {
        if (!tag.get('isSystem')) {
          this.set('form.tags', this.get('form.tags').filter(function (t) {
            return t != tag;
          }));
        }
      },
      logPayment: function logPayment() {
        var _this = this;

        this.set('buttonState', 'saving');
        var form = Ember.Object.create(this.form);
        form.set('tags', form.get('tags').filter(function (tag) {
          return !tag.get('isSystem');
        }));
        var promise = Ember.tryInvoke(this.callbacks, 'logPayment', [form]);

        if (promise) {
          promise.then(function () {
            _this.handleClose();
          }).catch(function (errors) {
            _this.set('buttonState', 'invalid');

            _this.set('errors', errors);
          });
        } else {
          this.handleClose();
        }
      }
    }
  });

  _exports.default = _default;
});