define("zipbooks/components/drawers/person-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    actions: {
      save: function save(model) {
        if (model.get('isNew')) {
          var contactParam = this.get('params.lastObject');
          var contact = this.store.peekRecord(contactParam.key, contactParam.value);
          model.set('contact', contact);
        }

        this._super(model);
      }
    }
  });

  _exports.default = _default;
});