define("zipbooks/controllers/main/reports/contractor-summary", ["exports", "zipbooks/mixins/tag-filterable-controller", "zipbooks/mixins/reports/date-error", "zipbooks/utils/tag-serialization"], function (_exports, _tagFilterableController, _dateError, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tagFilterableController.default, _dateError.default, {
    queryParams: ['start_date', 'end_date', 'type', 'group_by'],
    start_date: moment().startOf('year').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    type: 'standard',
    group_by: 'vendor',
    actions: {
      quickReport: function quickReport(kind) {
        if (kind === 'current') {
          var start = moment().startOf('year').format('YYYY-MM-DD');
          var end = moment().format('YYYY-MM-DD');
          this.transitionToRoute({
            queryParams: {
              start_date: start,
              end_date: end
            }
          });
        } else if (kind === 'last_year') {
          var _start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');

          var _end = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              start_date: _start,
              end_date: _end
            }
          });
        } else if (kind === 'month_to_date') {
          var _start2 = moment().startOf('month').format('YYYY-MM-DD');

          var _end2 = moment().format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              start_date: _start2,
              end_date: _end2
            }
          });
        } else {
          var _start3 = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

          var _end3 = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              start_date: _start3,
              end_date: _end3
            }
          });
        }
      },
      viewTransactions: function viewTransactions(chartAccountId) {
        if (this.permissions.can('accounting.read')) {
          this.transitionToRoute('main.transactions', {
            queryParams: {
              chart_account_id: chartAccountId,
              start_date: this.get('start_date'),
              end_date: this.get('end_date'),
              tags: _tagSerialization.default.serialize(this.tagObjects)
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});