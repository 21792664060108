define("zipbooks/templates/components/overlays/dialogs/destroy-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xavMhmUT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"warning\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[1,[22,\"warning\"],false],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[8],[1,[22,\"prompt\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[28,\"status-button\",null,[[\"type\",\"class\",\"action\",\"state\",\"title\",\"testId\"],[\"submit\",\"btn btn-full btn-red\",[28,\"action\",[[23,0,[]],\"destroy\"],null],[24,[\"buttonState\"]],\"Delete\",\"delete\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-full btn-white\"],[12,\"title\",\"Cancel\"],[12,\"data-test-id\",\"cancel\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"No, cancel\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/overlays/dialogs/destroy-confirm.hbs"
    }
  });

  _exports.default = _default;
});