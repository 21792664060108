define("zipbooks/templates/components/reports/cash-flow-statement-line-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HTs9qUu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[28,\"exists\",[[24,[\"quickLinkExclude\"]],[24,[\"lineInfo\",\"title\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"class\",\"quick-click-link\"],[3,\"action\",[[23,0,[]],\"viewTransactions\",[24,[\"lineInfo\",\"classification\"]]]],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[[22,\"sumLineClass\"]]]],[8],[1,[28,\"format-money\",[[24,[\"balance\"]],true],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[[22,\"sumLineClass\"]]]],[8],[1,[28,\"format-money\",[[24,[\"balance\"]],true],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/reports/cash-flow-statement-line-value.hbs"
    }
  });

  _exports.default = _default;
});