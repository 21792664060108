define("zipbooks/components/each-saved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EachSavedComponent = Ember.Component.extend({
    tagName: '',
    savedRecords: Ember.computed('isNew', 'archivedAt', function () {
      return this.records.filter(function (r) {
        return !r.get('isNew') && !r.get('isArchived');
      });
    })
  });
  EachSavedComponent.reopenClass({
    positionalParams: ['records']
  });
  var _default = EachSavedComponent;
  _exports.default = _default;
});