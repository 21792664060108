define("zipbooks/routes/main/recurring-transactions", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/slow-array", "zipbooks/utils/sort-param"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _permissionRedirect, _slowArray, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    autoUpdatingEvent: 'recurring-journal-entry.save',
    autoUpdatingModel: 'recurringJournalEntries',
    model: function model(params) {
      var filterParams = {
        archived: params.archived || false
      };
      var models = {
        recurringJournalEntries: (0, _slowArray.default)(this.store.cachedQuery('recurring-journal-entry', {
          page: {
            page: params.page || 1,
            'page-size': 100
          },
          filter: filterParams,
          sort: (0, _sortParam.default)(params)
        }))
      };
      return Ember.RSVP.hash(models);
    }
  });

  _exports.default = _default;
});