define("zipbooks/components/drawers/accounts-messenger-form", ["exports", "zipbooks/components/messaging/accounts-messenger"], function (_exports, _accountsMessenger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /************************************************************************************
   * This file is just a proxy for'components/messaging/accounts-messenger' so
   * that we can use the drawer infrastructure to display it.
   ************************************************************************************/
  var _default = _accountsMessenger.default.extend({
    layoutName: 'components/messaging/accounts-messenger',
    actions: {
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});