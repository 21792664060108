define("zipbooks/mixins/integrations/square-onboarding-route", ["exports", "zipbooks/mixins/integrations-route", "zipbooks/utils/storage"], function (_exports, _integrationsRoute, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_integrationsRoute.default, {
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      this.showAssociateLocations(model);
    },
    showAssociateLocations: function showAssociateLocations(model) {
      var _this = this;

      var integration = model.integrations.find(function (i) {
        return i.get('externalLocations.length') !== undefined && i.get('modelType') === 'square-integration';
      });

      if (integration) {
        if (integration.enableSync === null) {
          // careful not to show this on top of other dialogs
          if (!this.overlay.isShowing('overlays/dialogs/growth/associate-locations')) {
            this.overlay.showDialog('overlays/dialogs/growth/associate-locations', 'Locations', {
              customHeader: true,
              integration: integration,
              externalLocations: integration.externalLocations,
              locations: {
                all: this.store.peekAll('location')
              },
              squareSkipPreferences: _storage.default.get('squareSkipPreferences')
            }, {
              complete: function complete() {
                _this.refresh();
              }
            });
            return;
          }
        }
      }
    }
  });

  _exports.default = _default;
});