define("zipbooks/utils/in-transit-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    date: Ember.computed('sourceLines.@each.date', function () {
      return this.sourceLines.filter(function (l) {
        return !!l.date;
      }).get('firstObject.date');
    }),
    amount: Ember.computed('sourceLines.@each.{amount,kind}', function () {
      return this.sourceLines.reduce(function (acc, line) {
        var amount = Math.abs(line.amount);
        return acc + (line.kind === 'credit' ? -amount : +amount);
      }, 0);
    }),
    kind: Ember.computed('amount', function () {
      return this.amount > 0 ? 'credit' : 'debit';
    })
  });

  _exports.default = _default;
});