define("zipbooks/initializers/permissions", ["exports", "zipbooks/utils/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('permissions:main', _permissions.default);
    application.inject('component', 'permissions', 'permissions:main');
    application.inject('controller', 'permissions', 'permissions:main');
    application.inject('helper', 'permissions', 'permissions:main');
    application.inject('route', 'permissions', 'permissions:main');
  }

  var _default = {
    name: 'permissions',
    initialize: initialize
  };
  _exports.default = _default;
});