define("zipbooks/mixins/accountants/manage-team", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedUsers: [],
    allAreSelected: Ember.computed('users.length', 'selectedUsers.length', function () {
      return this.users.length === this.selectedUsers.length;
    }),
    users: Ember.computed(function () {
      var _this = this;

      return _emberData.default.PromiseArray.create({
        promise: this.store.cachedQuery('user', {
          filter: {
            archived: false
          },
          include: 'identity.avatar_cloud_file'
        }).then(function (users) {
          _this.markUsersAsSelected(users);

          return users;
        })
      });
    }),
    actions: {
      selectAll: function selectAll() {
        var _this2 = this;

        this.users.forEach(function (user) {
          var exists = _this2.selectedUsers.findBy('id', user.id);

          if (!exists) {
            _this2.selectedUsers.addObject(user);
          }
        });
      },
      deselectAll: function deselectAll() {
        var _this3 = this;

        this.users.forEach(function (user) {
          var exists = _this3.selectedUsers.findBy('id', user.id);

          if (exists) {
            _this3.selectedUsers.removeObject(user);
          }
        });
      }
    },
    saveUsers: function saveUsers(users, account_id) {
      var body = {
        data: {
          type: 'team',
          id: account_id,
          relationships: {
            users: {
              data: users.map(function (user) {
                return {
                  type: 'user',
                  relationships: {
                    identity: {
                      data: {
                        id: user.get('identity.id'),
                        type: 'identity'
                      }
                    }
                  }
                };
              })
            }
          }
        }
      };
      return this.client.PATCH('team-accounts', "".concat(this.get('session.account.id'), "/users"), body);
    }
  });

  _exports.default = _default;
});