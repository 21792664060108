define("zipbooks/components/drawers/base-drawer", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    inClass: 'drawerSlideInRight',
    outClass: 'drawerSlideOutRight',
    classNames: ['drawer'],
    overlay: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    layoutName: 'components/drawers/base-drawer',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // Hack to fix iOS bug where modal cursors appear in the wrong place


      $('body').addClass('position-fixed position-md-static'); // put the cursor in the first box so they can just start typing

      Ember.run.scheduleOnce('afterRender', this, function () {
        var firstInput = _this.$('input')[0];

        if (firstInput && !$(firstInput).closest('.select')) {
          $(firstInput).focus();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments); // REMOVE Hack to fix iOS bug where modal cursors appear in the wrong place


      $('body').removeClass('position-fixed position-md-static');
    },
    actions: {
      close: function close() {
        this.handleClose.apply(this, arguments);
      }
    },
    rest: Ember.computed('params', function () {
      return (this.params || []).slice(1).reduce(function (hash, kvp) {
        hash[kvp.key] = kvp.value;
        return hash;
      }, {});
    }),
    partialName: Ember.computed('template', function () {
      return "components/drawers/".concat(this.componentName);
    }),
    handleClose: function handleClose() {
      var close = this.close;

      if (!close) {
        var _this$overlay;

        Ember.tryInvoke(this.callbacks, 'onClose', []);

        (_this$overlay = this.overlay).close.apply(_this$overlay, [this.ref].concat(Array.prototype.slice.call(arguments)));
      } else {
        close.apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});