define("zipbooks/mixins/auto-upgrade-route", ["exports", "zipbooks/mixins/auto-upgrade"], function (_exports, _autoUpgrade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_autoUpgrade.default, {
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    needs: null,
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.session.hasPrivilege(this.needs)) {
        if (this.shouldAutoUpgrade(this.get('session.currentUser.account'))) {
          return this.autoUpgrade(function () {
            _this.overlay.showDialog('overlays/dialogs/paid-modal', Ember.String.htmlSafe('<i class="icon-rank-army-star-badge-2-filled"></i>You\'ve been upgraded!'), {
              needs: _this.needs,
              isAlreadyAutoUpgraded: true
            });
          }, function (_error) {
            Ember.tryInvoke(_this, 'onUpgradeFailure', []);
          });
        } else {
          Ember.tryInvoke(this, 'onUpgradeFailure', []);
        }
      }
    }
  });

  _exports.default = _default;
});