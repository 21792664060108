define("zipbooks/mixins/tag-filterable-controller", ["exports", "zipbooks/utils/tag-serialization", "zipbooks/utils/navigate-to"], function (_exports, _tagSerialization, _navigateTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['tags'],
    notificationCenter: Ember.inject.service(),
    tags: null,
    tagObjects: Ember.computed('tags.@each', {
      get: function get(_key) {
        var serialized = this.tags;

        if (Ember.isPresent(serialized)) {
          return _tagSerialization.default.deserialize(serialized, function (obj) {
            return Ember.Object.create(obj);
          });
        }

        return [];
      },
      set: function set(_key, value) {
        var serialized = _tagSerialization.default.serialize(value);

        this.set('page', 0);
        this.set('tags', serialized);
        return value;
      }
    }),
    actions: {
      tagClick: function tagClick(id, type) {
        var router = Ember.getOwner(this).lookup('router:main');
        var kind = type.dasherize();
        (0, _navigateTo.default)(router, kind, id);
      },
      addTag: function addTag(tag) {
        var tagObject = Ember.Object.create({
          id: tag.id,
          name: tag.name,
          taggableType: tag.type,
          kind: tag.kind,
          createdAt: new Date()
        });
        this.set('tagObjects', this.tagObjects.concat([tagObject]));
        this.notificationCenter.notify('tags-input-changed');
      },
      removeTag: function removeTag(tag) {
        var newTags = this.tagObjects.filter(function (t) {
          return t.get('id') !== tag.id;
        });
        this.set('tagObjects', newTags);
        this.notificationCenter.notify('tags-input-changed');
      }
    }
  });

  _exports.default = _default;
});