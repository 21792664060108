define("zipbooks/templates/components/reconciliations/bank-balances", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UvSDq1yy",
    "block": "{\"symbols\":[\"balance\"],\"statements\":[[4,\"tool-tip\",null,[[\"class\"],[[24,[\"class\"]]]],{\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"balances\",\"length\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n        \"],[7,\"small\",true],[10,\"class\",\"gray\"],[8],[0,\"Date\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col text-right\"],[8],[0,\"\\n        \"],[7,\"small\",true],[10,\"class\",\"gray\"],[8],[0,\"Reported balance\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"balances\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[\"date\"]],[24,[\"endDate\"]]],null],\"row font-weight-bold\",\"row\"],null]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n        \"],[1,[28,\"format-date\",[[23,1,[\"date\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col text-right\"],[8],[0,\"\\n        \"],[1,[28,\"format-money\",[[23,1,[\"balance\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    We don't have any reported balances for this date.\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/reconciliations/bank-balances.hbs"
    }
  });

  _exports.default = _default;
});