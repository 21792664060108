define("zipbooks/mixins/list-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    collection: Ember.computed.alias('model'),
    modelType: Ember.computed('collection.@each', function () {
      return this.get('collection.type').modelName.underscore();
    })
  });

  _exports.default = _default;
});