define("zipbooks/overrides/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.reopen({
    // this could break at the drop of a hat, so we'll want to test it thoroughly
    changedAttributes: function changedAttributes() {
      var _this = this;

      var attributes = this._super.apply(this, arguments);

      var relationships = {}; // check relationships

      this.eachRelationship(function (name, meta) {
        if (meta.kind === 'belongsTo') {
          var before = _this.get("_internalModel._recordData._relationships.initializedRelationships.".concat(name, ".canonicalState.id"));

          var now = _this.get("".concat(name, ".id"));

          if (before !== now) {
            relationships[name] = [before, now];
          }
        }
      });
      return Ember.assign(attributes, relationships);
    }
  });

  _exports.default = _default;
});