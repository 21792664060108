define("zipbooks/templates/components/small-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9vuEyb+H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"small-loader \",[22,\"class\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect1\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect2\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect3\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect4\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"rect5\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/small-loader.hbs"
    }
  });

  _exports.default = _default;
});