define("zipbooks/helpers/account-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountBalance = accountBalance;
  _exports.default = void 0;

  function accountBalance(params) {
    var transposedBalanceSheet = params[0];
    var account = params[1];
    var period = params[2];
    return transposedBalanceSheet[account][period].balance;
  }

  var _default = Ember.Helper.helper(accountBalance);

  _exports.default = _default;
});