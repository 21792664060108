define("zipbooks/templates/partials/-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fLxLAMa1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"spinner fadeIn\"],[10,\"title\",\"2\"],[8],[0,\"\\n  \"],[7,\"svg\",true],[10,\"version\",\"1.1\"],[10,\"id\",\"loader-1\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[10,\"x\",\"0px\"],[10,\"y\",\"0px\"],[10,\"viewBox\",\"0 0 50 50\"],[10,\"style\",\"enable-background:new 0 0 50 50;\"],[10,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[8],[0,\"\\n    \"],[7,\"path\",true],[10,\"fill\",\"#000\"],[10,\"d\",\"M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z\"],[8],[0,\"\\n      \"],[7,\"animateTransform\",true],[10,\"attributeType\",\"xml\"],[10,\"attributeName\",\"transform\"],[10,\"from\",\"0 25 25\"],[10,\"to\",\"360 25 25\"],[10,\"dur\",\"0.6s\"],[10,\"repeatCount\",\"indefinite\"],[10,\"type\",\"rotate\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/partials/-spinner.hbs"
    }
  });

  _exports.default = _default;
});