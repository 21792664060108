define("zipbooks/templates/components/drawers/-paypal-integration-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSG+WRUa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row x-small-gutters mb-2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[1,[28,\"form-input\",null,[[\"value\",\"placeholder\",\"label\"],[[24,[\"email\"]],\"PayPal Email Address\",true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"action\",\"model\",\"title\",\"class\",\"testId\"],[[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null],[24,[\"model\"]],\"Save\",\"btn btn-full btn-blue btn-depressable\",\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-paypal-integration-form.hbs"
    }
  });

  _exports.default = _default;
});