define("zipbooks/routes/main/bill", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model(params) {
      if (params.id === 'new') {
        return this.store.createRecord('bill', {
          items: [this.store.createRecord('billItem', {})]
        });
      } else {
        return this.store.findRecord('bill', params.id);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.get('isNew')) {
        controller.set('editing', true);
        model.set('currencyCode', this.get('session.account.defaultCurrencyCode'));
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        if (this.controller.get('editing') && !this.confirmedExit) {
          this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
            title: 'You have unsaved changes. Are you sure you want to leave?',
            confirmButton: {
              title: "Yes, I'm done",
              class: 'btn-blue'
            },
            cancelButton: {
              title: 'No, stay here',
              class: 'btn-white'
            }
          }, {
            confirm: function confirm() {
              _this.set('confirmedExit', true);

              transition.retry();
            },
            cancel: function cancel() {}
          });
          transition.abort();
        } else {
          this.set('confirmedExit', false); // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.

          return true;
        }
      }
    }
  });

  _exports.default = _default;
});