define("zipbooks/models/message-recipient", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    channel: (0, _model.attr)(),
    username: (0, _model.attr)(),
    error: (0, _model.attr)(),
    sentAt: (0, _model.attr)('datetime'),
    account: (0, _model.belongsTo)('account'),
    recipientable: (0, _model.belongsTo)('recipientable', {
      polymorphic: true
    }),
    message: (0, _model.belongsTo)('message')
  });

  _exports.default = _default;
});