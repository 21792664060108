define("zipbooks/services/ticker", ["exports", "zipbooks/utils/format-seconds-as-hours"], function (_exports, _formatSecondsAsHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    seconds: 0,
    isRunning: Ember.computed.notEmpty('intervalHandle'),
    start: function start(startTime) {
      if (this.isRunning) {
        return;
      }

      startTime = startTime || moment().utc();
      this.set('seconds', moment().diff(startTime, 'seconds'));
      this.set('startTime', startTime);
      this.tick();
    },
    stop: function stop() {
      var handle = this.intervalHandle;
      Ember.run.cancel(handle);
      this.set('intervalHandle', null);
      this.set('seconds', 0);
      document.title = 'ZipBooks';
    },
    tick: function tick() {
      var _this = this;

      document.title = "".concat((0, _formatSecondsAsHours.default)(this.seconds, 'h:m:s'), " | ZipBooks");
      var handle = Ember.run.later(this, function () {
        _this.set('seconds', moment().diff(_this.startTime, 'seconds'));

        _this.tick();
      }, 1000);
      this.set('intervalHandle', handle);
    }
  });

  _exports.default = _default;
});