define("zipbooks/templates/components/reconciliations/months-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HA4nMoA7",
    "block": "{\"symbols\":[\"period\",\"index\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"border-top-1 row no-gutters flex-row-reverse flex-nowrap \",[28,\"if\",[[28,\"gt\",[[28,\"length\",[[24,[\"periods\"]]],null],12],null],\"overflow-scroll\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"periods\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"border-left-1 col-3 col-sm-1 d-inline-block p-3 text-center\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"clickable\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"testId\",\"query\",\"route\",\"model\"],[\"rec-month\",[28,\"hash\",null,[[\"period_start_date\"],[[23,1,[\"startDate\"]]]]],\"main.reconciliation\",[24,[\"chartAccount\",\"id\"]]]],{\"statements\":[[0,\"          \"],[7,\"i\",true],[11,\"class\",[29,[\"font-size-18px d-block m-auto \",[28,\"if\",[[23,1,[\"isReconciled\"]],\"icon-check-circle blue\",\"icon-check-lines gray-500\"],null]]]],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"font-weight-bold font-size-14px h-1rem mt-1\"],[8],[1,[23,1,[\"month\"]],false],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"font-size-12px gray\"],[8],[1,[23,1,[\"year\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"gray-400\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"font-size-18px d-block m-auto icon-check-lines\"],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"font-weight-bold font-size-14px h-1rem mt-1\"],[8],[1,[23,1,[\"month\"]],false],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"font-size-12px\"],[8],[1,[23,1,[\"year\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/reconciliations/months-row.hbs"
    }
  });

  _exports.default = _default;
});