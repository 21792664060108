define("zipbooks/components/notifications-menu", ["exports", "zipbooks/mixins/clicked-outside", "zipbooks/mixins/testable", "zipbooks/mixins/infinite-scrollable"], function (_exports, _clickedOutside, _testable, _infiniteScrollable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _clickedOutside.default, _infiniteScrollable.default, {
    classNames: ['notifications-menu'],
    overlay: Ember.inject.service(),
    notificationStore: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    page: 1,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.registerInfiniteScroll('.js-notifications-scroll');

      if (this.get('notifications.length') > 0) {
        this.checkScroll();
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments); // don't mark as read if user is stealth


      if (this.get('session.isStealth')) {
        return;
      } // save read actions to the server


      var ids = this.notificationStore.peekAll('notification').filterBy('markAsRead').reduce(function (acc, a) {
        return acc.concat([a.get('id')]).concat(a.get('groupedIds'));
      }, []).filter(function (id) {
        return !!id;
      });

      if (ids.length > 0) {
        this.client.PATCH('actions', 'update-all', {
          ids: ids,
          data: {
            type: 'action',
            attributes: {
              'is-read': true
            }
          }
        }).then(function () {
          Ember.tryInvoke(_this, 'onClose', []);
        });
      } else {
        Ember.tryInvoke(this, 'onClose', []);
      }
    },
    actions: {
      markAllAsRead: function markAllAsRead() {
        var _this2 = this;

        this.client.PATCH('actions/mark-all-read').then(function (_response) {
          _this2.set('unReadNotificationsCount', 0);

          _this2.notificationStore.peekAll('notification').forEach(function (action) {
            action.set('markAsRead', false);
            action.set('isRead', true);
          });
        });
      }
    },

    /*
     * InfiniteScrollableMixin
     */
    loadMore: function loadMore(direction, callback) {
      var _this3 = this;

      if (direction === 'down') {
        this.incrementProperty('page');
        this.notificationStore.query('notification', {
          page: {
            page: this.page
          }
        }).then(function (more) {
          var array = more.toArray();
          var notifs = _this3.notifications;
          notifs.pushObjects(array);
          callback(true, array.length === 0);
        });
      } else {
        callback(false);
      }
    },

    /*
     * ClickedOutsideMixin
     */
    activateClickOutsideOnInsert: true,
    didClickOutside: function didClickOutside() {
      this.close();
    }
  });

  _exports.default = _default;
});