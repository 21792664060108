define("zipbooks/templates/components/drawers/-refund-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QMC0LdTN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group reason-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Reason\"],[9],[0,\"\\n    \"],[1,[28,\"form-input\",null,[[\"value\",\"errors\",\"testId\"],[[24,[\"formModel\",\"reason\"]],[24,[\"errors\",\"reason\"]],\"reason\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group amount-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Amount\"],[9],[0,\"\\n    \"],[1,[28,\"form-input\",null,[[\"value\",\"errors\",\"format\",\"testId\"],[[24,[\"formModel\",\"amount\"]],[24,[\"errors\",\"amount\"]],\"money\",\"amount\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"class\",\"action\",\"testId\",\"title\",\"state\"],[\"btn btn-full btn-blue btn-depressable\",[28,\"action\",[[23,0,[]],\"refund\"],null],\"submit\",\"Issue Refund\",[24,[\"buttonState\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-refund-form.hbs"
    }
  });

  _exports.default = _default;
});