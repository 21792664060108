define("zipbooks/controllers/main/transactions", ["exports", "zipbooks/mixins/index-controller", "zipbooks/utils/later", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/paid-modal-handler", "zipbooks/utils/parse-number", "zipbooks/utils/refresh-chart-accounts", "zipbooks/mixins/tag-filterable-controller", "zipbooks/utils/drawer-link-for", "zipbooks/mixins/transaction-list", "zipbooks/utils/base-64"], function (_exports, _indexController, _later, _controllerPagination, _paidModalHandler, _parseNumber, _refreshChartAccounts, _tagFilterableController, _drawerLinkFor, _transactionList, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, _paidModalHandler.default, _tagFilterableController.default, _transactionList.default, {
    queryParams: ['chart_account_id', 'classification', 'transaction', 'advanced', 'start_date', 'end_date', 'rollup', 'source', 'confirmed'],
    sort: 'date',
    direction: 'desc',
    chart_account_id: null,
    classification: null,
    confirmed: null,
    transaction: null,
    advanced: false,
    start_date: null,
    end_date: Ember.computed('start_date', {
      get: function get(_key) {
        return this._super.apply(this, arguments) || null;
      },
      set: function set(_key, value) {
        var s = moment(this.start_date);
        var e = moment(value);

        if (e.isBefore(s)) {
          return this.start_date;
        } else {
          return value;
        }
      }
    }),
    rollup: null,
    source: null,
    notificationCenter: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('bulkTags', []);

      var unselect = function unselect() {
        var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

        if (!_this.isDestroyed && !_this.isDestroying) {
          (0, _later.default)(delay, function () {
            _this.set('transaction', null);
          });
        }
      };

      this.notificationCenter.addObserver(this, 'clicked-to-close', function () {
        unselect();
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'clicked-to-close');
    },
    bankMembersToSetup: Ember.computed('model.bankMembersToSetup', function () {
      var _this2 = this;

      return this.get('model.bankMembersToSetup').filter(function (member) {
        return !member.get('syncTo') && !member.get('errorMessage');
      }).map(function (member) {
        return {
          name: member.get('institutionName'),
          setup: function setup() {
            return _this2.overlay.showDrawer('drawers/setup-bank-member-form', member);
          },
          member: true
        };
      });
    }),
    bankChartAccounts: Ember.computed('model.chartAccounts', function () {
      return this.get('model.chartAccounts').filter(function (chartAccount) {
        return chartAccount.get('isBankAccount') && chartAccount.get('bankAccount.connected');
      }).map(function (chartAccount) {
        return {
          balance: (0, _parseNumber.default)(chartAccount.get('bankAccount.balance')) > 0 ? chartAccount.get('bankAccount.balance') : chartAccount.get('bankAccount.availableBalance'),
          balanceLabel: (0, _parseNumber.default)(chartAccount.get('bankAccount.balance')) > 0 ? 'Current balance' : 'Available balance',
          classification: chartAccount.get('classification'),
          error: chartAccount.get('bankAccount.error'),
          id: chartAccount.get('id'),
          bankAccount: chartAccount.get('bankAccount'),
          name: chartAccount.get('name'),
          zipbooksBalance: chartAccount.get('balance')
        };
      });
    }),
    zipbooksChartAccount: Ember.computed('session.account.defaultCurrencyCode', function () {
      var zipbooksChartAccount = this.get('model.chartAccounts').find(function (chartAccount) {
        return chartAccount.get('classification') === 'ZipBooks merchant account' && chartAccount.get('isSystem');
      });
      var hasProcessor = this.get('session.account.settings.activePaymentProcessor') === 'zipbooks';

      if (zipbooksChartAccount && hasProcessor) {
        return {
          name: zipbooksChartAccount.get('name'),
          id: zipbooksChartAccount.get('id'),
          error: null,
          classification: zipbooksChartAccount.get('classification'),
          balance: zipbooksChartAccount.get('balance'),
          balanceLabel: 'Current balance',
          zipbooksBalance: zipbooksChartAccount.get('balance') // show the box while it loads

        };
      } else if (hasProcessor) {
        return {
          name: 'ZipBooks merchant account',
          id: 'zb',
          error: null,
          classification: 'ZipBooks merchant account',
          balance: 0,
          balanceLabel: 'Current balance',
          zipbooksBalance: 0
        };
      } else {
        return null;
      }
    }),
    squareChartAccount: Ember.computed('squareLoading', 'model.squareChartAccount', 'session.account.integrations.square', function () {
      var _this3 = this;

      if (this.session.account.get('integrations.square')) {
        if (this.squareLoading) {
          return {
            name: 'Square',
            id: 'square',
            error: null,
            classification: 'Square',
            balance: 0,
            balanceLabel: 'ZipBooks Balance',
            zipbooksBalance: 0
          };
        } else if (this.model.squareChartAccount) {
          return this.model.squareChartAccount;
        } else {
          this.set('squareLoading', true);
          return this.store.queryRecord('square-integration', {}).then(function (square) {
            var squareChartAccount = {
              name: square.chartAccount.get('name'),
              id: square.chartAccount.get('id'),
              error: null,
              classification: square.chartAccount.get('classification'),
              balance: square.chartAccount.get('balance'),
              balanceLabel: 'ZipBooks Balance',
              zipbooksBalance: square.chartAccount.get('balance')
            };

            _this3.model.set('squareChartAccount', squareChartAccount);

            _this3.set('squareLoading', false);

            return squareChartAccount;
          });
        }
      }
    }),
    highlightedChartAccounts: Ember.computed('squareChartAccount', 'zipbooksChartAccount', 'bankChartAccounts', 'bankMembersToSetup', function () {
      var highlightedChartAccounts = [];
      this.bankMembersToSetup.forEach(function (chartAccount) {
        highlightedChartAccounts.push(chartAccount);
      });

      if (this.zipbooksChartAccount) {
        highlightedChartAccounts.push(this.zipbooksChartAccount);
      }

      if (this.squareChartAccount) {
        highlightedChartAccounts.push(this.squareChartAccount);
      }

      this.bankChartAccounts.forEach(function (chartAccount) {
        highlightedChartAccounts.push(chartAccount);
      });
      return highlightedChartAccounts;
    }),
    highlightedChartAccountsColClass: Ember.computed('highlightedChartAccounts', function () {
      if (this.highlightedChartAccounts.length > 3) {
        return 'col-6 col-sm-3';
      } else {
        return 'col-6 col-sm-4';
      }
    }),
    isUnfiltered: Ember.computed('chart_account_id', function () {
      return !this.get('chart_account_id');
    }),
    collection: Ember.computed('model.lines.@each', function () {
      return this.get('model.lines');
    }),
    actions: {
      openLine: function openLine(line) {
        this.transitionToRoute({
          queryParams: {
            transaction: line.id
          }
        });
      },
      closeLine: function closeLine()
      /*line*/
      {
        this.transitionToRoute({
          queryParams: {
            transaction: null
          }
        });
      },
      toggleShowAllBanks: function toggleShowAllBanks() {
        this.toggleProperty('showAllBanks');
      },
      toggleAdvanced: function toggleAdvanced() {
        this.toggleProperty('advanced');
      },
      confirm: function confirm(line) {
        var _this4 = this;

        var original = line.get('journalEntry.isConfirmed');
        line.set('clickedToConfirm', !line.get('journalEntry.isConfirmed')); // all lines must have a chart account to be confirmed

        var allCategorized = line.get('journalEntry.journalEntryLines').every(function (line) {
          line.categorizeWithSuggestionByDefault();
          return !!line.get('chartAccount.id');
        });

        if (!allCategorized && !line.get('journalEntry.isConfirmed')) {
          this.overlay.showBanner('error', 'Transaction must be categorized to be confirmed.');
          return;
        }

        line.toggleProperty('journalEntry.isConfirmed');
        line.get('journalEntry').thenIfExists(function (journalEntry) {
          // send to server and deal with result
          journalEntry.save().then(function (_journalEntry) {
            (0, _refreshChartAccounts.default)(_this4.store, [line.get('journalEntry')]);

            _this4.refreshMeta();

            _this4.unselectAll();
          }).catch(function (error) {
            // stupid, but its clean (as in only one line)
            (0, _later.default)(500, function () {
              return line.set('journalEntry.isConfirmed', original);
            });

            _this4.overlay.showBanner('error', Ember.get(error, 'errors.firstObject.detail') || 'Unable to confirm.');
          });
        });
      },
      filterByUnconfirmed: function filterByUnconfirmed() {
        this.transitionToRoute({
          queryParams: {
            confirmed: 'unconfirmed',
            page: 1
          }
        });
      },
      openBankDrawer: function openBankDrawer(member) {
        var params = (0, _drawerLinkFor.default)([member]);
        this.transitionToRoute({
          queryParams: {
            drawer: params
          }
        });
      }
    },
    refreshMeta: function refreshMeta() {
      var _this5 = this;

      this.client.GET('journal-entries/meta').then(function (resp) {
        Ember.setProperties(_this5.get('model.lines.meta'), resp);
      });
    },
    rollupInfo: Ember.computed('rollup', function () {
      try {
        return JSON.parse((0, _base.decode)(this.rollup));
      } catch (_e) {// ignore
      }
    }),
    _observeTransactionMessage: Ember.observer('transaction', 'model.lines.@each', function () {
      var _this6 = this;

      Ember.run.next(function () {
        if (_this6.model && _this6.model.lines) {
          _this6.model.lines.forEach(function (entry) {
            if (entry.modelType === 'journal-entry' && entry.id === _this6.transaction) {
              entry.get('journalEntryLines').forEach(function (line) {
                line.get('tags').forEach(function (tag) {
                  if (tag.taggableType === 'label' && tag.kind === 'Message') {
                    _this6.overlay.showPopout('messaging/accountant-messenger', {
                      anchorLabelId: tag.belongsTo('taggable').id()
                    });
                  }
                });
              });
            }
          });
        }

        if (!_this6.transaction && _this6.overlay.isShowing('messaging/accountant-messenger')) {
          _this6.overlay.close('messaging/accountant-messenger');
        }
      });
    })
  });

  _exports.default = _default;
});