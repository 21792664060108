define("zipbooks/mixins/form-line", ["exports", "zipbooks/utils/default-bank-account", "zipbooks/utils/form-line"], function (_exports, _defaultBankAccount, _formLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    convertFromLines: function convertFromLines(lines, pl) {
      var _this = this;

      if (lines.get('length') === 0) {
        var primaryLine = _formLine.default.create({
          date: new Date(),
          kind: 'credit',
          // recomputed before save
          chartAccount: this.defaultCategory(),
          billToContact: null,
          is1099Required: false,
          tags: []
        });

        var opposingLine = _formLine.default.create({
          date: new Date(),
          kind: 'debit',
          // recomputed before save
          chartAccount: null,
          billToContact: null,
          is1099Required: false,
          tags: []
        });

        return [primaryLine, opposingLine];
      } // if we're editing an existing journalEntry
      else {
          return lines.map(function (jeLine) {
            return _this.convertFromLine(jeLine, _this._amount(jeLine, pl));
          });
        }
    },
    convertFromLine: function convertFromLine(jeLine, amount) {
      return _formLine.default.create({
        id: jeLine.get('id'),
        date: jeLine.get('date'),
        amount: amount || jeLine.get('amount'),
        kind: jeLine.get('kind'),
        // recomputed before save
        previousKind: jeLine.get('kind'),
        chartAccount: jeLine.get('chartAccount'),
        billToContact: jeLine.get('billToContact'),
        is1099Required: jeLine.get('is1099Required'),
        tags: jeLine.getWithDefault('tags', []).toArray()
      });
    },
    convertToLines: function convertToLines(formLines) {
      var _this2 = this;

      return formLines.map(function (line) {
        return _this2.convertToLine(line);
      });
    },
    convertToLine: function convertToLine(formLine) {
      var type = this.recurring ? 'recurringJournalEntryLine' : 'journalEntryLine';
      var jeLine;

      if (formLine.get('id')) {
        jeLine = this.store.peekRecord(type, formLine.get('id'));
      } else {
        jeLine = this.store.createRecord(type, {
          tags: [] // remove once this is fixed https://github.com/emberjs/data/issues/5100

        });
      }

      jeLine.setProperties({
        amount: Math.abs(formLine.get('amount')),
        kind: formLine.get('kind'),
        date: formLine.get('date'),
        tags: formLine.getWithDefault('tags', []).toArray(),
        chartAccount: formLine.get('chartAccount'),
        billToContact: formLine.get('billToContact'),
        is1099Required: formLine.get('is1099Required'),
        hasErrors: Object.keys(formLine.get('errors')).any(function (key) {
          return formLine.get("errors.".concat(key, ".length")) !== 0;
        })
      });
      return jeLine;
    },
    _amount: function _amount(line, primary) {
      if (line !== primary && line.get('kind') === primary.get('kind')) {
        return line.get('amount') * -1;
      } else {
        return line.get('amount');
      }
    },
    // can be overridden
    defaultCategory: function defaultCategory() {
      var chartAccounts = this.store.chartAccounts();
      var currencyCode = this.get('session.account.defaultCurrencyCode') || 'USD';
      return (0, _defaultBankAccount.default)(chartAccounts, currencyCode);
    }
  });

  _exports.default = _default;
});