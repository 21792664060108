define("zipbooks/mixins/list-controller-transactionable", ["exports", "zipbooks/mixins/bulk-operation"], function (_exports, _bulkOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_bulkOperation.default, {
    bulkConfirm: function bulkConfirm(journalEntries, callback) {
      var _this = this;

      if (journalEntries.length > 0) {
        this.batch(journalEntries, function (je) {
          je.get('journalEntryLines').forEach(function (line) {
            return line.categorizeWithSuggestionByDefault();
          });
          je.set('isConfirmed', true);
          return je.serialize({
            includeId: true
          });
        }, function (results) {
          _this.unselectAll();

          _this.showBulkBanner('confirmed', results.succeeded.length, results.unchanged.length, results.failed.length);

          results.failed.forEach(function (je) {
            return je.set('isConfirmed', false);
          });
          callback && callback(results);
        });
      }
    },
    bulkUnconfirm: function bulkUnconfirm(journalEntries, callback) {
      var _this2 = this;

      if (journalEntries.length > 0) {
        this.batch(journalEntries, function (je) {
          je.set('isConfirmed', false);
          return je.serialize({
            includeId: true
          });
        }, function (results) {
          _this2.unselectAll();

          _this2.showBulkBanner('unconfirmed', results.succeeded.length, results.unchanged.length);

          results.failed.forEach(function (je) {
            return je.set('isConfirmed', true);
          });
          callback && callback(results);
        });
      }
    },
    match: function match(entry1, entry2, callback) {
      var _this3 = this;

      this.overlay.showDialog('overlays/dialogs/match-confirm', 'Are you sure?', {
        warning: 'Are you sure you want to match these transactions?'
      }, {
        match: function match() {
          _this3.client.send('PATCH', 'journal-entries', 'match', {
            journal_entry_1: entry1.get('id'),
            journal_entry_2: entry2.get('id')
          }).then(function () {
            _this3.overlay.showBanner('success', 'Transactions matched successfully!');

            _this3.unselectAll();

            callback && callback();
          }, function (jqXHR
          /*, textStatus,errorThrown */
          ) {
            var error = jqXHR.responseJSON.errors[0].detail;

            _this3.overlay.showBanner('error', error, {
              title: 'Oops!'
            });
          });
        }
      });
    },
    bulkDelete: function bulkDelete(journalEntries, callback, func) {
      var _this4 = this;

      if (journalEntries.length > 0) {
        // confirm
        this.overlay.showDialog('overlays/dialogs/destroy-confirm', 'Are you sure?', {
          count: journalEntries.length,
          type: 'transaction'
        }, {
          destroy: function destroy() {
            // provide a function for deleting jes one by one
            var deleteModels = func || function (models) {
              _this4.bulk(models, function (model) {
                return model.destroyRecord();
              }, function (results) {
                results.succeeded.forEach(function (m) {
                  return m.unloadRecord();
                });
                results.failed.forEach(function (m) {
                  return m.rollbackAttributes();
                });

                _this4.unselectAll();

                _this4.showBulkBanner('deleted', results.succeeded.length, 0, results.failed.length);

                callback && callback(results);
              });
            }; // make sure if there are any selected entries that will break reconciliation, we ask the user


            var models = journalEntries.mapBy('id').map(function (id) {
              return _this4.store.peekRecord('journalEntry', id);
            });

            if (models.any(function (model) {
              return model.hasChangesThatBreakReconciliation();
            })) {
              _this4.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
                title: Ember.String.htmlSafe("Some of the lines you have selected will break reconcilations. Do you want to delete all of these or only lines that won't?"),
                confirmButton: {
                  title: 'Yes, delete all',
                  class: 'btn-red text-small-bd'
                },
                cancelButton: {
                  title: "Only those that won't break reconcilations",
                  class: 'btn-white text-small-bd'
                }
              }, {
                confirm: function confirm() {
                  deleteModels(models, callback);
                },
                cancel: function cancel() {
                  deleteModels(models.filter(function (model) {
                    return !model.hasChangesThatBreakReconciliation();
                  }), callback);
                },
                close: function close() {}
              });
            } else {
              deleteModels(models, callback);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});