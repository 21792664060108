define("zipbooks/components/drawers/verify-micro-deposits-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    title: 'Verify microdeposits',
    actions: {
      save: function save() {
        var _this = this;

        this.set('buttonState', 'saving');
        this.client.send('POST', 'payments', "bank-accounts/".concat(this.rest.bank_id, "/verify"), {
          first_deposit: this.firstDeposit,
          second_deposit: this.secondDeposit
        }).then(function () {
          _this.set('buttonState', 'loaded');

          _this.notificationCenter.notify('payments.bank-account.verified', null);

          _this.handleClose();
        }, function (resp) {
          _this.set('buttonState', 'invalid');

          _this.overlay.showBanner('error', resp.responseJSON.errors.mapBy('detail').join('<br>'));
        });
      }
    }
  });

  _exports.default = _default;
});