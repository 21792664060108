define("zipbooks/components/drawers/financial-package-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    title: 'Export financial package',
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('include', this.get('session.account.settings.reportsDefaultShowUnconfirmed') ? 'all' : 'confirmed');
    },
    includeIncomeStatement: true,
    includeBalanceSheet: true,
    groupBy: 'month',
    startDate: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    actions: {
      export: function _export() {
        var _this = this;

        this.set('buttonState', 'saving');
        var params = {
          start_date: moment(this.startDate).isAfter(moment(this.endDate)) ? this.endDate : this.startDate,
          end_date: this.endDate,
          group_by: this.groupBy,
          include: this.include,
          include_income_statement: this.includeIncomeStatement,
          include_balance_sheet: this.includeBalanceSheet,
          tmz_offset: moment().utcOffset()
        };
        this.client.POST('reports', 'financial-package', params).then(function (resp) {
          _this.set('buttonState', 'loaded');

          _this.handleClose();

          _this.router.transitionTo('main.documents');

          if (_this.sendMessage) {
            var name = 'financial reports';

            if (_this.includeIncomeStatement && !_this.includeBalanceSheet) {
              name = 'income statement';
            } else if (!_this.includeIncomeStatement && _this.includeBalanceSheet) {
              name = 'balance sheet';
            }

            _this.overlay.showPopout('messaging/accountant-messenger', {
              suggestedMessage: "Hi, here's your ".concat(name, " from ").concat(moment(_this.startDate).format('MMM YYYY'), " to ").concat(moment(_this.endDate).format('MMM YYYY'), ": ").concat(window.location.origin, "/v2/cloud-files/").concat(resp['token'], "/download")
            });
          }

          window.open(resp['download-url'], '_blank');
        }, function () {
          _this.set('buttonState', 'invalid');

          _this.overlay.showBanner('error', 'The package failed to generate, please try again.');
        });
      },
      quickReport: function quickReport(kind) {
        if (kind === 'previous_3_months') {
          this.set('startDate', moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'));
          this.set('endDate', moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
          this.set('groupBy', 'month');
        } else if (kind === 'this_year') {
          this.set('startDate', moment().startOf('year').format('YYYY-MM-DD'));
          this.set('endDate', moment().format('YYYY-MM-DD'));
          this.set('groupBy', 'month');
        } else if (kind === 'last_year') {
          this.set('startDate', moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
          this.set('endDate', moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
          this.set('groupBy', 'year');
        } else if (kind === 'month_to_date') {
          this.set('startDate', moment().startOf('month').format('YYYY-MM-DD'));
          this.set('endDate', moment().format('YYYY-MM-DD'));
          this.set('groupBy', 'month');
        } else if (kind === 'last_month') {
          this.set('startDate', moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
          this.set('endDate', moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
          this.set('groupBy', 'month');
        }
      }
    }
  });

  _exports.default = _default;
});