define("zipbooks/components/invoice-history", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    classNames: 'history',
    client: Ember.inject.service(),
    onRefund: function onRefund()
    /*historyItem*/
    {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.fetchHistory();
    },
    _history: Ember.observer('invoice.id', 'invoice.paidTotal', 'invoice.status', function () {
      this.fetchHistory();
    }),
    fetchHistory: function fetchHistory() {
      var _this = this;

      this.client.GET('invoices', "".concat(this.invoice.id, "/history")).then(function (resp) {
        return _this.set('history', resp);
      });
    },
    actions: {
      logPayment: function logPayment() {
        this.logPayment();
      }
    }
  });

  _exports.default = _default;
});