define("zipbooks/templates/components/drawers/-gusto-integration-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7v0NjQ4U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-weight-bold\"],[8],[0,\"\\n        Sync payroll transactions\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mb-2 font-size-14px\"],[8],[0,\"\\n        With sync payroll transactions turned \"],[7,\"b\",true],[8],[0,\"On\"],[9],[0,\", ZipBooks will pull information from your Gusto account to\\n        automatically create transactions for reporting and accounting in ZipBooks.\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"switch\"],[8],[0,\"\\n        \"],[1,[28,\"toggle-switch\",null,[[\"highlight\",\"checked\"],[\"enable-sync\",[24,[\"model\",\"enableSync\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"enableSync\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n      \"],[1,[28,\"date-picker\",null,[[\"label\",\"value\",\"placeholder\"],[true,[24,[\"model\",\"syncedTo\"]],\"Sync payrolls back to\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"action\",\"model\",\"title\",\"class\",\"testId\"],[[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null],[24,[\"model\"]],\"Save\",\"btn btn-full btn-blue btn-depressable\",\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-gusto-integration-form.hbs"
    }
  });

  _exports.default = _default;
});