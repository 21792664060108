define("zipbooks/templates/components/bill-history-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yqgGgfeZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"table-first-col item p-1\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-weight-bold item-date\"],[8],[1,[28,\"format-date\",[[24,[\"model\",\"date\"]]],null],false],[0,\":\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item-message\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"action\"]],\"create\"],null]],null,{\"statements\":[[0,\"      You added this bill.\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"action\"]],\"edit\"],null]],null,{\"statements\":[[0,\"      You edited this bill.\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"action\"]],\"payment\"],null]],null,{\"statements\":[[0,\"      You paid\\n      \"],[1,[28,\"format-money\",[[24,[\"model\",\"paid_amount\"]]],[[\"currency\"],[[24,[\"currency\"]]]]],false],[0,\"\\n      on this bill.\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/bill-history-item.hbs"
    }
  });

  _exports.default = _default;
});