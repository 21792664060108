define("zipbooks/routes/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.Object.create({
        email: null
      });
    }
  });

  _exports.default = _default;
});