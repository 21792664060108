define("zipbooks/controllers/main/settings/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    provider: Ember.computed(function () {
      return this.get('session.account.settings.bankAccountProvider');
    }),
    adminPassword: null,
    actions: {
      save: function save() {
        var _this = this;

        this.set('buttonStatus', 'saving');
        var body = {
          provider: this.provider
        };
        return this.client.send('PATCH', 'admin/settings', null, body).then(function () {
          _this.set('session.account.settings.bankAccountProvider', _this.provider);

          _this.set('buttonStatus', 'loaded');
        }, function () {
          _this.set('buttonStatus', 'invalid');
        });
      },
      selectProvider: function selectProvider(provider) {
        this.set('provider', provider.id);
      }
    }
  });

  _exports.default = _default;
});