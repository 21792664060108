define("zipbooks/controllers/main/time-entries", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/bulk-operation", "zipbooks/mixins/list-controller-selectable", "zipbooks/utils/format-seconds-as-hours", "zipbooks/utils/string"], function (_exports, _controllerPagination, _bulkOperation, _listControllerSelectable, _formatSecondsAsHours, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _listControllerSelectable.default, _bulkOperation.default, {
    overlay: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    queryParams: ['user_id', 'contact_id', 'project_id', 'start_date', 'end_date', 'billed', 'paid', 'group'],
    direction: 'desc',
    sort: 'date',
    modelType: 'time-entry',
    collection: Ember.computed('model.groups.@each', function () {
      return this.get('model.groups').reduce(function (acc, g) {
        return acc.concat(g.timeEntries.toArray());
      }, []);
    }),
    cachedCollection: Ember.computed('model.groups.@each', function () {
      return this.get('model.groups').toArray();
    }),
    filteredDuration: Ember.computed('model.groups', function () {
      return this.get('model.groups.meta.filtered-duration') || 0;
    }),
    filteredHours: Ember.computed('model.groups', function () {
      var seconds = this.get('model.groups.meta.filtered-duration') || 0;
      return (0, _formatSecondsAsHours.default)(seconds, 'h');
    }),
    filteredMinutes: Ember.computed('model.groups', function () {
      var seconds = this.get('model.groups.meta.filtered-duration') || 0;
      return (0, _formatSecondsAsHours.default)(seconds, 'm');
    }),
    actions: {
      toggleCheckAll: function toggleCheckAll(checked) {
        this.model.groups.forEach(function (g) {
          return g.set('isSelected', checked);
        });
        this.collection.forEach(function (entry) {
          if (!entry.get('isInProgress')) {
            entry.set('isSelected', checked);
          }
        });
      },
      openGroup: function openGroup(group_id) {
        this.transitionToRoute({
          queryParams: {
            group: group_id
          }
        });
      },
      closeGroup: function closeGroup() {
        this.transitionToRoute({
          queryParams: {
            group: null
          }
        });
      },
      filterByUser: function filterByUser(user) {
        this.transitionToRoute({
          queryParams: {
            user_id: user && user.get('id'),
            page: 1
          }
        });
      },
      filterByContact: function filterByContact(contact) {
        this.transitionToRoute({
          queryParams: {
            contact_id: contact && contact.get('id'),
            page: 1
          }
        });
      },
      filterByProject: function filterByProject(project) {
        this.transitionToRoute({
          queryParams: {
            project_id: project && project.get('id'),
            page: 1
          }
        });
      },
      startedEntry: function startedEntry(entry) {
        this.cachedCollection.unshiftObject(entry);
      },
      bulkMarkAsBilled: function bulkMarkAsBilled() {
        var _this = this;

        var ids = this.selected.mapBy('id');

        if (ids.length > 0) {
          this.bulk(ids.map(function (id) {
            return _this.store.peekRecord('time-entry', id);
          }), function (model) {
            model.set('billed', true);
            return model.save();
          }, function (result) {
            result.failed.forEach(function (m) {
              return m.rollbackAttributes();
            });

            _this.send('refreshRoute');

            _this.unselectAll();

            _this.model.groups.forEach(function (g) {
              return g.set('isSelected', false);
            });

            _this.showBulkBanner('marked as billed', result.succeeded.length, result.unchanged.length, 0, 'time entries');
          });
        }
      },
      bulkMarkAsPaid: function bulkMarkAsPaid() {
        var _this2 = this;

        var ids = this.selected.mapBy('id');

        if (ids.length > 0) {
          this.bulk(ids.map(function (id) {
            return _this2.store.peekRecord('time-entry', id);
          }).filter(function (te) {
            return !te.gustoPayrollId;
          }), function (model) {
            model.set('paid', true);
            return model.save();
          }, function (result) {
            result.failed.forEach(function (m) {
              return m.rollbackAttributes();
            });

            _this2.send('refreshRoute');

            _this2.unselectAll();

            _this2.model.groups.forEach(function (g) {
              return g.set('isSelected', false);
            });

            _this2.showBulkBanner('marked as paid', result.succeeded.length, result.unchanged.length, 0, 'time entries');
          });
        }
      },
      destroy: function destroy(model) {
        var _this3 = this;

        if (!model) {
          return;
        }

        this.overlay.showDialog('overlays/dialogs/destroy-confirm', (0, _string.default)('time-entry', 'destroy', 'title'), {
          type: 'time-entry'
        }, {
          destroy: function destroy() {
            _this3.urlCache.clear();

            return model.destroyRecord().then(function () {
              model.unloadRecord();

              _this3.send('refreshRoute');
            }, function (error) {
              model.rollbackAttributes();

              if (error.errors && error.errors.length > 0) {
                _this3.overlay.showBanner('error', error.errors[0].detail);
              }
            });
          }
        });
      },
      bulkDestroy: function bulkDestroy() {
        var _this4 = this;

        var ids = this.selected.mapBy('id');

        if (ids.length > 0) {
          this.overlay.showDialog('overlays/dialogs/destroy-confirm', (0, _string.default)('time-entry', 'destroy', 'title'), {
            type: 'time-entry',
            count: ids.length
          }, {
            destroy: function destroy() {
              _this4.bulk(ids.map(function (id) {
                return _this4.store.peekRecord('time-entry', id);
              }).filter(function (te) {
                return !te.gustoPayrollId;
              }), function (model) {
                return model.destroyRecord();
              }, function (result) {
                result.succeeded.forEach(function (m) {
                  return m.unloadRecord();
                });
                result.failed.forEach(function (m) {
                  return m.rollbackAttributes();
                });

                _this4.send('refreshRoute');

                _this4.unselectAll();

                _this4.model.groups.forEach(function (g) {
                  return g.set('isSelected', false);
                });

                _this4.showBulkBanner('deleted', result.succeeded.length, result.unchanged.length, result.failed.length, 'time entries');
              });
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});