define("zipbooks/helpers/project-user-hours", ["exports", "zipbooks/utils/parse-number"], function (_exports, _parseNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.projectUserHours = projectUserHours;
  _exports.default = void 0;

  function projectUserHours(params
  /*, hash*/
  ) {
    var project = params[0];
    var user = params[1];
    var sum = project.get('timeEntries').reduce(function (a, b) {
      if (b.get('user.id') === user.get('id')) {
        return a + (0, _parseNumber.default)(b.get('duration'));
      } else {
        return a;
      }
    }, 0);
    return (sum / 3600).round(2);
  }

  var _default = Ember.Helper.helper(projectUserHours);

  _exports.default = _default;
});