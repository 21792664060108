define("zipbooks/templates/components/drawers/-add-to-invoice-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FCtIdEkI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[1,[28,\"line-item-previewer\",null,[[\"model\",\"contact\",\"format\"],[[24,[\"formModel\"]],[24,[\"model\"]],\"invoice\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"error error-static mt-2\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-full btn-blue btn-depressable\"],[12,\"data-test-id\",\"add-line-items\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"add\"]],[8],[0,\"Add Line Items Now\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-add-to-invoice-form.hbs"
    }
  });

  _exports.default = _default;
});