define("zipbooks/components/suggest/project-select", ["exports", "zipbooks/components/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Project',
    layoutName: 'components/auto-suggest',
    store: Ember.inject.service(),
    throttle: 500,
    assignedTo: null,
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var _this = this;

      var params = {
        filter: {
          archived: false,
          q: search
        }
      };

      if (this.assignedTo) {
        params.filter.user_id = this.get('assignedTo.id');
      }

      if (this.get('contact.id')) {
        params.sort = "-contact_".concat(this.get('contact.id'), ",name");
      }

      return this.store.cachedQuery('project', params).then(function (results) {
        _this.set('isFetched', true);

        if (_this.get('child.id')) {
          return results.filter(function (project) {
            var sameProject = project.get('id') === _this.get('child.id');

            var sameRootParent = project.get('mainParent.id') === _this.get('child.mainParent.id');

            var bothRoot = !project.get('mainParent.id') && !_this.get('child.mainParent.id');
            return !sameProject && (!sameRootParent || bothRoot) && !project.get('allowMultipleContacts');
          });
        }

        return results;
      });
    },
    labelForResult: function labelForResult(result) {
      return result.get('name');
    },
    handleAddNew: function handleAddNew(label) {
      var _this2 = this;

      var project = this.store.createRecord('project', {
        name: label,
        contact: this.contact
      });
      this.overlay.showDrawer('drawers/project-form', project, {
        saved: function saved(model) {
          _this2.trySelect(model);
        }
      });
    },
    _assignedChanged: Ember.observer('assignedTo.id', function () {
      this.set('filteredResults', []);
      this.updateResultsMatching();
      this.set('show', false);
    })
  });

  _exports.default = _default;
});