define("zipbooks/components/paid-tag", ["exports", "zipbooks/mixins/paid-action"], function (_exports, _paidAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PaidTagComponent = Ember.Component.extend(_paidAction.default, {
    session: Ember.inject.service(),
    actions: {
      click: function click() {
        Ember.tryInvoke(this, 'action');
      }
    }
  });
  PaidTagComponent.reopenClass({
    positionalParams: ['dynamicTagName', 'needs']
  });
  var _default = PaidTagComponent;
  _exports.default = _default;
});