define("zipbooks/templates/main/partials/-add-from-quickbooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+hXl5YvH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"rest\",\"linkedContactId\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn btn-green btn-depressable btn-small mr-2\"],[3,\"action\",[[23,0,[]],\"addFromQuickbooks\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"icon-quickbooks font-size-18px\"],[8],[9],[0,\"\\n    Add from QuickBooks\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/main/partials/-add-from-quickbooks.hbs"
    }
  });

  _exports.default = _default;
});