define("zipbooks/templates/components/confirm-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tgqZeRnq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"can\",[\"accounting.all\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[22,\"confirmedIconUrl\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggle\"],null]],[11,\"title\",[22,\"title\"]],[11,\"data-test-id\",[22,\"testId\"]],[11,\"data-test-is-checked\",[29,[[22,\"checked\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[22,\"confirmedIconUrl\"]],[11,\"title\",[22,\"title\"]],[11,\"data-test-id\",[22,\"testId\"]],[11,\"data-test-is-checked\",[29,[[22,\"checked\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"showMatched\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"success-tooltip mt6 fadeSlideInOut-right\"],[8],[0,\"Matched!\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/confirm-check.hbs"
    }
  });

  _exports.default = _default;
});