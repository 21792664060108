define("zipbooks/components/line-itemable-history", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    classNames: 'history',
    client: Ember.inject.service()
  });

  _exports.default = _default;
});