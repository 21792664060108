define("zipbooks/utils/parse-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseDate;

  function parseDate(string) {
    var formats = ['.', '-', '/'].reduce(function (acc, s) {
      return acc.concat(["M".concat(s, "D").concat(s, "YYYY"), "M".concat(s, "D").concat(s, "YY")]);
    }, []);
    var parsed = moment(string, formats, true);
    return parsed.isValid() ? parsed : null;
  }
});