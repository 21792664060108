define("zipbooks/models/estimate", ["exports", "zipbooks/mixins/line-itemable", "@ember-data/model", "zipbooks/models/model"], function (_exports, _lineItemable, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_lineItemable.default, {
    date: (0, _model.attr)('date'),
    externalId: (0, _model.attr)(),
    history: (0, _model.attr)(),
    number: (0, _model.attr)(),
    daysOut: (0, _model.attr)(),
    status: (0, _model.attr)(),
    sentDate: (0, _model.attr)(),
    amountDue: Ember.computed.alias('total'),
    contact: (0, _model.belongsTo)('contact'),
    invoice: (0, _model.belongsTo)('invoice'),
    account: (0, _model.belongsTo)('account')
  });

  _exports.default = _default;
});