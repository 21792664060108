define("zipbooks/mixins/with-router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    })
  });

  _exports.default = _default;
});