define("zipbooks/components/paid-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click: function click() {
      Ember.tryInvoke(this, 'action', arguments);
    }
  });

  _exports.default = _default;
});