define("zipbooks/controllers/main/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['tab'],
    tab: 'info',
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    logoUploadedViaHeadlineImage: false,
    actions: {
      save: function save() {
        return this.get('model.account').save();
      },
      selectRevenueBracket: function selectRevenueBracket(revenue) {
        this.set('model.account.annualRevenue', revenue);
      },
      selectBusinessEntityTypeBracket: function selectBusinessEntityTypeBracket(businessEntityType) {
        this.set('model.account.businessEntityType', businessEntityType);
      },
      selectCountry: function selectCountry(country) {
        this.set('model.location.country', country && country.id);
      },
      saveAccount: function saveAccount() {
        return this.get('model.account').save();
      },
      saveLocation: function saveLocation(confirm) {
        var _this = this;

        return this.confirmSave(confirm).then(function () {
          return _this.get('model.location').save();
        }, function () {
          return _this.get('model.location').rollbackAttributes();
        });
      }
    },
    revenueValues: ['$0 - $10,000', '$10,000 - $50,000', '$50,000 - $200,000', '$200,000 - $500,000', '$500,000 - $1,000,000', '$1,000,000 - $5,000,000', '$5,000,000+'],
    businessEntityTypes: ['Sole Proprietorship', 'LLC', 'S Corp', 'LLP', 'General Partnership', 'C Corp', 'Other'],
    hasSomeAddress: Ember.computed('model.location.address_1', 'model.location.address_2', 'model.location.city', 'model.location.state', 'model.location.postalCode', function () {
      var _this2 = this;

      if (['address_1', 'address_2', 'city', 'state', 'postalCode'].any(function (k) {
        return _this2.get('model.location.' + k);
      })) {
        return true;
      }
    }),
    connectedIntegrations: Ember.computed('model.integrations.@each', function () {
      var _this3 = this;

      return this.model.integrations.map(function (i) {
        return {
          integration: i,
          externalLocations: i.get('externalLocations.length') > 0 ? i.externalLocations.filter(function (loc) {
            return loc.get('location.id') === _this3.model.location.id;
          }) : []
        };
      }).filter(function (i) {
        return i.externalLocations.length > 0;
      });
    }),
    confirmSave: function confirmSave(confirm) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (confirm) {
          var integrations = _this4.get('model.integrations').filter(function (i) {
            return i.get('externalLocations.length') > 0;
          });

          var integration = integrations.find(function (i) {
            return i.get('providerInfo.syncs');
          });

          if (integration) {
            var found = integration.get('externalLocations').find(function (l) {
              return l.get('location.id') === _this4.get('model.location.id');
            });

            if (found) {
              _this4.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
                title: "Saving this location will sync changes back to <b>".concat(integration.name, "</b>."),
                confirmButton: {
                  title: 'Yes, Save',
                  class: 'btn-red'
                },
                cancelButton: {
                  title: 'No, cancel',
                  class: 'btn-white'
                }
              }, {
                confirm: function confirm() {
                  resolve();
                },
                cancel: function cancel() {
                  reject();
                }
              });

              return;
            }
          }
        }

        resolve();
      });
    }
  });

  _exports.default = _default;
});