define("zipbooks/mixins/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    transitionTo: function transitionTo() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var onComplete = args[args.length - 1] && args[args.length - 1].onComplete;

      if (onComplete && !args[args.length - 1].queryParams) {
        args[args.length - 1].queryParams = {};
      }

      var transition = this._super.apply(this, arguments);

      if (onComplete) {
        transition.data.onComplete = onComplete;
      }

      return transition;
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeWillChange', function (transition) {
        transition.then(function () {
          Ember.tryInvoke(transition.data, 'onComplete');

          _this.session.forceReloadIfNewVersionAvailable();
        });
      });
    }
  });

  _exports.default = _default;
});