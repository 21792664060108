define("zipbooks/routes/main/locations", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/refreshable-route", "zipbooks/mixins/integrations-route", "zipbooks/mixins/google-maps-route", "zipbooks/mixins/permission-redirect"], function (_exports, _routePagination, _autoUpdatingRoute, _refreshableRoute, _integrationsRoute, _googleMapsRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _autoUpdatingRoute.default, _refreshableRoute.default, _integrationsRoute.default, _googleMapsRoute.default, _permissionRedirect.default, {
    autoUpdatingEvent: 'location.save',
    autoUpdatingModel: 'locations',
    model: function model(params) {
      return Ember.RSVP.hash({
        locations: this.store.cachedQuery('location', {
          page: {
            page: params.page || 1,
            'page-size': 30
          },
          sort: '-created_at',
          filter: {
            archived: false
          }
        }),
        integrations: this.integrationsPromise()
      });
    }
  });

  _exports.default = _default;
});