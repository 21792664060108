define("zipbooks/components/line-item-line", ["exports", "zipbooks/mixins/paid-modal-handler", "zipbooks/utils/drawer-link-for"], function (_exports, _paidModalHandler, _drawerLinkFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, {
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: '',
    actions: {
      edit: function edit(e) {
        var _this = this;

        e.preventDefault();
        e.stopPropagation();
        this.overlay.showPopover('overlays/popovers/tax-form', (e || event).target, 'Line Item Options', {
          direction: 'right',
          lineItem: this.lineItem,
          lineItemable: this.lineItemable
        }, {
          syncLineItemTaxes: function syncLineItemTaxes(taxes) {
            Ember.tryInvoke(_this, 'syncLineItemTaxes', [taxes]);
          },
          showTaxesDrawer: function showTaxesDrawer() {
            _this.router.transitionTo({
              queryParams: {
                drawer: (0, _drawerLinkFor.default)(['taxes'])
              }
            });
          },
          addNewTax: function addNewTax(label, lineItem, position) {
            var tax = _this.store.createRecord('tax', {
              name: label
            });

            _this.overlay.showDrawer('drawers/taxes-form', tax, {
              saved: function saved(taxes) {
                var newTax = taxes.find(function (t) {
                  return t.get('name') === label;
                });

                if (newTax) {
                  var lineTaxes = lineItem.get('taxes') || [];
                  var _tax = {
                    id: newTax.get('id'),
                    name: newTax.get('name'),
                    rate: newTax.get('rate')
                  };

                  if (position === '0') {
                    lineItem.set('taxes', [_tax, lineTaxes.objectAt(1) || null]);
                  } else {
                    lineItem.set('taxes', [lineTaxes.objectAt(0) || null, _tax]);
                  }

                  if (_this.lineItemable.syncTaxes) {
                    Ember.tryInvoke(_this, 'syncLineItemTaxes', [lineItem.taxes]);
                  }
                }
              }
            });
          }
        });
        return false;
      },
      delete: function _delete() {
        Ember.tryInvoke(this, 'delete', []);
      },
      updateItemTitle: function updateItemTitle(name) {
        this.lineItem.set('name', name);
      },
      selectItem: function selectItem(item) {
        if (item) {
          this.lineItem.set('item', item);
          this.lineItem.set('name', item.name);
          this.lineItem.set('notes', item.description);
          this.lineItem.set('rate', item.price);
          this.lineItem.set('quantity', 1);
          this.lineItem.set('chartAccount', item.chartAccount);
        }
      },
      saveAsItem: function saveAsItem() {
        var _this2 = this;

        var item = this.get('store').createRecord('item', {
          name: this.lineItem.name,
          description: this.lineItem.notes,
          price: this.lineItem.rate,
          kind: 'invoice'
        });
        this.set('itemModel', item);
        item.save().then(function () {
          _this2.overlay.showBanner('success', Ember.String.htmlSafe('Line saved to <b>Items</b> successfully.'));
        }, function (error) {
          if (Ember.get(error, 'errors.firstObject.source.pointer') === '/data/attributes/name') {
            _this2.overlay.showBanner('error', Ember.String.htmlSafe('Please add a <b>title</b> and a <b>price/rate</b> before saving this line as an Item.'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});