define("zipbooks/controllers/main", ["exports", "zipbooks/utils/redirect", "zipbooks/utils/navigate-to", "zipbooks/mixins/drawers", "zipbooks/mixins/with-router", "zipbooks/mixins/browser-fixes", "zipbooks/mixins/subscription-banners", "zipbooks/utils/storage", "zipbooks/utils/drawer-link-for", "zipbooks/mixins/paid-modal-handler", "zipbooks/utils/search-in", "zipbooks/utils/push-payload", "zipbooks/utils/slow-array"], function (_exports, _redirect, _navigateTo, _drawers, _withRouter, _browserFixes, _subscriptionBanners, _storage, _drawerLinkFor, _paidModalHandler, _searchIn, _pushPayload, _slowArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_drawers.default, _withRouter.default, _browserFixes.default, _subscriptionBanners.default, _paidModalHandler.default, {
    session: Ember.inject.service(),
    sockets: Ember.inject.service(),
    overlay: Ember.inject.service(),
    showNotificationsMenu: false,
    unReadNotificationsCount: 0,
    notificationStore: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    showNavigationXs: Ember.computed('router.currentPath', function () {
      var pathSubstrs = ['main.reports.'];
      var currentPath = this.get('router.currentPath');

      if (pathSubstrs.some(function (element) {
        return currentPath.indexOf(element) >= 0;
      })) {
        return true;
      }
    }),
    showAccountsSearch: Ember.computed('session.currentUser.identity.userTokens.length', function () {
      return this.get('session.currentUser.identity.userTokens.length') > 3;
    }),
    showDropdownMenuIdentityBorder: Ember.computed('session.currentUser.identity.userTokens.length', 'showAccountsSearch', function () {
      return this.get('session.currentUser.identity.userTokens.length') > 1 && !this.get('showAccountsSearch');
    }),
    tokens: Ember.computed('session.currentUser.identity.userTokens', 'searchText', function () {
      var _this = this;

      var tokens = this.getWithDefault('session.currentUser.identity.userTokens', []).filter(function (t) {
        return "".concat(t.user_id) !== _this.get('session.currentUser.id');
      });

      if (this.searchText) {
        return (0, _slowArray.slowNativeArray)((0, _searchIn.default)(tokens, 'account_name', this.searchText));
      } else {
        return (0, _slowArray.slowNativeArray)(tokens);
      }
    }),
    trackerType: Ember.computed('session.subscription.plan.kind', 'session.currentUser.identity.accountantToken', function () {
      if (this.get('session.subscription.plan.kind') !== 'cpa' && !!this.get('session.currentUser.identity.accountantToken')) {
        return 'accountant-tracker';
      } else {
        return 'mini-tracker';
      }
    }),
    accountantUI: Ember.computed('session.privileges.@each', function () {
      // return this.session.hasPrivilege('accountant')
      return this.get('session.subscription.plan.kind') === 'cpa';
    }),
    hasPermissionToSeeMyPracticeBox: Ember.computed(function () {
      return this.permissions.any(['invoices.read', 'estimates.read', 'projects.read', 'time_tracking.read', 'account.read', 'contacts.read', 'account.read', 'users.read']);
    }),
    actions: {
      logout: function logout() {
        // we do this to make sure there is a clean slate
        // it causes errors to call `this.session.logout()` here. But, its called when the login
        // route is entered, so we'll just redirect there and let it call it
        (0, _redirect.default)('/login');
      },
      navigateToSearched: function navigateToSearched(searched) {
        if (!searched) {
          return;
        }

        if (searched.privileges && !this.session.hasPrivilege(searched.privileges.firstObject)) {
          this.send('showPaidModal', searched.privileges.firstObject, {
            afterUpgrade: searched.transition.route
          });
        } else {
          // TODO: test this
          var router = Ember.getOwner(this).lookup('router:main'); // if its an action, handle the action special

          if (searched.type === 'action') {
            if (searched.transition.drawer && searched.transition.params) {
              var params = (0, _drawerLinkFor.default)([searched.transition.drawer, searched.transition.params]);
              router.transitionTo({
                queryParams: {
                  drawer: params
                }
              });
            } else if (searched.transition.drawer) {
              var _params = (0, _drawerLinkFor.default)([searched.transition.drawer]);

              router.transitionTo({
                queryParams: {
                  drawer: _params
                }
              });
            } else if (searched.transition.route && searched.transition.params) {
              router.transitionTo(searched.transition.route, {
                queryParams: searched.transition.params
              });
            } else if (searched.transition.route && searched.transition.model) {
              router.transitionTo(searched.transition.route, searched.transition.model);
            } else {
              router.transitionTo(searched.transition.route);
            }
          } // otherwise, navigate to the resource
          else {
              (0, _navigateTo.default)(router, searched.type, searched.id);
            }
        }
      },
      linkLogin: function linkLogin(token) {
        // there is weird flickering of empty data when `logout` empties the store, so we blank the whole screen first
        $('body').hide();
        Ember.run.later(this, function () {
          return $('body').show();
        }, 1000); // there's a lot that could get funky if mixed user data happens, so we just hard reload the page to be safe.

        var isStealth = this.get('session.isStealth');
        this.session.login(token, _storage.default.get('remember'), isStealth);
        (0, _redirect.default)('/', {
          force: true
        });
      },
      toggleNotificationsMenu: function toggleNotificationsMenu() {
        this.toggleProperty('showNotificationsMenu');
      },
      notificationsDidClose: function notificationsDidClose() {
        var _this2 = this;

        this.notificationStore.query('notification', {}).then(function (results) {
          _this2.set('notifications', results.toArray());

          _this2.set('unReadNotificationsCount', results.get('meta.unread'));
        });
      },
      showAccountsMessenger: function showAccountsMessenger() {
        this.overlay.showComponent('messaging/accounts-messenger', {});
      }
    },
    // we need this listening globally because we're going to show notifications even if the messenger is not open
    setupMessengerWebsocket: function setupMessengerWebsocket() {
      var _this3 = this;

      this._super.apply(this, arguments);

      var socket = this.sockets.socketForToken(this.session.token);
      var channel = socket.channel("messenger:".concat(this.get('session.currentUser.identity.id')), {});
      channel.join().receive('ok', function (resp) {
        _this3.notificationCenter.notify('joined-messages-channel', resp);
      }); // .receive('error', resp => { console.log('Unable to join', resp) })

      channel.on('new_message', function (payload) {
        console.info("Websocket received", payload);
        var message = (0, _pushPayload.default)(_this3.store, 'message', payload);

        _this3.notificationCenter.notify('new-message', message);
      });
      channel.on('messaging_accounts_update', function (payload) {
        _this3.notificationCenter.notify('messaging-accounts-update', payload);
      });
    },
    canSeeAccountsMessenger: Ember.computed('session.currentUser.identity.accountantToken', function () {
      return this.session.hasPrivilege('messaging') && this.session.get('currentUser.identity.accountantToken');
    })
  });

  _exports.default = _default;
});