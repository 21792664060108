define("zipbooks/utils/credit-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cardImageUrl;

  function cardImageUrl(brand, suffix) {
    var map = {
      visa: {
        nobg: '/assets/images/cc-logo-visa-nobg.png',
        bg: '/assets/images/cc-logo-visa-bg.png'
      },
      amex: {
        nobg: '/assets/images/cc-logo-amex-nobg.png',
        bg: '/assets/images/cc-logo-amex-bg.png'
      },
      discover: {
        nobg: '/assets/images/cc-logo-discover-nobg.png',
        bg: '/assets/images/cc-logo-discover-bg.png'
      },
      mastercard: {
        nobg: '/assets/images/cc-logo-mastercard-nobg.png',
        bg: '/assets/images/cc-logo-mastercard-bg.png'
      }
    };
    return map[brand] && map[brand][suffix];
  }
});