define("zipbooks/components/integrations/square-integration-preferences", ["exports", "zipbooks/mixins/paid-modal-handler"], function (_exports, _paidModalHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, {
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.integration.enableSync === null) {
        this.integration.set('enableSync', this.session.hasPrivilege('integrations'));
      }

      var maxDate = this.integration.syncedTo === null ? moment() : this.integration.syncedTo;
      this.set('maxDate', moment(maxDate).add(1, 'day').format('YYYY-MM-DD'));
      this.set('minDate', moment(this.integration.createdAt).subtract(728, 'days').format('YYYY-MM-DD'));

      if (this.integration.syncedTo === null) {
        this.integration.set('syncedTo', moment().subtract(30, 'days').format('YYYY-MM-DD'));
      }
    },
    actions: {
      disconnect: function disconnect() {
        var _this = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: 'Are you sure you want to disconnect Square. Your data will no longer sync.',
          confirmButton: {
            title: 'Disconnect',
            class: 'btn-red text-small-bd'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white text-small-bd'
          }
        }, {
          confirm: function confirm() {
            _this.client.POST('integrations/square/revoke').then(function () {
              return _this.session.fetchLoggedInUser();
            });

            _this.handleClose(0);
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      },
      selectSaleChartAccount: function selectSaleChartAccount(chartAccount) {
        this.set('integration.saleChartAccount', chartAccount);
      },
      selectFeeChartAccount: function selectFeeChartAccount(chartAccount) {
        this.set('integration.feeChartAccount', chartAccount);
      },
      selectLocation: function selectLocation(location) {
        this.set('integration.location', location);
      }
    }
  });

  _exports.default = _default;
});