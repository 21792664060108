define("zipbooks/routes/main/recurring-profile", ["exports", "zipbooks/mixins/line-itemable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _lineItemableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lineItemableRoute.default, _permissionRedirect.default, {
    queryParams: {
      invoice_id: {}
    },
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      // if it's new and there's an invoice template requested, pull the data
      // off of the invoice and apply it to the new recurring profile
      if (transition.to.queryParams.invoice_id) {
        var promise = this.store.find('invoice', transition.to.queryParams.invoice_id);
        promise.then(function (invoice) {
          return _this.set('invoiceTemplate', invoice);
        });
        return promise;
      }
    },
    model: function model(params) {
      var _this2 = this;

      var data; // if it's not a new recurring profile just fetch and return the model

      if (params.id !== 'new') {
        return this.store.findRecord('recurringProfile', params.id, {
          reload: true
        });
      } else if (this.invoiceTemplate) {
        var invoice = this.invoiceTemplate;
        data = invoice.getProperties('currencyCode', 'notes', 'terms', 'contact', 'acceptPaypal');
        data['lineItems'] = invoice.get('lineItems').map(function (lineItem) {
          return lineItem.getProperties('name', 'notes', 'quantity', 'rate', 'taxes', 'discount', 'type');
        }); // otherwise, put any other required data on the recurring profile (none for now)
      } else {
        data = {};
      } // since we have to wait for the potential template to download, we use the promise
      // so once that's done we can merge in any other default data that applies for either
      // a templated or non-templated recurring profile


      var hasProcessor = this.get('session.account.settings.activePaymentProcessor.length') > 0; // since line item models can't be created until the recurring profile model has been created,
      // we create the recurring profile with no line items and then add any line items
      // that might have been passed in from the invoice template

      var lineItems = data['lineItems'] || [];
      data['lineItems'] = [];
      data = Ember.assign(data, {
        discount: 0,
        account: this.get('session.account'),
        frequency: 'monthly',
        acceptCreditCards: hasProcessor,
        autobill: hasProcessor,
        logoCloudFile: this.store.createRecord('cloudFile', {
          downloadUrl: this.get('session.account.logoCloudFile.downloadUrl')
        }),
        // remove once this is fixed https://github.com/emberjs/data/issues/5100
        cloudFiles: [],
        lineItems: []
      });
      var model = this.store.createRecord('recurringProfile', data);
      lineItems.forEach(function (lineItem) {
        lineItem['lineItemable'] = model;
        lineItem = _this2.store.createRecord('lineItem', lineItem);
        model.get('lineItems').addObject(lineItem);
      });
      return model;
    },
    setupController: function setupController(_controller, model) {
      this._super.apply(this, arguments);

      if (model.get('isNew')) {
        if (!model.get('notes')) {
          model.set('notes', model.get('account.settings.defaultNotes'));
        }

        if (!model.get('terms')) {
          model.set('terms', model.get('account.settings.defaultTerms'));
        }
      }
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set('invoice_id', undefined);
    },
    afterModel: function afterModel(recurringProfile) {
      this._super.apply(this, arguments);

      if (recurringProfile.get('isNew')) {
        var acceptPaypal = !!this.get('session.account.settings.defaultAcceptPaypal');

        if (acceptPaypal) {
          recurringProfile.set('acceptPaypal', true);
        }

        recurringProfile.set('nextScheduledDate', moment().format('YYYY-MM-DD'));
      }
    }
  });

  _exports.default = _default;
});