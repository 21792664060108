define("zipbooks/components/draggable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isDragging:FG-dropzone-over', 'draggable:grabbable'],
    attributeBindings: ['draggable', 'testId:data-test-id'],
    mouseDown: function mouseDown(event) {
      if (this.isDraggable) {
        if (event.target.contentEditable === 'true') {
          this.set('draggable', false);
        } else {
          this.set('draggable', true);
        }
      } else {
        this.set('draggable', false);
      }
    },

    /******************
     * called on dragged
     *******************/
    dragStart: function dragStart()
    /*event*/
    {
      this.set('isDragging', true);
      this.group.start(this.content);
    },
    dragEnd: function dragEnd(event) {
      event.preventDefault();
      this.set('isDragging', false);
      this.group.stop(this.content);
    },

    /*********************
     * called on dragged on
     **********************/
    dragEnter: function dragEnter(event) {
      event.preventDefault();
      this.group.over(this.content);
    }
  });

  _exports.default = _default;
});