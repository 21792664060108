define("zipbooks/components/forms/cc-input", ["exports", "zipbooks/components/form-input", "zipbooks/helpers/format-cc", "zipbooks/utils/keycodes"], function (_exports, _formInput, _formatCc, _keycodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formInput.default.extend({
    layoutName: 'components/form-input',
    keyUp: function keyUp(event) {
      var current = this.value;

      if (event.which === _keycodes.default.BACKSPACE || event.which === _keycodes.default.DELETE) {
        return;
      }

      var newValue = (0, _formatCc.formatCc)([current]);
      this.$('input').val(newValue);
    },
    valueTransform: Ember.computed('value', 'money', {
      get: function get(_key) {
        return (0, _formatCc.formatCc)([this.value]);
      },
      set: function set(_key, value) {
        this.set('value', value.replace(/[^0-9]/g, ''));
        return value;
      }
    })
  });

  _exports.default = _default;
});