define("zipbooks/controllers/main/bills", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller"], function (_exports, _controllerPagination, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    queryParams: ['status', 'contact_id', 'archived'],
    status: 'all',
    contact_id: null,
    sort: 'due_date',
    archived: false,
    collection: Ember.computed('model.bills.@each', function () {
      return this.get('model.bills');
    }),
    actions: {
      filterByContact: function filterByContact(contact) {
        this.transitionToRoute({
          queryParams: {
            contact_id: contact && contact.get('id'),
            page: 1
          }
        });
      },
      logPayment: function logPayment(model) {
        var _this = this;

        var openDrawer = function openDrawer() {
          _this.set('isDrawerOpen', true);

          _this.overlay.showDrawer('drawers/bill-payment-form', model, {
            logPayment: function logPayment(paymentForm) {
              var paymentPromise = model.addPayment(paymentForm);
              paymentPromise.then(function () {
                model.reload();
              });
              return paymentPromise;
            },
            onClose: function onClose() {
              _this.set('isDrawerOpen', false);
            }
          });
        };

        if (this.editing) {
          this.save().then(function () {
            openDrawer();
          });
        } else {
          openDrawer();
        }
      }
    }
  });

  _exports.default = _default;
});