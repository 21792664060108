define("zipbooks/components/reports/time-tracking-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    timeEntryTimes: Ember.computed('entry.start_time', 'entry.duration', function () {
      if (this.get('entry.start_time')) {
        return {
          startTime: moment.utc(this.get('entry.start_time')).toDate(),
          endTime: moment.utc(this.get('entry.start_time')).add('seconds', this.get('entry.duration')).toDate()
        };
      }
    })
  });

  _exports.default = _default;
});