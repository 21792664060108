define("zipbooks/templates/components/drawers/model-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LKAT9k7P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"header\",true],[10,\"class\",\"row drawer-header\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto pl-0\"],[10,\"data-test-id\",\"drawer-title\"],[8],[0,\"\\n      \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col pr-0 text-right\"],[8],[0,\"\\n      \"],[15,[24,[\"rightHeaderPartial\"]],[]],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"drawer-header-close\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[7,\"i\",true],[10,\"class\",\"icon-remove-1\"],[10,\"data-test-id\",\"close\"],[8],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[15,[24,[\"partialName\"]],[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,\"partials/loading\",[]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/model-drawer.hbs"
    }
  });

  _exports.default = _default;
});