define("zipbooks/templates/components/suggest/choices/chart-account-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N+JEb0UH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"chart-account-color\",[[24,[\"choice\",\"classification\"]]],null],\" \",[28,\"unless\",[[24,[\"choice\",\"viewable\"]],\"gray-400\"],null]]]],[10,\"title\",\"Name\"],[8],[1,[28,\"search-highlight\",[[24,[\"choice\",\"name\"]],[24,[\"searchText\"]]],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"gray font-size-11px \",[28,\"unless\",[[24,[\"choice\",\"viewable\"]],\"gray-400\"],null]]]],[10,\"title\",\"Parent\"],[8],[1,[24,[\"choice\",\"parent\",\"name\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/suggest/choices/chart-account-select.hbs"
    }
  });

  _exports.default = _default;
});