define("zipbooks/routes/main/reports/accounts-receivable", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      tags: {
        refreshModel: true
      },
      include: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.tags = this.paramTags(params);
      this.set('params', params);
      return this.client.cached('GET', 'reports', "accounts-receivable?".concat($.param(params)));
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var queryParams = {
          include: params.include
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.location = '/v2/reports/accounts-receivable/export?' + $.param(queryParams);
      }
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      this._super.apply(this, arguments);

      if (!controller.get('include')) {
        controller.set('include', this.get('session.account.settings.reportsDefaultShowUnconfirmed') ? 'all' : 'confirmed');
      }
    }
  });

  _exports.default = _default;
});