define("zipbooks/components/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('startValue', this.start);
      this.set('endValue', this.end);
    },
    actions: {
      edit: function edit(edge) {
        var _this = this;

        this.set('selected', edge);
        Ember.run.later(this, function () {
          _this.$("#".concat(edge, "-picker input")).focus();
        }, 100);
      },
      startChanged: function startChanged() {
        var _this2 = this;

        if (this.ignoreFocusEvents) return;
        this.validateEnd();
        this.set('selected', 'end');
        Ember.run.later(this, function () {
          _this2.set('selected', 'end');

          Ember.run.later(_this2, function () {
            _this2.$("#end-picker input").focus();
          }, 100);
        }, 100);
      },
      doneEditing: function doneEditing() {
        var _this3 = this;

        if (this.ignoreFocusEvents) return;
        Ember.run.next(function () {
          _this3.set('selected', null);

          Ember.run.later(_this3, function () {
            if (!_this3.selected) {
              _this3.validateStart();

              _this3.set('end', _this3.endValue);

              _this3.set('start', _this3.startValue);

              if (_this3.onClose) {
                _this3.onClose();
              }
            }
          }, 200);
        });
      }
    },
    keyDown: function keyDown(event) {
      var _this4 = this;

      if (event.which === 9) {
        this.set('ignoreFocusEvents', true);
        Ember.run.later(this, function () {
          _this4.set('ignoreFocusEvents', false);
        }, 100);

        if (event.shiftKey && this.selected === 'end') {
          this.validateStart();
          this.set('selected', 'start');
          Ember.run.later(this, function () {
            _this4.send('edit', 'start');
          }, 100);
        } else if (!event.shiftKey && this.selected === 'start') {
          this.set('ignoreFocusEvents', true);
          this.validateEnd();
          this.set('selected', 'end');
          Ember.run.later(this, function () {
            _this4.send('edit', 'end');
          }, 100);
        } else {
          this.set('selected', null);
        }
      }

      return true;
    },
    validateStart: function validateStart() {
      if (moment(this.endValue).isBefore(moment(this.startValue))) {
        this.set('startValue', this.endValue);
      }
    },
    validateEnd: function validateEnd() {
      if (moment(this.startValue).isAfter(moment(this.endValue))) {
        this.set('endValue', this.startValue);
      }
    }
  });

  _exports.default = _default;
});