define("zipbooks/helpers/format-time-diff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTimeDiff = formatTimeDiff;
  _exports.default = void 0;

  function formatTimeDiff(params
  /*, hash*/
  ) {
    var dates = [];

    for (var i = 0; i < params.length; i++) {
      if (params[i] === 'now') {
        dates[i] = moment().format('Y-M-D');
      } else {
        dates[i] = params[i];
      }
    }

    return Math.floor((Date.parse(dates[1]) - Date.parse(dates[0])) / 86400000);
  }

  var _default = Ember.Helper.helper(formatTimeDiff);

  _exports.default = _default;
});