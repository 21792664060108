define("zipbooks/templates/components/expandable-each", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vWCRTNyo",
    "block": "{\"symbols\":[\"section\",\"index\",\"unit\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"sections\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[14,4,[[23,3,[]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[4,\"unless\",[[24,[\"expanded\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"shouldShowMore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\" font-size-12px blue\"],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"truncateMiddle\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],0],null]],null,{\"statements\":[[0,\"              ...show all...\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"            more...\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"shouldShowMore\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",\" font-size-12px blue\"],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"less\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/expandable-each.hbs"
    }
  });

  _exports.default = _default;
});