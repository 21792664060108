define("zipbooks/routes/main/reports/general-journal", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/infinite-scroll", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _infiniteScroll, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _infiniteScroll.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    nextPage: null,
    params: null,
    queryParams: {
      include: {
        refreshModel: true
      },
      view: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      this.set('params', this.getParams(params));
      this.set('finished', false);
      return this.store.cachedQuery('journalEntry', this.getParams(params)).then(function (results) {
        _this.set('nextPage', results.meta.links.next);

        return results.toArray();
      });
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var queryParams = {
          end_date: params.filter.end_date,
          start_date: params.filter.start_date,
          view: this.get('controller.view'),
          include: params.filter.confirmed === true ? 'confirmed' : 'all'
        };

        if (params.filter && params.filter.tags) {
          queryParams.tags = params.filter.tags;
        }

        window.location = '/v2/reports/general-journal/export?' + $.param(queryParams);
      }
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      this._super.apply(this, arguments);

      if (!controller.get('include')) {
        controller.set('include', this.get('session.account.settings.reportsDefaultShowUnconfirmed') ? 'all' : 'confirmed');
      }
    },
    getParams: function getParams(params) {
      var result = {
        page: {
          page: params.page || 1,
          'page-size': 100
        },
        filter: {
          start_date: params.start_date,
          end_date: params.end_date
        },
        sort: '-date'
      };

      if (params.include === 'confirmed') {
        result.filter.confirmed = true;
      } else if (params.include !== 'all' && !this.get('session.account.settings.reportsDefaultShowUnconfirmed')) {
        result.filter.confirmed = true;
      }

      if (params.tags) {
        result.filter.tags = this.paramTags(params);
      }

      return result;
    },
    infiniteLoadMore: function infiniteLoadMore() {
      var _this2 = this;

      if (this.nextPage) {
        this.set('controller.showLoader', true);
        var params = this.params;
        params.page.page++;
        this.set('params', params);
        return this.store.cachedQuery('journalEntry', params).then(function (results) {
          _this2.set('nextPage', results.meta.links.next);

          _this2.get('controller.model').pushObjects(results.toArray());

          _this2.set('controller.showLoader', false);

          Ember.run.later(_this2, function () {
            _this2.set('loading', false);
          }, 2000);
        });
      } else {
        this.set('finished', true);
      }
    }
  });

  _exports.default = _default;
});