define("zipbooks/components/drawers/import-contacts-form", ["exports", "zipbooks/components/drawers/base-drawer", "zipbooks/utils/file-list-to-array", "zipbooks/utils/parse-csv"], function (_exports, _baseDrawer, _fileListToArray, _parseCsv) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    classNames: ['drawer-large'],
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    title: 'Import Contacts',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('step', 1); // actual map needs to be a property on "mapping" that is passed around so the "map" property
      // can be observed by all rows when it changes

      this.set('mapping', Ember.Object.create({
        map: {}
      }));
      this.set('outputHeaders', [{
        value: 'company_name',
        title: 'Company name'
      }, {
        value: 'first_name',
        title: 'First name'
      }, {
        value: 'last_name',
        title: 'Last name'
      }, {
        value: 'email',
        title: 'Email (required)'
      }, {
        value: 'phone',
        title: 'Phone'
      }, {
        value: 'address_1',
        title: 'Address line 1'
      }, {
        value: 'address_2',
        title: 'Address line 2'
      }, {
        value: 'city',
        title: 'City'
      }, {
        value: 'state',
        title: 'State'
      }, {
        value: 'postal_code',
        title: 'Postal Code'
      }, {
        value: 'country',
        title: 'Country'
      }, {
        value: 'website',
        title: 'Website'
      }, {
        value: 'industry',
        title: 'Industry'
      }, {
        value: 'notes',
        title: 'Notes'
      }]);
    },
    actions: {
      selectedFiles: function selectedFiles(files) {
        var _this = this;

        if (files && (0, _fileListToArray.default)(files).length > 0) {
          var file = (0, _fileListToArray.default)(files)[0];

          if ((file.name || '').toLowerCase().split('.').pop() === 'csv') {
            this.set('file', file);
            var reader = new FileReader();
            reader.addEventListener('load', function () {
              var rows = (0, _parseCsv.default)(reader.result);

              if (rows.length > 0) {
                _this.set('fileName', file.name);

                _this.set('inputHeaders', rows.firstObject);

                _this.set('step', 2);
              }
            });
            reader.readAsText(file);
          } else {
            this.overlay.showBanner('error', 'File must be a CSV.', {
              title: 'Whoops!'
            });
          }
        } else {
          this.overlay.showBanner('error', 'You must select a file.', {
            title: 'Oops!'
          });
        }
      },
      upload: function upload() {
        var self = this;
        this.set('buttonState', 'saving'); // debugger

        var mappingParams = $.param({
          header_map: this.mapping.map
        });
        this.client.upload('contacts', "import?".concat(mappingParams), this.file, {
          success: function success()
          /*result*/
          {
            self.set('buttonState', 'loaded');
            self.notificationCenter.notify('contact.save');
            Ember.tryInvoke(self.callbacks, 'uploaded', []);
            self.send('close', 715);
          },
          failed: function failed(status, json) {
            self.set('buttonState', null);

            if (status === 422 && json.errors) {
              var lineErrors = json.errors.map(function (error) {
                return error.title;
              });
              var errorHtml = Ember.String.htmlSafe(lineErrors.map(function (s) {
                return s.stripHTML();
              }).join('<br>'));
              self.overlay.showBanner('error', errorHtml, {
                title: 'Whoops!'
              });
            } else {
              self.overlay.showBanner('error', 'Something went wrong. Contact support.', {
                title: 'Whoops!'
              });
            }
          },
          error: function error(_error) {
            self.set('buttonState', null);
            self.overlay.showBanner('error', _error, {
              title: 'Whoops!'
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});