define("zipbooks/serializers/estimate", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/embedded-jsonapi-records", "zipbooks/mixins/patch-serializer"], function (_exports, _application, _embeddedJsonapiRecords, _patchSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_patchSerializer.default, _embeddedJsonapiRecords.default, {
    attrs: {
      lineItems: {
        serialize: 'records'
      },
      logoCloudFile: {
        serialize: 'records'
      },
      notifications: {
        serialize: false
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, _json, relationship) {
      if (relationship.key === 'logoCloudFile' && !snapshot.record.get(relationship.key + '.token')) {
        return;
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});