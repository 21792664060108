define("zipbooks/helpers/dec-num", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decNum = decNum;
  _exports.default = void 0;

  function decNum(params
  /*, hash*/
  ) {
    return +params[0].toFixed(2);
  }

  var _default = Ember.Helper.helper(decNum);

  _exports.default = _default;
});