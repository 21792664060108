define("zipbooks/components/quick-stats/imported-transactions-count", ["exports", "zipbooks/mixins/testable", "ember-data"], function (_exports, _testable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    client: Ember.inject.service(),
    counts: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: this.client.cached('GET', 'quick-stats/imported-transactions-count')
      });
    }),
    actions: {
      viewTransactions: function viewTransactions(month) {
        var startDate = moment(month).startOf('month');
        var endDate = moment(month).endOf('month');

        if (this.permissions.can('accounting.read')) {
          this.router.transitionTo('main.transactions', {
            queryParams: {
              chart_account_id: 'all-banks',
              end_date: endDate.format('YYYY-MM-DD'),
              start_date: startDate.format('YYYY-MM-DD'),
              confirmed: ''
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});