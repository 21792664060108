define("zipbooks/models/grouped-time-entry", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/utils/format-seconds-as-hours"], function (_exports, _model, _model2, _formatSecondsAsHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    timeEntries: (0, _model.hasMany)('timeEntry'),
    hours: Ember.computed('duration', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 'h');
    }),
    minutes: Ember.computed('duration', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 'm');
    }),
    date: Ember.computed('timeEntries.@each.date', function () {
      return this.timeEntries.firstObject.date;
    }),
    duration: Ember.computed('timeEntries.@each.duration', function () {
      return this.timeEntries.reduce(function (acc, te) {
        return acc + te.duration;
      }, 0);
    })
  });

  _exports.default = _default;
});