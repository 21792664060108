define("zipbooks/components/dashboard/tracked-time", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/chart-custom-tooltip"], function (_exports, _testable, _chartCustomTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _chartCustomTooltip.default, {
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var ctx = this.$('canvas') && this.$('canvas')[0];

      if (!ctx) {
        return;
      }

      var data = this.graphData;
      new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [{
              display: false,
              ticks: {
                fontFamily: 'Karla, sans-serif',
                maxTicksLimit: 2
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              ticks: {
                padding: 10,
                fontFamily: 'Karla, sans-serif',
                beginAtZero: true,
                maxTicksLimit: 5
              },
              gridlines: {
                drawBorder: false,
                color: '#dee2e6'
              }
            }]
          },
          legend: {
            display: false
          },
          tooltips: $.extend(true, {
            position: 'nearest',
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function label(tooltipItem, _data) {
                return {
                  user: data.datasets[0].data[tooltipItem.index],
                  account: data.datasets.length > 1 && data.datasets[1].data[tooltipItem.index],
                  date: data.labels[tooltipItem.index]
                };
              }
            }
          }, this.customTooltipOpts(this))
        }
      });
    },
    anyTimeTracked: Ember.computed(function () {
      var accountEntries = this.get('data.account');
      return accountEntries && Object.keys(accountEntries).any(function (key) {
        if (accountEntries[key] !== '0') {
          return true;
        }
      });
    }),
    graphData: Ember.computed(function () {
      var _this = this;

      var graphData = {
        labels: [],
        datasets: [{
          label: 'You',
          data: [],
          backgroundColor: '#0064FF',
          borderColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 0
        }]
      };

      if (this.permissions.can('users.read')) {
        graphData.datasets.push({
          label: 'Team',
          data: [],
          backgroundColor: '#945CE8',
          borderColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 0
        });
      }

      if (this.get('data.user')) {
        var orderedDates = [];
        Object.keys(this.get('data.user')).forEach(function (dateKey
        /*, timeLog*/
        ) {
          orderedDates.push(dateKey);
        });
        orderedDates.sort();
        orderedDates.forEach(function (date) {
          var formattedDate = moment(date).format('MMM D');
          graphData.labels.push(formattedDate);

          var userTimeLogs = _this.get('data.user');

          graphData.datasets[0].data.push(userTimeLogs[date] / 3600);

          if (_this.permissions.can('users.read')) {
            var accountTimeLogs = _this.get('data.account');

            graphData.datasets[1].data.push(accountTimeLogs[date] / 3600);
          }
        });
      }

      return graphData;
    }),
    chartWasClicked: function chartWasClicked(router, context) {
      var date = moment(context.date, 'MMM DD').format('YYYY-MM-DD');
      router.transitionTo('main.time-entries', {
        queryParams: {
          start_date: date,
          end_date: date
        }
      });
    }
  });

  _exports.default = _default;
});