define("zipbooks/templates/components/quick-stats/client-counts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2S/hLnYe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"box-header\"],[8],[0,\"License counts\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"stats\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      \"],[1,[28,\"small-loader\",null,[[\"class\"],[\"my-6\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"stats\",\"isRejected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      Failed to load counts.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body border-bottom-1\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray\"],[8],[0,\"All\"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"font-weight-bold font-size-18px\"],[8],[1,[24,[\"stats\",\"all_active\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray\"],[8],[0,\"Firm-paid\"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"font-weight-bold font-size-18px\"],[8],[1,[24,[\"stats\",\"licensed\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray\"],[8],[0,\"Client-paid\"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"font-weight-bold font-size-18px\"],[8],[1,[24,[\"stats\",\"client_paid\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/quick-stats/client-counts.hbs"
    }
  });

  _exports.default = _default;
});