define("zipbooks/components/lazy-relationship-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    proxyValue: Ember.computed('relationship', 'attribute', 'model', 'invalidate', {
      get: function get(_key) {
        return this.get("model.".concat(this.relationship, ".").concat(this.attribute));
      },
      set: function set(_key, value) {
        if (Ember.isBlank(value) && !Ember.isBlank(this.get("model.".concat(this.relationship, ".id")))) {
          this.set("model.".concat(this.relationship), null);
        } else if (!Ember.isBlank(value) && Ember.isBlank(this.get("model.".concat(this.relationship, ".id")))) {
          var attributes = {};
          attributes[this.attribute] = value;
          var created = this.store.createRecord(this.type, attributes);
          this.set("model.".concat(this.relationship), created);
        } else if (!Ember.isBlank(value) && !Ember.isBlank(this.get("model.".concat(this.relationship, ".id")))) {
          this.set("model.".concat(this.relationship, ".").concat(this.attribute), value);
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});