define("zipbooks/components/suggest/label-kind-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/search-in"], function (_exports, _autoSuggest, _searchIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Item',
    allowArbitrary: true,
    displayArbitrary: true,
    layoutName: 'components/auto-suggest',
    throttle: 0,
    client: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.client.send('GET', 'label-kinds').then(function (response) {
        var objects = [{
          name: 'Contact',
          id: 'Contact'
        }, {
          name: 'Location',
          id: 'Location'
        }];
        var others = response.data.map(function (kind) {
          return {
            name: kind,
            id: kind
          };
        });

        _this.set('kinds', objects.concat(others));
      });
    },
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      return (0, _searchIn.default)(this.getWithDefault('kinds', []), 'name', search);
    },
    labelForResult: function labelForResult(result) {
      return result.name;
    }
  });

  _exports.default = _default;
});