define("zipbooks/controllers/main/dashboard", ["exports", "zipbooks/mixins/onboarding"], function (_exports, _onboarding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_onboarding.default, {
    session: Ember.inject.service(),
    showProgressOnboarding: Ember.computed('isCompleted', 'session.account.createdAt', function () {
      if (!this.permissions.can('account.read')) {
        return false;
      }

      var newAccount = moment(this.get('session.account.createdAt')).isAfter(moment().subtract(1, 'month'));

      if (newAccount) {
        return !this.isComplete;
      } else {
        return false;
      }
    }),
    showFinancialPerformance: Ember.computed('model.monthly_summaries', function () {
      return this.permissions.can('accounting.read');
    }),
    // won't show if backend returns empty (if no permissions/privileges)
    showAccountsReceivable: Ember.computed('model.accounts_receivable', function () {
      return this.get('model.accounts_receivable').any(function (r) {
        return r.total * 1 > 0;
      }) && this.get('model.accounts_receivable').any(function (c) {
        return c.customer_name !== 'Unassigned';
      });
    }),
    // won't show if backend returns empty (if no permissions/privileges)
    showRevenue: Ember.computed('model.revenue', function () {
      return this.get('model.revenue').any(function (r) {
        return r.total * 1 > 0;
      });
    }),
    showExpenses: Ember.computed(function () {
      return this.permissions.can('accounting.read');
    }),
    showSalesByCustomer: Ember.computed(function () {
      return this.permissions.can('accounting.read') && this.getWithDefault('model.sales_by_customer', []).filter(function (c) {
        return c.total * 1 > 0;
      }).length > 2;
    }),
    showTrackedTime: Ember.computed(function () {
      return this.permissions.can('time_tracking.read');
    })
  });

  _exports.default = _default;
});