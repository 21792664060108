define("zipbooks/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.condense = condense;
  _exports.splitBy = splitBy;
  _exports.groupBy = groupBy;
  _exports.median = median;
  _exports.quartiles = quartiles;
  _exports.average = average;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function condense(array, targetCount) {
    var selected = [];

    for (var i = 0; i < targetCount; i++) {
      var percent = i / targetCount;
      var idx = Math.round(percent * array.length);
      var obj = array[idx];
      selected.push(obj);
    }

    return selected;
  }

  function splitBy(array, func) {
    return array.reduce(function (acc, v) {
      if (func(v)) {
        return [acc[0].concat(v), acc[1]];
      } else {
        return [acc[0], acc[1].concat(v)];
      }
    }, [[], []]);
  }

  function groupBy(xs, keyFunc, initialValue, reduceFunc) {
    if (!reduceFunc) {
      reduceFunc = function reduceFunc(a, v) {
        return a.concat(v);
      };

      initialValue = [];
    }

    return xs.reduce(function (acc, x) {
      var key = keyFunc(x);
      var value = acc[key] || JSON.parse(JSON.stringify(initialValue));
      acc[key] = reduceFunc(value, x);
      return acc;
    }, {});
  }

  function median(array) {
    array.sort(function (a, b) {
      return a - b;
    });

    if (array.length === 0) {
      return 0;
    }

    var half = Math.floor(array.length / 2.0);

    if (array.length % 2) {
      return array[half];
    } else {
      return (array[half - 1] + array[half]) / 2.0;
    }
  }

  function quartiles(array) {
    var med = median(array);

    var _array$reduce = array.reduce(function (acc, v) {
      if (v === med) {
        return [acc[0].concat(v), acc[1].concat(v)];
      } else if (v > med) {
        return [acc[0], acc[1].concat(v)];
      } else {
        return [acc[0].concat(v), acc[1]];
      }
    }, [[], []]),
        _array$reduce2 = _slicedToArray(_array$reduce, 2),
        lower = _array$reduce2[0],
        upper = _array$reduce2[1];

    return {
      lower: median(lower),
      median: med,
      upper: median(upper)
    };
  }

  function average(array) {
    if (array.length === 0) return 0;
    return array.reduce(function (acc, v) {
      return acc + v;
    }) / array.length;
  }
});