define("zipbooks/utils/search-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = searchIn;

  function searchIn(array, labelKey, typed) {
    // nothing matches all
    if (!typed || typed.length === 0) {
      return array;
    }

    var options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      tokenize: true,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: Ember.isArray(labelKey) ? labelKey : [labelKey],
      getFn: function getFn(item, key) {
        return item.get ? item.get(key) : item[key] || item;
      }
    };
    var fuse = new Fuse(array.toArray(), options);
    var results = fuse.search(typed);
    return results;
  }
});