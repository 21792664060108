define("zipbooks/controllers/main/reports/accounts-receivable", ["exports", "zipbooks/mixins/tag-filterable-controller"], function (_exports, _tagFilterableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tagFilterableController.default, {
    queryParams: ['include']
  });

  _exports.default = _default;
});