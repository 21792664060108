define("zipbooks/overrides/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = _emberInflector.default.inflector;
  inflector.uncountable('account-settings');
  inflector.uncountable('location-settings');
  inflector.uncountable('user-settings');
  var _default = {};
  _exports.default = _default;
});