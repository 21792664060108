define("zipbooks/routes/main/reports/client-report", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      include: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      },
      period: {
        refreshModel: true
      },
      contact_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (moment(params.start_date).isAfter(moment(params.end_date))) {
        params.start_date = params.end_date;
      }

      this.set('params', params);
      var models = {
        contacts: this.client.send('GET', 'reports', "client-report?".concat($.param(params)))
      };

      if (params.contact_id) {
        models.contact = this.store.findRecord('contact', params.contact_id);
      }

      return Ember.RSVP.hash(models);
    },
    actions: {
      export: function _export() {
        var params = this.params;
        params.token = this.get('session.token');
        window.location = '/v2/reports/client-report/export?' + $.param(params);
      }
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      this._super.apply(this, arguments);

      if (!controller.get('include')) {
        controller.set('include', this.get('session.account.settings.reportsDefaultShowUnconfirmed') ? 'all' : 'confirmed');
      }
    }
  });

  _exports.default = _default;
});