define("zipbooks/mixins/index-controller", ["exports", "zipbooks/mixins/archive-actionable", "zipbooks/mixins/bulk-operation", "zipbooks/mixins/list-controller", "zipbooks/mixins/list-controller-selectable", "zipbooks/utils/string"], function (_exports, _archiveActionable, _bulkOperation, _listController, _listControllerSelectable, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_listController.default, _listControllerSelectable.default, _archiveActionable.default, _bulkOperation.default, {
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    queryParams: ['filter', 'sort', 'page', 'direction', 'archived'],
    page: 1,
    filter: 'active',
    archived: null,
    sort: null,
    direction: null,
    nextPage: Ember.computed('page', function () {
      return this.page + 1;
    }),
    prevPage: Ember.computed('page', function () {
      return this.page - 1;
    }),
    actions: {
      bulkDelete: function bulkDelete() {
        var _this = this;

        var ids = this.selected.mapBy('id');

        if (ids.length > 0) {
          var modelType = this.modelType;
          this.overlay.showDialog('overlays/dialogs/destroy-confirm', (0, _string.default)(modelType, 'destroy', 'title'), {
            type: modelType,
            count: ids.length
          }, {
            destroy: function destroy() {
              _this.bulk(ids.map(function (id) {
                return _this.store.peekRecord(modelType, id);
              }), function (model) {
                return model.destroyRecord();
              }, function (result) {
                result.succeeded.forEach(function (m) {
                  return m.unloadRecord();
                });
                result.failed.forEach(function (m) {
                  return m.rollbackAttributes();
                });

                _this.refresh();

                _this.unselectAll();

                Ember.tryInvoke(_this, 'didDestroy', result.succeeded);

                _this.showBulkBanner('deleted', result.succeeded.length, result.unchanged.length, result.failed.length, modelType);
              });
            }
          });
        }
      },
      destroy: function destroy(model) {
        var _this2 = this;

        if (!model) {
          return;
        }

        var modelType = model.get('modelType');
        this.overlay.showDialog('overlays/dialogs/destroy-confirm', (0, _string.default)(modelType, 'destroy', 'title'), {
          type: modelType
        }, {
          destroy: function destroy() {
            _this2.urlCache.clear();

            return model.destroyRecord().then(function () {
              Ember.tryInvoke(_this2, 'didDestroy', [model]);
              model.unloadRecord();
            }, function (error) {
              model.rollbackAttributes();

              if (error.errors && error.errors.length > 0) {
                _this2.overlay.showBanner('error', error.errors[0].detail);
              }
            });
          }
        });
      },
      save: function save(model) {
        return model.save();
      }
    },
    idsKey: function idsKey(modelType) {
      return (modelType === 'contact' ? 'customer' : modelType) + '_ids';
    },
    showSuccessMessage: function showSuccessMessage(modelType, action, count) {
      var message = (0, _string.default)(modelType, 'success', 'body', {
        count: count,
        humanize: true,
        action: action
      });
      this.overlay.showBanner('success', message);
    },
    showFailureMessage: function showFailureMessage(modelType, action, count) {
      var message = (0, _string.default)(modelType, 'failure', 'body', {
        count: count,
        humanize: true,
        action: action
      });
      this.overlay.showBanner('error', message);
    },
    refresh: function refresh() {
      var _this3 = this;

      this.collection.update().then(function (results) {
        // unselect everything
        var modelType = _this3.modelType;

        _this3.store.peekAll(modelType).forEach(function (m) {
          return m.set('isSelected', false);
        });

        if (results.get('length') === 0) {
          _this3.decrementProperty('page');
        }
      });
    }
  });

  _exports.default = _default;
});