define("zipbooks/components/custom-je-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hideAmount: Ember.computed('otherLines.length', function () {
      return this.get('otherLines.length') < 2;
    }),
    actions: {
      selectChartAccount: function selectChartAccount(chartAccount) {
        this.set('line.chartAccount', chartAccount);
      },
      edit: function edit(event) {
        this.edit(this.line, event);
      },
      remove: function remove() {
        this.remove(this.lind);
      }
    }
  });

  _exports.default = _default;
});