define("zipbooks/components/time/duration-formatter-report", ["exports", "zipbooks/utils/format-seconds-as-hours"], function (_exports, _formatSecondsAsHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hours: Ember.computed('duration', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 'h');
    }),
    minutes: Ember.computed('duration', 'showSeconds', function () {
      if (this.get('showSeconds')) {
        return 0;
      } else {
        return (0, _formatSecondsAsHours.default)(this.duration || 0, 'm');
      }
    }),
    clock: Ember.computed('clock', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 'h:m');
    }),
    decimal: Ember.computed('duration', function () {
      var value = (0, _formatSecondsAsHours.default)(this.duration || 0, 'd').toString();
      var decimals = value.split('.')[1];

      if (!decimals || decimals.length > 2) {
        value = (+value).toFixed(2);
      }

      return value;
    })
  });

  _exports.default = _default;
});