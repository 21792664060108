define("zipbooks/mixins/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    hasCompanyName: Ember.computed.notEmpty('session.account.name'),
    hasLogo: Ember.computed.notEmpty('session.account.logoCloudFile.downloadUrl'),
    hasUserInfo: Ember.computed('session.currentUser.identity.{firstName,lastName,email}', function () {
      var _this = this;

      return ['firstName', 'lastName', 'email'].every(function (k) {
        return Ember.isPresent(_this.get('session.currentUser.identity.' + k));
      });
    }),
    hasAddressAndPhone: Ember.computed('session.account.primaryLocation.{phone,address_1,city}', function () {
      var _this2 = this;

      return ['phone', 'address_1', 'city'].every(function (k) {
        return Ember.isPresent(_this2.get('session.account.primaryLocation.' + k));
      });
    }),
    countCompleted: Ember.computed('hasCompanyName', 'hasAddressAndPhone', 'hasLogo', 'hasConnectedBank', 'hasSetupPayments', 'hasUserInfo', function () {
      var _this3 = this;

      return ['hasCompanyName', 'hasAddressAndPhone', 'hasLogo', 'hasConnectedBank', 'hasSetupPayments', 'hasUserInfo'].map(function (k) {
        return _this3.get(k);
      }).filter(function (v) {
        return !!v;
      }).length;
    }),
    total: 6,
    percentCompleted: Ember.computed('countCompleted', function () {
      return Math.round(this.countCompleted / this.total * 100.0);
    }),
    isComplete: Ember.computed('percentCompleted', function () {
      return this.percentCompleted === 100;
    }),
    hasSetupPayments: Ember.computed('model', function () {
      var activePaymentProcessor = this.get('session.currentUser.account.settings.activePaymentProcessor');

      if (Ember.isPresent(activePaymentProcessor)) {
        return true;
      }
    }),
    hasConnectedBank: Ember.computed('session.chartAccounts.@each', function () {
      return this.get('session.chartAccounts').filter(function (ca) {
        return ca.get('bankAccount.id');
      }).length > 0;
    }),
    hasAddedCompanyDetails: Ember.computed('hasCompanyName', 'hasCompanyName', function () {
      return this.hasCompanyName && this.hasLogo;
    })
  });

  _exports.default = _default;
});