define("zipbooks/serializers/page", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/embedded-jsonapi-records", "zipbooks/mixins/patch-serializer"], function (_exports, _application, _embeddedJsonapiRecords, _patchSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _application.default.extend(_patchSerializer.default, _embeddedJsonapiRecords.default, {
    attrs: {
      cloudFiles: {
        serialize: 'records'
      }
    },
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      if (payload && _typeof(payload) === 'object' && payload.errors) {
        payload.errors.forEach(function (e) {
          return e.detail = e.detail.replace('slug', 'link');
        });
      }

      return this._super(store, typeClass, payload, id);
    }
  });

  _exports.default = _default;
});