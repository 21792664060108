define("zipbooks/components/quick-stats/account", ["exports", "zipbooks/mixins/testable", "ember-data", "zipbooks/mixins/onboarding"], function (_exports, _testable, _emberData, _onboarding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _onboarding.default, {
    client: Ember.inject.service(),
    stats: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.client.cached('GET', "quick-stats/account")
      });
    })
  });

  _exports.default = _default;
});