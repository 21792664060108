define("zipbooks/routes/main/estimate", ["exports", "zipbooks/mixins/line-itemable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _lineItemableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lineItemableRoute.default, _permissionRedirect.default, {
    session: Ember.inject.service(),
    model: function model(params) {
      if (params.id === 'new') {
        return this.store.createRecord('estimate', {
          account: this.get('session.account'),
          discount: 0,
          logoCloudFile: this.store.createRecord('cloudFile', {
            downloadUrl: this.get('session.account.logoCloudFile.downloadUrl')
          }),
          // remove once this is fixed https://github.com/emberjs/data/issues/5100
          cloudFiles: [],
          lineItems: []
        });
      } else {
        return this.store.findRecord('estimate', params.id, {
          reload: true
        });
      }
    },
    setupController: function setupController(_controller, model) {
      this._super.apply(this, arguments);

      if (model.get('isNew')) {
        model.set('notes', model.get('account.settings.estimateDefaultNotes'));
        model.set('terms', model.get('account.settings.estimateDefaultTerms'));
      }
    }
  });

  _exports.default = _default;
});