define("zipbooks/components/overlays/pop-out", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    overlay: Ember.inject.service(),
    classNames: ['pop-out'],
    inClass: 'popIn',
    outClass: 'popOut',
    outAttachment: 'prepend',
    actions: {
      close: function close() {
        this.overlay.close(this.ref);

        if (this.get('callbacks.close')) {
          this.get('callbacks.close')();
        }
      }
    }
  });

  _exports.default = _default;
});