define("zipbooks/components/drawers/user-form", ["exports", "zipbooks/components/drawers/model-drawer", "ember-data", "zipbooks/utils/push-payload"], function (_exports, _modelDrawer, _emberData, _pushPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _modelDrawer.default.extend({
    classNames: ['overlay'],
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('changes', {});
      this.set('clientPage', 1);
    },
    title: Ember.computed('model', function () {
      if (this.get('model.isNew')) {
        return 'Add Team Member';
      } else {
        return 'Edit Team Member';
      }
    }),
    isMe: Ember.computed('model', function () {
      return this.get('model.id') === this.get('session.currentUser.id');
    }),
    identity: Ember.computed(function () {
      return this.store.createRecord('identity', {});
    }),
    clients: Ember.computed('clientSearch', 'clientPage', function () {
      var _this = this;

      var params = {
        kind: 'client',
        include: 'linked_account.logo_cloud_file',
        page: {
          page: this.clientPage,
          'page-size': 100
        },
        filter: {
          status: 'active-licenses'
        }
      };

      if (this.clientSearch) {
        params.filter.name = this.clientSearch;
      }

      return _emberData.default.PromiseObject.create({
        promise: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.client.GET("contacts?".concat($.param(params))).then(function (clients) {
            _this.set('clientMeta', clients.meta);

            var clientRecords = (0, _pushPayload.default)(_this.store, 'contact', clients);

            var p = _objectSpread({}, params, {
              page: {
                page: 1,
                'page-size': 100
              },
              filter: _objectSpread({}, params.filter, {
                identity_id: _this.get('model.identity.id'),
                ids: clientRecords.mapBy('id')
              })
            });

            _this.client.GET("contacts?".concat($.param(p))).then(function (assigned) {
              _this.set('assignedMeta', assigned.meta);

              var assignedRecords = (0, _pushPayload.default)(_this.store, 'contact', assigned);
              resolve({
                all: clientRecords,
                assigned: assignedRecords
              });
            }, function () {
              reject([]);
            });
          }, function () {
            reject([]);
          });
        })
      });
    }),
    filteredClients: Ember.computed('clients.all.@each', function () {
      var _this2 = this;

      var clients = this.getWithDefault('clients.all', []);
      var assigned = this.getWithDefault('clients.assigned', []).mapBy('id');
      clients.forEach(function (c) {
        return c.setProperties({
          selected: _this2.changes[c.id] !== undefined ? !!_this2.changes[c.id] : assigned.includes(c.id),
          originalSelected: assigned.includes(c.id)
        });
      });
      return clients;
    }),
    teamUsers: Ember.computed('model.identity.users.@each', function () {
      return this.get('model.identity.users').filter(function (u) {
        return u.isTeamMember;
      });
    }),
    showDeselectAll: Ember.computed('filteredClients.@each.selected', function () {
      return this.filteredClients.every(function (c) {
        return !!c.selected;
      });
    }),
    actions: {
      save: function save() {
        var _this3 = this;

        var isNew = this.get('model.isNew');
        this.set('model.identity', this.identity);
        return this._super.apply(this, arguments).then(function (user) {
          if (user) {
            return _this3.saveIdentity(user.get('identity.id'), user.get('account.id')).then(function () {
              if (isNew) {
                _this3.overlay.showBanner('success', 'Invite sent successfully!');
              }
            });
          }
        });
      },
      updateSearch: function updateSearch(searchString) {
        this.set('clientSearch', searchString);
      },
      toggleClient: function toggleClient(client) {
        client.toggleProperty('selected');
        this.changes[client.id] = {
          client: client,
          selected: client.selected
        };
      },
      selectAll: function selectAll() {
        var _this4 = this;

        this.get('filteredClients').forEach(function (client) {
          client.set('selected', true);
          _this4.changes[client.id] = {
            client: client,
            selected: true
          };
        });
      },
      deselectAll: function deselectAll() {
        var _this5 = this;

        this.get('filteredClients').forEach(function (client) {
          client.set('selected', false);
          _this5.changes[client.id] = {
            client: client,
            selected: false
          };
        });
      },
      nextPage: function nextPage() {
        this.set('clientPage', this.clientPage + 1);
      },
      prevPage: function prevPage() {
        this.set('clientPage', this.clientPage - 1);
      }
    },
    saveIdentity: function saveIdentity(identityId, teamAccountId) {
      var _this6 = this;

      if (this.get('session.subscription.plan.kind') === 'cpa') {
        var users = Object.keys(this.changes).map(function (id) {
          var change = _this6.changes[id];

          if (change.client.selected != change.client.originalSelected) {
            return {
              type: 'user',
              attributes: {
                delete: !change.selected
              },
              relationships: {
                account: {
                  data: {
                    id: change.client.linkedAccount.get('id'),
                    type: 'account'
                  }
                },
                team_account: {
                  data: {
                    id: teamAccountId,
                    type: 'account'
                  }
                }
              }
            };
          }
        }).compact();

        if (users.length > 0) {
          return this.client.PATCH("identities/".concat(identityId), null, {
            data: {
              id: identityId,
              type: 'identity',
              relationships: {
                users: {
                  data: users
                }
              }
            }
          });
        } else {
          return Ember.RSVP.resolve(identityId);
        }
      } else {
        return Ember.RSVP.resolve(identityId);
      }
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.get('isNew')) {
        model.set('permissionsMap', {
          account: 9,
          invoices: 9,
          users: 9,
          contacts: 9,
          projects: 9,
          accounting: 9,
          reports: 9,
          time_tracking: 9,
          estimates: 9,
          payroll: 9,
          banks: 9
        });
      }
    },
    handleError: function handleError(error) {
      if (error.errors) {
        this.overlay.showBanner('error', error.errors.mapBy('detail').join('<br>'));
      }
    },
    showPermissions: Ember.computed('model.id', 'session.currentUser', 'model.accountant', function () {
      if (this.get('model.id') === this.get('session.currentUser.id')) {
        return false;
      } else if (this.getWithDefault('session.currentUser.permissionsMap.users') < 9) {
        return false;
      } else if (this.model.accountant) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});