define("zipbooks/templates/components/overlays/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bQ7empZ1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-backdrop modalFadeIn\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"cancelDialog\"],null]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-dialog modalSlideInDown\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"model\",\"model\",\"customHeader\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n          \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[1,[24,[\"model\",\"title\"]],false],[9],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancelDialog\"]],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[7,\"i\",true],[10,\"class\",\"icon-remove-1\"],[8],[9],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"component\",[[24,[\"model\",\"component\"]]],[[\"model\",\"container\",\"callbacks\",\"close\"],[[24,[\"model\",\"model\"]],[24,[\"model\"]],[24,[\"callbacks\"]],[28,\"action\",[[23,0,[]],\"closeDialog\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/overlays/modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});