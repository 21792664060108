define("zipbooks/components/drawers/import-categories-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    large: true,
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    title: 'Import Categories',
    actions: {
      upload: function upload() {
        var field = this.$('input[type="file"]')[0];
        var file = field.files.length > 0 ? field.files[0] : (field.testingfiles || [])[0];

        if (file) {
          if ((file.name || '').toLowerCase().split('.').pop() === 'csv') {
            var self = this;
            this.set('buttonState', 'saving');
            this.client.upload('chart-accounts', 'import', file, {
              success: function success() {
                self.set('buttonState', 'loaded');
                self.get('overlay').showBanner('success', 'Your categories have been imported!');
                self.get('notificationCenter').notify('journal-entry.save');
                self.get('close')(715);
              },
              failed: function failed() {
                self.get('overlay').showBanner('error', 'Something went wrong. Contact support.', {
                  title: 'Whoops!'
                });
              },
              error: function error(_error) {
                self.set('buttonState', null);
                self.get('overlay').showBanner('error', _error, {
                  title: 'Whoops!'
                });
              }
            });
          } else {
            this.overlay.showBanner('error', "File must be a .csv} file.", {
              title: 'Whoops!'
            });
          }
        } else {
          this.overlay.showBanner('error', 'You must select a file.', {
            title: 'Oops!'
          });
        }
      }
    }
  });

  _exports.default = _default;
});