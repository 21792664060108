define("zipbooks/routes/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.Object.create({
        token: params.token,
        password: null,
        passwordConfirmation: null
      });
    }
  });

  _exports.default = _default;
});