define("zipbooks/overrides/promise-object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.PromiseObject.reopen({
    thenIfExists: function thenIfExists(fn) {
      this.then(function (model) {
        if (model && !model.isDestroyed && !model.isDestroying) {
          fn(model);
        }
      });
    }
  });

  _exports.default = _default;
});