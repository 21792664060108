define("zipbooks/helpers/amount-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    onUpdate: Ember.observer('line.kind', 'line.amount', 'line.chartAccount', 'primaryLine.amount', 'primaryLine.chartAccount', function () {
      this.recompute();
    }),
    compute: function compute(params) {
      var line = params[0];
      var primaryLine = params[1];
      this.set('line', line);
      this.set('primaryLine', primaryLine);
      return line.isRed(primaryLine) ? 'red-amount' : 'green-amount';
    }
  });

  _exports.default = _default;
});