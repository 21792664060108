define("zipbooks/utils/to-decimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = toDecimal;

  function toDecimal(value) {
    value = '' + value;
    var valid = value.match(/^([-+]*)([0-9]+|\.[0-9]+|[0-9]+\.[0-9]+)$/g);
    return valid ? new Decimal(value) : new Decimal(0);
  }
});