define("zipbooks/templates/components/drawers/-recurring-journal-entry-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i/N0RR/a",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[\"drawers/transaction/recurring-mode\"],[[\"entry\",\"tagName\",\"save\",\"recurring\",\"shared\"],[[24,[\"model\"]],\"\",[28,\"action\",[[23,0,[]],\"save\"],null],true,[24,[\"shared\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-recurring-journal-entry-form.hbs"
    }
  });

  _exports.default = _default;
});