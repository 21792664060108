define("zipbooks/components/reconciliations/months-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reconciliation-months'],
    periods: Ember.computed('chartAccount.firstPeriodDate', 'chartAccount.reconciliations.@each.isReconciled', function () {
      var _this = this;

      var recEndDay = this.get('chartAccount.reconcileEndDay');
      var oneYearAgo = moment().subtract(11, 'months').startOf('month');
      var periods = [];
      var firstDate = moment(this.get('chartAccount.firstPeriodDate'));
      var end, days, month, year;
      var date = firstDate > oneYearAgo ? oneYearAgo : firstDate;

      var _loop = function _loop() {
        var startDate = moment(date).format('YYYY-MM-DD');
        var displayDate = moment(date).add(1, 'month').subtract(1, 'day');
        year = displayDate.format('YYYY');
        month = displayDate.format('MM');

        if (moment(year + '-' + month + '-' + recEndDay, 'YYYY-MM-D').isValid()) {
          end = moment(year + '-' + month + '-' + recEndDay, 'YYYY-MM-D');
        } else {
          days = moment(year + '-' + month, 'YYYY-MM').daysInMonth();
          end = moment(year + '-' + month + '-' + days, 'YYYY-MM-D');
        }

        periods.push({
          month: displayDate.format('MMM'),
          year: year,
          startDate: startDate,
          isReconciled: !!_this.get('chartAccount.reconciliations').find(function (r) {
            return r.get('startDate') === startDate && r.get('isReconciled');
          }),
          clickable: date >= firstDate.startOf('month')
        });
        date = end.add(1, 'day');
      };

      while (date <= moment()) {
        _loop();
      }

      return periods.map(function (_e, i, a) {
        return a[a.length - 1 - i];
      });
    })
  });

  _exports.default = _default;
});