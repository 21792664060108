define("zipbooks/utils/log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = log;

  function log(level, message) {
    if (!Ember.testing) {
      Ember.Logger[level](message);
    }
  }
});