define("zipbooks/components/drawers/transaction/advanced-mode", ["exports", "zipbooks/mixins/transaction-mode", "zipbooks/utils/form-line"], function (_exports, _transactionMode, _formLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transactionMode.default, {
    session: Ember.inject.service(),
    actions: {
      addPart: function addPart() {
        var newPart = _formLine.default.create({
          name: null,
          amount: 0,
          kind: null,
          tags: []
        });

        this.formLines.pushObject(newPart);
        this.otherLines.pushObject(newPart);
      }
    },
    defaultCategory: function defaultCategory() {
      return null;
    },
    _observeCategorization: null,
    savableEntry: function savableEntry() {
      var _this = this;

      var formLines = this.formLines;
      formLines = this.withEmptyLinesRemoved(formLines);
      formLines.forEach(function (l) {
        return l.set('date', _this.get('shared.date'));
      });
      var lines = this.convertToLines(formLines);
      var entry = this.entry;
      entry.set('currencyCode', this.session.get('account.defaultCurrencyCode'));
      entry.set('journalEntryLines', lines);
      return entry;
    }
  });

  _exports.default = _default;
});