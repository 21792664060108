define("zipbooks/utils/pluralize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pluralize;

  function pluralize(string, count) {
    var multiple = count !== 1;
    string = string.replace('%p', count);
    return string.replace(/\((.*?)\/(.*?)\)/g, function (_match, singular, plural) {
      return multiple ? plural : singular;
    });
  }
});