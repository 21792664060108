define("zipbooks/routes/main/reports/credit-card-processing", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    queryParams: {
      view: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      group_by: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.tags = this.paramTags(params);

      if (moment(params.start_date).isAfter(moment(params.end_date))) {
        params.start_date = params.end_date;
      }

      this.set('params', params);
      return this.client.cached('GET', 'reports', "credit-card-processing?".concat($.param(params))).then(function (resp) {
        return resp['credit_card_summary'];
      });
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var queryParams = {
          end_date: params.end_date,
          group_by: this.get('controller.group_by'),
          start_date: params.start_date,
          status: this.get('controller.status'),
          view: this.get('controller.view')
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.location = '/v2/reports/credit-card-processing/export?' + $.param(queryParams);
      }
    }
  });

  _exports.default = _default;
});