define("zipbooks/mixins/subscription-banners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['drawer'],
    session: Ember.inject.service(),
    showTrialBanner: Ember.computed('router.currentPath', 'freeTrialExpiresIn', 'session.account.hasCreditCard', 'this.session.subscriptionFlags.@each', function () {
      var hasCard = this.get('session.account.hasCreditCard');
      var subsPage = this.get('router.currentPath') === 'main.settings.subscription';
      var isCPA = this.session.subscriptionFlags.includes('cpa');
      var trialExpiresSoon = this.freeTrialExpiresIn >= 0 && this.freeTrialExpiresIn < 14;
      return hasCard === false && !subsPage && !isCPA && trialExpiresSoon;
    }),
    freeTrialExpiresIn: Ember.computed('model', 'session.subscription.isTrialing', function () {
      if (this.get('session.subscription.isTrialing')) {
        var freeTrialExpiresOn = moment(this.get('session.subscription.trialEndsOn'));
        var today = moment().startOf('day');
        var freeTrialExpiresIn = freeTrialExpiresOn.diff(today, 'days');
        return freeTrialExpiresIn;
      }
    })
  });

  _exports.default = _default;
});