define("zipbooks/mixins/add-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    state: 'login',
    // 'importing', 'loading'
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    overlay: Ember.inject.service(),
    router: Ember.inject.service(),

    /************************************************************************
     * New Account
     ************************************************************************/
    connectNew: function connectNew() {
      var _this = this;

      var existing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.showWidget(existing).then(function () {
        _this.bankSuccessfullyImporting();
      });
    },

    /************************************************************************
     * AddBankMixin
     ************************************************************************/
    bankSuccessfullyImporting: function bankSuccessfullyImporting() {
      (false && Ember.warn('You must implement this', {
        id: 'bankSuccessfullyImporting'
      }));
    },

    /************************************************************************
     * Helpers
     ************************************************************************/
    showErrors: function showErrors(error) {
      // if jqXHR
      if (error.status === 404) {
        this.overlay.showBanner('error', 'Looks like this bank account has been deleted!');
        this.close();
      } else if (error.responseJSON && error.responseJSON.errors) {
        this.set('message', error.responseJSON.errors.mapBy('title').join(', '));
      } // if string
      else if (error) {
          this.set('message', error);
        } else {
          this.set('message', 'There was a problem setting up the connect form. Contact support.');
        }
    },
    showWidget: function showWidget() {
      var _this2 = this;

      var existingMember = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.set('state', 'login');

        _this2.set('loadingWidgetUrl', true);

        var path = 'widget';

        if (existingMember.guid) {
          path += '?current_member_guid=' + existingMember.guid;
        }

        _this2.client.send('GET', 'bank-accounts', path).then(function (resp) {
          _this2.set('loadingWidgetUrl', false);

          var loaded = false;

          if (Ember.testing) {
            Ember.Test.registerWaiter(function () {
              return loaded;
            });
          }

          var options = {
            id: 'mx-widget',
            isMobileWebView: false,
            url: resp.user.connect_widget_url,
            onLoad: function onLoad() {
              loaded = true;
            },
            onSuccess: function onSuccess(response) {
              if (!existingMember.guid) {
                _this2.store.createRecord('bank-member', {
                  externalId: response.member_guid,
                  provider: 'atrium'
                }).save().then(function () {
                  _this2.notificationCenter.notify('bank-member.save');

                  switch (response.member.status) {
                    case 'CHALLENGED':
                    case 'DEGRADED':
                    case 'DENIED':
                    case 'DELAYED':
                    case 'DISCONNECTED':
                    case 'EXPIRED':
                    case 'FAILED':
                    case 'IMPAIRED':
                    case 'IMPORTED':
                    case 'PREVENTED':
                    case 'RECONNECTED':
                    case 'REJECTED':
                    case 'CLOSED':
                    case 'IMPEDED':
                    case 'DISCONTINUED':
                    case 'LOCKED':
                      reject('Bank was in error status');
                      break;

                    default:
                      // Remove the widget
                      _this2.set('outClass', null);

                      _this2.set('state', 'loading');

                      resolve();
                      break;
                  }
                });
              } else {
                _this2.set('outClass', null);

                resolve(); //the widget is left open after it is done aggregating
              }
            }
          };
          $.getScript('https://atrium.mx.com/connect.js', function ()
          /*data, textStatus, jqxhr*/
          {
            var mxConnect = new MXConnect(options);
            mxConnect.load();
          });
        }, function (jqXHR
        /*, textStatus,errorThrown */
        ) {
          if (jqXHR.status === 429) {
            _this2.router.transitionTo({
              queryParams: {
                drawer: null
              }
            });
          } else {
            _this2.showErrors(jqXHR);

            reject(jqXHR);
          }
        });
      });
    }
  });

  _exports.default = _default;
});