define("zipbooks/components/index-row-content/time-entry", ["exports", "zipbooks/mixins/bulk-operation", "zipbooks/utils/string"], function (_exports, _bulkOperation, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_bulkOperation.default, {
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: '',
    timeEntry: Ember.computed('group.timeEntries.@each.id', function () {
      if (this.group.timeEntries) {
        return this.group.timeEntries.firstObject;
      } else {
        return this.group;
      }
    }),
    isGroup: Ember.computed('group.timeEntries.length', function () {
      if (this.group.timeEntries) {
        return this.group.timeEntries.length > 1;
      } else {
        return false;
      }
    }),
    actions: {
      destroy: function destroy(timeEntry) {
        this.onDestroy(timeEntry);
      },
      destroyGroup: function destroyGroup() {
        var _this = this;

        var ids = this.group.timeEntries.mapBy('id');

        if (ids.length > 0) {
          this.overlay.showDialog('overlays/dialogs/destroy-confirm', (0, _string.default)('time-entry', 'destroy', 'title'), {
            type: 'time-entry',
            count: ids.length
          }, {
            destroy: function destroy() {
              _this.bulk(ids.map(function (id) {
                return _this.store.peekRecord('time-entry', id);
              }), function (model) {
                return model.destroyRecord();
              }, function (result) {
                _this.store.unloadRecord(_this.group);

                result.succeeded.forEach(function (m) {
                  return m.unloadRecord();
                });
                result.failed.forEach(function (m) {
                  return m.rollbackAttributes();
                });

                _this.showBulkBanner('deleted', result.succeeded.length, result.unchanged.length, result.failed.length, 'time entries');
              });
            }
          });
        }
      },
      select: function select() {
        this.onSelect.apply(this, arguments);
      },
      selectGroup: function selectGroup(group, event) {
        var _this2 = this;

        var checked = $(event.target).is(':checked');
        group.timeEntries.forEach(function (te) {
          te.set('isSelected', checked);

          _this2.onSelect(te, event);
        });
      },
      toggleExpand: function toggleExpand() {
        if (this.isExpanded) {
          Ember.tryInvoke(this, 'close', [this.group.id]);
        } else {
          Ember.tryInvoke(this, 'open', [this.group.id]);
        }
      }
    }
  });

  _exports.default = _default;
});