define("zipbooks/templates/main/partials/integration-box/-action-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jEzVQWoe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"connected\"]],[24,[\"preferenceDrawer\"]]],null]],null,{\"statements\":[[4,\"drawer-link\",[[24,[\"preferenceDrawer\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"btn btn-med btn-gray-med ml-auto\"],[8],[0,\"\\n      Preferences\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"connected\"]],[24,[\"preferenceLink\"]]],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\"],[[24,[\"preferenceLink\"]]]],{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"btn btn-med btn-gray-med ml-auto\"],[8],[0,\"\\n      Preferences\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"connected\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"connectWithDrawer\"]]],null,{\"statements\":[[4,\"drawer-link\",[[24,[\"preferenceDrawer\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"btn btn-med btn-purple ml-auto\"],[8],[0,\"\\n      Connect now\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"authUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[28,\"concat\",[\"/v2/auth-link/\",[24,[\"authUrl\"]],\"?callback-url=\",[28,\"current-url\",null,null]],null]],[10,\"class\",\"btn btn-purple btn-med ml-auto\"],[8],[0,\"\\n    Connect Now\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"route\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-purple btn-med ml-auto\",[24,[\"route\"]]]],{\"statements\":[[0,\"    Connect Now\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/main/partials/integration-box/-action-button.hbs"
    }
  });

  _exports.default = _default;
});