define("zipbooks/components/drawers/import-transactions-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    title: 'Import Transactions',
    large: true,
    type: 'standard',
    verifyText: '',
    typeChoices: Ember.computed('model.clientAccount.id', function () {
      var choices = [{
        value: 'standard',
        title: 'Standard'
      }, // I'm not going to fully remove this right now because I want to see if we can get it working again.  I think its probably not a lot of work, just not
      // a priority right now
      // { value: 'qbo', title: 'QuickBooks Online' },
      {
        value: 'qbo-file',
        title: 'QuickBooks General Journal'
      }, {
        value: 'nap',
        title: 'NAP'
      }];

      if (!this.session.hasPrivilege('advanced-settings')) {
        return choices.rejectBy('value', 'qbo');
      }

      return choices;
    }),
    canImport: Ember.computed('model.clientAccount.hasTransactions', 'verifyText', function () {
      if (this.get('model.clientAccount.hasTransactions') && this.verifyText.toLowerCase() !== 'import') {
        return false;
      }

      return true;
    }),
    actions: {
      upload: function upload() {
        this.upload('import', 'csv');
      },
      uploadNap: function uploadNap() {
        this.upload('nap-import', 'csv');
      },
      quickbooksUpload: function quickbooksUpload() {
        if (!this.canImport) {
          return;
        }

        this.upload('quickbooks-import', 'xlsx');
      }
    },
    upload: function upload(path, format) {
      var field = this.$('input[type="file"]')[0];
      var file = field.files.length > 0 ? field.files[0] : (field.testingfiles || [])[0];

      if (file) {
        if ((file.name || '').toLowerCase().split('.').pop() === format) {
          var self = this;
          this.set('buttonState', 'saving');
          this.client.upload('journal-entries', path, file, {
            success: function success()
            /*result*/
            {
              self.set('buttonState', 'loaded');
              self.get('overlay').showBanner('success', 'Your transactions have been imported!');
              self.get('notificationCenter').notify('journal-entry.save');
              self.get('close')(715);
            },
            failed: function failed(status, json) {
              self.set('buttonState', null);

              if (status === 422 && json.errors) {
                var lineErrors = json.errors.map(function (error) {
                  return error.title;
                });
                var errorHtml = Ember.String.htmlSafe(lineErrors.map(function (s) {
                  return s.stripHTML();
                }).join('<br>'));
                self.get('overlay').showBanner('error', errorHtml, {
                  title: 'Whoops!'
                });
              } else {
                self.get('overlay').showBanner('error', 'Something went wrong. Contact support.', {
                  title: 'Whoops!'
                });
              }
            },
            error: function error(_error) {
              self.set('buttonState', null);
              self.get('overlay').showBanner('error', _error, {
                title: 'Whoops!'
              });
            }
          });
        } else {
          this.overlay.showBanner('error', "File must be a ".concat(format, " file."), {
            title: 'Whoops!'
          });
        }
      } else {
        this.overlay.showBanner('error', 'You must select a file.', {
          title: 'Oops!'
        });
      }
    }
  });

  _exports.default = _default;
});