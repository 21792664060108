define("zipbooks/controllers/main/locations", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller"], function (_exports, _controllerPagination, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    client: Ember.inject.service(),
    collection: Ember.computed.alias('model.locations'),
    numLocations: Ember.computed('model.locations', function () {
      return this.get('model.locations').length;
    })
  });

  _exports.default = _default;
});