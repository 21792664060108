define("zipbooks/components/reports/cash-flow-statement-line-value", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/paid-modal-handler", "zipbooks/utils/tag-serialization", "zipbooks/utils/to-decimal"], function (_exports, _testable, _paidModalHandler, _tagSerialization, _toDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, _testable.default, {
    quickLinkExclude: ['Total cash flow from operating activities', 'Total cash flow from investing activities', 'Total cash flow from financing activities', 'Fixed assets', 'Shareholders', 'Non-operating activities'],
    balance: Ember.computed('lineInfo.balances', 'group.name', function () {
      var _this = this;

      return this.get('lineInfo.balances').find(function (balance) {
        return balance.group === _this.get('group.name');
      }).balance;
    }),
    sumLineClass: Ember.computed('lineInfo.classification', 'balance', function () {
      if (Ember.isBlank(this.get('lineInfo.classification'))) {
        var balance = (0, _toDecimal.default)(this.get('balance'));
        var zero = new Decimal(0);

        if (balance.comparedTo(zero) > 0) {
          return 'green ';
        } else if (balance.comparedTo(zero) === 0) {
          return 'base ';
        } else {
          return 'red ';
        }
      }
    }),
    actions: {
      viewTransactions: function viewTransactions(classification) {
        var startDate,
            endDate,
            date = this.get('group.name'),
            month,
            year;
        var maxStartDate = moment(this.get('startDate'));
        var maxEndDate = moment(this.get('endDate'));
        var tags = this.get('tags');

        if (date === 'Total' || this.get('tagGroupSelected')) {
          startDate = maxStartDate;
          endDate = maxEndDate;
        } else {
          switch (this.get('groupBy')) {
            case 'month':
              startDate = moment(date).startOf('month');
              endDate = moment(date).endOf('month');
              break;

            case 'quarter':
              month = date.split(' ')[0].charAt(1) * 3 - 2;
              year = date.split(' ')[1];
              date = year + '-' + month;
              startDate = moment(date, 'YYYY-M').startOf('quarter');
              endDate = moment(date, 'YYYY-M').endOf('quarter');
              break;

            case 'year':
              startDate = moment(date, 'YYYY').startOf('year');
              endDate = moment(date, 'YYYY').endOf('year');
              break;

            default:
              startDate = maxStartDate;
              endDate = maxEndDate;
          }

          startDate = startDate.isBefore(this.get('startDate')) ? maxStartDate : startDate;
          endDate = endDate.isAfter(this.get('endDate')) ? maxEndDate : endDate;
        }

        if (this.get('tagGroupSelected')) {
          tags = tags.filter(function (tag) {
            return tag.kind !== 'kind';
          });
          tags.push(this.get('group.tag'));
        }

        if (classification === 'Net income') {
          var groupBy = this.get('groupBy'); // If clicking through to Income Statement on non-total column, we
          // want to display the correct date range but only show the total.

          if (this.get('group.name') !== 'Total') {
            groupBy = 'total';
          }

          this.get('router').transitionTo('main.reports.income-statement', {
            queryParams: {
              end_date: endDate.format('YYYY-MM-DD'),
              start_date: startDate.format('YYYY-MM-DD'),
              group_by: groupBy,
              tags: _tagSerialization.default.serialize(tags)
            }
          });
        } else {
          if (this.permissions.can('accounting.read')) {
            this.get('router').transitionTo('main.transactions', {
              queryParams: {
                classification: classification,
                end_date: endDate.format('YYYY-MM-DD'),
                start_date: startDate.format('YYYY-MM-DD'),
                tags: _tagSerialization.default.serialize(tags)
              }
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});