define("zipbooks/templates/main/partials/preferences/-receipt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y1TtnD4B",
    "block": "{\"symbols\":[\"value\",\"hasErrors\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box-space-header-sm\"],[8],[0,\"Receipts\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group form-group-inline row flex-items-lg-top\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"col-12 col-lg-4 label text-lg-right\"],[8],[0,\"\\n\"],[4,\"paid-tag\",[\"div\",\"account-defaults\"],[[\"otherwise\"],[\"show\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"purple icon-rank-army-star-badge-2-filled mr5\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    Default email body\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-lg-8\"],[8],[0,\"\\n\"],[4,\"form-input\",null,[[\"value\",\"errors\",\"valuesError\"],[[24,[\"model\",\"settings\",\"receiptDefaultEmailBody\"]],[24,[\"model\",\"errors\",\"values\"]],\"receipt_default_email_body\"]],{\"statements\":[[0,\"      \"],[1,[28,\"textarea\",null,[[\"value\",\"class\",\"placeholder\",\"title\",\"testId\"],[[23,1,[]],[28,\"concat\",[\"form-control\",\" \",[28,\"if\",[[23,2,[]],\"input-error\"],null],\" \"],null],\"Default body of receipts sent from inside ZipBooks.\",\"Default receipt email body\",\"default-receipt-email-body\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[4,\"paid-tag\",[\"div\",\"account-defaults\"],[[\"otherwise\"],[\"show\"]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showPaidModal\",\"account-defaults\"],null]],[10,\"class\",\"paid-input-disabler\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/main/partials/preferences/-receipt.hbs"
    }
  });

  _exports.default = _default;
});