define("zipbooks/components/transaction-sheet/suggest/chart-account-select", ["exports", "zipbooks/components/suggest/chart-account-select", "zipbooks/mixins/transaction-sheet/auto-suggest", "zipbooks/utils/array", "zipbooks/utils/transaction-sheet/line-sorter", "zipbooks/utils/search-in"], function (_exports, _chartAccountSelect, _autoSuggest, _array, _lineSorter, _searchIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _chartAccountSelect.default.extend(_autoSuggest.default, {
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var _this = this;

      var history;

      if (!Ember.isBlank(this.get('filterByName'))) {
        history = this.store.peekAll('historical-journal-entry-line').filter(function (l) {
          return l.get('name') === _this.get('filterByName');
        });
      } else {
        history = [];
      }

      var groupedByJournalEntry = (0, _array.groupBy)(history, function (v) {
        return v.journalEntryId;
      });
      history = Object.keys(groupedByJournalEntry).map(function (k) {
        var lines = groupedByJournalEntry[k];
        return lines.sort(_lineSorter.default).lastObject;
      });
      history = history.mapBy('chartAccount.name').uniq();
      var results = this.store.chartAccounts({
        filter: this.filter
      });

      if (this.excludeChartAccountIds) {
        results = results.filter(function (r) {
          return !_this.excludeChartAccountIds.includes(r.id);
        });
      }

      results = (0, _searchIn.default)(results, 'name', search);
      return results.map(function (ca) {
        return Ember.Object.create({
          id: ca.id,
          name: ca.name,
          chartAccount: ca,
          usedPreviously: history.includes(ca.name)
        });
      });
    },
    sort: function sort(a, b) {
      if (a.usedPreviously === b.usedPreviously) {
        return b.chartAccount.viewable - a.chartAccount.viewable;
      } else {
        return b.usedPreviously - a.usedPreviously;
      }
    },
    handleAddNew: function handleAddNew(label) {
      var _this2 = this;

      var newChartAccount = this.store.createRecord('chartAccount', {
        name: label
      });
      this.overlay.showDrawer('drawers/chart-account-form', newChartAccount, {
        saved: function saved(model) {
          _this2.didSelect({
            chartAccount: model
          });
        }
      });
    }
  });

  _exports.default = _default;
});