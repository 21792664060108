define("zipbooks/models/line-item", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/utils/parse-number", "zipbooks/utils/to-decimal"], function (_exports, _model, _model2, _parseNumber, _toDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    notes: (0, _model.attr)(),
    quantity: (0, _model.attr)('decimal-2'),
    rate: (0, _model.attr)('decimal-2'),
    type: (0, _model.attr)(),
    taxes: (0, _model.attr)(),
    startDate: (0, _model.attr)('date'),
    endDate: (0, _model.attr)('date'),
    discount: (0, _model.attr)(),
    order: (0, _model.attr)(),
    item: (0, _model.belongsTo)('item'),
    lineItemable: (0, _model.belongsTo)('lineItemable', {
      polymorphic: true
    }),
    chartAccount: (0, _model.belongsTo)('chartAccount'),
    total: Ember.computed('rate', 'quantity', function () {
      var rate = this.rate,
          quantity = this.quantity;

      if (rate) {
        rate = (0, _toDecimal.default)(rate);
      } else {
        rate = (0, _toDecimal.default)(0);
      }

      if (quantity) {
        quantity = (0, _toDecimal.default)(quantity);
      } else {
        quantity = (0, _toDecimal.default)(0);
      }

      return rate.times(quantity).toDecimalPlaces(2);
    }),
    discountTotal: Ember.computed('discount', 'total', function () {
      var total = this.total;
      var discount = (0, _parseNumber.default)(this.discount);
      return total.times(discount).dividedBy(100).toDecimalPlaces(2);
    }),
    active: Ember.computed('lineItemable.nextScheduledDate', 'lineItemable.modelType', 'startDate', 'endDate', function () {
      return this.get('lineItemable.modelType') !== 'recurring-profile' || (!this.startDate || !moment(this.get('lineItemable.nextScheduledDate')).isBefore(moment(this.startDate))) && (!this.endDate || !moment(this.get('lineItemable.nextScheduledDate')).isAfter(moment(this.endDate)));
    }),
    tax1: Ember.computed.alias('taxes.firstObject'),
    tax2: Ember.computed.alias('taxes.lastObject'),
    // for linking unbilled time/expenses to the line items
    timeEntries: (0, _model.hasMany)('timeEntry'),
    journalEntryLine: (0, _model.belongsTo)('journalEntryLine')
  });

  _exports.default = _default;
});