define("zipbooks/utils/insensitive-compare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = insensitiveCompare;

  function insensitiveCompare(a, b) {
    return (a || '').toLowerCase() === (b || '').toLowerCase();
  }
});