define("zipbooks/components/drawers/chart-account-form", ["exports", "zipbooks/utils/classifications", "zipbooks/components/drawers/model-drawer"], function (_exports, _classifications, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _modelDrawer.default.extend({
    title: Ember.computed('model.isNew', function () {
      return this.get('model.isNew') ? 'Add Category' : 'Edit Category';
    }),
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    initialValue: 0,
    showReassign: false,
    reassignChartAccount: null,
    canChangeParentCodes: [1610, 1620, 1630, 1640, 2310, 2320, 2330, 2340, 2350, 4550, 6100, 6110, 6200, 6300, 6310, 6320, 6330, 6340, 6350, 6400, 6410, 6420, 6430, 6440, 6450, 6460, 6500, 6510, 6520, 6550, 6600, 6650, 6700, 6750, 6800, 6810, 6820, 6830, 6900, 6910, 6920, 6930, 7100],
    actions: {
      didSelectUncreated: function didSelectUncreated(uncreated) {
        if (uncreated) {
          this.set('model.name', uncreated.name);
          this.set('model.systemCode', uncreated.systemCode);
          this.set('model.parent', uncreated.parent);
        }
      },
      didTypeCustomName: function didTypeCustomName(name) {
        this.set('model.name', name);
      },
      selectParentChartAccount: function selectParentChartAccount(chartAccount) {
        this.set('model.parent', chartAccount);

        if (chartAccount && this.get('model.code') === this.get('model.classCode')) {
          this.set('model.code', chartAccount.code);
        }
      },
      saveChartAccount: function saveChartAccount(model) {
        var _this = this,
            _arguments = arguments;

        // start date should be null if there's no value
        if (model.get('value.length') === 0) {
          model.set('value', undefined);
          model.set('startDate', undefined);
        }

        if (this.reassignChartAccount !== null) {
          this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
            title: "Are you sure you want to move all ".concat(model.get('name'), " transactions to ").concat(this.get('reassignChartAccount.name'), "?"),
            confirmButton: {
              title: 'Move transactions',
              class: 'btn-red'
            },
            cancelButton: {
              title: 'No, cancel',
              class: 'btn-white'
            }
          }, {
            confirm: function confirm() {
              var body = {
                reassign_chart_account_id: _this.get('reassignChartAccount.id')
              };

              _this.client.send('PATCH', "chart-accounts", "".concat(model.get('id'), "/reassign"), body);

              _this.send.apply(_this, ['save'].concat(_toConsumableArray(_arguments)));
            },
            cancel: function cancel() {}
          });
        } else if (this.reconcileDayChanged) {
          this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
            title: "Are you sure you want to change the reconciliation period end. All reconciliations for this chart account will be reset?",
            confirmButton: {
              title: 'Yes, reset',
              class: 'btn-red'
            },
            cancelButton: {
              title: 'No, cancel',
              class: 'btn-white'
            }
          }, {
            confirm: function confirm() {
              return _this.send.apply(_this, ['save'].concat(_toConsumableArray(_arguments)));
            },
            cancel: function cancel() {
              return _this.set('model.reconcileEndDay', _this.initialReconcileEndDay);
            }
          });
        } else {
          this.send.apply(this, ['save'].concat(Array.prototype.slice.call(arguments)));
        }
      },
      showReassign: function showReassign() {
        this.set('showReassign', true);
      },
      setReassignAccount: function setReassignAccount(chartAccount) {
        this.set('reassignChartAccount', chartAccount);
      }
    },
    initialReconcileEndDay: Ember.computed(function () {
      return this.get('model.reconcileEndDay');
    }),
    reconcileDayChanged: Ember.computed('initialReconcileEndDay', 'model.reconcileEndDay', function () {
      return this.initialReconcileEndDay !== this.get('model.reconcileEndDay');
    }),
    canChangeParent: Ember.computed('model.isSystem', 'model.systemCode', function () {
      var isSystem = this.get('model.isSystem');
      var systemCode = this.get('model.systemCode') * 1;
      return !isSystem || this.canChangeParentCodes.includes(systemCode);
    }),
    showIsDepreciable: Ember.computed('model.parent.classification', function () {
      var parentClassification = this.get('model.parent.classification');
      return parentClassification && (0, _classifications.default)()[parentClassification].depreciable;
    }),
    showInitialValue: Ember.computed('model.parent.classification', function () {
      var parentClassification = this.get('model.parent.classification');
      return parentClassification && (0, _classifications.default)()[parentClassification].initValue;
    }),
    showStartDate: Ember.computed('model.isDepreciable', 'model.value', function () {
      return this.get('model.value.length') > 0;
    }),
    reconcileEndDayOptions: [{
      title: 'Last Day of Month',
      value: 31
    }, {
      title: '1',
      value: 1
    }, {
      title: '2',
      value: 2
    }, {
      title: '3',
      value: 3
    }, {
      title: '4',
      value: 4
    }, {
      title: '5',
      value: 5
    }, {
      title: '6',
      value: 6
    }, {
      title: '7',
      value: 7
    }, {
      title: '8',
      value: 8
    }, {
      title: '9',
      value: 9
    }, {
      title: '10',
      value: 10
    }, {
      title: '11',
      value: 11
    }, {
      title: '12',
      value: 12
    }, {
      title: '13',
      value: 13
    }, {
      title: '14',
      value: 14
    }, {
      title: '15',
      value: 15
    }, {
      title: '16',
      value: 16
    }, {
      title: '17',
      value: 17
    }, {
      title: '18',
      value: 18
    }, {
      title: '19',
      value: 19
    }, {
      title: '20',
      value: 20
    }, {
      title: '21',
      value: 21
    }, {
      title: '22',
      value: 22
    }, {
      title: '23',
      value: 23
    }, {
      title: '24',
      value: 24
    }, {
      title: '25',
      value: 25
    }, {
      title: '26',
      value: 26
    }, {
      title: '27',
      value: 27
    }, {
      title: '28',
      value: 28
    }, {
      title: '29',
      value: 29
    }, {
      title: '30',
      value: 30
    }, {
      title: '31',
      value: 31
    }]
  });

  _exports.default = _default;
});