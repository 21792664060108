define("zipbooks/models/invoice", ["exports", "ember-data", "zipbooks/mixins/line-itemable", "@ember-data/model", "zipbooks/models/model"], function (_exports, _emberData, _lineItemable, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_lineItemable.default, {
    session: Ember.inject.service(),
    acceptCreditCards: (0, _model.attr)('boolean'),
    acceptPaypal: (0, _model.attr)('boolean'),
    amountDue: (0, _model.attr)('money'),
    client: Ember.inject.service(),
    date: (0, _model.attr)('date'),
    daysOutstanding: (0, _model.attr)(),
    dueDate: (0, _model.attr)('date'),
    externalId: (0, _model.attr)(),
    history: (0, _model.attr)(),
    number: (0, _model.attr)(),
    paidAmount: (0, _model.attr)('money'),
    paidTotal: (0, _model.attr)('money'),
    sentDate: (0, _model.attr)('date'),
    squareLocationId: (0, _model.attr)(),
    status: (0, _model.attr)(),
    payments: (0, _model.hasMany)('payments'),
    estimate: (0, _model.belongsTo)('estimate'),
    recurringProfile: (0, _model.belongsTo)('recurringProfile'),
    fetchedHistory: Ember.computed('id', 'updatedAt', function () {
      return _emberData.default.PromiseArray.create({
        promise: this.client.send('GET', 'invoices', this.id + '/history')
      });
    }),
    paidDate: Ember.computed('payments.@each', function () {
      if (this.get('payments.length') > 0) {
        return this.payments.sortBy('date').get('lastObject.date');
      }
    }),
    locked: Ember.computed('date', 'session.account.settings.lockDate', function () {
      if (this.session.get('account.settings.lockDate')) {
        return moment(this.date).isSameOrBefore(this.session.get('account.settings.lockDate'));
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});