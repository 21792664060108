define("zipbooks/components/overlays/modal-dialog", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    outSelector: '.modal-dialog',
    outClass: 'modalSlideOutUp',
    overlay: Ember.inject.service(),
    actions: {
      closeDialog: function closeDialog() {
        this.overlay.close(this.ref);

        if (this.get('callbacks.close')) {
          this.get('callbacks.close')();
        }
      },
      cancelDialog: function cancelDialog() {
        this.overlay.close(this.ref);

        if (this.get('callbacks.close')) {
          this.get('callbacks.close')();
        } else if (this.get('callbacks.cancel')) {
          this.get('callbacks.cancel')();
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal-backdrop').removeClass('modalFadeIn').addClass('modalFadeOut');

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});