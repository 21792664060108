define("zipbooks/components/drawers/transaction/expense-mode", ["exports", "zipbooks/mixins/transaction-mode", "zipbooks/utils/line-sorter"], function (_exports, _transactionMode, _lineSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transactionMode.default, {
    sortedLines: Ember.computed('entry.journalEntryLines.@each', function () {
      var lines = this.get('entry.journalEntryLines').toArray();
      return (0, _lineSorter.default)(lines, 'credit', 'expense-drawer-account-filter');
    }),
    getPrimaryLine: function getPrimaryLine() {
      var primaryLine = this._super.apply(this, arguments);

      if (primaryLine) {
        primaryLine.set('previousKind', 'credit');
        primaryLine.set('kind', 'credit');
      }

      return primaryLine;
    },
    is1099Required: Ember.computed('formLines.@each', function () {
      return this.otherLines.any(function (l) {
        return l.get('is1099Required');
      });
    }),
    is1099CheckboxDisabled: Ember.computed('formLines.@each.is1099Required', 'allTags.@each', function () {
      var allSaveValue = this.formLines.every(function (line, _i, arr) {
        return line.get('is1099Required') === arr[0].get('is1099Required');
      });
      var hasContactTag = this.allTags.any(function (tag) {
        return tag.get('taggableType') === 'contact';
      });
      return !allSaveValue || !hasContactTag;
    }),
    billToContact: Ember.computed('otherLines.@each', function () {
      return this.otherLines.filter(function (line) {
        return line.get('billToContact.id');
      }).get('firstObject.billToContact');
    }),
    actions: {
      selectBillToContact: function selectBillToContact(contact) {
        this.otherLines.forEach(function (line) {
          return line.set('billToContact', contact);
        });
      },
      toggleIs1099Required: function toggleIs1099Required(event) {
        var isRequired = $(event.target).is(':checked');
        this.formLines.forEach(function (line) {
          return line.set('is1099Required', isRequired);
        });
      }
    },
    savableEntry: function savableEntry() {
      var primaryLine = this.primaryLine; // set the amount of the primary line to the sum of the bottom lines

      primaryLine.set('amount', this.bottomSum);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});