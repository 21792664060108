define("zipbooks/utils/magic-due-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = extractDueDateFromTerms;

  function extractDueDateFromTerms(string, modelDate) {
    if (!string) {
      return null;
    }

    modelDate = modelDate || moment();
    /*************************
     * Example: net 30
     **************************/

    var netMatch = string.match(/(?:^|\s)net (\d+)/i);

    if (netMatch) {
      return moment(modelDate).add(+netMatch[1], 'days').format('YYYY-MM-DD');
    }
    /*************************
     * Example: due in 30 days
     **************************/


    var dueInMatch = string.match(/(?:^|\s)due in (\d+)(?: |)(days|weeks|months|)/i);

    if (dueInMatch) {
      var quantity = dueInMatch[1];
      var unit = dueInMatch[2];
      return moment(modelDate).add(+quantity, unit || 'days').format('YYYY-MM-DD');
    }
    /*************************
     * Example: due 8/11/2017
     **************************/


    var withYear = ['\\d{1,2}/\\d{1,2}/\\d{2,4}', '\\d{1,2}-\\d{1,2}-\\d{2,4}', '[a-z]+ \\d{1,2}, \\d{2,4}'];
    var withYearMatch = withYear.firstValue(function (s) {
      var regex = new RegExp("(?:^|\\s)due (".concat(s, ")"), 'i');
      return string.match(regex);
    });

    if (withYearMatch) {
      var parsed = moment(withYearMatch[1], ['YYYY-MM-DD', 'MM-DD-YYYY', 'MMM D, YYYY']);

      if (parsed.isValid()) {
        return parsed.format('YYYY-MM-DD');
      }
    }
    /*************************
     * Example: due 8/11
     **************************/


    var withoutYear = ['\\d{1,2}/\\d{1,2}', '\\d{1,2}-\\d{1,2}', '[a-z]+ \\d{1,2}'];
    var withoutYearMatch = withoutYear.firstValue(function (s) {
      var regex = new RegExp("(?:^|\\s)due (".concat(s, ")"), 'i');
      return string.match(regex);
    });

    if (withoutYearMatch) {
      var guess = moment(withoutYearMatch[1], ['MM-DD', 'MM-DD', 'MMM D']).set('year', moment(modelDate).year());

      if (guess.isBefore(modelDate)) {
        guess.add(1, 'year');
      }

      if (guess.isValid()) {
        return guess.format('YYYY-MM-DD');
      }
    }

    return null;
  }
});