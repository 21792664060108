define("zipbooks/controllers/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    actions: {
      submit: function submit() {
        var _this = this;

        this.set('buttonState', 'saving');
        this.set('message', null);
        var attrs = this.model.getProperties('email');
        this.client.send('POST', 'auth', 'request-password-reset', attrs).then(function ()
        /*resp*/
        {
          _this.set('buttonState', 'loaded');

          _this.overlay.showBanner('success', "We've sent a reset link to that email address.", {
            title: 'Ok!'
          });
        }, function (jqXHR
        /*, textStatus,errorThrown */
        ) {
          _this.set('buttonState', 'invalid');

          if (jqXHR.responseJSON) {
            if (jqXHR.status === 422) {
              _this.overlay.showBanner('error', jqXHR.responseJSON.errors[0].detail, {
                title: 'Whoops!'
              });
            }

            var errors = jqXHR.responseJSON;

            if (Ember.typeOf(errors.message) === 'string') {
              errors = {
                email: [{
                  message: errors.message
                }]
              };
            } else {
              Object.keys(errors).forEach(function (key) {
                return errors[key] = errors[key].map(function (e) {
                  return {
                    message: e
                  };
                });
              });
            }

            _this.set('errors', errors);
          }
        });
      }
    }
  });

  _exports.default = _default;
});