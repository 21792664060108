define("zipbooks/mixins/infinite-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    loading: false,
    finished: false,
    init: function init() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        $(window).on('scroll', function () {
          var scrollPos = $(window).scrollTop() + $(window).height();

          if (scrollPos > $(document).height() - 500 && !_this.loading && !_this.finished) {
            _this.set('loading', true);

            _this.infiniteLoadMore();
          }
        });
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});