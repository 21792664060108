define("zipbooks/models/message", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    fromChannel: (0, _model.attr)(),
    fromUsername: (0, _model.attr)(),
    body: (0, _model.attr)(),
    transactionCount: (0, _model.attr)(),
    readAt: (0, _model.attr)('datetime'),
    account: (0, _model.belongsTo)('account'),
    fromUser: (0, _model.belongsTo)('user'),
    recipients: (0, _model.hasMany)('message-recipient'),
    label: (0, _model.belongsTo)('label')
  });

  _exports.default = _default;
});