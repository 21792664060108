define("zipbooks/routes/main/settings/subscription", ["exports", "zipbooks/mixins/refreshable-route", "zipbooks/utils/push-payload"], function (_exports, _refreshableRoute, _pushPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, {
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        account: Ember.RSVP.resolve(this.get('session.account')),
        locations: this.store.query('location', {}),
        creditCard: this.client.GET('credit-cards', 'default').then(function (payload) {
          if (payload && payload.data) {
            return (0, _pushPayload.default)(_this.store, 'credit-card', payload);
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.modelDidLoad(model);
    },
    // refresh when locations changes
    activate: function activate() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'location.save', function () {
        _this2.refresh();
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'location.save');
    }
  });

  _exports.default = _default;
});