define("zipbooks/helpers/oauth-host", ["exports", "zipbooks/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oauthHost = oauthHost;
  _exports.default = void 0;

  // to prevent attack explained here https://security.stackexchange.com/a/57886
  // we need to set an unguessable token in the browser session so we can check its still there
  // when they come back from OAuth. Because we redirect back to a tunnel domain (ngrok) we also
  // need to start the oauth from the tunnel domain so that the session token set when going will
  // be there on the same domain when coming.
  // why can't we just redirect back to `.local` and not even use a tunnel? Because some stupid
  // oauth services (*cough* google) don't let you use `.local` urls
  // https://d.pr/i/9QCUYi
  // basically, to use the much more flexible adapter approach for oauth, we need to accomodate
  // the least common denominator
  function oauthHost(_params, hash) {
    if (_environment.default.environment === 'development') {
      if (hash.tunnel) {
        if (_environment.default.TUNNEL_URL && _environment.default.TUNNEL_URL.length > 0) {
          return _environment.default.TUNNEL_URL;
        } else {
          alert('You need to set the environment variable TUNNEL_URL to your ngrok, then reload your bash, then restart ember');
        }
      }

      if (hash.host) {
        return "https://".concat(hash.host, ".local");
      }
    } else {
      if (hash.host) {
        return "https://".concat(hash.host, ".com");
      }

      return hash.productionHost || '';
    }
  }

  var _default = Ember.Helper.helper(oauthHost);

  _exports.default = _default;
});