define("zipbooks/components/drawers/refund-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    title: 'Refund',
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.findRecord('payment', this.get('model.payment_id')).then(function (payment) {
        var refund = _this.store.createRecord('refund', {
          payment: payment,
          reason: '',
          amount: _this.get('model.max_refundable')
        });

        _this.set('formModel', refund);
      });
    },
    actions: {
      refund: function refund() {
        var _this2 = this;

        this.set('buttonState', 'saving');
        this.formModel.save().then(function (_resp) {
          _this2.overlay.close(_this2.ref);

          _this2.get('callbacks.refunded')();
        }, function (resp) {
          _this2.set('buttonState', 'invalid');

          var message = resp.errors.mapBy('detail').join('<br>');

          _this2.overlay.showBanner('error', message);
        });
      }
    }
  });

  _exports.default = _default;
});