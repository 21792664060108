define("zipbooks/routes/main/reconciliation", ["exports", "zipbooks/mixins/index-route", "zipbooks/mixins/infinite-scroll", "zipbooks/mixins/permission-redirect", "zipbooks/utils/sort-param"], function (_exports, _indexRoute, _infiniteScroll, _permissionRedirect, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_indexRoute.default, _infiniteScroll.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    queryParams: {
      confirmed: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      },
      kind: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    actions: {
      refresh: function refresh() {
        this.set('controller.model.transactions', null);
        this.urlCache.clear();
        this.refresh();
      }
    },
    model: function model(params) {
      var _this = this;

      params.chart_account_id = params.id;
      delete params.id;
      return this.client.cached('GET', "chart-accounts/".concat(params.chart_account_id, "/reconciliation-meta"), "?".concat($.param(params))).then(function (meta) {
        _this.set('params', _this.getParams(meta, params));

        var models = {
          chartAccounts: _this.store.peekAll('chartAccount'),
          meta: meta,
          reconciliation: _this.store.findRecord('reconciliation', meta.reconciliation_id, {
            reload: true
          }),
          transactions: _this.store.cachedQuery('journalEntryLine', _this.params).then(function (results) {
            _this.set('nextPage', results.meta.links.next);

            _this.set('finished', false);

            return results.toArray();
          })
        };

        if (meta.bank_account_id) {
          models.balances = _this.client.cached('GET', "bank-accounts/".concat(meta.bank_account_id, "/balances?date=").concat(meta.end_date));
        }

        return Ember.RSVP.hash(models);
      });
    },
    getParams: function getParams(resp, params) {
      var result = {
        filter: {
          chart_account_id: resp.chart_account_id,
          start_date: resp.start_date,
          end_date: resp.end_date,
          kind: params.kind
        },
        sort: (0, _sortParam.default)(params),
        page: {
          page: params.page || 1,
          'page-size': 100
        }
      };

      if (params.confirmed == 'confirmed') {
        result.filter.confirmed = true;
      }

      if (params.confirmed == 'unconfirmed') {
        result.filter.confirmed = false;
      }

      if (params.source) {
        result.filter.source = params.source;
      }

      return result;
    },
    activate: function activate() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'journal-entry.save', function () {
        _this2.refresh();
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'journal-entry.save');
    },
    infiniteLoadMore: function infiniteLoadMore() {
      var _this3 = this;

      if (this.get('controller.model.transactions.length') > 99) {
        if (this.nextPage) {
          this.set('controller.showLoader', true);
          var params = this.params;
          params.page.page++;
          this.set('params', params);
          return this.store.cachedQuery('journalEntryLine', params).then(function (results) {
            _this3.set('nextPage', results.meta.links.next);

            _this3.get('controller.model.transactions').pushObjects(results.toArray());

            _this3.set('controller.showLoader', false);

            Ember.run.later(_this3, function () {
              _this3.set('loading', false);
            }, 2000);
          });
        } else {
          this.set('finished', true);
          this.set('loading', false);
        }
      } else {
        this.set('loading', false);
      }
    }
  });

  _exports.default = _default;
});