define("zipbooks/adapters/accountant", ["exports", "zipbooks/adapters/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    accountantSession: Ember.inject.service(),

    get headers() {
      var headers = {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json' // include the token if present

      };

      if (this.get('accountantSession.token')) {
        headers['Authorization'] = "Bearer ".concat(this.get('accountantSession.token'));
      } // return the headers


      return headers;
    }

  });

  _exports.default = _default;
});