define("zipbooks/components/transaction-sheet/row", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    tagName: 'tr',
    classNames: ['transaction-sheet-table-row'],
    classNameBindings: ['row.transaction.isSelected:transaction-sheet-table-row-selected', 'alternateBackground:transaction-sheet-table-row-alternate'],
    noTestIdPassthrough: true,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('row.component', this);
      this.set('row.transaction.component', this);
    },
    actions: {
      mouseEvent: function mouseEvent() {
        this.mouseEvent.apply(this, arguments);
      },
      selectRow: function selectRow() {
        this.selectRow.apply(this, arguments);
      },
      split: function split() {
        this.split.apply(this, arguments);
      }
    },
    alternateBackground: Ember.computed('row.transaction.number', function () {
      return this.get('row.transaction.number') % 2 == 1;
    }),
    numberColumnIsMerged: Ember.computed('row.transaction.splitInfo.isSplit', 'row.isMergedRow', function () {
      return this.get('row.transaction.splitInfo.isSplit') && !this.get('row.isMergedRow');
    }),
    numberColumnRowSpan: Ember.computed('row.transaction.splitInfo.lines.@each', function () {
      return this.get('row.transaction.splitInfo.lines.length') || 1;
    }),
    statusColorClass: Ember.computed('row.transaction.journalEntry.dirtyType', 'row.transaction.journalEntry.isValid', 'row.transaction.journalEntry.isNew', 'row.transaction.journalEntry.journalEntryLines.@each.dirtyType', 'row.transaction.journalEntry.journalEntryLines.@each.isValid', 'row.transaction.journalEntry.journalEntryLines.@each.isNew', function () {
      if (this.row.transaction.journalEntry.errors.length > 0) {
        return 'transaction-sheet-table-row-number-invalid';
      }

      var hasChanges = this.row.transaction.journalEntry.journalEntryLines.toArray().concat([this.row.transaction.journalEntry]).any(function (m) {
        var dirtyKeys = Object.keys(m.changedAttributes());
        return dirtyKeys.length !== 0 && !(dirtyKeys.length === 1 && ['autoConfirm', 'journalEntry'].includes(dirtyKeys.firstObject));
      });

      if (hasChanges) {
        return 'transaction-sheet-table-row-number-unsaved';
      }
    })
  });

  _exports.default = _default;
});