define("zipbooks/templates/components/drawers/-bank-member-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fmqV3e4I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[1,[22,\"message\"],false],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"loading\"],null]],null,{\"statements\":[[0,\"    \"],[15,\"partials/loading\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"login\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"id\",\"mx-widget\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"loadingWidgetUrl\"]]],null,{\"statements\":[[0,\"      \"],[15,\"partials/loading\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"drawer-notification fadeIn text-center mt-4\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon-check-circle font-size-36px green\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-bank-member-form.hbs"
    }
  });

  _exports.default = _default;
});