define("zipbooks/controllers/main/settings/subscription", ["exports", "zipbooks/utils/plans", "zipbooks/utils/later", "zipbooks/utils/need-details", "ember-data", "zipbooks/utils/compare-arrays"], function (_exports, _plans, _later, _needDetails, _emberData, _compareArrays) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    overlay: Ember.inject.service(),
    client: Ember.inject.service(),
    queryParams: ['needs'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'credit-card.save', function () {
        _this.commit();
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'credit-card.save');
    },
    modelDidLoad: function modelDidLoad() {
      var _this2 = this;

      this.set('newAccountingPlan', _plans.default.find(function (p) {
        return p.id === _this2.get('accountingSubscription.plan.id');
      }));
    },
    layout: Ember.computed('session.account.subscriptions.@each.id', function () {
      return this.session.subscriptionFlags.includes('cpa') ? 'accountant' : 'default';
    }),
    actions: {
      selectNewAccountingPlan: function selectNewAccountingPlan(selection) {
        var plan = _plans.default.find(function (p) {
          return p.key === selection.id;
        });

        this.set('newAccountingPlan', plan);
      },
      cancelAccountingPlan: function cancelAccountingPlan() {
        var _this3 = this;

        this.set('newAccountingPlan', null);
        this.save(function () {
          _this3.set('newAccountingPlan', _plans.default.find(function (p) {
            return p.id === _this3.get('accountingSubscription.plan.id');
          }));
        });
      },
      addLicense: function addLicense() {
        this.overlay.showDrawer('drawers/add-client-form');
      },
      save: function save() {
        this.save();
      }
    },

    /**********************************************
     * Top banners
     **********************************************/
    overDueSub: Ember.computed('model.account.subscriptions.@each.status', function () {
      return this.get('model.account.subscriptions').find(function (s) {
        return s.isOverdue && !s.isCanceled;
      });
    }),
    upgradeOptionDetails: Ember.computed('needs', function () {
      var needs = this.needs;
      return _needDetails.default[needs];
    }),

    /**********************************************
     * Accounting
     **********************************************/
    accountingSubscription: Ember.computed('session.subscription', function () {
      // if messaging is one of the subscriptions, we use that to represent the current accounting subscription
      var messaging = this.getWithDefault('session.account.subscriptions', []).find(function (s) {
        return s.get('plan.externalId') === 'messaging' && !s.canceledAt;
      });

      if (messaging) {
        return messaging;
      } else {
        return this.get('session.subscription');
      }
    }),
    showStarter: Ember.computed('accountingSubscription', function () {
      return !this.get('accountingSubscription.plan.id');
    }),
    zombieAccountingSubscription: Ember.computed('model.account.subscriptions.@each.{canceledAt,nextBillingDate}', function () {
      var currentPlanId = this.get('accountingSubscription.plan.id'); // if a plan has a higher id, we should let the customer know they are still subscribed to it until the next billing date

      return this.get('model.account.subscriptions').filter(function (s) {
        return ['accounting', 'cpa'].includes(s.get('plan.kind'));
      }).find(function (s) {
        var downgraded = s.get('plan.id') > (currentPlanId || 0);
        var isDeleted = !!s.get('canceledAt');
        var nextBillDateInFuture = moment(s.get('nextBillingDate')).isBetween(moment(), moment().add(1, 'years'));

        if (downgraded && isDeleted && nextBillDateInFuture) {
          return s;
        }
      });
    }),

    /**********************************************
     * Special accounting plans
     **********************************************/
    specialAccounting: Ember.computed(function () {
      return _plans.default.filter(function (p) {
        return p.exclusive || !['1', '2', '3'].includes(p.id);
      }).filter(function (p) {
        return ['accounting', 'cpa'].includes(p.kind);
      });
    }),
    showSpecialAccounting: Ember.computed('session.subscription', 'session.isStealth', 'specialAccounting.@each', function () {
      var _this4 = this;

      var isStealth = this.get('session.isStealth');
      var alreadyHasSpecial = this.specialAccounting.find(function (p) {
        return p.id === _this4.get('session.subscription.plan.id');
      });
      return isStealth || alreadyHasSpecial;
    }),
    specialAccountingWithPrices: Ember.computed('specialAccounting.@each', function () {
      return this.specialAccounting.filter(function (o) {
        return ['4', '97', '103', '300', '302'].includes(o.id);
      }).map(function (o) {
        if (o.price > 0) {
          return {
            id: o.key,
            name: "".concat(o.name, " ($").concat(o.price, "/mo)")
          };
        } else {
          return {
            id: o.key,
            name: o.name
          };
        }
      });
    }),
    currentSpecialAccountingPlan: Ember.computed('accountingSubscription.plan.id', function () {
      var _this5 = this;

      return _plans.default.find(function (p) {
        return p.id === _this5.get('accountingSubscription.plan.id');
      });
    }),

    /**********************************************
     * Summary
     **********************************************/
    costPerMonth: Ember.computed('newAccountingPlan.price', function () {
      var _this6 = this;

      var accounting = null;

      if (this.newAccountingPlan && this.newAccountingPlan.key) {
        var key;

        if (this.newAccountingPlan.id !== this.get('accountingSubscription.plan.id')) {
          key = this.newAccountingPlan.key;
        } else {
          key = [this.get('accountingSubscription.plan.externalId'), this.get('accountingSubscription.planPostfix')].compact().join('-');
        }

        accounting = {
          plan: key,
          quantity: this.accountingSubscription ? this.accountingSubscription.quantity : 1
        };
      }

      var items = [accounting].compact().filter(function (s) {
        return !Ember.isBlank(s.plan);
      });

      if (accounting && accounting.plan === 'messaging') {
        items.push({
          plan: 'accountant',
          quantity: accounting.quantity
        });
      }

      var promise = this.client.POST('subscriptions', 'preview', {
        items: items
      }).then(function (payload) {
        if (!Ember.isBlank(Ember.get(payload, 'data.attributes.total'))) {
          _this6.set('hasDiscount', !!payload.data.attributes.coupon);

          return {
            total: payload.data.attributes.total,
            amountDue: payload.data.attributes['amount-due'],
            credit: payload.data.attributes.credit,
            coupon: payload.data.attributes.coupon
          };
        } else {
          return {
            total: _this6.getWithDefault('newAccountingPlan.price', 0)
          };
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    costPerLicense: Ember.computed(function () {
      var accountant = {
        plan: 'accountant',
        quantity: 1
      };
      var messaging = {
        plan: 'messaging',
        quantity: 1
      };
      return _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.hash({
          sophisticated: this.client.POST('subscriptions', 'preview', {
            items: [accountant]
          }),
          plusMessaging: this.client.POST('subscriptions', 'preview', {
            items: [accountant, messaging]
          })
        })
      });
    }),
    nextBillingDate: Ember.computed('accountingSubscription.{status,nextBillingDate}', function () {
      var _this7 = this;

      // billing dates are the same for both subs, so we just get the first sub that has the info we want
      var found = ['accountingSubscription'].map(function (k) {
        return _this7.get(k);
      }).compact().find(function (s) {
        return s.get('status') === 'active' && moment(s.get('nextBillingDate')).isBetween(moment(), moment().add(1, 'year'));
      });

      if (found) {
        return found.get('nextBillingDate');
      }
    }),

    /**********************************************
     * Helpers
     **********************************************/
    save: function save(cancelFunc) {
      var _this8 = this;

      var message = this.confirmMessage();

      if (message) {
        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: message.title,
          confirmButton: {
            title: message.button,
            class: 'btn-blue'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            _this8.commit();
          },
          cancel: function cancel() {
            if (cancelFunc) {
              cancelFunc();
            }
          }
        });
      } else {
        // if they click save when there is nothing to save, just fake it
        this.set('buttonState', 'saving');
        (0, _later.default)(100, function () {
          _this8.set('buttonState', 'loaded');
        });
      }
    },
    commit: function commit() {
      var _this9 = this;

      this.set('buttonState', 'saving');
      var accountId = this.get('model.account.id');
      return this.client.PATCH("accounts/".concat(accountId, "/relationships/subscriptions"), null, {
        data: this.payload()
      }).then(function (_resp) {
        _this9.set('buttonState', 'loaded');

        _this9.session.fetchLoggedInUser().then(function () {
          _this9.send('refreshRoute'); // if they signed up for messaging, make them choose a twilio number


          if (_this9.session.getWithDefault('account.subscriptions', []).any(function (s) {
            return s.get('plan.id') === '303' && !s.canceledAt;
          }) && !_this9.session.get('account.settings.messagingPhone')) {
            _this9.overlay.showDialog('overlays/dialogs/choose-number-modal', '', {
              customHeader: true
            });
          }
        });
      }, function (resp) {
        _this9.set('buttonState', 'invalid');

        _this9.overlay.showBanner('error', Ember.getWithDefault(resp, 'responseJSON.errors.firstObject.detail', 'There was an error').capitalize());
      });
    },
    payload: function payload() {
      var _this10 = this;

      var planKeys = [this.newAccountingPlan]; // special for managing accountant plans

      var existingAccountant = this.getWithDefault('session.account.subscriptions', []).find(function (s) {
        return s.get('plan.kind') === 'cpa';
      });

      if (existingAccountant) {
        if (this.newAccountingPlan && this.newAccountingPlan.key === 'messaging') {
          // add messaging to the accountant plan they already had
          planKeys = [_plans.default.find(function (p) {
            return p.id === existingAccountant.get('plan.id');
          }), this.newAccountingPlan];
        } else {
          // just keep the accountant plan they already had
          planKeys = [_plans.default.find(function (p) {
            return p.id === existingAccountant.get('plan.id');
          })];
        }
      }

      return planKeys.compact().map(function (p) {
        var body = {
          type: 'subscription',
          attributes: {
            quantity: 1
          },
          relationships: {
            plan: {
              data: {
                type: 'plan',
                id: p.id
              }
            }
          } // if they already have a subscription with this plan, update it instead of replacing it

        };

        var existing = _this10.getWithDefault('session.account.subscriptions', []).find(function (s) {
          return s && !s.canceledAt && s.get('plan.id') === p.id;
        });

        if (existing) {
          body.id = existing.get('id');
        }

        if (p.kind === 'cpa' || p.kind == 'inherited') {
          body.attributes.quantity = _this10.getWithDefault('accountingSubscription.quantity', 0);
        }

        return body;
      });
    },
    planCompare: function planCompare(o, n, overdue, oldIds, newIds) {
      var change; // if didn't change

      if (!o && !n || o && n && o.id == n.id) {
        // if the current plan is overdue, no change should mean we are subscribing again
        if (overdue) {
          change = 'subscribe';
        } else if (!(0, _compareArrays.default)(oldIds, newIds)) {
          change = 'subscribe';
        } else {
          change = 'none';
        }
      } // if theres no old but theres a new, its an subscribe
      else if (!o && n) {
          change = 'subscribe';
        } else if (o && !n) {
          change = 'cancel';
        } else if (o && n && n.id > o.id) {
          change = 'upgrade';
        } else if (o && n && n.id < o.id) {
          change = 'downgrade';
        } else {
          change = 'unknown';
        }

      return {
        change: change,
        old: o,
        new: n,
        quantity: newIds.length
      };
    },
    confirmMessage: function confirmMessage() {
      var _this11 = this;

      var changes = {
        accounting: this.planCompare(_plans.default.find(function (p) {
          return p.id === _this11.get('accountingSubscription.plan.id');
        }), this.newAccountingPlan, this.get('accountingSubscription.isOverdue'), [], [])
      };
      var parts = [];
      var warning = '';
      var button = [changes.accounting.change].find(function (a) {
        return a !== 'none';
      }) || 'confirm';

      if (changes.accounting.change === 'cancel') {
        parts.push("".concat(changes.accounting.change, " the <b>").concat(changes.accounting.old.name, "</b> accounting plan"));
        button = 'confirm';
      } else if (changes.accounting.change !== 'none') {
        parts.push("".concat(changes.accounting.change, " to the <b>").concat(changes.accounting.new.name, "</b> accounting plan"));
      }

      if (parts.length > 0) {
        return {
          title: "Do you want to ".concat(parts.join(' and '), "? ").concat(warning),
          button: button.capitalize()
        };
      }
    }
  });

  _exports.default = _default;
});