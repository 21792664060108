define("zipbooks/components/suggest/chart-account-template-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/search-in"], function (_exports, _autoSuggest, _searchIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Category',
    layoutName: 'components/auto-suggest',
    filter: [],
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var results = this.templateGroup.get('templates').filter(function (t) {
        return t.classificationInfo.children;
      });
      return (0, _searchIn.default)(results, 'name', search);
    },
    labelForResult: function labelForResult(result) {
      return result.get('name');
    }
  });

  _exports.default = _default;
});