define("zipbooks/helpers/confidence-score-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confidenceScoreColor = confidenceScoreColor;
  _exports.default = void 0;

  function confidenceScoreColor(params
  /*, hash*/
  ) {
    var confidenceScore = params[0];

    if (confidenceScore >= 90) {
      return 'green';
    } else if (confidenceScore >= 80) {
      return 'gold';
    } else {
      return 'orange';
    }
  }

  var _default = Ember.Helper.helper(confidenceScoreColor);

  _exports.default = _default;
});