define("zipbooks/controllers/main/estimates", ["exports", "ember-inflector", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller"], function (_exports, _emberInflector, _controllerPagination, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    queryParams: ['status', 'contact_id'],
    status: null,
    contact_id: null,
    sort: 'date',
    archived: false,
    direction: 'desc',
    session: Ember.inject.service(),
    collection: Ember.computed('model.estimates.@each', function () {
      return this.get('model.estimates');
    }),
    actions: {
      filterByContact: function filterByContact(contact) {
        this.transitionToRoute({
          queryParams: {
            contact_id: contact && contact.get('id'),
            page: 1
          }
        });
      },
      duplicate: function duplicate(estimate) {
        var _this = this;

        this.client.send('POST', 'estimates', estimate.get('id') + '/duplicate').then(function (resp) {
          _this.store.pushPayload(resp);

          var duplicated = _this.store.peekRecord('estimate', resp.data.id);

          _this.transitionToRoute('main.estimate', duplicated, {
            queryParams: {
              editing: true
            }
          });

          _this.overlay.showBanner('success', "Your ".concat((0, _emberInflector.singularize)(_this.get('session.account.settings.estimatesCalled')), " has been duplicated."));
        }, function ()
        /*jqXHR, textStatus,errorThrown */
        {
          _this.overlay.showBanner('error', "Failed to duplicate ".concat((0, _emberInflector.singularize)(_this.get('session.account.settings.estimatesCalled')), "."));
        });
      }
    }
  });

  _exports.default = _default;
});