define("zipbooks/controllers/main/settings/payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    account: Ember.computed.alias('session.account'),
    actions: {
      savePaypal: function savePaypal() {
        this.store.peekRecord('account', this.get('account.id')).save();
      },
      selectFeeChartAccount: function selectFeeChartAccount(chartAccount) {
        this.set('model.integration.feeChartAccount', chartAccount);
      },
      save: function save() {
        var _this = this;

        this.set('statusButton', 'saving');
        this.get('model.integration').save().then(function () {
          return _this.set('statusButton', 'loaded');
        }, function () {
          return _this.set('statusButton', 'invalid');
        });
      }
    },
    onApplicationComplete: Ember.computed(function () {
      var _this2 = this;

      return function () {
        return _this2.send('refresh');
      };
    }),
    onVerificationComplete: Ember.computed('model.verification', function () {
      var _this3 = this;

      return function () {
        return _this3.set('model.verification', {
          identities: []
        });
      };
    }),
    rejected: Ember.computed('model.integration.status', function () {
      return this.model.integration && this.model.integration.status === 'rejected';
    }),
    needsVerification: Ember.computed('model.verification', function () {
      var identities = this.get('model.verification.identities');
      return identities && identities.length > 0;
    }),
    needsApplication: Ember.computed('model.integration', function () {
      return !this.model.integration;
    })
  });

  _exports.default = _default;
});