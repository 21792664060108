define("zipbooks/components/growth/associate-locations", ["exports", "zipbooks/utils/compare-locations"], function (_exports, _compareLocations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    overwrite: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('reference.locationAssociator', this);
    },
    rows: Ember.computed('externalLocations.@each', function () {
      var _this = this;

      var matched = {};
      var anyAlreadyMatched = this.externalLocations.any(function (el) {
        return !!el.get('location.id');
      });
      return this.externalLocations.map(function (l) {
        var operation = Ember.Object.create({});

        var match = _this.locations.all.find(function (internal) {
          return (0, _compareLocations.default)(internal, l);
        }); // if this external location is alread associated with an internal one, we show that


        if (l.get('location.id')) {
          matched[l.get('location.id')] = true;
          operation = Ember.Object.create({
            operationKey: 'associate',
            location: l.location.content,
            alreadyAssociated: true
          });
        } // just to be helpful, if an external and internal location have the same address info
        // we match them up
        else if (match && !matched[match.id]) {
            matched[match.id] = true;
            operation = Ember.Object.create({
              operationKey: 'associate',
              location: match
            });
          } // if this modal is being reopened, meaning they have already matched locations for this integration,
          // then we don't want to force them to choose "dont manage" a bunch of times again, so we default umatched
          // to dont-manage for them
          else if (anyAlreadyMatched) {
              operation = Ember.Object.create({
                operationKey: 'dont-manage'
              });
            }

        return {
          operation: operation,
          externalLocation: l
        };
      });
    }),
    _operations: Ember.computed('rows.@each.operation', function () {
      return this.rows.mapBy('operation');
    }),
    selectedLocationIds: Ember.computed('_operations.@each.{location,operationKey}', function () {
      if (this.get('allowManyExternalToOneInternal')) {
        return [];
      } else {
        return this.rows.map(function (r) {
          return Ember.get(r, 'operation.location');
        }).compact().mapBy('id');
      }
    }),
    save: function save() {
      var _this2 = this;

      // they cant all be 'dont-manage'
      this.set('generalError', null);

      if (this.rows.every(function (r) {
        return r.operation && r.operation.operationKey === 'dont-manage';
      })) {
        this.set('generalError', 'You must match at least one location.');
        return Ember.RSVP.reject(this.generalError);
      }

      var readyRows = this.rows.map(function (row) {
        var operation = row.operation && row.operation.operationKey;

        if (operation === 'associate' || operation === 'add-location' || operation === 'dont-manage') {
          return true;
        } else {
          row.operation.set('errors', [{
            message: 'You must select an option.'
          }]);
          return false;
        }
      });

      if (readyRows.every(function (l) {
        return l;
      })) {
        var promises = this.rows.map(function (row) {
          var operation = row.operation && row.operation.operationKey;
          var externalLocation = row.operation && row.externalLocation; // if we're associating the location, we set it on the external location
          // and save

          if (operation === 'associate') {
            var location = row.operation.location;
            externalLocation.set('location', location);

            _this2.mergeExternalOntoLocation(location, externalLocation, {
              overwrite: _this2.overwrite
            });

            return [location.save(), externalLocation.save()];
          } // if we're add a location, we have to create it first, then we associate it like above
          else if (operation === 'add-location') {
              var newLocation = _this2.store.createRecord('location');

              _this2.mergeExternalOntoLocation(newLocation, externalLocation, {
                overwrite: true
              });

              return newLocation.save().then(function (location) {
                externalLocation.set('location', location);
                return externalLocation.save();
              });
            } // if they choose dont-manage on a provider that archives those, create and archive the location
            else if (operation === 'dont-manage' && _this2.get('integration.providerInfo.archiveDontManage')) {
                var _newLocation = _this2.store.createRecord('location');

                _this2.mergeExternalOntoLocation(_newLocation, externalLocation, {
                  overwrite: true
                });

                _newLocation.set('archivedAt', new Date());

                return _newLocation.save().then(function (location) {
                  externalLocation.set('location', location);
                  return externalLocation.save();
                });
              } else {
                return [];
              }
        }).reduce(function (acc, val) {
          return acc.concat(val);
        }, []);
        return Ember.RSVP.all(promises);
      } else {
        return Ember.RSVP.reject('You must choose an option for each location.');
      }
    },
    mergeExternalOntoLocation: function mergeExternalOntoLocation(location, externalLocation) {
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      ;
      ['name', 'phone', 'website', 'address_1', 'address_2', 'city', 'state', 'postalCode', 'country'].forEach(function (key) {
        var internalValue = location.get(key);
        var externalValue = externalLocation.get(key);

        if (!Ember.isBlank(externalValue) && (Ember.isBlank(internalValue) || opts.overwrite)) {
          location.set(key, externalValue);
        }
      });
    }
  });

  _exports.default = _default;
});