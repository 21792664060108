define("zipbooks/utils/humanize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = humanize;

  function humanize(string) {
    // don't ruin SafeString objects
    if (string && typeof string !== 'string') {
      return string;
    } else {
      return (string || '').replace(/[_+-]/g, ' ');
    }
  }
});