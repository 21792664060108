define("zipbooks/routes/main/chart-accounts", ["exports", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/sort-param"], function (_exports, _indexRoute, _autoUpdatingRoute, _permissionRedirect, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_indexRoute.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    queryParams: {
      parent_id: {
        refreshModel: true
      },
      show: {
        refreshModel: true
      }
    },
    autoUpdatingEvent: 'chart-account.save',
    model: function model(params) {
      var reqParams = {
        sort: (0, _sortParam.default)(params),
        filter: {
          archived: params.archived
        }
      };
      return this.store.cachedQuery('chartAccount', reqParams);
    }
  });

  _exports.default = _default;
});