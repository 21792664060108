define("zipbooks/components/table-sort-link", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    oppositeSortDirection: Ember.computed('direction', function () {
      return !this.direction || this.direction === 'asc' ? 'desc' : 'asc';
    }),
    titleText: Ember.computed('attribute', 'title', function () {
      return this.title || this.attribute;
    }),
    isActive: Ember.computed('attribute', 'sort', 'direction', function () {
      return this.sort === this.attribute;
    })
  });

  _exports.default = _default;
});