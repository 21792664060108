define("zipbooks/routes/main/integrations/index", ["exports", "zipbooks/mixins/integrations/square-onboarding-route"], function (_exports, _squareOnboardingRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_squareOnboardingRoute.default, {
    client: Ember.inject.service(),
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments); // square requires us to check the token is valid every time the user views the page


      if (this.get('session.account.integrations.square')) {
        this.client.send('POST', 'integrations', 'square/renew').then(function () {
          return _this.session.fetchLoggedInUser();
        });
      }
    },
    model: function model(_params) {
      return Ember.RSVP.hash({
        integrations: this.integrationsPromise()
      });
    }
  });

  _exports.default = _default;
});