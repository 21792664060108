define("zipbooks/templates/components/overlays/dialogs/action-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aHUOOYx+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"data-test-id\",\"title\"],[8],[1,[28,\"safe\",[[24,[\"model\",\"title\"]]],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"warning\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"data-test-id\",\"warning\"],[8],[1,[24,[\"model\",\"warning\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[28,\"status-button\",null,[[\"type\",\"class\",\"action\",\"state\",\"title\",\"testId\"],[\"submit\",[28,\"concat\",[\"btn btn-full btn-depressable \",[24,[\"model\",\"confirmButton\",\"class\"]]],null],[28,\"action\",[[23,0,[]],\"confirm\"],null],[24,[\"buttonState\"]],[24,[\"model\",\"confirmButton\",\"title\"]],\"confirm-button\"]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-full \",[24,[\"model\",\"cancelButton\",\"class\"]]]]],[12,\"title\",\"Cancel\"],[12,\"data-test-id\",\"cancel-button\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[24,[\"model\",\"cancelButton\",\"title\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/overlays/dialogs/action-confirm.hbs"
    }
  });

  _exports.default = _default;
});