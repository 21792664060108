define("zipbooks/controllers/main/chart-accounts", ["exports", "zipbooks/mixins/index-controller", "zipbooks/mixins/list-controller-selectable", "zipbooks/mixins/bulk-operation", "zipbooks/utils/throttle", "zipbooks/utils/is-control-key"], function (_exports, _indexController, _listControllerSelectable, _bulkOperation, _throttle, _isControlKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_indexController.default, _listControllerSelectable.default, _bulkOperation.default, {
    queryParams: ['parent_id'],
    client: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    session: Ember.inject.service(),
    parent_id: null,
    sort: 'type',
    archived: false,
    modelType: 'chart-account',
    collection: Ember.computed.alias('model'),
    actions: {
      updateSearch: function updateSearch(_, event) {
        if (!(0, _isControlKey.default)(event.which)) {
          (0, _throttle.default)(this, 'updateResultsMatching', 1000);
        }
      },
      filterByParentChartAccount: function filterByParentChartAccount(chartAccount) {
        this.transitionToRoute({
          queryParams: {
            parent_id: chartAccount && chartAccount.get('id'),
            page: 1
          }
        });
      },
      import: function _import(e) {
        var field = e.target;
        var file = field.files.length > 0 ? field.files[0] : (field.testingfiles || [])[0];
        var formData = new FormData();
        formData.append('chart_accounts', file);

        if (file) {
          if ((file.name || '').toLowerCase().split('.').pop() === 'csv') {
            var self = this;
            this.client.upload('chart_accounts', 'import', formData, {
              success: function success()
              /*result*/
              {
                self.get('overlay').showBanner('success', 'Your chart accounts are now importing and will display soon.');
                self.get('urlCache').clear();
                self.send('refreshRoute');
              },
              failed: function failed(_status, json) {
                self.get('overlay').showBanner('error', json, {
                  title: 'Whoops!'
                });
              },
              error: function error(_error) {
                self.get('overlay').showBanner('error', _error, {
                  title: 'Whoops!'
                });
              }
            });
          } else {
            this.overlay.showBanner('error', 'File must be a CSV.', {
              title: 'Whoops!'
            });
          }
        } else {
          this.overlay.showBanner('error', 'You must select a file.', {
            title: 'Oops!'
          });
        }
      },
      bulkUpdateParent: function bulkUpdateParent(chartAccount) {
        var _this = this;

        var ids = this.selected.mapBy('id');

        if (ids.length > 0 && chartAccount && chartAccount.get('id')) {
          this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
            title: "Are you sure you want to update ".concat(ids.length, " categories to ").concat(chartAccount.get('name'), "?"),
            confirmButton: {
              title: 'Yes, update',
              class: 'btn-blue'
            },
            cancelButton: {
              title: 'No, cancel',
              class: 'btn-white'
            }
          }, {
            confirm: function confirm() {
              _this.bulk(ids.map(function (id) {
                return _this.store.peekRecord('chart-account', id);
              }), function (model) {
                model.set('parent', chartAccount);
                return model.save();
              }, function (result) {
                result.failed.forEach(function (m) {
                  return m.rollbackAttributes();
                });

                _this.unselectAll();

                _this.set('filteredChartAccount', null);

                _this.showBulkBanner('updated', result.succeeded.length, result.unchanged.length, result.failed.length, 'categories');
              });
            },
            cancel: function cancel() {}
          });
        }
      }
    },
    updateResultsMatching: function updateResultsMatching() {
      var _this2 = this;

      Ember.run.next(function () {
        _this2.set('isRefreshing', true);

        var reqParams = {
          sort: 'type',
          filter: {
            archived: false
          }
        };

        if (_this2.searchText) {
          reqParams.filter.name = _this2.searchText;
        }

        _this2.store.cachedQuery('chartAccount', reqParams).then(function (models) {
          _this2.set('model', models);

          _this2.set('isRefreshing', false);
        });
      });
    }
  });

  _exports.default = _default;
});