define("zipbooks/helpers/file-type-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fileTypeIcon = fileTypeIcon;
  _exports.default = void 0;

  function fileTypeIcon(params
  /*, hash*/
  ) {
    var fileTypesIconMap = {
      pdf: 'icon-file-pdf red',
      xlsx: 'icon-file-excel green',
      xls: 'icon-file-excel green',
      txt: 'icon-file-text grey',
      ppt: 'icon-file-powerpoint red',
      pptx: 'icon-file-powerpoint red',
      zip: 'icon-file-zip gray',
      psd: 'icon-file-photoshop blue',
      bmp: 'icon-file-format-bitmap blue',
      gif: 'icon-file-format-gif blue',
      jpg: 'icon-file-format-jpg blue',
      png: 'icon-file-format-png blue',
      svg: 'icon-file-format-svg blue',
      csv: 'icon-file-format-csv green'
    };
    var fileType = (params[0] || '').split('.').pop();

    if (typeof fileTypesIconMap[fileType] === 'undefined') {
      return 'icon-file-new-1';
    } else {
      return fileTypesIconMap[fileType];
    }
  }

  var _default = Ember.Helper.helper(fileTypeIcon);

  _exports.default = _default;
});