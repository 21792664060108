define("zipbooks/templates/components/drawers/-affini-pay-integration-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lXdbXFG2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[1,[28,\"custom-select\",null,[[\"value\",\"placeholder\",\"label\",\"didSelect\",\"options\"],[[24,[\"model\",\"chargeAccount\"]],\"Charge account\",true,[28,\"action\",[[23,0,[]],\"setCurrency\"],null],[24,[\"chargeAccountOptions\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row table-border-row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-border-col col-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-size-14px gray\"],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"blue\"],[3,\"action\",[[23,0,[]],\"disconnect\"]],[8],[0,\"Disconnect \"],[1,[22,\"name\"],false],[9],[0,\"\\n        \"],[4,\"tool-tip\",null,[[\"class\"],[\"top ml-1\"]],{\"statements\":[[0,\"Revoke access to \"],[1,[22,\"name\"],false],[0,\". Your data will no longer sync.\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"action\",\"model\",\"title\",\"class\",\"testId\"],[[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null],[24,[\"model\"]],\"Save\",\"btn btn-full btn-blue btn-depressable\",\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-affini-pay-integration-form.hbs"
    }
  });

  _exports.default = _default;
});