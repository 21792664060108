define("zipbooks/utils/scrolling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.smoothScroll = smoothScroll;
  _exports.immediateScroll = immediateScroll;
  var buffer = 20;

  function getScrollDestination(element) {
    var topAdjustment = $('nav').height() + buffer;
    return element.offset().top - topAdjustment;
  } // the animation will take at most maxScrollTimeMs if there is one or more screen
  // height of scrolling. If there's less it will scroll at the same speed that
  // the screen would scroll if scrolling the full height and no more. So scrolling 25%
  // of the height of the screen would take 25% of maxScrollTimeMs


  function smoothScroll(element, maxScrollTimeMs) {
    return new Ember.RSVP.Promise(function (resolve) {
      var body = $('body');
      var destination = getScrollDestination(element);
      var currentLocation = body.scrollTop();
      var difference = Math.abs(currentLocation - destination);
      var percentScreenChange = difference / body.height();
      var animationDuration = Math.max(1, percentScreenChange) * (maxScrollTimeMs || 1000);
      body.animate({
        scrollTop: destination
      }, animationDuration, function () {
        return resolve();
      });
    });
  }

  function immediateScroll(element) {
    var destination = getScrollDestination(element);
    $('body').get(0).scrollTop = destination;
  }
});