define("zipbooks/components/overlays/popovers/tax-form", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/parse-number", "zipbooks/utils/later", "zipbooks/mixins/paid-modal-handler"], function (_exports, _testable, _parseNumber, _later, _paidModalHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _paidModalHandler.default, {
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    actions: {
      done: function done() {
        var startDate = this.get('model.lineItem.startDate');
        var endDate = this.get('model.lineItem.endDate');
        var validated = true;

        if (startDate !== undefined && endDate !== undefined) {
          if (moment(startDate) > moment(endDate)) {
            validated = false;
          }
        }

        if (validated) {
          this.close();
        } else {
          this.overlay.showBanner('error', 'End date must be after start date.');
        }
      },
      saveAsItem: function saveAsItem() {
        var _this = this;

        var item = this.store.createRecord('item', {
          name: this.get('model.lineItem.name'),
          description: this.get('model.lineItem.notes'),
          price: this.get('model.lineItem.rate')
        });
        this.set('itemModel', item);
        item.save().then(function () {
          (0, _later.default)(500, function () {
            _this.close();
          });
        }, function (error) {
          if (Ember.get(error, 'errors.firstObject.source.pointer') === '/data/attributes/name') {
            _this.overlay.showBanner('error', Ember.String.htmlSafe('Please add a <b>title</b> and a <b>price/rate</b> before saving this line as an Item.'));
          }
        });
      },
      selectFirstTax: function selectFirstTax(tax) {
        this.setTax(tax, '0');
      },
      selectSecondTax: function selectSecondTax(tax) {
        this.setTax(tax, '1');
      },
      addNewTax: function addNewTax(label, lineItem, position) {
        this.get('callbacks.addNewTax')(label, lineItem, position);
        this.close();
      },
      showTaxesDrawer: function showTaxesDrawer() {
        this.get('callbacks.showTaxesDrawer')();
        this.close();
      },
      formatDiscount: function formatDiscount() {
        this.set('model.lineItem.discount', (0, _parseNumber.default)(this.get('model.lineItem.discount')).round(2).toFixed(2));
      },
      selectChartAccount: function selectChartAccount(chartAccount) {
        this.model.lineItem.set('chartAccount', chartAccount);
      },
      syncTaxesChanged: function syncTaxesChanged() {
        var _this2 = this;

        Ember.run.scheduleOnce('afterRender', this, function () {
          if (_this2.model.lineItemable.syncTaxes) {
            _this2.get('callbacks.syncLineItemTaxes')(_this2.model.lineItem.taxes);
          }
        });
      }
    },
    setTax: function setTax(tax) {
      var position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0';
      var taxes = this.get('model.lineItem.taxes') || [];

      if (position === '0') {
        this.set('model.lineItem.taxes', [tax, taxes.objectAt(1) || null]);
      } else {
        this.set('model.lineItem.taxes', [taxes.objectAt(0) || null, tax]);
      }

      if (this.model.lineItemable.syncTaxes) {
        this.get('callbacks.syncLineItemTaxes')(this.model.lineItem.taxes);
      }
    }
  });

  _exports.default = _default;
});