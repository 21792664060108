define("zipbooks/components/send-a-file", ["exports", "zipbooks/components/documents-upload"], function (_exports, _documentsUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentsUpload.default.extend({});

  _exports.default = _default;
});