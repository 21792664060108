define("zipbooks/templates/components/documents-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BG5T1mCN",
    "block": "{\"symbols\":[\"uploader\"],\"statements\":[[4,\"each\",[[24,[\"uploaders\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"state\"]],\"preparing\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"file-upload__status--preparing\"],[8],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"state\"]],\"uploading\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"document-file-progress\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"file-progress__bar\"],[11,\"style\",[23,1,[\"progressStyle\"]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"state\"]],\"success\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"document-file-progress\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"file-progress__bar\"],[10,\"style\",\"width: 100%;\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"state\"]],\"error\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"file-upload__status--error\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"state\"]],\"invalid\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"class\",\"attachment-file\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"file-upload__status--error\"],[8],[0,\"Too big. Must be less than 5mb.\"],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon-bin-1\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clearError\",[23,1,[]]],null]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/documents-upload.hbs"
    }
  });

  _exports.default = _default;
});