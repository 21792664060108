define("zipbooks/components/dashboard/active-mrr", ["exports", "zipbooks/utils/graph-colors"], function (_exports, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    trialText: Ember.computed(function () {
      return this.session.canHaveTrial('intelligence-basic') ? 'Try Smarter free' : 'Upgrade';
    }),
    sortedCustomers: Ember.computed('data.@each', function () {
      var customers = this.data.filter(function (c) {
        return c.total * 1 > 0;
      });
      return customers.sort(function (a, b) {
        return b.total - a.total;
      });
    }),
    graphCustomers: Ember.computed('sortedCustomers.@each', function () {
      var customers = this.sortedCustomers.slice(0, 100);
      var total = customers.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
      var colorIndex = 0;
      return customers.slice(0, 100).map(function (customer) {
        if (customer.customer_name === 'Unassigned') {
          Ember.set(customer, 'backgroundColor', '#BEC5CB');
        } else {
          Ember.set(customer, 'backgroundColor', _graphColors.default.cycle[colorIndex % _graphColors.default.cycle.length]);
          colorIndex += 1;
        }

        Ember.set(customer, 'percent', customer.total * 1 / total * 100);
        return customer;
      });
    }),
    total: Ember.computed('data.@each', function () {
      return this.data.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
    }),
    topCustomers: Ember.computed('sortedCustomers.@each', function () {
      if (this.get('sortedCustomers.length') > 6) {
        var other = this.sortedCustomers.slice(6).reduce(function (acc, c) {
          return acc + c.total * 1;
        }, 0);
        return this.sortedCustomers.slice(0, 5).concat([{
          backgroundColor: '#E8EAEE',
          customer_name: 'Other',
          total: other
        }]);
      } else {
        return this.sortedCustomers;
      }
    }),
    actions: {
      view: function view(customer) {
        this.router.transitionTo('main.recurring-profiles', {
          queryParams: {
            contact_id: customer.contact_id
          }
        });
      }
    }
  });

  _exports.default = _default;
});