define("zipbooks/routes/main/transaction-sheet", ["exports", "zipbooks/mixins/partial-page-refresh", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/push-payload"], function (_exports, _partialPageRefresh, _tagFilterableRoute, _permissionRedirect, _pushPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_partialPageRefresh.default, _tagFilterableRoute.default, _permissionRedirect.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    queryParams: {
      chart_account_id: {
        refreshModel: true
      },
      confirmed: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.set('zipbooksBalance', 0);
    },
    model: function model(params) {
      var _this = this;

      var models = {
        chartAccounts: this.session.chartAccountsPromise.then(function () {
          return _this.store.peekAll('chartAccount');
        }) // if viewing with filter of chart account, show journal entry lines for that chart account

      };

      if (params.chart_account_id && params.chart_account_id !== 'in-transit') {
        this.set('params', this.getParams(params));
        models.lines = this.store.cachedQuery('journalEntryLine', this.params);
        models.balances = this.session.chartAccountsPromise.then(function () {
          var chartAccount = _this.store.peekRecord('chartAccount', params.chart_account_id);

          if (chartAccount.get('bankAccount.id')) {
            return _this.client.cached('GET', "bank-accounts/".concat(chartAccount.get('bankAccount.id'), "/balances?date=").concat(_this.params.filter.end_date));
          }
        });
      } // if not viewing by chart account, fetch journal entries and use heuristic to choose how to display
      else {
          this.set('params', this.getParams(params));
          models.lines = this.store.cachedQuery('journalEntry', this.params);
        }

      return Ember.RSVP.hash(models).then(function (result) {
        return Ember.Object.create(result);
      });
    },
    afterModel: function afterModel(_model) {
      var _this2 = this;

      this._super.apply(this, arguments); // preload tags


      this.client.cached('GET', 'taggables', null, 10 * 60 * 1000
      /* 10 mins */
      ); // preload historical transaction data

      this.client.cached('GET', 'journal-entry-lines/historical', null, 10 * 60 * 1000
      /* 10 mins */
      ).then(function (entries) {
        return (0, _pushPayload.default)(_this2.store, 'historical-journal-entry-line', $.extend(true, [], entries));
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        controller.setup();
      });
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller.set('chart_account_id', null);
        controller.set('direction', 'desc');
        controller.set('messages_label_id', null);
        controller.set('start_date', moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
        controller.set('end_date', moment().endOf('month').format('YYYY-MM-DD'));
      }
    },
    deactivate: function deactivate() {
      this._super();

      $('body').off('.sheet');
    },
    getParams: function getParams(params) {
      var result = {
        // we don't support this in the json-api implementation in phoenix yet
        // include: ['invoices', 'customers', 'journal_entries', 'chart_accounts'].join(','),
        page: {
          page: params.page || 1,
          'page-size': 5000
        },
        sort: '-date',
        filter: {}
      };

      if (params.chart_account_id) {
        result.filter.chart_account_id = params.chart_account_id;
      }

      if (params.confirmed == 'confirmed') {
        result.filter.confirmed = true;
      }

      if (params.confirmed == 'unconfirmed') {
        result.filter.confirmed = false;
      }

      if (params.start_date) {
        result.filter.start_date = params.start_date;
      }

      if (params.end_date) {
        result.filter.end_date = params.end_date;
      }

      if (params.source) {
        result.filter.source = params.source;
      }

      if (params.tags) {
        result.filter.tags = this.paramTags(params);
      }

      return result;
    }
  });

  _exports.default = _default;
});