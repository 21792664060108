define("zipbooks/components/nav-side-bar", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/adjust-nav-position", "zipbooks/utils/overridable-computed"], function (_exports, _testable, _adjustNavPosition, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    mapping: {
      'settings.admin': 'settings',
      'settings.company': 'settings',
      'settings.payments': 'settings',
      'settings.preferences': 'settings',
      'settings.subscription': 'settings',
      'settings.team': 'settings',
      documents: 'settings',
      locations: 'settings',
      location: 'settings',
      integrations: 'settings',
      'accept-payments': 'settings',
      'reports.income-statement': 'reports.financial-statements',
      'reports.income-statement-percent-of-sales': 'reports.financial-statements',
      'reports.balance-sheet': 'reports.financial-statements',
      'reports.cash-flow-statement': 'reports.financial-statements',
      'reports.taxes-invoiced': 'reports.accounting-tax',
      'reports.general-journal': 'reports.accounting-tax',
      'reports.general-ledger': 'reports.accounting-tax',
      'reports.trial-balance': 'reports.accounting-tax',
      'reports.accounts-receivable': 'reports.sales',
      'reports.client-report': 'reports.sales',
      'reports.invoice-summary': 'reports.sales',
      'reports.sales-summary': 'reports.sales',
      'reports.credit-card-processing': 'reports.sales',
      'reports.expense-summary': 'reports.expenses',
      'reports.contractor-summary': 'reports.expenses',
      'reports.billable-expenses': 'reports.expenses',
      'reports.user-summary': 'reports.teams-tracking',
      'reports.time-tracking': 'reports.teams-tracking',
      reports: 'reports',
      invoices: 'invoices',
      invoice: 'invoices',
      estimates: 'invoices',
      estimate: 'invoices',
      'recurring-profiles': 'invoices',
      'recurring-profile': 'invoices',
      items: 'invoices',
      reminders: 'invoices',
      transactions: 'transactions',
      'recurring-transactions': 'transactions',
      'transaction-sheet': 'transactions',
      'bank-accounts': 'transactions',
      reconciliations: 'transactions',
      reconciliation: 'transactions',
      'chart-accounts': 'transactions',
      labels: 'transactions',
      bills: 'transactions',
      bill: 'transactions',
      'accountant.dashboard': 'accountant',
      'accountant.clients': 'accountant',
      contacts: 'contacts',
      contact: 'contacts',
      projects: 'tracking',
      project: 'tracking',
      'time-entries': 'tracking',
      'time-and-pay': 'tracking',
      dashboard: 'dashboard',
      '': 'dashboard'
    },
    inAnimation: 'navSlideInRight',
    routeCategory: (0, _overridableComputed.default)('routeCategory', 'router.currentPath', function () {
      return this.categoryFromRouteName(this.router.currentPath);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.resize();
      var router = this.router;
      router.addObserver('currentPath', this, this._routeDidChange);
    },
    actions: {
      activateReports: function activateReports() {
        this.set('routeCategory', 'reports');
        this.resize();
      },
      back: function back(category) {
        this.set('inAnimation', 'navSlideInLeft');
        this.set('routeCategory', category || 'dashboard');
        this.resize();
      },
      drill: function drill(category) {
        this.set('inAnimation', 'navSlideInRight'); // switch to that UI

        this.set('routeCategory', category);
        this.resize(); // route to the first link

        var router = this.router;

        switch (category) {
          case 'invoices':
            if (this.permissions.can('invoices.read')) {
              return router.transitionTo('main.invoices');
            } else {
              return router.transitionTo('main.estimates');
            }

          case 'accountant':
            return router.transitionTo('main.accountant.dashboard');

          case 'transactions':
            if (this.permissions.can('accounting.read')) {
              return router.transitionTo('main.transactions');
            } else {
              return router.transitionTo('main.bank-accounts');
            }

          case 'reports':
            return router.transitionTo('main.reports.income-statement');

          case 'tracking':
            if (this.permissions.can('time_tracking.read')) {
              return router.transitionTo('main.time-entries');
            } else if (this.permissions.can('projects.read')) {
              return router.transitionTo('main.projects');
            }

            break;

          case 'contacts':
            return router.transitionTo('main.contacts');

          case 'settings':
            if (this.permissions.can('account.read')) {
              return router.transitionTo('main.settings');
            } else {
              return router.transitionTo('main.settings.team');
            }

        }
      }
    },
    showMain: Ember.computed('routeCategory', 'expandContacts', function () {
      return this.routeCategory === 'dashboard' || this.routeCategory === 'contacts' && !this.expandContacts;
    }),
    showAccountant: Ember.computed('session.subscription.plan.id', function () {
      return this.get('session.subscription.plan.kind') === 'cpa';
    }),
    resize: function resize() {
      var _this = this;

      // wait a bit for that to render, then resize to new content
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (_this.$('.box')) {
          _this.$('.box').animate({
            height: _this.$('.box-list').height()
          }, 200, 'easeOutBack');
        }
      });
    },
    categoryFromRouteName: function categoryFromRouteName(routeName) {
      var currentPath = routeName.replace(/main\./, '');
      var mapping = this.mapping;

      for (var route in mapping) {
        if (currentPath.match(new RegExp("".concat(route, "$|").concat(route, ".index$")))) {
          return mapping[route];
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.router.removeObserver('currentPath', this, this._routeDidChange);
    },
    _routeDidChange: function _routeDidChange() {
      var routeName = this.router.currentPath;

      if (routeName.indexOf('loading') === -1) {
        var category = this.categoryFromRouteName(routeName);
        this.set('routeCategory', category);
        this.resize();
      }

      (0, _adjustNavPosition.default)();
    }
  });

  _exports.default = _default;
});