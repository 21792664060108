define("zipbooks/components/history-item", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    tagName: 'li',
    classNameBindings: ['row', 'statusClass'],
    statusClass: Ember.computed('model', function () {
      var action = this.get('model.action');

      switch (action) {
        case 'sent':
        case 'open':
          return 'blue-border';

        case 'accepted':
          return 'green-border';

        default:
          return 'gray-border';
      }
    }),
    message: Ember.computed('model', function () {
      var action = this.get('model.action');

      switch (action) {
        case 'estimate_converted':
          return "This estimate was converted.";

        case 'open':
          return "This estimate was finalized.";

        default:
          return "This estimate was ".concat(action, ".");
      }
    })
  });

  _exports.default = _default;
});