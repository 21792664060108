define("zipbooks/controllers/register", ["exports", "zipbooks/mixins/social-login-controller", "zipbooks/config/environment", "zipbooks/utils/plans", "zipbooks/utils/redirect", "zipbooks/helpers/current-url"], function (_exports, _socialLoginController, _environment, _plans, _redirect, _currentUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_socialLoginController.default, {
    queryParams: ['plan', 'redirectUrl', 'after', 's', 'partner'],
    plan: null,
    partner: null,
    client: Ember.inject.service(),
    app: _environment.default.modulePrefix,
    session: Ember.inject.service(),
    includeThe: Ember.computed('partner', function () {
      return this.partner.slice(0, 10) === 'University';
    }),
    // if source starts with a number, jaren is signing someone up at a conference and wants to
    // collect their phone and attach to their accoount
    collectPhoneNumber: Ember.computed('s', function () {
      return /^\d.*$/.test(this.s || '');
    }),
    actions: {
      signup: function signup() {
        var _this = this;

        this.set('buttonState', 'saving');
        var body = {
          data: {
            type: 'account',
            attributes: {
              name: this.get('model.name'),
              email: this.get('model.email'),
              phone: this.get('model.phone'),
              password: this.get('model.password'),
              source: this.get('s')
            },
            relationships: {
              subscriptions: {
                data: [{
                  type: 'subscription',
                  attributes: {
                    quantity: this.planId() === '302' ? 0 : 1
                  },
                  relationships: {
                    plan: {
                      data: {
                        type: 'plan',
                        id: this.planId()
                      }
                    }
                  }
                }]
              }
            }
          }
        };
        this.client.POST('accounts', '', body).then(function (resp) {
          _this.set('buttonState', 'loaded');

          _this.session.login(resp.meta.token);

          if (_this.after === 'square-setup') {
            window.location.href = "/v2/auth-link/square?callback-url=".concat((0, _currentUrl.currentUrl)([], {}));
          } else if (_this.redirectUrl) {
            (0, _redirect.default)(_this.redirectUrl + '&infoField1=' + encodeURIComponent(resp.data.attributes['default-bcc-address']));
          } else {
            _this.transitionToRoute('main');
          }
        }, function (jqXHR) {
          _this.set('buttonState', 'invalid');

          var errors = jqXHR.responseJSON.errors.reduce(function (acc, e) {
            if (e.source) {
              var key = e.source.pointer.substr(e.source.pointer.lastIndexOf('/') + 1);
              acc[key] = [{
                message: e.title
              }];
            }

            return acc;
          }, {});

          _this.set('errors', errors);
        });
      }
    },
    planId: function planId() {
      var _this2 = this;

      var plan = _plans.default.find(function (p) {
        return p.key == _this2.plan;
      });

      if (Ember.isPresent(plan)) {
        return plan.id;
      } else {
        return 1;
      }
    }
  });

  _exports.default = _default;
});