define("zipbooks/utils/classification-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /*
      Used to limit the chart account select on transfer drawer
    */
    'owners-equity-account-filter': 'oeaf',

    /*
      Used to determine when to open the deposit drawer.
      If lines have chart accounts with this tag, etc
    */
    'deposit-drawer-open-equity': 'ddoe',

    /*
      Used to determine when to open the deposit drawer.
      If lines have chart accounts with this tag, etc
    */
    'deposit-drawer-open-revenue': 'ddor',

    /*
      Used to determine when to open the deposit drawer.
      If lines have chart accounts with this tag, etc
    */
    'deposit-drawer-open-ar': 'ddoar',

    /*
      Used to limit the chart account select on the deposit drawer
    */
    'deposit-drawer-account-filter': 'ddaf',

    /*
      Used to determine when to open the expense drawer.
      If lines have chart accounts with this tag, etc
    */
    'expense-drawer-open-equity': 'xdoe',

    /*
      Used to determine when to open the expense drawer.
      If lines have chart accounts with this tag, etc
    */
    'expense-drawer-open-expense': 'xdox',

    /*
      Used to limit the chart account select on the expense
    */
    'expense-drawer-account-filter': 'xdaf',

    /*
      Used to limit the chart account select on bill line items
    */
    'bill-item-account-filter': 'biaf'
  };
  _exports.default = _default;
});