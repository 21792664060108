define("zipbooks/components/nav-side-bar/nav-link", ["exports", "zipbooks/utils/route-info"], function (_exports, _routeInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNameBindings: ['isAllowed::hidden'],
    tagName: '',
    session: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // merge passed-in properties onto default properties


      var info = $.extend(true, {}, (0, _routeInfo.default)()[this.route], this.getProperties(['title', 'color', 'class', 'icon', 'currentWhen', 'badgeClass']));
      this.set('routeInfo', info); // `permissions` is injected by an initializer, so it conflicts

      var properties = $.extend(true, {}, info);
      delete properties.permissions;
      this.setProperties(properties);
    },
    upgradeOrPrivilege: Ember.computed('upgrade', 'privilege', function () {
      return this.upgrade || this.privileges.firstObject;
    }),
    currentWhenOrRoute: Ember.computed('currentWhen', 'route', function () {
      return this.currentWhen || 'main.' + this.route;
    }),
    isAllowed: Ember.computed('session.currentUser.permissionsMap', 'routeInfo', function () {
      var _this = this;

      if (this.routeInfo.privileges && this.routeInfo.hideUpgrade && !this.routeInfo.privileges.any(function (n) {
        return _this.session.hasPrivilege(n);
      })) {
        return false;
      }

      if (this.routeInfo.anyPermissions) {
        return this.routeInfo.anyPermissions.any(function (p) {
          return _this.permissions.can(p);
        });
      } else if (this.routeInfo.permissions) {
        return this.routeInfo.permissions.every(function (p) {
          return _this.permissions.can(p);
        });
      } else {
        return false;
      }
    }),
    secondaryLinkInfo: Ember.computed('secondaryLink', function () {
      return (0, _routeInfo.default)()[this.secondaryLink];
    }),
    secondaryIsAllowed: Ember.computed('secondaryLinkInfo', 'session.currentUser.permissionsMap', function () {
      var _this2 = this;

      if (this.secondaryLinkInfo && this.secondaryLinkInfo.anyPermissions) {
        return this.secondaryLinkInfo.anyPermissions.any(function (p) {
          return _this2.permissions.can(p);
        });
      } else if (this.secondaryLinkInfo && this.secondaryLinkInfo.permissions) {
        return this.secondaryLinkInfo.permissions.every(function (p) {
          return _this2.permissions.can(p);
        });
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});