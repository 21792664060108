define("zipbooks/templates/components/auto-resize-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kjutl+wT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"textarea\",null,[[\"rows\",\"value\",\"title\",\"keyUp\",\"class\",\"maxlength\",\"testId\"],[1,[24,[\"value\"]],[24,[\"title\"]],[28,\"action\",[[23,0,[]],\"adjust\"],[[\"value\"],[\"target\"]]],\"auto-resize-textarea\",[24,[\"maxlength\"]],[24,[\"testId\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/auto-resize-textarea.hbs"
    }
  });

  _exports.default = _default;
});