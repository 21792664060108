define("zipbooks/components/mini-tracker/default-tracker", ["exports", "zipbooks/mixins/time-tracking", "zipbooks/mixins/clicked-outside"], function (_exports, _timeTracking, _clickedOutside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_timeTracking.default, _clickedOutside.default, {
    miniTrackerTicker: Ember.inject.service(),
    store: Ember.inject.service(),
    ticker: Ember.computed.alias('miniTrackerTicker'),
    classNames: 'mini-tracker',
    classNameBindings: ['loaded::hidden'],
    loaded: false,
    showEditorForTimeEntryId: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.getTodaysTime();
      this.checkForUnfinished().then(function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('loaded', true);
        }
      });
      this.setupWebSocket(this.get('session.token'));
    },
    willDestroyElement: function willDestroyElement() {
      this.ticker.stop();
    },
    afterStart: function afterStart() {
      this.open();
    },
    stop: function stop() {
      this._super.apply(this, arguments);

      this.getTodaysTime();
      this.open();
    },

    /*
     * ClickedOutsideMixin
     */
    outsideClickTriggerElement: function outsideClickTriggerElement() {
      return this.$('.mini-tracker-ticker-time, .mini-tracker-summary-spacer');
    },
    didClick: function didClick() {
      this.$('.mini-tracker-detail').slideToggle(200);
    },
    didClickOutside: function didClickOutside() {
      this.$('.mini-tracker-detail').slideUp(200);
    },
    open: function open() {
      this.setupOutsideClickListener();
      this.$('.mini-tracker-detail').slideDown(200);
    }
  });

  _exports.default = _default;
});