define("zipbooks/components/transaction-sheet/suggest/name-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/mixins/transaction-sheet/auto-suggest", "zipbooks/utils/search-in", "zipbooks/utils/uniq-by", "zipbooks/utils/array", "zipbooks/utils/transaction-sheet/line-sorter"], function (_exports, _autoSuggest, _autoSuggest2, _searchIn, _uniqBy, _array, _lineSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend(_autoSuggest2.default, {
    placeholder: 'Name',
    layoutName: 'components/auto-suggest',
    displayArbitrary: true,
    allowArbitrary: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('input').attr('maxlength', 255);
    },
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var _this = this;

      var history = this.store.peekAll('historical-journal-entry-line');
      var groupedByJournalEntry = (0, _array.groupBy)(history, function (v) {
        return v.journalEntryId;
      });

      if (this.get('filterByAccount.id')) {
        history = history.filterBy('enabled').filter(function (line) {
          var lines = groupedByJournalEntry[line.journalEntryId];
          return lines.any(function (l) {
            return l.get('chartAccount.id') === _this.get('filterByAccount.id');
          });
        });
      }

      groupedByJournalEntry = (0, _array.groupBy)(history, function (v) {
        return v.journalEntryId;
      });
      history = Object.keys(groupedByJournalEntry).map(function (k) {
        var lines = groupedByJournalEntry[k];
        return lines.sort(_lineSorter.default).lastObject;
      });
      history = (0, _uniqBy.default)(history, function (l) {
        return "".concat(l.amount, "-").concat(l.get('chartAccount.name'));
      });
      return (0, _searchIn.default)(history, 'name', search).filter(function (l) {
        return !Ember.isBlank(l.name);
      }).map(function (item) {
        return {
          id: item.name,
          name: item.name,
          amount: item.amount,
          chartAccount: item.chartAccount,
          date: moment(item.date).format('MM/DD/YYYY')
        };
      });
    },
    labelForResult: function labelForResult(result) {
      return result.name;
    }
  });

  _exports.default = _default;
});