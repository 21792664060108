define("zipbooks/models/user", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/mixins/taggable", "zipbooks/mixins/actorable", "zipbooks/mixins/notificationable", "zipbooks/mixins/recipientable"], function (_exports, _model, _model2, _taggable, _actorable, _notificationable, _recipientable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_taggable.default, _actorable.default, _notificationable.default, _recipientable.default, {
    accountant: (0, _model.attr)('boolean'),
    dateJoined: (0, _model.attr)('date'),
    hourlyCost: (0, _model.attr)({
      example: 5
    }),
    hourlyRate: (0, _model.attr)({
      example: 5
    }),
    isTeamMember: (0, _model.attr)('boolean'),
    name: Ember.computed.alias('identity.name'),
    owner: (0, _model.attr)('boolean'),
    permissionsMap: (0, _model.attr)(),
    phone: (0, _model.attr)(),
    title: (0, _model.attr)(),
    userHash: (0, _model.attr)(),
    values: (0, _model.attr)(),
    identity: (0, _model.belongsTo)('identity'),
    account: (0, _model.belongsTo)('account', {
      inverse: 'users'
    }),
    teamAccount: (0, _model.belongsTo)('account'),
    settings: (0, _model.belongsTo)('user-settings'),
    integrationObject: (0, _model.belongsTo)('integration-object'),
    connectedWithGusto: Ember.computed('integrationObject.externalSource', function () {
      return this.get('integrationObject.externalSource') === 'Gusto';
    })
  });

  _exports.default = _default;
});