define("zipbooks/routes/main/bank-accounts", ["exports", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/refreshable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _autoUpdatingRoute, _refreshableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_autoUpdatingRoute.default, _refreshableRoute.default, _permissionRedirect.default, {
    queryParams: {
      auto_connect: {}
    },
    autoUpdatingEvent: 'bank-member.save',
    model: function model()
    /*params*/
    {
      return this.store.query('bank-member', {});
    },
    setupController: function setupController(controller, _model, transition) {
      this._super.apply(this, arguments);

      if (transition.to.queryParams.auto_connect === 'true') {
        controller.set('auto_connect', false);
        controller.send('connect');
      }
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});