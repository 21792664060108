define("zipbooks/helpers/trial-banner-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialBannerColor = trialBannerColor;
  _exports.default = void 0;

  function trialBannerColor(params
  /*, hash*/
  ) {
    var trialExpiresIn = params[0];

    if (trialExpiresIn >= 0 && trialExpiresIn <= 2) {
      return 'red';
    } else if (trialExpiresIn > 2 && trialExpiresIn <= 7) {
      return 'gold';
    } else if (trialExpiresIn >= 8 && trialExpiresIn <= 14) {
      return 'gray';
    }

    return params;
  }

  var _default = Ember.Helper.helper(trialBannerColor);

  _exports.default = _default;
});