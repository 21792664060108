define("zipbooks/components/check-all", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    checked: false,
    actions: {
      toggle: function toggle(event) {
        var checked = $(event.target).is(':checked');
        this.onToggle(checked);
        this.set('checked', checked);
        return true;
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.once(function () {
        _this.set('checked', false);
      });
    }
  });

  _exports.default = _default;
});