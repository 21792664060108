define("zipbooks/utils/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = redirect;

  function redirect(url) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    // prevent infinite redirects
    if (window.location.pathname === url && !opts.force) {
      return false;
    }

    if (Ember.testing) {
      window.testLocation = url;
    } else {
      if (opts.blank) {
        window.open(url, '_blank');
      } else {
        window.location = url;
      }
    }

    return false;
  }
});