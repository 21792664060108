define("zipbooks/utils/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(xs, keys) {
    return xs.reduce(function (rv, x) {
      var key = x.get(keys.find(function (k) {
        return !!x.get(k);
      }));
      rv[key] = rv[key] || [];
      rv[key].push(x);
      return rv;
    }, {});
  }
});