define("zipbooks/components/quick-stats/top-contacts", ["exports", "zipbooks/mixins/testable", "ember-data"], function (_exports, _testable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    customers: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: this.store.cachedQuery('contact', {
          page: {
            'page-size': 3
          },
          filter: {
            type: 'customers'
          },
          sort: '-revenue'
        })
      });
    }),
    vendors: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: this.store.cachedQuery('contact', {
          page: {
            'page-size': 3
          },
          filter: {
            type: 'vendors'
          },
          sort: '-expenses'
        })
      });
    })
  });

  _exports.default = _default;
});