define("zipbooks/mixins/line-itemable-controller", ["exports", "jquery", "zipbooks/utils/drawer-link-for", "zipbooks/utils/countries", "zipbooks/utils/parse-number", "zipbooks/utils/magic-due-date", "zipbooks/mixins/paid-modal-handler"], function (_exports, _jquery, _drawerLinkFor, _countries, _parseNumber, _magicDueDate, _paidModalHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_paidModalHandler.default, {
    queryParams: ['editing'],
    editing: false,
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    actions: {
      edit: function edit() {
        this.transitionToRoute({
          queryParams: {
            editing: true
          }
        });
      },
      save: function save() {
        return this.save();
      },
      autoSaveModel: function autoSaveModel() {
        // only auto save when we're not editing and its already been saved
        if (!this.get('model.isNew') && !this.editing) {
          return this.save(false);
        }
      },
      send: function send() {
        var _this = this;

        var send = function send() {
          var params = (0, _drawerLinkFor.default)(['finalize-sending', _this.model]);

          _this.transitionToRoute({
            queryParams: {
              drawer: params
            }
          });
        };

        if (this.editing) {
          this.save().then(function (success) {
            if (success) {
              send();
            }
          });
        } else {
          send();
        }
      },
      selectContact: function selectContact(contact) {
        var _this2 = this;

        this.set('model.contact', contact);

        if (contact) {
          this.store.findRecord('contact', contact.get('id'), {
            reload: true
          }).then(function (fetchedContact) {
            switch (_this2.get('model.modelType')) {
              case 'invoice':
                _this2.set('model.number', fetchedContact.get('suggestedNextInvoiceNumber'));

                break;

              case 'estimate':
                _this2.set('model.number', fetchedContact.get('suggestedNextEstimateNumber'));

                break;

              case 'recurring-profile':
                if (_this2.model.acceptAch) {
                  _this2.set('model.bankAccount', _this2.get('model.contact.paymentBankAccounts.firstObject'));
                }

                break;
            }
          });
        }
      },
      extractDueDateFromTermsChange: function extractDueDateFromTermsChange() {
        var dueGuess = (0, _magicDueDate.default)(this.get('model.terms'), this.get('model.date'));

        if (dueGuess) {
          this.set('model.dueDate', dueGuess);
        }
      },
      selectCurrency: function selectCurrency(currency) {
        this.set('model.currencyCode', currency);
      },
      addLineItem: function addLineItem(type) {
        var newLineItem = this.store.createRecord('lineItem', {
          type: type,
          lineItemable: this.model,
          discount: this.discountPercent === '% varies' ? 0 : this.discountPercent,
          taxes: this.model.syncTaxes ? this.get('model.lineItems.firstObject.taxes') : null
        });
        this.get('model.lineItems').addObject(newLineItem);
      },
      destroyLineItem: function destroyLineItem(lineItem) {
        this.get('model.lineItems').removeObject(lineItem);
      },
      syncLineItemTaxes: function syncLineItemTaxes(taxes) {
        var newTaxes = taxes ? _jquery.default.extend(true, [], taxes.toArray()) : [];
        this.model.lineItems.forEach(function (lineItem) {
          return lineItem.set('taxes', newTaxes);
        });
      },
      swapLines: function swapLines(dropped, on) {
        var lineItems = this.get('model.lineItems');
        var droppedIndex = lineItems.indexOf(dropped);
        var onIndex = lineItems.indexOf(on);
        lineItems.replace(onIndex, 1, [dropped]);
        lineItems.replace(droppedIndex, 0, [on]);
      },
      saveDefaultTerms: function saveDefaultTerms() {
        var _this3 = this;

        var terms = this.get('model.terms');
        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: "Are you sure you want to save \"".concat(terms, "\" as your default terms?"),
          confirmButton: {
            title: 'Yes, Save',
            class: 'btn-green'
          },
          cancelButton: {
            title: 'No, cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            return _this3.get('session.account.settings').then(function (settings) {
              if (_this3.get('model.modelType') === 'estimate') {
                settings.set('estimateDefaultTerms', terms);
              } else {
                settings.set('defaultTerms', terms);
              }

              return settings.save().then(function (_result) {
                _this3.overlay.showBanner('success', 'Default terms updated!');
              });
            });
          },
          cancel: function cancel() {}
        });
      },
      saveDefaultNotes: function saveDefaultNotes() {
        var _this4 = this;

        var notes = this.get('model.notes');
        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: "Are you sure you want to save \"".concat(notes, "\" as your default notes?"),
          confirmButton: {
            title: 'Yes, Save',
            class: 'btn-green'
          },
          cancelButton: {
            title: 'No, cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            return _this4.get('session.account.settings').then(function (settings) {
              if (_this4.get('model.modelType') === 'estimate') {
                settings.set('estimateDefaultNotes', notes);
              } else {
                settings.set('defaultNotes', notes);
              }

              return settings.save().then(function (_result) {
                _this4.overlay.showBanner('success', 'Default notes updated!');
              });
            });
          },
          cancel: function cancel() {}
        });
      }
    },
    borderStyle: Ember.computed('model.account.settings.defaultAccentHex', function () {
      var hex = this.get('model.account.settings.defaultAccentHex'); // make sure its a safe hex value before just putting in the dangerous style attribute

      if (!hex || !hex.match(/^#[0-9a-f]{3,6}$/i)) {
        hex = '#eeeeee';
      }

      return Ember.String.htmlSafe("border-color: ".concat(hex, ";"));
    }),
    logoHeight: Ember.computed('model.account.settings.logoHeight', function () {
      var logoHeight = this.get('model.account.settings.logoHeight');

      if (!logoHeight || +logoHeight === 0) {
        logoHeight = 80;
      }

      return Ember.String.htmlSafe("max-height: ".concat(logoHeight, "px;"));
    }),
    _observeEditing: Ember.observer('editing', 'model', function () {
      // if we changed to editing and there are no lineItems
      if (this.editing === true && this.get('model.lineItems.length') === 0) {
        this.send('addLineItem', 'time_entry');
        this.send('addLineItem', 'item');
      }
    }),
    modelTitle: Ember.computed('model', function () {
      return this.get('model.modelType').capitalize();
    }),
    showNumber: Ember.computed('model', function () {
      return this.get('model.modelType') !== 'recurring-profile';
    }),
    isInvoice: Ember.computed('model', function () {
      return this.get('model.modelType') === 'invoice';
    }),
    showCurrencySelect: Ember.computed('editing', 'model.status', function () {
      if (this.get('model.modelType') === 'invoice') {
        var status = this.get('model.status');

        if (['Paid', 'Sent', 'Viewed', 'Payment Failed', 'Partial'].indexOf(status) < 0) {
          return this.editing;
        }

        return false;
      }

      return this.editing;
    }),
    contactCountry: Ember.computed('model.contact.country', function () {
      return (0, _countries.getCountryName)(this.get('model.contact.country'));
    }),
    accountCountry: Ember.computed('model.account.primaryLocation.country', function () {
      return (0, _countries.getCountryName)(this.get('model.account.primaryLocation.country'));
    }),
    differentCountries: Ember.computed('contactCountry', 'accountCountry', function () {
      return this.contactCountry && this.accountCountry && this.contactCountry !== this.accountCountry;
    }),
    // if all line items are the same show the discount percent and allow editing, otherwise show "Varies"
    discountPercent: Ember.computed('model.lineItems.@each.discount', {
      get: function get() {
        var discount = (0, _parseNumber.default)(this.get('model.lineItems.firstObject.discount')).round(2).toFixed(2); // if they aren't all the same discount

        if (this.get('model.lineItems.length') > 1) {
          this.get('model.lineItems').forEach(function (lineItem) {
            if ((0, _parseNumber.default)(lineItem.get('discount')).round(2).toFixed(2) !== discount) {
              discount = '% varies';
            }
          });
        }

        return discount;
      },
      set: function set(_key, value) {
        var discount = (0, _parseNumber.default)(value).round(2).toFixed(2);
        this.get('model.lineItems').forEach(function (lineItem) {
          lineItem.set('discount', discount);
        });
        return value;
      }
    }),
    discountEditable: Ember.computed('discountPercent', 'editing', function () {
      return this.discountPercent !== '% varies' && this.editing;
    }),
    showDiscountPercentSign: Ember.computed('discountPercent', function () {
      return this.discountPercent !== '% varies';
    }),
    save: function save(_beforeSave, _afterSave) {
      var _this5 = this;

      /*****************************************************
       * This exists as a hack to fix a bug with ember 3.0
       * https://github.com/emberjs/ember.js/issues/16624
       * For some reason, in this very particular instance,
       * (cause it works for transactions…),
       * lineItem._internalModel._record
       * is not being updated when attributes change…
       ******************************************************/
      this.model.lineItems.forEach(function (li) {
        li.eachAttribute(function (keyName) {
          return li._internalModel._record.set(keyName, Ember.get(li, keyName));
        });
      });
      this.removeEmptyLines(); // normalize the discount amount

      this.set('model.discount', +this.get('model.discount'));
      var order = 1;
      this.get('model.lineItems').forEach(function (lineItem) {
        ;
        ['rate', 'quantity'].forEach(function (key) {
          var value = Ember.isEmpty(lineItem.get(key)) ? 0 : lineItem.get(key);
          lineItem.set(key, value);
        });
        lineItem.set('order', order++);

        if (lineItem.taxes) {
          lineItem.set('taxes', lineItem.taxes.filter(function (t) {
            return t;
          }));
        }
      });
      var isNew = this.get('model.isNew');
      return this.model.save().then(function (saved) {
        _this5.urlCache.clear();

        _this5.store.unloadUnsaved('lineItem');

        _this5.transitionToRoute({
          queryParams: {
            editing: false
          }
        }).then(function () {
          if (isNew) {
            var lineItemableType = _this5.get('model.modelType');

            _this5.transitionToRoute("main.".concat(lineItemableType), saved.id);
          }
        });

        return true;
      }, function (resp) {
        var errors = resp.errors.mapBy('detail').join('<br>');

        if (errors) {
          var lineItemableType = _this5.get('model.modelType');

          _this5.overlay.showBanner('error', Ember.String.htmlSafe(errors) || 'Failed to save ' + lineItemableType + '.');
        }

        return false;
      });
    },
    removeEmptyLines: function removeEmptyLines() {
      this.set('model.lineItems', this.model.lineItems.filter(function (li) {
        return li.name || li.notes || li.rate || li.quantity;
      }));
    }
  });

  _exports.default = _default;
});