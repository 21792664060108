define("zipbooks/helpers/format-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatHours = formatHours;
  _exports.default = void 0;

  function formatHours(params
  /*, hash*/
  ) {
    return params[0].toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  }

  var _default = Ember.Helper.helper(formatHours);

  _exports.default = _default;
});