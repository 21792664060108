define("zipbooks/components/bill-item-line", ["exports", "zipbooks/mixins/paid-modal-handler"], function (_exports, _paidModalHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, {
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: '',
    actions: {
      selectChartAccount: function selectChartAccount(item, chartAccount) {
        item.set('chartAccount', chartAccount);
      },
      delete: function _delete() {
        Ember.tryInvoke(this, 'delete', []);
      },
      updateItemName: function updateItemName(name) {
        var billItem = this.billItem;
        billItem.set('name', name);
      },
      selectItem: function selectItem(item) {
        var billItem = this.billItem;

        if (item) {
          billItem.set('name', item.get('name'));
          billItem.set('description', item.get('description'));
          billItem.set('total', item.get('price'));

          if (item.get('chartAccount.id')) {
            var chartAccount = this.store.peekRecord('chartAccount', item.get('chartAccount.id'));
            billItem.set('chartAccount', chartAccount);
          }
        }
      },
      saveAsItem: function saveAsItem() {
        var _this = this;

        var billItem = this.billItem;
        var item = this.store.createRecord('item', {
          name: billItem.get('name'),
          description: billItem.get('description'),
          price: billItem.get('total'),
          chartAccount: billItem.get('chartAccount'),
          kind: 'bill'
        });
        item.save().then(function () {
          _this.overlay.showBanner('success', Ember.String.htmlSafe('Line saved to <b>Items</b> successfully.'));
        }, function (error) {
          if (Ember.get(error, 'errors.firstObject.source.pointer') === '/data/attributes/name') {
            _this.overlay.showBanner('error', Ember.String.htmlSafe('Please add a <b>title</b> and a <b>price</b> before saving this line as an Item.'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});