define("zipbooks/components/reports/income-statement-line-value", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/paid-modal-handler", "zipbooks/utils/tag-serialization", "zipbooks/utils/to-decimal"], function (_exports, _testable, _paidModalHandler, _tagSerialization, _toDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, _testable.default, {
    quickLinkExclude: ['Gross profit', 'Operating income', 'Net income'],
    quickLinkExcludeId: ['uncategorized-income', 'uncategorized-expense'],
    quickLinkSales: ['Sales', 'Other revenue'],
    quickLinkExpenses: ['Expenses', 'Other expenses'],
    hideBalance: Ember.computed('balance', 'accountInfo.children.length', function () {
      var zero = new Decimal(0);
      var balance = (0, _toDecimal.default)(this.balance);
      var count = this.get('accountInfo.children.length');
      return balance.comparedTo(zero) === 0 && count > 0;
    }),
    balance: Ember.computed('accountInfo.balances', 'index', function () {
      return this.get('accountInfo.balances')[this.index].balance;
    }),
    balancePercentOfSales: Ember.computed('accountInfo.balances', 'index', function () {
      return this.get('accountInfo.balances')[this.index].balance_percent_of_sales;
    }),
    total: Ember.computed('accountInfo.balances', 'index', function () {
      return this.get('accountInfo.balances')[this.index].total;
    }),
    totalPercentOfSales: Ember.computed('accountInfo.balances', 'index', function () {
      return this.get('accountInfo.balances')[this.index].total_percent_of_sales;
    }),
    sumLineClass: Ember.computed('accountInfo.classification', 'balance', function () {
      if (Ember.isBlank(this.get('accountInfo.classification'))) {
        var balance = (0, _toDecimal.default)(this.balance);
        var zero = new Decimal(0);

        if (balance.comparedTo(zero) > 0) {
          return 'green ';
        } else if (balance.comparedTo(zero) === 0) {
          return 'base ';
        } else {
          return 'red ';
        }
      }
    }),
    hideQuickClick: Ember.computed(function () {
      return this.consolidated || this.quickLinkExclude.includes(this.accountInfo.name) || this.quickLinkExcludeId.includes(this.accountInfo.id);
    }),
    actions: {
      viewTransactions: function viewTransactions(chartAccountId) {
        var startDate, endDate, month, year;
        var date = this.get('group.name');
        var maxStartDate = moment(this.startDate);
        var maxEndDate = moment(this.endDate);
        var tags = this.tags;

        if (date === 'Total' || this.tagGroupSelected) {
          startDate = maxStartDate;
          endDate = maxEndDate;
        } else {
          switch (this.groupBy) {
            case 'month':
              startDate = moment(date).startOf('month');
              endDate = moment(date).endOf('month');
              break;

            case 'quarter':
              month = date.split(' ')[0].charAt(1) * 3 - 2;
              year = date.split(' ')[1];
              date = year + '-' + month;
              startDate = moment(date, 'YYYY-M').startOf('quarter');
              endDate = moment(date, 'YYYY-M').endOf('quarter');
              break;

            case 'year':
              startDate = moment(date, 'YYYY').startOf('year');
              endDate = moment(date, 'YYYY').endOf('year');
              break;

            default:
              startDate = maxStartDate;
              endDate = maxEndDate;
          }

          startDate = startDate.isBefore(this.startDate) ? maxStartDate : startDate;
          endDate = endDate.isAfter(this.endDate) ? maxEndDate : endDate;
        }

        if (this.tagGroupSelected) {
          tags = tags.filter(function (tag) {
            return tag.kind !== 'kind';
          });
          tags.push(this.get('group.tag'));
        }

        if (this.permissions.can('accounting.read')) {
          this.router.transitionTo('main.transactions', {
            queryParams: {
              chart_account_id: chartAccountId,
              end_date: endDate.format('YYYY-MM-DD'),
              start_date: startDate.format('YYYY-MM-DD'),
              tags: _tagSerialization.default.serialize(tags),
              confirmed: this.include === 'all' ? '' : 'confirmed'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});