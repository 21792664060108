define("zipbooks/components/drawers/item-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.get('isNew')) {
        model.set('kind', 'invoice');
      }
    },
    actions: {
      selectChartAccount: function selectChartAccount(chartAccount) {
        this.set('model.chartAccount', chartAccount);
      }
    },
    filter: Ember.computed('model.kind', function () {
      return this.get('model.kind') === 'invoice' ? ['sales'] : ['expenses'];
    })
  });

  _exports.default = _default;
});