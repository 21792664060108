define("zipbooks/templates/components/history-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VYyhK17A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"table-first-col\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-weight-bold\"],[8],[1,[28,\"format-date\",[[24,[\"model\",\"created_at\"]]],null],false],[0,\":\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/history-item.hbs"
    }
  });

  _exports.default = _default;
});