define("zipbooks/components/drawers/setup-bank-member-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    client: Ember.inject.service(),
    modelName: 'bank-member',
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.getWithDefault('callbacks.animateIn', true)) {
        this.set('inClass', null);
      }

      this.set('syncTo', moment().subtract(90, 'days').startOf('month'));

      this._super.apply(this, arguments);
    },
    loading: Ember.computed('model.bankAccounts.length', function () {
      return this.model.get('bankAccounts.length') === 0;
    }),
    title: Ember.computed('model.institutionName', function () {
      return "Setup ".concat(this.model.institutionName);
    }),
    partialName: Ember.computed('modelName', function () {
      return "components/drawers/setup-bank-member-form";
    }),
    actions: {
      save: function save(model) {
        var _this = this;

        Ember.RSVP.all(model.bankAccounts.filter(function (bankAccount) {
          return bankAccount.connected;
        }).map(function (bankAccount) {
          bankAccount.set('connected', true);
          return _this.client.send('PATCH', 'bank-accounts', "".concat(bankAccount.id, "/reconnect"));
        })).then(function () {
          model.set('syncTo', _this.syncTo);
          model.save().then(function () {
            return _this.afterSave(model);
          }, function (error) {
            return _this.handleError(error);
          });
        });
      }
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      this._super.apply(this, arguments); // do an initial check in 5 seconds


      Ember.run.later(this, function () {
        _this2.client.send('POST', 'bank-members', "".concat(model.id, "/sync")).then(function () {
          model.reload().then(function () {
            _this2.set('showLoadingWarning', true); // then start a check every 10 seconds until there are accounts


            _this2.checkForNewAccounts();
          });
        });
      }, 5000);
    },
    checkForNewAccounts: function checkForNewAccounts() {
      var _this3 = this;

      Ember.run.later(this, function () {
        if (_this3.loading) {
          _this3.client.send('POST', 'bank-members', "".concat(_this3.model.id, "/sync")).then(function () {
            _this3.model.reload();

            _this3.checkForNewAccounts();
          });
        }
      }, 10000);
    }
  });

  _exports.default = _default;
});