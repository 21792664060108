define("zipbooks/components/drawers/recurring-profile-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    title: 'Include Unbilled',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var translated = this.translateFromModel(this.model);
      this.set('compModel', translated);
    },
    actions: {
      save: function save() {
        this.translateToModel(this.compModel, this.model);
        this.get('callbacks.updated')();
        this.send('close');
      }
    },
    // translate the rec prof to model the component expects
    translateFromModel: function translateFromModel(model) {
      var view = model.get('timeEntryFormat') || 'detailed';
      return {
        view: view,
        show: {
          date: model.get('showDate'),
          notes: model.get('showNotes'),
          project: model.get('showProject'),
          teamMember: model.get('showTeamMember')
        },
        projects: model.get('projects'),
        includeExpenses: model.get('includeExpenses'),
        includeFutureProjects: model.get('includeFutureProjects'),
        noProject: model.get('noProject')
      };
    },
    // translates from the model component back to rec prof
    translateToModel: function translateToModel(compModel, model) {
      var view = compModel.view;
      model.set('timeEntryFormat', view);

      for (var attr in compModel.show) {
        var key = Ember.String.camelize("show_".concat(attr));
        model.set(key, compModel.show[attr]);
      }

      model.set('projects', compModel.projects.toArray());
      model.set('includeExpenses', compModel.includeExpenses);
      model.set('includeFutureProjects', compModel.includeFutureProjects);
      model.set('noProject', compModel.noProject);
    }
  });

  _exports.default = _default;
});