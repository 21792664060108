define("zipbooks/components/index-row-content/client", ["exports", "zipbooks/utils/storage", "zipbooks/utils/redirect"], function (_exports, _storage, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    clientService: Ember.inject.service('client'),
    actions: {
      archive: function archive(client) {
        this.toggleArchive(client);
      },
      manageUsers: function manageUsers(account) {
        this.overlay.showDrawer('drawers/manage-team-form', account);
      },
      filesSelected: function filesSelected(event) {
        this.set('fileEvent', event);
        this.set('hasUploaded', true);
      },
      documentsDidUpload: function documentsDidUpload() {
        this.overlay.showBanner('success', 'Document sent successfully!');
      },
      deactivate: function deactivate(account) {
        var _this = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: 'Do you want to deactivate this license now?',
          confirmButton: {
            title: 'Deactivate',
            class: 'btn-red text-small-bd'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white text-small-bd'
          }
        }, {
          confirm: function confirm() {
            var sub = account.get('subscriptions.firstObject');

            _this.clientService.send('PATCH', "subscriptions/".concat(sub.id, "/deactivate")).then(function () {
              return _this.refresh();
            });

            _this.session.fetchLoggedInUser();
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      },
      activate: function activate(account) {
        var _this2 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: 'Do you want to activate this license now?',
          confirmButton: {
            title: 'Activate',
            class: 'btn-green text-small-bd'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white text-small-bd'
          }
        }, {
          confirm: function confirm() {
            var sub = account.get('subscriptions.firstObject');

            _this2.clientService.send('PATCH', "subscriptions/".concat(sub.id, "/activate")).then(function () {
              return _this2.refresh();
            });

            _this2.session.fetchLoggedInUser();
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      },
      linkLogin: function linkLogin() {
        var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '../';
        // there is weird flickering of empty data when `logout` empties the store, so we blank the whole screen first
        $('body').hide();
        Ember.run.later(this, function () {
          return $('body').show();
        }, 1000); // there's a lot that could get funky if mixed user data happens, so we just hard reload the page to be safe.

        var isStealth = this.get('session.isStealth');
        this.session.login(this.token, _storage.default.get('remember'), isStealth);
        (0, _redirect.default)(path, {
          force: true
        });
      },
      migrateData: function migrateData(account) {
        this.overlay.showDrawer('drawers/import-transactions-form', {
          clientAccount: account
        });
      }
    },
    clientName: Ember.computed('client.formattedName', 'client.name', function () {
      return this.client.name || this.client.formattedName;
    }),
    canAccessAccount: Ember.computed('token', function () {
      return !!this.token;
    }),
    token: Ember.computed('session.currentUser.identity.userTokens', 'account.id', function () {
      var _this3 = this;

      if (this.client.linkedAccount) {
        var found = this.get('session.currentUser.identity.userTokens').find(function (token) {
          return "".concat(token.account_id) === _this3.get('client.linkedAccount.id');
        });

        if (found) {
          return found.token;
        }
      }
    })
  });

  _exports.default = _default;
});