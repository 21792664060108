define("zipbooks/routes/main/time-entries", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/sort-param"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _permissionRedirect, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    queryParams: {
      user_id: {
        refreshModel: true
      },
      contact_id: {
        refreshModel: true
      },
      project_id: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      },
      billed: {
        refreshModel: true
      },
      paid: {
        refreshModel: true
      }
    },
    autoUpdatingEvent: 'time-entry.save',
    autoUpdatingModel: 'groups',
    autoUpdatingHardRefresh: true,
    model: function model(params) {
      var page = params.page || 1;
      var queryParams = {
        page: {
          page: page,
          'page-size': 50
        },
        filter: {
          user_id: params.user_id,
          contact_id: params.contact_id,
          project_id: params.project_id,
          start_date: params.start_date,
          end_date: params.end_date,
          billed: !params.billed ? undefined : params.billed === 'billed' ? true : false,
          paid: !params.paid ? undefined : params.paid === 'paid' ? true : false,
          is_pending: false
        },
        sort: (0, _sortParam.default)(params)
      };
      var models = {
        groups: this.store.cachedQuery('timeEntry', queryParams)
      };

      if (page === 1) {
        var pendingParams = {
          filter: {
            user_id: params.user_id,
            contact_id: params.contact_id,
            project_id: params.project_id,
            start_date: params.start_date,
            end_date: params.end_date,
            billed: !params.billed ? undefined : params.billed === 'billed' ? true : false,
            paid: !params.paid ? undefined : params.paid === 'paid' ? true : false,
            is_pending: true
          }
        };
        models.pending = this.store.cachedQuery('timeEntry', pendingParams);
      }

      if (params.user_id) {
        models.user = this.store.find('user', params.user_id);
      }

      if (params.contact_id) {
        models.contact = this.store.findRecord('contact', params.contact_id, {
          include: 'public_profile'
        });
      }

      if (params.project_id) {
        models.project = this.store.find('project', params.project_id);
      }

      return Ember.RSVP.hash(models);
    }
  });

  _exports.default = _default;
});