define("zipbooks/controllers/main/transaction-sheet", ["exports", "zipbooks/mixins/index-controller", "zipbooks/mixins/transaction-list", "zipbooks/mixins/tag-filterable-controller", "zipbooks/utils/keycodes", "zipbooks/utils/alphanumeric-sort", "zipbooks/utils/transaction-sheet/transaction", "zipbooks/utils/transaction-sheet/row", "zipbooks/utils/transaction-sheet/cell", "zipbooks/utils/is-browser", "zipbooks/utils/array", "zipbooks/utils/clipboard"], function (_exports, _indexController, _transactionList, _tagFilterableController, _keycodes, _alphanumericSort, _transaction, _row, _cell3, _isBrowser, _array, clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend(_indexController.default, _transactionList.default, _tagFilterableController.default, {
    queryParams: ['chart_account_id', 'start_date', 'end_date', 'confirmed', 'kind', {
      visibleColumns: 'columns'
    }],
    sort: 'date',
    direction: 'desc',
    chart_account_id: null,
    confirmed: null,
    kind: 'all',
    start_date: Ember.computed('start_date', {
      get: function get(_key) {
        return this._super.apply(this, arguments) || moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      },
      set: function set(_key, value) {
        var end = moment(value).endOf('month').format('YYYY-MM-DD');
        this.set('end_date', end);
        return value;
      }
    }),
    end_date: Ember.computed('start_date', {
      get: function get(_key) {
        return this._super.apply(this, arguments) || moment().endOf('month').format('YYYY-MM-DD');
      },
      set: function set(_key, value) {
        var s = moment(this.start_date);
        var e = moment(value);

        if (e.isBefore(s)) {
          return this.start_date;
        } else {
          return value;
        }
      }
    }),
    visibleColumns: 'name,notes',
    // mixins/list-controller.js
    collection: Ember.computed.alias('model.lines'),
    modelType: Ember.computed('collection.@each', function () {
      if (this.chart_account_id) {
        return 'journal_entry_line';
      } else {
        return 'journal_entry';
      }
    }),
    rowTransactions: Ember.computed('rows.@each.transaction', function () {
      return this.rows.mapBy('transaction').uniqBy('transactionIndex');
    }),
    selected: Ember.computed('rowTransactions.@each.isSelected', function () {
      return this.get('rowTransactions').filterBy('isSelected').mapBy('line');
    }),
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('bulkTags', []);
      this.cellMap = {};
      this.transactionMap = {};
      this.state = Ember.Object.create({
        mode: 'standard',
        // or bulk
        highlighted: null
      });
    },
    ctrlKey: Ember.computed(function () {
      if ((0, _isBrowser.hasCommandKey)()) {
        return '⌘';
      } else if ((0, _isBrowser.hasControlKey)()) {
        return 'Ctrl';
      }
    }),
    // is computed once when the page loads and then is added to and managed by callbacks below
    transactions: Ember.computed('model.lines.@each', function () {
      var _this = this;

      var lines;

      if (this.get('model.lines.length')) {
        lines = this.model.lines.toArray().compact();
      } else {
        lines = [];
      }

      var transactions = lines.map(function (line, idx) {
        var entry;

        if (!_this.chart_account_id) {
          entry = line;
        } else {
          entry = line.get('journalEntry.content');
        }

        return _this.buildTransaction(entry, line, idx);
      });
      return transactions;
    }),
    sortedTransactions: Ember.computed('transactions.@each', 'sort', 'direction', 'kind', '_invalidateRows', function () {
      var _this2 = this;

      // drop any empty transactions
      var transactions = this.transactions.filter(function (t) {
        return t.get('journalEntry.journalEntryLines').any(function (l) {
          return l.get('chartAccount.id');
        });
      });

      if (this.kind && this.kind !== 'all') {
        transactions = transactions.filter(function (transaction) {
          return transaction.kind === _this2.kind;
        });
      }

      var column = this.columns.find(function (c) {
        return c.key === _this2.sort;
      });
      var sorted = transactions; // sorting by tags doesn't make sense, so we just ignore

      if (column.key !== 'tags') {
        var keyPath = column.sort;
        sorted.sort(function (a, b) {
          var aValue = a.get(keyPath);
          var bValue = b.get(keyPath);
          var sort;

          if (column.sortType === 'number' && !Ember.isBlank(aValue) && !Ember.isBlank(bValue)) {
            sort = aValue - bValue;
          } else if (column.sortType === 'date' && !Ember.isBlank(aValue) && !Ember.isBlank(bValue)) {
            sort = moment(aValue).diff(bValue);
          } else {
            sort = (0, _alphanumericSort.default)(aValue ? aValue + '' : '', bValue ? bValue + '' : '');
          }

          if (_this2.direction === 'asc') {
            return sort;
          } else {
            return sort * -1;
          }
        });
      }

      var emptyTransaction = this.createEmptyTransaction(sorted.length); // we dont want to use pushObject which would trigger an infinite recompute

      this.transactions.push(emptyTransaction);
      sorted.push(emptyTransaction); // now that the rows are sorted, we have to renumber them

      this.updateTransactionCache(sorted);
      return sorted;
    }),
    buildTransaction: function buildTransaction(entry, line, idx) {
      var _this3 = this;

      return _transaction.default.create({
        store: this.store,
        overlay: this.overlay,
        transactionIndex: idx,
        number: idx + 1,
        line: line,
        splitInfo: this.splitInfo(entry),
        journalEntry: entry,
        isSelected: false,
        isHighlighted: false,
        isFlipped: this.isFlipped,
        chartAccountId: this.chart_account_id,
        equityException: this.equityException,
        // because we don't send any tmp id to the backend with journal entry lines, theres no way to match up lines
        // sent with lines saved. So its just a bag of lines we need to replace as a group. So, here we replace
        // all the rows of a transaction when the lines are saved. Translating transactions to rows is kind of
        // complicated so we let `addRowsToTransaction` handle it
        afterSave: function afterSave(transaction) {
          _this3.replaceTransaction(transaction);
        }
      });
    },
    replaceTransaction: function replaceTransaction(transaction) {
      var _this4 = this;

      // update splitInfo
      transaction.set('splitInfo', this.splitInfo(transaction.journalEntry)); // regenerate the rows for this transactions

      this.addRowsToTransaction(transaction);
      var rows = this.rows.filterBy('transaction.journalEntry.id', transaction.journalEntry.id);
      var grouped = (0, _array.groupBy)($.extend([], rows).concat(transaction.rows), function (t) {
        return t.categoryLine.identityKey();
      }); // if any of the grouped have anything but 2 in each, we need to just rerender the rows to be thorough

      if (Object.keys(grouped).any(function (k) {
        return grouped[k].length !== 2;
      })) {
        // we need to splice these new rows into the `rows` array that is used to render the list
        var indices = this.rows.map(function (r, idx) {
          return r.transaction.journalEntry.id === transaction.journalEntry.id ? idx : null;
        }).compact().sort(); // this will cause just these rows to re-rendered which is really fast

        this.rows.replace(indices.firstObject, indices.length, transaction.rows); // cellMap and indexes need to be updated

        this.updateRowCache(this.rows); // after its rendered, it needs to be highlighted again

        var cell = this.state.highlighted;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this4.setHighlighted(_this4.cellMap["".concat(cell.row.rowIndex, ",").concat(cell.columnIndex)]);
        });
      } else {
        Object.keys(grouped).forEach(function (k) {
          var _grouped$k = _slicedToArray(grouped[k], 2),
              o = _grouped$k[0],
              n = _grouped$k[1];

          o.setProperties({
            transaction: n.transaction,
            accountLine: n.accountLine,
            categoryLine: n.categoryLine,
            kind: n.kind,
            tagLines: n.tagLines,
            isMergedRow: n.isMergedRow
          });
        });
      }
    },
    createEmptyTransaction: function createEmptyTransaction(index) {
      // add journal entry for empty row
      var primaryLine = this.store.createRecord('journalEntryLine', {
        kind: 'debit',
        tags: []
      });
      var balancingLine = this.store.createRecord('journalEntryLine', {
        kind: 'credit',
        tags: []
      });
      var entry = this.store.createRecord('journalEntry', {
        journalEntryLines: [primaryLine, balancingLine],
        cloudFiles: []
      });
      return this.buildTransaction(entry, this.chart_account_id ? primaryLine : entry, index);
    },
    updateTransactionCache: function updateTransactionCache(transactions) {
      var _this5 = this;

      this.transactionMap = {};
      transactions.forEach(function (transaction, transactionIndex) {
        _this5.transactionMap[transactionIndex] = transaction;
        transaction.set('transactionIndex', transactionIndex);
        transaction.set('number', transactionIndex + 1);
      });
    },
    rows: Ember.computed('sortedTransactions.@each', 'columns.@each', function () {
      var _this6 = this;

      var rows = this.sortedTransactions.reduce(function (acc, transaction) {
        _this6.addRowsToTransaction(transaction);

        return acc.concat(transaction.rows);
      }, []);
      this.set('newRowIndex', this.sortedTransactions.length);
      this.updateRowCache(rows); // every time ths changes, we want to auto-select a cell. If its saved, then we use that, otherwise we just
      // select 0,0

      if (this.state.mode === 'standard') {
        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.run.next(function () {
            if (_this6.lastHighlightedCellCoords) {
              var cell = _this6.cellAt(_this6.lastHighlightedCellCoords).cell;

              _this6.setHighlighted(_this6.cellMap["".concat(cell.row.rowIndex, ",").concat(cell.columnIndex)]);
            } else {
              _this6.setHighlighted(_this6.cellMap['0,0']);
            } // if any dimensions changed on the top nav bar, we need to re-adjust this


            _this6.notificationCenter.notify('tags-input-changed'); // also ajust the columns header cause why not. jk its really important


            var height = $('.content-nav-container').height();
            $('tr.transaction-sheet-table-row-header th').css({
              top: 101 + height
            });
          });
        });
      }

      return rows;
    }),
    addRowsToTransaction: function addRowsToTransaction(transaction) {
      var _this7 = this;

      var split = transaction.splitInfo;
      var forwardTargets = {};
      var transactionRows = split.lines.map(function (jel, splitLineIndex) {
        var row = _row.default.create({
          store: _this7.store,
          overlay: _this7.overlay,
          transaction: transaction,
          accountLine: transaction.accountLine,
          categoryLine: transaction.categoryLine,
          kind: transaction.kind,
          tagLines: transaction.journalEntry.journalEntryLines
        }); // if this is a split we basically map a row directly to each journal entry line


        if (split.isSplit) {
          row.setProperties({
            accountLine: split.accountLine,
            categoryLine: jel,
            tagLines: [jel],
            kind: split.accountLine ? transaction.kind : jel.kind,
            isMergedRow: splitLineIndex === 0
          });
        } // create cells for this row
        // even cells that wont be shown, because they are merged. So we have a full N x N grid.


        var cells = _this7.columns.map(function (column, idx) {
          var merged = split.mergedColumns.includes(column.key);
          var grayOutAccountColumn = column.key === 'account' && row.transaction.splitInfo.isSplit && !row.get('accountLine.id');

          var cell = _cell3.default.create({
            row: row,
            column: column,
            isHighlighted: false,
            columnIndex: idx,
            isMerged: merged,
            isHidden: merged && splitLineIndex !== 0,
            forwardTo: merged && forwardTargets[column.key] || null,
            grayOutAccountColumn: grayOutAccountColumn,
            showSplitButton: ['account', 'category'].includes(column.key) && !grayOutAccountColumn
          });

          if (merged && splitLineIndex === 0) {
            forwardTargets[column.key] = cell;
          }

          cell.updateRevertFunction();
          return cell;
        });

        row.set('cells', cells);
        return row;
      });
      transaction.set('rows', transactionRows);
    },
    createEmptyRow: function createEmptyRow() {
      var transaction = this.createEmptyTransaction(this.transactions.length + 1);
      this.addRowsToTransaction(transaction);
      return transaction.rows.firstObject;
    },
    updateRowCache: function updateRowCache(rows) {
      var _this8 = this;

      rows.forEach(function (r, i) {
        return r.set('rowIndex', i);
      });
      rows.forEach(function (row, rowIndex) {
        row.cells.forEach(function (cell, columnIndex) {
          _this8.cellMap["".concat(rowIndex, ",").concat(columnIndex)] = cell;
        });
      });
    },
    setup: function setup() {
      var _this9 = this;

      $('body').off('.sheet');
      $('body').on('keydown.sheet', function (e) {
        if (_this9.processKeyPress(e)) {
          e.preventDefault();
          e.stopPropagation();
        }
      });
      $('body').on('keyup.sheet', function (e) {
        // if its the shift key, we clear the flag that says if they selecting or deselecting in bulk mode
        if (e.key === 'Shift') {
          _this9.set('_bulkSelectionMode', null);
        }
      });
      $('body').on('mouseup.sheet', function (e) {
        if (_this9.state.mode === 'bulk') {
          if ($(e.target).closest('.js-bulk-mode').length === 0 && $(e.target).closest('.modal').length === 0) {
            _this9.setMode('standard');
          }
        }

        return true;
      });
    },
    actions: {
      mouseEvent: function mouseEvent(type, y, x, event) {
        if ($(event.target).closest('.autosuggest-item').length > 0) {
          return true;
        }

        if (type === 'down') {
          this.state.mouse = {
            button: 'down',
            position: {
              x: event.screenX,
              y: event.screenY
            }
          };
        } else if (type === 'move' && this.get('state.mouse.position')) {
          var p1 = {
            x: event.screenX,
            y: event.screenY
          };
          var p2 = this.state.mouse.position;
          var distance = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
          var cell = this.cellMap[y + ',' + x]; // start dragging

          if (this.state.mouse.button === 'down' && distance > 5) {
            this.state.mouse.button = 'dragging';

            if (!cell.row.transaction.isSelected) {
              var index = cell.row.transaction.transactionIndex;
              this.toggleTransaction(index);
            }

            event.preventDefault();
            event.stopPropagation();
          } // already dragging
          else if (this.state.mouse.button === 'dragging') {
              if (!cell.row.transaction.isSelected) {
                var _index = cell.row.transaction.transactionIndex;
                this.selectUntil(_index);
              }

              event.preventDefault();
              event.stopPropagation();
            }
        } else if (type === 'up') {
          // if the mouse is up and they didn't move it, we consider that a click, so we select the cell
          if (this.state.mouse.button === 'down') {
            var _cell = this.cellMap[y + ',' + x];

            if (this.state.mode === 'bulk') {
              var _index2 = _cell.row.transaction.transactionIndex;

              if (event.shiftKey) {
                event.preventDefault();
                event.stopPropagation();
                this.selectUntil(_index2);
              } else {
                this.toggleTransaction(_index2);
              }
            } else {
              this.setHighlighted(_cell, {
                editing: _cell.isEditing
              });
            }
          }

          this.state.mouse = {
            button: 'up'
          };
        } else if (type === 'doubleclick') {
          var _cell2 = this.cellMap[y + ',' + x];

          if (this.state.mode === 'bulk') {
            var _index3 = _cell2.row.transaction.transactionIndex;
            this.toggleTransaction(_index3);
          } else {
            this.setHighlighted(_cell2, {
              editing: true
            });
          }
        }
      },
      sortBy: function sortBy(key) {
        if (this.sort === key) {
          this.set('direction', this.direction === 'asc' ? 'desc' : 'asc');
        } else {
          this.set('sort', key);
          this.set('direction', 'asc');
        }
      },
      selectRow: function selectRow(index, event) {
        if (event.shiftKey) {
          this.selectUntil(index);
        } else {
          this.toggleTransaction(index);
        }
      },
      split: function split(cell) {
        var kind;

        if (cell.column.key === 'account') {
          kind = cell.row.accountLine.kind;
        } else {
          kind = cell.row.categoryLine.kind;
        }

        var journalEntry = cell.row.transaction.journalEntry;
        var newLine = this.store.createRecord('journalEntryLine', {
          kind: kind,
          journalEntry: journalEntry,
          tags: [],
          date: cell.row.categoryLine.date
        });
        journalEntry.get('journalEntryLines').pushObject(newLine);
        this.replaceTransaction(cell.row.transaction);
      },
      bulkDelete: function bulkDelete() {
        var _this10 = this;

        var highlightedIndex = this.state.highlighted.transactionIndex;

        var deleteJe = function deleteJe(je) {
          je.deleteRecord();
          je.unloadRecord();
          je.journalEntryLines.toArray().forEach(function (line) {
            line.deleteRecord();
            line.unloadRecord();
          });
        };

        var findNewRowToHighlight = function findNewRowToHighlight() {
          Ember.run.scheduleOnce('afterRender', _this10, function () {
            var index = highlightedIndex < _this10.rowTransactions.length - 2 ? highlightedIndex : _this10.rowTransactions.length - 2;

            _this10.setHighlighted(_this10.transactionAt({
              y: index
            }).transaction);
          });
        };

        var removeTransactions = function removeTransactions(toRemove) {
          _this10.transactions.removeObjects(toRemove);

          _this10.updateTransactionCache(_this10.sortedTransactions);
        }; // this will select only those that have an id and need to be deleted from the server
        // we handle unsaved below


        var jes = this.selectedJournalEntries();
        this.bulkDelete(jes, function (_results) {
          jes.forEach(function (je) {
            return deleteJe(je);
          });
        }, function (models, callback) {
          var ids = models.mapBy('id').compact(); // remove from `rows` to cause an immediate re-render

          var toRemove = _this10.transactions.filter(function (t) {
            return ids.includes(t.journalEntry.id);
          });

          removeTransactions(toRemove); // then we remove from the server

          var query = $.param({
            ids: ids
          });

          _this10.client.DELETE("journal-entries?".concat(query)).then(function () {
            callback();
            findNewRowToHighlight();
          });
        }); // we also want to handle any jes that have not been saved

        var entries;

        if (this.modelType === 'journal_entry') {
          entries = this.selected;
        } else {
          entries = this.selected.mapBy('journalEntry');
        }

        var uncreated = entries.filterBy('isNew', true).map(function (je) {
          return je.content || je;
        });
        uncreated.forEach(function (je) {
          return deleteJe(je);
        }); // remove from `rows` to cause an immediate re-render

        var toRemove = this.transactions.filter(function (t) {
          return uncreated.includes(t.journalEntry);
        });
        removeTransactions(toRemove);
        findNewRowToHighlight();
      },
      bulkTag: function bulkTag() {
        this._super.apply(this, arguments);
      }
    },
    processKeyPress: function processKeyPress(event) {
      var _this11 = this;

      // we need to ignore key presses when the focus is on input elements outside of the sheet
      if (document.activeElement.tagName !== 'BODY' && document.activeElement.closest('.js-sheet-input') === null) {
        // if they hit the escape key, we want to just lose focus on these elements
        if (event.which === _keycodes.default.ESCAPE) {
          document.activeElement.blur();
          return true;
        }

        return false;
      } // for key events to work in this context, there has to be a current highlighted cell


      if (this.state.mode === 'bulk') {
        switch (event.which) {
          case _keycodes.default.ARROW_DOWN:
            if (event.shiftKey) {
              if (this._bulkSelectionMode === null) {
                this.toggleTransaction(this.state.highlighted.transactionIndex, {
                  select: true
                });
                this.set('_bulkSelectionMode', this.state.highlighted.isSelected);
              }

              this.setHighlighted(this.transactionAt({
                dy: 1
              }).transaction);
              this.toggleTransaction(this.state.highlighted.transactionIndex, {
                select: true
              });
            } else {
              this.setHighlighted(this.transactionAt({
                dy: 1
              }).transaction);
            }

            return true;

          case _keycodes.default.ARROW_UP:
            if (event.shiftKey) {
              if (this._bulkSelectionMode === null) {
                this.toggleTransaction(this.state.highlighted.transactionIndex);
                this.set('_bulkSelectionMode', this.state.highlighted.isSelected, {
                  select: true
                });
              }

              this.setHighlighted(this.transactionAt({
                dy: -1
              }).transaction);
              this.toggleTransaction(this.state.highlighted.transactionIndex, {
                select: true
              });
            } else {
              this.setHighlighted(this.transactionAt({
                dy: -1
              }).transaction);
            }

            return true;

          case _keycodes.default.SPACE:
          case _keycodes.default.ENTER:
            if (event.shiftKey) {
              this.selectUntil(this.state.highlighted.transactionIndex);
            } else if (event.ctrlKey || event.metaKey) {
              this.send('bulkConfirm');
            } else {
              this.toggleTransaction(this.state.highlighted.transactionIndex);
            }

            return true;

          case _keycodes.default.ESCAPE:
            this.setMode('standard');
            return true;

          case _keycodes.default.DELETE:
          case _keycodes.default.BACKSPACE:
            this.send('bulkDelete');
            return true;

          default:
            // select line
            if (['x', 's'].includes(event.key)) {
              this.toggleTransaction(this.state.highlighted.transactionIndex);
              return true;
            } // unselect line


            if (event.key === 'u') {
              this.send('bulkUnconfirm');
              return true;
            } // select all (except empty row)


            if (event.key === 'a') {
              if (event.ctrlKey || event.metaKey) {
                this.rowTransactions.filter(function (l) {
                  return l.journalEntry.id;
                }).forEach(function (l) {
                  l.set('isSelected', true);
                });
              } else {
                this.send('askClient');
              }

              return true;
            } // tag selected lines


            if (event.key === 't') {
              $('#bulk-tag-input input').focus();
              return true;
            } // categorize selected rows


            if (event.key === 'c' || event.which === _keycodes.default.SPACE) {
              $('#bulk-categorize-input input').focus();
              return true;
            }

        }
      } else {
        if (!this.state.highlighted) {
          return false;
        }

        var columnCount = this.get('columns.length');

        switch (event.which) {
          case _keycodes.default.ARROW_RIGHT:
            if (this.state.highlighted.isEditing) {
              return false;
            }

            this.move({
              dx: 1,
              jump: event.ctrlKey || event.metaKey
            });
            return true;

          case _keycodes.default.ARROW_LEFT:
            if (this.state.highlighted.isEditing) {
              return false;
            }

            this.move({
              dx: -1,
              jump: event.ctrlKey || event.metaKey
            });
            return true;

          case _keycodes.default.ARROW_DOWN:
            if (this.state.highlighted.isEditing) {
              return false;
            }

            if (event.shiftKey) {
              this.set('_bulkSelectionMode', this.state.highlighted.isSelected);
              this.toggleTransaction(this.state.highlighted.row.transaction.transactionIndex);
              this.toggleTransaction(this.state.highlighted.transactionIndex + 1);
            } else {
              this.move({
                dy: 1,
                jump: event.ctrlKey || event.metaKey
              });
            }

            return true;

          case _keycodes.default.ARROW_UP:
            if (this.state.highlighted.isEditing) {
              return false;
            }

            if (event.shiftKey) {
              this.set('_bulkSelectionMode', this.state.highlighted.isSelected);
              this.toggleTransaction(this.state.highlighted.row.transaction.transactionIndex);
              this.toggleTransaction(this.state.highlighted.transactionIndex - 1);
            } else {
              this.move({
                dy: -1,
                jump: event.ctrlKey || event.metaKey
              });
            }

            return true;

          case _keycodes.default.TAB:
            if (event.shiftKey) {
              if (this.state.highlighted.columnIndex > 0) {
                this.move({
                  dx: -1
                });
              } else {
                this.move({
                  x: columnCount,
                  dy: -1
                });
              }
            } else {
              if (this.state.highlighted.columnIndex < columnCount - 1) {
                this.move({
                  dx: 1
                });
              } else {
                this.move({
                  x: 0,
                  dy: 1
                });
              }
            }

            return true;

          case _keycodes.default.SPACE:
            if (event.shiftKey && !this.state.highlighted.isEditing) {
              this.toggleTransaction(this.state.highlighted.row.transaction.transactionIndex);
              return true;
            }

            break;

          case _keycodes.default.ENTER:
            if (event.shiftKey && !this.state.highlighted.isEditing) {
              this.toggleTransaction(this.state.highlighted.row.transaction.transactionIndex);
            } else {
              if (this.state.highlighted.isEditing) {
                // Requirement: If you press enter when a cell is in Editing state, then the focus moves down to the next
                // row; if the next row is the Empty row, then the focus goes to the "Type" column near the left side. If
                // the next row IS populated, then Enter moves directly down to the cell directly beneath the previously
                // focused cell.
                if (this.cellAt({
                  dy: 1
                }).cell.row.rowIndex === this.rows.length - 1) {
                  this.move({
                    dy: 1,
                    x: this.columns.mapBy('key').indexOf('type')
                  });
                } else {
                  this.move({
                    dy: 1
                  });
                }
              } else {
                this.state.highlighted.setEditing(true);
              }
            }

            return true;

          case _keycodes.default.ESCAPE:
            if (this.state.highlighted.isEditing) {
              this.state.highlighted.setEditing(false, {
                revert: true
              });
            }

            return true;

          case _keycodes.default.DELETE: // falls through

          case _keycodes.default.BACKSPACE:
            {
              var cell = this.state.highlighted;

              if (!cell.isEditing) {
                if (cell.hasValue()) {
                  cell.clear();
                  cell.setEditing(false, {
                    save: true
                  });
                } else if (cell.row.transaction.isSplit && ['amount', 'category'].includes(cell.column.key)) {
                  var journalEntry = cell.row.transaction.journalEntry;
                  journalEntry.get('journalEntryLines').removeObject(cell.row.categoryLine);
                  this.incrementProperty('_invalidateRows');
                }

                return true;
              }

              return false;
            }

          default:
            // split
            if (event.key === 's' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
              this.send('split', this.state.highlighted);
              return true;
            } // copy


            if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
              clipboard.write(this.state.highlighted.value());
              return true;
            } // paste


            if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
              var textarea = document.createElement('textarea');
              textarea.style.hidden = true;
              textarea.style.alpha = 0;
              textarea.style.height = 0;
              textarea.style.width = 0;
              textarea.contentEditable = true;
              this.state.highlighted.component.element.appendChild(textarea);
              textarea.focus();
              textarea.select();
              Ember.run.later(this, function () {
                var value = textarea.value;
                textarea.remove();
                var highlighted = _this11.state.highlighted;
                highlighted.setValue(value);
                highlighted.row.transaction.saveEntry(highlighted).catch(function (_e) {});
              }, 100);
              return false;
            } // select all (except empty row)


            if (event.key === 'a') {
              if (event.ctrlKey || event.metaKey) {
                this.toggleTransaction(this.state.highlighted.row.transaction.transactionIndex);
                this.rowTransactions.filter(function (l) {
                  return l.journalEntry.id;
                }).forEach(function (l) {
                  l.set('isSelected', true);
                });
                return true;
              }
            }

            if (this.state.highlighted.isEditing) {
              return false;
            } else {
              if (event.key && event.key.length === 1 && !this.hasModifierKey(event)) {
                this.setHighlighted(this.state.highlighted, {
                  editing: true,
                  value: event.key
                });
                return true;
              }
            }

        }
      }

      return false;
    },
    hasModifierKey: function hasModifierKey(event) {
      return event.altKey || event.ctrlKey || event.metaKey;
    },
    cellAt: function cellAt(opts) {
      var columnCount = this.get('columns.length');
      var rowCount = this.get('rows.length');
      var dx = (opts.dx || 0) * (opts.multiplier || 1);
      var dy = (opts.dy || 0) * (opts.multiplier || 1);
      var newX = opts.x === undefined ? this.state.highlighted.columnIndex + dx : opts.x;
      var newY = opts.y === undefined ? this.state.highlighted.row.rowIndex + dy : opts.y;
      var x = Math.min(columnCount - 1, Math.max(0, newX));
      var y = Math.min(rowCount - 1, Math.max(0, newY));
      var cell = this.cellMap[y + ',' + x];

      if (cell.forwardTo) {
        // if the direction we are heades is DOWN, inferentially from the cell that is the forward target, they will
        // be stuck on that cell. So, if the direction is down in the y direction, that is a special case we need to
        // handle by finding the next non-hidden cell in the down direction
        if (opts.dy > 0) {
          var found;
          var i; // this will try 100 cells down to find one that isn't hidden. If we're at the very bottom of the sheet, its
          // possible we won't find one, in which case, we'll just follow the forward

          for (i = 1; i < 100; i++) {
            var next = this.cellMap[y + i + ',' + x];

            if (next && !next.isHidden) {
              found = next;
              break;
            }
          }

          if (found) {
            cell = found;
          } else {
            cell = cell.forwardTo;
          }
        } else {
          cell = cell.forwardTo;
        }
      }

      return {
        cell: cell,
        outOfBounds: newX < 0 || newX > columnCount - 1 || newY < 0 || newY > rowCount - 1
      };
    },
    transactionAt: function transactionAt(opts) {
      var transactionCount = Object.keys(this.transactionMap).length - 1;
      var dy = (opts.dy || 0) * (opts.multiplier || 1);
      var newY = opts.y === undefined ? this.state.highlighted.transactionIndex + dy : opts.y;
      var y = Math.min(transactionCount - 1, Math.max(0, newY));
      return {
        transaction: this.transactionMap[y],
        outOfBounds: newY < 0 || newY > transactionCount - 1
      };
    },
    move: function move(opts) {
      var _this12 = this;

      if (opts.jump) {
        var currentHasValue = !!this.state.highlighted.hasValue();
        var cell = this.cellAt(opts).cell;
        var nextHasValue = !!cell.hasValue();

        var findNext = function findNext(v) {
          var i; // this should be a while(true), but I like to put a limit just in case theres a bug it doesn't crash their browser

          for (i = 1; i < 10000; i++) {
            var _o = $.extend({}, {
              multiplier: i
            }, opts);

            var result = _this12.cellAt(_o);

            var hasValue = !!result.cell.hasValue();

            if (hasValue === v || result.outOfBounds) {
              return i;
            }
          }
        };

        var multiplier;

        if (currentHasValue && !nextHasValue) {
          multiplier = findNext(true);
        } else if (currentHasValue && nextHasValue) {
          multiplier = findNext(false) - 1;
        } else {
          multiplier = findNext(true);
        }

        var o = $.extend({}, {
          multiplier: multiplier
        }, opts);
        this.setHighlighted(this.cellAt(o).cell, opts);
      } else {
        this.setHighlighted(this.cellAt(opts).cell, opts);
      }
    },
    columns: Ember.computed('columns', 'chart_account_id', 'isFlipped', 'visibleColumns.@each', function () {
      var visible = this.visibleColumns.split(',');
      var hide = ['name', 'comments', 'notes', 'tags'].filter(function (c) {
        return !visible.includes(c);
      });

      if (this.chart_account_id) {
        hide = hide.concat(['account']);
      }

      return [{
        key: 'account',
        title: 'Account',
        path: 'row.accountLine.chartAccount',
        exists: 'id',
        sort: 'accountLine.chartAccount.name'
      }, {
        key: 'type',
        title: 'Type',
        path: 'row.kind',
        sort: 'kind',
        validate: function validate(char) {
          return isNaN(char * 1);
        }
      }, {
        key: 'date',
        title: 'Date',
        path: 'row.dateTransform',
        sort: 'primaryLine.journalEntry.date',
        sortType: 'date',
        validate: function validate(int) {
          return !isNaN(int * 1);
        }
      }, {
        key: 'name',
        title: 'Name',
        path: 'row.transaction.journalEntry.name',
        sort: 'primaryLine.journalEntry.name'
      }, {
        key: 'amount',
        title: 'Amount',
        path: 'row.categoryLine.amount',
        sort: 'amount',
        sortType: 'number',
        validate: function validate(amount) {
          return !isNaN(amount * 1) || amount === '.';
        },
        transform: function transform(amount) {
          return amount * 1;
        }
      }, {
        key: 'category',
        title: 'Category',
        path: 'row.categoryLine.chartAccount',
        exists: 'id',
        sort: 'categorySort'
      }, {
        key: 'tags',
        title: 'Tags',
        path: 'row.tags',
        sort: 'primaryLine.journalEntry.lineTags',
        exists: 'length'
      }, {
        key: 'comments',
        title: 'Comments',
        path: 'row.transaction.journalEntry.comment',
        exists: 'id',
        sort: 'primaryLine.journalEntry.comment.body'
      }, {
        key: 'confirmed',
        title: 'Confirmed',
        path: 'row.transaction.isConfirmed',
        sort: 'primaryLine.journalEntry.confirmed',
        sortType: 'boolean'
      }, {
        key: 'notes',
        title: 'Notes',
        path: 'row.transaction.journalEntry.note',
        sort: 'primaryLine.journalEntry.note'
      }].filter(function (c) {
        return !hide.includes(c.key);
      });
    }),
    toggleTransaction: function toggleTransaction(transactionIndex) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.setMode('bulk');
      var transaction = this.transactionMap[transactionIndex];

      if (opts.select !== undefined) {
        transaction.set('isSelected', opts.select);
      } else {
        transaction.toggleProperty('isSelected');
      }

      if (transaction.isSelected) {
        this.lastSelectedTransactionIndex = transactionIndex;
      }

      this.setHighlighted(transaction);
    },
    selectUntil: function selectUntil(transactionIndex) {
      var index = null;

      if (this.lastSelectedTransactionIndex !== null) {
        index = this.lastSelectedTransactionIndex;
      } else {
        var above = transactionIndex;
        var below = transactionIndex; // this should be a while(true), but I like to put a limit just in case theres a bug it doesn't crash their browser

        var i;

        for (i = 1; i < 10000; i++) {
          above -= 1;
          below += 1;

          if (this.transactionMap[above]) {
            if (this.transactionMap[above].isSelected) {
              index = above;
              break;
            }
          } else {
            index = above + 1;
            break;
          }

          if (this.transactionMap[below]) {
            if (this.transactionMap[below].isSelected) {
              index = below;
              break;
            }
          } else {
            index = below - 1;
            break;
          }
        }
      }

      var start = Math.min(transactionIndex, index);
      var end = Math.max(transactionIndex, index);

      for (i = start; i <= end; i++) {
        this.transactionMap[i].set('isSelected', true);
      }
    },
    setMode: function setMode(mode) {
      var _this13 = this;

      if (mode === 'standard') {
        this.state.mouse = {
          button: 'up'
        };
      }

      var currentMode = this.state.mode;
      var rowIndex;

      if (mode !== currentMode && mode === 'standard') {
        rowIndex = this.state.highlighted.rows.firstObject.rowIndex;
      }

      if (mode !== currentMode) {
        this.dehighlightCurrent();

        if (mode === 'standard') {
          Object.keys(this.transactionMap).forEach(function (idx) {
            var row = _this13.transactionMap[idx];
            row.set('isSelected', false);
          });
        }
      }

      this.set('state.mode', mode);

      if (mode !== currentMode && mode == 'standard') {
        this.setHighlighted(this.cellMap[rowIndex + ',' + 0]);
        this.set('_bulkSelectionMode', null);
      }
    },
    setHighlighted: function setHighlighted(highlighted) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (this.state.highlighted !== highlighted) {
        this.dehighlightCurrent();
      }

      this.set('state.highlighted', highlighted);

      if (highlighted) {
        this.set('state.highlighted', highlighted);
        highlighted.set('isHighlighted', true);

        if (this.state.mode === 'standard') {
          this.set('lastHighlightedCellCoords', {
            y: highlighted.row.rowIndex,
            x: highlighted.columnIndex
          }); // Any time you navigate into on any cell in the empty row, it auto-populates the Type column with the same
          // type as the previous row. It also populates date with the same date as the previous row. It also creates
          // a NEW empty row at that point. If you leave that row with no info other than type/date, then that row
          // disappears (leaving the one empty row).

          var journalEntry = highlighted.row.transaction.journalEntry;

          if (!journalEntry.id && !journalEntry.kind && highlighted.row.rowIndex === this.rows.length - 1) {
            var previousRowCell = this.cellMap[highlighted.row.rowIndex - 1 + ',' + 0];

            if (previousRowCell && previousRowCell.row.transaction.kind) {
              // set kind
              // mazuma said it screwed them up a lot to set this automatically, so I'm not going to
              // journalEntry.set('kind', previousRowCell.row.transaction.kind)
              // highlighted.row.set('kind', previousRowCell.row.transaction.kind)
              // set date
              var date = moment(previousRowCell.row.dateTransform, 'MM/DD/YY');
              journalEntry.set('date', date);
              journalEntry.get('journalEntryLines').forEach(function (line) {
                line.set('date', date.format('YYYY-MM-DD'));
              }); // set account

              highlighted.set('row.accountLine.chartAccount', previousRowCell.get('row.accountLine.chartAccount'));
              highlighted.row.transaction.saveEntry(highlighted).catch(function (_e) {});
            }

            var emptyRow = this.createEmptyRow();
            this.rows.pushObject(emptyRow);
            this.updateRowCache(this.rows); // we DO NOT want to trigger sortedTransactions to recompute here, so we DON'T use pushObject on purpose
            // we do not want to recompute the whole list because we already added a row at the end to be fast.
            // we just want to add it to the original array in case theres a sort or something that DOES cause require
            // a full list reload

            this.transactions.push(emptyRow.transaction);
            this.sortedTransactions.push(emptyRow.transaction);
            this.updateTransactionCache(this.sortedTransactions);
          }
        } // certain moves, like tab auto put us in editing. also date is always editing


        if (opts.editing) {
          highlighted.setEditing(true, {
            value: opts.value
          });
        }

        if (highlighted.component && highlighted.component.element) {
          var top = $(highlighted.component.element).offset().top;
          var height = highlighted.component.element.offsetHeight;
          var scrollTop = $(document).scrollTop();
          var viewportH = $(document.body)[0].getBoundingClientRect().height;
          var fixedNav = $('.content-nav-fixed');
          var fixedNavY = fixedNav.offset().top + fixedNav[0].offsetHeight;
          var headerH = $('.js-sheet-table-header')[0].offsetHeight; // this gives some space on the bottom for dropdown menues etc

          var bottomMargin = 200; // if its off the bottom of the screen

          if (top + height > scrollTop + viewportH - bottomMargin) {
            $(document).scrollTop(top + height - viewportH + bottomMargin);
          } // if its off the top of the screen
          else if (top < fixedNavY) {
              $(document).scrollTop(top - height - height - fixedNav[0].offsetHeight - headerH);
            }
        }
      }
    },
    dehighlightCurrent: function dehighlightCurrent() {
      if (this.get('state.highlighted')) {
        if (this.state.mode === 'standard') {
          this.state.highlighted.setEditing(false);
        }

        this.set('state.highlighted.isHighlighted', false);
        this.set('state.highlighted', null);
      }
    },
    sortChartAccounts: function sortChartAccounts(a, b) {
      var aClass = a.classificationInfo;
      var bClass = b.classificationInfo; // a < b return -1
      // a should be before b return -1

      if (!aClass) {
        return 1;
      }

      if (!bClass) {
        return -1;
      } // if one is critical and one is not


      if (aClass.critical !== bClass.critical) {
        return bClass.critical - aClass.critical;
      } // if both are non-critical, return one with lower priority (which means higher)
      else {
          return aClass.priority - bClass.priority;
        }
    },
    refresh: function refresh() {
      this.rowTransactions.filter(function (l) {
        return l.journalEntry.id;
      }).forEach(function (transaction) {
        transaction.set('isSelected', false);
      });
    },
    refreshMeta: function refreshMeta() {},
    splitInfo: function splitInfo(entry) {
      if (entry.get('journalEntryLines.length') > 2) {
        // splits should just be all lines on the right EXCEPT if theres one critical line and its the only one of its kind (debit/credit)
        // in which case the account should be in a merged cell on the left and all other lines should be on rigth
        var lines = entry.get('journalEntryLines').toArray().sort(function (a, b) {
          if (!a.id) {
            return 1;
          }

          if (!b.id) {
            return -1;
          }

          return a.id - b.id;
        });
        var criticalLines = lines.filterBy('chartAccount.classificationInfo.critical');

        if (criticalLines.length === 1) {
          var criticalLine = lines.findBy('chartAccount.classificationInfo.critical');
          var linesWithSameKind = lines.filterBy('kind', criticalLine.kind);

          if (linesWithSameKind.length == 1) {
            var otherLines = lines.filter(function (l) {
              return l !== criticalLine;
            });
            return {
              isSplit: true,
              accountLine: criticalLine,
              mergedColumns: ['account', 'date', 'type', 'name', 'comments', 'confirmed', 'notes'],
              lines: otherLines
            };
          }
        }

        return {
          isSplit: true,
          accountLine: null,
          mergedColumns: ['account', 'name', 'comments', 'confirmed', 'notes'],
          lines: lines
        };
      } else {
        return {
          isSplit: false,
          accountLine: null,
          mergedColumns: [],
          lines: [entry.get('primaryLine')]
        };
      }
    },
    _rowCategoryLines: Ember.computed.mapBy('rows', 'categoryLine'),
    _journalEntries: Ember.computed.mapBy('rowTransactions', 'journalEntry'),
    bottomTotals: Ember.computed('chart_account_id', '_rowCategoryLines.@each.amount', 'rows.@each.kind', 'rowTransactions.@each.isSelected', function () {
      // have to consume these so that it starts observing
      this.get('_rowCategoryLines');
      this.get('_journalEntries');

      if (this.chart_account_id && this.get('rowTransactions.firstObject.line.modelType') === 'journal-entry-line') {
        var rows = this.rowTransactions;
        var selected = this.get('rowTransactions').filterBy('isSelected');

        if (selected.length > 0) {
          rows = selected;
        }

        rows = rows.filter(function (row) {
          return !!row.kind;
        });
        var grouped = (0, _array.groupBy)(rows, function (t) {
          return t.kind;
        }, 0, function (a, t) {
          var row = t.rows.find(function (r) {
            return r.accountLine.id === t.line.id || r.categoryLine.id === t.line.id;
          });
          return a + +row.categoryLine.amount;
        }); // calculate the totals

        var chartAccount = this.store.peekRecord('chartAccount', this.chart_account_id);
        var kind = chartAccount.classificationInfo.creditRed === true ? 'debit' : 'credit';
        grouped['total'] = rows.reduce(function (a, t) {
          var row = t.rows.find(function (r) {
            return r.accountLine.id === t.line.id || r.categoryLine.id === t.line.id;
          });
          return a + (row.categoryLine.kind === kind ? row.categoryLine.amount * 1 : row.categoryLine.amount * -1);
        }, 0); // Total, Deposit, Debit, Withdrawal, Check, Payment, Credit

        var sortMap = {
          total: 0,
          deposit: 1,
          debit: 2,
          withdrawal: 3,
          check: 4,
          payment: 5,
          credit: 6
        };
        var pairs = Object.keys(grouped).map(function (k) {
          return {
            kind: k,
            total: grouped[k]
          };
        }).sort(function (a, b) {
          return sortMap[a.kind] - sortMap[b.kind];
        });
        return pairs;
      }
    }),
    _bottomBalances: Ember.on('init', Ember.observer('chart_account_id', 'model.lines.meta', '_rowCategoryLines.@each.amount', 'rows.@each.kind', 'bottomTotals.@each.total', function () {
      // have to consume these so that it starts observing
      this.get('_rowCategoryLines');
      this.get('_journalEntries');

      if (this.chart_account_id && this.bottomTotals) {
        var chartAccount = this.store.peekRecord('chartAccount', this.chart_account_id);
        var tags = chartAccount.classificationInfo.tags; // ONLY when you are filtered on an Asset, Equity or Liability account (and all classes within those three)

        if (['oeaf', 'liability', 'asset'].any(function (tag) {
          return tags.includes(tag);
        })) {
          var details = Ember.get(this, 'model.lines.meta.chart_account_details');

          if (details) {
            var endingBalance = details.find(function (k) {
              return k.key === 'ending_balance';
            }).value;
            var change = details.find(function (k) {
              return k.key === 'change_in_balance';
            }).value;
            var startingBalance = endingBalance - change;
            var total = this.bottomTotals.find(function (o) {
              return o.kind === 'total';
            }).total;
            var computedEndingBalance = startingBalance - total;

            if (tags.includes('asset')) {
              computedEndingBalance = startingBalance + total;
            }

            this.set('bottomBalances', {
              startingBalance: startingBalance,
              endingBalance: computedEndingBalance
            });
          }
        }
      }
    })),
    _syncHistorical: Ember.observer('_journalEntries.@each.isConfirmed', '_rowCategoryLines.@each.{name,date,kind,amount,chartAccount}', function () {
      var _this14 = this;

      this.get('_journalEntries');
      this.rowTransactions.mapBy('journalEntry').reduce(function (acc, je) {
        return acc.concat(je.journalEntryLines.toArray());
      }, []).filter(function (l) {
        return !!l.id;
      }).forEach(function (line) {
        var record = _this14.store.peekRecord('historical-journal-entry-line', line.id);

        if (!record) {
          record = _this14.store.createRecord('historical-journal-entry-line', {
            id: line.id
          });
        }

        record.setProperties({
          id: line.id,
          name: line.get('journalEntry.name'),
          enabled: line.get('journalEntry.isConfirmed'),
          date: line.date,
          kind: line.kind,
          amount: line.amount,
          chartAccount: line.chartAccount
        });
      });
    })
  });

  _exports.default = _default;
});