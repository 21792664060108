define("zipbooks/components/reports/balance-sheet-line", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/paid-modal-handler", "zipbooks/utils/tag-serialization"], function (_exports, _testable, _paidModalHandler, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_paidModalHandler.default, _testable.default, {
    session: Ember.inject.service(),
    quickLinkExclude: ['Liabilities and Equity total'],
    actions: {
      viewTransactions: function viewTransactions(chartAccountId) {
        if (this.permissions.can('accounting.read')) {
          this.router.transitionTo('main.transactions', {
            queryParams: {
              chart_account_id: chartAccountId,
              end_date: moment(this.endDate).format('YYYY-MM-DD'),
              start_date: moment(this.startDate).format('YYYY-MM-DD'),
              tags: _tagSerialization.default.serialize(this.tags),
              confirmed: this.include === 'all' ? '' : 'confirmed'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});