define("zipbooks/controllers/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: [{
      verificationCode: 'verification-code'
    }, {
      needsPassword: 'needs-password'
    }],
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    needsPassword: true,
    password: null,
    autoLogin: function autoLogin() {
      var _this = this;

      if (!this.needsPassword) {
        var params = {
          token: this.verificationCode
        };
        this.client.send('PATCH', 'auth', 'verify', params).then(function (resp) {
          _this.store.pushPayload(resp);

          var identity = _this.store.peekRecord('identity', resp.data.id);

          _this.set('identity', identity);

          _this.set('buttonState', 'loaded');

          _this.session.login(identity.userTokens[0].token);

          _this.transitionToRoute('main');

          _this.overlay.showBanner('success', 'Your email has been verified.');
        }, function () {
          _this.transitionToRoute('/login');
        });
      }
    },
    actions: {
      verify: function verify() {
        var _this2 = this;

        this.set('buttonState', 'saving');
        var params = {
          token: this.verificationCode,
          password: this.password
        };
        this.client.send('PATCH', 'auth', 'verify', params).then(function (resp) {
          _this2.store.pushPayload(resp);

          var identity = _this2.store.peekRecord('identity', resp.data.id);

          _this2.set('identity', identity);

          _this2.set('buttonState', 'loaded');

          _this2.session.login(identity.userTokens[0].token);

          _this2.transitionToRoute('main');
        }, function (jqXHR) {
          _this2.overlay.showBanner('error', jqXHR.responseJSON.errors.mapBy('detail').join('<br>'));

          _this2.set('buttonState', 'invalid');
        });
      }
    }
  });

  _exports.default = _default;
});