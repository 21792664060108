define("zipbooks/components/drawers/model-drawer", ["exports", "zipbooks/components/drawers/base-drawer", "zipbooks/utils/titleize", "zipbooks/utils/overridable-computed"], function (_exports, _baseDrawer, _titleize, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    store: Ember.inject.service(),
    layoutName: 'components/drawers/model-drawer',
    fetchOnOpen: true,
    actions: {
      save: function save(model, drawerDelay) {
        var _this = this;

        return model.get('_internalModel').save().then(function () {
          _this.afterSave(model, drawerDelay);

          return model;
        }, function (error) {
          _this.handleError(error);
        });
      }
    },
    modelName: Ember.computed('params', function () {
      return this.get('params.firstObject').key;
    }),
    modelId: Ember.computed('params', function () {
      return this.get('params.firstObject').value;
    }),
    model: (0, _overridableComputed.default)('model', 'modelId', function () {
      var _this2 = this;

      var type = this.modelName;
      var id = this.modelId;

      if (id) {
        var model; // if theres one in the store that we can return now, lets do it

        var existing = this.store.peekRecord(type, id);

        if (existing) {
          model = existing;
        }

        if (!model || this.fetchOnOpen) {
          var promise = this.store.find(type, id); // if we cant get the model, just close the drawer

          promise.catch(function ()
          /*result*/
          {
            _this2.handleClose(0);

            _this2.overlay.showBanner('error', 'There was something wrong with that item. Could not open.');
          });
          model = promise;
        }

        return model;
      } else if (type) {
        var defaultAttributes = this.rest; // remove once this is fixed https://github.com/emberjs/data/issues/5100

        if (type === 'journal-entry') {
          Ember.assign(defaultAttributes, {
            cloudFiles: []
          });
        }

        return this.store.createRecord(type, defaultAttributes);
      } else {
        this.afterModel(null);
      }
    }),
    title: Ember.computed('model.modelType', function () {
      var type = (0, _titleize.default)(this.get('model.modelType'));

      if (this.get('model.isNew')) {
        return 'New ' + type;
      } else {
        return 'Edit ' + type;
      }
    }),
    partialName: Ember.computed('model.modelType', function () {
      return "components/drawers/".concat(this.get('model.modelType'), "-form");
    }),
    _modelDidChange: Ember.on('init', Ember.observer('model', function () {
      var _this3 = this;

      var model = this.model;

      if (this.get('_currentModel') !== model) {
        if (model.then) {
          model.then(function (model) {
            _this3.afterModel(model);
          });
        } else {
          this.afterModel(model);
        }
      }

      this.set('_currentModel', model);
    })),
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      var model = Ember.get(this, 'model.content') || this.model;

      if (model && model.fresh && !model.isDestroyed && !model.isDestroying) {
        if (model.get('id')) {
          model = model.fresh();
          model.eachRelationship(function (name, descriptor) {
            if (descriptor.kind === 'hasMany') {
              // for all related properties that aren't new, rollback
              model.get(name).filter(function (r) {
                return r.get('id');
              }).forEach(function (r) {
                return r.fresh().rollbackAttributes();
              });
            }
          });
        }

        Ember.run.schedule('destroy', this, function () {
          // I didn't use to need this here but then an error in tests just randomly started happening. It looks
          // like the `_internalModel.record` was getting unset and it was resulting in a `Cannot read property '_notifyProperties' of null`
          // error. I'd like to know why it seeminly randomly without cause started happening but I have no idea right now.
          // UPDATE: ok so what happened was I recloned the repo and ember data updated to 2.12.2 from 2.12.0, so I'll
          // need to investigate what changed there, but this seems to fix it nonetheless
          if (!model.get('isEmpty')) {
            model.rollbackAttributes();
          }
        });
      }
    },
    _modelWasDestroyed: Ember.observer('model.isDeleted', function () {
      if (this.get('model.isDeleted')) {
        this.handleClose(0);
      }
    }),
    // Override
    afterModel: function afterModel()
    /*model*/
    {},
    afterSave: function afterSave(model) {
      var drawerDelay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 750;
      var modelName = this.get('model.modelType');
      var notificationCenter = this.notificationCenter;
      Ember.tryInvoke(this.callbacks, 'saved', [model]);
      this.handleClose(drawerDelay, function () {
        notificationCenter.notify(modelName + '.save', model);
      });
    },
    handleError: function handleError()
    /*error*/
    {}
  });

  _exports.default = _default;
});