define("zipbooks/controllers/main/accountant/clients/category-templates", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller"], function (_exports, _controllerPagination, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    overlay: Ember.inject.service(),
    sort: 'name',
    actions: {
      duplicate: function duplicate(model) {
        var _this = this;

        this.client.POST('chart-accounts/template-groups', "".concat(model.id, "/duplicate")).then(function () {
          return _this.notificationCenter.notify('chart-account-template-group.save');
        }, function () {
          return _this.overlay.showBanner('error', 'There was a problem duplicating the template.', {
            title: 'Oops!'
          });
        });
      }
    }
  });

  _exports.default = _default;
});