define("zipbooks/components/messaging/accounts-messenger/transaction-line", ["exports", "zipbooks/components/index-row-content/transaction", "zipbooks/utils/secure-downloader"], function (_exports, _transaction, _secureDownloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transaction.default.extend({
    actions: {
      setCategory: function setCategory(chartAccount) {
        var _this = this;

        var line = this.categoryLine;
        line.set('chartAccount', chartAccount);
        line.get('journalEntry').thenIfExists(function (journalEntry) {
          journalEntry.set('autoConfirm', false);
          journalEntry.save().then(function () {
            if (_this.didSave) {
              _this.didSave(journalEntry, _this);
            }
          });
        });
      },
      downloadFile: function downloadFile(cloudFile) {
        (0, _secureDownloader.default)(cloudFile);
      }
    },
    showUnreadIndicator: Ember.computed('primaryLine.journalEntry.comment.{id,user.isTeamMember}', 'primaryLine.journalEntry.isConfirmed', function () {
      return this.get('primaryLine.journalEntry.comment.id') && !this.get('primaryLine.journalEntry.comment.user.isTeamMember') && !this.get('primaryLine.journalEntry.isConfirmed');
    })
  });

  _exports.default = _default;
});