define("zipbooks/components/datetime-picker", ["exports", "zipbooks/components/date-picker", "zipbooks/mixins/clicked-outside", "zipbooks/utils/keycodes", "zipbooks/utils/normalize-time"], function (_exports, _datePicker, _clickedOutside, _keycodes, _normalizeTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datePicker.default.extend(_clickedOutside.default, {
    timeFormat: 'h:mma',
    classNames: ['datetime-picker'],
    visible: false,
    editingTime: null,
    didInsertElement: function didInsertElement() {
      this.set('editingTime', moment(this.datetime).format('h:mma'));
      this.set('editingDate', moment(this.datetime).format(this.format));
      this.set('value', moment(this.datetime));

      this._super.apply(this, arguments); // remove key navigation


      var picker = this.picker;
      document.removeEventListener('keydown', picker._onKeyChange);
    },
    pikadayOptions: function pikadayOptions() {
      return $.extend(true, this._super(), {
        field: this.$('.calendar-anchor')[0],
        format: this.format,
        yearRange: [1900, new Date().getFullYear()],
        position: this.position || 'bottom left',
        bound: false,
        container: this.$('.calendar-container')[0],
        reposition: false
      });
    },
    click: function click(e) {
      var _this = this;

      if (!this.disabled) {
        var input = this.$('.time-input')[0];

        if (e.target !== input) {
          Ember.run.later(this, function () {
            _this.set('visible', true);

            _this.setupOutsideClickListener();
          }, 50);
        }
      }
    },
    focusIn: function focusIn() {
      this.set('visible', true);
      this.setupOutsideClickListener();
      var input = this.$('.time-input')[0];
      Ember.run.later(this, function () {
        input.setSelectionRange(0, input.value.length);
      }, 100);
      return true;
    },
    focusOut: function focusOut() {
      this._doneEditing();

      return true;
    },
    onselect: function onselect() {
      if (this.visible) {
        this._doneEditing();
      }
    },
    _doneEditing: function _doneEditing() {
      // when done editing, combine the date and time into one moment.js date
      var normalizedDate = this.editingDate;
      var normalizedTime = (0, _normalizeTime.default)(this.editingTime, this.timeFormat);
      var combined = moment(normalizedDate + ' ' + normalizedTime, this.format + ' ' + this.timeFormat);
      this.set('datetime', combined); // now update editingTime to have final formatting

      this.set('editingTime', normalizedTime);
      Ember.tryInvoke(this, 'onDoneEditing');
    },
    formattedDate: Ember.computed('stringValue', function () {
      if (this.stringValue === moment().format(this.format)) {
        return 'Today';
      } else {
        return this.stringValue;
      }
    }),
    keyDown: function keyDown(event) {
      if (event.which === _keycodes.default.TAB) {
        this.set('visible', false);
      }

      return true;
    },

    /*********************
     * ClickedOutsideMixin
     **********************/
    clickableElement: function clickableElement() {
      return this.$();
    },
    didClick: function didClick() {
      this.set('visible', true);
    },
    didClickOutside: function didClickOutside() {
      this._doneEditing();

      this.set('visible', false);
    }
  });

  _exports.default = _default;
});