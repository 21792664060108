define("zipbooks/controllers/main/reconciliation", ["exports", "zipbooks/mixins/list-controller", "zipbooks/mixins/list-controller-selectable", "zipbooks/mixins/list-controller-transactionable", "zipbooks/utils/drawer-link-for", "zipbooks/utils/to-decimal"], function (_exports, _listController, _listControllerSelectable, _listControllerTransactionable, _drawerLinkFor, _toDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_listController.default, _listControllerSelectable.default, _listControllerTransactionable.default, {
    queryParams: ['period_start_date', 'sort', 'direction', 'kind'],
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    direction: 'asc',
    period_start_date: null,
    sort: 'date',
    kind: 'all',
    init: function init() {
      var _this = this;

      this.notificationCenter.addObserver(this, 'journal-entry.save', function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.reloadMeta();
        }
      });
      this.notificationCenter.addObserver(this, 'reload-reconciliation-meta', function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.reloadMeta();
        }
      });

      this._super.apply(this, arguments);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'journal-entry.save');
      this.notificationCenter.removeObserver(this, 'reload-reconciliation-meta');
    },
    reloadMeta: function reloadMeta() {
      var _this2 = this;

      this.client.send('GET', "chart-accounts/".concat(this.get('model.meta.chart_account_id'), "/reconciliation-meta"), "?period_start_date=".concat(this.period_start_date)).then(function (meta) {
        return _this2.set('model.meta', meta);
      });
    },
    transactions: Ember.computed('model.transactions.@each.amount', 'sort', 'model.meta.bank_type', 'model.meta.starting_balance', function () {
      var _this3 = this;

      if (this.sort === 'date' && this.get('model.transactions')) {
        var balance;

        if (this.direction === 'asc') {
          balance = (0, _toDecimal.default)(this.get('model.meta.starting_balance'));
        } else {
          balance = (0, _toDecimal.default)(this.get('model.meta.ending_balance'));
        }

        return this.get('model.transactions').map(function (t) {
          var amount = (0, _toDecimal.default)(t.get('amount'));

          if (t.get('kind') !== _this3.get('model.meta.bank_type')) {
            amount = amount.negated();
          } // only update balance for confirmed entries
          // if in ascending order update balance before setting on jel, otherwise set it after


          if (t.get('journalEntry.isConfirmed') && _this3.direction === 'asc') {
            balance = balance.plus(amount);
          }

          t.set('balance', t.get('journalEntry.isConfirmed') ? balance : null);

          if (t.get('journalEntry.isConfirmed') && _this3.direction === 'desc') {
            balance = balance.minus(amount);
          }

          return t;
        });
      } else {
        return this.get('model.transactions');
      }
    }),
    collection: Ember.computed.alias('transactions'),
    modelType: 'transaction',
    previousMonth: Ember.computed('model.meta.start_date', 'model.meta.first_period_date', function () {
      var firstPeriod = moment(this.get('model.meta.first_period_date'));
      var previousMonth = moment(this.get('model.meta.start_date')).subtract(1, 'month');

      if (firstPeriod.diff(previousMonth, 'days') > 0) {
        return false;
      }

      return previousMonth;
    }),
    nextMonth: Ember.computed('model.meta.start_date', 'periods.firstObject.value', function () {
      var nextMonth = moment(this.get('model.meta.start_date')).add(1, 'month');
      var maxMonth = moment(this.get('periods.firstObject.value'));

      if (nextMonth.diff(maxMonth, 'days') > 0) {
        return false;
      }

      return nextMonth;
    }),
    periods: Ember.computed('model.meta.first_period_date', function () {
      var recEndDay = this.get('model.reconciliation.chartAccount.reconcileEndDay');
      var periods = [];
      var date = moment(this.get('model.meta.first_period_date'));
      var end, days, month, year;

      while (date <= moment()) {
        end = moment(date).add(1, 'month').subtract(1, 'day');
        year = end.format('YYYY');
        month = end.format('MM');

        if (moment(year + '-' + month + '-' + recEndDay, 'YYYY-MM-D').isValid()) {
          end = moment(year + '-' + month + '-' + recEndDay, 'YYYY-MM-D');
        } else {
          days = moment(year + '-' + month, 'YYYY-MM').daysInMonth();
          end = moment(year + '-' + month + '-' + days, 'YYYY-MM-D');
        }

        periods.push({
          value: moment(date).format('YYYY-MM-DD'),
          text: moment(date).format('MMM D') + ' - ' + end.format('MMM D, YYYY')
        });
        date = end.add(1, 'day');
      }

      return periods.map(function (_e, i, a) {
        return a[a.length - 1 - i];
      });
    }),
    actions: {
      selectPeriod: function selectPeriod(date) {
        this.set('period_start_date', moment(date).format('YYYY-MM-DD'));
        this.send('refresh');
      },
      selectKind: function selectKind(kind) {
        this.set('kind', kind);
      },
      plusButtonClicked: function plusButtonClicked(index, mode, position) {
        var transactions = this.transactions;
        var rowIndex = position === 'bottom' ? index : index - 1;
        rowIndex = Math.min(Math.max(rowIndex, 0), transactions.length - 1);
        var transaction = transactions.objectAt(rowIndex);
        var params = (0, _drawerLinkFor.default)(['journal-entry', {
          mode: mode,
          date: transaction.get('date'),
          default_account_id: this.get('model.reconciliation.chartAccountId')
        }]);
        this.transitionToRoute({
          queryParams: {
            drawer: params
          }
        });
      },
      bulkConfirm: function bulkConfirm() {
        var _this4 = this;

        this.bulkConfirm(this.selectedEntries(), function () {
          return _this4.reloadMeta();
        });
      },
      bulkUnconfirm: function bulkUnconfirm() {
        var _this5 = this;

        this.bulkUnconfirm(this.selectedEntries(), function () {
          return _this5.reloadMeta();
        });
      },
      match: function match() {
        var _this6 = this;

        var jes = this.selected.filter(function (je) {
          return je && !!je.get('id');
        });

        if (jes.length === 2) {
          this.match(jes[0], jes[1], function () {
            return _this6.send('refresh');
          });
        }
      },
      bulkDelete: function bulkDelete() {
        var _this7 = this;

        this.bulkDelete(this.selectedEntries(), function () {
          return _this7.send('refresh');
        });
      },
      refreshRoute: function refreshRoute() {
        this.send('refresh');
      }
    },
    selectedEntries: function selectedEntries() {
      var _this8 = this;

      return this.selected.mapBy('journalEntry.id').compact().uniq().map(function (id) {
        return _this8.store.peekRecord('journalEntry', id);
      }).compact();
    },
    selectedAreAllConfirmed: Ember.computed('collection.@each.isSelected', function () {
      return this.selected.every(function (t) {
        return t.get('journalEntry.isConfirmed');
      });
    })
  });

  _exports.default = _default;
});