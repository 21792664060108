define("zipbooks/utils/classification-helpers", ["exports", "zipbooks/utils/classifications"], function (_exports, _classifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    withTag: function withTag(tag) {
      var classes = (0, _classifications.default)();
      var keys = Object.keys(classes).filter(function (key) {
        return classes[key].tags.includes(tag);
      });
      return keys.map(function (key) {
        var body = classes[key];
        body.name = key;
        return body;
      });
    },
    // undefined behavior for invalid classification
    hasTag: function hasTag(classification, tag) {
      return (0, _classifications.default)()[classification].tags.any(function (t) {
        return t == tag;
      });
    }
  };
  _exports.default = _default;
});