define("zipbooks/templates/components/manage-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zn/BQjBy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"label form-check-label form-check-label-start row med-small-gutters align-items-center mb-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-auto\"],[8],[0,\"\\n\"],[4,\"form-input\",null,null,{\"statements\":[[0,\"      \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"class\",\"change\",\"disabled\",\"testId\"],[\"checkbox\",[24,[\"isSelected\"]],\"form-check-input disabled\",[28,\"action\",[[23,0,[]],\"selectUser\",[24,[\"user\"]]],null],[24,[\"disabled\"]],\"user-checkbox\"]]],false],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"form-checkbox \",[28,\"if\",[[24,[\"disabled\"]],\"form-checkbox-disabled\"],null],\" m-0\"]]],[10,\"title\",\"Select User\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"col-auto \",[28,\"if\",[[24,[\"disabled\"]],\"opacity-70\"],null]]]],[8],[0,\"\\n    \"],[1,[28,\"avatar-image\",null,[[\"url\",\"initials\",\"size\"],[[24,[\"user\",\"identity\",\"avatarCloudFile\",\"downloadUrl\"]],[24,[\"user\",\"identity\",\"initials\"]],\"tiny\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"col \",[28,\"if\",[[24,[\"disabled\"]],\"opacity-70\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"user\",\"owner\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"font-size-12px font-weight-bold\"],[8],[0,\"Owner\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"font-size-14px\"],[8],[1,[28,\"truncate\",[[24,[\"user\",\"identity\",\"name\"]],38,true],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"font-size-12px gray\"],[8],[1,[24,[\"user\",\"identity\",\"email\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/manage-user.hbs"
    }
  });

  _exports.default = _default;
});