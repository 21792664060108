define("zipbooks/utils/file-list-to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fileListToArray;

  function fileListToArray(fileList) {
    var files = [];

    for (var i = 0; i < fileList.length; i++) {
      var file = fileList[i];
      files.push(file);
    }

    return files;
  }
});