define("zipbooks/components/content-editable", ["exports", "zipbooks/utils/observe-change", "zipbooks/mixins/testable", "zipbooks/utils/is-control-key", "zipbooks/utils/later", "zipbooks/utils/sanitize", "zipbooks/utils/keycodes"], function (_exports, _observeChange, _testable, _isControlKey, _later, _sanitize, _keycodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    classNameBindings: ['editable:-editable'],
    attributeBindings: ['title'],
    placeholderColor: '#bbb',
    default: null,
    active: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().attr('contenteditable', this.editable);
    },
    focusIn: function focusIn() {
      this._super.apply(this, arguments);

      this.set('focused', true);
    },
    keyPress: function keyPress(event) {
      if (this.format === 'number') {
        // if the string typed is not a number, hyphen, backspace, or period prevent it
        switch (event.which) {
          case _keycodes.default.BACKSPACE:
          case _keycodes.default.DELETE:
          case _keycodes.default.DASH:
            return;

          default:
            break;
        }

        if (isNaN(String.fromCharCode(event.which)) || [_keycodes.default.SPACE, _keycodes.default.ENTER].includes(event.which)) {
          event.preventDefault();
        }
      }

      return true;
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      this.set('focused', false);
      Ember.tryInvoke(this, 'onBlur');
    },
    paste: function paste() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _later.default)(100, function () {
        _this.set('selfSetValue', _this.text());

        _this.set('value', _this.text());

        Ember.tryInvoke(_this, 'didChange');
      });
    },
    keyUp: function keyUp(event) {
      if (this.$() && !(0, _isControlKey.default)(event.which, ['ENTER', 'SPACE'])) {
        this.set('selfSetValue', this.text());
        this.set('value', this.text());
        Ember.tryInvoke(this, 'didChange');
      }

      var character = String.fromCharCode(event.which);
      Ember.tryInvoke(this, 'key-up', [character, event]);
      return true;
    },
    attributesChanged: (0, _observeChange.default)('value', 'focused', 'editable', function ()
    /*changes*/
    {
      var _this2 = this;

      if (this.selfSetValue && this.value === this.selfSetValue) {
        return;
      }

      var color;
      var text;
      var value = this.value || this.default;

      if (this.editable && Ember.isEmpty(value) && this.focused) {
        text = null;
        color = this.active ? '#414141' : '#BEC5CB';
      } else if (this.editable && Ember.isEmpty(value) && !this.focused) {
        text = this.placeholder;
        color = this.placeholderColor;
      } else {
        text = value;
        color = this.active ? '#414141' : '#BEC5CB';
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        text = (0, _sanitize.default)(text);
        var html = text.replace(/\n/g, '<br>'); // for firefox, make sure there is a least a break

        if (html === '') {
          html = '<br>' + html;
        }

        _this2.$().html(html);

        _this2.$().css({
          color: color
        });
      });
    }, {
      init: true
    }),
    editableDidChange: Ember.observer('editable', function () {
      this.$().attr('contenteditable', this.editable);
    }),
    text: function text() {
      var html = (this.$().html() || ''). // content editable is very stupid about adding line breaks
      replace(/(<div>|)<br>/g, '\n').replace(/<div>/g, '\n').replace(/\s+$/, ''); // strip everything else using query (and regex, cause for some reason text doesnt get it all)

      return (0, _sanitize.default)(html);
    }
  });

  _exports.default = _default;
});