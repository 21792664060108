define("zipbooks/components/messaging/accountant-messenger", ["exports", "ember-data", "zipbooks/utils/keycodes", "zipbooks/mixins/infinite-scrollable", "zipbooks/utils/insert-at-cursor"], function (_exports, _emberData, _keycodes, _infiniteScrollable, _insertAtCursor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_infiniteScrollable.default, {
    classNames: ['accountant-messenger'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    overlay: Ember.inject.service(),
    router: Ember.inject.service(),
    client: Ember.inject.service(),
    latestMessageLoaded: true,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'new-message', function (message) {
        if (_this.get('allLoaded-down')) {
          if (message.get('account.id') === _this.get('session.account.id')) {
            _this.get('messengerModel.messages').pushObject(message);

            _this.$('.js-scroll').animate({
              scrollTop: _this.$('.js-scroll')[0].scrollHeight
            }, 1000);
          }
        }
      });
      this.notificationCenter.addObserver(this, 'joined-messages-channel', function () {
        if (_this.get('allLoaded-down')) {
          var id = _this.get('messengerModel.messages').mapBy('id').sort(function (a, b) {
            return a * 1 - b * 1;
          }).lastObject;

          _this.store.query('message', {
            sort: 'id',
            filter: {
              after_id: id
            }
          }).then(function (more) {
            var array = more.toArray();
            array.sort(function (a, b) {
              return a.id * 1 - b.id * 1;
            });

            _this.get('messengerModel.messages').pushObjects(array);

            if (_this.$('.js-scroll')) {
              _this.$('.js-scroll').animate({
                scrollTop: _this.$('.js-scroll')[0].scrollHeight
              }, 1000, 'swing');
            }
          });
        }
      });
      var saved = this.session.get('_accountantMessengerDraft');

      if (saved && saved.length > 0 && (!this.draft || this.draft.length === 0)) {
        this.set('draft', saved);
      } // as soon as the messenger opens, we mark all the messages as read


      this.client.PATCH('messages/mark-all-read').then(function () {
        _this.store.peekAll('message').forEach(function (m) {
          m.set('readAt', moment());
        });
      });
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'new-message');
      this.notificationCenter.removeObserver(this, 'joined-messages-channel');
    },
    messengerModel: Ember.computed(function () {
      var _this2 = this;

      var id = this.get('model.anchorLabelId');

      var latestMessages = function latestMessages() {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.hash({
            messages: _this2.store.query('message', {
              sort: '-id'
            }).then(function (results) {
              return results.toArray().sort(function (a, b) {
                return a.id * 1 - b.id * 1;
              });
            }),
            teamAccounts: _this2.store.query('teamAccount', {})
          })
        });
      };

      if (id) {
        this.set('latestMessageLoaded', false);
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.hash({
            before: this.store.query('message', {
              filter: {
                before_anchor: id
              }
            }),
            after: this.store.query('message', {
              filter: {
                after_anchor: id
              }
            }),
            teamAccounts: this.store.query('teamAccount', {})
          }).then(function (results) {
            var b = results.before.toArray().sort(function (a, b) {
              return a.id * 1 - b.id * 1;
            });
            var a = results.after.toArray().sort(function (a, b) {
              return a.id * 1 - b.id * 1;
            });
            return {
              messages: b.concat(a),
              teamAccounts: results.teamAccounts
            };
          }, function (_error) {
            return latestMessages();
          })
        });
      } else {
        return latestMessages();
      }
    }),
    _messagesDidLoad: Ember.observer('messengerModel.isPending', function () {
      var _this3 = this;

      if (!this.messengerModel.isPending && !this.messengerModel.isRejected) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var div = _this3.$('.js-loaded');

          div.css('opacity', '0');
          div.animate({
            opacity: 1
          }, 330);

          var el = _this3.$('.js-scroll')[0];

          if (_this3.$(".js-message-for-".concat(_this3.get('model.anchorLabelId')))[0]) {
            el.scrollTop = _this3.$(".js-message-for-".concat(_this3.model.anchorLabelId))[0].offsetTop - 70;
          } else {
            el.scrollTop = el.scrollHeight; // put keyboard focus in the compose field

            _this3.$('textarea').focus();
          }

          _this3.registerInfiniteScroll('.js-scroll');
        });
      }
    }),
    _newMessageLinkAvailable: Ember.on('init', Ember.observer('model.suggestedMessage.label', function () {
      var suggestedMessage = this.get('model.suggestedMessage');

      if (suggestedMessage) {
        var template = this.session.get('account.settings.messagingDefaultBody');

        if (Ember.typeOf(suggestedMessage) === 'string') {
          this.set('draft', suggestedMessage);
        } else if (suggestedMessage.link && suggestedMessage.link.length > 0 && template && template.length > 0) {
          var body = template.replace('::transaction-tag-link::', suggestedMessage.link);
          this.set('draft', body);
        }
      }
    })),
    actions: {
      keyDown: function keyDown(_content, event) {
        this.session.set('_accountantMessengerDraft', this.draft);

        if (event.which === _keycodes.default.ENTER && !event.shiftKey && !event.metaKey && !event.ctrlKey) {
          if (event) {
            event.preventDefault();
            event.stopPropagation();
          }

          this.send('sendMessage');
        } // by default return + enter does nothing, but we want it to return
        else if (event.which === _keycodes.default.ENTER && event.ctrlKey) {
            (0, _insertAtCursor.default)(this.$('.js-new-message')[0], '\n');
          }
      },
      sendMessage: function sendMessage() {
        var _this4 = this;

        var content = this.draft;
        var message = this.store.createRecord('message', {
          fromChannel: 'zipbooks',
          body: content
        });
        var label = this.get('model.suggestedMessage.label');

        if (label) {
          message.set('label', label);
        } // if they are an accountant


        if (this.get('session.currentUser.isTeamMember')) {
          var recipients = [this.store.createRecord('message-recipient', {
            channel: 'zipbooks',
            message: message,
            recipientable: this.session.account
          })];
          this.store.query('user', {
            filter: {
              archived: false
            },
            sort: 'id'
          }).then(function (users) {
            users = users.filter(function (u) {
              return u.permissionsMap.accounting >= 1;
            });
            var smsUser = users.find(function (u) {
              return u.phone && u.phone.length > 0;
            });

            if (smsUser) {
              recipients.push(_this4.store.createRecord('message-recipient', {
                channel: 'sms',
                username: smsUser.phone,
                message: message,
                recipientable: smsUser
              }));
              message.set('recipients', recipients);

              _this4.saveMessage(message);

              _this4.set('draft', '');
            } else {
              _this4.overlay.showDialog('overlays/dialogs/action-confirm', "Can't send message", {
                title: 'To send an SMS message to this client, ZipBooks needs the phone number of at least one client team member with accounting permissions.',
                confirmButton: {
                  title: 'View team members',
                  class: 'btn-purple'
                },
                cancelButton: {
                  title: 'Send in-app only',
                  class: 'btn-white'
                }
              }, {
                confirm: function confirm() {
                  _this4.router.transitionTo('main.settings.team', {
                    queryParams: {
                      drawer: null
                    }
                  });
                },
                close: function close() {},
                cancel: function cancel() {
                  _this4.set('draft', '');

                  message.set('recipients', recipients);

                  _this4.saveMessage(message);
                }
              });
            }
          });
        } // otherwise, a client user
        else {
            this.set('draft', '');
            this.store.findAll('teamAccount').then(function (teamAccounts) {
              var account = _this4.store.peekRecord('account', teamAccounts.firstObject.id) || _this4.store.createRecord('account', {
                id: teamAccounts.firstObject.id
              });

              account.transitionTo('loaded.saved');
              message.set('recipients', [_this4.store.createRecord('message-recipient', {
                channel: 'zipbooks',
                message: message,
                recipientable: account
              })]);

              _this4.saveMessage(message);
            });
          }
      },
      close: function close() {
        this.overlay.close(this.ref);
      },
      putCursorAtEnd: function putCursorAtEnd() {
        this.$('textarea').focus();
      }
    },
    resetToNewestMessagesIfNeeded: function resetToNewestMessagesIfNeeded(cb) {
      var _this5 = this;

      if (!this.latestMessageLoaded) {
        this.set('latestMessageLoaded', true);
        this.store.query('message', {
          sort: '-id'
        }).then(function (results) {
          _this5.set('messengerModel.messages', results.toArray().sort(function (a, b) {
            return a.id * 1 - b.id * 1;
          }));

          cb();
        });
      } else {
        cb();
      }
    },
    saveMessage: function saveMessage(message) {
      var _this6 = this;

      this.set('infiniteScrollEnabled', false);
      this.resetToNewestMessagesIfNeeded(function () {
        message.save().then(function (message) {
          _this6.session.set('_accountantMessengerDraft', null);

          if (_this6.get('model.suggestedMessage')) {
            _this6.set('model.suggestedMessage', null);
          }

          _this6.get('messengerModel.messages').pushObject(message);

          if (_this6.$('.js-scroll')) {
            _this6.$('.js-scroll').animate({
              scrollTop: _this6.$('.js-scroll')[0].scrollHeight
            }, 1000, 'swing', function () {
              _this6.set('infiniteScrollEnabled', true);
            });
          }
        });
      });
    },

    /*
     * InfiniteScrollableMixin
     */
    loadMore: function loadMore(direction, callback) {
      var _this7 = this;

      var sortedById = this.get('messengerModel.messages').mapBy('id');
      sortedById.sort(function (a, b) {
        return a * 1 - b * 1;
      });

      if (direction === 'down') {
        var id = sortedById.lastObject;
        this.store.query('message', {
          filter: {
            after_id: id
          }
        }).then(function (more) {
          var array = more.toArray();
          array.sort(function (a, b) {
            return a.id * 1 - b.id * 1;
          });

          _this7.get('messengerModel.messages').pushObjects(array);

          callback(true, array.length === 0);
        });
      } else if (direction === 'up') {
        var _id = sortedById.firstObject;
        this.store.query('message', {
          filter: {
            before_id: _id
          }
        }).then(function (more) {
          var array = more.toArray();
          array.sort(function (a, b) {
            return a.id * 1 - b.id * 1;
          });

          _this7.get('messengerModel.messages').unshiftObjects(array);

          callback(true, array.length === 0);
        });
      }
    }
  });

  _exports.default = _default;
});