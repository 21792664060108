define("zipbooks/mixins/permission-redirect", ["exports", "zipbooks/utils/route-info"], function (_exports, _routeInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.routeName) {
        this.checkRoute(this.routeName.substring(5));
      }
    },
    checkRoute: function checkRoute(route) {
      var info = (0, _routeInfo.default)()[route];

      if (!this.hasPermission(info)) {
        this.findAllowedRoute();
      }
    },
    hasPermission: function hasPermission(info) {
      var _this = this;

      if (info.anyPermissions) {
        return info.anyPermissions.any(function (p) {
          return _this.permissions.can(p);
        });
      } else {
        return info.permissions.every(function (p) {
          return _this.permissions.can(p);
        });
      }
    },
    findAllowedRoute: function findAllowedRoute() {
      if (this.permissions.can('account.read')) {
        this.transitionTo('main.dashboard');
      } else if (this.permissions.can('time_tracking.read')) {
        this.transitionTo('main.time-entries');
      } else if (this.permissions.can('accounting.read')) {
        this.transitionTo('main.transactions');
      } else if (this.permissions.can('invoices.read')) {
        this.transitionTo('main.invoices');
      } else if (this.permissions.can('estimates.read')) {
        this.transitionTo('main.estimates');
      } else if (this.permissions.can('projects.read')) {
        this.transitionTo('main.projects');
      } else if (this.permissions.can('contacts.read')) {
        this.transitionTo('main.contacts');
      } else if (this.permissions.can('reports.read')) {
        this.transitionTo('main.reports');
      } else if (this.permissions.can('banks.all')) {
        this.transitionTo('main.bank-accounts');
      } else if (this.permissions.can('users.read')) {
        this.transitionTo('main.settings.team');
      } else if (this.permissions.can('payroll.all')) {
        this.transitionTo('main.payroll');
      }
    }
  });

  _exports.default = _default;
});