define("zipbooks/components/drawers/contact-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    classNames: ['drawer-large'],
    session: Ember.inject.service(),
    actions: {
      selectCountry: function selectCountry(country) {
        this.set('model.country', country && country.id);
      },
      save: function save(model) {
        var filter = this.get('router._routerMicrolib.state.queryParams.kind');

        if (filter) {
          if (filter === 'customers') {
            model.set('isCustomer', true);
          } else if (filter === 'vendors') {
            model.set('isVendor', true);
          }
        }

        this._super(model);
      }
    }
  });

  _exports.default = _default;
});