define("zipbooks/components/overlays/pop-over", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    overlay: Ember.inject.service(),
    direction: 'left',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var anchor = $(this.get('model.anchor'));
      var content = this.$('.popover');
      var width = content.outerWidth();
      var height = content.outerHeight();
      var anchorMiddle = anchor.outerWidth() / 2.0;
      var offset = anchor.offset();
      var left = offset.left;
      var top = offset.top;
      var arrowInset = 25;

      if (this.get('model.model.direction') === 'center') {
        left -= width / 2.0;
      } else if (this.get('model.model.direction') === 'right') {
        left = left - width + arrowInset;
      } else if (this.get('model.model.direction') === 'left') {
        left -= arrowInset;
      } else if (this.get('model.model.direction') === 'top') {
        left -= width / 2.0;
        top = top - height - arrowInset;
      }

      content.offset({
        top: top + anchor.outerHeight() + 10,
        left: left + anchorMiddle
      });
    },
    actions: {
      closePopover: function closePopover() {
        this.overlay.close(this.ref);

        if (this.get('callbacks.close')) {
          this.get('callbacks.close')();
        }
      }
    }
  });

  _exports.default = _default;
});