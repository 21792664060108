define("zipbooks/components/drawers/paypal-integration-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    session: Ember.inject.service(),
    title: 'Integration Preferences',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('email', this.get('session.account.paypalEmailAddress'));
    },
    actions: {
      save: function save() {
        var _this = this;

        this.set('session.account.paypalEmailAddress', this.email);
        this.get('session.account').then(function (account) {
          account.save();

          _this.send('close');
        });
      }
    }
  });

  _exports.default = _default;
});