define("zipbooks/templates/components/datetime-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8neBIcKJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row p-0\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-6 position-relative\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"class\",\"autocomplete\",\"name\",\"placeholder\",\"disabled\",\"title\",\"testId\"],[[24,[\"editingTime\"]],[28,\"concat\",[[28,\"concat\",[\"border-0 font-size-14px gray-850 w-6rem time-input \",[28,\"if\",[[24,[\"labelString\"]],\"down\"],null]],null],\" \",[28,\"if\",[[24,[\"hasErrors\"]],\"input-error\"],null],\" \"],null],\"off\",\"date\",[24,[\"placeholder\"]],[24,[\"disabled\"]],[24,[\"title\"]],[24,[\"testId\"]]]]],false],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label-inside\"],[8],[1,[22,\"labelString\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-6 pr-0\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"class\"],[[24,[\"editingDate\"]],\"hidden calendar-anchor\"]]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"datetime-picker-date\"],[8],[1,[22,\"editingDate\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"calendar-container \",[28,\"if\",[[24,[\"visible\"]],\"\",\"hidden\"],null]]]],[10,\"style\",\"position: absolute\"],[8],[9],[0,\"\\n\"],[15,\"partials/errors\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "zipbooks/templates/components/datetime-picker.hbs"
    }
  });

  _exports.default = _default;
});