define("zipbooks/controllers/main/accountant/clients/index", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller", "zipbooks/utils/throttle", "zipbooks/utils/is-control-key", "zipbooks/mixins/archive-actionable"], function (_exports, _controllerPagination, _indexController, _throttle, _isControlKey, _archiveActionable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, _archiveActionable.default, {
    queryParams: ['status'],
    status: 'all-clients',
    actions: {
      updateSearch: function updateSearch(_, event) {
        this.set('isRefreshing', true);

        if (!(0, _isControlKey.default)(event.which)) {
          (0, _throttle.default)(this, 'updateResultsMatching', 1000);
        }
      },
      refresh: function refresh() {
        this.send('refreshRoute');
      }
    },
    updateResultsMatching: function updateResultsMatching() {
      var _this = this;

      var queryParams = {
        kind: 'client',
        page: {
          page: this.page || 1,
          'page-size': 100
        },
        filter: {
          status: this.status
        }
      };
      var originalSearchText = this.searchText;

      if (this.searchText) {
        queryParams.filter.name = originalSearchText;
      }

      this.store.query('contact', queryParams).then(function (models) {
        if (originalSearchText === _this.searchText) {
          _this.set('model', models);

          _this.set('isRefreshing', false);
        }
      });
    }
  });

  _exports.default = _default;
});