define("zipbooks/controllers/main/projects", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller", "zipbooks/mixins/archive-actionable"], function (_exports, _controllerPagination, _indexController, _archiveActionable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, _archiveActionable.default, {
    queryParams: ['archived', 'show', 'contact_id'],
    contact_id: null,
    archived: false,
    sort: 'name',
    show: 'primary',
    collection: Ember.computed.alias('model.projects'),
    actions: {
      filterByContact: function filterByContact(contact) {
        this.transitionToRoute({
          queryParams: {
            contact_id: contact && contact.get('id'),
            page: 1
          }
        });
      }
    }
  });

  _exports.default = _default;
});