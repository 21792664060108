define("zipbooks/templates/components/list-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rybGZdim",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"checked\",\"disabled\",\"click\",\"class\"],[\"checkbox\",[24,[\"model\",\"isSelected\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"clicked\",[24,[\"model\"]]],[[\"allowedKeys\"],[\"shift\"]]],\"form-check-input\"]]],false],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"form-checkbox form-checkbox-no-margin \",[28,\"if\",[[24,[\"tooltip\"]],\"red left tooltip-element\"],null]]]],[11,\"data-test-id\",[22,\"testId\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"tooltip\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"path1\"],[8],[9],[7,\"span\",true],[10,\"class\",\"path2\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"tooltip-content\"],[8],[1,[22,\"tooltip\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/list-check.hbs"
    }
  });

  _exports.default = _default;
});