define("zipbooks/templates/components/stealth-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gzQYtxOA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"position: fixed; bottom: 0px; right: 0px; background-color: white\"],[8],[0,\"\\n  \"],[7,\"b\",true],[8],[0,\"Account ID:\"],[9],[0,\" \"],[1,[24,[\"session\",\"account\",\"id\"]],false],[0,\"\\n   \\n  \"],[7,\"b\",true],[8],[0,\"User ID:\"],[9],[0,\" \"],[1,[24,[\"session\",\"currentUser\",\"id\"]],false],[0,\"\\n   \\n  \"],[7,\"b\",true],[8],[0,\"Identity ID:\"],[9],[0,\" \"],[1,[24,[\"session\",\"currentUser\",\"identity\",\"id\"]],false],[0,\"\\n   \\n  \"],[7,\"b\",true],[8],[0,\"Plan:\"],[9],[0,\" \"],[1,[24,[\"session\",\"subscription\",\"plan\",\"name\"]],false],[0,\"\\n   \\n  \"],[7,\"a\",true],[11,\"href\",[29,[\"https://dashboard.stripe.com/\",[28,\"if\",[[28,\"eq\",[[24,[\"session\",\"environment\"]],\"development\"],null],\"test/\",\"\"],null],\"customers/\",[24,[\"session\",\"account\",\"stripeCustomerId\"]]]]],[10,\"class\",\"blue\"],[10,\"target\",\"_blank\"],[8],[1,[24,[\"session\",\"account\",\"stripeCustomerId\"]],false],[9],[0,\"\\n   \\n  \"],[7,\"span\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleSoftRelease\"],null]],[10,\"class\",\"cursor-pointer\"],[8],[7,\"b\",true],[8],[0,\"Soft release:\"],[9],[0,\" \"],[1,[24,[\"session\",\"softRelease\"]],false],[9],[0,\"\\n   \\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/stealth-bar.hbs"
    }
  });

  _exports.default = _default;
});