define("zipbooks/helpers/chart-account-color", ["exports", "zipbooks/utils/classifications"], function (_exports, _classifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chartAccountColor = chartAccountColor;
  _exports.default = void 0;

  function chartAccountColor(params
  /*, hash*/
  ) {
    return ((0, _classifications.default)()[params[0]] || 'Assets').color;
  }

  var _default = Ember.Helper.helper(chartAccountColor);

  _exports.default = _default;
});