define("zipbooks/components/drawers/add-to-invoice-form", ["exports", "zipbooks/components/drawers/base-drawer", "ember-data"], function (_exports, _baseDrawer, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    title: 'Add to Invoice',
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var projectParams = {
        filter: {
          archived: false,
          contact_id: this.get('model.id'),
          show: 'primary',
          billable: true
        }
      };
      var projectPromise;

      if (this.session.hasPrivilege('projects')) {
        projectPromise = {
          promise: this.store.cachedQuery('project', projectParams).then(function (results) {
            return results.toArray();
          })
        };
      }

      this.set('formModel', Ember.Object.create({
        view: 'detailed',
        show: {
          date: true,
          notes: true,
          project: true,
          teamMember: true
        },
        projects: _emberData.default.PromiseArray.create(projectPromise || []),
        includeExpenses: true,
        noProject: true
      }));
    },
    actions: {
      add: function add() {
        var _this = this;

        var model = this.formModel;
        var projectIds = model.get('projects').mapBy('id');
        var show = Object.keys(model.get('show')).filter(function (key) {
          return model.get("show.".concat(key)) === true;
        }).map(function (key) {
          return Ember.String.underscore(key);
        });

        if (show.length > 0 || projectIds.length === 0 && model.get('noProject') === false) {
          this.get('callbacks.addLineItems')({
            view: model.get('view'),
            show: show,
            project_ids: projectIds,
            include_expenses: model.get('includeExpenses'),
            time_entries_from_date: model.get('timeEntriesFromDate') ? moment(model.get('timeEntriesFromDate')).format('Y-MM-DD') : undefined,
            time_entries_to_date: model.get('timeEntriesToDate') ? moment(model.get('timeEntriesToDate')).format('Y-MM-DD') : undefined,
            expenses_from_date: model.get('expensesFromDate') ? moment(model.get('expensesFromDate')).format('Y-MM-DD') : undefined,
            expenses_to_date: model.get('expensesToDate') ? moment(model.get('expensesToDate')).format('Y-MM-DD') : undefined,
            no_project: model.get('noProject'),
            contact_id: this.get('model.id')
          }, function () {
            _this.handleClose();
          });
        } else {
          this.set('error', 'You must check at least one field to show.');
        }
      }
    }
  });

  _exports.default = _default;
});