define("zipbooks/components/drawers/bank-member-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/utils/log", "zipbooks/mixins/add-bank"], function (_exports, _modelDrawer, _log, _addBank) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend(_addBank.default, {
    title: 'Connect Bank Account',
    // STARTS HERE
    afterModel: function afterModel(model) {
      // if they clicked the edit button on an existing account
      if (model && model.get('id')) {
        (0, _log.default)('info', 'checking status for existing member');
        this.set('state', 'loading');
        this.checkExisting(model);
      } // if they clicked the plus button
      else {
          this.set('state', 'loading'); // the model drawer will create a blank record for us, we do not want it

          model.unloadRecord();
          (0, _log.default)('info', 'connecting new account');
          this.connectNew();
        }
    },

    /************************************************************************
     * AddBankMixin
     ************************************************************************/
    bankSuccessfullyImporting: function bankSuccessfullyImporting() {
      this.close();
    },

    /************************************************************************
     * Fix Bank Account Problem
     ************************************************************************/
    checkExisting: function checkExisting(member) {
      switch (member.status) {
        case 'failed':
        case 'blocked':
        case 'invalid_credentials':
        case 'not_supported':
        case 'closed':
        case 'disabled':
          this.fix(member);
          break;

        default:
          this.fix(member);
          break;
      }
    },
    fix: function fix(member) {
      var _this = this;

      (0, _log.default)('info', 'fixing existing bank account');
      this.showWidget({
        guid: member.externalId
      }).then(function () {
        (0, _log.default)('info', 'calling sync and being done fixing'); // we'll just fire this off and hope for the best. if it doesnt work, no use showing an error

        _this.client.send('POST', 'bank-members', "".concat(member.id, "/sync"));

        _this.successfullyFixed();
      });
    },

    /************************************************************************
     * Helpers
     ************************************************************************/
    successfullyFixed: function successfullyFixed() {
      this.set('state', 'default');
      this.set('message', 'This bank connection is established and working properly.');
    },
    handleClose: function handleClose() {
      this.client.send('POST', 'bank-members', 'check-for-new-members');

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});