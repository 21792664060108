define("zipbooks/components/suggest/chart-account-select", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/search-in", "zipbooks/utils/observe-change"], function (_exports, _autoSuggest, _searchIn, _observeChange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Category',
    layoutName: 'components/auto-suggest',
    store: Ember.inject.service(),
    filter: [],
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var results = this.store.chartAccounts({
        filter: this.filter
      });
      return (0, _searchIn.default)(results, ['name', 'code'], search);
    },
    labelForResult: function labelForResult(result) {
      return result.get('name');
    },
    handleAddNew: function handleAddNew(label) {
      var _this = this;

      var newChartAccount = this.store.createRecord('chartAccount', {
        name: label
      });
      this.overlay.showDrawer('drawers/chart-account-form', newChartAccount, {
        saved: function saved(model) {
          _this.trySelect(model);
        }
      });
    },
    sort: function sort(a, b) {
      return b.get('viewable') - a.get('viewable');
    },
    // clear if the transaction chart account filter changes
    _filteredChange: (0, _observeChange.default)('bound', function (c) {
      // if was filtered and is not not filtered
      if (!c.bound.new && c.bound.new !== c.bound.old) {
        this.clear();
      }
    }, {
      init: true
    })
  });

  _exports.default = _default;
});