define("zipbooks/controllers/main/reports/cash-flow-statement", ["exports", "zipbooks/utils/parse-number", "zipbooks/mixins/tag-filterable-controller", "zipbooks/mixins/reports/date-error", "zipbooks/utils/tag-serialization"], function (_exports, _parseNumber, _tagFilterableController, _dateError, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tagFilterableController.default, _dateError.default, {
    queryParams: ['start_date', 'end_date', 'group_by'],
    start_date: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    end_date: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    group_by: 'month',
    showTotalOption: 'show',
    currentlyScrolling: false,
    showScrollNav: Ember.computed('columnCount', function () {
      return this.get('columnCount') > 1;
    }),
    showTotal: Ember.computed('group_by', 'showTotalOption', function () {
      return this.group_by === 'total' || this.showTotalOption === 'show';
    }),
    setup: function setup() {
      Ember.run.schedule('afterRender', this, function () {
        var colsLeftValue = (0, _parseNumber.default)($('.period-col:first').css('left'));
        this.enableDisableScroll(colsLeftValue);
      });
    },
    enableDisableScroll: function enableDisableScroll(colsLeftValue) {
      var numCols = this.get('columnCount');
      var colWidth = $('.period-col:first').width();
      var containerWidth = $('.period-cols-container').width();
      var numShowingCols = (0, _parseNumber.default)(containerWidth / colWidth).round(0); // Adjust disabled classes

      if ((0, _parseNumber.default)(colsLeftValue).round(0) <= 0) {
        $('.scroll-right').addClass('disabled');
      } else {
        $('.scroll-right').removeClass('disabled');
      }

      if ((0, _parseNumber.default)(colsLeftValue).round(0) >= (0, _parseNumber.default)(numCols * colWidth - colWidth * numShowingCols).round(0)) {
        $('.scroll-left').addClass('disabled');
      } else {
        $('.scroll-left').removeClass('disabled');
      }
    },
    actions: {
      selectPeriod: function selectPeriod(period) {
        this.set('group_by', period);

        if (period === 'total') {
          this.set('showTotal', true);
        }

        this.send('generate');
      },
      generateReport: function generateReport() {
        this.send('generate');
      },
      selectShowTotal: function selectShowTotal(showTotal) {
        this.set('showTotal', showTotal === 'true');
      },
      scroll: function scroll(direction, e) {
        var _this = this;

        if (this.get('currentlyScrolling') === false) {
          // Performing the action, don't allow another one at the same time
          this.set('currentlyScrolling', true);
          var colWidth = $('.period-col:first').width();
          var colsLeftValue = (0, _parseNumber.default)($('.period-col:first').css('left'));

          if (!$(e.target).hasClass('disabled')) {
            // Do the animation
            var newColsLeftValue;

            if (direction === 'left') {
              newColsLeftValue = colsLeftValue + colWidth;
            } else if (direction === 'right') {
              newColsLeftValue = colsLeftValue - colWidth;
            }

            $('.period-col').animate({
              left: newColsLeftValue
            }, 'fast', function () {
              _this.set('currentlyScrolling', false);
            });
            this.enableDisableScroll(newColsLeftValue);
          } else {
            this.set('currentlyScrolling', false);
          }
        }
      },
      viewTransactions: function viewTransactions(tag) {
        var startDate,
            endDate,
            date = this.get('group'),
            month,
            year;
        var maxStartDate = moment(this.get('start_date'));
        var maxEndDate = moment(this.get('end_date'));

        switch (this.get('group_by')) {
          case 'month':
            startDate = moment(date).startOf('month');
            endDate = moment(date).endOf('month');
            break;

          case 'quarter':
            month = date.split(' ')[0].charAt(1) * 3 - 2;
            year = date.split(' ')[1];
            date = year + '-' + month;
            startDate = moment(date, 'YYYY-M').startOf('quarter');
            endDate = moment(date, 'YYYY-M').endOf('quarter');
            break;

          case 'year':
            startDate = moment(date).startOf('year');
            endDate = moment(date).endOf('year');
            break;

          default:
            startDate = maxStartDate;
            endDate = maxEndDate;
        }

        startDate = startDate.isBefore(this.get('startDate')) ? maxStartDate : startDate;
        endDate = endDate.isAfter(this.get('endDate')) ? maxEndDate : endDate;

        if (this.permissions.can('accounting.read')) {
          this.transitionToRoute('main.transactions', {
            queryParams: {
              end_date: endDate.format('YYYY-MM-DD'),
              start_date: startDate.format('YYYY-MM-DD'),
              tags: _tagSerialization.default.serialize([tag])
            }
          });
        }
      },
      quickReport: function quickReport(kind) {
        if (kind === 'current') {
          var start = moment().startOf('year').format('YYYY-MM-DD');
          var end = moment().format('YYYY-MM-DD');
          this.transitionToRoute({
            queryParams: {
              group_by: 'month',
              start_date: start,
              end_date: end
            }
          });
        } else if (kind === 'last_year') {
          var _start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');

          var _end = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              group_by: 'year',
              start_date: _start,
              end_date: _end
            }
          });
        } else if (kind === 'month_to_date') {
          var _start2 = moment().startOf('month').format('YYYY-MM-DD');

          var _end2 = moment().format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              group_by: 'month',
              start_date: _start2,
              end_date: _end2
            }
          });
        } else {
          var _start3 = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

          var _end3 = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              group_by: 'month',
              start_date: _start3,
              end_date: _end3
            }
          });
        }
      }
    },
    tagGroupSelected: Ember.computed('tags', function () {
      var tags = _tagSerialization.default.deserialize(this.get('tags'));

      return !!tags.find(function (t) {
        return t.taggableType === 'kind';
      });
    }),
    tagKindSelected: Ember.computed('tags', function () {
      var tags = _tagSerialization.default.deserialize(this.get('tags'));

      return tags.find(function (t) {
        return t.taggableType === 'kind';
      });
    }),
    formatAsDate: Ember.computed('tagGroupSelected', 'group_by', function () {
      return this.get('group_by') === 'month' && !this.get('tagGroupSelected');
    }),
    columnCount: Ember.computed('model.groups', 'showTotal', function () {
      var count = Object.keys(this.get('model.groups')).length;
      return !this.get('showTotal') && this.get('group_by') !== 'total' ? count - 1 : count;
    }),
    operatingActivitiesWithStyles: Ember.computed('model.cash_flow_statement', function () {
      var cashFlowStatement = this.get('model.cash_flow_statement');
      var operatingActivities = cashFlowStatement[0].lines;
      this.addStyles(operatingActivities);
      return operatingActivities;
    }),
    operatingTotals: Ember.computed('model.cash_flow_statement', function () {
      var cashFlowStatement = this.get('model.cash_flow_statement');
      return cashFlowStatement[0].total;
    }),
    investingActivitiesWithStyles: Ember.computed('model.cash_flow_statement', function () {
      var cashFlowStatement = this.get('model.cash_flow_statement');
      var investingActivities = cashFlowStatement[1].lines;
      this.addStyles(investingActivities);
      return investingActivities;
    }),
    investingTotals: Ember.computed('model.cash_flow_statement', function () {
      var cashFlowStatement = this.get('model.cash_flow_statement');
      return cashFlowStatement[1].total;
    }),
    financingActivitiesWithStyles: Ember.computed('model.cash_flow_statement', function () {
      var cashFlowStatement = this.get('model.cash_flow_statement');
      var financingActivities = cashFlowStatement[2].lines;
      this.addStyles(financingActivities);
      return financingActivities;
    }),
    financingTotals: Ember.computed('model.cash_flow_statement', function () {
      var cashFlowStatement = this.get('model.cash_flow_statement');
      return cashFlowStatement[2].total;
    }),
    netChangeInCash: Ember.computed('model.cash_flow_statement', function () {
      return this.get('model.cash_flow_statement')[3];
    }),
    addStyles: function addStyles(cashFlowStatement) {
      cashFlowStatement.forEach(function (account) {
        if (account.title == 'Net income') {
          account['style_classes'] = '';
        } else if (account.total) {
          account['style_classes'] = 'font-weight-bold';
        } else {
          account['style_classes'] = 'ml-2';
        }
      });
    }
  });

  _exports.default = _default;
});