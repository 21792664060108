define("zipbooks/components/quick-stats/client-counts", ["exports", "zipbooks/mixins/testable", "ember-data"], function (_exports, _testable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    subscription: Ember.computed('session.account.subscriptions.@each.quantity', function () {
      return this.get('session.account.subscriptions').find(function (s) {
        return s.get('plan.kind') === 'cpa';
      });
    }),
    stats: Ember.computed('subscription.quantity', function () {
      if (this.subscription) {
        return _emberData.default.PromiseObject.create({
          promise: this.client.cached('GET', "quick-stats/client-counts")
        });
      }
    })
  });

  _exports.default = _default;
});