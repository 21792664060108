define("zipbooks/models/account-template", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    autoConfirm: (0, _model.attr)('boolean'),
    defaultBasis: (0, _model.attr)(),
    defaultClientPermissions: (0, _model.attr)(),
    reportsDefaultShowUnconfirmed: (0, _model.attr)(),
    showChartClass: (0, _model.attr)('boolean'),
    showChartCode: (0, _model.attr)(),
    account: (0, _model.belongsTo)('account'),
    templateGroup: (0, _model.belongsTo)('chart-account-template-group')
  });

  _exports.default = _default;
});