define("zipbooks/components/drawers/payment-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/utils/default-bank-account"], function (_exports, _modelDrawer, _defaultBankAccount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    title: 'Log payment',
    afterModel: function afterModel(model) {
      var _this = this;

      if (model.isNew) {
        // the component doesn't update if there isn't an initial value before it renders
        model.set('debitChartAccount', this.store.chartAccounts().find(function (ca) {
          return ca.get('systemCode') === '1200';
        }));
        var invoiceParam = this.get('params.lastObject');
        this.store.find('invoice', invoiceParam.value).then(function (invoice) {
          model.set('invoice', invoice); // this || is to overcome a weakness in the api where sometimes the line items are not available, but amount_due is

          model.set('amount', invoice.computedAmountDue.greaterThan(0) ? invoice.computedAmountDue.toFixed(2) : invoice.amountDue);

          var chartAccounts = _this.store.chartAccounts({
            filter: ['cash']
          });

          var account = (0, _defaultBankAccount.default)(chartAccounts, invoice.currencyCode);
          model.set('debitChartAccount', account);
        });
      }
    },
    handleError: function handleError(error) {
      this.overlay.showBanner('error', error.errors.map(function (error) {
        return error.detail;
      }).join(' '));
    },
    actions: {
      selectDebitChartAccount: function selectDebitChartAccount(chartAccount) {
        this.set('model.debitChartAccount', chartAccount);
      },
      selectPaymentMethod: function selectPaymentMethod(paymentMethod) {
        this.set('model.paymentMethod', paymentMethod && paymentMethod.name);
      },
      typeCustomPaymentMethod: function typeCustomPaymentMethod(paymentMethod) {
        this.set('model.paymentMethod', paymentMethod);
      }
    }
  });

  _exports.default = _default;
});