define("zipbooks/templates/components/invoice-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D0UP2y/y",
    "block": "{\"symbols\":[\"historyItem\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box box-info\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"box-header box-header-split\"],[8],[0,\"\\n    History\\n\"],[4,\"if\",[[28,\"can\",[\"accounting.read\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-x-x-tiny btn-green\"],[12,\"title\",\"Add Payment\"],[12,\"data-test-id\",\"add-payment\"],[3,\"action\",[[23,0,[]],\"logPayment\"]],[8],[0,\"Add Payment\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"box-table\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"history\",\"isPending\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"box-body p-1\"],[8],[0,\"\\n        \"],[1,[28,\"small-loader\",null,[[\"class\"],[\"my-6\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"ul\",true],[10,\"class\",\"box-list-stripe box-list-border\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"history\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"invoice-history-item\",null,[[\"model\",\"onRefund\"],[[23,1,[]],[24,[\"onRefund\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[24,[\"history\",\"isRejected\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[8],[0,\"Failed to load history\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"li\",true],[8],[0,\"\\n              No history yet.\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/invoice-history.hbs"
    }
  });

  _exports.default = _default;
});