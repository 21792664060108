define("zipbooks/routes/main/transactions", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/slow-array", "zipbooks/utils/sort-param", "zipbooks/utils/base-64"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _tagFilterableRoute, _permissionRedirect, _slowArray, _sortParam, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, _tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    queryParams: {
      chart_account_id: {
        refreshModel: true
      },
      classification: {
        refreshModel: true
      },
      confirmed: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      },
      rollup: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      }
    },
    autoUpdatingEvent: 'journal-entry.save',
    autoUpdatingModel: 'lines',
    autoUpdatingHardRefresh: true,
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.set('zipbooksBalance', 0);
    },
    model: function model(params) {
      var models = {
        chartAccounts: this.store.cachedQuery('chartAccount'),
        bankMembersToSetup: []
      };

      if (this.permissions.can('banks.all')) {
        models.bankMembersToSetup = this.store.query('bank-member', {
          filter: {
            setup: false
          }
        });
      } // if viewing with filter of chart account, show journal entry lines for that chart account


      if (params.chart_account_id && params.chart_account_id !== 'in-transit') {
        this.set('params', this.getParams(params));
        models.lines = (0, _slowArray.default)(this.store.cachedQuery('journalEntryLine', this.params));
      } // if viewing with filter of classsification, show journal entry lines for that classification
      else if (params.classification) {
          this.set('params', this.getParams(params));
          models.lines = (0, _slowArray.default)(this.store.cachedQuery('journalEntryLine', this.params));
        } // if not viewing by chart account, fetch journal entries and use heuristic to choose how to display
        else {
            this.set('params', this.getParams(params));
            models.lines = (0, _slowArray.default)(this.store.cachedQuery('journalEntry', this.params));
          }

      return Ember.RSVP.hash(models).then(function (result) {
        return Ember.Object.create(result);
      });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments); // preload tags


      this.client.cached('GET', 'taggables', null, 10 * 60 * 1000
      /* 10 mins */
      );
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller.set('page', 1);
        controller.set('chart_account_id', null);
        controller.set('sort', 'date');
        controller.set('direction', 'desc');
        controller.set('transaction', null);
        controller.set('messages_label_id', null);
        controller.set('start_date', null);
        controller.set('end_date', null);
      }
    },
    // click anywhere to close transaction
    clickToClose: function clickToClose(event) {
      if ($(event.target).hasClass('click-to-close') || event.target.parentElement === document.body) {
        this.notificationCenter.notify('clicked-to-close');
      }
    },
    activate: function activate() {
      $(window).on('click.transactions', $.proxy(this.clickToClose, this));
    },
    deactivate: function deactivate() {
      $(window).off('.transactions');
    },
    getParams: function getParams(params) {
      var result = {
        // we don't support this in the json-api implementation in phoenix yet
        // include: ['invoices', 'customers', 'journal_entries', 'chart_accounts'].join(','),
        page: {
          page: params.page || 1,
          'page-size': 100
        },
        sort: (0, _sortParam.default)(params),
        filter: {}
      };

      if (params.classification) {
        result.filter.classification = params.classification;
      }

      if (params.chart_account_id === 'in-transit') {
        result.filter.chart_account_group = params.chart_account_id;
        delete params.chart_account_id;
      }

      if (params.chart_account_id && params.chart_account_id.indexOf('all-') > -1) {
        result.filter.chart_account_group = params.chart_account_id.substr(4);
        delete params.chart_account_id;
      }

      if (params.chart_account_id) {
        result.filter.chart_account_id = params.chart_account_id;
      }

      if (params.confirmed == 'confirmed') {
        result.filter.confirmed = true;
      }

      if (params.confirmed == 'unconfirmed') {
        result.filter.confirmed = false;
      }

      if (params.source) {
        result.filter.source = params.source;
      }

      if (params.start_date) {
        result.filter.start_date = params.start_date;
      }

      if (params.end_date) {
        result.filter.end_date = params.end_date;
      }

      if (params.rollup) {
        try {
          result.filter.rollup_id = JSON.parse((0, _base.decode)(params.rollup)).id;
        } catch (_e) {// ignore
        }
      }

      if (params.tags) {
        result.filter.tags = this.paramTags(params);
      }

      return result;
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var include = 'all';

        if (params.filter.confirmed === true) {
          include = 'confirmed';
        } else if (params.filter.confirmed === false) {
          include = 'unconfirmed';
        }

        var queryParams = {
          view: 'detailed',
          include: include
        };

        if (params.filter.start_date) {
          queryParams.start_date = params.filter.start_date;
        }

        if (params.filter.end_date) {
          queryParams.end_date = params.filter.end_date;
        }

        if (params.filter.chart_account_id) {
          queryParams.chart_account_id = params.filter.chart_account_id;
        }

        if (params.filter.tags) {
          queryParams.tags = params.filter.tags;
        }

        window.location = '/v2/reports/general-journal/export?' + $.param(queryParams);
      }
    }
  });

  _exports.default = _default;
});