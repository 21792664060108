define("zipbooks/routes/main/labels", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/integrations-route", "zipbooks/mixins/google-maps-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/sort-param"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _integrationsRoute, _googleMapsRoute, _permissionRedirect, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, _integrationsRoute.default, _googleMapsRoute.default, _permissionRedirect.default, {
    queryParams: {
      parent_id: {
        refreshModel: true
      },
      taggable: {
        refreshModel: true
      }
    },
    autoUpdatingEvent: 'label.save',
    autoUpdatingModel: 'labels',
    model: function model(params) {
      var query = {
        page: {
          page: params.page || 1,
          'page-size': 30
        },
        sort: (0, _sortParam.default)(params),
        filter: {}
      };

      if (['contact', 'project', 'location'].includes(params.taggable)) {
        query.filter.archived = false;
      }

      var type = params.taggable;

      if (params.taggable === 'message') {
        type = 'label';
        query.filter.kind = 'message';
      } else if (params.taggable === 'label') {
        query.filter.exclude = 'message';
      }

      var models = {
        labels: this.store.cachedQuery(type, query),
        integrations: this.integrationsPromise()
      };
      return Ember.RSVP.hash(models);
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      this._super.apply(this, arguments);

      if (controller.taggable == 'location') {
        controller.set('hideShadow', true);
      } else {
        controller.set('hideShadow', false);
      }
    }
  });

  _exports.default = _default;
});