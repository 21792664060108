define("zipbooks/components/index-row-content/chart-account-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    indent: 0,
    actions: {
      destroy: function destroy() {
        this.destroy.apply(this, arguments);
      },
      select: function select() {
        this.onSelect.apply(this, arguments);
      }
    },
    nextIndent: Ember.computed('indent', function () {
      return this.indent + 1;
    }),
    hasParent: Ember.computed('template.parent.id', function () {
      return !!this.get('template.parent.id');
    })
  });

  _exports.default = _default;
});