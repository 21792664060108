define("zipbooks/utils/values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = values;

  function values(object) {
    return Object.keys(object).map(function (key) {
      return object[key];
    });
  }
});