define("zipbooks/overrides/text-field", ["exports", "zipbooks/utils/is-browser"], function (_exports, _isBrowser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.reopen({
    attributeBindings: ['highlight:data-highlight'],
    _valueChanged: Ember.observer('value', function () {
      if ((0, _isBrowser.default)().safari && !Ember.testing) {
        var el = this.$()[0];
        el.style.display = 'none';
        el.style.display = 'block';
      } // this symbol doesnt show correctly with certain styles so
      // we apply this class to override those styles


      if (this.value === '₨') {
        this.$().addClass('symbol-safe');
      } else {
        this.$().removeClass('symbol-safe');
      }
    })
  });

  _exports.default = _default;
});