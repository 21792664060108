define("zipbooks/templates/components/growth/associate-locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AN85TbYy",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row small-gutters gray font-size-13px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-5 col-md-4\"],[8],[0,\"\\n    \"],[1,[24,[\"integration\",\"providerInfo\",\"shortName\"]],false],[0,\" \"],[1,[24,[\"integration\",\"providerInfo\",\"locationName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-2\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-5 col-md-4\"],[8],[0,\"\\n    How to handle in ZipBooks\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"rows\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"growth/external-location-matcher\",null,[[\"model\",\"syncPrompt\",\"locations\",\"excludedLocationIds\"],[[23,1,[]],[24,[\"syncPrompt\"]],[24,[\"locations\"]],[24,[\"selectedLocationIds\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"generalError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"error\"],[8],[1,[22,\"generalError\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"style\",\"display:none\"],[8],[1,[22,\"_operations\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/growth/associate-locations.hbs"
    }
  });

  _exports.default = _default;
});