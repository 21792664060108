define("zipbooks/serializers/journal-entry", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/embedded-jsonapi-records", "zipbooks/mixins/timestamp-serializer", "zipbooks/mixins/patch-serializer"], function (_exports, _application, _embeddedJsonapiRecords, _timestampSerializer, _patchSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_embeddedJsonapiRecords.default, _timestampSerializer.default, _patchSerializer.default, {
    alwaysSend: ['autoConfirm'],
    attrs: {
      journalEntryLines: {
        serialize: 'records'
      },
      cloudFiles: {
        serialize: 'records'
      },
      comment: {
        serialize: 'records'
      },
      notifications: {
        serialize: false
      }
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key
    /*, attributes*/
    ) {
      this._super.apply(this, arguments);

      if (snapshot.record.get('isNew') && key === 'isConfirmed') {
        delete json.attributes['is-confirmed'];
      }
    }
  });

  _exports.default = _default;
});