define("zipbooks/services/accountant-store", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _store.default.extend({
    adapter: 'accountant'
  });

  _exports.default = _default;
});