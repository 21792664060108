define("zipbooks/models/reminder", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/mixins/notificationable"], function (_exports, _model, _model2, _notificationable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_notificationable.default, {
    body: (0, _model.attr)(),
    subject: (0, _model.attr)(),
    daysAfterDue: (0, _model.attr)(),
    enabled: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});