define("zipbooks/templates/components/paid-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LoFZbBV0",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"shouldHide\"]]],null,{\"statements\":[[4,\"dynamic-tag\",null,[[\"tagName\",\"action\",\"class\"],[[24,[\"dynamicTagName\"]],[28,\"action\",[[23,0,[]],\"click\"],null],[24,[\"class\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[24,[\"privileged\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/paid-tag.hbs"
    }
  });

  _exports.default = _default;
});