define("zipbooks/utils/compare-locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = compareLocations;

  function compareLocations(location, externalLocation) {
    var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var matchKeys = ['address_1', 'city', 'state'];

    if (opts.exact) {
      matchKeys = ['name', 'phone', 'website', 'address_1', 'address_2', 'city', 'state', 'postalCode', 'country'];
    }

    var matches = matchKeys.every(function (key) {
      var internalValue = location.get(key);
      var externalValue = externalLocation.get(key);

      if (key === 'phone') {
        // only compare last 10 digits of phone number
        return (internalValue || '').slice(-10) === (externalValue || '').slice(-10);
      } else {
        return internalValue == externalValue;
        /*eslint eqeqeq: 0*/
      }
    });
    var notAllBlank = matchKeys.any(function (key) {
      return !!location.get(key);
    });
    return notAllBlank && matches;
  }
});