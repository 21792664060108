define("zipbooks/mixins/journal-entry/sorted-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sortedLines: Ember.computed('journalEntryLines.@each.chartAccount', function () {
      var isSplit = this.get('journalEntryLines.length') > 2;
      return this.journalEntryLines.toArray().sort(function (a, b) {
        var aClass = a.get('chartAccount.classificationInfo');
        var bClass = b.get('chartAccount.classificationInfo'); // a < b return -1
        // a should be before b return -1

        if (!aClass) {
          return 1;
        }

        if (!bClass) {
          return -1;
        } // if one is critical and one is not


        if (aClass.critical !== bClass.critical) {
          return bClass.critical - aClass.critical;
        } // if both are critical
        else if (aClass.critical && bClass.critical && !isSplit) {
            if (a.amount !== b.amount) {
              return a.amount > b.amount ? -1 : 1;
            } else {
              return a.kind === 'credit' ? -1 : 1;
            }
          } // if both are non-critical, return one with lower priority (which means higher)
          else {
              return aClass.priority - bClass.priority;
            }
      });
    })
  });

  _exports.default = _default;
});