define("zipbooks/templates/components/overlays/pop-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GIPetZ/G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",false],[12,\"class\",\"pop-out-close\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"icon-remove-2\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"component\",[[24,[\"model\",\"component\"]]],[[\"model\",\"callbacks\"],[[24,[\"model\",\"model\"]],[24,[\"callbacks\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"pop-out-width-hack\"],[8],[0,\"\\n  -------------------------------------------------------------------------------------------------\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/overlays/pop-out.hbs"
    }
  });

  _exports.default = _default;
});