define("zipbooks/components/overlays/dialogs/recurring-profile-send", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    actions: {
      send: function send() {
        if (this.get('callbacks.send')) {
          this.get('callbacks.send')();
        }

        this.close();
      },
      cancel: function cancel() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});