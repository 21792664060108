define("zipbooks/components/suggest/simple-select", ["exports", "zipbooks/components/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    placeholder: 'Payment method',
    layoutName: 'components/auto-suggest',
    allowArbitrary: true,
    options: [],
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var results = this.options.filter(function (item) {
        return item.toLowerCase().indexOf((search || '').toLowerCase()) > -1;
      });
      return (search && search.length > 0 ? results : this.options).map(function (item
      /*, index, enumerable*/
      ) {
        return {
          id: item,
          name: item
        };
      });
    },
    labelForResult: function labelForResult(result) {
      return result.name;
    }
  });

  _exports.default = _default;
});