define("zipbooks/utils/line-sorter", ["exports", "zipbooks/utils/classification-helpers", "zipbooks/utils/classification-tags"], function (_exports, _classificationHelpers, _classificationTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lineSorter;

  function lineSorter(lines, kind, tag) {
    return lines.toArray().sort(function (a, b) {
      var aClass = a.get('chartAccount.classificationInfo');
      var bClass = b.get('chartAccount.classificationInfo'); // a < b return -1
      // a should be before b return -1

      if (!aClass) {
        return 1;
      }

      if (!bClass) {
        return -1;
      } // if a debit, it MUST be the first line


      if (a.get('kind') !== b.get('kind')) {
        return a.get('kind') === kind ? -1 : 1;
      } // if one is critical and one is not


      if (aClass.critical !== bClass.critical) {
        return bClass.critical - aClass.critical;
      } // if both are critical
      else if (aClass.critical && bClass.critical) {
          return aClass.priority - bClass.priority;
        } // if both are non-critical, choose one that is in classes
        else {
            var inClasses = _classificationHelpers.default.withTag(_classificationTags.default[tag]).mapBy('name');

            var aExpense = inClasses.includes(a.get('classification'));
            var bExpense = inClasses.includes(b.get('classification'));

            if (aExpense !== bExpense) {
              return aExpense - bExpense;
            } else {
              return a.get('createdAt') - b.get('createdAt');
            }
          }
    });
  }
});