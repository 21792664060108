define("zipbooks/components/login/account-selector", ["exports", "zipbooks/utils/search-in", "zipbooks/utils/keycodes", "zipbooks/utils/slow-array"], function (_exports, _searchIn, _keycodes, _slowArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.later(this, function () {
        $(".js-search-input input").focus();
      }, 100);
    },
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    stealth: false,
    actions: {
      loginAs: function loginAs(token) {
        this.session.login(token, !!this.get('model.remember'), this.stealth);
        this.router.transitionTo('main');
      },
      searchInputKeyDown: function searchInputKeyDown(event) {
        if (event.which === _keycodes.default.ENTER && this.tokens.length === 1) {
          this.send('loginAs', this.tokens.firstObject.token);
        }
      }
    },
    accountsCount: Ember.computed('identity.userTokens', function () {
      return this.get('identity.userTokens').length;
    }),
    tokensCount: Ember.computed('tokens.length', function () {
      return this.tokens.length;
    }),
    tokens: Ember.computed('identity.userTokens', 'searchText', function () {
      var tokens = this.identity.userTokens;

      if (this.searchText) {
        return (0, _slowArray.slowNativeArray)((0, _searchIn.default)(tokens, 'account_name', this.searchText));
      } else {
        return (0, _slowArray.slowNativeArray)(tokens);
      }
    })
  });

  _exports.default = _default;
});