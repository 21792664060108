define("zipbooks/components/add-bank-widget", ["exports", "zipbooks/mixins/add-bank"], function (_exports, _addBank) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_addBank.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('state', 'loading');
      this.connectNew();
    },

    /************************************************************************
     * AddBankMixin
     ************************************************************************/
    bankSuccessfullyImporting: function bankSuccessfullyImporting() {
      Ember.tryInvoke(this, 'completed', []);
    }
  });

  _exports.default = _default;
});