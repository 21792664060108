define("zipbooks/mixins/tag-filterable-route", ["exports", "zipbooks/utils/tag-serialization", "zipbooks/mixins/partial-page-refresh"], function (_exports, _tagSerialization, _partialPageRefresh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_partialPageRefresh.default, {
    queryParams: {
      tags: {
        refreshModel: true
      }
    },
    paramTags: function paramTags(params) {
      return _tagSerialization.default.deserialize(params.tags, function (tag) {
        if (tag.id === 'none') {
          return 'none';
        }

        tag.type = tag.taggableType.capitalize();
        delete tag.kind;
        delete tag.createdAt;
        delete tag.name;
        delete tag.taggableType;
        return tag;
      });
    },
    // we store the kind in tags for user experience slick-ness
    decodeTags: function decodeTags(tags) {
      var kind = tags.findBy('type', 'Kind');
      var rest = tags.filter(function (t) {
        return t.type !== 'Kind';
      });
      return {
        kind: kind && kind.id.toLowerCase(),
        rest: rest
      };
    }
  });

  _exports.default = _default;
});