define("zipbooks/components/suggest/currency-symbol-select", ["exports", "zipbooks/components/suggest/currency-select", "zipbooks/utils/format-money"], function (_exports, _currencySelect, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currencySelect.default.extend({
    placeholder: '$',
    layoutName: 'components/auto-suggest',
    autoSelectFirst: false,
    hideArrow: true,
    session: Ember.inject.service(),
    labelForResult: function labelForResult(result) {
      var s = (0, _formatMoney.default)(0, {
        currency: result,
        escaped: false,
        accountCurrency: this.get('session.account.defaultCurrencyCode')
      });
      return s.substr(0, s.indexOf('0.'));
    }
  });

  _exports.default = _default;
});