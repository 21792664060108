define("zipbooks/routes/main/accountant/clients/category-templates", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/utils/sort-param"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, {
    store: Ember.inject.service(),
    autoUpdatingEvent: 'chart-account-template-group.save',
    autoUpdatingHardRefresh: true,
    model: function model(params) {
      return this.store.cachedQuery('chart-account-template-group', {
        page: {
          page: params.page || 1,
          'page-size': 30
        },
        sort: (0, _sortParam.default)(params)
      });
    }
  });

  _exports.default = _default;
});