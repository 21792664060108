define("zipbooks/templates/application-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7sBsws8D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"error-page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"Oops!\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Looks like something went wrong.  Sorry about that!  We’ve let our engineering team know about this problem.\"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"/invoices\"],[10,\"class\",\"btn btn-white mb-1\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"green icon-dollar-sign-circle\"],[8],[9],[0,\"\\n      Invoices\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"/transactions\"],[10,\"class\",\"btn btn-white mb-1\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon-bank-notes-12 blue\"],[8],[9],[0,\"\\n      Transactions\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"/reports\"],[10,\"class\",\"btn btn-white mb-1\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon-graph-bar-line purple\"],[8],[9],[0,\"\\n      Reports\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"/time-entries\"],[10,\"class\",\"btn btn-white mb-1\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"blue icon-clock-3\"],[8],[9],[0,\"\\n      Tracking\\n    \"],[9],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/assets/images/logo.svg\"],[10,\"alt\",\"logo\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/application-error.hbs"
    }
  });

  _exports.default = _default;
});