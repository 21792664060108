define("zipbooks/helpers/transaction-detail-column-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionDetailColumnClass = transactionDetailColumnClass;
  _exports.default = void 0;

  function transactionDetailColumnClass(params
  /*, hash*/
  ) {
    var columnNum = params[0];
    var showAmount = params[1];

    if (columnNum === 1) {
      if (showAmount) {
        return 'col-sm-4';
      } else {
        return 'col-sm-6';
      }
    }

    if (columnNum === 2) {
      if (showAmount) {
        return 'col-sm-9';
      } else {
        return 'col-sm-11';
      }
    }

    return params;
  }

  var _default = Ember.Helper.helper(transactionDetailColumnClass);

  _exports.default = _default;
});