define("zipbooks/mixins/line-itemable-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    attrs: {
      account: {
        deserialize: 'records',
        serialize: 'ids'
      },
      contact: {
        deserialize: 'records',
        serialize: 'ids'
      },
      lineItems: {
        deserialize: 'records',
        serialize: 'records'
      },
      actions: {
        deserialize: 'records',
        serialize: false
      },
      logoCloudFile: {
        deserialize: 'records',
        serialize: 'records'
      },
      cloudFiles: {
        deserialize: 'records',
        serialize: 'records'
      },
      total: {
        serialize: false
      },
      subTotal: {
        serialize: false
      }
    },
    removeParentReferences: function removeParentReferences(payload) {
      payload.line_items.forEach(function (i) {
        delete i.line_itemable_id;
        delete i.line_itemable_type;
      });
    },

    /*****************************************************
     * This exists as a hack to fix a bug with ember 3.0
     * https://github.com/emberjs/ember.js/issues/16624
     * For some reason, in this very particular instance,
     * (cause it works for transactions…),
     * lineItem._internalModel._record
     * is not being updated when attributes change…
     ******************************************************/
    serialize: function serialize(snapshot, _options) {
      snapshot.record.lineItems.forEach(function (li) {
        li.eachAttribute(function (keyName) {
          return li._internalModel._record.set(keyName, Ember.get(li, keyName));
        });
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});