define("zipbooks/routes/main/reports/transaction-tracking", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    client: Ember.inject.service(),
    queryParams: {
      accountant: {
        refreshModel: true
      },
      group_by: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (moment(params.start_date).isAfter(moment(params.end_date))) {
        params.start_date = params.end_date;
      }

      this.set('params', params);
      return this.client.cached('GET', 'reports', "transaction-tracking?".concat($.param(params))).then(function (resp) {
        return resp['transaction_tracking'];
      });
    },
    actions: {
      export: function _export() {
        var queryParams = {
          accountant: this.get('controller.accountant'),
          end_date: this.params.end_date,
          group_by: this.get('controller.group_by'),
          start_date: this.params.start_date
        };
        window.location = '/v2/reports/transaction-tracking/export?' + $.param(queryParams);
      }
    }
  });

  _exports.default = _default;
});