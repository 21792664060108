define("zipbooks/utils/need-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    teams: {
      required_plan: 'smarter',
      description: 'add and manage team members',
      sell: Ember.String.htmlSafe('<b>Team members</b> can be employees, contractors, accountants, or anyone else you want to access ZipBooks.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'recurring-profiles': {
      required_plan: 'smarter',
      description: 'use Recurring Profiles',
      sell: Ember.String.htmlSafe('<b>Recurring Profiles</b> are invoices that get sent on a regular schedule of your choice.  You can even accept credit cards to auto-bill and send receipts to customers!</b>'),
      learn_more_url: '',
      learn_more_title: ''
    },
    time: {
      required_plan: 'smarter',
      description: 'use Time Tracking',
      sell: Ember.String.htmlSafe('<b>Time Tracking</b> lets you manage client projects and track time against them, then automatically pull those time entries into invoices in any format you like.  These features <b>save the average ZipBooks time tracker more than 2 hours per month.</b>'),
      learn_more_url: '',
      learn_more_title: ''
    },
    items: {
      required_plan: 'smarter',
      description: 'use Items',
      sell: Ember.String.htmlSafe('<b>Items</b> allow you to save the details of products and services you offer, then add them instantly to invoices!'),
      learn_more_url: '',
      learn_more_title: ''
    },
    reminders: {
      required_plan: 'smarter',
      description: 'use Reminders',
      sell: Ember.String.htmlSafe("<b>Reminders</b> allow you to automatically set up reminder emails for customers or clients with overdue invoices.  You can create as many as you'd like, with your preferences, and we take care of the rest!"),
      learn_more_url: '',
      learn_more_title: ''
    },
    projects: {
      required_plan: 'smarter',
      description: 'use Projects',
      sell: Ember.String.htmlSafe("<b>Projects</b> allow you to track any project you're working on by assigning time, users, or even transctions to it.  Use projects to keep your business more organized than ever!"),
      learn_more_url: '',
      learn_more_title: ''
    },
    'batch-categorize': {
      required_plan: 'smarter',
      description: 'batch categorize transactions',
      sell: Ember.String.htmlSafe("<b>Batch categorization</b> lets you categorize many transactions at once.  If you're trying to speed up your bookkeeping workflow, batch categorization is a great place to start!"),
      learn_more_url: '',
      learn_more_title: ''
    },
    'banks-expanded': {
      required_plan: 'smarter',
      description: 'connect more than one bank',
      learn_more_url: '',
      learn_more_title: ''
    },
    billing: {
      required_plan: 'smarter',
      description: 'use advanced billing features',
      sell: Ember.String.htmlSafe('<b>Advanced billing</b> allows you to add temporary discounts or charges to recurring profiles and choose whether customers get invoices and/or receipts from recurring profiles.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'account-defaults': {
      required_plan: 'smarter',
      description: 'use advanced account defaults',
      sell: Ember.String.htmlSafe('<b>Advanced account defaults</b> allow you to set default terms and notes for invoices, estimates, and receipts.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    documents: {
      required_plan: 'sophisticated',
      description: 'upload and share documents',
      sell: Ember.String.htmlSafe('<b>Document sharing</b> gives you a secure way to share documents with team members or bookkeepers, right within ZipBooks.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    labels: {
      required_plan: 'sophisticated',
      description: 'use Tags',
      sell: Ember.String.htmlSafe("<b>Tags</b> allow you to assign transactions to anything you can imagine, then create reports based on those tags.  It's great for producing reports based on project, customer, team member, and more!"),
      learn_more_url: '',
      learn_more_title: ''
    },
    locations: {
      required_plan: 'sophisticated',
      description: 'use Locations',
      sell: Ember.String.htmlSafe("<b>Locations</b> allow you to assign transactions to .  It's great for producing reports based on project, customer, team member, and more!"),
      learn_more_url: '',
      learn_more_title: ''
    },
    'reports-expanded': {
      required_plan: 'smarter',
      description: 'use Expanded Reporting',
      sell: Ember.String.htmlSafe('<b>Expanded Reporting</b> goes beyond your income statement and balance sheet to allow you to analyze your business at a whole new level, and make it even easier to work with your accountant or bookkeeper.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'quick-click': {
      required_plan: 'smarter',
      description: 'use Quick Click',
      sell: Ember.String.htmlSafe('<b>Quick Click</b> allows you to effortlessly navigate throughout ZipBooks and dive into the details of your report.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'auto-categorize': {
      required_plan: 'smarter',
      description: 'use Auto-categorization',
      sell: Ember.String.htmlSafe('<b>Auto-categorization</b> saves you hours each month by instantly categorizing your transactions. ZipBooks builds personalized machine learning for your account that categorizes new transactions and highlights the level of confidence in our automation.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    importing: {
      required_plan: 'smarter',
      description: 'import',
      sell: Ember.String.htmlSafe('<b>Importing</b> makes it easy to quickly add multiple transactions or categories to your account. You can upload a bank statement, add journal entries, or migrate your old accounting records.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'bills-expanded': {
      required_plan: 'smarter',
      description: 'use Bills',
      sell: Ember.String.htmlSafe('<b>Bills</b> allow you to enter bills and invoices you receive from vendors, so we can keep track of what you owe and when it’s due.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    reconciliation: {
      required_plan: 'sophisticated',
      description: 'use reconciliation',
      sell: Ember.String.htmlSafe('<b>Bank reconciliation</b> helps you match transactions in ZipBooks to those on your bank statement, so you can be sure that every transaction is accounted for.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    notifications: {
      required_plan: 'smarter',
      description: 'see Expanded Notifications',
      sell: Ember.String.htmlSafe('<b>Expanded notifications</b> help you see important information as it happens, so you can stay 100% on top of your business.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    export: {
      required_plan: 'smarter',
      description: 'export reports',
      sell: Ember.String.htmlSafe('<b>Exporting Reports</b> lets you download your ZipBooks reports as a spreadsheet.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'intelligence-basic': {
      required_plan: 'smarter',
      description: 'access intelligence and advanced charting',
      sell: Ember.String.htmlSafe('Intelligence and advanced charting gives you quick visual insights into your business.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    integrations: {
      required_plan: 'smarter',
      description: 'access integrations',
      sell: Ember.String.htmlSafe('Leverage integrations to bring even more functionality into ZipBooks.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'advanced-settings': {
      required_plan: 'sophisticated',
      description: 'manage advanced settings',
      sell: Ember.String.htmlSafe('Advanced settings lets you customize your accounting setup including locking transactions and adding codes to your chart of accounts.'),
      learn_more_url: '',
      learn_more_title: ''
    },
    'recurring-transactions': {
      required_plan: 'sophisticated',
      description: 'use recurring transactions',
      sell: Ember.String.htmlSafe('A <b>recurring transaction</b> is a transaction template for creating transactions on a set schedule.'),
      learn_more_url: '',
      learn_more_title: ''
    }
  };
  _exports.default = _default;
});