define("zipbooks/components/drawers/document-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    actions: {
      fileDidUpload: function fileDidUpload(cloudFile) {
        this.set('model.contentType', cloudFile.get('contentType'));

        if (!this.get('model.filename')) {
          this.set('model.filename', cloudFile.get('filename'));
        }
      }
    }
  });

  _exports.default = _default;
});