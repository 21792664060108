define("zipbooks/routes/main/messaging/show", ["exports", "zipbooks/utils/push-payload", "zipbooks/utils/tag-serialization"], function (_exports, _pushPayload, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    client: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      this.client.GET('tokens', params.token).then(function (payload) {
        var token = (0, _pushPayload.default)(_this.store, 'token', payload);
        token.get('tokenable').then(function (label) {
          var tags = [Ember.Object.create({
            id: label.id,
            name: label.name,
            createdAt: label.createdAt,
            kind: 'Message',
            taggableType: 'Label'
          })];

          _this.router.transitionTo('main.transactions', {
            queryParams: {
              tags: _tagSerialization.default.serialize(tags),
              confirmed: ''
            }
          });
        });
      });
    }
  });

  _exports.default = _default;
});