define("zipbooks/models/historical-journal-entry-line", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)(),
    enabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    date: (0, _model.attr)('date'),
    amount: (0, _model.attr)('money', {
      defaultValue: 0
    }),
    journalEntryId: (0, _model.attr)(),
    kind: (0, _model.attr)(),
    chartAccount: (0, _model.belongsTo)('chartAccount')
  });

  _exports.default = _default;
});