define("zipbooks/utils/uniq-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = uniqBy;

  var identityFunction = function identityFunction(item) {
    return item;
  };

  function uniqBy(array) {
    var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : identityFunction;
    var ret = [];
    var seen = new Set();
    var getter = typeof key === 'function' ? key : function (item) {
      return Ember.get(item, key);
    };
    array.forEach(function (item) {
      var val = getter(item);

      if (!seen.has(val)) {
        seen.add(val);
        ret.push(item);
      }
    });
    return ret;
  }
});