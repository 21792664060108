define("zipbooks/components/confirm-check", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/later"], function (_exports, _testable, _later) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    classNames: ['confirm-check'],
    _currentIsChecked: null,
    confirmedIconUrl: Ember.computed('checked', 'isAnimating', function () {
      var _this = this;

      var checked = this.checked;
      var oldChecked = this.get('_currentIsChecked');
      this.set('_currentIsChecked', checked);

      if (checked) {
        if (checked && oldChecked === false) {
          this.set('isAnimating', true);
          (0, _later.default)(500, function () {
            if (_this.isDestroyed || _this.isDestroying) return;

            _this.set('isAnimating', false);
          });
          return '/assets/images/confirmation-forever2.gif';
        } else {
          return '/assets/images/confirmation-confirmed.png';
        }
      } else {
        return '/assets/images/confirmation-unconfirmed.png';
      }
    }),
    actions: {
      toggle: function toggle(e) {
        e.preventDefault();
        e.stopPropagation();
        this.onCheck.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});