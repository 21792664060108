define("zipbooks/components/suggest/chart-account-template-group-select", ["exports", "zipbooks/components/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    overlay: Ember.inject.service(),
    placeholder: 'Contact',
    layoutName: 'components/auto-suggest',
    throttle: 1000,
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      return this.store.query('chart-account-template-group', {
        filter: {
          name: "*".concat(search || '', "*")
        }
      });
    },
    labelForResult: function labelForResult(result) {
      return result.get && result.get('name');
    }
  });

  _exports.default = _default;
});