define("zipbooks/routes/main/documents", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect"], function (_exports, _routePagination, _autoUpdatingRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    model: function model(params) {
      return this.store.query('document', {
        page: {
          page: params.page || 1,
          'page-size': 30
        },
        sort: '-created_at'
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setupDropToUpload();
    },
    resetController: function resetController(controller) {
      controller.removeDropToUpload();
    }
  });

  _exports.default = _default;
});