define("zipbooks/routes/main/projects", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/sort-param"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _permissionRedirect, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    queryParams: {
      archived: {
        refreshModel: true
      },
      show: {
        refreshModel: true
      },
      contact_id: {
        refreshModel: true
      }
    },
    autoUpdatingEvent: 'project.save',
    autoUpdatingModel: 'projects',
    model: function model(params) {
      var queryParams = {
        page: {
          page: params.page || 1,
          'page-size': 100
        },
        filter: {
          archived: params.archived || false,
          show: params.show
        },
        sort: (0, _sortParam.default)(params)
      };

      if (params.contact_id) {
        queryParams.filter.contact_id = params.contact_id;
      }

      var models = {
        projects: this.store.cachedQuery('project', queryParams)
      };

      if (params.contact_id) {
        models.contact = this.store.findRecord('contact', params.contact_id, {
          include: 'public_profile'
        });
      }

      return Ember.RSVP.hash(models);
    }
  });

  _exports.default = _default;
});