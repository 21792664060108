define("zipbooks/templates/components/checkable-contact-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rR5WWE5i",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"checkable-contact-card \",[28,\"if\",[[24,[\"isSelected\"]],\"checkable-contact-card-selected\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isSelected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"checkable-contact-card-check icon-check-circle\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row x-small-gutters\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto\"],[8],[0,\"\\n      \"],[1,[28,\"avatar-image\",null,[[\"size\",\"url\",\"initials\"],[\"tiny\",[24,[\"contact\",\"publicProfile\",\"avatar\"]],[24,[\"contact\",\"initials\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-7 pl-2\"],[8],[0,\"\\n\"],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"checkable-contact-card-name\"],[8],[0,\"\\n        \"],[1,[24,[\"contact\",\"formattedName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"checkable-contact-card-phone\"],[8],[0,\"\\n        \"],[1,[24,[\"contact\",\"phone\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"checkable-contact-card-email\"],[8],[0,\"\\n        \"],[1,[28,\"truncate\",[[24,[\"contact\",\"email\"]],36,true],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col text-right direction-rtl\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"contact\",\"revenue\"]],0],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"btn btn-x-tiny btn-no-hover btn-green\"],[8],[0,\"\\n          \"],[1,[28,\"format-money\",[[24,[\"contact\",\"revenue\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/checkable-contact-card.hbs"
    }
  });

  _exports.default = _default;
});