define("zipbooks/mixins/archive-actionable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    overlay: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    actions: {
      toggleArchive: function toggleArchive(model) {
        var _this = this;

        model.toggleArchived();
        var modelType = this.modelType;
        var verb = model.get('archivedAt') ? 'archived' : 'activated';
        model.save().then(function () {
          _this.urlCache.clear();

          if (_this.collection) {
            _this.collection.update();

            _this.overlay.showBanner('success', "This ".concat(modelType, " was ").concat(verb, "!"));
          }
        }, function () {
          model.rollbackAttributes();

          _this.overlay.showBanner('error', "This ".concat(modelType, " could not be ").concat(verb, "."));
        });
      },
      bulkToggleArchive: function bulkToggleArchive() {
        var _this2 = this;

        var ids = this.selected.mapBy('id');

        if (ids.length > 0) {
          var modelType = this.modelType;
          var verb = !this.store.peekRecord(modelType, ids[0]).get('archivedAt') ? 'archived' : 'activated';
          this.bulk(ids.map(function (id) {
            return _this2.store.peekRecord(modelType, id);
          }), function (model) {
            model.toggleArchived();
            return model.save();
          }, function (result) {
            _this2.urlCache.clear();

            result.failed.forEach(function (m) {
              return m.rollbackAttributes();
            });

            _this2.refresh();

            _this2.unselectAll();

            _this2.showBulkBanner(verb, result.succeeded.length, result.unchanged.length, result.failed.length);
          });
        }
      }
    }
  });

  _exports.default = _default;
});