define("zipbooks/mixins/auto-upgrade", ["exports", "zipbooks/utils/need-details", "zipbooks/utils/plans"], function (_exports, _needDetails, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    shouldAutoUpgrade: function shouldAutoUpgrade(account) {
      if (this.isInExperiment(account)) {
        if (this.isEthical(account) && this.isChosen(account)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.isEthical(account)) {
          return true;
        }
      }
    },
    isInExperiment: function isInExperiment(account) {
      /** Rule out of experiment if an account created before paid plans available **/
      var earliestInExperimentStamp = moment('2017-05-02 21:03:37').unix();
      var latestInExperimentStamp = moment('2017-08-29 00:00:00').unix();
      var accountCreatedStamp = moment(account.get('createdAt')).unix();
      return accountCreatedStamp > earliestInExperimentStamp && accountCreatedStamp < latestInExperimentStamp; // Put them in the experiment by sending to mixpanel
    },
    isChosen: function isChosen(account) {
      return account.get('id') % 2 === 0;
    },
    isEthical: function isEthical(account) {
      // not cool to upgrade someone automatically if they are going to be charged
      // for it without explicitly saying they want to start paying the higher
      // price
      return account.get('hasCreditCard') === false;
    },
    isAutoUpgrading: false,
    autoUpgrade: function autoUpgrade(success, failure) {
      var _this = this;

      if (this.isAutoUpgrading) {
        return Ember.RSVP.resolve();
      }

      this.set('isAutoUpgrading', true);
      var neededPrivilege = this.needs;
      var neededPlanKey = _needDetails.default[neededPrivilege].required_plan;

      var requiredPlan = _plans.default.find(function (p) {
        return p.key == neededPlanKey;
      });

      var accountId = this.get('session.account.id');
      var payload = [Ember.Object.create({
        id: requiredPlan.id
      })].compact().map(function (p) {
        var body = {
          type: 'subscription',
          relationships: {
            plan: {
              data: {
                type: 'plan',
                id: p.get('id')
              }
            }
          }
        };
        return body;
      });
      return this.client.PATCH("accounts/".concat(accountId, "/relationships/subscriptions"), null, {
        data: payload
      }).then(function (_resp) {
        // mixpanel
        var properties = {
          plan_id: requiredPlan.id,
          needed_privilege: neededPrivilege
        };

        if (_this.get('model.element')) {
          properties.element = _this.get('model.element');
        } // reload user


        _this.client.send('GET', 'users', 'me').then(function (resp) {
          _this.store.pushPayload(resp);

          _this.set('isAutoUpgrading', false);

          success();
        });
      }, function (_resp) {
        _this.set('isAutoUpgrading', false);

        failure();
      });
    },
    navigateToSubscriptionPage: function navigateToSubscriptionPage(needs) {
      this.router.transitionTo('main.settings.subscription', {
        queryParams: {
          needs: needs
        }
      });
    }
  });

  _exports.default = _default;
});