define("zipbooks/models/time-entry", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/utils/parse-number", "zipbooks/utils/format-seconds-as-hours", "zipbooks/mixins/notificationable"], function (_exports, _model, _model2, _parseNumber, _formatSecondsAsHours, _notificationable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_notificationable.default, {
    billed: (0, _model.attr)('boolean'),
    date: (0, _model.attr)('date', {
      defaultValue: moment().format('YYYY-MM-DD')
    }),
    duration: (0, _model.attr)(),
    note: (0, _model.attr)(),
    gustoPayrollId: (0, _model.attr)(),
    paid: (0, _model.attr)('boolean'),
    startTime: (0, _model.attr)('datetime'),
    contact: (0, _model.belongsTo)('contact'),
    lineItem: (0, _model.belongsTo)('lineItem'),
    project: (0, _model.belongsTo)('project'),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    endTime: Ember.computed('duration', function () {
      if (this.startTime && this.duration) {
        return moment(this.startTime).add(this.duration, 'seconds');
      } else {
        return null;
      }
    }),
    hours: Ember.computed('duration', {
      get: function get() {
        return (0, _formatSecondsAsHours.default)(this.duration || 0, 'h');
      },
      set: function set(_key, value) {
        this.set('duration', (0, _parseNumber.default)(value) * 3600);
        return value;
      }
    }),
    minutes: Ember.computed('duration', function () {
      return (0, _formatSecondsAsHours.default)(this.duration || 0, 'm');
    }),
    isInProgress: Ember.computed('startTime', 'duration', function () {
      return this.startTime && this.duration === 0;
    }),
    statuses: Ember.computed('paid', 'billed', 'gustoPayrollId', function () {
      var statuses = [];

      if (this.paid) {
        statuses = statuses.concat([this.gustoPayrollId ? 'gusto-paid' : 'paid']);
      } else if (this.gustoPayrollId) {
        statuses = statuses.concat(['gusto-synced']);
      }

      if (this.billed) statuses = statuses.concat(['billed']);
      return statuses;
    })
  });

  _exports.default = _default;
});