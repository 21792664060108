define("zipbooks/routes/main/invoice", ["exports", "zipbooks/mixins/line-itemable-route", "zipbooks/mixins/refreshable-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/magic-due-date"], function (_exports, _lineItemableRoute, _refreshableRoute, _permissionRedirect, _magicDueDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lineItemableRoute.default, _refreshableRoute.default, _permissionRedirect.default, {
    session: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    model: function model(params) {
      if (params.id === 'new') {
        return this.store.createRecord('invoice', {
          account: this.get('session.account'),
          discount: 0,
          logoCloudFile: this.store.createRecord('cloudFile', {
            downloadUrl: this.get('session.account.logoCloudFile.downloadUrl')
          }),
          // remove once this is fixed https://github.com/emberjs/data/issues/5100
          cloudFiles: [],
          lineItems: []
        });
      } else {
        return this.store.findRecord('invoice', params.id, {
          reload: true
        });
      }
    },
    afterModel: function afterModel(invoice) {
      this._super.apply(this, arguments);

      if (invoice.get('isNew')) {
        var acceptCreditCards = !!this.get('session.account.settings.defaultAcceptCreditCards');
        var hasPaymentProcessor = ['zipbooks', 'stripe', 'square'].indexOf(this.get('session.account.settings.activePaymentProcessor')) > -1;

        if (acceptCreditCards && hasPaymentProcessor) {
          invoice.set('acceptCreditCards', true);
        }

        var acceptPaypal = !!this.get('session.account.settings.defaultAcceptPaypal');

        if (acceptPaypal) {
          invoice.set('acceptPaypal', true);
        }

        invoice.set('notes', invoice.get('account.settings.defaultNotes'));
        invoice.set('terms', invoice.get('account.settings.defaultTerms'));
        var dueGuess = (0, _magicDueDate.default)(invoice.get('terms'), invoice.get('date'));

        if (dueGuess) {
          invoice.set('dueDate', dueGuess);
        }
      }
    },
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'payments.bank-account.save', function () {
        _this.modelFor('main.invoice').get('contact').then(function (c) {
          return c.reload();
        });
      });
      this.notificationCenter.addObserver(this, 'payments.bank-account.verified', function () {
        _this.modelFor('main.invoice').get('contact').then(function (c) {
          return c.reload();
        });
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'payments.bank-account.save');
      this.notificationCenter.removeObserver(this, 'payments.bank-account.verified');
    }
  });

  _exports.default = _default;
});