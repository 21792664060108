define("zipbooks/components/dashboard/financial-performance", ["exports", "zipbooks/utils/format-money", "zipbooks/mixins/testable", "zipbooks/mixins/chart-custom-tooltip", "zipbooks/utils/graph-colors"], function (_exports, _formatMoney, _testable, _chartCustomTooltip, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var profitLossLabel = 'Profit / Loss';

  var _default = Ember.Component.extend(_testable.default, _chartCustomTooltip.default, {
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    show: false,
    canvasHeight: Ember.computed(function () {
      if (this.showProgressOnboarding) {
        return 260;
      } else {
        return 226;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var data = this.monthlySummaries;

      if (this.hasFinancialData(data)) {
        this.set('show', true);
        Ember.run.next(function () {
          _this.renderChart(data);
        });
      }
    },
    renderChart: function renderChart(data) {
      var _this2 = this;

      var ctx = this.$('canvas') && this.$('canvas')[0];

      if (!ctx) {
        return;
      }

      new Chart(ctx, {
        type: 'bar',
        data: this.graphData(data),
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              ticks: {
                fontFamily: 'Karla, sans-serif'
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              ticks: {
                fontFamily: 'Karla, sans-serif',
                beginAtZero: true,
                maxTicksLimit: 6,
                callback: function callback(value
                /*, index, values*/
                ) {
                  return (0, _formatMoney.default)(value, {
                    precision: 0,
                    currency: _this2.get('session.account.defaultCurrencyCode') || 'USD',
                    escaped: false
                  });
                }
              },
              gridLines: {
                color: '#dee2e6'
              }
            }]
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: $.extend(true, {
            callbacks: {
              label: function label(tooltipItem, _data) {
                return data[tooltipItem.index];
              }
            }
          }, this.customTooltipOpts(this)),
          hover: {
            onHover: function onHover(e) {
              var point = this.getElementAtEvent(e);
              if (point.length) e.target.style.cursor = 'pointer';else e.target.style.cursor = 'default';
            }
          }
        }
      });
    },
    graphData: function graphData(data) {
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var profitColor = _graphColors.default.net_income;
      var lossColor = _graphColors.default.loss;
      var graphData = {
        labels: [],
        datasets: [{
          label: 'Revenue',
          data: [],
          backgroundColor: _graphColors.default.revenue,
          titleFontColor: '#000000'
        }, {
          label: 'Expenses',
          data: [],
          backgroundColor: _graphColors.default.expenses
        }, {
          label: profitLossLabel,
          data: [],
          backgroundColor: []
        }]
      };
      data.forEach(function (summary) {
        var month = new Date(Date.parse(summary.month));
        graphData.labels.push(months[month.getUTCMonth()]);
        graphData.datasets[0].data.push(summary.revenue);
        graphData.datasets[1].data.push(summary.expenses);
        graphData.datasets[2].data.push(summary.net_income);
        graphData.datasets[2].backgroundColor.push(summary.net_income < 0 ? lossColor : profitColor);
      });
      return graphData;
    },
    hasFinancialData: function hasFinancialData(data) {
      return data.some(function (summary) {
        return summary.expenses > 0 || summary.revenue > 0 || summary.net_income > 0;
      });
    },
    chartWasClicked: function chartWasClicked(router, context) {
      var start = moment(context.month, 'YYYY-MM');
      router.transitionTo('main.reports.income-statement', {
        queryParams: {
          start_date: start.format('YYYY-MM-DD'),
          end_date: start.endOf('month').format('YYYY-MM-DD'),
          group_by: 'month'
        }
      });
    }
  });

  _exports.default = _default;
});