define("zipbooks/serializers/square-integration", ["exports", "zipbooks/serializers/application", "zipbooks/mixins/patch-serializer"], function (_exports, _application, _patchSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_patchSerializer.default, {});

  _exports.default = _default;
});