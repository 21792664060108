define("zipbooks/adapters/affini-pay-integration", ["exports", "zipbooks/adapters/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    namespace: 'v2/integrations',
    pathForType: function pathForType() {
      return 'affini-pay';
    }
  });

  _exports.default = _default;
});