define("zipbooks/components/avatar-image", ["exports", "zipbooks/components/failable-image", "zipbooks/utils/initials", "zipbooks/utils/overridable-computed"], function (_exports, _failableImage, _initials, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _failableImage.default.extend({
    initials: (0, _overridableComputed.default)('initials', function () {
      if (this.name) {
        return (0, _initials.default)(this.name);
      }
    })
  });

  _exports.default = _default;
});