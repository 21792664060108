define("zipbooks/routes/main/reports/trial-balance", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    queryParams: {
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params.start_date && params.end_date) {
        params.tags = this.paramTags(params);
        this.set('params', params);
        return this.client.cached('GET', 'reports', "trial-balance?".concat($.param(params))).then(function (resp) {
          return resp['trial_balance'];
        });
      }
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var queryParams = {
          start_date: params.start_date,
          end_date: params.end_date
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.location = '/v2/reports/trial-balance/export?' + $.param(queryParams);
      },
      exportGreatPlains: function exportGreatPlains() {
        var params = this.params;
        var queryParams = {
          start_date: params.start_date,
          end_date: params.end_date
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.location = '/v2/one-off/great-plains-export?' + $.param(queryParams);
      }
    }
  });

  _exports.default = _default;
});