define("zipbooks/components/manage-user", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    isSelected: Ember.computed('user.id', 'selectedUsers.@each.id', function () {
      var _this = this;

      return !!this.selectedUsers.find(function (user) {
        return user.id === _this.user.id;
      });
    }),
    actions: {
      selectUser: function selectUser(user) {
        var exists = this.selectedUsers.findBy('id', user.id);

        if (exists) {
          this.selectedUsers.removeObject(user);
        } else {
          this.selectedUsers.addObject(user);
        }
      }
    }
  });

  _exports.default = _default;
});