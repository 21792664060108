define("zipbooks/mixins/settings-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    model: function model() {
      return this.session.get('account');
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting && controller.get('model').rollbackAttributes) {
        controller.get('model').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});