define("zipbooks/components/gusto/unassigned-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    client: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchUsers();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('reference.userAssociator', this);
    },
    fetchUsers: function fetchUsers() {
      var _this = this;

      this.set('loading', true);
      this.store.cachedQuery('user', {
        filter: {
          archived: false,
          gusto: 'unassigned'
        },
        sort: 'display_name',
        include: 'identity.avatar_cloud_file'
      }).then(function (users) {
        _this.set('rows', users.map(function (user) {
          user.set('needsName', Ember.isBlank(user.identity.get('firstName')) || Ember.isBlank(user.identity.get('lastName')));
          return {
            operation: 'dont-add',
            user: user
          };
        }));

        _this.set('reference.unassignedUsersCount', _this.rows.length);

        _this.set('loading', false);
      });
    },
    save: function save() {
      var usersToAdd = this.rows.filter(function (row) {
        return row.operation === 'add-user';
      }).map(function (row) {
        if (Ember.isBlank(row.user.identity.get('firstName'))) {
          row.user.identity.set('errors.firstName', [{
            message: 'First name is required.'
          }]);
        } else {
          row.user.identity.set('errors.firstName', []);
        }

        if (Ember.isBlank(row.user.identity.get('lastName'))) {
          row.user.identity.set('errors.lastName', [{
            message: 'Last name is required.'
          }]);
        } else {
          row.user.identity.set('errors.lastName', []);
        }

        return {
          id: row.user.id,
          'first-name': row.user.identity.get('firstName'),
          'last-name': row.user.identity.get('lastName')
        };
      });

      if (usersToAdd.length === 0) {
        return Ember.RSVP.all([]);
      } else {
        var usersReady = usersToAdd.every(function (u) {
          return !Ember.isBlank(u['first-name']) && !Ember.isBlank(u['last-name']);
        });

        if (usersReady) {
          return this.client.POST('integrations/gusto/employees', null, {
            users: usersToAdd
          });
        } else {
          return Ember.RSVP.reject();
        }
      }
    }
  });

  _exports.default = _default;
});