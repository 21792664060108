define("zipbooks/components/drawers/tfa-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    title: '2-Step Verification',
    client: Ember.inject.service(),
    isLoading: true,
    verifyCode: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchTfaInfo();
    },
    fetchTfaInfo: function fetchTfaInfo() {
      var _this = this;

      this.client.send('GET', 'auth', 'tfa-info').then(function (resp) {
        _this.setProperties({
          enabled: resp.enabled,
          tfa_secret: resp.tfa_secret,
          qr_info: resp.qr_info,
          isLoading: false
        });
      });
    },
    validateCode: function validateCode() {
      if (this.get('verifyCode.length') !== 6) {
        this.set('errors', {
          verifyCode: [{
            message: 'The verification code must be 6 digits.'
          }]
        });
        this.set('buttonState', 'invalid');
        return false;
      }

      this.set('errors', null);
      return true;
    },
    handleError: function handleError(jqXHR) {
      this.set('buttonState', 'invalid');

      if (jqXHR.responseJSON && jqXHR.status === 422) {
        this.set('errors', {
          verifyCode: [{
            message: 'The verification code is incorrect.'
          }]
        });
      } else {
        this.overlay.showBanner('error', 'There was some problem on our end. Sorry!', {
          title: 'Oops!'
        });
      }
    },
    handleSuccess: function handleSuccess(message) {
      this.set('buttonState', 'loaded');
      this.overlay.showBanner('success', message);
      this.handleClose();
    },
    actions: {
      enable: function enable() {
        var _this2 = this;

        this.set('buttonState', 'saving');

        if (!this.validateCode()) {
          Ember.run.later(this, function () {
            return _this2.set('buttonState', 'invalid');
          }, 20);
          return;
        }

        var body = {
          code: this.verifyCode,
          secret: this.get('tfa_secret')
        };
        this.client.send('POST', 'auth', 'enable-tfa', body).then(function () {
          return _this2.handleSuccess('TFA Enabled!');
        }, function (jqXHR) {
          return _this2.handleError(jqXHR);
        });
      },
      disable: function disable() {
        var _this3 = this;

        this.set('buttonState', 'saving');

        if (!this.validateCode()) {
          Ember.run.later(this, function () {
            return _this3.set('buttonState', 'invalid');
          }, 20);
          return;
        }

        var body = {
          code: this.verifyCode,
          secret: this.get('tfa_secret')
        };
        this.client.send('POST', 'auth', 'disable-tfa', body).then(function () {
          return _this3.handleSuccess('TFA Disabled!');
        }, function (jqXHR) {
          return _this3.handleError(jqXHR);
        });
      }
    }
  });

  _exports.default = _default;
});