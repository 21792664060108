define("zipbooks/utils/decode-entities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = decodeEntities;

  function decodeEntities(html) {
    return $('<textarea />').html(html).text();
  }
});