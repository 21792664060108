define("zipbooks/templates/components/drawers/-journal-entry-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iDRuRhm5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"modeComponent\"]]],[[\"entry\",\"defaultAccount\",\"defaultDate\",\"tagName\",\"save\",\"shared\"],[[24,[\"model\"]],[24,[\"defaultAccount\"]],[24,[\"rest\",\"date\"]],\"\",[28,\"action\",[[23,0,[]],\"save\"],null],[24,[\"shared\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-journal-entry-form.hbs"
    }
  });

  _exports.default = _default;
});