define("zipbooks/routes/main/reports/cash-flow-statement", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    queryParams: {
      group_by: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var urlTags = this.paramTags(params);

      var _this$decodeTags = this.decodeTags(urlTags),
          kind = _this$decodeTags.kind,
          rest = _this$decodeTags.rest;

      if (kind) params.group_by = kind;
      params.tags = rest;

      if (moment(params.start_date).isAfter(moment(params.end_date))) {
        params.start_date = params.end_date;
      }

      this.set('params', params);
      return this.get('client').send('GET', 'reports', "cash-flow-statement?".concat($.param(params)));
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      this._super.apply(this, arguments);

      controller.setup();
    }
  });

  _exports.default = _default;
});