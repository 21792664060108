define("zipbooks/controllers/main/settings/preferences", ["exports", "zipbooks/utils/currencies", "zipbooks/mixins/paid-modal-handler"], function (_exports, _currencies, _paidModalHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_paidModalHandler.default, {
    queryParams: ['tab'],
    tab: 'general',
    currencies: Object.keys(_currencies.default),
    availableProcessors: Ember.computed('model.integrations', function () {
      return [{
        value: 'square',
        title: 'Square (Recommended)',
        available: this.model.integrations.square
      }, {
        value: 'stripe',
        title: 'Stripe',
        available: this.model.integrations.stripe.connected
      }, {
        value: 'AffiniPay',
        title: 'LawPay',
        available: this.model.integrations.lawpay.connected
      }, {
        value: 'AffiniPay',
        title: 'CPACharge',
        available: this.model.integrations.cpacharge.connected
      }].filter(function (p) {
        return p.available;
      });
    }),
    actions: {
      selectCurrency: function selectCurrency(currency) {
        this.set('model.defaultCurrencyCode', currency);
      },
      save: function save() {
        var _this = this;

        this.set('buttonStatus', 'saving');
        this.get('model.settings').then(function (settings) {
          Ember.RSVP.all([_this.model.save(), settings.save()]).then(function () {
            _this.set('buttonStatus', 'loaded');
          }, function () {
            _this.set('buttonStatus', 'invalid');
          });
        });
      },
      selectChartAccount: function selectChartAccount(chartAccount) {
        this.set('model.settings.defaultInvoiceSalesChartAccount', chartAccount);
      }
    }
  });

  _exports.default = _default;
});