define("zipbooks/templates/components/drawers/-team-account-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVkvxXpc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-3\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Permissions\"],[9],[0,\"\\n    \"],[1,[28,\"permissions/permissions-list\",null,[[\"map\"],[[24,[\"model\",\"permissionsMap\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"action\",\"model\",\"title\",\"class\",\"htmlTitle\",\"testId\"],[[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null],[24,[\"model\"]],\"Save\",\"btn btn-full btn-depressable btn-purple\",\"Save\",\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-team-account-form.hbs"
    }
  });

  _exports.default = _default;
});