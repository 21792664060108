define("zipbooks/templates/components/check-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+uVCz3f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"check-all\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"form-check-label\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"checked\",\"type\",\"class\",\"change\"],[[24,[\"checked\"]],\"checkbox\",\"form-check-input\",[28,\"action\",[[23,0,[]],\"toggle\"],null]]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-checkbox\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/check-all.hbs"
    }
  });

  _exports.default = _default;
});