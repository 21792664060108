define("zipbooks/utils/default-bank-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultBankAccount;

  /*
   * If Invoice currency = USD, then default "account" to Primary Bank Account
   * If Invoice currency <> USD, then default "account to Cash
   *
   * Primary Bank Account =
   *   Connected account that contains "Checking" (and is not deleted).
   *   Connected account that is the earliest created at (and is not deleted).
   */
  function defaultBankAccount(chartAccounts, code) {
    var bankChartAccounts = chartAccounts.filter(function (ca) {
      return ca.get('isBankAccount') && ca.get('bankAccount.connected') && ca.get('bankAccount.currencyCode') === code;
    });
    var defaultAccount = bankChartAccounts.find(function (ca) {
      return ca.get('bankAccount.name').toLowerCase().indexOf('checking') > -1;
    });
    if (defaultAccount) return defaultAccount;
    defaultAccount = bankChartAccounts.sortBy('createdAt').get('firstObject');
    if (defaultAccount) return defaultAccount;
    return chartAccounts.find(function (ca) {
      return ca.get('systemCode') === '1200';
    });
  }
});