define("zipbooks/utils/string", ["exports", "zipbooks/utils/pluralize", "ember-inflector", "zipbooks/utils/humanize"], function (_exports, _pluralize, _emberInflector, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = string;
  var strings = {
    contact: {
      destroy: {
        warning: 'Deleting a contact will also delete everything associated with it, including invoices, estimates, recurring profiles, transactions, and tracking data. If you want to keep this data, you can archive a contact instead.'
      }
    },
    project: {
      destroy: {
        warning: 'This will delete any subprojects and time entries assigned to th(is/ese) project(/s).'
      }
    },
    invoice: {
      destroy: {
        warning: 'This will delete any transaction associated with this invoice.'
      }
    },
    'chart-account': {
      destroy: {
        warning: 'Deleting th(is/ese) categor(y/ies) will unassign all associated transactions.'
      }
    },
    default: {
      destroy: {
        title: 'Are you sure?',
        body: 'Are you sure you want to delete (this/{{count}}) ({{singular}}/{{plural}})?'
      },
      success: {
        body: '{{count}} ({{singular}}/{{plural}}) (was/were) {{action}}.'
      },
      failure: {
        body: 'Failed to {{action}} {{count}} ({{singular}}/{{plural}}).'
      }
    }
  };

  function string() {
    // parse arguments
    var args = [].slice.call(arguments);
    var path = [];
    var options = {};
    args.forEach(function (a) {
      if (Ember.typeOf(a) === 'string') {
        path.push(a);
      } else {
        options = a;
      }
    }); // find string

    var search = function search(path) {
      var node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : strings;

      if (Ember.typeOf(node) === 'string') {
        return node;
      }

      var found;
      var key = path[0];

      if (node[key]) {
        found = search(path.slice(1), node[key]);
      }

      if (!found && node['default']) {
        found = search(path.slice(1), node['default']);
      }

      return found;
    };

    var str = search(path);

    if (!str) {
      return null;
    } // shortcut for plural/singular


    if (!options.plural) {
      var word = options.singular || args[0];

      if (options.humanize) {
        word = (0, _humanize.default)(word);
      }

      options.singular = word;
      options.plural = (0, _emberInflector.pluralize)(word);
      delete options.pluralize;
    } // token replace


    str = str.replace(/{{(.*?)}}/g, function (_match, token) {
      if (options[token]) {
        return options[token];
      }

      return token;
    }); // pluralize

    str = (0, _pluralize.default)(str, options.count || 1);
    return str;
  }
});