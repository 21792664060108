define("zipbooks/components/drawers/time-entry-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/utils/format-seconds-as-hours", "zipbooks/utils/parse-duration"], function (_exports, _modelDrawer, _formatSecondsAsHours, _parseDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    specificTimes: false,
    startTime: null,
    endTime: null,
    duration: null,
    date: null,
    timeFormat: 'h:mma',
    dateFormat: 'YYYY-MM-DD',
    init: function init() {
      this._super.apply(this, arguments); // preload projects so they appear faster


      this.store.findAll('project');
      this.set('drawerOpenTime', moment());
      this.store.cachedQuery('user').then(function (results) {
        // preload avatars
        results.forEach(function (user) {
          var logoUrl = user.get('identity.avatarCloudFile.downloadUrl');

          if (logoUrl) {
            var logo = new Image();
            logo.src = logoUrl;
          }
        });
      });
    },
    afterModel: function afterModel(model) {
      if (!model.get('user.id')) {
        model.set('user', this.get('session.currentUser'));
      }

      this.set('date', model.get('date'));
      this.set('duration', model.get('duration') || 0);

      if (model.get('startTime')) {
        this.set('specificTimes', true);
        this.set('startTime', moment(model.get('startTime')));
        this.set('endTime', (model.get('endTime') || this.drawerOpenTime).format(this.timeFormat));
      } else {
        this.set('specificTimes', false);
        this.set('startTime', null);
      }

      if (this.get('rest.project')) {
        this.store.find('project', this.get('rest.project')).then(function (result) {
          model.set('project', result);
          model.set('contact', result.get('contact'));
        });
      } else if (this.get('rest.contact')) {
        this.store.find('contact', this.get('rest.contact')).then(function (result) {
          model.set('contact', result);
        });
      }
    },
    hours: Ember.computed('duration', {
      get: function get() {
        return (0, _formatSecondsAsHours.default)(this.duration || 0, 'h:m');
      },
      set: function set(_key, value) {
        return value;
      }
    }),
    contactDisabled: Ember.computed('model.project.id', function () {
      return !!this.get('model.project.id') && !this.get('model.project.allowMultipleContacts');
    }),
    actions: {
      selectUser: function selectUser(user) {
        this.set('model.user', user);
      },
      selectProject: function selectProject(project) {
        this.set('model.project', project);

        if (project && project.get('contact.id')) {
          this.set('model.contact', project.get('contact'));
        }
      },
      selectContact: function selectContact(contact) {
        this.set('model.contact', contact);
      },
      save: function save(model) {
        var _this = this;

        this.set('durationError', null);

        if (this.duration < 1) {
          this.set('model.errors.duration', [{
            message: "Duration can't be zero."
          }]);
          this.set('durationError', "Duration can't be zero.");
          return;
        }

        if (this.get('model.project.timeEntries')) {
          var push = function push(m) {
            return _this.get('model.project.timeEntries').pushObject(m);
          };

          if (model.then) {
            model.then(function (m) {
              push(m);
            });
          } else {
            push(model);
          }
        }

        if (this.specificTimes && this.startTime) {
          model.set('startTime', this.startTime);
        } else {
          model.set('startTime', null);
        }

        model.set('duration', this.duration);
        model.set('date', this.date);

        this._super(model);
      },
      setDuration: function setDuration() {
        var duration = (0, _parseDuration.default)(this.hours);
        this.set('duration', duration.as('seconds'));
        this.set('hours', (0, _formatSecondsAsHours.default)(this.duration, 'h:m'));
      },
      toggleSpecificTimes: function toggleSpecificTimes() {
        if (!this.startTime) {
          this.set('startTime', moment().set({
            seconds: 0
          }));
        }

        this.set('specificTimes', !this.specificTimes);
      },
      calculateDuration: function calculateDuration() {
        var startTime = this.startTime;
        var endTime = this.endTime;
        var endDate;
        this.set('date', this.startTime.format(this.dateFormat)); // if not military time and does not contain am or pm add pm

        if (endTime && endTime.split(':')[0] <= 12 && !endTime.match(/am|pm/g)) {
          endTime = startTime.format(this.dateFormat) + ' ' + endTime;
          endDate = moment(endTime + 'pm', ['YYYY-MM-DD h:ma', 'YYYY-MM-DD H:m']); // if new end date is greater than 12 hours away switch to am

          if (endDate.diff(startTime, 'seconds') > 43200 || endDate.diff(startTime, 'seconds') < 0) {
            endDate = moment(endTime + 'am', ['YYYY-MM-DD h:ma', 'YYYY-MM-DD H:m']);
          }
        } else {
          endTime = startTime.format(this.dateFormat) + ' ' + endTime;
          endDate = moment(endTime, ['YYYY-MM-DD h:ma', 'YYYY-MM-DD H:m'], true);
        }

        if (endDate.isValid() && this.endTime) {
          this.set('endTime', endDate.format('h:mma'));

          if (endDate.isBefore(startTime)) {
            endDate = endDate.add('1', 'day');
          }

          this.set('duration', endDate.diff(startTime, 'seconds'));
        } else {
          this.set('endTime', (this.get('model.endTime') || this.drawerOpenTime).format(this.timeFormat));
          this.set('duration', moment.duration());
        }
      },
      unsyncFromGusto: function unsyncFromGusto() {
        var _this2 = this;

        this.set('buttonState', 'saving');
        var params = {
          filter: {
            date_range: "".concat(this.get('model.date'), "..").concat(this.get('model.date')),
            time_entry_ids: [this.get('model.id')]
          },
          unsync: true
        };
        return this.client.PATCH('integrations/gusto', 'payroll', params).then(function () {
          _this2.set('buttonState', 'loaded');

          _this2.store.find('time-entry', _this2.get('model.id'));
        }, function () {
          _this2.set('buttonState', 'invalid');

          _this2.overlay.showBanner('error', 'Gusto was unable to process the request. Please try again.');
        });
      }
    }
  });

  _exports.default = _default;
});