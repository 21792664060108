define("zipbooks/models/location", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/mixins/taggable"], function (_exports, _model, _model2, _taggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_taggable.default, {
    name: (0, _model.attr)(),
    address_1: (0, _model.attr)(),
    address_2: (0, _model.attr)(),
    city: (0, _model.attr)(),
    state: (0, _model.attr)(),
    postalCode: (0, _model.attr)(),
    country: (0, _model.attr)(),
    website: (0, _model.attr)(),
    phone: (0, _model.attr)(),
    description: (0, _model.attr)(),
    isPrimary: (0, _model.attr)(),
    account: (0, _model.belongsTo)('account'),
    integrationObject: (0, _model.belongsTo)('integrationObject')
  });

  _exports.default = _default;
});