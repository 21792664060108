define("zipbooks/adapters/cloud-file", ["exports", "zipbooks/adapters/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    buildURL: function buildURL(modelName, _id, snapshot, requestType, query) {
      return this._super(modelName, snapshot.record.get('token'), snapshot, requestType, query);
    }
  });

  _exports.default = _default;
});