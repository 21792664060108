define("zipbooks/controllers/main/project", ["exports", "zipbooks/mixins/archive-actionable"], function (_exports, _archiveActionable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_archiveActionable.default, {
    overlay: Ember.inject.service(),
    client: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    actions: {
      generateInvoice: function generateInvoice() {// TODO: go to new invoice
      },
      destroy: function destroy() {
        var _this = this;

        this.overlay.showDialog('overlays/dialogs/destroy-confirm', 'Are you sure?', {
          type: 'project'
        }, {
          destroy: function destroy() {
            _this.urlCache.clear();

            _this.model.destroyRecord().then(function ()
            /*resp*/
            {
              _this.transitionToRoute('main.projects');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});