define("zipbooks/components/file-select", ["exports", "zipbooks/components/file-upload"], function (_exports, _fileUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileUpload.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selected', []);
    },
    handleFiles: function handleFiles(files) {
      this.selectedFiles(files);
    }
  });

  _exports.default = _default;
});