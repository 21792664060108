define("zipbooks/models/token", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/mixins/taggable", "zipbooks/mixins/actorable"], function (_exports, _model, _model2, _taggable, _actorable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_taggable.default, _actorable.default, {
    token: (0, _model.attr)(),
    tokenable: (0, _model.belongsTo)('tokenable', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});