define("zipbooks/components/dashboard/accounts-receivable", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/chart-custom-tooltip", "zipbooks/utils/graph-colors"], function (_exports, _testable, _chartCustomTooltip, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _chartCustomTooltip.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    total: Ember.computed('data.@each', function () {
      return this.data.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
    }),
    sortedAccounts: Ember.computed('data.@each', function () {
      var accounts = this.data;
      var total = accounts.reduce(function (acc, c) {
        return acc + c.total * 1;
      }, 0);
      var colorIndex = 0;
      return accounts.sort(function (a, b) {
        return b.total - a.total;
      }).map(function (customer) {
        if (customer.customer_name === 'Unassigned') {
          Ember.set(customer, 'backgroundColor', '#BEC5CB');
        } else {
          Ember.set(customer, 'backgroundColor', _graphColors.default.cycle[colorIndex % _graphColors.default.cycle.length]);
          colorIndex += 1;
        }

        Ember.set(customer, 'percent', customer.total * 1 / total * 100);
        return customer;
      });
    }),
    topAccounts: Ember.computed('sortedAccounts.@each', function () {
      if (this.get('sortedAccounts.length') > 6) {
        var other = this.sortedAccounts.slice(6).reduce(function (acc, c) {
          return acc + c.total * 1;
        }, 0);
        return this.sortedAccounts.slice(0, 5).concat([{
          customer_name: 'Other',
          total: other,
          backgroundColor: '#E8EAEE'
        }]);
      } else {
        return this.sortedAccounts;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.session.chartAccountsPromise.then(function (_results) {
        _this.set('accountsReceivableId', _this.session.chartAccounts.find(function (ca) {
          return ca.systemCode === '1300';
        }).id);

        var ctx = _this.$('canvas') && _this.$('canvas')[0];

        if (!ctx) {
          return;
        }

        var data = _this.sortedAccounts.filter(function (c) {
          return c.total * 1 > 0;
        }).slice(0, 50);

        new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [{
              data: data.map(function (a) {
                return a.total;
              }),
              backgroundColor: data.map(function (a) {
                return a.backgroundColor;
              }),
              hoverBackgroundColor: data.map(function (a) {
                return a.backgroundColor;
              }),
              borderWidth: 0,
              pointRadius: 0
            }],
            labels: data.map(function (a) {
              return a.customer_name;
            })
          },
          options: {
            aspectRatio: 1,
            cutoutPercentage: 56,
            legend: {
              display: false
            }
          }
        });

        var ctx2 = _this.$('canvas')[1];

        if (!ctx2) {
          return;
        }

        new Chart(ctx2, {
          type: 'doughnut',
          data: {
            datasets: [{
              data: data.map(function (a) {
                return a.total;
              }),
              backgroundColor: data.map(function (a) {
                return a.backgroundColor;
              }),
              hoverBackgroundColor: data.map(function (a) {
                return Chart.helpers.color(a.backgroundColor).saturate(0.1).darken(0.05).rgbString();
              }),
              borderWidth: 0,
              pointRadius: 0
            }],
            labels: data.map(function (a) {
              return a.contact_id;
            })
          },
          options: {
            aspectRatio: 1,
            cutoutPercentage: 67,
            legend: {
              display: false
            },
            tooltips: $.extend(true, {
              callbacks: {
                label: function label(tooltipItem, _data) {
                  return data[tooltipItem.index];
                }
              }
            }, _this.customTooltipOpts(_this)),
            hover: {
              onHover: function onHover(e) {
                var point = this.getElementAtEvent(e);
                if (point.length) e.target.style.cursor = 'pointer';else e.target.style.cursor = 'default';
              }
            }
          }
        });
      });
    },
    chartWasClicked: function chartWasClicked(router, context) {
      if (context.contact_id) {
        router.transitionTo('main.invoices', {
          queryParams: {
            contact_id: context.contact_id,
            status: 'unpaid',
            archived: false,
            filter: null,
            page: null
          }
        });
      } else {
        router.transitionTo('main.transactions', {
          queryParams: {
            chart_account_id: this.accountsReceivableId,
            page: null
          }
        });
      }
    }
  });

  _exports.default = _default;
});