define("zipbooks/mixins/index-route", ["exports", "zipbooks/mixins/partial-page-refresh", "zipbooks/mixins/refreshable-route"], function (_exports, _partialPageRefresh, _refreshableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_partialPageRefresh.default, _refreshableRoute.default, {
    queryParams: {
      sort: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      },
      archived: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});