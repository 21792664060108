define("zipbooks/components/time/time-tracker", ["exports", "zipbooks/mixins/time-tracking"], function (_exports, _timeTracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_timeTracking.default, {
    store: Ember.inject.service(),
    ticker: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this.checkForUnfinished();
      this.calculateTimeEntries();
      this.notificationCenter.addObserver(this, 'time-entry.save', function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.calculateTimeEntries();
        }
      });
      this.notificationCenter.addObserver(this, 'time-entry.destroy', function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.calculateTimeEntries();
        }
      });
      this.setupWebSocket(this.get('session.token'));

      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.ticker.stop();
      this.notificationCenter.removeObserver(this, 'time-entry.save');
      this.notificationCenter.removeObserver(this, 'time-entry.destroy');
    },
    // allows for live updating of bar colors in summary bar (as user changes project/contact/billed)
    _allTimeEntries: Ember.computed(function () {
      return this.store.peekAll('time-entry');
    }),
    _timeEntryProjectContactChanged: Ember.observer('_allTimeEntries.@each.contact', '_allTimeEntries.@each.project', function () {
      this.calculateTimeEntries();
    }),
    calculateTimeEntries: function calculateTimeEntries() {
      var _this2 = this;

      var timeEntries = this.store.peekAll('time-entry').filter(function (te) {
        return te.get('user.id') == _this2.get('session.currentUser.id') && moment(te.get('date')).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && !!te.get('duration');
      }).sortBy('startTime').reverse();
      var total = timeEntries.reduce(function (acc, te) {
        if (te.get('duration')) {
          return acc + te.get('duration');
        } else {
          return acc + ((moment() - te.get('startTime')) / 10000).round(0);
        }
      }, 0);
      var percentage = 0;
      var min = 0.75;
      var totalPercentage = 0;
      var nonMinPercentage = 0;
      var extraPercentage = 0;
      timeEntries = timeEntries.map(function (timeEntry) {
        percentage = Math.max(timeEntry.get('duration') / total * 100, min);
        totalPercentage += percentage;
        timeEntry.set('oldPercentage', timeEntry.getWithDefault('percentage', 0));
        timeEntry.set('percentage', percentage);
        return timeEntry;
      }).reverse();
      var totalDuration = 0;
      timeEntries.forEach(function (timeEntry) {
        totalDuration += timeEntry.get('duration');
      });
      this.set('totalDuration', totalDuration); // distribute extra percentage by reducing time entries greater than the min percentage by their relative weight

      if (totalPercentage > 100) {
        timeEntries.forEach(function (timeEntry) {
          if (timeEntry.get('percentage') > min) nonMinPercentage += timeEntry.get('percentage');
        });
        extraPercentage = totalPercentage - 100;
        timeEntries = timeEntries.map(function (timeEntry) {
          if (timeEntry.get('percentage') > min) {
            percentage = timeEntry.get('percentage') - timeEntry.get('percentage') / nonMinPercentage * extraPercentage;
          } else {
            percentage = timeEntry.get('percentage');
          }

          timeEntry.set('percentage', percentage);
          return timeEntry;
        });
      }

      this.set('timeEntries', timeEntries);
    },
    afterStart: function afterStart(model) {
      this.didStartEntry(model);
    }
  });

  _exports.default = _default;
});