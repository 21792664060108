define("zipbooks/components/error-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ErrorLabelComponent = Ember.Component.extend({});
  ErrorLabelComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = ErrorLabelComponent;
  _exports.default = _default;
});