define("zipbooks/components/color-picker", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    classNames: ['color-picker'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var picker = this.$('.color-picker-inner').colorPicker({
        animationSpeed: 150,
        renderCallback: function renderCallback($elm
        /*, toggled*/
        ) {
          if (!_this.isDestroyed && !_this.isDestroying) {
            _this.set('value', $elm.val());

            $elm.css({
              color: 'transparent'
            });
          } else {
            _this.$('.color-picker-inner').unbind().removeData();
          }
        },
        opacity: false
      }).css({
        color: 'transparent'
      });
      this.set('picker', picker);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        var picker = _this2.picker;
        picker.css({
          'background-color': _this2.value
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('.color-picker-inner').unbind().removeData();
    }
  });

  _exports.default = _default;
});