define("zipbooks/mixins/add-journal-entry-line-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    actions: {
      addTag: function addTag(tagData) {
        this.addTag(tagData);
      },
      removeTag: function removeTag(tag) {
        this.removeTag(tag);
      }
    },
    addTag: function addTag(tagData) {
      var _this = this;

      var taggable = this.store.peekRecord(tagData.type, tagData.id);

      if (!taggable) {
        taggable = this.store.push({
          data: {
            id: tagData.id,
            type: tagData.type,
            attributes: {
              name: tagData.name,
              title: tagData.name
            }
          }
        });
      }

      var toBeTagged = this.linesToBeTagged(); // if its an invoice or contact/customer, do some validation

      var uniqueTypes = ['invoice', 'customer', 'contact'];

      if (uniqueTypes.includes(tagData.type)) {
        // make sure they havent' added this tag already
        var alreadyHasTag = toBeTagged.any(function (l) {
          return l.get('tags').any(function (t) {
            return t.get('taggableType') === tagData.type;
          });
        });

        if (alreadyHasTag) {
          this.overlay.showBanner('error', "You\u2019ve already added a ".concat(tagData.type, " on this transaction."));
          return;
        }
      }

      toBeTagged.forEach(function (l) {
        var tag = _this.store.createRecord('tag', {
          name: tagData.name,
          taggableType: tagData.type,
          kind: tagData.kind,
          taggable: taggable
        });

        l.get('tags').pushObject(tag);

        _this.tagWasAdded(tag, l);
      });
    },
    removeTag: function removeTag(tag) {
      var _this2 = this;

      this.linesToBeTagged().forEach(function (l) {
        l.get('tags').filter(function (t) {
          return t.get('name') === tag.get('name');
        }).forEach(function (thisTag) {
          l.get('tags').removeObject(thisTag);

          _this2.tagWasRemoved(thisTag);
        });
      });
    },

    /*************************************************
     * AddJournalEntryLineTagsMixin Protocol
     **************************************************/
    linesToBeTagged: function linesToBeTagged() {
      (false && Ember.warn('You must implement this', {
        id: 'linesToBeTagged'
      }));
    },
    tagWasAdded: function tagWasAdded()
    /*tag*/
    {
      (false && Ember.warn('You must implement this', {
        id: 'tagWasAdded'
      }));
    },
    tagWasRemoved: function tagWasRemoved()
    /*tag*/
    {
      (false && Ember.warn('You must implement this', {
        id: 'tagWasRemoved'
      }));
    }
  });

  _exports.default = _default;
});