define("zipbooks/components/dashboard/business-health-score", ["exports", "zipbooks/mixins/chart-custom-tooltip", "zipbooks/helpers/score-color", "zipbooks/utils/graph-colors"], function (_exports, _chartCustomTooltip, _scoreColor, _graphColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartCustomTooltip.default, {
    didInsertElement: function didInsertElement() {
      var ctx = this.$('canvas') && this.$('canvas')[0];

      if (!ctx) {
        return;
      }

      var data = this.get('data.last_30_days');
      var color = {
        '': _graphColors.default.expenses,
        red: _graphColors.default.loss,
        gold: _graphColors.default.expenses,
        green: _graphColors.default.net_income
      }[(0, _scoreColor.scoreColor)([this.get('data.score')])];
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map(function (d) {
            return moment(d.date).format('MMM D');
          }),
          datasets: [{
            data: data.map(function (d) {
              return d.score;
            }),
            borderColor: color,
            fill: false,
            backgroundColor: color,
            borderWidth: 2
          }]
        },
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          layout: {
            padding: {
              top: 20
            }
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              barThickness: 15,
              ticks: {
                padding: 20,
                autoSkipPadding: 200,
                fontFamily: 'Karla, sans-serif',
                maxRotation: 0,
                fontColor: '#596071'
              },
              gridLines: {
                display: false,
                drawBorder: false,
                color: '#dee2e6'
              }
            }],
            yAxes: [{
              ticks: {
                padding: 10,
                beginAtZero: false,
                min: 0,
                max: 100,
                stepSize: 20,
                autoSkipPadding: 30,
                fontFamily: 'Karla, sans-serif',
                maxRotation: 0,
                fontColor: '#596071'
              },
              gridLines: {
                drawBorder: false,
                color: '#dee2e6'
              },
              scaleLabel: {
                display: false
              }
            }]
          },
          tooltips: $.extend(true, {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function label(tooltipItem, _data) {
                return data[tooltipItem.index];
              }
            }
          }, this.customTooltipOpts(this))
        }
      });
    }
  });

  _exports.default = _default;
});