define("zipbooks/helpers/safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.safe = safe;
  _exports.default = void 0;

  function safe(params
  /*, hash*/
  ) {
    return Ember.String.htmlSafe(params[0]);
  }

  var _default = Ember.Helper.helper(safe);

  _exports.default = _default;
});