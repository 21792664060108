define("zipbooks/templates/components/suggest/item-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BxE0fek2",
    "block": "{\"symbols\":[\"choice\",\"index\",\"&inverse\",\"&default\"],\"statements\":[[1,[28,\"content-editable\",null,[[\"value\",\"placeholder\",\"editable\",\"active\",\"class\",\"key-up\",\"title\",\"testId\"],[[24,[\"searchText\"]],[24,[\"placeholder\"]],[24,[\"editable\"]],[24,[\"active\"]],\"font-size-14px font-weight-bold\",[28,\"action\",[[23,0,[]],\"updateSearch\"],null],[24,[\"title\"]],[24,[\"testId\"]]]]],false],[0,\"\\n\\n\"],[7,\"label\",true],[11,\"class\",[29,[\"label-inside \",[28,\"unless\",[[24,[\"animateLabel\"]],\"no-animation\"],null],\" \",[22,\"fadeClass\"]]]],[8],[1,[22,\"labelString\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[11,\"class\",[29,[\"autosuggest \",[22,\"sanitizedDropdownClass\"],\" \",[28,\"if\",[[28,\"or\",[[24,[\"showAdd\"]],[28,\"gt\",[[24,[\"filteredResults\",\"length\"]],0],null],[28,\"and\",[[28,\"gt\",[[24,[\"searchText\",\"length\"]],0],null],[25,3],[24,[\"filteredResults\",\"isSettled\"]]],null]],null],\"\",\"hidden\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredResults\"]]],null,{\"statements\":[[4,\"tapp-able\",null,[[\"action\",\"class\",\"testId\",\"tagName\"],[[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null],[28,\"concat\",[\"autosuggest-item \",[28,\"if\",[[28,\"eq\",[[24,[\"selectionIndex\"]],[23,2,[]]],null],\"autosuggest-item--selected\"],null]],null],\"auto-suggest-item\",\"li\"]],{\"statements\":[[0,\"        \"],[14,4,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"autosuggest-item no-results\"],[8],[0,\"\\n        \"],[14,3],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/suggest/item-select.hbs"
    }
  });

  _exports.default = _default;
});