define("zipbooks/templates/main/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n94ojrr+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"col-12 col-md-9 fadeInFast no-right-column\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"index-row-content/integration\",null,[[\"id\",\"logo\",\"logoClass\",\"recommended\",\"preferenceDrawer\",\"connected\",\"authUrl\",\"qualification\",\"description\"],[\"square\",\"/assets/images/brand/square-logo.svg\",\"w-10rem\",true,\"square-integration\",[24,[\"session\",\"account\",\"integrations\",\"square\"]],\"square\",\"US & CANADA ONLY\",[28,\"safe\",[\"Connect Square to ZipBooks to accept online payments or swipe credit and debit cards &mdash; all seamlessly synced into your ZipBooks account.\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"index-row-content/integration\",null,[[\"id\",\"logo\",\"logoClass\",\"preferenceDrawer\",\"connected\",\"route\",\"description\"],[\"gusto\",\"/assets/images/brand/gusto-logo.svg\",\"w-10rem\",\"gusto-integration\",[24,[\"session\",\"account\",\"integrations\",\"gusto\",\"connected\"]],\"main.payroll\",\"ZipBooks integrates seamlessly with Gusto, syncing tracked time and automatically categorizing the wages, taxes, and all other aspects of your payroll transactions.\"]]],false],[0,\"\\n\\n    \"],[1,[28,\"index-row-content/integration\",null,[[\"id\",\"connected\",\"description\",\"authUrl\",\"logo\"],[\"slack\",[24,[\"session\",\"account\",\"integrations\",\"slack\"]],[28,\"safe\",[\"Extend ZipBooks functionality into Slack with the ability to track time, view income, add expenses, and track accounts receivable, all from Slack. <a target=\\\"_blank\\\" class=\\\"blue\\\" href=\\\"https://zipbooks.com/help/slack-integration/\\\">Learn more</a>\"],null],\"slack\",\"/assets/images/brand/slack-logo.svg\"]]],false],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/main/integrations/index.hbs"
    }
  });

  _exports.default = _default;
});