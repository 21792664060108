define("zipbooks/routes/main/contacts", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/sort-param"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _permissionRedirect, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    queryParams: {
      archived: {
        refreshModel: true
      },
      kind: {
        refreshModel: true
      }
    },
    autoUpdatingEvent: 'contact.save',
    model: function model(params) {
      return this.store.cachedQuery('contact', {
        page: {
          page: params.page || 1,
          'page-size': 30
        },
        filter: {
          archived: params.archived || false,
          type: params.kind
        },
        sort: (0, _sortParam.default)(params)
      });
    }
  });

  _exports.default = _default;
});