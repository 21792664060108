define("zipbooks/mixins/partial-page-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      loading: function loading(transition
      /*, originRoute*/
      ) {
        var _this = this;

        var router = Ember.getOwner(this).lookup('router:main');

        if (transition.targetName === router.currentPath) {
          // if the route didn't change (only query params did) don't bubble up
          // to application route that handles using the -loading.hbs
          this.controller.set('isRefreshing', true);
          transition.finally(function () {
            _this.controller.set('isRefreshing', false);
          });
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});