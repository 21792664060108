define("zipbooks/helpers/tag-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tagColor = tagColor;
  _exports.default = void 0;

  function tagColor(params
  /*, hash*/
  ) {
    var type = params[0];

    if (params[0] === 'kind') {
      type = params[1];
    }

    switch (type.toLowerCase()) {
      case 'invoice':
        return 'green';

      case 'estimate':
        return 'green';

      case 'contact':
        return 'gold';

      case 'project':
        return 'blue';

      case 'task':
        return 'blue';

      case 'person':
        return 'gold';

      case 'user':
        return 'purple';

      case 'label':
        return 'gray';

      case 'bill':
        return 'red';
    }

    return 'gray';
  }

  var _default = Ember.Helper.helper(tagColor);

  _exports.default = _default;
});