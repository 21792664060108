define("zipbooks/templates/main/partials/-transaction-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lbENFxjW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"showAdvancedView\"]],[24,[\"advancedView\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"blue font-size-12px mr-1 font-weight-normal\"],[12,\"data-test-id\",\"toggle-advanced\"],[3,\"action\",[[23,0,[]],\"toggleView\"]],[8],[1,[28,\"if\",[[24,[\"advancedView\"]],\"Standard View\",\"Advanced View\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/main/partials/-transaction-view.hbs"
    }
  });

  _exports.default = _default;
});