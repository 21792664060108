define("zipbooks/components/suggest/contact-select", ["exports", "zipbooks/components/auto-suggest"], function (_exports, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    overlay: Ember.inject.service(),
    placeholder: 'Contact',
    layoutName: 'components/auto-suggest',
    throttle: 1000,
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      return this.store.query('contact', {
        filter: {
          type: 'all',
          name: "*".concat(search || '', "*"),
          archived: false
        },
        sort: 'name'
      });
    },
    labelForResult: function labelForResult(result) {
      return result.get && result.get('formattedName');
    },
    handleAddNew: function handleAddNew(label) {
      var _this = this;

      var contact = this.store.createRecord('contact', {
        name: label
      });
      this.overlay.showDrawer('drawers/contact-form', contact, {
        saved: function saved(model) {
          _this.trySelect(model);
        }
      });
    }
  });

  _exports.default = _default;
});