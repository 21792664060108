define("zipbooks/controllers/main/reports/balance-sheet", ["exports", "zipbooks/mixins/tag-filterable-controller", "zipbooks/mixins/reports/balance-sheet-controller", "zipbooks/mixins/reports/scrollable-report", "zipbooks/mixins/reports/date-error"], function (_exports, _tagFilterableController, _balanceSheetController, _scrollableReport, _dateError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tagFilterableController.default, _dateError.default, _balanceSheetController.default, _scrollableReport.default, {
    overlay: Ember.inject.service(),
    client: Ember.inject.service(),
    actions: {
      sendLive: function sendLive() {
        var params = {
          start_date: this.start_date,
          end_date: this.end_date,
          group_by: this.group_by,
          include: this.include,
          basis: this.basis
        };
        this.overlay.showPopout('messaging/accountant-messenger', {
          suggestedMessage: "".concat(window.location.origin, "/reports/balance-sheet?").concat($.param(params))
        });
      },
      sendPdf: function sendPdf() {
        var _this = this;

        var params = {
          start_date: this.start_date,
          end_date: this.end_date,
          group_by: this.group_by,
          include: this.include,
          basis: this.basis,
          include_balance_sheet: true,
          tmz_offset: moment().utcOffset()
        };
        this.overlay.showPopout('messaging/accountant-messenger', {
          suggestedMessage: '[Generating PDF...]'
        });
        this.client.POST('reports', 'financial-package', params).then(function (resp) {
          _this.overlay.showPopout('messaging/accountant-messenger', {
            suggestedMessage: "".concat(window.location.origin, "/v2/cloud-files/").concat(resp['token'], "/download")
          });
        });
      }
    }
  });

  _exports.default = _default;
});