define("zipbooks/components/reconciliations/header-box", ["exports", "zipbooks/utils/to-decimal", "zipbooks/utils/currencies", "zipbooks/utils/format-money"], function (_exports, _toDecimal, _currencies, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    init: function init() {
      this.set('chartAccounts', this.store.peekAll('chartAccount').filter(function (ca) {
        return ca.get('isReconcilable');
      }));

      this._super.apply(this, arguments);
    },
    bankStartingBalance: 0,
    _observeBankStartingBalance: Ember.on('init', Ember.observer('meta.chart_account_id', 'meta.start_date', function () {
      this.set('bankStartingBalance', this.get('meta.starting_balance'));
    })),
    currencySymbol: Ember.computed('reconciliation.chartAccount.bankAccount.currencyCode', function () {
      return Ember.String.htmlSafe(_currencies.default[this.get('reconciliation.chartAccount.bankAccount.currencyCode')]);
    }),
    startingBalance: Ember.computed('meta.starting_balance', function () {
      return (0, _toDecimal.default)(this.get('meta.starting_balance'));
    }),
    endingBalance: Ember.computed('meta.ending_balance', function () {
      return (0, _toDecimal.default)(this.get('meta.ending_balance'));
    }),
    zipbooksChange: Ember.computed('startingBalance', 'endingBalance', function () {
      return this.startingBalance.minus(this.endingBalance).abs();
    }),
    zipbooksChangeSymbol: Ember.computed('startingBalance', 'endingBalance', function () {
      return this.endingBalance.comparedTo(this.startingBalance) === 1 ? '+' : '-';
    }),
    zipbooksChangeColor: Ember.computed('meta.starting_balance', 'meta.ending_balance', function () {
      if (this.endingBalance.comparedTo(this.startingBalance) === 1) {
        return this.get('meta.bank_type') === 'debit' ? 'green' : 'red';
      } else {
        return this.get('meta.bank_type') === 'debit' ? 'red' : 'green';
      }
    }),
    bankChange: Ember.computed('bankStartingBalance', 'reconciliation.endBalance', function () {
      return (0, _toDecimal.default)(this.get('reconciliation.endBalance')).minus((0, _toDecimal.default)(this.bankStartingBalance)).abs();
    }),
    bankChangeSymbol: Ember.computed('bankStartingBalance', 'reconciliation.endBalance', function () {
      return (0, _toDecimal.default)(this.get('reconciliation.endBalance')).comparedTo((0, _toDecimal.default)(this.bankStartingBalance)) === 1 ? '+' : '-';
    }),
    bankChangeColor: Ember.computed('bankStartingBalance', 'reconciliation.endBalance', function () {
      if ((0, _toDecimal.default)(this.get('reconciliation.endBalance')).comparedTo((0, _toDecimal.default)(this.bankStartingBalance)) === 1) {
        return this.get('meta.bank_type') === 'debit' ? 'green' : 'red';
      } else {
        return this.get('meta.bank_type') === 'debit' ? 'red' : 'green';
      }
    }),
    compareAmount: Ember.computed('endingBalance', 'reconciliation.endBalance', function () {
      return (0, _toDecimal.default)(this.get('reconciliation.endBalance')).minus(this.endingBalance).abs();
    }),
    compareWord: Ember.computed('endingBalance', 'reconciliation.endBalance', function () {
      return (0, _toDecimal.default)(this.get('reconciliation.endBalance')).comparedTo(this.endingBalance) === 1 ? 'less' : 'more';
    }),
    isBalanceEqual: Ember.computed('endingBalance', 'reconciliation.endBalance', function () {
      return (0, _toDecimal.default)(this.get('reconciliation.endBalance')).equals(this.endingBalance);
    }),
    canReconcile: Ember.computed('meta.end_date', function () {
      return moment(this.get('meta.end_date')).diff(moment(), 'days') < 0;
    }),
    canEditStartingBalance: Ember.computed('meta.start_date', 'session.account.settings.lockDate', function () {
      if (this.session.get('account.settings.lockDate')) {
        return moment(this.get('meta.start_date')).subtract(1, 'day') > moment(this.session.get('account.settings.lockDate'));
      } else {
        return true;
      }
    }),
    actions: {
      handleKeyPressOnStartingBalance: function handleKeyPressOnStartingBalance(event) {
        if (event.which != 8 && event.which != 46 && event.which != 45 && isNaN(String.fromCharCode(event.which))) {
          // stop character from entering input
          event.preventDefault();
        }

        if (event.which === 13 && !this.updatingStartingBalance) {
          this.set('updatingStartingBalance', true);
          this.updateStartingBalance();
          event.target.blur();
          this.set('updatingStartingBalance', false);
        }
      },
      updateStartingBalance: function updateStartingBalance() {
        if (!this.updatingStartingBalance) {
          this.set('updatingStartingBalance', true);
          this.updateStartingBalance();
          this.set('updatingStartingBalance', false);
        }
      },
      updateEndBalance: function updateEndBalance() {
        this.get('reconciliation').save();
      },
      confirmReconciliation: function confirmReconciliation() {
        this.set('overrideReconciled', true);
        var reconciliation = this.get('reconciliation');
        reconciliation.set('isReconciled', true);
        reconciliation.save();
        this.overlay.showBanner('success', 'Your bank is reconciled.');
        this.set('overrideReconciled', false);
      }
    },
    updateStartingBalance: function updateStartingBalance() {
      var _this = this;

      if ((0, _toDecimal.default)(this.bankStartingBalance).equals(this.startingBalance)) {
        return;
      }

      var date = moment(this.get('meta.start_date')).subtract(1, 'day');
      var amount = (0, _toDecimal.default)(this.bankStartingBalance).minus(this.startingBalance).abs();
      var direction = (0, _toDecimal.default)(this.bankStartingBalance).comparedTo(this.startingBalance) === 1 ? this.get('meta.bank_type') === 'debit' ? 'deposit' : 'withdrawal' : this.get('meta.bank_type') === 'debit' ? 'withdrawal' : 'deposit';
      this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
        title: Ember.String.htmlSafe('To change the opening balance, an adjusting transaction will be created on ' + date.format('M/D/YY') + '. The adjustment will be a ' + direction + ' for ' + (0, _formatMoney.default)(amount) + '.'),
        confirmButton: {
          title: 'Change',
          class: 'btn-red text-small-bd'
        },
        cancelButton: {
          title: 'Cancel',
          class: 'btn-white text-small-bd'
        }
      }, {
        confirm: function confirm() {
          _this.store.createRecord('journalEntry', {
            name: 'Balancing Entry',
            note: 'Created by ZipBooks to balanace reconciliation',
            journalEntryLines: [_this.store.createRecord('journalEntryLine', {
              date: date.format('YYYY-MM-DD'),
              kind: direction === 'deposit' ? 'debit' : 'credit',
              amount: amount,
              chartAccount: _this.get('reconciliation.chartAccount')
            }), _this.store.createRecord('journalEntryLine', {
              date: date.format('YYYY-MM-DD'),
              kind: direction === 'deposit' ? 'credit' : 'debit',
              amount: amount,
              chartAccount: _this.store.chartAccounts().find(function (ca) {
                return ca.get('systemCode') === '3100';
              }) // Invested capital

            })]
          }).save().then(function () {
            _this.notificationCenter.notify('reload-reconciliation-meta');

            _this.overlay.showBanner('success', 'Successfully created a balancing entry on ' + date.format('M/D/YY') + '.');
          });
        },
        cancel: function cancel() {
          _this.set('bankStartingBalance', _this.startingBalance);
        },
        close: function close() {}
      });
    }
  });

  _exports.default = _default;
});