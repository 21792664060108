define("zipbooks/utils/transaction-sheet/cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    setEditing: function setEditing(editing) {
      var _this = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (this.column.key === 'account' && this.grayOutAccountColumn) {
        return;
      }

      if (editing) {
        this.set('hadValueWhenEditingStarted', this.hasValue());
        this.set('isEditing', true);

        if (this.component) {
          if (opts.value && this.column.key === 'date') {
            Ember.run.scheduleOnce('afterRender', this, function () {
              var date = _this.row.dateTransform || moment().format('MM/DD/YY');
              var match = /\/(\d{2})\//.exec(date);

              if (match && match.index && match.length === 2) {
                var spliced = _this.replaceRange(date, match.index + 1, match.index + 3, opts.value);

                _this.set('row.dateTransform', spliced);

                Ember.run.later(_this, function () {
                  _this.component.$('input, textarea')[0].setSelectionRange(match.index + 2, match.index + 2);

                  _this.component.$('input, textarea').focus();
                }, 20);
              }
            });
          } else {
            if (opts.value) {
              if (!this.column.validate || this.column.validate(opts.value)) {
                this.component.set('initialSearchText', opts.value);

                if (!['account', 'type', 'category', 'tags'].includes(this.column.key)) {
                  this.setValue(opts.value);
                }
              }
            }

            Ember.run.scheduleOnce('afterRender', this, function () {
              _this.component.$('input, textarea').focus();
            });
          }
        }
      } else {
        if (opts.revert && this.revertFunction) {
          this.revertFunction();
        } else if (this.isEditing || opts.save) {
          this.row.transaction.saveEntry(this).catch(function (_e) {});
        }

        this.set('isEditing', false);
      }
    },
    clear: function clear() {
      var keyPath = this.column.path;
      this.set('revertFunction', null);
      return this.set(keyPath, null);
    },
    value: function value() {
      var keyPath = this.column.path;
      return this.get(keyPath);
    },
    setValue: function setValue(value) {
      var keyPath = this.column.path;

      if (!this.column.validate || this.column.validate(value)) {
        if (this.column.transform) {
          // for example, amount is sometimes a string, so we coerce it into an number
          value = this.column.transform(value);
        }

        return this.set(keyPath, value);
      }
    },
    hasValue: function hasValue() {
      if (this.column.key === 'account' && this.grayOutAccountColumn) {
        return true;
      }

      var keyPath = this.column.path;

      if (this.column.exists) {
        keyPath = [keyPath, this.column.exists].join('.');
      }

      return !!this.get(keyPath);
    },
    replaceRange: function replaceRange(s, start, end, substitute) {
      return s.substring(0, start) + substitute + s.substring(end);
    },
    updateRevertFunction: function updateRevertFunction() {
      var _this2 = this;

      var valuePath = this.column.path;
      var initialValue = this.get(valuePath);
      this.set('revertFunction', function () {
        _this2.set(valuePath, initialValue);
      });
    }
  });

  _exports.default = _default;
});