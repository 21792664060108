define("zipbooks/controllers/main/bank-accounts", ["exports", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/paid-modal-handler", "zipbooks/config/environment", "zipbooks/utils/pluralize", "zipbooks/utils/drawer-link-for", "zipbooks/utils/log"], function (_exports, _autoUpdatingRoute, _paidModalHandler, _environment, _pluralize, _drawerLinkFor, _log) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_autoUpdatingRoute.default, _paidModalHandler.default, {
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      requireCreditCard: function requireCreditCard() {
        var _this = this;

        this.overlay.showDialog('overlays/dialogs/bank-credit-card-modal', null, {
          customHeader: true
        }, {
          upgraded: function upgraded() {
            return _this.connect();
          }
        });
      },
      toggleAccountConnected: function toggleAccountConnected(bankAccount) {
        var _this2 = this;

        // value changes before this is called so we check for new value
        var path = bankAccount.get('connected') ? 'reconnect' : 'disconnect';
        this.client.send('PATCH', 'bank-accounts', "".concat(bankAccount.id, "/").concat(path)).then(function () {
          return _this2.model.update();
        });
      },
      deleteMember: function deleteMember(member) {
        var _this3 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Delete Bank', {
          title: 'Are you sure you want to delete this connection?',
          confirmButton: {
            title: 'Delete',
            class: 'btn-red'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            member.destroyRecord().then(function () {
              member.bankAccounts.forEach(function (ba) {
                return ba.unloadRecord();
              });

              _this3.model.update();
            }, function (resp) {
              return _this3.overlay.showBanner('error', resp.errors.mapBy('detail').join('<br>'));
            });
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      },
      deleteMemberWithTransactions: function deleteMemberWithTransactions(member) {
        var _this4 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Delete Bank', {
          title: 'Are you sure you want to delete this connection and all associated transactions?',
          confirmButton: {
            title: 'Delete',
            class: 'btn-red'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            Ember.RSVP.all(member.bankAccounts.map(function (bankAccount) {
              return _this4.client.send('DELETE', 'bank-accounts', "".concat(bankAccount.id, "?delete_transactions=true"));
            })).then(function () {
              member.destroyRecord().then(function () {
                member.bankAccounts.forEach(function (ba) {
                  return ba.unloadRecord();
                });

                _this4.model.update();
              }, function (resp) {
                return _this4.overlay.showBanner('error', resp.errors.mapBy('detail').join('<br>'));
              });
            });
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      },
      deleteAccount: function deleteAccount(bankAccount) {
        var _this5 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Delete Bank', {
          title: 'Are you sure you want to delete this bank account?',
          confirmButton: {
            title: 'Delete',
            class: 'btn-red'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            bankAccount.destroyRecord().then(function () {}, function (resp) {
              bankAccount.rollbackAttributes();

              _this5.overlay.showBanner('error', resp.errors.mapBy('detail').join('<br>'));
            });
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      },
      deleteAccountWithTransactions: function deleteAccountWithTransactions(bankAccount) {
        var _this6 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Delete Bank', {
          title: 'Are you sure you want to delete this bank account and all associated transacions?',
          confirmButton: {
            title: 'Delete',
            class: 'btn-red'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            _this6.client.send('DELETE', 'bank-accounts', "".concat(bankAccount.id, "?delete_transactions=true")).then(function () {
              return _this6.send('refreshRoute');
            }, function (resp) {
              return _this6.overlay.showBanner('error', resp.errors.mapBy('detail').join('<br>'));
            });
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      },
      connect: function connect() {
        this.connect();
      },
      setup: function setup(member) {
        this.overlay.showDrawer('drawers/setup-bank-member-form', member);
      },
      fix: function fix(model) {
        var _this7 = this;

        var provider = model.get('provider');

        if (provider === 'yodlee') {
          this.overlay.showComponent('yodlee-widget', {
            externalId: model.get('externalId')
          }, {
            refresh: function refresh() {
              return _this7.send('refreshRoute');
            }
          });
        } else if (provider === 'atrium') {
          var params = (0, _drawerLinkFor.default)([model]);
          this.transitionToRoute({
            queryParams: {
              drawer: params
            }
          });
        } else if (provider === 'plaid') {
          this.connectPlaid(model);
        }
      }
    },
    institutionCountText: Ember.computed('memberCount', 'totalAccountsConnected', function () {
      var firstPart = (0, _pluralize.default)('<strong>%p</strong> Institution(/s)', this.memberCount);
      var secondPart = (0, _pluralize.default)('with <strong>%p</strong> account(/s) connected', this.totalAccountsConnected);
      return Ember.String.htmlSafe("".concat(firstPart, " ").concat(secondPart));
    }),
    memberCount: Ember.computed('model.length', function () {
      return this.model.length;
    }),
    totalAccountsConnected: Ember.computed('model.@each.bankAccounts', function () {
      return this.model.reduce(function (a, v) {
        return a + v.bankAccounts.filter(function (account) {
          return account.get('connected');
        }).length;
      }, 0);
    }),
    connect: function connect() {
      var _this8 = this;

      if (this.get('model.meta.limit_reached')) {
        this.overlay.showBanner('error', 'You can only connect ' + (this.account().get('settings.bankLimitPerDay') || 4) + ' bank accounts per day.');
        return;
      }

      if (this.get('session.account.settings.bankAccountProvider') === 'yodlee') {
        this.overlay.showComponent('yodlee-widget', '', {
          refresh: function refresh() {
            return _this8.send('refreshRoute');
          }
        });
      } else {
        var params = (0, _drawerLinkFor.default)(['bank-member']);
        this.transitionToRoute({
          queryParams: {
            drawer: params
          }
        });
      }
    },
    connectPlaid: function connectPlaid(existing) {
      var _this9 = this;

      (0, _log.default)('info', 'connecting plaid');
      this.showPlaidWidget(existing).then(function () {
        (0, _log.default)('info', 'adding public token…');

        _this9.overlay.showBanner('success', 'Great! Give us a few seconds to load your bank account.');

        _this9.model.update().then(function () {
          (0, _log.default)('info', 'done, showing import notification');

          _this9.overlay.showBanner('success', 'Your transactions are importing. They may take a few minutes to show up.');
        });
      }, function (err) {
        if (err != null) {
          _this9.overlay.showBanner('error', "Oops!  Something went wrong and we couldn't connect your account.");
        }
      });
    },
    showPlaidWidget: function showPlaidWidget(bankAccount) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var loaded = false;

        if (Ember.testing) {
          Ember.Test.registerWaiter(function () {
            return loaded;
          });
        } // the widget throws an error cause it can't find the script so we disable temporarily


        var saveOnerror = window.onerror;

        window.onerror = function () {
          return false;
        };

        var options = {
          env: 'production',
          //ENV.environment === 'production' ? 'production' : 'tartan',
          clientName: 'Zipbooks',
          key: _environment.default.PLAID_PUBLIC_KEY,
          product: 'connect',
          onLoad: function onLoad() {
            loaded = true;
            window.onerror = saveOnerror; // The Link module finished loading.
          },
          onSuccess: function onSuccess(_public_token, metadata) {
            // Send the public_token to your app server here.
            // The metadata object contains info about the institution the
            // user selected and the account ID, if selectAccount is enabled.
            (0, _log.default)('info', 'successfuly finished with widget. control back to us'); // Remove the widget

            resolve(metadata);
          },
          onExit: function onExit(err, metadata) {
            // The user exited the Link flow.
            if (err != null) {} // The user encountered a Plaid API error prior to exiting.
            // metadata contains information about the institution
            // that the user selected and the most recent API request IDs.
            // Storing this information can be helpful for support.


            reject(err, metadata);
          }
        };

        if (bankAccount) {
          (0, _log.default)('info', 'showing plaid widget for existing user'); // options.currentInstitutionCode = bankAccount.institution_code

          options.token = bankAccount.get('plaidPublicToken');
        }

        $.getScript('https://cdn.plaid.com/link/v2/stable/link-initialize.js', function ()
        /*data, textStatus, jqxhr*/
        {
          var widget = Plaid.create(options);
          widget.open();
        });
      });
    },
    account: function account() {
      return this.get('session.account').get('content');
    },
    canAddBank: Ember.computed('model.length', 'session.privileges', function () {
      return this.get('model.length') < 1 || this.session.hasPrivilege('banks-expanded');
    })
  });

  _exports.default = _default;
});