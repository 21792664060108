define("zipbooks/templates/components/import/column-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fNNzrteY",
    "block": "{\"symbols\":[\"inputHeader\"],\"statements\":[[7,\"p\",true],[8],[0,\"Please match the column headers to the corresponding ZipBooks fields:\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row mb-1\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-4 font-size-13px gray\"],[8],[0,\"CSV column header\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-1\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col font-size-13px gray\"],[8],[0,\"ZipBooks field\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"inputHeaders\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"import/column-matcher-row\",null,[[\"inputHeader\",\"outputHeaders\",\"mapping\"],[[23,1,[]],[24,[\"outputHeaders\"]],[24,[\"mapping\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/import/column-matcher.hbs"
    }
  });

  _exports.default = _default;
});