define("zipbooks/helpers/status-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusColor = statusColor;
  _exports.default = void 0;

  function statusColor(params
  /*, hash*/
  ) {
    switch (params[0]) {
      case 'Open':
      case 'Sent':
      case 'Viewed':
        return 'blue';

      case 'Partial':
        return 'gold';

      case 'Payment Failed':
      case 'Refunded':
      case 'Overdue':
        return 'red';

      case 'Paid':
      case 'Closed':
      case 'Accepted':
        return 'green';

      case 'Draft':
        return 'gray';
    }
  }

  var _default = Ember.Helper.helper(statusColor);

  _exports.default = _default;
});