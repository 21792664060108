define("zipbooks/utils/refresh-chart-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = refreshChartAccounts;

  function refreshChartAccounts(store, entries) {
    // refresh the chart accounts of these je lines to get the correct balance
    var chartAccountIds = entries.reduce(function (a, v) {
      return a.concat(v.get('journalEntryLines').map(function (line) {
        return line.get('chartAccount.id');
      }));
    }, []).uniq().compact();

    if (chartAccountIds.length > 0) {
      store.query('chartAccount', {
        filter: {
          ids: chartAccountIds.join(',')
        }
      });
    }
  }
});