define("zipbooks/components/drawers/location-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    classNames: ['drawer-x-large'],
    actions: {
      selectCountry: function selectCountry(country) {
        this.set('model.country', country.id);
      }
    }
  });

  _exports.default = _default;
});