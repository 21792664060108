define("zipbooks/helpers/truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncate = truncate;
  _exports.default = void 0;

  function truncate(params
  /*, hash*/
  ) {
    var subject = params[0];
    var length = params[1];
    var ellipsis = params[2];

    if (subject && Ember.isArray(subject)) {
      var truncated = subject.slice(0, 2);

      if (ellipsis === true && truncated.length < subject.length) {
        truncated.push("+ ".concat(subject.length - truncated.length));
      }

      return truncated.join(', ');
    } else if (subject) {
      var truncatedString = subject.substring(0, length);

      if (ellipsis === true && truncatedString.length < subject.length) {
        truncatedString += '...';
      }

      return truncatedString;
    }
  }

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});