define("zipbooks/mixins/time-tracking", ["exports", "zipbooks/utils/overridable-computed"], function (_exports, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    sockets: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    project: null,
    contact: null,
    user: Ember.computed.alias('session.currentUser'),
    getTodaysTime: function getTodaysTime() {
      var _this = this;

      this.store.query('time-entry', {
        filter: {
          start_date: moment().format('YYYY-MM-DD'),
          user_id: this.user.get('id'),
          is_pending: false
        }
      }).then(function (resp) {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('todaysTime', resp);
        }
      });
    },
    totalDuration: (0, _overridableComputed.default)('totalDuration', 'todaysTime.@each.duration', function () {
      if (this.todaysTime) {
        return this.todaysTime.reduce(function (acc, time) {
          return time.duration + acc;
        }, 0);
      }
    }),
    runningDuration: Ember.computed('totalDuration', 'ticker.seconds', function () {
      return this.totalDuration + this.ticker.seconds;
    }),
    actions: {
      selectProject: function selectProject(project) {
        this.set('errors', null);
        this.set('project', project);

        if (project && project.get('contact.id')) {
          this.set('contact', project.get('contact'));
        }

        Ember.run.debounce(this, 'updateModel', 1000);
      },
      selectContact: function selectContact(contact) {
        this.set('errors', null);
        this.set('contact', contact);

        if (this.project && this.contact && !this.get('project.allowMultipleContacts') && this.get('project.contact.id') !== this.contact.id) {
          this.set('project', null);
        }

        Ember.run.debounce(this, 'updateModel', 1000);
      },
      submitAndStartNew: function submitAndStartNew() {
        var _this2 = this;

        this.set('startingNew', true);
        var promise = this.submit();

        if (promise) {
          promise.then(function () {
            return _this2.start();
          }).then(function () {
            return _this2.set('startingNew', false);
          });
        }
      },
      start: function start() {
        this.start();
      },
      submit: function submit() {
        this.submit();
      },
      noteEdited: function noteEdited() {
        Ember.run.debounce(this, 'updateModel', 1000);
      },
      toggleEditor: function toggleEditor(timeEntry) {
        this.set('showEditorForTimeEntryId', timeEntry && timeEntry.id);
      }
    },
    setModelValues: function setModelValues(model) {
      this.set('model', model);
      this.set('project', model.get('project'));
      this.set('contact', model.get('contact'));
      this.set('note', model.get('note'));
    },
    updateModel: function updateModel(func) {
      var _this3 = this;

      // user is complaining previous entries are being updated after
      // this is to ensure a time entry cannot be updated unless the timer is running
      // we use intervalHandle because its possible isRunning is not updated by this point
      if (this.model && this.model.id && !this.get('ticker.intervalHandle')) {
        return;
      }

      this.set('errors', null);
      var model = this.model;

      if (model && !model.get('isSaving')) {
        model.set('project', this.project);
        model.set('contact', this.contact);
        model.set('note', this.note);
        return model.save().then(function (saved) {
          _this3.notificationCenter.notify('time-entry.save');

          if (func) {
            func(saved);
          }
        }, function (resp) {
          model.set('duration', 0);
          var errors = resp.errors.reduce(function (acc, e) {
            if (e.source) {
              var key = e.source.pointer.substr(e.source.pointer.lastIndexOf('/') + 1);
              acc[key] = [{
                message: e.detail
              }];
            }

            return acc;
          }, {});
          var inProgressError = resp.errors.find(function (e) {
            return e.title == 'Already in progress';
          });

          if (inProgressError) {
            _this3.overlay.showBanner('error', inProgressError.detail);
          }

          _this3.set('errors', errors);
        });
      }
    },
    start: function start() {
      var _this4 = this;

      if (this.get('ticker.isRunning')) {
        return;
      }

      var model = this.store.createRecord('time-entry', {
        date: moment().format('YYYY-MM-DD'),
        duration: 0,
        startTime: moment().utc(),
        user: this.user
      });
      this.set('model', model);
      return this.updateModel(function (saved) {
        _this4.ticker.start();

        _this4.afterStart(saved);
      });
    },
    submit: function submit() {
      var _this5 = this;

      if (!this.get('ticker.isRunning')) {
        return;
      }

      var model = this.model;
      var startTime = moment(model.get('startTime'));
      var endTime = moment().utc();
      model.set('duration', endTime.diff(startTime, 'seconds') || 1);
      model.set('date', moment().format('YYYY-MM-DD'));
      return this.updateModel(function () {
        _this5.stop();
      });
    },
    stop: function stop() {
      this.ticker.stop();
      this.set('model', null);
    },
    checkForUnfinished: function checkForUnfinished() {
      var _this6 = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.client.GET('time-entries', 'unfinished', null, null, params).then(function (resp) {
        if (resp && !_this6.isDestroyed && !_this6.isDestroying) {
          _this6.store.pushPayload(resp);

          var model = _this6.store.peekRecord('time-entry', resp.data.id);

          _this6.setModelValues(model);

          _this6.ticker.start(moment(model.get('startTime')));
        }
      });
    },
    setupWebSocket: function setupWebSocket(token) {
      var _this7 = this;

      if (!Ember.testing) {
        var socket = this.sockets.socketForToken(token);
        var channel = socket.channel("user:".concat(this.user.get('id')), {});
        channel.join(); // .receive('ok', resp => { console.log('Joined successfully', resp) })
        // .receive('error', resp => { console.log('Unable to join', resp) })

        channel.on('change', function (payload) {
          var model = _this7.store.peekRecord('time-entry', payload.data.id);

          if (_this7.get('ticker.isRunning') && _this7.model.id === payload.data.id) {
            _this7.store.pushPayload(payload);

            if (model.get('duration') > 0) {
              _this7.stop();
            } else {
              _this7.setModelValues(model);
            }
          } else if (!_this7.get('ticker.isRunning') && payload.data.attributes.duration === 0) {
            _this7.checkForUnfinished({
              token: token
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});