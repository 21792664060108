define("zipbooks/utils/promotions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInUSA = isInUSA;
  _exports.isInCanada = isInCanada;
  _exports.showSquarePromotion = showSquarePromotion;

  function isInUSA(country) {
    return ['United States', 'US', 'USA'].includes(country);
  }

  function isInCanada(country) {
    return ['CA', 'Canada'].includes(country);
  }

  function showSquarePromotion(location, integrations, user, settings, isStealth) {
    var inUSAOrCanada = isInUSA(location.country) || isInCanada(location.country);
    var squareAlreadyConnected = integrations.data.mapBy('type').includes('square-integration');
    var hasManageAccountPermission = user.get("permissionsMap.account") === 9;
    var hasAlreadyResponded = settings.acceptedSquarePromotion !== null;
    var accountOldEnough = moment(user.createdAt).isBefore(moment().subtract(3, 'days'));
    var onAnAccountingTeam = user.isTeamMember;
    return inUSAOrCanada && !squareAlreadyConnected && hasManageAccountPermission && !hasAlreadyResponded && accountOldEnough && !onAnAccountingTeam && !isStealth;
  }
});