define("zipbooks/helpers/slugify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.slugify = slugify;
  _exports.default = void 0;

  function slugify(params
  /*, hash*/
  ) {
    var str = params[0] || '';
    var slugstr = str.replace(/[^0-9a-z ]/gi, '').dasherize();
    return slugstr;
  }

  var _default = Ember.Helper.helper(slugify);

  _exports.default = _default;
});