define("zipbooks/utils/adjust-nav-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = adjustNavPosition;

  function adjustNavPosition() {
    var screenTopToSidebarTop = 94; // originally used $('.sidebar-nav').offset().top, but that can change as you scroll; we need a static distance here to know if sidebar can fit

    var windowHeight = $(window).height();
    var sidebarNavHeight = $('.sidebar-nav').height() - 14; // remove approximate margin at bottom

    var screenTopToSidebarBottom = sidebarNavHeight + screenTopToSidebarTop;

    if (screenTopToSidebarBottom > windowHeight) {
      $('.sidebar-nav').addClass('position-absolute');
    } else {
      $('.sidebar-nav').removeClass('position-absolute');
    }
  }
});