define("zipbooks/controllers/main/settings/delete", ["exports", "zipbooks/utils/redirect"], function (_exports, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      deleteAccount: function deleteAccount() {
        var _this = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: Ember.String.htmlSafe("Are you <b>absolutely sure</b> you want to delete this account? This action is <b>irreversible</b> and you will <b>not be able to recover any account data</b>."),
          confirmButton: {
            title: 'DELETE NOW',
            class: 'btn-red'
          },
          cancelButton: {
            title: 'No, cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            return _this.client.send('DELETE', 'accounts', _this.get('session.account.id')).then(function () {
              return (
                /*resp*/
                (0, _redirect.default)('/login')
              );
            }, function (response) {
              return _this.overlay.showBanner('error', response.responseJSON.message);
            });
          },
          cancel: function cancel() {}
        });
      },
      resetAccount: function resetAccount() {
        var _this2 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: Ember.String.htmlSafe("Are you <b>absolutely sure</b> you want to reset this account? This action is <b>irreversible</b> and you will <b>not be able to recover any account data</b>."),
          confirmButton: {
            title: 'RESET NOW',
            class: 'btn-red'
          },
          cancelButton: {
            title: 'No, cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            return _this2.client.send('POST', 'accounts', "".concat(_this2.get('session.account.id'), "/reset")).then(function () {
              return (
                /*resp*/
                (0, _redirect.default)('/')
              );
            }, function (response) {
              return _this2.overlay.showBanner('error', response.responseJSON.message);
            });
          },
          cancel: function cancel() {}
        });
      }
    }
  });

  _exports.default = _default;
});