define("zipbooks/helpers/not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.not = not;
  _exports.default = void 0;

  function not(params
  /*, hash*/
  ) {
    if (params.length === 2) {
      console.info('use of (not 1 2) is deprecated. Please remove it.');
      return params[0] !== params[1];
    } else {
      return !params[0];
    }
  }

  var _default = Ember.Helper.helper(not);

  _exports.default = _default;
});