define("zipbooks/initializers/chartjs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Chart.elements.Rectangle.prototype.draw = function () {
      var ctx = this._chart.ctx;
      var vm = this._view;
      var left, right, top, bottom, signX, signY, borderSkipped;
      var borderWidth = vm.borderWidth;

      if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
      } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
      } // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line


      if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2; // Adjust borderWidth when bar top position is near vm.base(zero).

        var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0); // not become a vertical line?

        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        } // not become a horizontal line?


        if (borderTop !== borderBottom) {
          left = borderLeft;
          right = borderRight;
        }
      }

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth; // Corner points, from bottom-left to bottom-right clockwise
      // | 1 2 |
      // | 0 3 |

      var corners = [[left, bottom], [left, top], [right, top], [right, bottom]]; // Find first (starting) corner with fallback to 'bottom'

      var borders = ['bottom', 'left', 'top', 'right'];
      var startCorner = borders.indexOf(borderSkipped, 0);

      if (startCorner === -1) {
        startCorner = 0;
      }

      function cornerAt(index) {
        return corners[(startCorner + index) % 4];
      } // Draw rectangle from 'startCorner'


      var corner = cornerAt(0);
      ctx.moveTo(corner[0], corner[1]);

      for (var i = 1; i < 4; i++) {
        corner = cornerAt(i); // if a stacked bar chart, we only want to round the very top bar

        if (this._chart.options.scales.xAxes[0].stacked && this._datasetIndex !== this._chart.data.datasets.length - 2) {
          ctx.lineTo(corner[0], corner[1]);
        } else {
          var width = Math.abs(corners[2][0] - corners[1][0]);
          var height = Math.abs(corners[0][1] - corners[1][1]);
          var x = corners[1][0];
          var y = corners[1][1];
          var radius = 3;

          if (height > radius) {
            if (corners[0][1] - corners[1][1] > 0) {
              ctx.moveTo(x + radius, y);
              ctx.lineTo(x + width - radius, y);
              ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
              ctx.lineTo(x + width, y + height);
              ctx.lineTo(x, y + height);
              ctx.lineTo(x, y + radius);
              ctx.quadraticCurveTo(x, y, x + radius, y);
            } else {
              ctx.moveTo(x + radius, y);
              ctx.lineTo(x + width - radius, y);
              ctx.quadraticCurveTo(x + width, y, x + width, y - radius);
              ctx.lineTo(x + width, y - height);
              ctx.lineTo(x, y - height);
              ctx.lineTo(x, y - radius);
              ctx.quadraticCurveTo(x, y, x + radius, y);
            }
          } else {
            ctx.lineTo(corner[0], corner[1]);
          }
        }
      }

      ctx.fill();

      if (borderWidth) {
        ctx.stroke();
      }
    }; // this is documented here https://www.chartjs.org/docs/latest/configuration/tooltip.html#position-modes


    Chart.Tooltip.positioners.alwaystop = function (elements, eventPosition) {
      var pos = Chart.Tooltip.positioners.nearest(elements, eventPosition);
      return {
        x: pos.x,
        y: 10
      };
    };
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});