define("zipbooks/components/messaging/message-line", ["exports", "zipbooks/utils/tag-serialization"], function (_exports, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['message-line'],
    classNameBindings: ['isMessageOnRight:message-line-right:message-line-left'],
    isMessageOnRight: Ember.computed(function () {
      if (this.get('session.currentUser.isTeamMember')) {
        // accountant side is on the right
        if (this.get('message.fromUser.isTeamMember')) {
          return true;
        } else {
          return false;
        }
      } else {
        // accountant side is on the left
        if (!this.get('message.fromUser.isTeamMember')) {
          return true;
        } else {
          return false;
        }
      }
    }),
    body: Ember.computed('message.body', function () {
      return this.get('message.body') // remove any html tags that might be in the body (it is user input) because we are about to mark it as safe
      .replace(/(<([^>]+)>)/gi, '').replace(/(http(?:s|):\/\/[^\s]*)/, "<a href=\"$1\" class=\"blue\">$1</a>");
    }),
    actions: {
      viewTransactions: function viewTransactions() {
        var anyTag = this.get('message.label.tags.firstObject');
        var tags = [Ember.Object.create({
          id: anyTag.get('taggable.id'),
          name: anyTag.name,
          createdAt: anyTag.createdAt,
          taggableType: anyTag.taggableType
        })];

        if (this.permissions.can('accounting.read')) {
          this.router.transitionTo('main.transactions', {
            queryParams: {
              tags: _tagSerialization.default.serialize(tags),
              confirmed: ''
            }
          });
        }
      }
    },
    isFirstMessageOfDay: Ember.computed('dateChangeParams.messages.@each.createdAt', function () {
      var date = moment(this.message.createdAt).format('YYYY-MM-DD');
      var previousMessage = this.dateChangeParams.messages.objectAt(this.dateChangeParams.index - 1);

      if (previousMessage && moment(previousMessage.createdAt).format('YYYY-MM-DD') !== date) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});