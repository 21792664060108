define("zipbooks/utils/common-prefix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = commonPrefix;

  function commonPrefix(array) {
    if (!array || array.length === 0) {
      return '';
    }

    var sorted = array.concat().sort();
    var firstWord = sorted[0];
    var lastWord = sorted[sorted.length - 1];
    var length = firstWord.length;
    var i = 0;

    while (i < length && firstWord.charAt(i) === lastWord.charAt(i)) {
      i++;
    }

    return firstWord.substring(0, i);
  }
});