define("zipbooks/controllers/main/payroll", ["exports", "zipbooks/helpers/current-url"], function (_exports, _currentUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    overlay: Ember.inject.service(),
    client: Ember.inject.service(),
    actions: {
      launchOnboarding: function launchOnboarding() {
        this.overlay.showDialog('overlays/dialogs/gusto-onboarding', 'Gusto Setup', {
          customHeader: true,
          gustoModel: this.model
        });
      },
      provision: function provision() {
        var _this = this;

        this.client.send('POST', 'integrations', 'gusto/provision').then(function (resp) {
          if (resp.message) {
            _this.overlay.showBanner('error', resp.message);
          } else if (resp.error === 'already_connected') {
            window.location.href = "/v2/auth-link/gusto?callback-url=".concat((0, _currentUrl.currentUrl)([], {}));
          } else {
            window.location = resp.redirect_url;
          }
        });
      }
    }
  });

  _exports.default = _default;
});