define("zipbooks/templates/components/reports/report-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "llEVW9u+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row flex-items-xs-bottom\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-sm-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"report-title font-size-24px font-weight-bold\"],[10,\"data-test-id\",\"title\"],[8],[1,[22,\"report\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"report-title font-size-16px\"],[8],[1,[24,[\"session\",\"account\",\"name\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showDates\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray mt-2\"],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[22,\"titleStartDate\"],false],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"–\"],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[22,\"titleEndDate\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-sm-6 text-sm-right\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"logo-small\"],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/reports/report-header.hbs"
    }
  });

  _exports.default = _default;
});