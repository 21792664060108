define("zipbooks/components/add-transaction-menu", ["exports", "zipbooks/mixins/testable", "zipbooks/mixins/clicked-outside", "zipbooks/utils/drawer-link-for"], function (_exports, _testable, _clickedOutside, _drawerLinkFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _clickedOutside.default, {
    classNames: 'd-inline-block',
    session: Ember.inject.service(),
    actions: {
      openTransactionDrawer: function openTransactionDrawer(mode) {
        if (!this.action) {
          this.openMode(mode);
        } else {
          Ember.tryInvoke(this, 'action', [mode]);
        }

        this.set('visible', false);
      },
      showMenu: function showMenu() {
        if (this.session.get('currentUser.identity.isAdvanced')) {
          this.openMode('custom');
        }
      }
    },
    openMode: function openMode(mode) {
      var opts = {
        mode: mode
      };

      if (this.get('defaultAccount.id')) {
        opts.default_account_id = this.get('defaultAccount.id');
      }

      var params = (0, _drawerLinkFor.default)(['journal-entry', opts]);
      this.router.transitionTo({
        queryParams: {
          drawer: params
        }
      });
    },

    /*
     * ClickedOutsideMixin
     */
    outsideClickTriggerElement: function outsideClickTriggerElement() {
      return this.$('#transactions-add-button');
    },
    didClick: function didClick() {
      this.set('visible', true);
    },
    didClickOutside: function didClickOutside() {
      this.set('visible', false);
    }
  });

  _exports.default = _default;
});