define("zipbooks/components/permissions/permission-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    readOnly: false,
    level: Ember.computed('readOnly', function () {
      return this.readOnly ? 1 : 9;
    }),
    value: Ember.computed('map', 'map.accounting', {
      get: function get(_key) {
        return this.getWithDefault("map.".concat(this.scope), 0) === this.level;
      },
      set: function set(_key, value) {
        this.set("map.".concat(this.scope), value ? this.level : 0);
        return value;
      }
    })
  });

  _exports.default = _default;
});