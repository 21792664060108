define("zipbooks/components/checkable-contact-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSelected: Ember.computed('contact.id', 'recipients.@each', function () {
      var _this = this;

      return !!this.recipients.find(function (r) {
        return _this.key(r) === _this.key(_this.contact);
      });
    }),
    key: function key(r) {
      return "".concat(r.formattedName || r.name, "-").concat(r.email, "-").concat(r.phone);
    }
  });

  _exports.default = _default;
});