define("zipbooks/components/mini-ranking-chart", ["exports", "zipbooks/mixins/chart-custom-tooltip"], function (_exports, _chartCustomTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chartCustomTooltip.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var ctx = this.$('canvas') && this.$('canvas')[0];

      if (!ctx) {
        return;
      }

      var data = (this.data || []).sortBy('date');
      var simpleData = data.map(function (d) {
        return {
          date: d.date,
          position: d.position,
          icon: "".concat(d.engine, "-").concat(d.kind)
        };
      });
      var hasData = data.get('length') > 0; // if chart only has 1 data point, give it 2 so it has a straight line

      if (simpleData.length === 1) {
        simpleData = simpleData.concat(simpleData.map(function (d) {
          var clone = JSON.parse(JSON.stringify(d));
          clone.date = moment(d.date).add(1, 'day').format('YYYY-MM-DD');
          return clone;
        }));
      }

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: simpleData.map(function (d) {
            return d.date;
          }),
          datasets: [{
            data: simpleData.map(function (d) {
              return d.position;
            }),
            borderColor: '#F6A623',
            fill: false,
            borderWidth: 2
          }]
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: simpleData.length > 1 ? 0 : 1
            }
          },
          tooltips: $.extend(true, {
            mode: 'index',
            intersect: false,
            zipbooksHideTooltip: !hasData,
            callbacks: {
              label: function label(tooltipItem, _data) {
                return simpleData[tooltipItem.index];
              }
            }
          }, this.customTooltipOpts(this)),
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                display: false
              },
              gridLines: {
                display: false,
                drawBorder: false
              }
            }],
            yAxes: [{
              ticks: {
                display: false,
                reverse: true
              },
              gridLines: {
                display: false,
                drawBorder: false
              }
            }]
          }
        }
      });
    }
  });

  _exports.default = _default;
});