define("zipbooks/transforms/datetime", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return serialized;
      } // "2017-01-26 12:40:33"


      return moment.utc(serialized).toDate();
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return deserialized;
      }

      return moment.utc(deserialized).format(); // will use ISO8601 by default
    }
  });

  _exports.default = _default;
});