define("zipbooks/utils/try-invoke-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tryInvokePath;

  function tryInvokePath(obj, method, params) {
    var parts = method.split('.');

    if (parts.length > 1) {
      var objPath = parts.slice(0, -1).join('.');

      var _method = parts.slice(-1).join('.');

      return Ember.tryInvoke(Ember.get(obj, objPath), _method, params);
    } else {
      return Ember.tryInvoke(obj, method, params);
    }
  }
});