define("zipbooks/components/drawers/payment-method-form", ["exports", "zipbooks/components/drawers/base-drawer", "zipbooks/utils/sentencize"], function (_exports, _baseDrawer, _sentencize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    title: 'Add ACH bank account',
    type: 'individual',
    actions: {
      save: function save() {
        var _this = this;

        this.set('buttonState', 'saving');
        Stripe.setPublishableKey(this.get('session.account.integrations.stripe.publishable_key'));
        Stripe.bankAccount.createToken({
          country: 'US',
          currency: 'usd',
          routing_number: this.routingNumber,
          account_number: this.accountNumber,
          account_holder_name: this.name,
          account_holder_type: this.type
        }, function (_status, response) {
          if (response.error) {
            _this.overlay.showBanner('error', (0, _sentencize.default)(response.error.message));

            _this.set('buttonState', 'invalid');
          } else {
            _this.client.send('POST', 'payments', 'bank-accounts', {
              token: response.id,
              contact_id: _this.rest.contact_id
            }).then(function () {
              _this.set('buttonState', 'loaded');

              _this.notificationCenter.notify('payments.bank-account.save', null);

              _this.handleClose();
            }, function (resp) {
              _this.set('buttonState', 'invalid');

              _this.overlay.showBanner('error', resp.responseJSON.errors.mapBy('detail').join('<br>'));
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});