define("zipbooks/components/quick-stats/estimated-totals", ["exports", "zipbooks/mixins/testable", "ember-data"], function (_exports, _testable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    client: Ember.inject.service(),
    totals: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: this.client.cached('GET', 'quick-stats/estimated-totals')
      });
    })
  });

  _exports.default = _default;
});