define("zipbooks/components/quick-stats/recurring-profile-overview", ["exports", "zipbooks/mixins/testable", "ember-data"], function (_exports, _testable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    client: Ember.inject.service(),
    stats: Ember.computed('profiles.@each.isActive', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.client.send('GET', 'quick-stats/recurring-profile-overview')
      });
    })
  });

  _exports.default = _default;
});