define("zipbooks/components/reports/report-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    showDates: true,
    dateFormat: Ember.computed('startDate', 'endDate', function () {
      if (moment(this.get('startDate')).format('D') === '1' && moment(this.get('endDate')).format('D') === moment(this.get('endDate')).endOf('month').format('D')) {
        return 'MMM YYYY';
      } else {
        return 'MMM D, YYYY';
      }
    }),
    titleStartDate: Ember.computed('startDate', 'dateFormat', function () {
      return moment(this.get('startDate')).format(this.dateFormat);
    }),
    titleEndDate: Ember.computed('endDate', 'dateFormat', function () {
      return moment(this.get('endDate')).format(this.dateFormat);
    })
  });

  _exports.default = _default;
});