define("zipbooks/utils/format-called", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatCalled;

  function formatCalled(called, params) {
    if (params.indexOf('pluralize') === -1) {
      called = (0, _emberInflector.singularize)(called);
    }

    if (params.indexOf('capitalize') > -1) {
      called = called.capitalize();
    }

    return called;
  }
});