define("zipbooks/components/messaging/account-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    date: Ember.computed('account.attributes.timestamp', function () {
      var date = this.get('account.attributes.timestamp');

      if (date) {
        date = moment(date);

        if (moment().isSame(date, 'day')) {
          return date.format('h:mma');
        } else if (moment().isSame(date, 'year')) {
          return date.format('MMM D');
        } else {
          return date.format('MMM D YYYY');
        }
      }
    }),
    hasUnread: Ember.computed('current', function () {
      if (this.get('account.attributes.unread-messages-count') > 0 && !this.current) {
        return true;
      }

      if (this.get('account.attributes.comment-count') > 0) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});