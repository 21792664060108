define("zipbooks/components/time/ticker-time", ["exports", "zipbooks/utils/format-seconds-as-hours"], function (_exports, _formatSecondsAsHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'ticker-time',
    classNameBindings: ['white:ticker-time-white'],
    opacityClass: Ember.computed(function () {
      if (this.get('white')) {
        return 'opacity-80';
      } else {
        return 'opacity-50';
      }
    }),
    greaterOpacityClass: Ember.computed(function () {
      if (this.get('white')) {
        return 'opacity-60';
      } else {
        return 'opacity-30';
      }
    }),
    timerHours: Ember.computed('seconds', function () {
      return (0, _formatSecondsAsHours.default)(this.seconds, 'h');
    }),
    timerMinutes: Ember.computed('seconds', function () {
      return (0, _formatSecondsAsHours.default)(this.seconds, 'm');
    }),
    timerSeconds: Ember.computed('seconds', function () {
      return (0, _formatSecondsAsHours.default)(this.seconds, 's');
    }),
    timerHoursClasses: Ember.computed('timerHours', function () {
      if (this.timerHours == 0) {
        return 'font-weight-normal ' + this.get('opacityClass');
      } else {
        return 'font-weight-bold';
      }
    }),
    timerMinutesClasses: Ember.computed('timerMinutes', function () {
      if (this.timerHours > 0) {
        return 'font-weight-bold';
      } else if (this.timerHours == 0 && this.timerMinutes > 0) {
        return 'font-weight-bold';
      } else {
        return this.get('opacityClass');
      }
    }),
    timerSecondsClasses: Ember.computed('timerSeconds', function () {
      if (this.timerHours == 0 && this.timerMinutes == 0 && this.timerSeconds == 0) {
        return this.get('opacityClass') + ' font-weight-bold';
      } else if (this.timerHours == 0 && this.timerMinutes == 0) {
        return 'font-weight-bold';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});