define("zipbooks/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aZPv2arJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"error-page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"Oops! 404.\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Nothing to see here.\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-white mb-1\",\"main.dashboard\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"purple icon-graph-bar-3\"],[8],[9],[0,\"\\n      Dashboard\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-white mb-1\",\"main.invoices\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"green icon-dollar-sign-circle\"],[8],[9],[0,\"\\n      Invoices\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-white mb-1\",\"main.transactions\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"icon-bank-notes-12 blue\"],[8],[9],[0,\"\\n      Transactions\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-white mb-1\",\"main.reports\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"icon-graph-bar-line purple\"],[8],[9],[0,\"\\n      Reports\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"img\",true],[10,\"src\",\"/assets/images/logo.svg\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});