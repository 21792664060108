define("zipbooks/components/drawers/taxes-form", ["exports", "zipbooks/components/drawers/base-drawer", "ember-data"], function (_exports, _baseDrawer, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    title: 'Edit Taxes',
    store: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        this.set('buttonState', 'saving');
        var promises = this.taxes.map(function (tax) {
          return tax.save();
        });
        Ember.RSVP.all(promises).then(function (results) {
          _this.set('buttonState', 'loaded');

          Ember.tryInvoke(_this.callbacks, 'saved', [results]);

          _this.handleClose(750);
        }).catch(function () {
          _this.set('buttonState', 'invalid');
        });
      },
      addTax: function addTax() {
        var _this2 = this;

        this.store.createRecord('tax');
        Ember.run.next(function () {
          _this2.$('.row:last input:first').focus();
        });
      },
      deleteTax: function deleteTax(tax) {
        var _this3 = this;

        this.overlay.showDialog('overlays/dialogs/destroy-confirm', 'Are you sure?', {
          type: 'tax'
        }, {
          destroy: function destroy() {
            _this3.urlCache.clear();

            tax.destroyRecord().then(function () {
              tax.unloadRecord();

              _this3.notifyPropertyChange('model');
            });
          }
        });
      }
    },
    taxesPromise: Ember.computed('modelId', 'model', function () {
      return this.store.findAll('tax');
    }),
    taxes: Ember.computed('taxesPromise.@each', function () {
      return _emberData.default.PromiseArray.create({
        promise: this.taxesPromise
      });
    }),
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.store.peekAll('tax').forEach(function (t) {
        return t.rollbackAttributes();
      });
    }
  });

  _exports.default = _default;
});