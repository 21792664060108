define("zipbooks/routes/main/reports/user-summary", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    queryParams: {
      view: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.tags = this.paramTags(params);
      this.set('params', params);
      return this.client.cached('GET', 'reports', "user-summary?".concat($.param(params))).then(function (resp) {
        return resp['user_summary'];
      });
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var queryParams = {
          view: this.get('controller.view')
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.location = '/v2/reports/user-summary/export?' + $.param(queryParams);
      }
    }
  });

  _exports.default = _default;
});