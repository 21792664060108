define("zipbooks/mixins/integrations-controller", ["exports", "zipbooks/helpers/oauth-host", "zipbooks/helpers/current-url", "zipbooks/utils/overridable-computed"], function (_exports, _oauthHost, _currentUrl, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    // GMB
    gmbIntegration: Ember.computed('integrations.@each', function () {
      return this.integrations.find(function (i) {
        return i.modelType === 'google-my-business-integration';
      });
    }),
    gmbLocation: Ember.computed('gmbIntegration.externalLocations.@each.location', function () {
      var _this = this;

      return this.gmbIntegration && this.gmbIntegration.externalLocations.find(function (loc) {
        return loc.get('location.id') === _this.locations.current.id;
      });
    }),
    // FACEBOOK
    fbIntegration: Ember.computed('integrations.@each', function () {
      return this.integrations.find(function (i) {
        return i.modelType === 'facebook-business-integration';
      });
    }),
    fbLocation: Ember.computed('fbIntegration.externalLocations.@each.location', function () {
      var _this2 = this;

      return this.fbIntegration && this.fbIntegration.externalLocations.find(function (loc) {
        return loc.get('location.id') === _this2.locations.current.id;
      });
    }),
    // SQUARE
    squareIntegration: Ember.computed('integrations.@each', function () {
      return this.integrations.find(function (i) {
        return i.modelType === 'square-integration';
      });
    }),
    squareLocation: Ember.computed('squareIntegration.externalLocations.@each.location', function () {
      var _this3 = this;

      return this.squareIntegration && this.squareIntegration.externalLocations.find(function (loc) {
        return loc.get('location.id') === _this3.locations.current.id;
      });
    }),
    isSquareConnected: Ember.computed('integrations.@each', function () {
      return this.integrations.any(function (i) {
        return i.modelType === 'square-integration' && i.status === 'connected';
      });
    }),
    connectIntegration: function connectIntegration(provider) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      window.location.href = "".concat((0, _oauthHost.oauthHost)([], opts), "/v2/auth-link/").concat(provider, "?location-id=").concat(this.locations.current.id, "&callback-url=").concat((0, _currentUrl.currentUrl)([], opts), "&token=").concat(this.session.token);
    },
    showAssociateLocationsDialog: function showAssociateLocationsDialog(integration, callback) {
      this.overlay.showDialog('overlays/dialogs/growth/associate-locations', 'Locations', {
        customHeader: true,
        integration: integration,
        externalLocations: integration.externalLocations,
        locations: this.locations
      }, {
        complete: function complete() {
          callback();
        }
      });
    },
    actions: {
      showAssociateLocationsDialog: function showAssociateLocationsDialog(integration) {
        this.showAssociateLocationsDialog(integration, function () {});
      }
    },
    // overide if needed
    integrations: (0, _overridableComputed.default)('integrations', 'model.integrations.@each', function () {
      return this.getWithDefault('model.integrations', []);
    }),
    locations: (0, _overridableComputed.default)('locations', 'model.locations.current', function () {
      return this.get('model.locations');
    })
  });

  _exports.default = _default;
});