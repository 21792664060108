define("zipbooks/services/mini-tracker-ticker", ["exports", "zipbooks/services/ticker"], function (_exports, _ticker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ticker.default.extend({});

  _exports.default = _default;
});