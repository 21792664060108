define("zipbooks/components/messaging/accounts-messenger", ["exports", "ember-data", "zipbooks/utils/later", "zipbooks/mixins/list-controller-selectable", "zipbooks/mixins/transaction-list"], function (_exports, _emberData, _later, _listControllerSelectable, _transactionList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_listControllerSelectable.default, _transactionList.default, {
    accountantClient: Ember.inject.service(),
    accountantStore: Ember.inject.service(),
    userSession: Ember.inject.service('session'),
    session: Ember.inject.service('sibling-session'),
    store: Ember.inject.service('sibling-store'),
    client: Ember.inject.service('sibling-client'),
    overlay: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    sockets: Ember.inject.service(),
    classNames: ['accounts-messenger'],
    inClass: 'modalSlideInDown',
    outClass: 'modalSlideOutUp',
    isPending: Ember.computed('transactions.isPending', 'isReloading', 'session.token', function () {
      return this.get('isReloading') || !this.session.token || this.get('transactions.isPending');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('bulkTags', []);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.overlay.close('messaging/accountant-messenger');
      $('body').addClass('overflow-y-hidden'); // disable scrolling behind this drawer while it's open

      this.set('isReloading', true);
      this.accountantClient.GET('messaging/accounts').then(function (resp) {
        if (!_this.selectedAccountId) {
          var currentAccount = resp.data.find(function (d) {
            return d.id == _this.get('userSession.account.id');
          });

          if (currentAccount) {
            _this.send('viewAccount', currentAccount);
          } else {
            _this.send('viewAccount', resp.data.firstObject);
          }
        }

        _this.set('accounts', resp.data);
      });
      this.notificationCenter.addObserver(this, 'messaging-accounts-update', function (payload) {
        _this.set('accounts', payload.data);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $('body').removeClass('overflow-y-hidden');
      this.notificationCenter.removeObserver(this, 'messaging-accounts-update');
    },
    actions: {
      viewAccount: function viewAccount(client) {
        var _this2 = this;

        this.set('selectedAccountId', client.id);
        this.set('isReloading', true);
        Ember.run.next(function () {
          _this2.store.unloadAll('journal-entry');

          _this2.store.unloadAll('journal-entry-line');

          _this2.store.unloadAll('chart-account');

          _this2.store.unloadAll();

          Ember.run.next(function () {
            _this2.set('session.token', client.attributes['user-token']);

            _this2.session.fetchLoggedInUser().then(function (_user) {
              _this2.session.preloadChartAccounts();

              _this2.session.chartAccountsPromise.then(function () {
                Ember.run.next(function () {
                  _this2.set('isReloading', false);
                });
              });
            });
          });
        });
      },
      confirm: function confirm(line, component) {
        var _this3 = this;

        if (line.get('journalEntry.journalEntryLines').mapBy('chartAccount.id').any(function (id) {
          return !id;
        })) {
          this.overlay.showBanner('error', "You can't confirm a transaction until it's categorized");
        } else {
          // let original = line.get('journalEntry.isConfirmed')
          line.get('journalEntry').thenIfExists(function (journalEntry) {
            journalEntry.toggleProperty('isConfirmed'); // send to server and deal with result

            journalEntry.save().then(function (_journalEntry) {
              if (journalEntry.isConfirmed && ['unconfirmed_and_asked', 'unconfirmed_and_commented', 'unconfirmed'].includes(_this3.transactionFilter)) {
                (0, _later.default)(1000, function () {
                  return _this3.$(component.element).fadeOut();
                });
              }
            }).catch(function (error) {
              _this3.overlay.showBanner('error', Ember.get(error, 'errors.firstObject.title') || 'Unable to confirm.');
            });
          });
        }
      }
    },

    /***************************
     * First column
     ***************************/
    accounts: [],
    selectedAccountId: null,

    /***************************
     * Third column
     ***************************/
    transactionFilter: 'unconfirmed_and_asked',
    transactions: Ember.computed('session.token', 'transactionFilter', function () {
      var filter;

      if (this.transactionFilter === 'unconfirmed') {
        filter = {
          confirmed: false
        };
      } else if (this.transactionFilter === 'commented') {
        filter = {
          has_comment: true
        };
      } else if (this.transactionFilter === 'unconfirmed_and_commented') {
        filter = {
          confirmed: false,
          has_comment: true
        };
      } else {
        filter = {
          confirmed: false,
          asked_client: true
        };
      }

      return _emberData.default.PromiseArray.create({
        promise: this.store.query('journal-entry', {
          filter: filter
        })
      });
    }),
    collection: Ember.computed.alias('transactions'),
    modelType: 'journal_entry',
    // those that include this mixin can override these
    refresh: function refresh() {},
    refreshMeta: function refreshMeta() {},
    // this is mixed in from ListControllerTransactionable > TransactionList, but jaren doesn't want
    // categorizeWithSuggestionByDefault to happen here, so we have to override with a new implementation without it
    bulkConfirm: function bulkConfirm(journalEntries, callback) {
      var _this4 = this;

      if (journalEntries.length > 0) {
        this.batch(journalEntries, function (je) {
          je.set('isConfirmed', true);
          return je.serialize({
            includeId: true
          });
        }, function (results) {
          _this4.unselectAll();

          _this4.showBulkBanner('confirmed', results.succeeded.length, results.unchanged.length, results.failed.length);

          results.failed.forEach(function (je) {
            return je.set('isConfirmed', false);
          });
          callback && callback(results);
        });
      }
    }
  });

  _exports.default = _default;
});