define("zipbooks/routes/main/estimates", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect", "zipbooks/utils/sort-param"], function (_exports, _routePagination, _indexRoute, _autoUpdatingRoute, _permissionRedirect, _sortParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    queryParams: {
      contact_id: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    autoUpdatingEvent: 'estimate.save',
    autoUpdatingModel: 'estimates',
    model: function model(params) {
      var filterParams = {
        archived: params.archived || false
      };

      if (params.contact_id) {
        filterParams.contact_id = params.contact_id;
      }

      if (params.status) {
        filterParams.status = params.status;
      }

      var models = {
        estimates: this.store.cachedQuery('estimate', {
          page: {
            page: params.page || 1,
            'page-size': 100
          },
          filter: filterParams,
          sort: (0, _sortParam.default)(params)
        })
      };

      if (params.contact_id) {
        models.contact = this.store.findRecord('contact', params.contact_id, {
          include: 'public_profile'
        });
      }

      return Ember.RSVP.hash(models);
    }
  });

  _exports.default = _default;
});