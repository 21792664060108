define("zipbooks/components/drawers/affini-pay-integration-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    name: Ember.computed('model.kind', function () {
      return this.get('model.kind') === 'law' ? 'LawPay' : 'CPACharge';
    }),
    title: Ember.computed('name', function () {
      return "".concat(this.name, " Preferences");
    }),
    model: Ember.computed('session.account.id', function () {
      return this.store.queryRecord('affini-pay-integration', {});
    }),
    chargeAccountOptions: Ember.computed('model.chargeAccounts', function () {
      return this.get('model.chargeAccounts').map(function (c) {
        return {
          value: c['id'],
          title: "".concat(c['name'], " (").concat(c['currency'], ")")
        };
      });
    }),
    actions: {
      setCurrency: function setCurrency() {
        var _this = this;

        var chargeAccount = this.get('model.chargeAccounts').find(function (c) {
          return c['id'] === _this.get('model.chargeAccount');
        });
        this.set('model.currency', chargeAccount['currency']);
      },
      disconnect: function disconnect() {
        var _this2 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: "Are you sure you want to disconnect ".concat(this.name, "."),
          confirmButton: {
            title: 'Disconnect',
            class: 'btn-red text-small-bd'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white text-small-bd'
          }
        }, {
          confirm: function confirm() {
            _this2.client.DELETE('integrations/affini-pay/deauthorize').then(function () {
              return _this2.session.fetchLoggedInUser();
            });

            _this2.handleClose(0);
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      }
    }
  });

  _exports.default = _default;
});