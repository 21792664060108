define("zipbooks/components/overlays/dialogs/match-confirm", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    testId: 'match-confirm',
    actions: {
      match: function match() {
        if (this.get('callbacks.match')) {
          this.get('callbacks.match')();
        }

        this.close();
      },
      cancel: function cancel() {
        if (this.get('callbacks.cancel')) {
          this.get('callbacks.cancel')();
        }

        this.close();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('[type="submit"]').focus();
    }
  });

  _exports.default = _default;
});