define("zipbooks/templates/components/drawers/-recurring-profile-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vNYIIIGN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"drawer-body\"],[8],[0,\"\\n  \"],[1,[28,\"line-item-previewer\",null,[[\"model\",\"contact\"],[[24,[\"compModel\"]],[24,[\"model\",\"contact\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"drawer-action\"],[8],[0,\"\\n  \"],[1,[28,\"status-button\",null,[[\"action\",\"model\",\"title\",\"class\",\"testId\"],[[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null],[24,[\"model\"]],\"Save\",\"btn btn-full btn-blue btn-depressable\",\"drawer-save-button\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/drawers/-recurring-profile-form.hbs"
    }
  });

  _exports.default = _default;
});