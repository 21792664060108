define("zipbooks/components/gusto/user-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filteredOperationOptions: Ember.computed('operationOptions', 'selectedUserIds', function () {
      var _this = this;

      return this.operationOptions.filter(function (o) {
        if (o.value.operationKey != 'associate') {
          return true;
        }

        if (_this.model.operation.operationKey == 'associate' && o.value.user.id === _this.model.operation.user.id) {
          return true;
        }

        return !_this.selectedUserIds.includes(o.value.user.id);
      });
    })
  });

  _exports.default = _default;
});