define("zipbooks/components/date-picker", ["exports", "zipbooks/components/form-input", "zipbooks/utils/observe-change"], function (_exports, _formInput, _observeChange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formInput.default.extend({
    format: 'M/D/YYYY',
    formatOutput: function formatOutput(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    formatInput: function formatInput(day) {
      return moment(day, 'YYYY-MM-DD').toDate();
    },
    focusOut: function focusOut() {
      if (!moment(this.stringValue, this.format).isValid()) {
        this.set('value', this.stringValue || null);
      }
    },
    change: function change() {
      var _this = this;

      // the new value isn't available right after they click the day sometimes, so we put it on the next runloop
      Ember.run.next(function () {
        var value = _this.getPickerValue();

        if (value !== _this.value) {
          _this.set('value', value);

          Ember.tryInvoke(_this, 'onchange');
        }

        Ember.tryInvoke(_this, 'onselect');
      });
    },
    getPickerValue: function getPickerValue() {
      var m = moment(this.stringValue, this.format);

      if (Ember.isPresent(this.stringValue) && m.isValid()) {
        return this.formatOutput(m);
      } else {
        return null;
      }
    },
    pikadayOptions: function pikadayOptions() {
      var self = this;
      return $.extend(true, {
        field: this.$('input')[0],
        format: this.format,
        yearRange: [1900, new Date().getFullYear()],
        position: this.position || 'bottom left',
        reposition: false,
        onOpen: function onOpen() {
          $(this.el).css({
            top: '+=5'
          }); // Move it down 5px from its default position
        },
        onClose: function onClose() {
          if (self.onclose) {
            self.onclose.apply(self, arguments);
          }

          return false;
        }
      }, this.extraPikadayOptions || {});
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var picker = new Pikaday(this.pikadayOptions());
      this.set('picker', picker);

      if (this.maxDate === 'today') {
        picker.setMaxDate(new Date());
      } else if (this.maxDate === 'yesterday') {
        picker.setMaxDate(new Date(moment().subtract(1, 'days').format('MM/DD/YYYY')));
      } else if (this.maxDate) {
        picker.setMaxDate(new Date(this.maxDate));
      }

      if (this.rangeEnd) {
        picker.setEndRange(new Date(this.rangeEnd));

        if (this.value) {
          picker.setStartRange(new Date(this.value));
        }
      }

      if (this.minDate) {
        picker.setMinDate(new Date(this.minDate));
      }

      if (this.rangeStart) {
        picker.setStartRange(new Date(this.rangeStart));

        if (this.value) {
          picker.setEndRange(new Date(this.value));
        }
      } // default value needs to be set here. `value` gets overriden by params if its default is set in init


      if (this.default === 'today' && !this.value) {
        this.set('value', this.formatOutput(new Date()));
      }

      var value = this.value;

      if (value && moment(value, 'YYYY-MM-DD').isValid()) {
        picker.setDate(this.formatInput(value));
      }
    },
    _updatePicker: (0, _observeChange.default)('value', function () {
      var picker = this.picker;
      var pickerValue = this.getPickerValue();

      if (picker && moment(this.value, 'YYYY-MM-DD').isValid() && pickerValue !== this.value) {
        picker.setDate(this.formatInput(this.value));
      }
    }),
    _updateMinDate: (0, _observeChange.default)('minDate', function () {
      if (this.minDate) {
        this.picker.setMinDate(moment(this.minDate, 'YYYY-MM-DD').toDate());
      }
    }),
    _updateMaxDate: (0, _observeChange.default)('maxDate', function () {
      if (this.maxDate === 'today') {
        this.picker.setMaxDate(new Date());
      } else if (this.maxDate) {
        this.picker.setMaxDate(new Date(this.maxDate));
      }
    }),
    keyDown: function keyDown(event) {
      if (![13, 27, 37, 38, 39, 40].includes(event.which)) {
        var picker = this.picker;
        document.removeEventListener('keydown', picker._onKeyChange);
      }
    }
  });

  _exports.default = _default;
});