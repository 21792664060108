define("zipbooks/templates/components/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1HKrBZmV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"switch \",[28,\"if\",[[28,\"eq\",[[24,[\"size\"]],\"small\"],null],\"switch-small\"],null],\" \",[28,\"if\",[[24,[\"noText\"]],\"switch-notext\"],null]]]],[11,\"title\",[22,\"title\"]],[8],[0,\"\\n  \"],[7,\"input\",true],[10,\"class\",\"switch-input\"],[11,\"id\",[29,[\"switch\",[22,\"elementId\"]]]],[11,\"checked\",[22,\"checked\"]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n  \"],[7,\"label\",true],[11,\"data-highlight\",[22,\"highlight\"]],[10,\"class\",\"switch-paddle\"],[11,\"disabled\",[22,\"disabled\"]],[11,\"for\",[29,[\"switch\",[22,\"elementId\"]]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/toggle-switch.hbs"
    }
  });

  _exports.default = _default;
});