define("zipbooks/routes/main/accountant/clients/category-template", ["exports", "zipbooks/mixins/refreshable-route"], function (_exports, _refreshableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, {
    model: function model(params) {
      return this.store.findRecord('chart-account-template-group', params.id);
    }
  });

  _exports.default = _default;
});