define("zipbooks/models/integration-object", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    externalSource: (0, _model.attr)('string'),
    externalId: (0, _model.attr)('string'),
    externalIdAlt: (0, _model.attr)('string'),
    enableSync: (0, _model.attr)('boolean'),
    icon: Ember.computed(function () {
      switch (this.externalSource) {
        case 'Square':
          return '/assets/images/brand/square-icon.svg';
      }
    })
  });

  _exports.default = _default;
});