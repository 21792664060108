define("zipbooks/templates/components/error-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aAtS8aR2",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"error\"],[8],[0,\"\\n    \"],[1,[28,\"capitalize\",[[23,1,[\"message\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/error-label.hbs"
    }
  });

  _exports.default = _default;
});