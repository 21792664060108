define("zipbooks/helpers/est-time-to-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.estTimeToConfirm = estTimeToConfirm;
  _exports.default = void 0;

  function estTimeToConfirm(params
  /*, hash*/
  ) {
    // 1-10 = 27 seconds, 11 - infinity = 35 seconds
    var count = params[0];

    if (count < 10) {
      return count * 27;
    } else {
      return 270 + (count - 10) * 35;
    }
  }

  var _default = Ember.Helper.helper(estTimeToConfirm);

  _exports.default = _default;
});