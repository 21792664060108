define("zipbooks/components/suggest/search-all", ["exports", "zipbooks/components/auto-suggest", "zipbooks/utils/is-browser", "zipbooks/utils/route-info"], function (_exports, _autoSuggest, _isBrowser, _routeInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoSuggest.default.extend({
    layoutName: 'components/auto-suggest',
    client: Ember.inject.service(),
    isFetched: false,
    throttle: 750,
    classNames: 'autosuggest-search pl-4',
    placeholder: Ember.computed(function () {
      if ((0, _isBrowser.hasCommandKey)()) {
        return 'Jump to (⌘ + J)';
      } else if ((0, _isBrowser.hasControlKey)()) {
        return 'Jump to (Ctrl + J)';
      }

      return 'Search';
    }),
    actions: {
      select: function select() {
        this._super.apply(this, arguments);

        this.set('searchText', '');
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var actions = this.extraChoices.filter(function (choice) {
        return !choice.permission || _this.permissions.can(choice.permission);
      });
      var infos = (0, _routeInfo.default)();
      Object.keys(infos).forEach(function (route) {
        var info = infos[route];

        if (info.title && (!info.permissions || _this.permissions.can(info.permissions.firstObject))) {
          actions.push({
            name: info.title,
            leftIconClass: info.icon,
            privileges: info.privileges,
            color: info.color,
            term: info.term,
            transition: {
              route: "main.".concat(route)
            },
            searchIconClass: info.searchIconClass
          });
        }
      });
      this.set('actionChoices', actions.map(function (a) {
        a.type = 'action';
        a.id = a.name;
        a.term = (a.term || a.name).toLowerCase();
        return a;
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('isFetched', false);
      this.set('searchText', '');
      this.set('show', false);
    },
    extraChoices: [{
      name: '<b>New</b> invoice',
      leftIconClass: 'icon-dollar-sign-circle',
      rightIconClass: 'btn btn-add btn-add-tiny',
      permission: 'invoices.all',
      color: 'green',
      term: 'add create new invoice',
      transition: {
        route: 'main.invoice',
        model: 'new'
      }
    }, {
      name: '<b>New</b> estimate',
      leftIconClass: 'icon-circle-cash',
      rightIconClass: 'btn btn-add btn-add-tiny',
      color: 'green',
      permission: 'estimates.all',
      term: 'add create new estimate',
      transition: {
        route: 'main.estimate',
        model: 'new'
      }
    }, {
      name: '<b>View</b> sales',
      leftIconClass: 'icon-bank-notes-12',
      rightIconClass: 'icon-list-bullets-1 gray-400',
      permission: 'accounting.read',
      color: 'blue',
      transition: {
        route: 'main.transactions',
        params: {
          page: 1,
          chart_account_id: 'all-sales',
          rollup: null,
          sort: 'date',
          direction: 'desc'
        }
      }
    }, {
      name: '<b>View</b> expenses',
      leftIconClass: 'icon-bank-notes-12',
      rightIconClass: 'icon-list-bullets-1 gray-400',
      permission: 'accounting.read',
      color: 'blue',
      transition: {
        route: 'main.transactions',
        params: {
          page: 1,
          chart_account_id: 'all-expenses',
          rollup: null,
          sort: 'date',
          direction: 'desc'
        }
      }
    }, {
      name: '<b>View</b> bank transactions',
      leftIconClass: 'icon-bank-notes-12',
      rightIconClass: 'icon-list-bullets-1 gray-400',
      permission: 'accounting.read',
      color: 'blue',
      term: 'bank transactions accounts',
      transition: {
        route: 'main.transactions',
        params: {
          page: 1,
          chart_account_id: 'all-banks',
          rollup: null,
          sort: 'date',
          direction: 'desc'
        }
      }
    }, {
      name: '<b>New</b> transaction',
      leftIconClass: 'icon-pencil-2',
      rightIconClass: 'btn btn-add btn-add-tiny',
      permission: 'accounting.all',
      color: 'blue',
      term: 'add create new transaction',
      transition: {
        drawer: 'journal-entry',
        params: {
          mode: 'custom'
        }
      }
    }, {
      name: '<b>New</b> sale',
      leftIconClass: 'icon-dollar-sign',
      rightIconClass: 'btn btn-add btn-add-tiny',
      permission: 'accounting.all',
      color: 'blue',
      term: 'add create new sale',
      transition: {
        drawer: 'journal-entry',
        params: {
          mode: 'deposit'
        }
      }
    }, {
      name: '<b>New</b> expense',
      leftIconClass: 'icon-receipt-3',
      rightIconClass: 'btn btn-add btn-add-tiny',
      permission: 'accounting.all',
      color: 'blue',
      term: 'add create new expense',
      transition: {
        drawer: 'journal-entry',
        params: {
          mode: 'expense'
        }
      }
    }, {
      name: '<b>New</b> transfer',
      leftIconClass: 'icon-transfer-3',
      rightIconClass: 'btn btn-add btn-add-tiny',
      permission: 'accounting.all',
      color: 'blue',
      term: 'add create new transfer',
      transition: {
        drawer: 'journal-entry',
        params: {
          mode: 'transfer'
        }
      }
    }, {
      name: 'New bill',
      leftIconClass: 'icon-receipt',
      rightIconClass: 'btn btn-add btn-add-tiny',
      permission: 'accounting.all',
      color: 'blue',
      term: 'add create new bill',
      transition: {
        route: 'main.bill',
        model: 'new'
      }
    }, {
      name: 'My profile',
      category: 'Account',
      leftIconClass: 'icon-account-group-5',
      rightIconClass: 'icon-list-bullets-1 gray-400',
      color: 'purple',
      term: 'user profile settings',
      transition: {
        drawer: 'identity.me'
      }
    }],
    resultsMatchingSearchString: function resultsMatchingSearchString(search) {
      var _this2 = this;

      if (!search) {
        return [];
      }

      var words = search.split(' ');
      var matchingActions = this.actionChoices.filter(function (a) {
        return words.every(function (w) {
          return a.term.indexOf(w) > -1;
        });
      });
      return this.client.send('GET', "search?q=".concat(encodeURIComponent(search))).then(function (results) {
        return matchingActions.concat(results.data);
      }, function (error) {
        _this2.overlay.showBanner('error', error.message || 'Search is temporarily unavailable.');
      });
    },
    labelForResult: function labelForResult(result) {
      return result.name;
    }
  });

  _exports.default = _default;
});