define("zipbooks/utils/graph-colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    gray: '#E8EAEE',
    zeroLine: '#E8EAEE',
    lightGray: '#EEF1F6',
    revenue: '#0064FF',
    expenses: '#F6A623',
    net_income: '#00D478',
    loss: '#FF194A',
    average: '#50E3C2',
    median: '#008CDD',
    ratings: {
      '1': '#FDEDD3',
      '2': '#FBDBA7',
      '3': '#F9CA7B',
      '4': '#F7B84F',
      '5': '#F5A623'
    },
    cycle: [
    /** See Sketch file webapp_v3.sketch, artboard "Colors" for work on this **/
    // Original palette
    '#71E35B', '#666BFF', '#E957E8', '#FFAC4E', '#A758EA', '#43BCF9', '#FF5858', '#15BF80', '#FF69BA', '#FFDB2E', // From here on, it's my new grid
    // First seven selected manually as the best ones
    '#435EFF', '#1BC9AF', '#F2CB0B', '#9D42EB', '#FF6F00', '#FF3F3F', '#FF7DFE', // start randomizing, and took out the dark ones that stood out too much
    '#21CA00', '#E36300', '#C386F8', '#6D81F6', '#FFC1FE', '#FF9340', '#4DEAD2', '#D9B4FA', '#2C48F2', '#3BDD1B', '#B54F00', '#D15B00', '#89F7E6', '#20B502', '#FF5A5A', '#8C28E2', '#C7CEF6', '#199400', '#B465F9', '#FFF0A6', '#FF9DFE', '#F51111', '#D500D3', '#FFAB6A', '#33DDC3', '#F9E377', '#FFC395', '#DB0000', '#FFAAAA', '#F132F0', '#137200', '#FFD4FE', '#A48900', '#F656F5', '#FF7C7C', '#0DAC94', '#7A16CF', '#048E79', '#F4D84E', '#72E95B', '#DCB700', '#A1AEF8', '#C4A300', '#93F780', '#027261']
  };
  _exports.default = _default;
});