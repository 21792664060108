define("zipbooks/controllers/main/reports/income-statement-percent-of-sales", ["exports", "zipbooks/utils/parse-number", "zipbooks/mixins/tag-filterable-controller", "zipbooks/mixins/reports/date-error", "zipbooks/utils/to-decimal", "zipbooks/utils/tag-serialization"], function (_exports, _parseNumber, _tagFilterableController, _dateError, _toDecimal, _tagSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend(_tagFilterableController.default, _dateError.default, {
    queryParams: ['start_date', 'end_date', 'group_by', 'include', 'basis'],
    start_date: moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    group_by: 'month',
    showTotalOption: 'show',
    currentlyScrolling: false,
    showScrollNav: Ember.computed('columnCount', function () {
      return this.columnCount > 1;
    }),
    setup: function setup() {
      Ember.run.schedule('afterRender', this, function () {
        var colsLeftValue = (0, _parseNumber.default)($('.period-col:first').css('left'));
        this.enableDisableScroll(colsLeftValue);
      });
    },
    enableDisableScroll: function enableDisableScroll(colsLeftValue) {
      var numCols = this.columnCount;
      var colWidth = $('.period-col:first').width();
      var containerWidth = $('.period-cols-container').width();
      var numShowingCols = (0, _parseNumber.default)(containerWidth / colWidth).round(0); // Adjust disabled classes

      if ((0, _parseNumber.default)(colsLeftValue).round(0) <= 0) {
        $('.scroll-right').addClass('disabled');
      } else {
        $('.scroll-right').removeClass('disabled');
      }

      if ((0, _parseNumber.default)(colsLeftValue).round(0) >= (0, _parseNumber.default)(numCols * colWidth - colWidth * numShowingCols).round(0)) {
        $('.scroll-left').addClass('disabled');
      } else {
        $('.scroll-left').removeClass('disabled');
      }
    },
    showTotal: Ember.computed('group_by', 'showTotalOption', function () {
      return this.group_by === 'total' || this.showTotalOption === 'show';
    }),
    actions: {
      scroll: function scroll(direction, e) {
        var _this = this;

        if (this.currentlyScrolling === false) {
          // Performing the action, don't allow another one at the same time
          this.set('currentlyScrolling', true);
          var colWidth = $('.period-col:first').width();
          var colsLeftValue = (0, _parseNumber.default)($('.period-col:first').css('left'));

          if (!$(e.target).hasClass('disabled')) {
            // Do the animation
            var newColsLeftValue;

            if (direction === 'left') {
              newColsLeftValue = colsLeftValue + colWidth;
            } else if (direction === 'right') {
              newColsLeftValue = colsLeftValue - colWidth;
            }

            $('.period-col').animate({
              left: newColsLeftValue
            }, 'fast', function () {
              _this.set('currentlyScrolling', false);
            });
            this.enableDisableScroll(newColsLeftValue);
          } else {
            this.set('currentlyScrolling', false);
          }
        }
      },
      viewTransactions: function viewTransactions(tag) {
        var startDate,
            endDate,
            date = this.group,
            month,
            year;
        var maxStartDate = moment(this.get('start_date'));
        var maxEndDate = moment(this.get('end_date'));

        switch (this.get('group_by')) {
          case 'month':
            startDate = moment(date).startOf('month');
            endDate = moment(date).endOf('month');
            break;

          case 'quarter':
            month = date.split(' ')[0].charAt(1) * 3 - 2;
            year = date.split(' ')[1];
            date = year + '-' + month;
            startDate = moment(date, 'YYYY-M').startOf('quarter');
            endDate = moment(date, 'YYYY-M').endOf('quarter');
            break;

          case 'year':
            startDate = moment(date).startOf('year');
            endDate = moment(date).endOf('year');
            break;

          default:
            startDate = maxStartDate;
            endDate = maxEndDate;
        }

        startDate = startDate.isBefore(this.startDate) ? maxStartDate : startDate;
        endDate = endDate.isAfter(this.endDate) ? maxEndDate : endDate;

        if (this.permissions.can('accounting.read')) {
          this.transitionToRoute('main.transactions', {
            queryParams: {
              end_date: endDate.format('YYYY-MM-DD'),
              start_date: startDate.format('YYYY-MM-DD'),
              tags: _tagSerialization.default.serialize([tag])
            }
          });
        }
      },
      quickReport: function quickReport(kind) {
        if (kind === 'current') {
          var start = moment().startOf('year').format('YYYY-MM-DD');
          var end = moment().format('YYYY-MM-DD');
          this.transitionToRoute({
            queryParams: {
              group_by: 'month',
              start_date: start,
              end_date: end
            }
          });
        } else if (kind === 'last_year') {
          var _start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');

          var _end = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              group_by: 'year',
              start_date: _start,
              end_date: _end
            }
          });
        } else if (kind === 'month_to_date') {
          var _start2 = moment().startOf('month').format('YYYY-MM-DD');

          var _end2 = moment().format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              group_by: 'month',
              start_date: _start2,
              end_date: _end2
            }
          });
        } else {
          var _start3 = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

          var _end3 = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

          this.transitionToRoute({
            queryParams: {
              group_by: 'month',
              start_date: _start3,
              end_date: _end3
            }
          });
        }
      }
    },
    tagGroupSelected: Ember.computed('tags', function () {
      var tags = _tagSerialization.default.deserialize(this.tags);

      return !!tags.find(function (t) {
        return t.taggableType === 'kind';
      });
    }),
    tagKindSelected: Ember.computed('tags', function () {
      var tags = _tagSerialization.default.deserialize(this.tags);

      return tags.find(function (t) {
        return t.taggableType === 'kind';
      });
    }),
    formatAsDate: Ember.computed('tagGroupSelected', 'group_by', function () {
      return this.get('group_by') === 'month' && !this.tagGroupSelected;
    }),
    columnCount: Ember.computed('model.groups', 'showTotal', function () {
      var count = Object.keys(this.get('model.groups')).length;
      return !this.showTotal && this.get('group_by') !== 'total' ? count - 1 : count;
    }),
    incomeStatementWithStyles: Ember.computed('model.income_statement', function () {
      var incomeStatement = this.get('model.income_statement');
      var hasCogs = this.doesCogsExist(incomeStatement);
      this.addStyles(incomeStatement, hasCogs);
      return incomeStatement;
    }),
    doesCogsExist: function doesCogsExist(incomeStatement) {
      var zero = new Decimal(0);
      var total;
      var exists = false;
      incomeStatement.forEach(function (account) {
        account.balances.forEach(function (balance) {
          total = (0, _toDecimal.default)(balance.total);

          if (account.classification == 'Cost of goods sold' && account.is_system && total.comparedTo(zero) !== 0) {
            exists = true;
          }
        });
      });
      return exists;
    },
    addStyles: function addStyles(incomeStatement, hasCogs) {
      var _this2 = this;

      var balance;
      var zero = new Decimal(0);
      var depth = 0;
      incomeStatement.forEach(function (account) {
        balance = (0, _toDecimal.default)(account.balance);
        account['style_classes'] = {
          balance: '',
          total: '',
          total_titles: '',
          balance_values: '',
          balance_titles: '' // Define big five (by their CLASSIFICATION) and their colors

        };
        var bigFive = {
          Sales: 'blue',
          'Operating expenses': 'gold-dark',
          // these are called "Expenses" in the UI
          'Cost of goods sold': 'gold-dark',
          'Other revenue': 'blue',
          'Other expenses': 'gold-dark' // Determine if an account is big five

        };

        var isBigFive = function isBigFive(account) {
          return Object.keys(bigFive).indexOf(account.classification) !== -1 && account.is_main;
        };

        var isSumLine = function isSumLine(account) {
          return Ember.isBlank(account.classification);
        };
        /** Borders **/
        // Bottom border after each big 5 and all its children

        /** Colors **/
        // On balance lines: show color if parent account is Big 5 and it has no children
        // On object total lines: show color if parent account is Big 5
        // On Gross profit, Operating income, Net profit - lines, titles are base
        // On Gross profit, Operating income, Net profit - values are green if > 0, red if < 0, base if = 0
        // COGS line is gold

        /** Margins **/
        // Big margin after each computed line

        /** Bold **/
        // Total Sales, COGS, Total Expenses, and computed lines are bold

        /** Text **/
        // If is total line, show the word "Total" after name

        /** Hide **/
        // If COGS is 0, then hide COGS and Gross profit
        // If is big 5 and amount is 0, then hide the value (the zero)


        if (balance.comparedTo(zero) === 0) {
          account['style_classes']['balance_values'] += ' ';
        }

        if (isBigFive(account)) {
          account['style_classes']['balance'] += 'income-statement-line-bottom-border ';
          account['style_classes']['total'] += 'font-weight-bold ';

          if (account.children.length === 0) {
            account['style_classes']['balance'] += 'font-weight-bold ';
            account['style_classes']['balance'] += bigFive[account.classification] + ' ';
          } else {
            account['style_classes']['total'] += bigFive[account.classification] + ' ';
          }
        }

        if (isSumLine(account)) {
          account['style_classes']['balance'] += 'mb-2 font-weight-bold ';
        }

        if (account.classification == 'Cost of goods sold' || isSumLine(account) && account.name == 'Gross profit') {
          if (!hasCogs) {
            account['style_classes']['balance_values'] = 'hidden ';
            account['style_classes']['balance'] += 'hidden ';
          }
        }

        if (depth > 1) {
          account['style_classes']['balance'] += 'gray-700 ';
          account['style_classes']['total'] += 'hidden ';
        } // Recurse through children


        if (_typeof(account.children) === 'object') {
          depth++;

          _this2.addStyles(account.children, hasCogs);

          depth--;
        }
      });
    }
  });

  _exports.default = _default;
});