define("zipbooks/components/drawers/chart-account-template-group-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    afterModel: function afterModel(model) {
      if (model.default) this.set('alreadyDefault', true);
      if (model.isNew) model.set('showChartCode', 'code');
    },
    title: Ember.computed('model.modelType', function () {
      return this.get('model.isNew') ? 'New template' : 'Edit template';
    })
  });

  _exports.default = _default;
});