define("zipbooks/utils/parse-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseNumber;

  function parseNumber(string) {
    // if its already easily parseable, we do it
    if (!isNaN(+string)) {
      return +string;
    } // if its anything but a string, return 0


    if (Ember.typeOf(string) !== 'string') {
      return 0;
    } // make sure the string only has valid characters


    var validChars = string.replace(/[^0-9.\-+]/g, ''); // return it as a number

    return +validChars;
  }
});