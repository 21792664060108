define("zipbooks/routes/main/reports", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, {
    beforeModel: function beforeModel() {
      if (!this.permissions.can('reports.read')) {
        this.transitionTo('main.contacts');
      }
    }
  });

  _exports.default = _default;
});