define("zipbooks/mixins/auto-updating-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notificationCenter: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    afterModel: function afterModel(model
    /*, transition*/
    ) {
      var _this = this;

      var modelKey = this.autoUpdatingModel;

      if (modelKey) {
        model = model[modelKey];
      }

      var modelName = model.get('type').modelName;
      var event = this.autoUpdatingEvent || modelName + '.save';

      var callback = function callback()
      /*info*/
      {
        _this.urlCache.clear();

        if (!model.isDestroyed && !model.isDestroying) {
          if (_this.autoUpdatingHardRefresh) {
            _this.refresh();
          } else {
            model.update();
          }
        }
      };

      this.notificationCenter.addObserver(this, event, callback);

      this._super.apply(this, arguments);
    },
    deactivate: function deactivate() {
      this.notificationCenter.removeObserver(this);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});