define("zipbooks/components/drawers/project-form", ["exports", "zipbooks/components/drawers/model-drawer", "ember-data"], function (_exports, _modelDrawer, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    session: Ember.inject.service(),
    override: false,
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.get('isNew')) {
        this.users.then(function (users) {
          model.set('users', users);
        });
        model.set('billable', true);

        if (this.get('rest.project')) {
          this.store.find('project', this.get('rest.project')).then(function (result) {
            model.set('parent', result);
            model.set('mainParent', result.get('mainParent.id') ? result.get('mainParent') : result);
            model.set('contact', result.get('contact'));
          });
        } else if (this.get('rest.contact')) {
          this.store.find('contact', this.get('rest.contact')).then(function (result) {
            model.set('contact', result);
          });
        }
      }

      if (model.get('billingMethod')) {
        this.set('override', true);
      }
    },
    allAreSelected: Ember.computed('users.length', 'model.users.length', function () {
      return this.get('users.length') === this.get('model.users.length');
    }),
    projectSelected: Ember.computed('model.parent.id', function () {
      return this.get('model.parent.id') ? true : false;
    }),
    showOverride: Ember.computed('override', 'projectSelected', function () {
      return this.override || !this.projectSelected;
    }),
    contactSelectPlaceholder: Ember.computed('model.allowMultipleContacts', function () {
      return this.model.allowMultipleContacts ? 'Multiple' : 'Contact (optional)';
    }),
    actions: {
      selectProject: function selectProject(project) {
        this.set('model.parent', project);
        this.set('model.mainParent', project && project.get('mainParent.id') ? project.get('mainParent') : project);

        if (project) {
          this.set('model.contact', project.get('contact'));
          this.set('model.allowMultipleContacts', project.get('allowMultipleContacts'));
        } else {
          this.set('model.contact', null);
        }
      },
      selectContact: function selectContact(contact) {
        this.set('model.contact', contact);
      },
      selectBillingMethod: function selectBillingMethod(billingMethod) {
        this.set('model.billingMethod', billingMethod);
      },
      selectUser: function selectUser(user) {
        var users = this.get('model.users');
        var exists = users.findBy('id', user.get('id'));

        if (exists) {
          users.removeObject(exists);
        } else {
          users.addObject(user);
        }
      },
      toggleOverride: function toggleOverride(model) {
        var value = !this.override;

        if (value) {
          model.set('hourlyRate', model.get('mainParent.hourlyRate'));
          model.set('flatAmount', model.get('mainParent.flatAmount'));
          model.set('billingMethod', model.get('mainParent.billingMethod'));
        } else {
          model.set('hourlyRate', null);
          model.set('flatAmount', null);
          model.set('billingMethod', null);
        }

        this.set('override', value);
      },
      selectAll: function selectAll() {
        var selectedUsers = this.get('model.users');
        this.users.forEach(function (user) {
          var exists = selectedUsers.findBy('id', user.get('id'));

          if (!exists) {
            selectedUsers.addObject(user);
          }
        });
      },
      deselectAll: function deselectAll() {
        var selectedUsers = this.get('model.users');
        this.users.forEach(function (user) {
          var exists = selectedUsers.findBy('id', user.get('id'));

          if (exists) {
            selectedUsers.removeObject(user);
          }
        });
      }
    },
    users: Ember.computed(function () {
      if (this.permissions.can('users.read') && this.session.hasPrivilege('teams')) {
        return this.store.query('user', {
          filter: {
            archived: false
          },
          include: 'identity.avatar_cloud_file'
        });
      } else {
        return _emberData.default.PromiseArray.create({
          promise: new Ember.RSVP.resolve([this.get('session.currentUser')])
        });
      }
    }).readOnly()
  });

  _exports.default = _default;
});