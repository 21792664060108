define("zipbooks/templates/components/permissions/permission-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EVYpSCqv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"label form-check-label font-weight-normal\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"class\"],[\"checkbox\",[24,[\"value\"]],\"form-check-input\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-checkbox purple\"],[8],[9],[0,\"\\n  \"],[1,[28,\"format-title\",[[24,[\"scope\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"readOnly\"]]],null,{\"statements\":[[0,\"    - view only\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"exists\",[[24,[\"permissions\",\"hasReadOnly\"]],[24,[\"scope\"]]],null]],null,{\"statements\":[[0,\"    - full access\\n   \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/permissions/permission-checkbox.hbs"
    }
  });

  _exports.default = _default;
});