define("zipbooks/components/drawers/chart-account-template-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    store: Ember.inject.service(),
    title: Ember.computed('model.isNew', function () {
      return this.get('model.isNew') ? 'Add Category' : 'Edit Category';
    }),
    afterModel: function afterModel(model) {
      if (model.isNew) {
        if (this.rest.templateGroupId) {
          this.store.findRecord('chart-account-template-group', this.rest.templateGroupId).then(function (group) {
            return model.set('templateGroup', group);
          });
        } else {
          this.handleClose();
        }
      }
    },
    canChangeParentCodes: [1610, 1620, 1630, 1640, 2310, 2320, 2330, 2340, 2350, 4550, 6100, 6110, 6200, 6300, 6310, 6320, 6330, 6340, 6350, 6400, 6410, 6420, 6430, 6440, 6450, 6460, 6500, 6510, 6520, 6550, 6600, 6650, 6700, 6750, 6800, 6810, 6820, 6830, 6900, 6910, 6920, 6930, 7100],
    actions: {
      didSelectUncreated: function didSelectUncreated(uncreated) {
        if (uncreated) {
          this.set('model.name', uncreated.name);
          this.set('model.systemCode', uncreated.systemCode);
          this.set('model.parent', uncreated.parent);
        }
      },
      didTypeCustomName: function didTypeCustomName(name) {
        this.set('model.name', name);
      },
      selectParentChartAccount: function selectParentChartAccount(chartAccount) {
        this.set('model.parent', chartAccount);

        if (chartAccount && this.get('model.code') === this.get('model.classCode')) {
          this.set('model.code', chartAccount.code);
        }
      }
    },
    canChangeParent: Ember.computed('model.systemCode', function () {
      var systemCode = this.get('model.systemCode') * 1;
      return !systemCode || this.canChangeParentCodes.includes(systemCode);
    })
  });

  _exports.default = _default;
});