define("zipbooks/templates/components/invoice-history-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aOYyHkdp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"table-first-col item p-1\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-weight-bold item-date\"],[8],[1,[28,\"format-date\",[[24,[\"date\"]]],null],false],[0,\":\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item-message\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"action\"]],\"payment\"],null]],null,{\"statements\":[[0,\"      \"],[1,[22,\"customerName\"],false],[0,\" paid \"],[1,[28,\"format-money\",[[24,[\"absoluteAmount\"]]],[[\"currency\"],[[24,[\"model\",\"currency_code\"]]]]],false],[0,\". \"],[4,\"if\",[[24,[\"showReceipt\"]]],null,{\"statements\":[[7,\"a\",true],[11,\"href\",[22,\"receiptUrl\"]],[10,\"class\",\"blue\"],[10,\"target\",\"_blank\"],[8],[0,\"Receipt\"],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"action\"]],\"refund\"],null]],null,{\"statements\":[[0,\"      \"],[1,[22,\"customerName\"],false],[0,\" was refunded \"],[1,[28,\"format-money\",[[24,[\"absoluteAmount\"]]],[[\"currency\"],[[24,[\"model\",\"currency_code\"]]]]],false],[0,\".\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,\"message\"],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"pending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"badge badge-primary\"],[8],[0,\"Pending\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/invoice-history-item.hbs"
    }
  });

  _exports.default = _default;
});