define("zipbooks/templates/components/dashboard/business-health-score", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QtNzwDle",
    "block": "{\"symbols\":[\"context\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-size-16px font-weight-bold\"],[8],[0,\"Business Health Score\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"box-info-content px-5 py-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"score-color\",[[24,[\"data\",\"score\"]]],null]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"score font-size-24px font-weight-bold\"],[8],[1,[24,[\"data\",\"score\"]],false],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"font-size-16px gray\"],[8],[0,\"/ 100\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[7,\"canvas\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"dashboard/chart-tooltip\",null,[[\"data\"],[[24,[\"tooltipData\"]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"font-size-12px font-weight-bold\"],[8],[0,\"\\n        \"],[1,[28,\"format-date\",[[23,1,[\"date\"]],\"MMM D\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"white-space-nowrap\"],[8],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"score-color\",[[23,1,[\"score\"]]],null],\" font-weight-bold font-size-18px\"]]],[8],[0,\"\\n          \"],[1,[23,1,[\"score\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"font-size-12px gray\"],[8],[0,\"\\n          / 100\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/dashboard/business-health-score.hbs"
    }
  });

  _exports.default = _default;
});