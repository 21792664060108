define("zipbooks/controllers/main/accountant/clients/category-template", ["exports", "zipbooks/mixins/index-controller", "zipbooks/mixins/list-controller-selectable", "zipbooks/mixins/bulk-operation"], function (_exports, _indexController, _listControllerSelectable, _bulkOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_indexController.default, _listControllerSelectable.default, _bulkOperation.default, {
    collection: Ember.computed.alias('model.templates'),
    modelType: 'chart-account-template',
    refresh: function refresh() {
      this.send('refreshRoute');
    },
    actions: {
      chooseGroup: function chooseGroup(group) {
        if (group) this.transitionToRoute('main.accountant.clients.category-template', group.id);
      }
    }
  });

  _exports.default = _default;
});