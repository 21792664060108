define("zipbooks/components/import/column-matcher-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    availableOutputHeaders: Ember.computed('mapping.map', 'inputHeader', function () {
      var _this = this;

      var takenHeaders = Object.keys(this.mapping.map).map(function (k) {
        return Ember.get(_this.mapping.map, k);
      });
      return this.outputHeaders.filter(function (h) {
        return Ember.get(_this.mapping.map, _this.inputHeader) === h.value || !takenHeaders.includes(h.value);
      }).concat([{
        title: 'Do not use',
        value: 'do_not_use'
      }]);
    }),
    value: Ember.computed('inputHeader', 'mapping.map', {
      get: function get(_key) {
        return Ember.get(this.mapping.map, this.inputHeader);
      },
      set: function set(_key, value) {
        var map = this.mapping.map;
        map[this.inputHeader] = value;
        this.mapping.set('map', map);
        this.mapping.notifyPropertyChange('map');
        return value;
      }
    })
  });

  _exports.default = _default;
});