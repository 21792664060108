define("zipbooks/components/dashboard/chart-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['chartjs-tooltip-element', 'top'],
    classNameBindings: ['data.visible::hidden'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var data = this.data;
      var el = this.$();

      if (el) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var pos = {
            left: $(document).scrollLeft(),
            top: $(document).scrollTop()
          };
          var rect = data.canvas.getBoundingClientRect();
          pos.left += rect.x + data.position.x;
          pos.top += rect.y + data.position.y;
          pos.left -= el.find('.chartjs-tooltip-content').outerWidth() / 2.0;
          pos.top -= el.find('.chartjs-tooltip-content').outerHeight() + 10;
          el.offset({
            left: pos.left,
            top: pos.top
          });
        });
      }
    }
  });

  _exports.default = _default;
});