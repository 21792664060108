define("zipbooks/adapters/chart-account-template-group", ["exports", "zipbooks/adapters/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    pathForType: function pathForType() {
      return 'chart-accounts/template-groups';
    }
  });

  _exports.default = _default;
});