define("zipbooks/models/chart-account", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/utils/classifications"], function (_exports, _model, _model2, _classifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    archivedAt: (0, _model.attr)(),
    balance: (0, _model.attr)('money'),
    classification: (0, _model.attr)(),
    classCode: (0, _model.attr)(),
    code: (0, _model.attr)(),
    deletable: (0, _model.attr)('boolean'),
    firstPeriodDate: (0, _model.attr)('date'),
    isBankAccount: Ember.computed.bool('bankAccount.id'),
    isReconcilable: (0, _model.attr)('boolean'),
    isSystem: (0, _model.attr)('boolean'),
    name: (0, _model.attr)(),
    nameLocked: (0, _model.attr)('boolean'),
    reconciledTo: (0, _model.attr)('date'),
    reconcileEndDay: (0, _model.attr)(),
    subCode: (0, _model.attr)(),
    systemCode: (0, _model.attr)(),
    viewable: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    // send-only attributes on create
    isDepreciable: (0, _model.attr)(),
    value: (0, _model.attr)(),
    startDate: (0, _model.attr)('date'),
    bankAccount: (0, _model.belongsTo)('bankAccount'),
    children: (0, _model.hasMany)('chartAccounts', {
      inverse: 'parent'
    }),
    journalEntryLines: (0, _model.hasMany)('journalEntryLines', {
      inverse: 'chartAccount'
    }),
    parent: (0, _model.belongsTo)('chartAccount', {
      inverse: 'children'
    }),
    reconciliations: (0, _model.hasMany)('reconciliation'),
    possibleReconciliations: Ember.computed('firstPeriodDate', function () {
      return moment().diff(moment(this.get('firstPeriodDate')), 'months') + 1;
    }),
    completedReconciliations: Ember.computed('reconciliations', function () {
      return this.get('reconciliations').filter(function (rec) {
        return rec.isReconciled;
      }).get('length');
    }),
    classificationInfo: Ember.computed('classification', function () {
      var classification = this.classification;
      return (0, _classifications.default)()[classification] || {};
    }),
    isCritical: Ember.computed('classificationInfo', function () {
      return this.classificationInfo.critical || false;
    })
  });

  _exports.default = _default;
});