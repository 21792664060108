define("zipbooks/controllers/main/items", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller"], function (_exports, _controllerPagination, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    sort: 'date',
    direction: 'desc',
    archived: false
  });

  _exports.default = _default;
});