define("zipbooks/controllers/main/settings/team", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller"], function (_exports, _controllerPagination, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    queryParams: ['archived'],
    archived: false,
    collection: Ember.computed.alias('model.users'),
    actions: {
      removeTeam: function removeTeam(team) {
        var _this = this;

        team.destroyRecord().then(function () {
          return _this.overlay.showBanner('success', 'Team removed.');
        });
      },
      makeOwner: function makeOwner(user) {
        var _this2 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: 'Are you sure you want to change the account owner? Only the new account owner will be able to change it back.',
          confirmButton: {
            title: 'Change',
            class: 'btn-red text-small-bd'
          },
          cancelButton: {
            title: 'Cancel',
            class: 'btn-white text-small-bd'
          }
        }, {
          confirm: function confirm() {
            _this2.client.send('PATCH', 'accounts/owner', null, {
              'user-id': user.id
            }).then(function () {
              _this2.session.fetchLoggedInUser();

              _this2.send('refreshRoute');
            });
          },
          cancel: function cancel() {},
          close: function close() {}
        });
      }
    }
  });

  _exports.default = _default;
});