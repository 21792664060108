define("zipbooks/utils/remove-blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removeBlank;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function removeBlank(obj) {
    // Clone source
    var o = JSON.parse(JSON.stringify(obj));
    Object.keys(o).forEach(function (key) {
      if (o[key] && _typeof(o[key]) === 'object') {
        o[key] = removeBlank(o[key]); // Recurse.
      } else if (o[key] === undefined || o[key] === null) {
        delete o[key]; // Delete undefined and null.
      }
    });
    return o; // Return new object.
  }
});