define("zipbooks/components/dashboard/total-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var ctx = this.$('canvas') && this.$('canvas')[0];
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.data.months,
          datasets: [{
            data: this.data.licenses,
            fill: false,
            borderColor: '#0064ff',
            borderWidth: 3
          }]
        },
        options: {
          animation: false,
          elements: {
            line: {
              tension: 0
            },
            point: {
              radius: 0
            }
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              barThickness: 15,
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              gridLines: {
                drawBorder: false,
                color: '#dee2e6'
              },
              scaleLabel: {
                display: false
              },
              ticks: {
                maxTicksLimit: 6,
                min: 0,
                beginAtZero: true,
                callback: function callback(value) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                }
              }
            }]
          }
        }
      });
    }
  });

  _exports.default = _default;
});