define("zipbooks/utils/classifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = classifications;
  _exports.filterClassifications = filterClassifications;

  function classifications() {
    return {
      Assets: {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 7,
        tags: ['asset', 'biaf']
      },
      'Current assets': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 3,
        tags: ['asset', 'biaf']
      },
      Cash: {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: true,
        priority: 2,
        tags: ['asset']
      },
      'ZipBooks merchant account': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: true,
        priority: 1,
        tags: ['asset']
      },
      'Square Merchant Account': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: true,
        priority: 1,
        tags: ['asset']
      },
      'Accounts receivable': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: true,
        critical: false,
        priority: 3,
        tags: ['asset', 'ddoe', 'ddaf']
      },
      Inventory: {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 8,
        tags: ['asset', 'biaf']
      },
      'Prepaid expenses': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 8,
        tags: ['asset', 'xdox', 'xdaf', 'biaf']
      },
      'Fixed assets': {
        initValue: true,
        depreciable: true,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 9,
        tags: ['asset', 'biaf']
      },
      'Long-term investments': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 9,
        tags: ['asset']
      },
      'Accumulated depreciation': {
        initValue: true,
        depreciable: false,
        children: false,
        creditRed: true,
        creditNegative: false,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 11,
        tags: ['asset']
      },
      'Other assets': {
        initValue: true,
        depreciable: true,
        children: true,
        creditRed: true,
        creditNegative: true,
        icon: 'icon-home-3',
        color: 'blue',
        oeSwap: false,
        critical: false,
        priority: 9,
        tags: ['asset', 'biaf']
      },
      Liabilities: {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: false,
        icon: 'icon-credit-card',
        color: 'red',
        oeSwap: false,
        critical: false,
        priority: 6,
        tags: ['liability']
      },
      'Current liabilities': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: false,
        icon: 'icon-credit-card',
        color: 'red',
        oeSwap: false,
        critical: false,
        priority: 4,
        tags: ['liability']
      },
      'Credit accounts': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: false,
        icon: 'icon-credit-card',
        color: 'red',
        oeSwap: false,
        critical: true,
        priority: 2,
        tags: ['liability']
      },
      'Accounts payable': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: false,
        icon: 'icon-credit-card',
        color: 'red',
        oeSwap: false,
        critical: false,
        priority: 5,
        tags: ['liability', 'xdoe', 'xdaf']
      },
      'Long-term liabilities': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: false,
        icon: null,
        color: 'red',
        oeSwap: false,
        critical: false,
        priority: 7,
        tags: ['liability']
      },
      'Unearned revenue': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: true,
        creditNegative: false,
        icon: null,
        color: 'red',
        oeSwap: false,
        critical: false,
        priority: 12,
        tags: ['liability', 'ddor', 'ddaf']
      },
      Equity: {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: false,
        icon: 'icon-wallet-3',
        color: 'purple',
        oeSwap: false,
        critical: false,
        priority: 13,
        tags: ['oeaf', 'ddoe', 'ddaf', 'xdoe', 'xdaf']
      },
      'Invested capital': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: false,
        icon: 'icon-wallet-3',
        color: 'purple',
        oeSwap: false,
        critical: false,
        priority: 15,
        tags: ['oeaf', 'ddoe', 'ddaf', 'xdoe', 'xdaf']
      },
      'Retained earnings': {
        initValue: true,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: false,
        icon: 'icon-wallet-3',
        color: 'purple',
        oeSwap: true,
        critical: false,
        priority: 16,
        tags: ['oeaf', 'ddoe', 'ddaf', 'xdoe', 'xdaf']
      },
      Revenue: {
        initValue: false,
        depreciable: false,
        children: false,
        creditRed: false,
        creditNegative: false,
        icon: 'icon-arrow-box-up-1',
        color: 'green',
        oeSwap: true,
        critical: false,
        priority: 17,
        tags: ['revenue', 'ddor']
      },
      Sales: {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: false,
        icon: 'icon-arrow-box-up-1',
        color: 'green',
        oeSwap: true,
        critical: false,
        priority: 18,
        tags: ['revenue', 'ddor']
      },
      'Other revenue': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: false,
        icon: 'icon-arrow-box-up-1',
        color: 'green',
        oeSwap: true,
        critical: false,
        priority: 19,
        tags: ['revenue', 'ddor']
      },
      'Gain on asset disposal': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: false,
        icon: 'icon-arrow-box-up-1',
        color: 'green',
        oeSwap: true,
        critical: false,
        priority: 19,
        tags: ['revenue', 'ddor']
      },
      Expenses: {
        initValue: false,
        depreciable: false,
        children: false,
        creditRed: false,
        creditNegative: true,
        icon: 'icon-arrow-box-down-1',
        color: 'red',
        oeSwap: true,
        critical: false,
        priority: 20,
        tags: ['expense', 'xdox', 'biaf']
      },
      'Cost of goods sold': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: true,
        icon: 'icon-arrow-box-down-1',
        color: 'red',
        oeSwap: true,
        critical: false,
        priority: 20,
        tags: ['expense', 'xdox', 'biaf']
      },
      'Operating expenses': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: true,
        icon: 'icon-arrow-box-down-1',
        color: 'red',
        oeSwap: true,
        critical: false,
        priority: 20,
        tags: ['expense', 'xdox', 'biaf']
      },
      'Depreciation expense': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: true,
        icon: 'icon-arrow-box-down-1',
        color: 'red',
        oeSwap: true,
        critical: false,
        priority: 23,
        tags: ['expense', 'xdox']
      },
      'Other expenses': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: true,
        icon: 'icon-arrow-box-down-1',
        color: 'red',
        oeSwap: true,
        critical: false,
        priority: 24,
        tags: ['expense', 'xdox', 'biaf']
      },
      'Loss on asset disposal': {
        initValue: false,
        depreciable: false,
        children: true,
        creditRed: false,
        creditNegative: true,
        icon: 'icon-arrow-box-down-1',
        color: 'red',
        oeSwap: true,
        critical: false,
        priority: 24,
        tags: ['expense', 'xdox']
      },
      'In transit': {
        initValue: false,
        depreciable: false,
        children: false,
        creditRed: false,
        creditNegative: true,
        // icon: null,
        // color: null,
        oeSwap: false,
        critical: false,
        priority: 25,
        tags: ['suspense', 'oeaf', 'xdaf', 'ddaf']
      },
      Suspense: {
        initValue: false,
        depreciable: false,
        children: false,
        creditRed: false,
        creditNegative: true,
        // icon: null,
        // color: null,
        oeSwap: false,
        critical: false,
        priority: 26,
        tags: ['suspense']
      }
    };
  }

  function filterClassifications() {
    var classes = classifications();
    return Object.keys(classes).reduce(function (acc, v) {
      var key = "all-".concat(v.dasherize());
      acc[key] = {
        id: key,
        name: "All ".concat(v)
      };
      return acc;
    }, {});
  }
});