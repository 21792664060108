define("zipbooks/components/transaction-sheet/cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['transaction-sheet-table-cell'],
    noTestIdPassthrough: true,
    classNameBindings: ['cell.isHighlighted:transaction-sheet-table-cell-highlighted', 'cell.isEditing:transaction-sheet-table-cell-editing', 'cell.grayOutAccountColumn:transaction-sheet-table-cell-greyed-out', 'cell.isHidden:d-none', 'cell.isEditing::no-select'],
    attributeBindings: ['splitRowSpan:rowspan'],
    splitRowSpan: Ember.computed('cell.isMerged', 'cell.row.transaction.splitInfo.lines.length', function () {
      if (this.cell.isMerged) {
        return this.get('cell.row.transaction.splitInfo.lines.length');
      } else {
        return 1;
      }
    }),
    confidence: Ember.computed.alias('cell.row.categoryLine.adjustedSuggestedChartAccountConfidence'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.cell.set('component', this);
    },
    amountProxy: Ember.computed('cell.row.categoryLine.amount', {
      get: function get(_key) {
        return this.cell.row.categoryLine.amount;
      },
      set: function set(_key, value) {
        this.set('cell.row.categoryLine.amount', value * 1);
        return value;
      }
    }),
    actions: {
      setAccount: function setAccount(selection) {
        this.set('cell.row.accountLine.chartAccount', selection && selection.chartAccount);
      },
      setCategory: function setCategory(selection) {
        this.set('cell.row.categoryLine.chartAccount', selection && selection.chartAccount);
      },
      setKind: function setKind(selection) {
        var _this = this;

        var kind = selection && selection.id;
        this.cell.row.transaction.journalEntry.set('kind', kind);
        this.set('cell.row.kind', kind); // only auto save if they selected something

        if (selection) {
          Ember.run.next(function () {
            _this.cell.setEditing(false);
          });
        }
      },
      setName: function setName(selection) {
        var _this2 = this;

        if (selection && selection.chartAccount) {
          this.set('cell.row.transaction.journalEntry.name', selection.id);
          this.set('cell.row.categoryLine.amount', selection.amount * 1);
          this.set('cell.row.categoryLine.chartAccount', selection.chartAccount);

          if (!this.get('cell.row.transaction.isSplit')) {
            this.cell.row.transaction.journalEntry.get('journalEntryLines').forEach(function (l) {
              return l.set('amount', selection.amount * 1);
            });
          }

          this.set('cell.row.transaction.journalEntry.autoConfirm', true);
          Ember.run.next(function () {
            _this2.cell.setEditing(false);
          });
        } else if (selection && selection.id) {
          this.set('cell.row.transaction.journalEntry.name', selection.id);
          Ember.run.next(function () {
            _this2.cell.setEditing(false);
          });
        } else {
          this.set('cell.row.transaction.journalEntry.name', selection);
        }
      },
      setAmountSuggestion: function setAmountSuggestion(selection) {
        var _this3 = this;

        if (selection && selection.chartAccount) {
          this.set('cell.row.categoryLine.amount', selection.amount * 1);
          this.set('cell.row.categoryLine.chartAccount', selection.chartAccount);

          if (!this.get('cell.row.transaction.isSplit')) {
            this.cell.row.transaction.journalEntry.get('journalEntryLines').forEach(function (l) {
              return l.set('amount', selection.amount * 1);
            });
          }

          this.set('cell.row.transaction.journalEntry.autoConfirm', true);
          Ember.run.next(function () {
            _this3.cell.setEditing(false);
          });
        } else if (selection && !isNaN(selection * 1)) {
          var amount = selection * 1;
          this.set('cell.row.categoryLine.amount', amount);

          if (!this.get('cell.row.transaction.isSplit')) {
            this.cell.row.transaction.journalEntry.get('journalEntryLines').forEach(function (l) {
              return l.set('amount', amount);
            });
          }
        }
      },
      addTag: function addTag(tagData) {
        this.cell.row.addTag(tagData);
      },
      removeTag: function removeTag(tag) {
        this.cell.row.removeTag(tag);
      },
      split: function split() {
        this.split.apply(this, arguments);
      }
    },
    keyPress: function keyPress(event) {
      if (this.cell.column.key === 'date') {
        // if the string typed is not a number, hyphen, backspace, or period prevent it
        switch (event.key) {
          case '/':
          case '.':
            return;

          default:
            break;
        }

        if (isNaN(String.fromCharCode(event.which))) {
          event.preventDefault();
        }
      } else if (this.cell.column.key === 'amount') {
        // if the string typed is not a number, hyphen, backspace, or period prevent it
        switch (event.key) {
          case ',':
          case '.':
          case '$':
            return;

          default:
            break;
        }

        if (isNaN(String.fromCharCode(event.which))) {
          event.preventDefault();
        }
      }
    },
    focusIn: function focusIn() {
      var _this4 = this;

      if (this.cell.column.key === 'date') {
        Ember.run.later(this, function () {
          var match = /\/(\d{2})\//.exec(_this4.cell.row.dateTransform);

          if (match && match.index && match.length === 2) {
            _this4.$('input, textarea')[0].setSelectionRange(match.index + 1, match.index + 3);
          }
        }, 20);
      }
    },
    tagsCommaSeparated: Ember.computed('cell.row.tags.@each', function () {
      return this.getWithDefault('cell.row.tags', []).map(function (t) {
        return t.name;
      }).join(',');
    })
  });

  _exports.default = _default;
});