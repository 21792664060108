define("zipbooks/models/wepay-integration", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    status: (0, _model.attr)(),
    account: (0, _model.belongsTo)('account')
  });

  _exports.default = _default;
});