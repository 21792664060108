define("zipbooks/utils/sort-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortParam;

  function sortParam(params) {
    if (params.sort) {
      if (params.direction && params.direction == 'desc') {
        return '-' + params.sort;
      } else {
        return params.sort;
      }
    }
  }
});