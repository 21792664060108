define("zipbooks/components/tapp-able", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    touchMoving: false,
    click: function click(e) {
      e.preventDefault();
      e.stopPropagation();
      Ember.tryInvoke(this, 'action');
      return false;
    },
    touchMove: function touchMove() {
      this.set('touchMoving', true);
    },
    touchEnd: function touchEnd(e) {
      if (!this.touchMoving) {
        e.preventDefault();
        e.stopPropagation();
        Ember.tryInvoke(this, 'action');
      }

      this.set('touchMoving', false);
      return false;
    }
  });

  _exports.default = _default;
});