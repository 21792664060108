define("zipbooks/routes/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.Object.create({
        email: null,
        phone: null,
        password: null,
        name: null,
        plan_id: null
      });
    }
  });

  _exports.default = _default;
});