define("zipbooks/mixins/device-registration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    org: 'ceurt9zj',
    session_id: '',
    deviceRegistered: Ember.computed(function () {
      return this.window.sessionStorage.getItem('tmx_deviceregistered') || false;
    }),
    registrationScript: function () {
      // <--- this is just some random name
      var deviceRegistered = this.session.isDeviceRegistered;

      if (!deviceRegistered) {
        // generate 32 character token
        var values = '0123456789abcdefghiklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXTZ';

        for (var i = 0; i < 32; i++) {
          var rand = Math.floor(Math.random() * values.length);
          this.session_id += values.substring(rand, rand + 1);
        }

        var url = "https://tm.bdc-cdn.com/fp/tags.js?org_id=".concat(this.org, "&session_id=").concat(this.session_id); // Create a <script> element

        var scriptElement = document.createElement('script'); // Set the src attribute of the <script> element

        scriptElement.src = url; // Append the <script> element to the document's <body>

        document.body.appendChild(scriptElement);
        this.session.setDeviceRegisteredToken(this.session_id);
        this.session.setDeviceRegistered(true);
      }
    }.on('init')
  });

  _exports.default = _default;
});