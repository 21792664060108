define("zipbooks/mixins/reports/scrollable-report", ["exports", "zipbooks/utils/parse-number"], function (_exports, _parseNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentlyScrolling: false,
    setup: function setup() {
      Ember.run.schedule('afterRender', this, function () {
        var colsLeftValue = (0, _parseNumber.default)($('.period-col:first').css('left'));
        this.enableDisableScroll(colsLeftValue);
      });
    },
    enableDisableScroll: function enableDisableScroll(colsLeftValue) {
      var numCols = this.columnCount;
      var colWidth = $('.period-col:first').width();
      var containerWidth = $('.period-cols-container').width();
      var numShowingCols = (0, _parseNumber.default)(containerWidth / colWidth).round(0); // Adjust disabled classes

      if ((0, _parseNumber.default)(colsLeftValue).round(0) <= 0) {
        $('.scroll-right').addClass('disabled');
      } else {
        $('.scroll-right').removeClass('disabled');
      }

      if ((0, _parseNumber.default)(colsLeftValue).round(0) >= (0, _parseNumber.default)(numCols * colWidth - colWidth * numShowingCols).round(0)) {
        $('.scroll-left').addClass('disabled');
      } else {
        $('.scroll-left').removeClass('disabled');
      }
    },
    showScrollNav: Ember.computed('columnCount', function () {
      return this.columnCount > 1;
    }),
    actions: {
      scroll: function scroll(direction, e) {
        var _this = this;

        if (this.currentlyScrolling === false) {
          // Performing the action, don't allow another one at the same time
          this.set('currentlyScrolling', true);
          var colWidth = $('.period-col:first').width();
          var colsLeftValue = (0, _parseNumber.default)($('.period-col:first').css('left'));

          if (!$(e.target).hasClass('disabled')) {
            // Do the animation
            var newColsLeftValue;

            if (direction === 'left') {
              newColsLeftValue = colsLeftValue + colWidth;
            } else if (direction === 'right') {
              newColsLeftValue = colsLeftValue - colWidth;
            }

            $('.period-col').animate({
              left: newColsLeftValue
            }, 'fast', function () {
              _this.set('currentlyScrolling', false);
            });
            this.enableDisableScroll(newColsLeftValue);
          } else {
            this.set('currentlyScrolling', false);
          }
        }
      }
    }
  });

  _exports.default = _default;
});