define("zipbooks/templates/components/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LhZnKfzd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"color-picker-square\"],[8],[1,[28,\"input\",null,[[\"value\",\"class\"],[[24,[\"value\"]],\"color-picker-inner\"]]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/color-picker.hbs"
    }
  });

  _exports.default = _default;
});