define("zipbooks/mixins/transaction-list-row", ["exports", "zipbooks/utils/line-analyzer"], function (_exports, _lineAnalyzer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    urlCache: Ember.inject.service(),
    overlay: Ember.inject.service(),
    primaryLine: Ember.computed('line.primaryLine.id', function () {
      var line = this.line;

      if (!this.chartAccountId) {
        return line.get('primaryLine');
      } else {
        return line;
      }
    }),
    memoLine: Ember.computed('primaryLine.journalEntry.journalEntryLines.@each.importedMemo', function () {
      return this.getWithDefault('primaryLine.journalEntry.journalEntryLines', []).find(function (line) {
        return line.importedMemo;
      });
    }),
    accountLine: Ember.computed('primaryLine.chartAccount.name', 'primaryLine.balancingLines.firstObject.chartAccount.name', function () {
      return this.isFlipped ? this.get('primaryLine.balancingLines.firstObject') : this.primaryLine;
    }),
    categoryLine: Ember.computed('isFlipped', 'primaryLine.chartAccount.name', function () {
      return this.isFlipped ? this.primaryLine : this.get('primaryLine.balancingLines.firstObject');
    }),
    amount: Ember.computed('chartAccountId', 'primaryLine.amount', 'primaryLine.journalEntry.journalEntryLines.@each', 'isTransfer', function () {
      if (this.chartAccountId) {
        return this.primaryLine.amount;
      }

      var lines = this.get('primaryLine.journalEntry.journalEntryLines').filter(function (line) {
        return line.get('chartAccount.isCritical');
      });

      if (lines.get('length') > 1 && !this.isTransfer) {
        var amount = new Decimal(0);
        lines.forEach(function (line) {
          var line_amount = new Decimal(line.get('amount'));

          if (line.get('kind') === 'debit') {
            amount = amount.plus(line_amount);
          } else {
            amount = amount.minus(line_amount);
          }
        });
        return amount;
      }

      return this.get('primaryLine.amount');
    }),
    isTransfer: Ember.computed('primaryLine.journalEntry.journalEntryLines.@each.chartAccountName', function () {
      var lines = this.get('primaryLine.journalEntry.journalEntryLines');
      return _lineAnalyzer.default.isTransfer(lines || []);
    }),
    isSplit: Ember.computed('primaryLine.journalEntry.journalEntryLines.@each', function () {
      return this.get('primaryLine.journalEntry.journalEntryLines.length') > 2 || this.get('primaryLine.journalEntry.isRollup');
    }),
    hasMultipleCriticalLines: Ember.computed('primaryLine.journalEntry.journalEntryLines.@each', function () {
      return this.getWithDefault('primaryLine.journalEntry.journalEntryLines', []).filter(function (line) {
        return line.get('chartAccount.isCritical');
      }).get('length') > 1;
    }),
    hasMultiplePrimaryAccounts: Ember.computed('primaryLine', function () {
      var _this = this;

      return this.getWithDefault('primaryLine.journalEntry.journalEntryLines', []).filter(function (line) {
        return line.get('kind') === _this.get('primaryLine.kind') && line.get('chartAccount.isCritical');
      }).get('length') > 1;
    }),
    // if a transaction is tagged with an invoice, we need to limit what they can categorize it as
    chartAccountFilters: Ember.computed('primaryLine.journalEntry.isSystem', function () {
      // https://github.com/ZipBooks/zipbooks/issues/1636
      if (this.get('primaryLine.journalEntry.isSystem')) {
        if (this.get('categoryLine.kind') === 'debit') {
          return ['accounts-receivable'];
        } else {
          return ['sales'];
        }
      } else {
        return [];
      }
    })
  });

  _exports.default = _default;
});