define("zipbooks/models/bill-item", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    description: (0, _model.attr)(),
    name: (0, _model.attr)(),
    total: (0, _model.attr)('decimal-2'),
    chartAccount: (0, _model.belongsTo)('chartAccount')
  });

  _exports.default = _default;
});