define("zipbooks/utils/transaction-sheet/transaction", ["exports", "zipbooks/mixins/transaction-list-row"], function (_exports, _transactionListRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_transactionListRow.default, {
    amount: Ember.computed('categoryLine.amount', function () {
      return this.get('categoryLine.amount');
    }),
    bankClassCodes: ['1251', '1252', '1271', '1280', '1281', '1290'],
    typeOptions: Ember.computed('accountLine.chartAccount.id', function () {
      // we're going to return all for now, but will very likely return to restricting soon
      if (this.splitInfo.isSplit && !this.splitInfo.accountLine) {
        return ['debit', 'credit'];
      } else {
        return ['deposit', 'withdrawal', 'payment', 'check', 'debit', 'credit'];
      } // if (this.get('accountLine.chartAccount.classification') === 'Credit accounts') {
      //   return [
      //     'deposit',
      //     'withdrawal',
      //     'debit',
      //     'credit'
      //   ]
      // }
      // else if (this.bankClassCodes.includes(this.get('accountLine.chartAccount.classCode'))) {
      //   return [
      //     'deposit',
      //     'withdrawal',
      //     'payment',
      //     'check',
      //     'debit',
      //     'credit'
      //   ]
      // }
      // else {
      //   return [
      //     'debit',
      //     'credit'
      //   ]
      // }

    }),
    isConfirmed: Ember.computed('journalEntry.isConfirmed', {
      get: function get(_key) {
        return this.get('journalEntry.isConfirmed') ? 'Y' : 'N';
      },
      set: function set(_key, value) {
        var result = ['yes', 'y', 'Y', 't', 'T', 'true'].includes(value);
        this.get('journalEntry.journalEntryLines').forEach(function (line) {
          line.categorizeWithSuggestionByDefault();
        });
        this.set('journalEntry.isConfirmed', result);
        return result ? 'Y' : 'N';
      }
    }),
    // the default is that it sorts by: Category selected, then auto-categorized by confidence (highest at the top),
    // then no category
    categorySort: Ember.computed('categoryLine.chartAccount.id', 'categoryLine.chartAccountSuggestion.id', function () {
      if (this.get('categoryLine.chartAccount.name')) {
        return this.get('categoryLine.chartAccount.name');
      } else if (this.get('categoryLine.adjustedSuggestedChartAccountConfidence')) {
        return 'zzz' + this.get('categoryLine.adjustedSuggestedChartAccountConfidence');
      } else {
        return 'zzzzzz';
      }
    }),
    saveEntry: function saveEntry(cell) {
      var _this = this;

      var entry = this.journalEntry;
      /***************
       * Auto-confirm
       ***************/

      if (cell.column.key === 'category') {
        entry.set('autoConfirm', true);
      }
      /***************
       * Amount
       ***************/
      // if its not split at all, we just make both lines the same amount


      if (!this.splitInfo.isSplit) {
        entry.get('journalEntryLines').forEach(function (l) {
          return l.set('amount', cell.get('row.categoryLine.amount') * 1);
        });
      } // if its a split where the account line is on the left and all other lines are on the right, then we just make
      // the line on the left the sum of all lines on the rigth


      if (this.splitInfo.isSplit && this.splitInfo.accountLine) {
        var lines = entry.get('journalEntryLines');
        var kinds = lines.reduce(function (acc, line) {
          acc[line.kind].pushObject(line);
          return acc;
        }, {
          debit: [],
          credit: []
        });
        var uniqueKind = Object.keys(kinds).find(function (k) {
          return kinds[k].length === 1;
        });

        if (uniqueKind) {
          var amount = lines.filter(function (l) {
            return l.kind !== uniqueKind;
          }).mapBy('amount').reduce(function (a, v) {
            return a + (v || 0) * 1;
          }, 0);
          lines.filter(function (l) {
            return l.kind === uniqueKind;
          }).map(function (l) {
            return l.set('amount', amount);
          });
        }
      }
      /***************
       * Kind
       ***************/


      var kind = entry.kind || cell.row.kind;
      var needsOneOf = [entry.name && entry.name.length > 0, entry.journalEntryLines.mapBy('amount').any(function (a) {
        return a > 0;
      }), entry.note && entry.note.length > 0];

      if (!kind || !needsOneOf.any(function (t) {
        return t;
      })) {
        cell.updateRevertFunction();
        return Ember.RSVP.reject('Ignored save because hasnt been filled out enough.');
      }

      if (this.splitInfo.isSplit && !this.splitInfo.accountLine) {
        cell.set('row.categoryLine.kind', cell.row.kind);
      } else {
        var lineKind = {
          deposit: 'debit',
          withdrawal: 'credit',
          payment: 'credit',
          check: 'credit',
          debit: 'debit',
          credit: 'credit'
        }[kind];
        var opposite = lineKind === 'debit' ? 'credit' : 'debit';

        if (cell.get('row.accountLine')) {
          cell.set('row.accountLine.kind', lineKind);
        }

        cell.get('row.transaction.rows').forEach(function (row) {
          row.set('categoryLine.kind', opposite);
        });
      }
      /***************
       * Debug
       ***************/


      if (console.table) {
        var _lines = entry.journalEntryLines.map(function (line) {
          return {
            Kind: line.kind,
            Amount: line.amount,
            Date: line.date,
            'Chart Account': "".concat(line.get('chartAccount.name'), " (").concat(line.get('chartAccount.id'), ")"),
            tags: line.getWithDefault('tags', []).mapBy('name').join(',')
          };
        });

        console.table(_lines);
      }

      return entry.save().then(function (entry) {
        console.info('Saved ', entry.id);
        entry.set('autoConfirm', false);
        var lines = entry.journalEntryLines.filter(function (line) {
          if (!line) {
            return false;
          }

          if (line.isNew) {
            line.deleteRecord();
            line.unloadRecord();
            return false;
          }

          return true;
        });
        entry.set('journalEntryLines', lines); // if we're filtered by a chart account, we need to set the line and primary line

        if (_this.chartAccountId) {
          _this.set('line', entry.journalEntryLines.find(function (l) {
            return l.get('chartAccount.id') === _this.chartAccountId;
          }) || entry.journalEntryLines.firstObject);
        }

        _this.afterSave(_this);

        cell.updateRevertFunction();
        return entry;
      }, function (_error) {
        // entry.rollbackAttributes()
        entry.set('isConfirmed', false);
      });
    },
    kind: Ember.computed('journalEntry.kind', 'accountLine.chartAccount.bankAccount.id', 'accountLine.kind', {
      get: function get(_key) {
        // if it has a kind in the database, use that
        if (this.get('journalEntry') && this.get('journalEntry.kind')) {
          return this.get('journalEntry.kind');
        } else if (this.get('journalEntry') && this.get('journalEntry.id')) {
          // if its a bank: debit on the account line is a deposit, credit is a withdrawal
          if (this.get('accountLine.chartAccount.bankAccount.id')) {
            return this.get('accountLine.kind') === 'debit' ? 'deposit' : 'withdrawal';
          } // otherwise just return debit or credit of the account line
          else if (this.get('accountLine.kind')) {
              return this.get('accountLine.kind');
            }
        }
      },
      set: function set(_key, value) {
        if (this.journalEntry) {
          this.journalEntry.set('kind', value);
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});