define("zipbooks/components/drawers/label-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    kind: '',
    fetchOnOpen: false,
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('contactModel', {});
      this.set('locationModel', {});
    },
    actions: {
      selectKind: function selectKind(kind) {
        if (kind) {
          this.set('model.kind', kind.name);
        }
      },
      typedCustom: function typedCustom(name) {
        this.set('model.kind', name);
      },
      selectCountry: function selectCountry(country) {
        this.set('locationModel.country', country.id);
      },
      save: function save(model) {
        var _this = this;

        // start date should be null if there's no value
        if (model.get('kind') === 'Contact') {
          var contact = this.store.createRecord('contact', {
            name: model.get('name'),
            email: this.get('contactModel.email')
          });
          this.set('contactModel', contact);
          contact.save().then(function (contact) {
            _this.afterSave(contact);
          });
        } else if (model.get('kind') === 'Location') {
          var location = this.store.createRecord('location', {
            name: model.get('name'),
            address_1: this.get('locationModel.address_1'),
            address_2: this.get('locationModel.address_2'),
            city: this.get('locationModel.city'),
            state: this.get('locationModel.state'),
            postalCode: this.get('locationModel.postalCode'),
            country: this.get('locationModel.country'),
            website: this.get('locationModel.website')
          });
          this.set('locationModel', location);
          location.save().then(function (location) {
            _this.afterSave(location);
          });
        } else {
          this._super.apply(this, arguments);
        }
      }
    },
    title: Ember.computed('model.isNew', function () {
      if (this.get('model.isNew')) {
        return 'New Tag';
      } else {
        return 'Edit Tag';
      }
    })
  });

  _exports.default = _default;
});