define("zipbooks/components/overlays/dialogs/paid-modal", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/need-details", "zipbooks/mixins/auto-upgrade"], function (_exports, _testable, _needDetails, _autoUpgrade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, _autoUpgrade.default, {
    client: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    needs: Ember.computed.alias('model.needs'),
    canAutoUpgrade: true,
    upgradeOptionDetails: Ember.computed('needs', function () {
      var needs = this.needs;
      return _needDetails.default[needs];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('model.isAlreadyAutoUpgraded')) {
        this.afterUpgrade();
        return true;
      } else if (this.shouldAutoUpgrade(this.get('session.currentUser.account'))) {
        this.autoUpgrade(function () {
          _this.afterUpgrade();
        }, function (_error) {
          _this.set('canAutoUpgrade', false);
        });
        return false;
      } else {
        this.set('canAutoUpgrade', false);
      }
    },
    actions: {
      goToSubscription: function goToSubscription() {
        this.navigateToSubscriptionPage(this.needs);
        this.close();
      },
      accept: function accept() {
        this.close();
      }
    },
    afterUpgrade: function afterUpgrade() {
      var _this2 = this;

      Ember.run.next(function () {
        _this2.set('container.title', Ember.String.htmlSafe('<i class="icon-rank-army-star-badge-2-filled"></i>You\'ve been upgraded!'));
      });
      var afterUpgrade = this.get('model.afterUpgrade');

      if (Ember.typeOf(afterUpgrade) === 'string') {
        this.router.transitionTo(afterUpgrade);
      } else if (Ember.typeOf(afterUpgrade) === 'function') {
        afterUpgrade();
      }
    }
  });

  _exports.default = _default;
});