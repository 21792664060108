define("zipbooks/models/bill", ["exports", "@ember-data/model", "zipbooks/models/model", "zipbooks/utils/parse-number", "zipbooks/mixins/taggable", "zipbooks/mixins/cloud-fileable", "zipbooks/mixins/notificationable"], function (_exports, _model, _model2, _parseNumber, _taggable, _cloudFileable, _notificationable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_cloudFileable.default, _taggable.default, _notificationable.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    currencyCode: (0, _model.attr)('string', {
      example: 'USD'
    }),
    date: (0, _model.attr)('date'),
    dueDate: (0, _model.attr)('date'),
    history: (0, _model.attr)(),
    notes: (0, _model.attr)(),
    number: (0, _model.attr)(),
    paidTotal: (0, _model.attr)(),
    status: (0, _model.attr)(),
    terms: (0, _model.attr)(),
    total: (0, _model.attr)(),
    contact: (0, _model.belongsTo)('contact'),
    items: (0, _model.hasMany)('billItem'),
    locked: Ember.computed('date', 'session.account.settings.lockDate', function () {
      return this.date <= this.session.get('account.settings.lockDate');
    }),
    computedTotal: Ember.computed('items.@each.total', function () {
      return this.items.reduce(function (acc, item) {
        return (0, _parseNumber.default)(item.get('total')).round(2) + acc;
      }, 0);
    }),
    due: Ember.computed('total', 'paidTotal', function () {
      return (0, _parseNumber.default)(this.total).round(2) - (0, _parseNumber.default)(this.paidTotal).round(2);
    }),
    daysUntilDue: Ember.computed('dueDate', function () {
      return Math.abs(moment(this.dueDate || this.date).diff(moment(), 'days'));
    }),
    daysUntilDueColor: Ember.computed('dueDate', function () {
      return moment(this.dueDate || this.date).diff(moment(), 'days') < 0 ? 'red' : 'black';
    }),
    calculatedDue: Ember.computed('computedTotal', 'paidTotal', function () {
      return (0, _parseNumber.default)(this.computedTotal).round(2) - (0, _parseNumber.default)(this.paidTotal).round(2);
    }),
    category: Ember.computed('items.firstObject.chartAccount.name', function () {
      return this.get('items.firstObject.chartAccount');
    }),
    isSplit: Ember.computed('items.@each.chartAccount', function () {
      var uniqueObjects = [];
      this.items.forEach(function (item) {
        if (!uniqueObjects.isAny('id', item.get('chartAccount.id'))) {
          uniqueObjects.addObject(item.get('chartAccount.id'));
        }
      });
      return uniqueObjects.length > 1;
    }),
    addPayment: function addPayment(params) {
      return this.client.POST('bills', this.id + '/payments', {
        data: {
          type: 'bill-payment',
          attributes: {
            date: params.get('date'),
            note: params.get('note')
          },
          relationships: {
            chart_account: {
              data: {
                id: params.get('chartAccount.id'),
                type: 'chart-account'
              }
            },
            tags: {
              data: params.get('tags').map(function (tag) {
                return {
                  type: 'tag',
                  relationships: {
                    taggable: {
                      data: {
                        id: tag.get('id'),
                        type: tag.get('taggableType')
                      }
                    }
                  }
                };
              })
            },
            debit_lines: {
              data: params.get('debitLines').map(function (debitLine) {
                return {
                  type: 'debit-line',
                  attributes: {
                    amount: debitLine.get('amount')
                  },
                  relationships: {
                    chart_account: {
                      data: {
                        id: debitLine.get('chartAccount.id'),
                        type: 'chart-account'
                      }
                    }
                  }
                };
              })
            }
          }
        }
      });
    }
  });

  _exports.default = _default;
});