define("zipbooks/helpers/humanize", ["exports", "zipbooks/utils/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanize = humanize;
  _exports.default = void 0;

  function humanize(params
  /*, hash*/
  ) {
    return (0, _humanize.default)(params[0]);
  }

  var _default = Ember.Helper.helper(humanize);

  _exports.default = _default;
});