define("zipbooks/utils/alphanumeric-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = alphanumericSort;
  var reA = /[^a-zA-Z]/g;
  var reN = /[^0-9]/g;

  function alphanumericSort(a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    var aA = a.replace(reA, '');
    var bA = b.replace(reA, '');

    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ''), 10);
      var bN = parseInt(b.replace(reN, ''), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }
});