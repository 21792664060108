define("zipbooks/utils/plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    key: 'starter',
    id: '1',
    name: 'Starter',
    price: 0,
    kind: 'accounting'
  }, {
    key: 'smarter',
    id: '2',
    name: 'Smarter',
    price: 15,
    kind: 'accounting'
  }, {
    key: 'sophisticated',
    id: '3',
    name: 'Sophisticated',
    price: 35,
    kind: 'accounting'
  }, {
    key: 'early_zb_free_plan',
    id: '4',
    name: 'Early ZipBooker Free Plan',
    price: 0,
    exclusive: true,
    kind: 'accounting'
  }, {
    key: 'billy',
    id: '7',
    name: 'Billy Sophisticated',
    price: 15,
    kind: 'accounting'
  }, {
    key: 'smarter_documents',
    id: '10',
    name: 'Smarter with documents',
    price: 15,
    exclusive: true,
    kind: 'accounting'
  }, {
    key: 'exclusive',
    // in stripe
    id: '97',
    name: 'ZipBooks Exclusive Sophisticated Plan',
    price: 10,
    exclusive: true,
    kind: 'accounting'
  }, {
    key: 'fnf',
    id: '103',
    name: 'Friends & Family',
    price: 0,
    kind: 'accounting',
    exclusive: true
  }, {
    key: 'bookkeeping_trial',
    id: '104',
    name: 'Bookkeeping Trial',
    price: 0,
    kind: 'accounting'
  }, {
    key: 'accounting_firm',
    id: '300',
    name: 'Accounting Firm',
    price: 0,
    kind: 'cpa',
    exclusive: true
  }, {
    key: 'managed-sophisticated',
    id: '301',
    name: 'Managed Sophisticated',
    price: 0,
    kind: 'accounting'
  }, {
    key: 'accountant',
    id: '302',
    name: 'Accountant',
    price: 10,
    kind: 'cpa',
    exclusive: true
  }, {
    key: 'messaging',
    id: '303',
    name: 'Messaging +',
    price: 4,
    kind: 'inherited',
    exclusive: true
  }];
  _exports.default = _default;
});