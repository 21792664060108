define("zipbooks/models/refund", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    amount: (0, _model.attr)('money'),
    reason: (0, _model.attr)(),
    payment: (0, _model.belongsTo)('payment')
  });

  _exports.default = _default;
});