define("zipbooks/templates/components/form-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4JY3Ikw0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"locked\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray-600\"],[8],[1,[22,\"placeholder\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[22,\"value\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"position-relative\"],[8],[0,\"\\n    \"],[1,[28,\"textarea\",null,[[\"value\",\"placeholder\",\"name\",\"class\",\"autocomplete\",\"title\",\"maxlength\",\"disabled\",\"testId\"],[[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"name\"]],[28,\"concat\",[\"form-control \",[24,[\"positionClass\"]],\" \",[24,[\"inputClass\"]],\" \",[24,[\"iconClass\"]]],null],[24,[\"autocomplete\"]],[24,[\"title\"]],[24,[\"maxlength\"]],[24,[\"disabled\"]],[24,[\"testId\"]]]]],false],[0,\"\\n    \"],[7,\"label\",true],[11,\"class\",[29,[\"label-inside \",[28,\"unless\",[[24,[\"animateLabel\"]],\"no-animation\"],null],\" \",[22,\"fadeClass\"]]]],[8],[1,[22,\"labelString\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/form-textarea.hbs"
    }
  });

  _exports.default = _default;
});