define("zipbooks/services/notification-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _observers: [],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_observers', []);
    },
    addObserver: function addObserver(object, event, callback) {
      // console.debug(object, 'subscribed to', event)
      // observer cant observe same event multiple times
      if (!this._observers.find(function (o) {
        return o.object === object && o.event === event;
      })) {
        this._observers.pushObject({
          object: object,
          event: event,
          callback: callback
        });
      }
    },
    removeObserver: function removeObserver(object) {
      var _this = this;

      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      // console.debug(event, 'unsubscribed')
      this._observers.forEach(function (observer) {
        if (observer.object === object) {
          if (observer.event === event || event === null) {
            _this._observers.removeObject(observer);
          }
        }
      });
    },
    notify: function notify(event) {
      var info = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      // console.debug(event, 'fired')
      this._observers.forEach(function (observer) {
        if (observer.event === event) {
          // console.debug(event, 'callback called on observer', observer)
          observer.callback(info);
        }
      });
    }
  });

  _exports.default = _default;
});