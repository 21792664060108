define("zipbooks/controllers/main/documents", ["exports", "zipbooks/mixins/controller-pagination", "zipbooks/mixins/index-controller", "zipbooks/utils/secure-downloader"], function (_exports, _controllerPagination, _indexController, _secureDownloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPagination.default, _indexController.default, {
    actions: {
      documentsDidUpload: function documentsDidUpload() {
        this.model.update();
      },
      filesSelected: function filesSelected(event) {
        this.set('fileEvent', event);
        this.set('hasUploaded', true);
      },
      downloadFile: function downloadFile(cloudFile) {
        cloudFile.then(function (loaded) {
          return (0, _secureDownloader.default)(loaded);
        });
      }
    },

    /****************
     * Drop to upload
     *****************/
    fileEvent: null,
    setupDropToUpload: function setupDropToUpload() {
      var _this = this;

      $(document).on('dragover.documents', function (event) {
        event.preventDefault();
        $('body').addClass('whole-page-drag-over');
        Ember.run.debounce(_this, 'removeDragIndicator', 100);
        return false;
      });
      $(document).on('drop.documents', function (event) {
        event.preventDefault();

        _this.set('fileEvent', {
          dataTransfer: {
            files: event.dataTransfer.files
          }
        });

        _this.set('hasUploaded', true);

        return false;
      });
    },
    removeDropToUpload: function removeDropToUpload() {
      $(document).off('dragover.documents');
      $(document).off('drop.documents');
    },
    // helpers
    removeDragIndicator: function removeDragIndicator() {
      $('body').removeClass('whole-page-drag-over');
    }
  });

  _exports.default = _default;
});