define("zipbooks/routes/maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    client: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.client.send('GET', 'users', 'me').then(function ()
      /*model*/
      {
        _this.transitionTo('main.dashboard');
      }, function (error) {
        return error;
      });
    },
    activate: function activate() {
      var _this2 = this;

      var handle = setInterval(function () {
        _this2.refresh();
      }, 10000);
      this.set('handle', handle);
    },
    deactivate: function deactivate() {
      clearInterval(this.handle);
    }
  });

  _exports.default = _default;
});