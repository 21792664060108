define("zipbooks/components/journal-entry-line", ["exports", "zipbooks/mixins/testable", "zipbooks/utils/is-control-key", "zipbooks/utils/parse-number", "zipbooks/utils/overridable-computed"], function (_exports, _testable, _isControlKey, _parseNumber, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    actions: {
      selectChartAccount: function selectChartAccount(chartAccount) {
        this.set('line.chartAccount', chartAccount);
      },
      edit: function edit() {
        Ember.tryInvoke(this, 'edit', [this.line].concat(Array.prototype.slice.call(arguments)));
      },
      remove: function remove() {
        Ember.tryInvoke(this, 'remove', [this.line]);
      },
      selectAll: function selectAll(event) {
        $(event.target).select();
      },
      setDebitAmount: function setDebitAmount(event) {
        if ((0, _isControlKey.default)(event.which)) {
          return;
        }

        this.set('line.kind', 'debit');
        this.set('creditAmount', null);
        this.set('line.amount', (0, _parseNumber.default)(event.target.value).toFixed(2));
      },
      setCreditAmount: function setCreditAmount(event) {
        if ((0, _isControlKey.default)(event.which)) {
          return;
        }

        this.set('line.kind', 'credit');
        this.set('debitAmount', null);
        this.set('line.amount', (0, _parseNumber.default)(event.target.value).toFixed(2));
      }
    },
    debitAmount: (0, _overridableComputed.default)('debitAmount', 'line.{kind,amount}', function () {
      if (this.get('line.kind') === 'debit') {
        return Math.abs(this.get('line.amount') || 0).toFixed(2);
      }
    }),
    creditAmount: (0, _overridableComputed.default)('creditAmount', 'line.{kind,amount}', function () {
      if (this.get('line.kind') === 'credit') {
        return Math.abs(this.get('line.amount') || 0).toFixed(2);
      }
    })
  });

  _exports.default = _default;
});