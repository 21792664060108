define("zipbooks/routes/main/reconciliations", ["exports", "zipbooks/mixins/index-route", "zipbooks/mixins/permission-redirect"], function (_exports, _indexRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_indexRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    actions: {
      refresh: function refresh() {
        this.urlCache.clear();
        this.refresh();
      }
    },
    model: function model()
    /*params*/
    {
      return this.store.cachedQuery('chartAccount', {
        filter: {
          reconcilable: true
        }
      });
    }
  });

  _exports.default = _default;
});