define("zipbooks/templates/components/time/duration-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aFw+bNJ1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showHours\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"font-weight-bold \",[22,\"hoursClass\"],\" \",[22,\"class\"]]]],[10,\"data-test-id\",\"hours\"],[8],[0,\"\\n    \"],[1,[22,\"hours\"],false],[7,\"span\",true],[11,\"class\",[29,[\"font-weight-normal \",[22,\"opacityClass\"],\" \",[22,\"class\"]]]],[8],[0,\"h\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"span\",true],[11,\"class\",[29,[\"font-weight-bold \",[22,\"minutesClass\"],\" \",[22,\"class\"]]]],[10,\"data-test-id\",\"minutes\"],[8],[0,\"\\n  \"],[1,[22,\"minutes\"],false],[7,\"span\",true],[11,\"class\",[29,[\"font-weight-normal \",[22,\"opacityClass\"],\" \",[22,\"class\"]]]],[8],[0,\"m\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showSeconds\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"font-weight-bold \",[22,\"secondsClass\"],\" \",[22,\"class\"]]]],[10,\"data-test-id\",\"minutes\"],[8],[0,\"\\n    \"],[1,[22,\"seconds\"],false],[7,\"span\",true],[11,\"class\",[29,[\"font-weight-normal \",[22,\"class\"]]]],[8],[0,\"s\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/time/duration-formatter.hbs"
    }
  });

  _exports.default = _default;
});