define("zipbooks/components/overlays/dialogs/action-confirm", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    testId: 'action-confirm',
    actions: {
      confirm: function confirm() {
        var _this = this;

        if (this.get('callbacks.confirm')) {
          var promise = this.get('callbacks.confirm')();

          if (promise && promise.then) {
            this.set('buttonState', 'saving');
            promise.then(function () {
              _this.set('buttonState', 'loaded');

              _this.close();
            }, function () {
              _this.set('buttonState', 'invalid');
            });
          } else {
            this.close();
          }
        } else {
          this.close();
        }
      },
      cancel: function cancel() {
        if (this.get('callbacks.cancel')) {
          this.get('callbacks.cancel')();
        }

        this.close();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('[type="submit"]').focus();
    }
  });

  _exports.default = _default;
});