define("zipbooks/components/dashboard/onboarding-progress", ["exports", "zipbooks/mixins/onboarding"], function (_exports, _onboarding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_onboarding.default, {// classNameBindings: ['isComplete:hidden'],
  });

  _exports.default = _default;
});