define("zipbooks/templates/components/single-item-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OhbjFVJ3",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[28,\"action\",[[23,0,[]],\"save\"],null],[24,[\"buttonState\"]]]],[0,\"\\n\"],[4,\"unless\",[[24,[\"hideButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"status-button\",null,[[\"type\",\"class\",\"action\",\"state\",\"title\",\"testId\"],[\"submit\",[28,\"concat\",[\"btn btn-small btn-single-item-form btn-\",[28,\"first\",[[24,[\"buttonColor\"]],\"blue\"],null]],null],[28,\"action\",[[23,0,[]],\"save\"],null],[24,[\"buttonState\"]],[28,\"if\",[[24,[\"buttonTitle\"]],[24,[\"buttonTitle\"]],\"Save\"],null],\"save\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"single-item-form-not-editing \",[22,\"notEditingClass\"],\" row x-small-gutters\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto\"],[8],[0,\"\\n      \"],[7,\"i\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"edit\"],null]],[10,\"class\",\"ml-2 icon-pencil-2 gray cursor-pointer\"],[10,\"data-test-id\",\"edit\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/single-item-form.hbs"
    }
  });

  _exports.default = _default;
});