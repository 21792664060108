define("zipbooks/mixins/bulk-operation", ["exports", "zipbooks/utils/pluralize", "zipbooks/utils/humanize"], function (_exports, _pluralize, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    client: Ember.inject.service(),
    // bulk performs each call to the server individually for a list of objects
    bulk: function bulk(models, eachCallback, resultsCallback) {
      var promises = models.map(function (model) {
        return eachCallback(model);
      });
      var changes = models.map(function (m) {
        return Object.keys(m.changedAttributes()).length;
      });
      Ember.RSVP.allSettled(promises).then(function (results) {
        results = results.map(function (r, idx) {
          return {
            success: r.state == 'fulfilled',
            model: models.objectAt(idx),
            isChanged: r.state == 'fulfilled' && (!r.value || r.value.get('isDeleted') || changes.objectAt(idx) || false),
            error: r.reason
          };
        });
        resultsCallback({
          succeeded: results.filter(function (r) {
            return r.success && r.isChanged;
          }).map(function (r) {
            return r.model;
          }),
          unchanged: results.filter(function (r) {
            return r.success && !r.isChanged;
          }).map(function (r) {
            return r.model;
          }),
          failed: results.filter(function (r) {
            return !r.success;
          }).map(function (r) {
            return r.model;
          })
        });
      });
    },
    // batch makes one call to the server to a `/resource/*` endpoint. This is a newer way to do it. It's more complex
    // because it requires a new endpoint, but it greatly reduces error rates and deadlocks, so it is the new and
    // preferred way to do list actions
    batch: function batch(models, prepareCallback, resultsCallback) {
      var _this = this;

      var payloads = models.map(function (model) {
        return prepareCallback(model);
      });
      var modelType = payloads.firstObject.data.type;
      var path = this.store.adapterFor(modelType).pathForType(modelType);
      var modelsById = models.reduce(function (acc, model) {
        var o = acc[model.id] || {};
        o.object = model;
        o.changes = Object.keys(model.changedAttributes()).length;
        acc[model.id] = o;
        return acc;
      }, {});
      this.client.PATCH(path, '*', {
        batch: payloads
      }).then(function (results) {
        results = results.map(function (r) {
          if (r.status === 'success') {
            _this.store.pushPayload(r.payload);
          }

          return {
            success: r.status === 'success',
            model: modelsById[r.id].object,
            isChanged: r.status === 'success' && (modelsById[r.id].changes || false),
            payload: r.payload
          };
        });
        resultsCallback({
          succeeded: results.filter(function (r) {
            return r.success && r.isChanged;
          }).map(function (r) {
            return r.model;
          }),
          unchanged: results.filter(function (r) {
            return r.success && !r.isChanged;
          }).map(function (r) {
            return r.model;
          }),
          failed: results.filter(function (r) {
            return !r.success;
          }).map(function (r) {
            return r.model;
          })
        });
      }, function (_error) {
        resultsCallback({
          succeeded: [],
          unchanged: [],
          failed: models
        });
      });
    },
    showBulkBanner: function showBulkBanner(action, successCount, alreadyCount, failedCount, pluralModelType) {
      var modelType = this.modelType;
      modelType = modelType === 'journal_entry' || modelType === 'journal_entry_line' ? 'transaction' : (0, _humanize.default)(modelType);
      pluralModelType = pluralModelType || modelType + 's';
      var didString = (0, _pluralize.default)("%p (".concat(modelType, "/").concat(pluralModelType, ") (was/were) ").concat(action, "."), successCount);
      var alreadyString = (0, _pluralize.default)("%p (".concat(modelType, "/").concat(pluralModelType, ") (was/were) already ").concat(action, "."), alreadyCount);
      var skipString = (0, _pluralize.default)("%p (".concat(modelType, "/").concat(pluralModelType, ") can't be ").concat(action, "."), failedCount);
      var parts = [];

      if (successCount > 0) {
        parts.push(didString);
      }

      if (alreadyCount > 0) {
        parts.push(alreadyString);
      }

      if (failedCount > 0) {
        parts.push(skipString);
      }

      var string = parts.join(' '); // its an error if non succeeded

      this.overlay.showBanner(successCount > 0 ? 'success' : 'error', string);
    }
  });

  _exports.default = _default;
});