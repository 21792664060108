define("zipbooks/components/line-item-previewer", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    tagName: 'form',
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      selectProject: function selectProject(project) {
        var projects = this.getWithDefault('model.projects', []);
        var exists = projects.findBy('id', project.get('id'));

        if (exists) {
          projects.removeObject(exists);
        } else {
          projects.addObject(project);
        }

        this.set('model.projects', projects);
      }
    },
    contactProjects: Ember.computed(function () {
      var params = {
        filter: {
          archived: false,
          contact_id: this.get('contact.id'),
          show: 'primary',
          billable: true
        }
      };
      return this.store.cachedQuery('project', params);
    }),
    multipleContactProjects: Ember.computed(function () {
      var params = {
        filter: {
          archived: false,
          show: 'multiple_contact',
          billable: true
        }
      };
      return this.store.cachedQuery('project', params);
    }),
    allProjects: Ember.computed.union('contactProjects', 'multipleContactProjects')
  });

  _exports.default = _default;
});