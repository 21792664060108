define("zipbooks/templates/components/add-bank-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YG5unT9+",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"message\"],false],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"loading\"],null]],null,{\"statements\":[[4,\"if\",[[28,\"not\",[[28,\"eq\",[[24,[\"hideLoader\"]],true],null]],null]],null,{\"statements\":[[0,\"    \"],[15,\"partials/loading\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"login\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"mx-widget\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"loadingWidgetUrl\"]]],null,{\"statements\":[[4,\"if\",[[28,\"not\",[[28,\"eq\",[[24,[\"hideLoader\"]],true],null]],null]],null,{\"statements\":[[0,\"      \"],[15,\"partials/loading\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "zipbooks/templates/components/add-bank-widget.hbs"
    }
  });

  _exports.default = _default;
});