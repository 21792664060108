define("zipbooks/components/overlays/dialogs/choose-number-modal", ["exports", "ember-data", "zipbooks/utils/push-payload", "zipbooks/utils/later"], function (_exports, _emberData, _pushPayload, _later) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var phone = this.session.get('account.primaryLocation.phone');

      if (phone) {
        var areaCode = phone.replace(/[^\d]/g, '').replace(/^1*/, '').slice(0, 3);
        this.set('areaCode', areaCode);
      }
    },
    availableNumbers: Ember.computed('areaCode', function () {
      if (this.areaCode && this.areaCode.length > 0) {
        var params = {
          'area-code': this.areaCode
        };
        return _emberData.default.PromiseArray.create({
          promise: this.client.GET('messaging', "twilio/search-number?".concat($.param(params))).then(function (r) {
            return r.data.slice(0, 3);
          })
        });
      } else {
        return _emberData.default.PromiseArray.create({
          promise: Ember.RSVP.resolve([])
        });
      }
    }),
    actions: {
      selectedNumber: function selectedNumber(number) {
        this.set('selected', number);
      },
      save: function save() {
        var _this = this;

        return this.client.POST('messaging', 'twilio/select-number', {
          number: this.selected
        }).then(function (settings) {
          (0, _pushPayload.default)(_this.store, 'account-settings', settings);
          (0, _later.default)(500, function () {
            _this.close();
          });
        }, function (errors) {
          _this.overlay.showBanner('error', errors.map(function (error) {
            return error.title;
          }).join(' '));
        });
      },
      search: function search(value) {
        this.set('selected', null);
        this.set('areaCode', value);
      }
    }
  });

  _exports.default = _default;
});