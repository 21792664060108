define("zipbooks/templates/components/time/ticker-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2rhxEYg/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[[22,\"timerHoursClasses\"]]]],[8],[1,[22,\"timerHours\"],false],[9],[7,\"span\",true],[11,\"class\",[29,[[22,\"greaterOpacityClass\"]]]],[8],[0,\":\"],[9],[7,\"span\",true],[11,\"class\",[29,[[22,\"timerMinutesClasses\"]]]],[8],[1,[22,\"timerMinutes\"],false],[9],[7,\"span\",true],[11,\"class\",[29,[[22,\"greaterOpacityClass\"]]]],[8],[0,\":\"],[9],[7,\"span\",true],[11,\"class\",[29,[[22,\"timerSecondsClasses\"]]]],[8],[1,[22,\"timerSeconds\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/time/ticker-time.hbs"
    }
  });

  _exports.default = _default;
});