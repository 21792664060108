define("zipbooks/templates/components/quick-stats/recurring-journal-entry-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RS1hCdVQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"box-header\"],[8],[0,\"Overview\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"stats\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      \"],[1,[28,\"small-loader\",null,[[\"class\"],[\"my-6\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"stats\",\"isRejected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      Failed to load overview.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body border-bottom-1\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray\"],[8],[0,\"\\n        Active\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"font-weight-bold font-size-18px \",[28,\"if\",[[28,\"eq\",[[24,[\"stats\",\"active_count\"]],0],null],\"gray\"],null]]]],[8],[1,[24,[\"stats\",\"active_count\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray\"],[8],[0,\"\\n        Paused\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"font-weight-bold font-size-18px \",[28,\"if\",[[28,\"eq\",[[24,[\"stats\",\"paused_count\"]],0],null],\"gray\"],null]]]],[8],[0,\"\\n        \"],[1,[24,[\"stats\",\"paused_count\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/quick-stats/recurring-journal-entry-overview.hbs"
    }
  });

  _exports.default = _default;
});