define("zipbooks/components/form-textarea", ["exports", "zipbooks/mixins/labelable"], function (_exports, _labelable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_labelable.default, {
    tagName: '',
    labelAttribute: Ember.computed.alias('value')
  });

  _exports.default = _default;
});