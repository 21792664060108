define("zipbooks/components/bill-history-item", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    tagName: 'li',
    classNameBindings: ['statusClass'],
    statusClass: Ember.computed('model', function () {
      var action = this.get('model.action');

      if (action === 'payment') {
        return 'green-border';
      } else if (action === 'create') {
        return 'blue-border';
      } else {
        return 'gray-border';
      }
    })
  });

  _exports.default = _default;
});