define("zipbooks/components/drawers/link-login-form", ["exports", "zipbooks/components/drawers/base-drawer"], function (_exports, _baseDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseDrawer.default.extend({
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    email: '',
    password: '',
    actions: {
      login: function login() {
        var _this = this;

        this.set('buttonState', 'saving');
        this.set('errors', null);
        var credentials = {
          email: this.email,
          password: this.password
        };
        this.client.send('POST', 'auth', 'link', credentials).then(function (resp) {
          _this.store.pushPayload(resp);

          _this.set('buttonState', 'loaded');

          _this.overlay.showBanner('success', 'Account Linked!');

          _this.handleClose();
        }, function (jqXHR
        /*, textStatus,errorThrown */
        ) {
          _this.set('buttonState', 'invalid');

          if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
            var errors = jqXHR.responseJSON.message;
            Object.keys(errors).forEach(function (key) {
              return errors[key] = errors[key].map(function (e) {
                return {
                  message: e
                };
              });
            });

            _this.set('errors', errors);
          } else if (jqXHR.responseJSON && jqXHR.status === 404) {
            _this.overlay.showBanner('error', "That doesn't look quite right!", {
              title: 'Oops!'
            });
          } else {
            _this.overlay.showBanner('error', 'There was some problem on our end. Sorry!', {
              title: 'Oops!'
            });
          }
        });
      }
    },
    title: 'Add Linked Account'
  });

  _exports.default = _default;
});