define("zipbooks/routes/main/reports/general-ledger", ["exports", "zipbooks/mixins/tag-filterable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _tagFilterableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tagFilterableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      detailed: {
        refreshModel: true
      },
      include: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.tags = this.paramTags(params);

      if (moment(params.start_date).isAfter(moment(params.end_date))) {
        params.start_date = params.end_date;
      }

      this.set('params', params);
      return this.client.cached('GET', 'reports', "general-ledger?".concat($.param(params))).then(function (resp) {
        return resp['general_ledger'];
      });
    },
    actions: {
      export: function _export() {
        var params = this.params;
        var queryParams = {
          detailed: this.get('controller.detailed'),
          include: this.get('controller.include'),
          end_date: params.end_date,
          start_date: params.start_date
        };

        if (params.tags) {
          queryParams.tags = params.tags;
        }

        window.location = '/v2/reports/general-ledger/export?' + $.param(queryParams);
      }
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      this._super.apply(this, arguments);

      if (!controller.get('include')) {
        controller.set('include', this.get('session.account.settings.reportsDefaultShowUnconfirmed') ? 'all' : 'confirmed');
      }
    }
  });

  _exports.default = _default;
});