define("zipbooks/components/time/summary-bar-entry", ["exports", "zipbooks/utils/drawer-link-for"], function (_exports, _drawerLinkFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    outClass: 'time-entry-summary-bar-animation',
    willDestroyElement: function willDestroyElement() {
      this.$().css('width', 0);

      this._super.apply(this, arguments);
    },
    attributeBindings: ['computedStyle:style'],
    descriptionCharMax: 100,
    _computedStyle: Ember.on('init', Ember.observer('timeEntry.percentage', 'timeEntry.oldPercentage', function () {
      var _this = this;

      var percentage = this.get('timeEntry.oldPercentage');
      var style = Ember.String.htmlSafe("width: ".concat(percentage, "%;"));
      this.set('computedStyle', style);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var percentage = _this.get('timeEntry.percentage');

        var style = Ember.String.htmlSafe("width: ".concat(percentage, "%;"));

        _this.set('computedStyle', style); // set the description char limit
        // hours/minutes usually takes up about 40 px, and each character is about 4px


        var entryWidth = percentage * 0.01 * $('.time-entry-summary-bar').width();
        var descriptionCharMax = entryWidth / 4 - 40;

        _this.set('descriptionCharMax', descriptionCharMax);
      });
    })),
    contentColor: Ember.computed('timeEntry.billed', 'timeEntry.project.id', 'timeEntry.contact.id', function () {
      if (this.get('timeEntry.billed')) {
        return 'green';
      } else if (this.get('timeEntry.project.id')) {
        return 'blue';
      } else if (this.get('timeEntry.contact.id')) {
        return 'gold';
      } else {
        return 'gray-500';
      }
    }),
    actions: {
      openTimeEntry: function openTimeEntry() {
        var params = (0, _drawerLinkFor.default)([this.timeEntry]);
        this.router.transitionTo({
          queryParams: {
            drawer: params
          }
        });
      }
    }
  });

  _exports.default = _default;
});