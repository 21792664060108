define("zipbooks/services/sockets", ["exports", "ember-phoenix"], function (_exports, _emberPhoenix) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _sockets: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_sockets', {});
    },
    socketForToken: function socketForToken(token) {
      if (!this._sockets[token]) {
        this._sockets[token] = new _emberPhoenix.Socket('/vs', {
          params: {
            token: token
          } // logger: (kind, msg, data) => {
          //   console.log(`${kind}: ${msg}`, data)
          // }

        });

        this._sockets[token].connect();
      }

      return this._sockets[token];
    }
  });

  _exports.default = _default;
});