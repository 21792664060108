define("zipbooks/routes/main/contact", ["exports", "zipbooks/mixins/permission-redirect"], function (_exports, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRedirect.default, _permissionRedirect.default, {
    notificationCenter: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('contact', params.id, {
        reload: true
      });
    },
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'payments.bank-account.save', function () {
        _this.refresh();
      });
      this.notificationCenter.addObserver(this, 'payments.bank-account.verified', function () {
        _this.refresh();
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'payments.bank-account.save');
      this.notificationCenter.removeObserver(this, 'payments.bank-account.verified');
    }
  });

  _exports.default = _default;
});