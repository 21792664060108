define("zipbooks/components/invoice-history-item", ["exports", "zipbooks/mixins/testable"], function (_exports, _testable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testable.default, {
    tagName: 'li',
    classNameBindings: ['row', 'statusClass'],
    onRefund: function onRefund()
    /*historyItem*/
    {},
    statusClass: Ember.computed('model.action', function () {
      switch (this.get('model.action')) {
        case 'invoice_created':
        case 'open':
          return 'blue-border';

        case 'sent':
        case 'payment':
        case 'refund':
          return 'green-border';

        case 'payment_failed':
          return 'red-border';

        default:
          return 'gray-border';
      }
    }),
    message: Ember.computed('model.action', function () {
      switch (this.get('model.action')) {
        case 'invoice_created':
          return 'This invoice was created.';

        case 'updated':
          return 'This invoice was edited.';

        case 'sent':
          return 'This invoice was sent.';

        case 'open':
          return 'This invoice was finalized.';

        case 'viewed':
          return 'This invoice was viewed.';

        case 'payment_failed':
          return 'Payment on this invoice failed.';

        case 'payment_retried':
          return 'Payment on this invoice was retried.';

        default:
          break;
      }
    }),
    date: Ember.computed('model.occurred_at', function () {
      return moment(this.get('model.occurred_at'));
    }),
    customerName: Ember.computed('model.contact_name', function () {
      return this.get('model.contact_name') || 'Customer';
    }),
    changeVerb: Ember.computed('model.amount', function () {
      return this.get('model.amount') > 0 ? 'increased' : 'decreased';
    }),
    absoluteAmount: Ember.computed('model.amount', function () {
      return Math.abs(this.get('model.amount'));
    }),
    showRefund: Ember.computed('model.can_refund', 'model.max_refundable', function () {
      return this.get('model.can_refund') && this.get('model.max_refundable') > 0;
    }),
    showReceipt: Ember.computed('model.receipt_id', function () {
      return this.model.receipt_id ? true : false;
    }),
    receiptUrl: Ember.computed('model.receipt_id', function () {
      return "".concat(window.location.origin, "/receipt?id=").concat(this.get('model.receipt_id'));
    }),
    actions: {
      refund: function refund() {
        this.onRefund(this.model);
      }
    }
  });

  _exports.default = _default;
});