define("zipbooks/components/base-tag-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tag-input', 'position-relative'],
    placeholder: 'Tags',
    store: Ember.inject.service(),
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    tags: [],
    actions: {
      add: function add(tag) {
        var _this = this;

        if (!tag) {
          return;
        }

        Ember.tryInvoke(this, 'added', [tag]);
        this.$('input').blur();
        Ember.run.scheduleOnce('afterRender', this, function () {
          if (!_this.isDestroyed && !_this.isDestroying) {
            _this.$('input').focus();
          }
        });
      },
      remove: function remove(tag, e) {
        e.preventDefault();
        e.stopPropagation();

        if (tag && !tag.isSystem) {
          Ember.tryInvoke(this, 'removed', [tag]);
        }
      },
      deleteLastTag: function deleteLastTag(e) {
        var lastTag = this.get('tags.lastObject');
        this.send('remove', lastTag, e);
      }
    },
    click: function click(evt) {
      if (evt.target.classList.contains('tag-input')) {
        this.$('input').focus();
      }
    }
  });

  _exports.default = _default;
});