define("zipbooks/routes/main/settings/team", ["exports", "zipbooks/mixins/index-route", "zipbooks/mixins/auto-updating-route", "zipbooks/mixins/permission-redirect"], function (_exports, _indexRoute, _autoUpdatingRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_indexRoute.default, _autoUpdatingRoute.default, _permissionRedirect.default, {
    autoUpdatingModel: 'users',
    model: function model(params) {
      var models = {
        users: this.store.cachedQuery('user', {
          filter: {
            archived: params.archived || false
          },
          sort: 'display_name',
          include: 'identity.avatar_cloud_file'
        }),
        teams: this.store.findAll('teamAccount')
      };
      return Ember.RSVP.hash(models);
    }
  });

  _exports.default = _default;
});