define("zipbooks/controllers/main/invoice", ["exports", "zipbooks/mixins/line-itemable-controller", "zipbooks/mixins/paid-modal-handler", "zipbooks/config/environment", "zipbooks/utils/errors-to-html", "zipbooks/utils/drawer-link-for", "zipbooks/utils/format-money"], function (_exports, _lineItemableController, _paidModalHandler, _environment, _errorsToHtml, _drawerLinkFor, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_lineItemableController.default, _paidModalHandler.default, {
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ['editing', 'code', 'folderId'],
    code: null,
    folderId: null,
    stripeBaseUrl: Ember.computed(function () {
      return _environment.default.isProduction ? 'https://dashboard.stripe.com/customers/' : 'https://dashboard.stripe.com/test/customers/';
    }),
    canAcceptCreditCardPayments: Ember.computed('model.account.settings.activePaymentProcessor', function () {
      if (this.session.hasPrivilege('integrations')) {
        if (this.get('model.account.settings.activePaymentProcessor')) {
          return true;
        }
      } else {
        if (this.get('model.account.settings.activePaymentProcessor') == 'square' || this.get('model.account.settings.activePaymentProcessor') == 'zipbooks') {
          return true;
        }
      }
    }),
    canChargeAch: Ember.computed('editing', 'model.computedTotal', function () {
      return !this.editing && this.model.computedTotal.greaterThan(0);
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.notificationCenter.addObserver(this, 'payment.save', function () {
        var model = _this.model;

        if (model && !model.isDestroyed && !model.isDestroying) {
          model.reload();
        }
      });
    },
    save: function save() {
      var _this2 = this;

      var result = this._super();

      if (this.code && this.folderId) {
        result.then(function (success) {
          if (success) {
            _this2.client.send('POST', 'integrations/drive', 'invoice', {
              invoice_id: _this2.get('model.id'),
              google_code: _this2.code,
              folder_id: _this2.folderId
            });
          }
        });
      }

      return result;
    },
    downloadUrl: Ember.computed('model.externalId', function () {
      return "".concat(window.location.origin, "/v2/public/invoices/").concat(this.get('model.externalId'), "/pdf");
    }),
    previewUrl: Ember.computed('model.externalId', function () {
      return "".concat(window.location.origin, "/invoice?id=").concat(this.get('model.externalId'), "&preview=1");
    }),
    actions: {
      logPayment: function logPayment() {
        var _this3 = this;

        var openDrawer = function openDrawer() {
          _this3.transitionToRoute({
            queryParams: {
              drawer: (0, _drawerLinkFor.default)(['payment', {
                invoice: _this3.get('model.id')
              }])
            }
          });
        };

        if (this.editing) {
          this.save().then(function (success) {
            if (success) {
              openDrawer();
            }
          });
        } else {
          openDrawer();
        }
      },
      chargeNow: function chargeNow(bank) {
        var _this4 = this;

        this.overlay.showDialog('overlays/dialogs/action-confirm', 'Are you sure?', {
          title: "Are you sure you want to charge <b>".concat((0, _formatMoney.default)(this.model.computedAmountDue), "</b> to <b>").concat(bank.bankName, " (...").concat(bank.last4, ") </b> now? ACH payments can take up to 5 business days to process."),
          confirmButton: {
            title: 'Yes, charge',
            class: 'btn-green'
          },
          cancelButton: {
            title: 'No, cancel',
            class: 'btn-white'
          }
        }, {
          confirm: function confirm() {
            var model = _this4.store.createRecord('payment', {
              amount: _this4.model.computedAmountDue,
              date: moment().utc(),
              invoice: _this4.model,
              paymentMethod: 'pending',
              token: "bank-account-".concat(bank.id)
            });

            model.save().then(function (_resp) {
              _this4.send('refreshRoute');
            }, function (resp) {
              _this4.overlay.showBanner('error', resp.errors.mapBy('detail').join('<br>'));
            });
          },
          cancel: function cancel() {}
        });
      },
      addUnbilledToInvoice: function addUnbilledToInvoice() {
        var _this5 = this;

        this.overlay.showDrawer('drawers/add-to-invoice-form', this.get('model.contact'), {
          addLineItems: function addLineItems(include, doneFunc) {
            var contact = _this5.get('model.contact');

            var params = $.param(include || {});

            _this5.client.send('GET', 'contacts', "".concat(contact.get('id'), "/unbilled?").concat(params)).then(function (resp) {
              // time entries
              var unbilledTimeEntries = Object.keys(resp.unbilled_time_entries || []).map(function (id) {
                return resp.unbilled_time_entries[id];
              });
              unbilledTimeEntries.forEach(function (item) {
                Ember.RSVP.all(item.time_entry_ids.map(function (id) {
                  return _this5.store.findRecord('time-entry', id);
                })).then(function (timeEntries) {
                  item.quantity = (+item.quantity).round(2);
                  item.rate = (+item.rate).round(2);
                  item.lineItemable = _this5.model;
                  item.notes = item.description;
                  delete item.description;
                  delete item.time_entry_ids;

                  var lineItem = _this5.store.createRecord('lineItem', item);

                  lineItem.set('timeEntries', timeEntries);
                  return lineItem;
                });
              }); // expenses

              var unbilledExpenses = Object.keys(resp.unbilled_expenses || []).map(function (id) {
                return resp.unbilled_expenses[id];
              });
              unbilledExpenses.forEach(function (item) {
                _this5.store.findRecord('journal-entry-line', item.journal_entry_line_id).then(function (jel) {
                  item.quantity = (+item.quantity).round(2);
                  item.rate = (+item.rate).round(2);
                  item.lineItemable = _this5.model;
                  item.notes = item.description;
                  item.journalEntryLineId = item.journal_entry_line_id;
                  delete item.description;
                  delete item.journal_entry_line_id;

                  var lineItem = _this5.store.createRecord('lineItem', item);

                  lineItem.set('journalEntryLine', jel);
                  return lineItem;
                });
              });

              _this5.removeEmptyLines();

              doneFunc();
            }, function (jqXHR
            /*, textStatus,errorThrown */
            ) {
              _this5.overlay.showBanner('error', (0, _errorsToHtml.default)(jqXHR.responseJSON.message));
            });
          }
        });
      },
      refundPayment: function refundPayment(historyItem) {
        var _this6 = this;

        this.overlay.showDrawer('drawers/refund-form', historyItem, {
          refunded: function refunded() {
            // reload the invoice so we get the updated totals and
            // pull in the newly changed history
            _this6.model.reload();
          }
        });
      }
    },
    contactHasUnbilled: Ember.computed('model.contact.unbilledTimeEntries', 'model.contact.unbilledJournalEntryLines', function () {
      return this.get('model.contact.unbilledTimeEntries') || this.get('model.contact.unbilledJournalEntryLines') > 0;
    }),
    hasThanks: Ember.computed('model.notes', 'model.terms', function () {
      var notesAndTerms = this.get('model.notes') + ' ' + this.get('model.terms');
      return notesAndTerms.toLowerCase().indexOf('thank') !== -1 || notesAndTerms.toLowerCase().indexOf('appreciate') !== -1;
    }),
    qualityScore: Ember.computed('model.notes', 'model.terms', 'model.contact', 'model.logoCloudFile.downloadUrl', 'model.acceptCreditCards', 'model.account.primaryLocation.address_1', 'model.account.primaryLocation.city', 'model.account.primaryLocation.phone', 'model.contact.address_1', 'model.contact.city', 'hasThanks', function () {
      var textFieldScore = function textFieldScore(text) {
        if (text && text.length === 0) {
          return 0;
        } else if (text && text.length < 400) {
          return 8;
        } else {
          return 6;
        }
      };

      var score = 16; // 16 base

      score += textFieldScore(this.get('model.notes'));
      score += textFieldScore(this.get('model.terms'));

      if (this.hasThanks) {
        score += 4;
      }

      if (this.get('model.contact.id')) {
        score += 11;
      }

      if (this.get('model.logoCloudFile.downloadUrl')) {
        score += 22;
      }

      if (this.get('model.acceptCreditCards')) {
        score += 12;
      }

      if (this.get('model.account.primaryLocation.address_1')) {
        score += 3;
      }

      if (this.get('model.account.primaryLocation.city')) {
        score += 8;
      }

      if (this.get('model.account.primaryLocation.phone')) {
        score += 4;
      }

      if (this.get('model.contact.address_1')) {
        score += 1;
      }

      if (this.get('model.contact.city')) {
        score += 3;
      }

      return score;
    })
  });

  _exports.default = _default;
});