define("zipbooks/utils/route-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = routeInfo;

  function routeInfo() {
    return {
      'settings.admin': {
        parent: 'settings'
      },
      'settings.company': {
        title: 'Company',
        parent: 'settings',
        permissions: ['account.all'],
        color: 'purple',
        icon: 'icon-building-3'
      },
      'settings.payments': {
        parent: 'settings',
        permissions: ['account.all']
      },
      'settings.preferences': {
        title: 'Preferences',
        parent: 'settings',
        permissions: ['account.all'],
        color: 'purple',
        icon: 'icon-settings-1'
      },
      'settings.subscription': {
        title: 'Subscription',
        parent: 'settings',
        permissions: ['account.all'],
        color: 'purple',
        icon: 'icon-rank-army-star-badge-2-filled'
      },
      'settings.team': {
        title: 'Team',
        parent: 'settings',
        permissions: ['users.read'],
        privileges: ['teams'],
        color: 'purple',
        icon: 'icon-account-group-5'
      },
      documents: {
        title: 'Documents',
        parent: 'settings',
        permissions: ['account.all'],
        privileges: ['documents'],
        color: 'purple',
        icon: 'icon-file-new-1'
      },
      locations: {
        title: 'Locations',
        parent: 'settings',
        permissions: ['account.read'],
        color: 'purple',
        icon: 'icon-location-pin-2'
      },
      location: {
        parent: 'settings',
        permissions: ['account.read']
      },
      integrations: {
        title: 'Integrations',
        parent: 'settings',
        permissions: ['account.all'],
        color: 'purple',
        icon: 'icon-synchronize-3'
      },
      'accept-payments': {
        title: 'Accept payments',
        parent: 'settings',
        permissions: ['account.all'],
        color: 'purple',
        icon: 'icon-coin-bank-note2'
      },
      'reports.income-statement': {
        title: 'Income statement',
        parent: 'reports.financial-statements',
        permissions: ['reports.read'],
        color: 'purple',
        class: 'nav-list-item-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.income-statement-percent-of-sales': {
        title: 'Income statement - % of sales',
        parent: 'reports.financial-statements',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'purple',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.balance-sheet': {
        title: 'Balance sheet',
        parent: 'reports.financial-statements',
        permissions: ['reports.read'],
        color: 'purple',
        class: 'nav-list-item-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.cash-flow-statement': {
        title: 'Cash flow statement',
        parent: 'reports.financial-statements',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'purple',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.taxes-invoiced': {
        title: 'Taxes invoiced',
        parent: 'reports.accounting-tax',
        permissions: ['reports.read'],
        color: 'blue',
        icon: 'icon-nav-placeholder no-indent',
        class: 'nav-list-item-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.general-journal': {
        title: 'General journal',
        parent: 'reports.accounting-tax',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'blue',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.general-ledger': {
        title: 'General ledger',
        parent: 'reports.accounting-tax',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'blue',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.trial-balance': {
        title: 'Trial balance',
        parent: 'reports.accounting-tax',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'blue',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.accounts-receivable': {
        title: 'Accounts receivable',
        parent: 'reports.sales',
        permissions: ['reports.read'],
        color: 'green',
        icon: 'icon-nav-placeholder no-indent',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.invoice-summary': {
        title: 'Invoice summary',
        parent: 'reports.sales',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'green',
        icon: 'icon-nav-placeholder no-indent',
        badgeClass: 'no-indent',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.sales-summary': {
        title: 'Sales summary',
        parent: 'reports.sales',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'green',
        icon: 'icon-nav-placeholder no-indent',
        badgeClass: 'no-indent',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.client-report': {
        title: 'Client report',
        parent: 'reports.sales',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'green',
        icon: 'icon-nav-placeholder no-indent',
        badgeClass: 'no-indent',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.credit-card-processing': {
        title: 'Credit card processing',
        parent: 'reports.sales',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'green',
        icon: 'icon-nav-placeholder no-indent',
        badgeClass: 'no-indent',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.expense-summary': {
        title: 'Expense summary',
        parent: 'reports.expenses',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'red',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.contractor-summary': {
        title: '1099 summary',
        parent: 'reports.expenses',
        permissions: ['reports.read'],
        color: 'red',
        class: 'nav-list-item-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.billable-expenses': {
        title: 'Billable expenses',
        parent: 'reports.expenses',
        permissions: ['reports.read'],
        color: 'red',
        class: 'nav-list-item-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.user-summary': {
        title: 'User summary',
        parent: 'reports.teams-tracking',
        permissions: ['reports.read'],
        privileges: ['reports-expanded'],
        color: 'blue',
        icon: 'icon-nav-placeholder no-indent',
        badgeClass: 'no-indent-no-icon',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.time-tracking': {
        title: 'Time tracking',
        parent: 'reports.teams-tracking',
        permissions: ['reports.read'],
        color: 'blue',
        icon: 'icon-nav-placeholder no-indent',
        searchIconClass: 'icon-graph-bar-line'
      },
      'reports.transaction-tracking': {
        title: 'Transaction tracking',
        parent: 'reports.teams-tracking',
        permissions: ['reports.read'],
        privileges: ['accountant'],
        hideUpgrade: true,
        color: 'blue',
        icon: 'icon-nav-placeholder no-indent',
        searchIconClass: 'icon-graph-bar-line'
      },
      reports: {
        title: 'Reports',
        parent: 'reports',
        permissions: ['reports.read'],
        color: 'purple',
        icon: 'icon-graph-bar-line'
      },
      invoices: {
        title: 'Invoices',
        parent: 'invoices',
        permissions: ['invoices.read'],
        color: 'green',
        icon: 'icon-dollar-sign-circle'
      },
      invoice: {
        parent: 'invoices',
        permissions: ['invoices.read'],
        currentWhen: 'main.invoices main.invoice'
      },
      estimates: {
        title: 'Estimates',
        parent: 'invoices',
        permissions: ['estimates.read'],
        color: 'green',
        icon: 'icon-circle-cash',
        currentWhen: 'main.estimates main.estimate'
      },
      estimate: {
        parent: 'invoices',
        permissions: ['estimates.read']
      },
      'recurring-profiles': {
        title: 'Recurring profiles',
        parent: 'invoices',
        permissions: ['invoices.read'],
        privileges: ['recurring-profiles'],
        color: 'green',
        icon: 'icon-dollars-box',
        currentWhen: 'main.recurring-profiles main.recurring-profile'
      },
      'recurring-profile': {
        parent: 'invoices',
        permissions: ['invoices.read']
      },
      items: {
        title: 'Items',
        parent: 'invoices',
        permissions: ['invoices.read'],
        privileges: ['items'],
        color: 'green',
        icon: 'icon-box-1'
      },
      reminders: {
        title: 'Reminders',
        parent: 'invoices',
        permissions: ['invoices.read'],
        privileges: ['reminders'],
        color: 'green',
        icon: 'icon-alarm-sound'
      },
      transactions: {
        title: 'Transactions',
        parent: 'transactions',
        permissions: ['accounting.read'],
        color: 'blue',
        icon: 'icon-bank-notes-12',
        secondaryLink: 'transaction-sheet',
        currentWhen: 'main.transactions main.transaction-sheet'
      },
      'transaction-sheet': {
        title: 'Transaction sheet',
        parent: 'transactions',
        permissions: ['accounting.all'],
        privileges: ['transaction-sheet'],
        color: 'blue',
        icon: 'icon-file-table gray-600'
      },
      'bank-accounts': {
        title: 'Manage banks',
        parent: 'transactions',
        permissions: ['banks.all'],
        color: 'blue',
        term: 'manage banks bank accounts',
        icon: 'icon-bank-22'
      },
      reconciliations: {
        title: 'Reconciliation',
        parent: 'transactions',
        permissions: ['accounting.all'],
        privileges: ['reconciliation'],
        color: 'blue',
        icon: 'icon-check-22'
      },
      reconciliation: {
        parent: 'transactions',
        permissions: ['accounting.all'],
        privileges: ['reconciliation']
      },
      'chart-accounts': {
        title: 'Categories',
        parent: 'transactions',
        permissions: ['accounting.read'],
        color: 'blue',
        icon: 'icon-flow-chart-1'
      },
      labels: {
        title: 'Tags',
        parent: 'transactions',
        permissions: ['accounting.read'],
        privileges: ['labels'],
        color: 'blue',
        icon: 'icon-bookmark-all-2'
      },
      bills: {
        title: 'Bills',
        parent: 'transactions',
        permissions: ['accounting.read'],
        privileges: ['bills-expanded'],
        color: 'blue',
        icon: 'icon-receipt',
        currentWhen: 'main.bills main.bill'
      },
      bill: {
        parent: 'transactions',
        permissions: ['accounting.read'],
        privileges: ['bills-expanded']
      },
      'accountant.dashboard': {
        title: 'Dashboard',
        parent: 'contacts',
        permissions: ['contacts.read'],
        color: 'gold',
        icon: 'icon-graph-bar-line'
      },
      'accountant.clients.index': {
        title: 'Clients',
        parent: 'contacts',
        permissions: ['contacts.read'],
        color: 'gold',
        icon: 'icon-book-contact-2'
      },
      'accountant.clients.defaults': {
        title: 'Client defaults',
        parent: 'contacts',
        anyPermissions: ['account.all', 'contacts.all'],
        color: 'gold',
        icon: 'icon-settings-1'
      },
      'accountant.clients.category-templates': {
        title: 'Category templates',
        parent: 'contacts',
        anyPermissions: ['account.all', 'contacts.all'],
        color: 'gold',
        icon: 'icon-flow-chart-1',
        currentWhen: 'main.accountant.clients.category-templates main.accountant.clients.category-template'
      },
      contacts: {
        title: 'Contacts',
        parent: 'contacts',
        permissions: ['contacts.read'],
        color: 'gold',
        icon: 'icon-book-contact-2',
        currentWhen: 'main.contacts main.contact'
      },
      contact: {
        parent: 'contacts',
        permissions: ['contacts.read']
      },
      projects: {
        title: 'Projects',
        parent: 'tracking',
        permissions: ['projects.read'],
        color: 'blue',
        icon: 'icon-checklist',
        currentWhen: 'main.projects main.project'
      },
      project: {
        parent: 'tracking',
        permissions: ['projects.read']
      },
      'recurring-transactions': {
        title: 'Recurring',
        parent: 'transactions',
        permissions: ['accounting.read'],
        privileges: ['recurring-transactions'],
        color: 'blue',
        icon: 'icon-synchronize-3'
      },
      'time-entries': {
        title: 'Track time',
        parent: 'tracking',
        permissions: ['time_tracking.read'],
        privileges: ['projects'],
        color: 'blue',
        icon: 'icon-clock-1',
        upgrade: 'time'
      },
      'time-and-pay': {
        title: 'Time & Pay',
        parent: 'tracking',
        permissions: ['users.read', 'payroll.all'],
        color: 'blue',
        icon: 'icon-coin-receive'
      },
      payroll: {
        title: 'Payroll',
        parent: 'payroll',
        anyPermissions: ['account.all', 'payroll.all'],
        color: 'red',
        icon: 'icon-account-group-5'
      },
      dashboard: {
        parent: 'dashboard',
        anyPermissions: ['account.read', 'accounting.read'],
        color: 'purple'
      },
      '': {
        parent: 'dashboard'
      }
    };
  }
});