define("zipbooks/components/expandable-each", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExpandableEachComponent = Ember.Component.extend({
    tagName: '',
    expanded: false,
    count: 3,
    truncateMiddle: false,
    sections: Ember.computed('records.@each', 'expanded', function () {
      if (this.expanded || !this.shouldShowMore) {
        return [this.records];
      } else {
        if (this.truncateMiddle) {
          var margin = Math.floor(this.count / 2);
          return [this.records.slice(0, margin), this.records.slice(-margin)];
        } else {
          return [this.records.slice(0, this.count)];
        }
      }
    }),
    shouldShowMore: Ember.computed('records.length', function () {
      return this.records.length > this.count;
    }),
    actions: {
      toggle: function toggle() {
        this.toggleProperty('expanded');
      }
    }
  });
  ExpandableEachComponent.reopenClass({
    positionalParams: ['records']
  });
  var _default = ExpandableEachComponent;
  _exports.default = _default;
});