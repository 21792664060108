define("zipbooks/utils/normalize-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeTime;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function normalizeTime(typed, outputFormat) {
    var acceptedFormats = [[/^\d{1,2}(am|pm)$/, typed, 'Ha'], // 11am   => 11:00am
    [/^\d{1,2}:\d{1,2}(am|pm)$/, typed, 'H:ma'], // 11am   => 11:00am
    [/^\d{2}$/, typed, 'H'], // 14     => 2:00pm, 11 => 11:00am
    [/^\d{2}:\d{1,2}$/, typed, 'H:m'], // 14:30  => 2:30pm
    [/^[1-6]$/, typed + 'pm', 'ha'], // 2      => 2:00pm
    [/^[1-6]:\d{1,2}$/, typed + 'pm', 'h:ma'], // 2:30   => 2:30pm
    [/^\d{1}$/, typed + 'am', 'ha'], // 8      => 8:00am
    [/^\d{1}:\d{1,2}$/, typed + 'am', 'h:ma'], // 8:30   => 8:30am
    [/^.*$/, typed + 'am', 'h:ma']];
    // get rid of characters that don't make sense
    typed = typed.replace(/[^0-9:apm]/g, '');

    var _acceptedFormats$find = acceptedFormats.find(function (f) {
      return typed.match(new RegExp(f[0], 'i'));
    }),
        _acceptedFormats$find2 = _slicedToArray(_acceptedFormats$find, 3),
        _regex = _acceptedFormats$find2[0],
        input = _acceptedFormats$find2[1],
        inputFormat = _acceptedFormats$find2[2]; // eslint-disable-line


    var parsed = moment(input, inputFormat);

    if (parsed.isValid()) {
      return parsed.format(outputFormat);
    } else {
      return moment().format(outputFormat);
    }
  }
});