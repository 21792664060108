define("zipbooks/mixins/locatable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    name: (0, _model.attr)(),
    status: (0, _model.attr)(),
    oauthProvider: (0, _model.attr)(),
    isConnected: Ember.computed.equal('status', 'connected'),
    isAuthError: Ember.computed.equal('status', 'auth_error'),
    account: (0, _model.belongsTo)('account'),
    location: (0, _model.belongsTo)('location'),
    externalLocations: (0, _model.hasMany)('external-location')
  });

  _exports.default = _default;
});