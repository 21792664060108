define("zipbooks/routes/main/accountant/clients/index", ["exports", "zipbooks/mixins/route-pagination", "zipbooks/mixins/index-route", "zipbooks/mixins/permission-redirect"], function (_exports, _routePagination, _indexRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routePagination.default, _indexRoute.default, _permissionRedirect.default, {
    session: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    queryParams: {
      name: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    init: function init() {
      var _this = this;

      this.notificationCenter.addObserver(this, 'account-added', function () {
        return _this.send('refreshRoute');
      });
      this.notificationCenter.addObserver(this, 'client-edited', function () {
        return _this.send('refreshRoute');
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.notificationCenter.removeObserver(this, 'account-added');
      this.notificationCenter.removeObserver(this, 'client-edited');
    },
    beforeModel: function beforeModel() {
      if (this.get('session.subscription.plan.kind') !== 'cpa') {
        this.transitionTo('main.dashboard');
      }

      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var queryParams = {
        kind: 'client',
        page: {
          page: params.page || 1,
          'page-size': 50
        },
        filter: {
          status: params.status
        }
      };
      return this.store.query('contact', queryParams);
    }
  });

  _exports.default = _default;
});