define("zipbooks/utils/errors-to-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = errorsToHtml;

  function errorsToHtml(errors) {
    return Ember.String.htmlSafe(Object.keys(errors).map(function (key) {
      return errors[key].map(function (s) {
        return s.stripHTML();
      }).join('<br>');
    }).join('<br>'));
  }
});