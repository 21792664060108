define("zipbooks/components/notification-row", ["exports", "zipbooks/utils/action-details"], function (_exports, _actionDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isRead: Ember.computed.alias('notification.isRead'),
    classNameBindings: 'isRead:disabler',
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.scrollable = this.$().closest('.js-notifications-scroll');
      this.scrollable.on('scroll.' + this.elementId, function () {
        _this.checkIsSeen();
      });
      $(window).on('resize.' + this.elementId, function () {
        _this.checkIsSeen();
      });
      this.checkIsSeen();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.scrollable.off('.' + this.elementId);
      $(window).off('.' + this.elementId);
    },
    click: function click(e) {
      if (e.target.tagName === 'A') {
        return true;
      } else {
        this.$('.js-click-action').click();
      }
    },
    actorable: Ember.computed.alias('notification.actorable'),
    notificationable: Ember.computed.alias('notification.notificationable'),
    actorableName: Ember.computed('actorable.name', 'actorable.identity', function () {
      var _this2 = this;

      if (this.get('actorable.modelType') === 'user' && this.get('actorable.id') === this.get('session.currentUser.id')) {
        return 'You';
      } else {
        var teamName = this.get('actorable.teamAccount.name');

        if (teamName) {
          return teamName;
        } else {
          var key = ['name', 'email', 'identity.name', 'identity.email'].find(function (k) {
            return _this2.get('actorable.' + k);
          });
          return this.get('actorable.' + key);
        }
      }
    }),
    actorableAvatarUrl: Ember.computed('actorable.{teamAccount.logoCloudFile.downloadUrl,publicProfile.avatar,identity.avatarCloudFile.downloadUrl}', function () {
      if (this.get('actorable.teamAccount.id')) {
        return this.get('actorable.teamAccount.logoCloudFile.downloadUrl');
      } else {
        return this.get('actorable.publicProfile.avatar') || this.get('actorable.identity.avatarCloudFile.downloadUrl');
      }
    }),
    groupedCount: Ember.computed.alias('notification.groupedIds.length'),
    isGrouped: Ember.computed('notification.notification', 'groupedCount', function () {
      return this.groupedCount > 1 || ['transaction_confirmed', 'transaction_imported_from_bank', 'transaction_auto_categorized'].includes(this.get('notification.notification'));
    }),
    pluralType: Ember.computed('notification.notificationableType', function () {
      return (this.get('notification.notificationableType') + 's').toLowerCase();
    }),
    actions: {
      dismissNotifications: function dismissNotifications() {
        Ember.getOwner(this).lookup('controller:main').send('toggleNotificationsMenu');
      }
    },
    leftBorder: Ember.computed('notification.notification', 'isRead', function () {
      if (!this.isRead) {
        var details = _actionDetails.default[this.get('notification.notification')];

        var color = details && details.color || 'gray';
        return Ember.String.htmlSafe('border-left-2-' + color);
      } else {
        return Ember.String.htmlSafe('border-left-2-white');
      }
    }),
    checkIsSeen: function checkIsSeen() {
      if (!this.get('notification.isRead') && this.isVisible()) {
        Ember.run.debounce(this, 'checkAfterDelay', 500);
      }
    },
    checkAfterDelay: function checkAfterDelay() {
      // if still visible after 2 seconds of not scrolling, mark as seen
      if (this.isVisible()) {
        this.set('notification.markAsRead', true);
      }
    },
    isVisible: function isVisible() {
      var $el = this.$();

      if (!$el) {
        return false;
      }

      var $scrollable = this.scrollable;
      var viewportR = $scrollable[0].getBoundingClientRect();
      var viewportH = viewportR.height;
      var viewportT = $scrollable.scrollTop() + viewportR.y;
      var viewportB = viewportT + viewportH;
      var elH = $el.outerHeight();
      var elT = $scrollable.scrollTop() + $el.offset().top;
      var elB = elT + elH;
      return elT >= viewportT && elB <= viewportB;
    }
  });

  _exports.default = _default;
});