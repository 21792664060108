define("zipbooks/routes/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.autoLogin();
    }
  });

  _exports.default = _default;
});