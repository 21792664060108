define("zipbooks/components/index-row-content/reconciliation", ["exports", "zipbooks/utils/navigate-to"], function (_exports, _navigateTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    client: Ember.inject.service(),
    notificationCenter: Ember.inject.service(),
    overlay: Ember.inject.service(),
    store: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['reconciliation-line', 'border-bottom-1'],
    tagName: 'tr',
    categoryLine: Ember.computed('transaction.chartAccount.name', 'transaction.balancingLines.firstObject', function () {
      return this.get('transaction.balancingLines.firstObject');
    }),
    actions: {
      toggle: function toggle() {
        var _this = this;

        var checked = !this.get('transaction.journalEntry.isConfirmed');
        this.get('transaction.journalEntry').then(function (model) {
          model.set('isConfirmed', checked);
          model.save().then(function () {
            return _this.notificationCenter.notify('reload-reconciliation-meta');
          }, function () {
            return _this.set('transaction.journalEntry.isConfirmed', false);
          });
        });
      },
      edit: function edit() {
        var router = Ember.getOwner(this).lookup('router:main');
        (0, _navigateTo.default)(router, 'journal-entry', this.get('transaction.journalEntry.id'));
      },
      destroy: function destroy() {
        var _this2 = this;

        this.urlCache.clear();
        this.get('transaction.journalEntry').then(function (model) {
          model.destroyRecord().then(function () {
            _this2.transaction.unloadRecord();

            Ember.tryInvoke(_this2, 'refreshRoute');
          });
        });
      },
      plusButtonClicked: function plusButtonClicked(position) {
        Ember.tryInvoke(this, 'add', ['custom', position]);
      },
      setChartAccount: function setChartAccount(chartAccount) {
        var _this3 = this;

        this.categoryLine.set('chartAccount', chartAccount);
        this.categoryLine.get('journalEntry').thenIfExists(function (journalEntry) {
          journalEntry.save().then(function () {
            return _this3.notificationCenter.notify('reload-reconciliation-meta');
          });
        });
      },
      selected: function selected() {
        this.selected.apply(this, arguments);
      }
    },
    // show plus button
    mouseMove: function mouseMove(event) {
      if (this.transaction.date > this.session.get('account.settings.lockDate')) {
        var tr = $(event.target).closest('tr');
        var topOfRow = tr.offset().top;
        var offset = event.pageY - topOfRow;
        var half = tr.height() / 2.0;

        if (offset > half) {
          this.set('plusVisible', true);
          this.set('plusPosition', 'bottom');
        } else {
          this.set('plusVisible', true);
          this.set('plusPosition', 'top');
        }
      }
    },
    mouseLeave: function mouseLeave()
    /*event*/
    {
      this.set('plusVisible', false);
    }
  });

  _exports.default = _default;
});