define("zipbooks/controllers/main/reconciliations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    chartAccounts: Ember.computed('model.@each', function () {
      return this.model.sortBy('firstPeriodDate');
    }),
    actions: {
      saveAccountSettings: function saveAccountSettings() {
        return this.get('session.account.settings').then(function (settings) {
          return settings.save();
        });
      }
    }
  });

  _exports.default = _default;
});