define("zipbooks/transforms/percent", ["exports", "@ember-data/serializer/transform", "zipbooks/utils/parse-number"], function (_exports, _transform, _parseNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return (0, _parseNumber.default)(serialized);
    },
    serialize: function serialize(deserialized) {
      return deserialized + '%';
    }
  });

  _exports.default = _default;
});