define("zipbooks/templates/components/quick-stats/imported-transactions-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VYMeKNW6",
    "block": "{\"symbols\":[\"total\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"box-header\"],[8],[0,\"Imported totals\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"counts\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      \"],[1,[28,\"small-loader\",null,[[\"class\"],[\"my-6\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"counts\",\"isRejected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-body\"],[8],[0,\"\\n      Failed to load totals.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"counts\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",\"cursor-pointer d-block box-body border-bottom-1\"],[3,\"action\",[[23,0,[]],\"viewTransactions\",[23,1,[\"month\"]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-size-13px gray\"],[8],[0,\"\\n          \"],[1,[28,\"format-date\",[[23,1,[\"month\"]],\"MMM YYYY\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"font-weight-bold blue\"],[8],[1,[23,1,[\"count\"]],false],[9],[0,\"\\n        transactions\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"box-body font-size-13px gray-550\"],[8],[0,\"\\n        No transactions imported yet.\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zipbooks/templates/components/quick-stats/imported-transactions-count.hbs"
    }
  });

  _exports.default = _default;
});