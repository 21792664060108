define("zipbooks/services/session", ["exports", "ember-data", "zipbooks/utils/redirect", "zipbooks/utils/storage", "zipbooks/utils/intercom", "zipbooks/config/environment", "zipbooks/mixins/sessions"], function (_exports, _emberData, _redirect, _storage, _intercom, _environment, _sessions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_sessions.default, {
    store: Ember.inject.service(),
    accountantStore: Ember.inject.service(),
    client: Ember.inject.service(),
    urlCache: Ember.inject.service(),
    router: Ember.inject.service(),
    overlay: Ember.inject.service(),
    currentUserRecord: null,
    currentAdminRecord: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('currentUserRecord', null);
      this.set('currentAdminRecord', null); // poll for latest ember version every 3 minutes

      this.set('currentEmberVersion', null);

      if (_environment.default.isProduction) {
        setInterval(function () {
          return _this.client.GET('current-hash').then(function (resp) {
            // if we have no version recorded, use this first version we get as the current version
            if (!_this.currentEmberVersion) {
              _this.set('currentEmberVersion', resp.hash);
            } // always record the latest version we get as the new version. Next time we check (on
            // route change) if its differnet from current version, we'll force a reload of the app


            _this.set('newEmberVersion', resp.hash);
          });
        }, 3 * 60 * 1000);
      }

      this.set('chartAccounts', this.get('store').peekAll('chartAccount'));
    },
    // can be used as an object or a promise
    currentUser: Ember.computed('currentUserRecord', 'token', {
      get: function get() {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.resolve(this.currentUserRecord)
        });
      },
      set: function set(_key, value) {
        this.set('currentUserRecord', value);
        return value;
      }
    }),
    accountantUser: Ember.computed('accountantUserRecord', 'currentUser.identity.accountantToken', {
      get: function get() {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.resolve(this.accountantUserRecord)
        });
      },
      set: function set(_key, value) {
        this.set('accountantUserRecord', value);
        return value;
      }
    }),
    currentAdmin: Ember.computed('currentAdminRecord', 'token', {
      get: function get() {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.resolve(this.currentAdminRecord)
        });
      },
      set: function set(_key, value) {
        this.set('currentAdminRecord', value);
        return value;
      }
    }),
    token: Ember.computed({
      get: function get() {
        return _storage.default.get('token');
      },
      set: function set(_key, value) {
        _storage.default.set('token', value, !_storage.default.get('remember'));

        return value;
      }
    }),
    isLoggedIn: Ember.computed('currentUserRecord', 'token', function () {
      return !!this.token;
    }),
    isStealth: Ember.computed('currentUserRecord', 'token', function () {
      return _storage.default.get('stealth') === 'true';
    }),
    isDeviceRegistered: Ember.computed(function () {
      return _storage.default.get('tmx_deviceregistered');
    }),
    environment: Ember.computed(function () {
      return _environment.default.environment;
    }),
    deviceRegisteredToken: Ember.computed(function () {
      return _storage.default.get('tmx_deviceregistered_token');
    }),
    setDeviceRegistered: function setDeviceRegistered(registration) {
      _storage.default.set('tmx_deviceregistered', registration);
    },
    setDeviceRegisteredToken: function setDeviceRegisteredToken(registration_token) {
      _storage.default.set('tmx_deviceregistered_token', registration_token);
    },
    login: function login(token) {
      var remember = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var stealth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.set('token', token);

      _storage.default.set('remember', remember);

      _storage.default.set('token', token, !remember
      /* sessionOnly */
      );

      _storage.default.set('stealth', stealth, true);

      if (_storage.default.get('login-redirect')) {
        var location = _storage.default.get('login-redirect');

        _storage.default.remove('login-redirect');

        (0, _redirect.default)(location);
      }
    },
    logout: function logout() {
      _intercom.default.shutdown();

      this.set('token', null);
      this.urlCache.clear();

      _storage.default.clear({
        except: ['login-redirect', 'tmx_deviceregistered', 'tmx_deviceregistered_token']
      });

      this.set('currentUser', null);
      this.set('accountantUser', null);
      this.store.unloadAll();
      this.accountantStore.unloadAll();
    },
    fetchLoggedInUser: function fetchLoggedInUser() {
      var _this2 = this;

      return this.client.GET('users', 'me').then(function (resp) {
        // make a deep copy because serializers modify the payload in place
        var payload = $.extend(true, {}, resp);

        _this2.store.pushPayload(payload); // get the user we just pushed into the store


        var user = _this2.store.peekRecord('user', resp.data.id); // record if stealth


        if (resp.meta && resp.meta.stealth === true) {
          _storage.default.set('stealth', true, true);
        } // set it as the sessions current user


        _this2.set('currentUser', user);

        if (user.get('identity.accountantToken') && user.get('account.id') !== "".concat(user.get('identity.accountantToken.account_id'))) {
          _this2.fetchAccountantUser();
        } // return as model of main route


        return user;
      });
    },
    fetchAccountantUser: function fetchAccountantUser() {
      var _this3 = this;

      var token = this.get('currentUser.identity.accountantToken');

      if (token) {
        return this.client.GET('users', 'me', null, null, {
          token: token.token
        }).then(function (resp) {
          // make a deep copy because serializers modify the payload in place
          var payload = $.extend(true, {}, resp);

          _this3.accountantStore.pushPayload(payload);

          var user = _this3.accountantStore.peekRecord('user', resp.data.id);

          _this3.set('accountantUser', user);

          return user;
        });
      }
    },
    fetchLoggedInAdmin: function fetchLoggedInAdmin() {
      var _this4 = this;

      return this.client.GET('admin/identities/me').then(function (resp) {
        // make a deep copy because serializers modify the payload in place
        var payload = $.extend(true, {}, resp);

        _this4.store.pushPayload(payload); // get the user we just pushed into the store


        var identity = _this4.store.peekRecord('identity', resp.data.id); // set it as the sessions current user


        _this4.set('currentAdmin', identity); // return as model of main route


        return identity;
      });
    },
    rememberLoginRedirectParams: function rememberLoginRedirectParams() {
      if (window.location.pathname !== '/login' && window.location.pathname !== '/verify') {
        _storage.default.set('login-redirect', window.location.pathname + window.location.search, true
        /* sessionStorage */
        );
      }
    },
    handleEmailNotVerified: function handleEmailNotVerified(error) {
      var _this5 = this;

      this.overlay.showDialog('overlays/dialogs/action-confirm', 'Please verify your email', {
        title: error.title,
        confirmButton: {
          title: 'Resend email',
          class: 'btn-green'
        },
        cancelButton: {
          title: 'Cancel',
          class: 'btn-white'
        }
      }, {
        confirm: function confirm() {
          _this5.client.GET('auth', 'request-verification-email').then(function () {
            _this5.overlay.showBanner('success', 'Verification email sent.');
          });
        },
        cancel: function cancel() {}
      });
    },
    softRelease: Ember.computed({
      get: function get() {
        return _storage.default.get('soft-release') || false;
      },
      set: function set(_key, value) {
        if (value) {
          _storage.default.set('soft-release', value, true);
        } else {
          _storage.default.remove('soft-release');
        }

        return value;
      }
    }),
    isSalesUser: Ember.computed('currentUser.identity.email', function () {
      return this.getWithDefault('currentUser.identity.email', '').slice(-13) === '@zipbooks.com';
    }),
    forceReloadIfNewVersionAvailable: function forceReloadIfNewVersionAvailable() {
      // if a version has been recorded and its now different from a newer version recorded, we know
      // there is a newer ember version available so we now force the page to refresh because the route
      // is changing
      if (this.currentEmberVersion && this.currentEmberVersion !== this.newEmberVersion) {
        window.location = this.router.currentURL;
      }
    }
  });

  _exports.default = _default;
});