define("zipbooks/components/transaction-sheet/suggest/tag-select", ["exports", "zipbooks/components/suggest/tag-select", "zipbooks/mixins/transaction-sheet/auto-suggest"], function (_exports, _tagSelect, _autoSuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tagSelect.default.extend(_autoSuggest.default, {
    focusIn: function focusIn() {
      this._super.apply(this, arguments);

      this.set('show', true);
    }
  });

  _exports.default = _default;
});