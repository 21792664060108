define("zipbooks/helpers/format-money", ["exports", "zipbooks/utils/format-money"], function (_exports, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    compute: function compute(params, hash) {
      // deletes undefined keys
      hash = JSON.parse(JSON.stringify(hash));
      var options = Ember.assign({
        accountingStyle: params[1],
        currency: this.get('session.account.defaultCurrencyCode') || 'USD',
        accountCurrency: this.get('session.account.defaultCurrencyCode') || 'USD',
        hideCurrencyCode: false
      }, hash);
      return Ember.String.htmlSafe((0, _formatMoney.default)(params[0], options));
    }
  });

  _exports.default = _default;
});