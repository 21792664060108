define("zipbooks/helpers/concat-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatArray = concatArray;
  _exports.default = void 0;

  function concatArray(params
  /*, hash*/
  ) {
    return params.compact().reduce(function (acc, v) {
      return acc.concat(v);
    }, []);
  }

  var _default = Ember.Helper.helper(concatArray);

  _exports.default = _default;
});