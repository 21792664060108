define("zipbooks/utils/if-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ifPromise;

  function ifPromise(obj, func) {
    if (obj.then) {
      obj.then(function (o) {
        return func(o);
      });
    } else {
      func(obj);
    }
  }
});